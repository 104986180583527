<template>
  <div class="no-skills-found">
    <img src="@/assets/icons/empty_skills_icon.svg" />
    <p class="header-title">
      The function or part played in a current or past situation.
    </p>
    <p class="example">E.g: CEO, Designer, Project Manager</p>
    <p class="content" v-if="showAdd">
      You have not added any role yet, click below to add one
    </p>
    <button v-if="showAdd" class="add-btn" @click="onAdd()">Add Role</button>
  </div>
</template>
<script>
export default {
  name: "no_project",
  components: {},
  props: {
    showAdd: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {
    onAdd() {
      let vm = this;
      vm.$emit("onAdd", null);
    },
  },
};
</script>
<style lang="scss" scoped>
.no-skills-found {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 33px 36px;
  img {
    width: 100px;
    margin-bottom: 40px;
    opacity: 0.4;
  }

  p.header-title {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 14px;
    font-style: italic;
    font-family: "Nunito", sans-serif;
    color: #646262;
  }
  p.example {
    margin-top: 0px;
    margin-bottom: 50px;
    font-size: 14px;

    font-family: "Nunito", sans-serif;
  }
  p.content {
    margin-top: 0px;
    margin-bottom: 50px;
    font-size: 14px;
    font-family: "Nunito", sans-serif;
    color: #646262;
  }
  .add-btn {
    width: 200px;
    height: 50px;
    background: #1890a9 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #fff;
  }
}
</style>
