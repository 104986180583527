<template>
  <div class="wrapper">
    <div class="tags-widget">
      <div class="tags-item" v-for="(item, index) in input_data.added" :key="index">
        <span>{{ item.name | validate }}</span>
        <button @click="removeItem(item, index)" class="close-btn">
          &times;
        </button>
      </div>
      <input v-if="false" type="text" class="item-input" v-model="tag_text" placeholder="Enter tag"
        @keypress.enter="addTag()" @blur="addTag()" />

      <span class="item-input" placeholder="Enter tag" @keyup="(e) => {
          tag_text = e.target.innerText;
        }
        " @keypress.enter="addTag" @blur="addTag" role="textbox" contenteditable></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "tags-widget",
  props: {
    value: {
      type: Object,
      default: () => {
        return { added: [], removed: [] };
      },
    },
  },
  data() {
    return {
      input_data: { added: [], removed: [] },
      tag_text: "",
    };
  },
  computed: {},
  watch: {
    value(val) {
      let vm = this;
      vm.input_data.added = val.added;
    },
  },
  mounted() {
    let vm = this;
    if (vm.value) {
      vm.input_data.added = vm.value.added;
    }
  },
  methods: {
    addTag(e) {
      let vm = this;
      if (vm.tag_text) {
        let obj = {
          name: vm.tag_text,
        };
        vm.input_data.added.push(obj);
        vm.tag_text = "";
        vm.$emit("onSubmit", vm.input_data);
        vm.$emit("input", vm.input_data);
      }
      setTimeout(() => {
        e.target.innerText = "";
      }, 100);
    },
    removeItem(item, index) {
      let vm = this;
      vm.input_data.added.splice(index, 1);
      if (item.on_update) {
        vm.input_data.removed.push(item.id);
      }
      vm.$emit("onSubmit", vm.input_data);
      vm.$emit("input", vm.input_data);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.wrapper {
  .tags-widget {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex-wrap: wrap;
    padding: 4px;
    min-height: 30px;
    align-items: center;
    overflow-y: auto;
    background: #fff;

    .tags-item {
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #04a5a5;
      margin-right: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      padding: 5px 10px;
      color: #fff;
      border-radius: 5px;

      span {
        margin-right: 10px;
        font-size: 11px;
      }

      .close-btn {
        background: #f5f5f5;
        color: rgb(75, 75, 75);
        height: 15px;
        width: 15px;
        font-size: 11px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .item-input {
      border: none;
      outline: none;
      min-width: 60px;
      width: max-content;
    }
  }
}
</style>