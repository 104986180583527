<template>
  <div class="customer-uploader">
    <div class="select-file" @click="handleGetFile()">
      <slot></slot>
    </div>
    <input
      type="file"
      class="file-input"
      ref="file"
      v-on:change="handleFileUpload()"
    />
  </div>
</template>
<script>
export default {
  name: "customer-uploader",
  components: {},
  props: [],
  data: () => {
    return {
      file: null,
      url: null,
    };
  },
  mounted() {},
  methods: {
    handleGetFile() {
      let vm = this;
      vm.$refs.file.click();
    },
    handleFileUpload() {
      let vm = this;
      if (vm.$refs.file.files.length !== 0) {
        vm.file = vm.$refs.file.files[0];
        vm.url = URL.createObjectURL(vm.file);
        vm.$emit("onFileSelected", vm.file);
      }
    },
    removeFile() {
      let vm = this;
      vm.$refs.file.type = "text";
      vm.$refs.file.type = "file";
      vm.file = null;
      vm.url = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.customer-uploader {
  display: flex;
  width: 100%;
  height: 100%;
  .select-file {
    display: flex;
    height: 100%;
    width: 100%;
  }
  .file-input {
    display: none;
  }
}
</style>
