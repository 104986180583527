<template>
  <widgetDropdown v-model="class_dropdown" :hover="false" :interactive="true" class="class-dropdown">
    <loading :height="15" color="#000" :opacity="1" :width="15" :active.sync="is_loading" :is-full-page="false"
      :can-cancel="false" background-color="#fff" class="loader"></loading>
    <button v-if="!form.is_all_classes &&
      !form.is_my_classes &&
      form.selected_classes.length == 0
      " class="btn-class-dropdown">
      <span>Select class</span>
      <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
    </button>
    <div v-else class="tags-widget">
      <div class="tags-list">
        <div class="tag-item" v-if="form.is_all_classes">All Classes</div>
        <div class="tag-item" v-if="form.is_my_classes">My Classes</div>
        <template v-for="(item, index) in class_data">
          <div class="tag-item" :key="i" v-for="(team, i) in item.teams" v-if="team.checked">
            {{ team.name }}
          </div>
        </template>
      </div>
      <div class="option">
        <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
      </div>
    </div>
    <div slot="dropdown" class="class-dropdown-widget">
      <div class="search-widget">
        <widgetSearch icon="left" v-model="search_input" :loading="isLoading" @keyup="searchClass"
          placeholder="Search here..."></widgetSearch>
      </div>
      <div class="class-list">
        <ul>
          <li v-if="!search_input">
            <a class="class-dropdown-item" href="#">
              <span>
                <input type="checkbox" v-model="form.is_all_classes" @change="onAllClasses" value="all_classes" />
                All Classes</span>
            </a>
          </li>
          <!-- <li v-if="!search_input">
            <a class="class-dropdown-item" href="#"
              ><span
                ><input
                  type="checkbox"
                  @change="onMyClasses"
                  v-model="form.is_my_classes"
                  value="my_classes"
                />
                My Classes
              </span></a
            >
          </li>  -->
          <li v-for="(item, index) in class_data" :key="index" v-if="item.teams.length > 0">
            <a class="class-dropdown-item" href="#" @click="item.expanded = !item.expanded"><span>
                <input type="checkbox" @change="onChecked(item, 'dep')" v-model="item.checked" />
                {{ item.name }}</span>
              <img v-if="!item.expanded" class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
              <img v-else class="icon" src="@/assets/icon/carret-top-gray.svg" /></a>
            <ul class="class-dropdown-item-subitems" v-if="item.expanded">
              <li v-for="(team, index) in item.teams" :key="index">
                <input type="checkbox" @change="onChecked(team, 'team')" v-model="team.checked" />
                {{ team.name }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </widgetDropdown>
</template>

<script>
import { eventBus } from "@/main";
export default {
  name: "widget_input",
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          is_all_classes: false,
          is_my_classes: false,
          selected_classes: [],
        };
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      class_dropdown: false,
      class_data: [],
      form: {
        is_all_classes: false,
        is_my_classes: false,
        selected_classes: [],
      },
      isLoading: false,
      search_input: "",
      is_loading: false,
    };
  },
  watch: {},
  mounted() {
    let vm = this;
    vm.getClassData();
  },
  methods: {
    onAllClasses() {
      let vm = this;
      setTimeout(() => {
        if (vm.form.is_all_classes) {
          vm.form.is_my_classes = false;
          vm.form.selected_classes = [];
        }
        vm.class_data.map((item, index) => {
          eventBus.$set(item, "checked", false);
          eventBus.$set(item, "expanded", false);
          item.teams = item.teams.map((team) => {
            eventBus.$set(team, "checked", false);
            return team;
          });
          return item;
        });
        vm.$emit("input", vm.form);
      }, 500);
    },
    onMyClasses() {
      let vm = this;
      setTimeout(() => {
        if (vm.form.is_my_classes) {
          vm.form.is_all_classes = false;
          vm.form.selected_classes = [];
        }
        vm.class_data.map((item, index) => {
          eventBus.$set(item, "checked", false);
          eventBus.$set(item, "expanded", false);
          item.teams = item.teams.map((team) => {
            eventBus.$set(team, "checked", false);
            return team;
          });
          return item;
        });
        vm.$emit("input", vm.form);
      }, 500);
    },
    onChecked(item, type) {
      let vm = this;
      setTimeout(() => {
        if (item.checked) {
          if (type == "dep") {
            vm.form.is_all_classes = false;
            vm.form.is_my_classes = false;
            item.teams.forEach((el) => {
              el.checked = true;
              vm.form.selected_classes.push(el.id);
            });
            item.expanded = true;
          }
          if (type == "team") {
            vm.form.selected_classes.push(item.id);
          }
        } else {
          if (type == "dep") {
            item.teams.forEach((el, i) => {
              el.checked = false;
              vm.form.selected_classes.splice(i, 1);
            });
            item.expanded = false;
          }
          if (type == "team") {
            let i = vm.form.selected_classes.indexOf(item.id);
            vm.form.selected_classes.splice(i, 1);
          }
        }
        vm.$emit("input", vm.form);
      }, 500);
    },
    searchClass({ target }) {
      let vm = this;
      vm.isLoading = true;
      vm.$store
        .dispatch("SCHOOL_DROPDOWN_SEARCH_CLASS", { quel: target.value })
        .then((response) => {
          vm.isLoading = false;
          vm.class_data = response.data.map((item, index) => {
            eventBus.$set(item, "checked", false);
            eventBus.$set(item, "expanded", true);
            let found_selected_team = false;
            item.teams = item.teams.map((team) => {
              let exists = vm.value.selected_classes.includes(team.id);
              if (exists) {
                found_selected_team = true;
                eventBus.$set(team, "checked", true);
              } else {
                eventBus.$set(team, "checked", false);
              }
              return team;
            });
            if (found_selected_team) {
              eventBus.$set(item, "expanded", true);
            }
            return item;
          });
        })
        .catch((error) => {
          vm.isLoading = false;
          console.error({ error });
        });
    },
    getClassData() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("SCHOOL_DROPDOWN_ALL_CLASS")
        .then((response) => {
          vm.is_loading = false;
          vm.form = vm.value;
          vm.class_data = response.data.map((item, index) => {
            eventBus.$set(item, "checked", false);
            eventBus.$set(item, "expanded", false);
            let found_selected_team = false;
            item.teams = item.teams.map((team) => {
              let exists = vm.value.selected_classes.includes(team.id);
              if (exists) {
                found_selected_team = true;
                eventBus.$set(team, "checked", true);
              } else {
                eventBus.$set(team, "checked", false);
              }
              return team;
            });
            if (found_selected_team) {
              eventBus.$set(item, "expanded", true);
            }
            return item;
          });
        })
        .catch((error) => {
          vm.is_loading = false;
          console.error({ error });
        });
    },
  },
};
</script>
<style lang="scss" >
.class-dropdown {
  position: relative;
  width: 100%;

  .btn-class-dropdown {
    padding: 0px 10px;
    border-radius: 5px;
    font-weight: 300;
    width: 100%;
    height: 30px;
    position: relative;
    background: #fff;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      width: 15px;
    }
  }

  .tags-widget {
    display: flex;
    align-items: center;
    width: 100%;
    background: #fff;
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 4px;
    min-height: 45px;

    .tags-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      max-height: 100px;
      overflow-y: auto;

      .tag-item {
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 4px;
        border-radius: 10px;
        background: #ebebeb;
        max-width: 100%;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .option {
      .icon {
        width: 15px;
      }
    }
  }

  .dropdown-menu {
    width: 100%;
  }

  .class-dropdown-widget {
    width: 100%;
    position: absolute;
    top: 0px;
    height: 200px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px #ddd;
    padding-top: 10px;
    border-radius: 5px;
    z-index: 999;
    background: #fff;

    .search-widget {
      background: #fff;
      z-index: 99999;
      padding: 0px 10px;
    }

    .class-list {
      height: calc(100% - 45px);
      overflow-y: auto;

      ul {
        list-style: none;
        padding-left: 0px;

        li {
          .class-dropdown-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #ececec;
            padding: 15px 20px;
            text-decoration: none;

            .icon {
              width: 15px;
            }
          }

          .class-dropdown-item-subitems {
            li {
              padding: 15px 0px;
              cursor: pointer;
              padding-left: 40px;

              &:hover {
                background: rgb(245, 245, 245);
              }
            }
          }
        }
      }
    }
  }
}
</style>
