<template>
  <div class="overlay">
    <div class="widget-modal-widget" :style="{ width: width }">
      <loading :height="15" color="#000" :opacity="1" :width="15" :active.sync="loading" :is-full-page="false"
        :can-cancel="false" background-color="#ffffff77" class="loader"></loading>

      <button v-if="show_close_btn" class="btn-close" @click="onClose()">
        &times;
      </button>

      <div class="widget-modal-header" v-if="!headerhidden" :style="{ padding: header_padding }">
        <div class="widget-modal-header-left-panel">
          <h1 v-if="title" class="title">{{ title }}</h1>
          <h3 v-if="subtitle" class="sub-title">{{ subtitle }}</h3>
        </div>

        <div class="widget-modal-header-right-panel">
          <slot name="right_header_options"></slot>
        </div>
      </div>
      <div :style="{ padding: body_padding }">
        <slot></slot>
      </div>
      <div class="widget-modal-footer" v-if="!footerhidden" :style="{ padding: footer_padding }">
        <div class="left-modal-options row">
          <div v-if="btnlefttext" class="modal-option">
            <widgetButton @click="onLeftClick" class="btn-primary" :loading="leftloading">
              {{ btnlefttext }}
            </widgetButton>
          </div>
          <div v-if="btnlefttext" class="h-spacer"></div>
          <div v-if="btncentertext" class="modal-option">
            <widgetButton @click="onCenterClick" class="btn-transparent-primary" :loading="centerloading">
              {{ btncentertext }}
            </widgetButton>
          </div>
        </div>
        <div class="modal-option">
          <widgetButton v-if="!hiderightbtn" @click="onRightClick" class="btn-transparent-secondary"
            :loading="rightloading">
            {{ btnrighttext }}
          </widgetButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "widget_button",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    btnlefttext: {
      type: String,
      default: "Ok",
    },
    btnrighttext: {
      type: String,
      default: "Other",
    },
    btncentertext: {
      type: String,
      default: "Cancel",
    },
    leftloading: {
      type: Boolean,
      default: false,
    },
    centerloading: {
      type: Boolean,
      default: false,
    },
    rightloading: {
      type: Boolean,
      default: false,
    },
    show_close_btn: {
      type: Boolean,
      default: false,
    },
    footerhidden: {
      type: Boolean,
      default: false,
    },
    headerhidden: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "500px",
    },
    footer_padding: {
      type: String,
      default: "10px 0px",
    },
    header_padding: {
      type: String,
      default: "10px 0px",
    },
    body_padding: {
      type: String,
      default: "10px",
    },
    hiderightbtn: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() { },
  methods: {
    onClose() {
      let vm = this;
      vm.$emit("onClose", null);
    },
    onLeftClick() {
      let vm = this;
      vm.$emit("onLeftClick", null);
    },
    onCenterClick() {
      let vm = this;
      vm.$emit("onCenterClick", null);
    },
    onRightClick() {
      let vm = this;
      vm.$emit("onRightClick", null);
    },
  },
};
</script>
<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba($color: #000000, $alpha: 0.8);
  display: block;
  padding-top: 70px;
  overflow-y: auto;
  z-index: 999999999;

  .widget-modal-widget {
    margin: 0 auto;
    width: 500px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;

    .btn-close {
      position: absolute;
      top: -15px;
      right: -12px;
      width: 44px;
      height: 44px;
      background: #ffdcdc;
      border: none;
      border-radius: 50%;
      font-size: 28px;
      color: #ff0000;
      cursor: pointer;
      z-index: 99;

      &:hover {
        opacity: 0.9;
      }
    }

    .corner-icon {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 100px;
      height: 100px;
    }

    .widget-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .widget-modal-header-left-panel {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 20px;
          margin-bottom: 7px;
          margin-top: 0px;
          color: #000000;
          font-weight: 300;
        }

        .sub-title {
          font-size: 12px;
          margin: 0px;
          color: #707070;
          font-weight: 300;
        }
      }

      .widget-modal-header-right-panel {}
    }

    .widget-modal-body {
      height: 400px;
      overflow-y: auto;
    }

    .widget-modal-footer {
      padding: 10px 0px;
      display: flex;
      justify-content: space-between;

      .modal-option {}
    }
  }
}
</style>
