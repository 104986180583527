<template>
  <div class="wrapper">
    <div class="choose-widget">
      <div
        class="choose-item"
        v-for="(item, index) in input_data"
        :key="index"
        :class="{ active: selected_item == item.value }"
        @click="selectItem(item.value)"
      >
        {{ item.name | validate }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "shoose-widget",
  props: {
    content: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      input_data: [],
      selected_item: "",
    };
  },
  computed: {},
  watch: {
    content(val) {
      let vm = this;
      vm.input_data = val;
    },
    value(val) {
      let vm = this;
      vm.selected_item = val;
    },
  },
  mounted() {
    let vm = this;
    vm.input_data = vm.content;
    if (vm.value) {
      vm.selected_item = vm.value;
    }
  },
  methods: {
    selectItem(value) {
      let vm = this;
      vm.selected_item = value;
      vm.$emit("onSubmit", vm.selected_item);
      vm.$emit("input", vm.selected_item);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";
.wrapper {
  .choose-widget {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 5px;
    .choose-item {
      width: 33.3333%;
      height: 45px;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #ddd;
      cursor: pointer;
      &.active {
        background: #04a5a5;
        color: #fff;
      }
    }
  }
}
</style>