<template>
  <input :type="type" :disabled="disabled" :value="value" :placeholder="placeholder" :readonly="readonly"
    class="widget_input" @change="onChange" @blur="onBlur" @focus="onFocus" @keyup="onKeyup" @keydown="onKeydown"
    @click="onClick" />
</template>

<script>
export default {
  name: "widget_input",
  props: {
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() { },
  methods: {
    onChange(e) {
      let vm = this;
      vm.$emit("change", e);
    },
    onBlur(e) {
      let vm = this;
      vm.$emit("blur", e);
    },
    onFocus(e) {
      let vm = this;
      vm.$emit("focus", e);
    },
    onKeyup(e) {
      let vm = this;
      vm.$emit("input", e.target.value);
    },
    onKeydown(e) {
      let vm = this;
      vm.$emit("input", e.target.value);
    },
    onClick(e) {
      let vm = this;
      vm.$emit("click", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.widget_input {
  padding: 0px 10px;
  border-radius: 5px;
  font-weight: 300;
  width: 100%;
  height: 30px;
  position: relative;
  background: #fff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  outline: none;
}
</style>
