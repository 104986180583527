<template>
  <div class="wrapper">
    <div class="__container">
      <div class="form">
        <div class="logo">
          <router-link v-if="false" to="/">
            <img src="../assets/images/logo/new_logo.svg" />
          </router-link>
        </div>
        <div class="banner">
          <h5>Welcome back!</h5>
          <h1>Admin Panel</h1>
        </div>
        <div class="login_form">
          <label class="label">E-mail</label>
          <widgetInput type="email" v-model="username" placeholder="Type your E-mail" v-validate="'required'">
          </widgetInput>

          <div class="error-msg" v-show="isErrorOnEmail">
            {{ getErrorMsg.message | validate }}
          </div>
          <label class="label label-password">Password</label>
          <VuePassword type="password" placeholder="Type your Password" class="login-pass-input" v-validate="'required'"
            :strength="0" :disableStrength="false" v-model="password" :class="{ error_input: isErrorOnPassword }"
            @keyup.enter="logIn" />
          <div class="error-msg" v-show="isErrorOnPassword">
            {{ getErrorMsg.message | validate }}
          </div>
          <div class="error-msg" v-show="try_error_label">
            You tried so many time please wait
            {{ chance_time | validate }} seconds
          </div>
          <div class="forgot-password">
            <label>
              Forgot password?
              <router-link to="/forgot-password">
                <a class="signup">Reset</a>
              </router-link>
            </label>
          </div>
        </div>
        <div class="bottom">
          <div class="input-group remember-me-input">
            <input type="checkbox" id="rem" v-model="remember_me" />
            <label for="rem">Remember me</label>
          </div>
          <widgetButton :loading="loading" @click="logIn" class="btn-login btn-secondary">
            Sign In
          </widgetButton>

        </div>
      </div>
    </div>
    <cookieLaw v-if="show_cookie_law" @decline="cookieAccepted('declined')" @allow="cookieAccepted('allowed')">
    </cookieLaw>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VuePassword from "vue-password";
import moment from "moment";
import { eventBus } from "../main";
export default {
  name: "login",
  components: {
    VuePassword,
  },
  computed: {
    ...mapGetters([
      "getLocale",
      "getMessages",
      "getHelper",
      "getAlert",
      "getErrorMsg",
    ]),

    isPageReady() {
      // return true;
      return !(this.getMessages === null || this.getMessages === undefined);
    },

    pageContent() {
      return this.getMessages;
    },
    isErrorOnEmail() {
      return this.getErrorMsg.type === "email";
    },
    isErrorOnPassword() {
      return this.getErrorMsg.type === "password";
    },
  },
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      try_error_label: false,
      chance_time: 30,
      timer: null,
      // emailFocussed : false,
      // passFocussed : false,
      remember_me: true,
      show_cookie_law: false,
    };
  },
  mounted() {
    let vm = this;
    // Logged data loader
    if (vm.$localStorage.get("logged_user_data")) {
      let user_data = JSON.parse(vm.$localStorage.get("logged_user_data"));
      sessionStorage.authToken = user_data.access_token;
      sessionStorage.setItem("isLogedIn", true);
      sessionStorage.setItem("account_type", user_data.account_type);
      vm.$store.commit("AUTH_REQUEST", user_data);
      vm.$store.commit("INITIALIZE_APP_DATA", {
        token: user_data.access_token,
        id: user_data.id,
      });

      if (user_data.verified) {
        vm.$router.push({ name: "feeds" });
      } else {
        vm.$router.push({
          name: "account_activation",
          query: { type: "verify" },
        });
      }
    }

    if (!vm.$localStorage.get("cookie:accepted")) {
      vm.show_cookie_law = true;
    }
  },
  methods: {
    moment,
    cookieAccepted(status) {
      let vm = this;
      vm.$localStorage.set("cookie:accepted", status);
      vm.show_cookie_law = false;
    },
    logIn() {
      let vm = this;
      if (
        vm.$localStorage.get("cookie:accepted") &&
        vm.$localStorage.get("cookie:accepted") == "allowed"
      ) {
        let obj = {
          username: vm.username,
          tryies: 0,
          chance: 1,
          time: vm.moment().format(),
        };
        if (vm.timer) {
          clearInterval(vm.timer);
          vm.timer = null;
        }
        let auth_tryies = [];
        if (sessionStorage.getItem("auth_tryies")) {
          auth_tryies = JSON.parse(sessionStorage.getItem("auth_tryies"));
        }
        const found = auth_tryies.some((item) => item.username === vm.username);
        if (!found) {
          auth_tryies.push(obj);
          vm.authenticate();
        }
        if (found) {
          let user = auth_tryies.find((item) => item.username === vm.username);
          if (user.tryies <= 6) {
            // When still have time
            vm.authenticate();
            auth_tryies.find((item, index) => {
              if (item.username === vm.username) {
                let obj = {
                  username: vm.username,
                  tryies: item.tryies + 1,
                  chance: item.chance,
                  time: vm.moment().format(),
                };
                auth_tryies[index] = obj;
                vm.try_error_label = false;
              }
            });
          } else {
            let time = vm.moment(user.time);
            let time_left = moment().diff(time, "seconds");
            if (time_left > 30 * user.chance) {
              // When time end
              vm.authenticate();
              auth_tryies.find((item, index) => {
                if (item.username === vm.username) {
                  let obj = {
                    username: vm.username,
                    tryies: 0,
                    chance: item.chance + 1,
                    time: vm.moment().format(),
                  };
                  auth_tryies[index] = obj;
                  vm.try_error_label = false;
                }
              });
            } else {
              vm.try_error_label = true;
              vm.chance_time = 30 * user.chance;
              vm.timer = setInterval(() => {
                if (vm.chance_time > 0) {
                  vm.chance_time =
                    30 * user.chance - moment().diff(time, "seconds");
                } else {
                  clearInterval(vm.timer);
                  vm.timer = null;
                  vm.try_error_label = false;
                }
              }, 1000);
            }
          }
        }
        sessionStorage.setItem("auth_tryies", JSON.stringify(auth_tryies));
      } else {
        vm.$notify({
          group: "status",
          type: "warn",
          title: "Messsage",
          text: "We don't have your permission to use cookie",
        });
        vm.show_cookie_law = true;
      }
    },
    startProgressBar() {
      this.$store.dispatch("LOADER_START");
      this.$store.dispatch("LOADER_SUCCESS");
      this.$store.dispatch("LOADER_MESSAGE");
    },
    stopProgressBar() {
      this.$store.dispatch("LOADER_CLOSE");
    },
    authenticate() {
      let vm = this;
      vm.loading = true;
      vm.$store
        .dispatch("AUTH_REQUEST", {
          email: vm.username,
          password: vm.password,
          remember_me: vm.remember_me,
        })
        .then((response) => {
          vm.loading = false;
          let auth_tryies = [];
          // this.$cookies.set(
          //   "account-type",
          //   parseInt(response.data.account_type)
          // );
          if (sessionStorage.getItem("auth_tryies")) {
            auth_tryies = JSON.parse(sessionStorage.getItem("auth_tryies"));
          }
          auth_tryies.find((item, index) => {
            if (item.username === vm.username) {
              auth_tryies.splice(index, 1);
              vm.try_error_label = false;
            }
          });
          sessionStorage.setItem("auth_tryies", JSON.stringify(auth_tryies));

          vm.$localStorage.set(
            "logged_user_data",
            JSON.stringify(response.data)
          );
          vm.$localStorage.set("remember_me", vm.remember_me);


          if (!vm.remember_me) {
            vm.$session.start();
            vm.$session.set("temp_session", "true");
          }

          vm.$router.push({ name: "users-management" });

        })
        .catch((error) => {
          console.log("error", error);
          vm.loading = false;
          if (error.response.data.message) {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "Message",
              text: "Email or Password is incorrect",
            });
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "Message",
              text: "Email or Password is incorrect",
            });
          }
        });
    },

  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 999999;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #fff;

  @media (min-width: 300px) and (max-width: 630px) {
    align-items: unset;
    overflow-y: auto;
  }
}

.__container {
  position: relative;

  @media (min-width: 300px) and (max-width: 630px) {
    padding-top: 70px;
  }

  .form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;

    .logo {
      display: flex;
      flex-direction: column;

      @media (min-width: 300px) and (max-width: 630px) {
        position: relative;

        img {
          width: 70px;
        }
      }
    }

    .banner {
      margin: 20px 0px;

      h5 {
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #676879;
      }

      h1 {
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        color: #000000;
      }
    }

    .login_form {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 300px;

      @media (min-width: 300px) and (max-width: 630px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .label-password {
        margin-top: 10px;
      }

      .label {
        color: #000;
        font-weight: normal;
        font-size: 14px;
        margin-bottom: 5px;
        margin-left: 20px;
      }

      .error_input {
        border: 1px solid crimson !important;
        color: crimson;
      }

      .error-msg {
        color: crimson;
        text-align: left;
      }

      .input:focus {
        outline: none !important;
        box-shadow: none;
      }

      input[type="password"] {
        width: 100%;
        padding: 10px;
        height: 45px;
        background-color: #ffffff;
        border: 1px solid #ccc;

        @media (min-width: 300px) and (max-width: 630px) {
          width: 300px;
        }

        border-radius: 5px;
        background-size: 20px 15px;
        background-origin: content-box;
        background-repeat: no-repeat;

        &:focus {
          outline: none;
        }
      }

      .forgot-password {
        margin-top: 10px;
      }

      .input-group {
        display: flex;
        margin-top: 30px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-bottom: 20px;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 15px;

      .remember-me-input {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      .signup {
        color: #1885ad !important;
        margin-top: 10px;
        cursor: pointer;
        margin-bottom: 10px;
      }

      .btn-login {
        width: 100%;

        @media (min-width: 300px) and (max-width: 630px) {
          width: 300px;
        }
      }

      input[type="checkbox"] {
        background-color: #ffffff;
        position: relative;
        top: -1px;
        margin-right: 5px;
      }

      .sign-in-up-option {
        color: #1ba5a4;
        font-weight: 800;
        margin-top: 20px;
        margin-bottom: 5px;
      }

      .loader {
        margin-top: 10px;

        img {
          width: 30px;
        }
      }

      .social-media-list {
        list-style: none;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;
        padding-left: 0px;



        li {
          &.divider {
            margin-right: 10px;
          }

          a {
            display: flex;
            align-items: center;
            background: #fff;
            padding: 5px 10px;
            border-radius: 10px;
            border: 1px solid #ccc;

            img {
              width: 20px;
            }

            span {
              margin-top: 2px;
              margin-left: 3px;
              color: #000000;

              @media (min-width: 300px) and (max-width: 630px) {
                display: none;
              }
            }

            &:hover {
              background: #ededed;
              color: #fff;
            }
          }
        }
      }

      .dont-have-account {
        color: #8f95a4;

        a {
          color: #0884af;
          text-decoration: none;
        }
      }
    }
  }
}
</style>


