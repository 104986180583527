<template>
  <div class="widget-datepicker row">
    <datepicker :monday-first="true" :placeholder="left_input_placeholder" :disabled="left_input_disabled"
      :disabled-dates="left_input_disabled_dates" @selected="leftDateChange" :value="left_date_input"
      input-class="date-picker-input" wrapper-class="date-picker-wrapper"></datepicker>
    <div class="h-spacer"></div>
    <datepicker :monday-first="true" :placeholder="right_input_placeholder" :disabled="right_input_disabled"
      :disabled-dates="right_input_disabled_dates" @selected="rightDateChange" :value="right_date_input"
      input-class="date-picker-input" wrapper-class="date-picker-wrapper"></datepicker>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
export default {
  name: "widget-datepicker",
  components: {
    Datepicker,
  },
  props: {
    left_input_placeholder: {
      type: String,
      default: "",
    },
    right_input_placeholder: {
      type: String,
      default: "",
    },
    left_input_disabled: {
      type: Boolean,
      default: false,
    },
    right_input_disabled: {
      type: Boolean,
      default: false,
    },
    left_input_disabled_dates: {
      type: Object,
      default: () => { },
    },
    right_input_disabled_dates: {
      type: Object,
      default: () => { },
    },
    value: {
      type: Object,
      default: () => {
        return {
          to: new Date(),
          from: new Date(),
        };
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    left_date_input() {
      return this.value.from;
    },
    right_date_input() {
      return this.value.to;
    },
  },
  watch: {},
  mounted() {
    let vm = this;
  },
  methods: {
    leftDateChange(date) {
      let vm = this;
      let new_value = {
        from: date,
        to: vm.right_date_input,
      };
      vm.$emit("input", new_value);
    },
    rightDateChange(date) {
      let vm = this;
      let new_value = {
        from: vm.left_date_input,
        to: date,
      };
      vm.$emit("input", new_value);
    },
  },
};
</script>
<style lang="scss" scoped>
.widget-datepicker {
  padding: 0px 5px;
  border-radius: 5px;
  font-weight: 300;
  width: 100%;
  height: 30px;
  position: relative;
  background: #fff;
  border: 2px solid #dddddd;
  box-sizing: border-box;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .h-spacer {
    margin-right: 10px !important;
  }
}
</style>
<style lang="scss">
.date-picker-input {
  width: 100%;
  border: none;
  background: #f0f0f0;
  outline: none;
  border-radius: 3px;
  text-align: center;
  height: 20px !important;
  padding-top: 2px;
}
</style>
