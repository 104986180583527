<template>
  <div class="input-group">
    <div class="input-header" v-if="!readonly && _new">
      <div class="option">
        <input v-model="new_company" type="checkbox" id="sfga" />
        <span for="sfga">New Account</span>
      </div>
    </div>
    <div class="exist-company" v-if="!new_company">
      <widgetSearch class="search-input" icon="right" placeholder="Search Account"
        v-if="!miltiple && !readonly && !selected_company" v-model="search_company_input" :loading="is_loading">
      </widgetSearch>

      <widgetSearch class="search-input" icon="left" placeholder="Search Account" v-if="miltiple && !readonly"
        v-model="search_company_input" :loading="is_loading"></widgetSearch>

      <div class="selected-accounts-widget" v-if="selected_company">
        <div v-if="!miltiple" class="selected-company">
          <div class="content">
            <widgetProfile :title="selected_company.name" :subtitle="selected_company.category" file_name="" path=""
              :text_size="8"></widgetProfile>
          </div>
          <div class="option" v-if="!readonly">
            <button @click="removeSelectCompany()" class="btn-close">
              &times;
            </button>
          </div>
        </div>
        <div v-else class="profile-list">
          <div class="profile-item" v-for="(item, index) in selected_company" :key="index">
            <widgetProfile :title="item.name" :show_name="false" subtitle="" file_name="" path=""></widgetProfile>
            <button v-if="!readonly" @click="deleteSelectCompany(index)" class="btn-remove">
              &times;
            </button>
            <div class="small-spacer"></div>
          </div>
        </div>
      </div>

      <div class="search-result-widget" v-if="search_company_input">
        <div class="search-item" v-for="(item, index) in search_company_result" :key="index" @click="selectCompany(item)">
          <widgetProfile :title="item.name" :subtitle="item.category" file_name="" path="" :text_size="8"></widgetProfile>
        </div>
      </div>
    </div>
    <div class="new-company-field" v-if="new_company">
      <widgetInput type="text" @change="onNewAccount" v-model="company_name" placeholder=""></widgetInput>
    </div>
  </div>
</template>

<script>
export default {
  name: "widget_button",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    miltiple: {
      type: Boolean,
      default: false,
    },
    _new: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      search_company_input: "",
      is_loading: false,
      new_company: false,
      selected_company: null,
      search_company_result: [],
      company_name: "",
    };
  },
  watch: {
    loading: function (val) {
      this.is_loading = val;
    },
    search_company_input(query) {
      let vm = this;
      vm.searchCompany(query);
    },
    value: {
      handler: function (val) {
        let vm = this;
        vm.selected_company = val;
      },
      deep: true,
    },
  },
  mounted() {
    let vm = this;
    if (vm.miltiple) {
      vm.selected_company = [];
    } else {
      vm.selected_company = null;
    }
    if (vm.value) {
      vm.selected_company = vm.value;
    }
  },
  methods: {
    onNewAccount() {
      let vm = this;
      vm.$emit("onNewAccount", {
        enabled: vm.new_company,
        name: vm.company_name,
      });
    },
    selectCompany(item) {
      let vm = this;
      vm.search_company_input = "";
      if (vm.miltiple) {
        vm.selected_company.push(item);
        vm.$emit("input", vm.selected_company);
        vm.$emit("change", vm.selected_company);
      } else {
        vm.selected_company = item;
        vm.$emit("input", vm.selected_company);
        vm.$emit("change", vm.selected_company);
      }
    },
    deleteSelectCompany(index) {
      let vm = this;
      vm.selected_company.splice(index, 1);
      vm.$emit("input", vm.selected_company);
      vm.$emit("change", vm.selected_company);
    },
    removeSelectCompany() {
      let vm = this;
      vm.selected_company = null;
      vm.$emit("input", vm.selected_company);
      vm.$emit("change", vm.selected_company);
    },
    searchCompany(query) {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("INSTRUCTOR_SEARCH_COMPANY", { query: query, type: "all" })
        .then((responce) => {
          vm.is_loading = false;
          vm.search_company_result = responce.data;
        })
        .catch((error) => {
          vm.is_loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.input-group {
  .input-header {
    position: relative;

    .option {
      position: absolute;
      right: 0px;
      top: -24px;
      color: rgb(145, 145, 145);
    }
  }

  .exist-company {
    position: relative;

    .search-input {
      margin-bottom: 0px;
    }

    .selected-accounts-widget {
      .selected-company {
        border: 1px solid #ddd;
        border-radius: 5px;
        background: #fff;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;

        .content {
          display: flex;
          align-items: center;

          img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
        }

        .option {
          .btn-close {
            font-size: 14px;
            background: #7f0404;
            color: #fff;
            border-radius: 5px;
          }
        }
      }

      .profile-list {
        display: flex;
        flex-wrap: wrap;

        .profile-item {
          margin-bottom: 10px;
          position: relative;

          .small-spacer {
            margin-right: 5px;
          }

          .btn-remove {
            font-size: 16px;
            height: 18px;
            width: 18px;
            background: #7f0404;
            color: #fff;
            border-radius: 50%;
            position: absolute;
            top: -3px;
            right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 4px;
          }
        }
      }
    }

    .search-result-widget {
      position: absolute;
      top: 35px;
      height: 200px;
      width: 100%;
      background: #fff;
      z-index: 9999;
      box-shadow: 0px 0px 3px #ccc;
      border-radius: 5px;
      overflow-y: auto;

      .search-item {
        display: flex;
        align-items: center;
        padding: 4px 5px;
        border-bottom: 1px solid #ddd;
        cursor: pointer;

        &:hover {
          background: rgb(240, 240, 240);
        }

        img {
          width: 26px;
          height: 26px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
    }
  }

  .new-company-field {}
}
</style>
