<template>
  <div class="overlay">
    <div class="form-widget-modal section-modal">
      <div class="moder-header">
        <h4 v-if="!section_form.id">Create a new Section</h4>
        <h4 v-else>Update Section</h4>
        <button
          class="btn-section-delete"
          v-if="section_form.id"
          @click="deleteSection(section_form.id)"
        >
          <img src="@/assets/admin-icon/trush.svg" /> Delete
        </button>
      </div>
      <div class="modal-body">
        <div class="input-group">
          <label>Section name*</label>
          <input
            v-model="section_form.section_name"
            type="text"
            class="input-form"
          />
        </div>
      </div>
      <div class="modal-footer">
        <c-button
          class="btn-save"
          :loading="section_add_loading"
          @click="submitSection()"
          >Save</c-button
        >
        <button class="btn-cancel" @click="show_section_form = false">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "page-modal",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {},
  computed: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.form-widget-modal {
  width: 500px;
  position: relative;
  &.section-modal {
    .moder-header {
      display: flex;
      justify-content: space-between;
      .btn-section-delete {
        display: flex;
        align-items: center;
        img {
          width: 12px;
          margin-right: 3px;
          margin-top: -2px;
        }
      }
    }
  }
  .input-group {
    position: relative;
    .task-search-reasult {
      position: absolute;
      height: 200px;
      overflow-y: auto;
      z-index: 9999;
      background: #fff;
      box-shadow: 0px 0px 10px #a8a8a8;
      width: 100%;
      top: 62px;
      .task-item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        padding: 5px 10px;
        cursor: pointer;
        &:hover {
          background: rgb(241, 241, 241);
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
}
.search-account-item {
  padding: 10px 15px;
  display: block;
  cursor: pointer;
  &:hover {
    background: rgb(250, 250, 250);
  }
}
.selected-search-account-item {
  padding: 5px 12px;
  display: block;
  cursor: pointer;
  border: 1px solid #ddd;
  margin-top: 10px;
  border-radius: 15px;
  font-size: 11px;
  &:hover {
    background: rgb(250, 250, 250);
  }
}

.input-date {
  background: #fff;
}
</style>
