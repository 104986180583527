var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-managment h-full w-full p-2 bg-gray-100"},[(!_vm.isLoadingSchool)?_c('PageHeader',{scopedSlots:_vm._u([{key:"brand",fn:function(){return [_c('div',{staticClass:"page-brand flex items-center mr-3"},[_c('img',{staticClass:"icon mr-2",attrs:{"src":require("@/assets/images/menu/user-active.svg")}}),_c('span',{staticClass:"text-black"},[_vm._v("User management")])])]},proxy:true},{key:"path",fn:function(){return [_c('div',{staticClass:"nav-path-widget flex items-center"},[_c('div',{staticClass:"nav-path mr-4 text-green-600 cursor-pointer",on:{"click":function($event){return _vm.$router.push({
                    name: 'users-management'
                })}}},[_vm._v("All Schools > ")]),_c('div',{staticClass:"nav-path"},[_vm._v(_vm._s(_vm.school.company_info.name))])])]},proxy:true}],null,false,4138379981)}):_vm._e(),_c('div',{staticClass:"page-body"},[(!_vm.isLoadingSchool)?_c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-container-header"},[_c('widgetTabs',{attrs:{"tabs":[
                    {
                        title: 'Feed',
                        value: 'feed',
                    },
                    {
                        title: 'LIA',
                        value: 'lia',
                    },
                    {
                        title: 'Tasks',
                        value: 'tasks',
                    },
                    {
                        title: 'Instructor',
                        value: 'instructor',
                    },
                    {
                        title: 'Accounts',
                        value: 'accounts',
                        drobdown: [
                            {
                                title: 'Accounts',
                                value: 'accounts',
                            },
                            {
                                title: 'Account contact',
                                value: 'account_contact',
                            },
                            {
                                title: 'LIA contact',
                                value: 'lia_contact',
                            }
                        ]
                    },
                    {
                        title: 'School',
                        value: 'school',
                    },
                    {
                        title: 'Resumes',
                        value: 'resumes',
                    },
                    {
                        title: 'Candidates',
                        value: 'candidates',
                    },
                    {
                        title: 'Leads',
                        value: 'leads',
                    },
                    {
                        title: 'Reports',
                        value: 'reports',
                    },
                    {
                        title: 'Comunications',
                        value: 'comunications',
                    },
                ]},on:{"change":_vm.onTabChange},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})],1),_c('div',{staticClass:"page-container-body"},[(_vm.tab == 1)?_c('Feed',{attrs:{"school":_vm.school}}):_vm._e(),(_vm.tab == 3 && _vm.selecteTab == 'tasks')?_c('Tasks',{attrs:{"school":_vm.school}}):_vm._e(),(_vm.tab == 9 && _vm.selecteTab == 'leads')?_c('Leads',{attrs:{"school":_vm.school}}):_vm._e(),(_vm.tab == 5 && _vm.selecteTab == 'accounts')?_c('Accounts'):_vm._e(),(_vm.tab == 5 && _vm.selecteTab == 'account_contact')?_c('AccountContacts'):_vm._e(),(_vm.tab == 5 && _vm.selecteTab == 'lia_contact')?_c('LIAContacts'):_vm._e(),(_vm.tab == 6)?_c('School',{attrs:{"school":_vm.school}}):_vm._e()],1)]):_vm._e(),(_vm.isLoadingSchool)?_c('div',[_c('loading',{staticClass:"loader",attrs:{"height":20,"color":"#1ba5a4","opacity":1,"width":20,"active":_vm.isLoadingSchool,"is-full-page":false,"can-cancel":false,"background-color":"#fff"},on:{"update:active":function($event){_vm.isLoadingSchool=$event}}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }