<template>
  <label class="checkbox-container">{{ title }}
    <input type="checkbox" :disabled="disabled" :checked="value" class="widget_checkbox" @change="onChange" />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: "widget_checkbox",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Boolean],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() { },
  methods: {
    onChange(e) {
      let vm = this;
      vm.$emit("input", e.target.checked);
      vm.$emit("change", e);
    },
  },
};
</script>
<style lang="scss" scoped>
/* The checkbox-container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 18px;
  padding-top: 1px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
  background-color: #ddd;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
  background-color: #0085ff;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
