export const TASK_ADD_NEW_SECTION = "TASK_ADD_NEW_SECTION";
export const TASK_UPDATE_SECTION = "TASK_UPDATE_SECTION";
export const TASK_DELETE_SECTION = "TASK_DELETE_SECTION";
export const TASK_ADD_NEW_STORY = "TASK_ADD_NEW_STORY";
export const TASK_UPDATE_STORY = "TASK_UPDATE_STORY";
export const TASK_GET_ALL = "TASK_GET_ALL";
export const TASK_DELETE_STORY = "TASK_DELETE_STORY";
export const TASK_MOVE_STORY = "TASK_MOVE_STORY";
export const TASK_FILTER_BY_TAG = "TASK_FILTER_BY_TAG";
export const TASK_FILTER_BY_SORT = "TASK_FILTER_BY_SORT";
export const TASK_SEARCH = "TASK_SEARCH";
export const TASK_MAKE_COMPLETED = "TASK_MAKE_COMPLETED";