import {
    SITE_API,
    API,
    ADD_COMPANY_URL,
    ADD_EMPLOYEE_URL,
    GET_COMPANY_EMPLOYEES_URL,
    GET_COMPANY_INFO_URL,
    BUY_COMPANY_URL,
    GET_COMPANY_INFO_ADMIN_URL,
    ADD_COMPANY_MANAGER_INFO_URL,
    GET_ME_USER_COMPANY_URL,
    DELETE_COMPANY_ACCOUNT_URL,
    DELETE_COMPANY_URL,
    UPDATE_COMPANY_ACCOUNT_PASSWORD_URL,
    UPDATE_COMPANY_INFO_URL,
    UPDATE_MANAGER_INFO_URL,
    ADD_COMPANY_MEMBER_URL,
    GRANT_TO_BE_COMPANY_MEMBER_URL,
    ADD_PITCH_URL,
    SEARCH_FOR_COMPANY_MEMBERS_URL,
    REMOVE_PITCH_URL,
    UPDATE_PITCH_URL,
    SEARCH_PITCH_URL,
    REMOVE_MEMBER_FROM_PITCH_URL,
    REMOVE_MEMBER_FROM_COMPANY_URL,
    GET_CONSULTANT_ALL_DATA_URL,
    EXPORT_CONSULTANT_CV_URL,
    SAVE_CONSULTANT_INFO_URL,
    COMPANY_CONSULTANT_UPDATE_PROJECT_URL,
    COMPANY_CONSULTANT_ADD_NEW_PROJECT_URL,
    COMPANY_CONSULTANT_DELETE_PROJECT_URL,
    COMPANY_CONSULTANT_VERIFY_PROJECT_URL,
    COMPANY_CONSULTANT_ADD_SKILL_URL,
    COMPANY_CONSULTANT_DELETE_SKILL_URL,
    COMPANY_CONSULTANT_UPDATE_SKILL_URL,
    COMPANY_CONSULTANT_SAVE_SKILL_PRIOLITY_URL,
    COMPANY_CONSULTANT_UPDATE_EMPLOYMENT_URL,
    COMPANY_CONSULTANT_ADD_NEW_EMPLOYMENT_URL,
    COMPANY_CONSULTANT_DELETE_EMPLOYMENT_URL,
    COMPANY_CONSULTANT_VERIFY_EMPLOYMENT_URL,
    COMPANY_CONSULTANT_UPDATE_EDUCATION_URL,
    COMPANY_CONSULTANT_ADD_NEW_EDUCATION_URL,
    COMPANY_CONSULTANT_DELETE_EDUCATION_URL,
    COMPANY_CONSULTANT_UPDATE_TRAINING_URL,
    COMPANY_CONSULTANT_ADD_NEW_TRAINING_URL,
    COMPANY_CONSULTANT_DELETE_TRAINING_URL,
    COMPANY_CONSULTANT_DELETE_CERTIFICATION_URL,
    COMPANY_CONSULTANT_ADD_NEW_CERTIFICATION_URL,
    COMPANY_CONSULTANT_UPDATE_CERTIFICATION_URL,
    SEARCH_COMPANY_PROJECT_URL,
    COMPANY_INVITE_CONSULTANT_AS_MANAGER_URL,
    COMPANY_REQUEST_MARGER_PROFILE_TO_CONSULTANT_URL,
    COMPANY_ADD_NEW_ASSIGNMENT_URL,
    COMPANY_UPDATE_ASSIGNMENT_URL,
    COMPANY_ASSING_ASSINGMENT_URL,
    CONSULTANT_GET_ASSIGNMENT_URL,
    CONSULTANT_ACCEPT_ASSIGNMENT_URL,
    CONSULTANT_REJECT_ASSIGNMENT_URL,
    COMPANY_ADD_PROJECT_TO_PUBLIC_URL,
    COMPANY_REMOVE_PROJECT_TO_PUBLIC_URL,
    COMPANY_SEND_PITCH_URL,
    SEARCH_CUSTOMERS_MEMBERS_URL,
    GET_CUSTOMERS_URL,
    GET_SINGLE_PITCH_URL,
    CUSTOMER_REJECT_PITCH_URL,
    CUSTOMER_ACCEPT_PITCH_URL,
    CUSTOMER_ADD_PITCH_URL,
    COMPANY_ADD_SKILL_URL,
    COMPANY_SKILL_SAVE_PRIOLITY_URL,
    COMPANY_DELETE_SKILL_URL,
    COMPANY_EDIT_SKILL_URL,
    COMPANY_SEND_PITCH_VIA_EMAIL_URL,
    SEARCH_COMPANY_OUR_PROJECT_URL,
    SEARCH_ASIGNED_ASSIGNMENT_URL,
    GET_COMPANY_PROFILE_URL,
    COMPANY_ADD_NEW_CV_URL,
    COMPANY_APPLY_CV_URL,
    GET_USER_COMPANY_CV_URL,
    COMPANY_DOWNLOAD_CV_URL,
    COMPANY_UPDATE_CV_URL,
    COMPANY_REMOVE_CV_URL,
    COMPANY_CONSULTANT_SAVE_PRIOLITY_URL,
    COMPANY_ADD_NEW_CV_FILE_URL,
    COMPANY_UPDATE_CV_FILE_URL,
    COMPANY_ADD_NEW_FOLDER_URL,
    COMPANY_UPDATE_FOLDER_URL,
    COMPANY_GET_MY_CV_URL,
    COMPANY_CV_DELETE_FILE_FOLDER_URL,
    COMPANY_DOWNLOAD_CV_FILE_URL,
    ORGANIZATION_GET_TEAM_URL,
    ORGANIZATION_ADD_NEW_TEAM_URL,
    ORGANIZATION_UPDATE_TEAM_URL,
    ORGANIZATION_DELETE_TEAM_URL,
    ORGANIZATION_ADD_NEW_DEPARTMENT_URL,
    ORGANIZATION_UPDATE_DEPARTMENT_URL,
    ORGANIZATION_DELETE_DEPARTMENT_URL,
    ORGANIZATION_GET_TEAM_DATA_URL,
    ORGANIZATION_TEAM_REMOVE_MEMBER_URL,
    ORGANIZATION_ADD_NEW_TEAM_MEMBERS_URL,
    COMPANY_ADD_SELECTED_PROJECTS_TO_PUBLIC_URL,
    ORGANIZATION_SEARCH_DEPARTMENT_TEAM_URL,
    ORGANIZATION_SEARCH_TEAM_MEMBER_URL,
    ORGANIZATION_MOVE_TEAM_MEMBER_URL,
    COMPANY_UPLOAD_NEW_CV_URL,
    COMPANY_UPDATE_UPLOADED_CV_URL,
    COMPANY_GET_CONSULTANT_CV_URL,
    REMOVE_MANAGER_FROM_COMPANY_URL,
    COMPANY_DOWNLOAD_CVs_FILE_URL,
    COMPANY_DOWNLOAD_MILT_CVS_URL,
    COMPANY_CONSULTANT_CHANGE_PERMISSION_URL,
    COMPANY_DOWNLOAD_MILT_UPLOADED_CVs_URL,
    ADD_SCHOOL_MANAGER_URL,
    ADD_SCHOOL_STUDENT_URL,
    GET_ALL_SCHOOL_STUDENT_URL,
    INVITE_SCHOOL_STUDENT_URL,
    UPDATE_SCHOOL_STUDENT_URL,
    UPDATE_SCHOOL_MANAGER_URL,
    VALIDATE_STUDENT_IF_EXIST_IN_CLASS_URL,
    SEARCH_PUBLIC_INTERNSHIP_URL,
    COMPANY_CONSULTANT_SEND_REMAINDER_URL,
    SCHOOL_DROPDOWN_SEARCH_CLASS_URL,
    SCHOOL_DROPDOWN_ALL_CLASS_URL,
    ORGANIZATION_SEARCH_USER_IN_DEPARTMENT_URL,
    CONNECT_EXISTING_USER_TO_CLASS_URL,
    ORGANIZATION_CLOSE_TEAM_URL,
    COMPANY_TRANSFER_STUDENT_URL
} from "../variable";

import axios from 'axios'

import {
    ADD_COMPANY,
    GET_COMPANY_INFO,
    ADD_EMPLOYEE,
    GET_COMPANY_EMPLOYEES,
    BUY_COMPANY,
    GET_COMPANY_ADMIN_INFO,
    ADD_COMPANY_MANAGER_INFO,
    GET_ME_USER_COMPANY,
    DELETE_COMPANY_ACCOUNT,
    DELETE_COMPANY,
    UPDATE_COMPANY_ACCOUNT_PASSWORD,
    UPDATE_COMPANY_INFO,
    UPDATE_MANAGER_INFO,
    ADD_COMPANY_MEMBER,
    GRANT_TO_BE_COMPANY_MEMBER,
    ADD_PITCH,
    SEARCH_FOR_COMPANY_MEMBERS,
    REMOVE_PITCH,
    UPDATE_PITCH,
    SEARCH_PITCH,
    REMOVE_MEMBER_FROM_PITCH,
    REMOVE_MEMBER_FROM_COMPANY,
    GET_CONSULTANT_ALL_DATA,
    EXPORT_CONSULTANT_CV,
    SAVE_CONSULTANT_INFO,
    COMPANY_CONSULTANT_UPDATE_PROJECT,
    COMPANY_CONSULTANT_ADD_NEW_PROJECT,
    COMPANY_CONSULTANT_DELETE_PROJECT,
    COMPANY_CONSULTANT_VERIFY_PROJECT,
    COMPANY_CONSULTANT_ADD_SKILL,
    COMPANY_CONSULTANT_DELETE_SKILL,
    COMPANY_CONSULTANT_UPDATE_SKILL,
    COMPANY_CONSULTANT_SAVE_SKILL_PRIOLITY,
    COMPANY_CONSULTANT_UPDATE_EMPLOYMENT,
    COMPANY_CONSULTANT_ADD_NEW_EMPLOYMENT,
    COMPANY_CONSULTANT_DELETE_EMPLOYMENT,
    COMPANY_CONSULTANT_VERIFY_EMPLOYMENT,
    COMPANY_CONSULTANT_UPDATE_EDUCATION,
    COMPANY_CONSULTANT_ADD_NEW_EDUCATION,
    COMPANY_CONSULTANT_DELETE_EDUCATION,
    COMPANY_CONSULTANT_UPDATE_TRAINING,
    COMPANY_CONSULTANT_ADD_NEW_TRAINING,
    COMPANY_CONSULTANT_DELETE_TRAINING,
    COMPANY_CONSULTANT_DELETE_CERTIFICATION,
    COMPANY_CONSULTANT_ADD_NEW_CERTIFICATION,
    COMPANY_CONSULTANT_UPDATE_CERTIFICATION,
    REMOVE_MANAGER_FROM_COMPANY,
    SEARCH_COMPANY_PROJECT,
    COMPANY_INVITE_CONSULTANT_AS_MANAGER,
    COMPANY_REQUEST_MARGER_PROFILE_TO_CONSULTANT,
    COMPANY_ADD_NEW_ASSIGNMENT,
    COMPANY_UPDATE_ASSIGNMENT,
    COMPANY_ASSING_ASSINGMENT,
    CONSULTANT_GET_ASSIGNMENT,
    CONSULTANT_ACCEPT_ASSIGNMENT,
    CONSULTANT_REJECT_ASSIGNMENT,
    COMPANY_ADD_PROJECT_TO_PUBLIC,
    COMPANY_REMOVE_PROJECT_TO_PUBLIC,
    COMPANY_SEND_PITCH,
    SEARCH_CUSTOMERS_MEMBERS,
    GET_CUSTOMERS,
    GET_SINGLE_PITCH,
    CUSTOMER_REJECT_PITCH,
    CUSTOMER_ACCEPT_PITCH,
    CUSTOMER_ADD_PITCH,
    COMPANY_ADD_SKILL,
    COMPANY_SKILL_SAVE_PRIOLITY,
    COMPANY_DELETE_SKILL,
    COMPANY_EDIT_SKILL,
    COMPANY_SEND_PITCH_VIA_EMAIL,
    SEARCH_COMPANY_OUR_PROJECT,
    SEARCH_ASIGNED_ASSIGNMENT,
    GET_COMPANY_PROFILE,
    COMPANY_ADD_NEW_CV,
    COMPANY_APPLY_CV,
    GET_USER_COMPANY_CV,
    COMPANY_DOWNLOAD_CV,
    COMPANY_UPDATE_CV,
    COMPANY_REMOVE_CV,
    COMPANY_CONSULTANT_SAVE_PRIOLITY,
    COMPANY_ADD_NEW_CV_FILE,
    COMPANY_UPDATE_CV_FILE,
    COMPANY_ADD_NEW_FOLDER,
    COMPANY_UPDATE_FOLDER,
    COMPANY_GET_MY_CV,
    COMPANY_CV_DELETE_FILE_FOLDER,
    COMPANY_DOWNLOAD_CV_FILE,
    ORGANIZATION_GET_TEAM,
    ORGANIZATION_ADD_NEW_TEAM,
    ORGANIZATION_UPDATE_TEAM,
    ORGANIZATION_DELETE_TEAM,
    ORGANIZATION_ADD_NEW_DEPARTMENT,
    ORGANIZATION_UPDATE_DEPARTMENT,
    ORGANIZATION_DELETE_DEPARTMENT,
    ORGANIZATION_GET_TEAM_DATA,
    ORGANIZATION_TEAM_REMOVE_MEMBER,
    ORGANIZATION_ADD_NEW_TEAM_MEMBERS,
    COMPANY_ADD_SELECTED_PROJECTS_TO_PUBLIC,
    ORGANIZATION_SEARCH_DEPARTMENT_TEAM,
    ORGANIZATION_SEARCH_TEAM_MEMBER,
    ORGANIZATION_MOVE_TEAM_MEMBER,
    COMPANY_UPLOAD_NEW_CV,
    COMPANY_UPDATE_UPLOADED_CV,
    COMPANY_GET_CONSULTANT_CV,
    COMPANY_DOWNLOAD_CVs_FILE,
    COMPANY_DOWNLOAD_MILT_CVS,
    COMPANY_CONSULTANT_CHANGE_PERMISSION,
    COMPANY_DOWNLOAD_MILT_UPLOADED_CVs,
    ADD_SCHOOL_MANAGER,
    ADD_SCHOOL_STUDENT,
    GET_ALL_SCHOOL_STUDENT,
    INVITE_SCHOOL_STUDENT,
    UPDATE_SCHOOL_STUDENT,
    UPDATE_SCHOOL_MANAGER,
    VALIDATE_STUDENT_IF_EXIST_IN_CLASS,
    SEARCH_PUBLIC_INTERNSHIP,
    COMPANY_CONSULTANT_SEND_REMAINDER,
    SCHOOL_DROPDOWN_SEARCH_CLASS,
    SCHOOL_DROPDOWN_ALL_CLASS,
    ORGANIZATION_SEARCH_USER_IN_DEPARTMENT,
    CONNECT_EXISTING_USER_TO_CLASS,
    ORGANIZATION_CLOSE_TEAM,
    COMPANY_TRANSFER_STUDENT
} from "../Actions/company";
import router from "../../router";

const state = {
    company: null,
    employees: null
};

const actions = {
    [COMPANY_TRANSFER_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_TRANSFER_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [ORGANIZATION_CLOSE_TEAM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_CLOSE_TEAM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [CONNECT_EXISTING_USER_TO_CLASS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CONNECT_EXISTING_USER_TO_CLASS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [ORGANIZATION_SEARCH_USER_IN_DEPARTMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_SEARCH_USER_IN_DEPARTMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [SCHOOL_DROPDOWN_SEARCH_CLASS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + SCHOOL_DROPDOWN_SEARCH_CLASS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [SCHOOL_DROPDOWN_ALL_CLASS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + SCHOOL_DROPDOWN_ALL_CLASS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [COMPANY_CONSULTANT_SEND_REMAINDER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_SEND_REMAINDER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [SEARCH_PUBLIC_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + SEARCH_PUBLIC_INTERNSHIP_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [VALIDATE_STUDENT_IF_EXIST_IN_CLASS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + VALIDATE_STUDENT_IF_EXIST_IN_CLASS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [UPDATE_SCHOOL_MANAGER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_SCHOOL_MANAGER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [UPDATE_SCHOOL_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_SCHOOL_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INVITE_SCHOOL_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INVITE_SCHOOL_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [GET_ALL_SCHOOL_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == 'init' ? SITE_API + getters.get_crm_prefix + GET_ALL_SCHOOL_STUDENT_URL : payload.next_page_url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [ADD_SCHOOL_MANAGER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ADD_SCHOOL_MANAGER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [ADD_SCHOOL_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ADD_SCHOOL_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [COMPANY_DOWNLOAD_MILT_UPLOADED_CVs]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_DOWNLOAD_MILT_UPLOADED_CVs_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
            responseType: 'blob',
        });
    },
    [COMPANY_CONSULTANT_CHANGE_PERMISSION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_CHANGE_PERMISSION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [COMPANY_DOWNLOAD_MILT_CVS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_DOWNLOAD_MILT_CVS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
            responseType: 'blob',
        });
    },
    [COMPANY_DOWNLOAD_CVs_FILE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_DOWNLOAD_CVs_FILE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
            responseType: 'blob',
        });
    },
    [REMOVE_MANAGER_FROM_COMPANY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + REMOVE_MANAGER_FROM_COMPANY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_GET_CONSULTANT_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_GET_CONSULTANT_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_UPLOAD_NEW_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_UPLOAD_NEW_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_UPDATE_UPLOADED_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_UPDATE_UPLOADED_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_REMOVE_PROJECT_TO_PUBLIC]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_REMOVE_PROJECT_TO_PUBLIC_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_SEARCH_DEPARTMENT_TEAM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_SEARCH_DEPARTMENT_TEAM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_SEARCH_TEAM_MEMBER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_SEARCH_TEAM_MEMBER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_MOVE_TEAM_MEMBER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_MOVE_TEAM_MEMBER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_ADD_SELECTED_PROJECTS_TO_PUBLIC]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_ADD_SELECTED_PROJECTS_TO_PUBLIC_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_ADD_NEW_TEAM_MEMBERS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_ADD_NEW_TEAM_MEMBERS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_TEAM_REMOVE_MEMBER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_TEAM_REMOVE_MEMBER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_GET_TEAM_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_GET_TEAM_DATA_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_DELETE_DEPARTMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_DELETE_DEPARTMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_ADD_NEW_DEPARTMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_ADD_NEW_DEPARTMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_UPDATE_DEPARTMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_UPDATE_DEPARTMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_GET_TEAM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_GET_TEAM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_ADD_NEW_TEAM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_ADD_NEW_TEAM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_UPDATE_TEAM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_UPDATE_TEAM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ORGANIZATION_DELETE_TEAM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ORGANIZATION_DELETE_TEAM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_DOWNLOAD_CV_FILE]: ({
        commit,
        dispatch,
    }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_DOWNLOAD_CV_FILE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
            responseType: 'blob',
        });
    },
    [COMPANY_CV_DELETE_FILE_FOLDER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CV_DELETE_FILE_FOLDER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_GET_MY_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_GET_MY_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_ADD_NEW_CV_FILE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_ADD_NEW_CV_FILE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_UPDATE_CV_FILE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_UPDATE_CV_FILE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_ADD_NEW_FOLDER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_ADD_NEW_FOLDER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_UPDATE_FOLDER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_UPDATE_FOLDER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_SAVE_PRIOLITY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_SAVE_PRIOLITY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_UPDATE_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_UPDATE_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_REMOVE_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_REMOVE_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_DOWNLOAD_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_DOWNLOAD_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
            responseType: 'blob',
        });
    },
    [GET_USER_COMPANY_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + GET_USER_COMPANY_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_APPLY_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_APPLY_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [GET_COMPANY_PROFILE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + GET_COMPANY_PROFILE_URL,
            method: "POST",
            data: validatePayloadData(payload),
        });
    },
    [COMPANY_ADD_NEW_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_ADD_NEW_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [SEARCH_ASIGNED_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + SEARCH_ASIGNED_ASSIGNMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [SEARCH_COMPANY_OUR_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + SEARCH_COMPANY_OUR_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_SEND_PITCH_VIA_EMAIL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_SEND_PITCH_VIA_EMAIL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_ADD_SKILL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_ADD_SKILL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_SKILL_SAVE_PRIOLITY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_SKILL_SAVE_PRIOLITY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_DELETE_SKILL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_DELETE_SKILL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_EDIT_SKILL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_EDIT_SKILL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [CUSTOMER_ADD_PITCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CUSTOMER_ADD_PITCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [CUSTOMER_REJECT_PITCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CUSTOMER_REJECT_PITCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [CUSTOMER_ACCEPT_PITCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CUSTOMER_ACCEPT_PITCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [GET_SINGLE_PITCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + GET_SINGLE_PITCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_SEND_PITCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_SEND_PITCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [SEARCH_CUSTOMERS_MEMBERS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + SEARCH_CUSTOMERS_MEMBERS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [GET_CUSTOMERS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + GET_CUSTOMERS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_ADD_PROJECT_TO_PUBLIC]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_ADD_PROJECT_TO_PUBLIC_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [CONSULTANT_ACCEPT_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CONSULTANT_ACCEPT_ASSIGNMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [CONSULTANT_REJECT_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CONSULTANT_REJECT_ASSIGNMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [CONSULTANT_GET_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CONSULTANT_GET_ASSIGNMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_ASSING_ASSINGMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_ASSING_ASSINGMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_ADD_NEW_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_ADD_NEW_ASSIGNMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_UPDATE_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_UPDATE_ASSIGNMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_INVITE_CONSULTANT_AS_MANAGER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_INVITE_CONSULTANT_AS_MANAGER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_REQUEST_MARGER_PROFILE_TO_CONSULTANT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_REQUEST_MARGER_PROFILE_TO_CONSULTANT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [SEARCH_COMPANY_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + SEARCH_COMPANY_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_DELETE_CERTIFICATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_DELETE_CERTIFICATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_ADD_NEW_CERTIFICATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_ADD_NEW_CERTIFICATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_UPDATE_CERTIFICATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_UPDATE_CERTIFICATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_UPDATE_EDUCATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_UPDATE_EDUCATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_ADD_NEW_EDUCATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_ADD_NEW_EDUCATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_DELETE_EDUCATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_DELETE_EDUCATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_UPDATE_TRAINING]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_UPDATE_TRAINING_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_ADD_NEW_TRAINING]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_ADD_NEW_TRAINING_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_DELETE_TRAINING]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_DELETE_TRAINING_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_UPDATE_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_UPDATE_EMPLOYMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_ADD_NEW_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_ADD_NEW_EMPLOYMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_DELETE_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_DELETE_EMPLOYMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_VERIFY_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_VERIFY_EMPLOYMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_SAVE_SKILL_PRIOLITY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_SAVE_SKILL_PRIOLITY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_ADD_SKILL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_ADD_SKILL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_DELETE_SKILL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_DELETE_SKILL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_UPDATE_SKILL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_UPDATE_SKILL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_UPDATE_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_UPDATE_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_ADD_NEW_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_ADD_NEW_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_DELETE_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_DELETE_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [COMPANY_CONSULTANT_VERIFY_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + COMPANY_CONSULTANT_VERIFY_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [SAVE_CONSULTANT_INFO]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + SAVE_CONSULTANT_INFO_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [EXPORT_CONSULTANT_CV]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + EXPORT_CONSULTANT_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                Authorization: "Bearer " + sessionStorage.siteAuthToken
            },
            responseType: 'blob',
        });
        request.then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            if (payload.type == 'pdf') {
                link.setAttribute('download', payload.names + '.pdf'); //or any other extension
            } else if (payload.type == 'json') {
                link.setAttribute('download', payload.names + '.json'); //or any other extension
            } else if (payload.type == 'docx') {
                link.setAttribute('download', payload.names + '.docx'); //or any other extension
            }
            document.body.appendChild(link);
            link.click();
        })
            .catch(error => {
                console.log(error);
            });
        return request;
    },
    [GET_CONSULTANT_ALL_DATA]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + GET_CONSULTANT_ALL_DATA_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });

        request.then(response => {
            dispatch("LOADER_CLOSE");
        }).catch(error => {
            dispatch("LOADER_CLOSE");
        });

        return request;
    },
    [REMOVE_MEMBER_FROM_COMPANY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + REMOVE_MEMBER_FROM_COMPANY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [REMOVE_MEMBER_FROM_PITCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + REMOVE_MEMBER_FROM_PITCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [SEARCH_PITCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + SEARCH_PITCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [UPDATE_PITCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_PITCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [REMOVE_PITCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + REMOVE_PITCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [SEARCH_FOR_COMPANY_MEMBERS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + SEARCH_FOR_COMPANY_MEMBERS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [ADD_PITCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ADD_PITCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [GRANT_TO_BE_COMPANY_MEMBER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + GRANT_TO_BE_COMPANY_MEMBER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        }).then(response => {

            dispatch("GET_ME_PROFILE_DATA");
        })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);

            });
    },
    [ADD_COMPANY_MEMBER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ADD_COMPANY_MEMBER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        });
    },
    [DELETE_COMPANY_ACCOUNT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_COMPANY_ACCOUNT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        });
    },
    [DELETE_COMPANY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_COMPANY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        });
    },
    [UPDATE_COMPANY_ACCOUNT_PASSWORD]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_COMPANY_ACCOUNT_PASSWORD_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        });
    },
    [UPDATE_COMPANY_INFO]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_COMPANY_INFO_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        });
    },
    [UPDATE_MANAGER_INFO]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_MANAGER_INFO_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        });
    },
    [GET_ME_USER_COMPANY]: ({ commit, dispatch, getters }, payload) => {
        var request = axios({
            url: SITE_API + getters.get_crm_prefix + GET_ME_USER_COMPANY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
        return request;
    },
    [ADD_COMPANY_MANAGER_INFO]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ADD_COMPANY_MANAGER_INFO_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        });
    },
    [GET_COMPANY_INFO]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + GET_COMPANY_INFO_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        }).then(response => {
            commit(GET_COMPANY_INFO, response)
        }).catch(error => {
            console.log(error.response)
        });
    },
    [GET_COMPANY_ADMIN_INFO]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + GET_COMPANY_INFO_ADMIN_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        }).then(response => {
            commit(GET_COMPANY_ADMIN_INFO, response)
        }).catch(error => {
            console.log(error.response)
        });
    },
    [BUY_COMPANY]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + BUY_COMPANY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        }).then(response => {
            router.push({
                name: 'company-admin',
                params: {
                    id: payload.company
                }
            })
        }).catch(error => {
            console.log(error)
        });
    },
    [GET_COMPANY_EMPLOYEES]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + GET_COMPANY_EMPLOYEES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        }).then(response => {
            commit(GET_COMPANY_EMPLOYEES, response)
        }).catch(error => {
            console.log(error.response)
        });
    },
    [ADD_COMPANY]: ({ commit, dispatch, getters }, payload) => {

        axios({
            url: SITE_API + getters.get_crm_prefix + ADD_COMPANY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        }).then(response => {
            router.push({
                name: 'company-profile',
                params: {
                    id: response.data.id
                }
            });
        }).catch(error => {
            console.log(error.response)
        });

    },
    [ADD_EMPLOYEE]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + ADD_EMPLOYEE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }

        }).then(response => { }).catch(error => {
            console.log(error.response)
        });

    },

};

const mutations = {

    [GET_COMPANY_INFO]: (state, payload) => {
        state.company = payload.data
    },

    [GET_COMPANY_EMPLOYEES]: (state, payload) => {
        state.employees = payload.data
    },

};

const getters = {
    getCompany(state) {
        return state.company;
    },
    getEmployees(state) {
        return state.employees;
    }

};

export default {
    state,
    getters,
    actions,
    mutations
};