let host = window.location.host;
let SITE_API__ = "";
let API__ = "";
let FILE_BASE_URL__ = "";
let FILE_MAKER_SERVER_BASE_URL__ = "";

if (host == "admin.meritios.com") {
    SITE_API__ = "https://api.meritios.com/api";
    API__ = "https://crm.api.meritios.com/api";
    FILE_BASE_URL__ = "https://storage.googleapis.com/consultified_media/production/";
    FILE_MAKER_SERVER_BASE_URL__ = "https://api.meritios.com/node";
}
else if (host == "admin.dev.meritios.com") {
    SITE_API__ = "https://api.dev.meritios.com/api";
    API__ = "https://crm.api.dev.meritios.com/api";
    FILE_BASE_URL__ = "https://storage.googleapis.com/consultified_media/production/";
    FILE_MAKER_SERVER_BASE_URL__ = "https://api.dev.meritios.com/node";
}
else if (host == "admin.staging.meritios.com") {
    SITE_API__ = "https://api.staging.meritios.com/api";
    API__ = "https://crm.api.staging.meritios.com/api";
    FILE_BASE_URL__ = "https://storage.googleapis.com/consultified_media/production/";
    FILE_MAKER_SERVER_BASE_URL__ = "https://api.dev.meritios.com/node";
}
else if (host.startsWith("localhost")) {
    SITE_API__ = "https://api.dev.meritios.com/api";
    API__ = "https://crm.api.dev.meritios.com/api";
    FILE_BASE_URL__ = "https://storage.googleapis.com/consultified_media/production/";
    FILE_MAKER_SERVER_BASE_URL__ = "https://api.dev.meritios.com/node";
}

export const SITE_API_ = SITE_API__;
export const API_ = API__;
export const FILE_BASE_URL_ = FILE_BASE_URL__;
export const FILE_MAKER_SERVER_BASE_URL_ = FILE_MAKER_SERVER_BASE_URL__;
