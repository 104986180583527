import axios from "axios";
import { API, INVITE_USER_VIA_EMAIL_URL, SEND_CONTACT_MAIL_URL, SEND_INVESTOR_MAIL_URL } from "../variable";
import { SEND_CONTACT_MAIL, SEND_INVESTOR_MAIL } from "../Actions/landing";


const state = {};

const mutations = {};

const actions = {
    [SEND_CONTACT_MAIL]: ({ commit, dispatch, getters }, payload) => {
        axios({
                url: API + getters.get_crm_prefix + SEND_CONTACT_MAIL_URL,
                method: "POST",
                data: validatePayloadData(payload),
                headers: { Authorization: "Bearer " + sessionStorage.authToken }
            })
            .then(response => {
                dispatch("SET_INFO", { state: true, message: "Email successfully sent.", title: "CONTACT EMAIL" });
            })
            .catch(error => {
                dispatch("SET_INFO", { state: true, message: "Error occured.", title: "CONTACT EMAIL" });
            });
    },
    [SEND_INVESTOR_MAIL]: ({ commit, dispatch, getters }, payload) => {
        axios({
                url: API + getters.get_crm_prefix + SEND_INVESTOR_MAIL_URL,
                method: "POST",
                data: validatePayloadData(payload),
                headers: { Authorization: "Bearer " + sessionStorage.authToken }
            })
            .then(response => {
                dispatch("SET_INFO", { state: true, message: "Email successfully sent.", title: "CONTACT EMAIL" });
            })
            .catch(error => {
                console.log(error.response);
                dispatch("SET_INFO", { state: true, message: "Error occured.", title: "CONTACT EMAIL" });
            });
    },
};

const getters = {};

export default {
    mutations,
    actions,
    getters,
    state
};