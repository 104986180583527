import {
    GET_ME_PROFILE_DATA,
    GET_ME_PROFILE,
    ADD_EXPERIENCE,
    UPDATE_EXPERIENCE,
    DELETE_EXPERIENCE,
    GRANT_PERMISSION,
    ADD_PORTFOLIO,
    UPDATE_PORTFOLIO,
    DELETE_PORTFOLIO,
    ADD_EMPLOYMENT,
    UPDATE_EMPLOYMENT,
    DELETE_EMPLOYMENT,
    ADD_QUALIFICATION,
    UPDATE_QUALIFICATION,
    DELETE_QUALIFICATION,
    ADD_EDUCATION,
    UPDATE_EDUCATION,
    DELETE_EDUCATION,
    VIEW_EDUCATION,
    VIEW_EXPERIENCE,
    VIEW_PORTFOLIO,
    VIEW_EMPLOYMENT,
    VIEW_QUALIFICATION,
    PUSH_EMPLOYMENT,
    SAVE_PROJECT,
    SAVE_EMPLOYMENT,
    SEARCH_FOR_PROJECT_MEMBERS,
    CLEAR_PROJECT_MEMBERS,
    GET_ME_DASHBOARD_DATA,
    INVITE_USER_VIA_PROJECT,
    ADD_SKILL,
    VERIFY_PROJECT,
    CONFIRM_VERIFY_PROJECT,
    EDIT_PROJECT_ITEM,
    DELETE_SKILL,
    EDIT_SKILL,
    EDIT_PROJECT_MEMBERS,
    GET_NOTIFICATION_DATA,
    ALLOW_TOBE_MEMBER,
    ALLOW_TOBE_EMPLOYMENT_MEMBER,
    DECLINE_TOBE_MEMBER,
    EDIT_PROFILE_INFO,
    CHANGE_PASSWORD,
    EDIT_QUALIFICATION_ITEM,
    SAVE_CERTIFICATION,
    DELETE_QUALIFICATION_ITEM,
    EDIT_EMPLOYMENT_ITEM,
    DELETE_PROJECT,
    INVITE_USER_VIA_EMAIL,
    CONFIRM_VERIFY_EMPLOYMENT,
    VERIFY_EMPLOYMENT,
    TITLE_BAR_INFO,
    SET_TITLE_BAR_INFO,
    POST_PROJECTS,
    EXPORT_CV,
    RESET_NOTIFICATION,
    REQUEST_TO_VIEW_PROFILE,
    GRANT_REQUEST_TO_VIEW_PROFILE,
    CHECK_REQUEST_TO_VIEW_PROFILE,
    RESET,
    DELETE_ACCOUNT,
    GET_CONSULTANT_DATA,
    AI_ACCOUNT_CHANGE_PASSWORD,
    MAIN_SEARCH,
    ACCEPT_ONLY_PROJECT,
    ACCEPT_ONLY_EMPLOYMENT,
    ACCOUNT_PROJECT_GET_ALL,
    VALIDATE_TOKEN,
    GET_ALL_USER_INFO,
    SUBMIT_SETTING,
    GET_SUBMIT_SETTING,
    USER_CHECK_WIZARD_STATUS,
    GET_SCHOOLS,
    GET_SCHOOL,
    DELETE_SCHOOL
} from "../Actions/user";

import { eventBus } from "../../main";

import router from "../../router";

import {
    API,
    SITE_API,
    GET_ME_PROFILE_DATA_URL,
    GET_ME_PROFILE_URL,
    ADD_EXPERIENCE_URL,
    UPDATE_EXPERIENCE_URL,
    DELETE_EXPERIENCE_URL,
    ADD_PORTFOLIO_URL,
    UPDATE_PORTFOLIO_URL,
    DELETE_PORTFOLIO_URL,
    ADD_EMPLOYMENT_URL,
    UPDATE_EMPLOYMENT_URL,
    DELETE_EMPLOYMENT_URL,
    ADD_QUALIFICATION_URL,
    UPDATE_QUALIFICATION_URL,
    DELETE_QUALIFICATION_URL,
    ADD_EDUCATION_URL,
    UPDATE_EDUCATION_URL,
    DELETE_EDUCATION_URL,
    VIEW_EDUCATION_URL,
    VIEW_EXPERIENCE_URL,
    VIEW_PORTFOLIO_URL,
    VIEW_EMPLOYMENT_URL,
    VIEW_QUALIFICATION_URL,
    SAVE_PROJECT_URL,
    SAVE_EMPLOYMENT_URL,
    SEARCH_FOR_PROJECT_MEMBERS_URL,
    GET_ME_DASHBOARD_DATA_URL,
    INVITE_USER_VIA_PROJECT_URL,
    VERIFY_PROJECT_URL,
    ADD_SKILL_URL,
    EDIT_PROJECT_ITEM_URL,
    GRANT_PERMISSION_URL,
    DELETE_SKILL_URL,
    EDIT_SKILL_URL,
    EDIT_PROJECT_MEMBERS_URL,
    GET_NOTIFICATION_DATA_URL,
    ALLOW_TOBE_MEMBER_URL,
    ALLOW_TOBE_EMPLOYMENT_MEMBER_URL,
    DECLINE_TOBE_MEMBER_URL,
    EDIT_PROFILE_INFO_URL,
    CONFIRM_VERIFY_PROJECT_URL,
    CHANGE_PASSWORD_URL,
    EDIT_QUALIFICATION_ITEM_URL,
    SAVE_CERTIFICATION_URL,
    DELETE_QUALIFICATION_ITEM_URL,
    EDIT_EMPLOYMENT_ITEM_URL,
    DELETE_PROJECT_URL,
    INVITE_USER_VIA_EMAIL_URL,
    CONFIRM_VERIFY_EMPLOYMENT_URL,
    VERIFY_EMPLOYMENT_URL,
    TITLE_BAR_INFO_URL,
    EXPORT_CV_URL,
    RESET_NOTIFICATION_URL,
    REQUEST_TO_VIEW_PROFILE_URL,
    GRANT_REQUEST_TO_VIEW_PROFILE_URL,
    CHECK_REQUEST_TO_VIEW_PROFILE_URL,
    DELETE_ACCOUNT_URL,
    AI_ACCOUNT_CHANGE_PASSWORD_URL,
    MAIN_SEARCH_URL,
    ACCEPT_ONLY_PROJECT_URL,
    ACCEPT_ONLY_EMPLOYMENT_URL,
    ACCOUNT_PROJECT_GET_ALL_URL,
    VALIDATE_TOKEN_URL,
    GET_ALL_USER_INFO_URL,
    SUBMIT_SETTING_URL,
    GET_SUBMIT_SETTING_URL,
    USER_CHECK_WIZARD_STATUS_URL,
    GET_SCHOOLS_URL,
    GET_SCHOOL_URL,
    DELETE_SCHOOL_URL
} from "../variable";

import axios from "axios";

const state = {
    meProfileData: {
        employment: [],
        projects: [],
        profile: {},
        user: {},
        notifications: [],
        skills: [],
        educations: [],
        trainings: [],
        certifications: []
    },
    toolbarInfos: { notifications: [], messages: 0, user: {} },
    meDashBoardData: null,
    educations: [],
    experiences: [],
    portfolios: [],
    employments: [],
    qualifications: [],
    searchedpeople: [],
    invitationData: {},
    profile: { employment: [], profile: {}, user: {}, isAllowed: false },
    notification: {},
    subscription: {}
};

const mutations = {
    [RESET]: (state, payload) => {
        state.meProfileData = { employment: [], profile: {}, user: {} }
        state.toolbarInfos = { notifications: [], messages: 0, user: {} }
    },
    [POST_PROJECTS]: (state, payload) => {
        state.meProfileData.projects = payload
    },
    [GET_NOTIFICATION_DATA]: (state, payload) => {
        if (payload.project) {
            payload.project.country_id = 0
            payload.project.city_id = 0
        } else if (payload.employment) {
            payload.employment.country_id = 0
            payload.employment.city_id = 0
        }
        state.notification = payload;
    },
    [TITLE_BAR_INFO]: (state, payload) => {
        state.toolbarInfos = payload;
    },
    [SET_TITLE_BAR_INFO]: (state, payload) => {
        state.toolbarInfos.messages = payload.messages
    },
    [GET_ME_PROFILE]: (state, payload) => {
        state.profile = payload;
    },
    [GET_ME_PROFILE_DATA]: (state, payload) => {
        state.meProfileData = payload;
    },
    [INVITE_USER_VIA_PROJECT]: (state, payload) => {
        state.invitationData = payload;
    },
    [GET_ME_DASHBOARD_DATA]: (state, payload) => {
        state.meDashBoardData = payload;
    },
    [VIEW_EDUCATION]: (state, payload) => {
        state.educations = payload;
    },
    [VIEW_EXPERIENCE]: (state, payload) => {
        state.experiences = payload;
    },
    [VIEW_PORTFOLIO]: (state, payload) => {
        state.portfolios = payload;
    },
    [VIEW_EMPLOYMENT]: (state, payload) => {
        state.employments = payload;
    },
    [PUSH_EMPLOYMENT]: (state, payload) => {
        state.employments.push(payload);
    },
    [VIEW_QUALIFICATION]: (state, payload) => {
        state.qualifications = payload;
    },
    [SEARCH_FOR_PROJECT_MEMBERS]: (state, payload) => {
        state.searchedpeople = payload
    },
    ["PUSH_SUBSCRIPTION"]: (state, payload) => {
        state.subscription = payload
    }
};

const actions = {
    [DELETE_SCHOOL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_SCHOOL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.authToken }
        });
    },
    [GET_SCHOOL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + GET_SCHOOL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.authToken }
        });
    },
    [GET_SCHOOLS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == 'init' ? SITE_API + getters.get_crm_prefix + GET_SCHOOLS_URL : payload.next_page_url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.authToken }
        });
    },
    [USER_CHECK_WIZARD_STATUS]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API + USER_CHECK_WIZARD_STATUS_URL,
            method: "POST",
            data: payload,
            headers: { Authorization: "Bearer " + sessionStorage.authToken }
        });
    },
    [GET_SUBMIT_SETTING]: ({ commit, dispatch }, payload) => {
        return axios({
            url: SITE_API + GET_SUBMIT_SETTING_URL,
            method: "POST",
            data: payload,
            headers: { Authorization: "Bearer " + sessionStorage.authToken }
        });
    },
    [SUBMIT_SETTING]: ({ commit, dispatch }, payload) => {
        return axios({
            url: SITE_API + SUBMIT_SETTING_URL,
            method: "POST",
            data: payload,
            headers: { Authorization: "Bearer " + sessionStorage.authToken }
        });
    },
    [GET_ALL_USER_INFO]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload != null ? SITE_API + "/" + payload.prefix + GET_ALL_USER_INFO_URL : SITE_API + getters.get_crm_prefix + GET_ALL_USER_INFO_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [VALIDATE_TOKEN]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + VALIDATE_TOKEN_URL,
            method: "POST",
            data: payload,
            headers: { Authorization: "Bearer " + payload.crm_token }
        });
    },
    [ACCOUNT_PROJECT_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + ACCOUNT_PROJECT_GET_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
        return request;
    },
    [ACCEPT_ONLY_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + ACCEPT_ONLY_EMPLOYMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("LOADER_CLOSE");
                router.push({ name: 'consultant-profile' });
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);
            });
    },
    [ACCEPT_ONLY_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + ACCEPT_ONLY_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("LOADER_CLOSE");
                router.push({ name: 'consultant-profile' });
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);
            });
    },
    [MAIN_SEARCH]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + MAIN_SEARCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
        return request;
    },
    [AI_ACCOUNT_CHANGE_PASSWORD]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + AI_ACCOUNT_CHANGE_PASSWORD_URL,
            method: "POST",
            data: validatePayloadData(payload),
        });
        return request;
    },
    ["PUSH_SUBSCRIPTION"]: ({ commit, dispatch, getters }, payload) => {
        console.log("from a good subscription", payload)
        commit("PUSH_SUBSCRIPTION", payload)
    },
    [GET_ME_PROFILE]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: SITE_API + GET_ME_PROFILE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
        request.then(response => {
            commit(GET_ME_PROFILE, response.data);
        }).catch(error => {
            console.log(error.response);
        });
        return request;
    },
    [TITLE_BAR_INFO]: ({ commit, dispatch, getters }) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + TITLE_BAR_INFO_URL,
            method: "POST",
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                commit(TITLE_BAR_INFO, response.data);
            })
            .catch(error => {
                console.log(error.response);
            });
    },
    [SET_TITLE_BAR_INFO]: ({ commit, dispatch, getters }, payload) => {
        commit(SET_TITLE_BAR_INFO, payload);
    },
    [ALLOW_TOBE_MEMBER]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + ALLOW_TOBE_MEMBER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("LOADER_CLOSE");
                router.push({ name: 'consultant-profile' });
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);
            });
    },
    [ALLOW_TOBE_EMPLOYMENT_MEMBER]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + ALLOW_TOBE_EMPLOYMENT_MEMBER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("LOADER_CLOSE");
                router.push({ name: 'consultant-profile' });
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);
            });
    },
    [REQUEST_TO_VIEW_PROFILE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + REQUEST_TO_VIEW_PROFILE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [GRANT_REQUEST_TO_VIEW_PROFILE]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + GRANT_REQUEST_TO_VIEW_PROFILE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("GET_ME_PROFILE_DATA")
                dispatch("SET_INFO", { state: true, message: "Permission Granted", title: "Profile View Request" });
            })
            .catch(error => {
                console.log(error.response);
                dispatch("SET_INFO", { state: true, message: "Error Occurred!!!", title: "Profile View Request" });
            });
    },
    [CHECK_REQUEST_TO_VIEW_PROFILE]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + CHECK_REQUEST_TO_VIEW_PROFILE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => { })
            .catch(error => {
                console.log(error.response);
            });
    },
    [RESET_NOTIFICATION]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + RESET_NOTIFICATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("TITLE_BAR_INFO");
            })
            .catch(error => {
                console.log(error.response);
            });
    },
    [DECLINE_TOBE_MEMBER]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + DECLINE_TOBE_MEMBER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("GET_ME_PROFILE_DATA")
            })
            .catch(error => {
                console.log(error.response);
            });
    },
    [GET_ME_PROFILE_DATA]: ({ commit, dispatch, getters }) => {
        dispatch("LOADER_START");
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + GET_ME_PROFILE_DATA_URL,
            method: "POST",
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });

        request.then(response => {
            commit(GET_ME_PROFILE_DATA, response.data);
            dispatch("LOADER_CLOSE");
        })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error);
            });
        return request;
    },
    [GET_CONSULTANT_DATA]: ({ commit, dispatch, getters }) => {
        dispatch("LOADER_START");
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + GET_ME_PROFILE_DATA_URL,
            method: "POST",
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });

        request.then(response => {
            dispatch("LOADER_CLOSE");
        })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error);
            });
        return request;
    },
    [GET_NOTIFICATION_DATA]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + GET_NOTIFICATION_DATA_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                commit(GET_NOTIFICATION_DATA, response.data);
                dispatch("LOADER_CLOSE");
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);
            });
    },
    [GET_ME_DASHBOARD_DATA]: ({ commit, dispatch, getters }) => {
        dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + CHANGE_PASSWORD_URL,
            method: "POST",
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                commit(GET_ME_DASHBOARD_DATA, response.data);
                dispatch("TITLE_BAR_INFO");
                dispatch("LOADER_CLOSE");
            })
            .catch(error => {
                console.log(error.response);
            });
    },
    [EDIT_PROFILE_INFO]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + EDIT_PROFILE_INFO_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("LOADER_CLOSE");
                router.push({ name: "consultant-profile" })
                dispatch("TITLE_BAR_INFO");
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);


            });
    },
    [CHANGE_PASSWORD]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + CHANGE_PASSWORD_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("LOADER_CLOSE");
                router.push({ name: "consultant-profile" })
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);


            });
    },
    [DELETE_ACCOUNT]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_ACCOUNT_URL,
            method: "POST",
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("LOADER_CLOSE");
                dispatch("AUTH_LOGOUT");
                router.push({ name: "welcome" });
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);


            });
    },
    [ADD_EXPERIENCE]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + ADD_EXPERIENCE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("LOADER_CLOSE");
                //COMMIT THE  RETURNED DATA
                dispatch("VIEW_EXPERIENCE")
                if (response.status == 200) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'success',
                        message: `${response.data.title} is successfully created`
                    })
                    return
                }
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");

                console.log(error.response);

                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [SAVE_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: SITE_API + SAVE_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
        return request;
    },
    [SAVE_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + SAVE_EMPLOYMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
        return request;
    },
    [EDIT_PROJECT_ITEM]: ({ commit, dispatch, getters }, payload) => {

        let request = axios({
            url: SITE_API + EDIT_PROJECT_ITEM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
        return request;
    },
    [EDIT_EMPLOYMENT_ITEM]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + EDIT_EMPLOYMENT_ITEM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
        return request;
    },
    [EDIT_QUALIFICATION_ITEM]: ({ commit, dispatch, getters }, payload) => {
        // dispatch("LOADER_START");
        console.log(payload)
        axios({
            url: SITE_API + getters.get_crm_prefix + EDIT_QUALIFICATION_ITEM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {

                dispatch("GET_ME_PROFILE_DATA");
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);

            });
    },
    [DELETE_QUALIFICATION_ITEM]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_QUALIFICATION_ITEM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {

                dispatch("GET_ME_PROFILE_DATA");
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);

            });
    },
    [VERIFY_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        // dispatch("LOADER_START");
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + VERIFY_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });

        return request;
    },
    [VERIFY_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        // dispatch("LOADER_START");
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + VERIFY_EMPLOYMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });

        return request;
    },
    [CONFIRM_VERIFY_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        // dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + CONFIRM_VERIFY_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {

            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);

            });
    },
    [CONFIRM_VERIFY_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        // dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + CONFIRM_VERIFY_EMPLOYMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {

            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);

            });
    },
    [GRANT_PERMISSION]: ({ commit, dispatch, getters }, payload) => {
        // dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + GRANT_PERMISSION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {

                dispatch("GET_ME_PROFILE_DATA");
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);

            });
    },
    [ADD_SKILL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ADD_SKILL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [INVITE_USER_VIA_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + INVITE_USER_VIA_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("LOADER_CLOSE");

                commit(INVITE_USER_VIA_PROJECT, response.data);

            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error.response);

            });
    },
    [INVITE_USER_VIA_EMAIL]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + INVITE_USER_VIA_EMAIL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => { })
            .catch(error => {
                console.log(error.response);

            });
    },
    [EXPORT_CV]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + EXPORT_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken },
            responseType: 'blob',
        });
        request.then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            if (payload.type == 'pdf') {
                link.setAttribute('download', payload.names + '.pdf'); //or any other extension
            } else if (payload.type == 'json') {
                link.setAttribute('download', payload.names + '.json'); //or any other extension
            } else if (payload.type == 'docx') {
                link.setAttribute('download', payload.names + '.docx'); //or any other extension
            }
            document.body.appendChild(link);
            link.click();
        })
            .catch(error => {
                console.log(error);
            });
        return request;
    },
    [SEARCH_FOR_PROJECT_MEMBERS]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + SEARCH_FOR_PROJECT_MEMBERS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
        request.then(response => {

            commit(SEARCH_FOR_PROJECT_MEMBERS, response.data);
        })
            .catch(error => {
                commit(SEARCH_FOR_PROJECT_MEMBERS, []);
            });
        return request;
    },
    [EDIT_PROJECT_MEMBERS]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + EDIT_PROJECT_MEMBERS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {

            })
            .catch(error => { });
    },
    [CLEAR_PROJECT_MEMBERS]: ({ commit, dispatch, getters }) => {
        commit(SEARCH_FOR_PROJECT_MEMBERS, []);
    },
    [UPDATE_EXPERIENCE]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_EXPERIENCE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("LOADER_CLOSE");
                dispatch("VIEW_EXPERIENCE")

                console.log(response);
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");

                console.log(error.response);

                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [DELETE_EXPERIENCE]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_EXPERIENCE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("LOADER_CLOSE");
                dispatch("VIEW_EXPERIENCE")

                console.log(response);
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");

                console.log(error.response);

                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [ADD_PORTFOLIO]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + ADD_PORTFOLIO_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("VIEW_PORTFOLIO")
                dispatch("LOADER_CLOSE");

                console.log(response);
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");

                console.log(error.response);

                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [UPDATE_PORTFOLIO]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_PORTFOLIO_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("VIEW_PORTFOLIO")
                dispatch("LOADER_CLOSE");

                console.log(response);
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");

                console.log(error.response);

                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [DELETE_PORTFOLIO]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_PORTFOLIO_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("VIEW_PORTFOLIO")
                dispatch("LOADER_CLOSE");

                console.log(response);
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");

                console.log(error.response);

                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [ADD_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + ADD_EMPLOYMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("VIEW_EMPLOYMENT")
                dispatch("LOADER_CLOSE");

                console.log(response);


                if (response.status == 200) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'success',
                        message: `${response.data.title} is successfully created`
                    })
                    return
                }
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");

                console.log(error.response);

                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [UPDATE_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_EMPLOYMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                //TODO: COMMIT THE RETURNED DATA
                dispatch("VIEW_EMPLOYMENT")
                dispatch("LOADER_CLOSE");

                console.log(response);
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");

                console.log(error.response);

                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [DELETE_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_EMPLOYMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("GET_ME_PROFILE_DATA");
            })
            .catch(error => {
                console.log(error.response);
                let response = error.response;
            });
    },
    [ADD_QUALIFICATION]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + ADD_QUALIFICATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("GET_ME_PROFILE_DATA");
                console.log(response);
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }

                console.log(error.response);
            });
    },
    [UPDATE_QUALIFICATION]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_QUALIFICATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                //TODO:UPDATE WITH THE RETURNED DATA
                dispatch("LOADER_CLOSE");

                dispatch("VIEW_QUALIFICATION")





                console.log(response);
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }

                console.log(error.response);
            });
    },
    [DELETE_PROJECT]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_PROJECT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("GET_ME_PROFILE_DATA");
                console.log(response);
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                let response = error.response;
                console.log(error.response);
            });
    },
    [ADD_EDUCATION]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + ADD_EDUCATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {

                dispatch("GET_ME_PROFILE_DATA");
            })
            .catch(error => {
                console.log(error.response);
            });
    },
    [SAVE_CERTIFICATION]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        axios({
            url: SITE_API + getters.get_crm_prefix + SAVE_CERTIFICATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                dispatch("GET_ME_PROFILE_DATA");
            })
            .catch(error => {
                console.log(error.response);
            });
    },
    [UPDATE_EDUCATION]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_EDUCATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                //TODO:COMMIT THE RETURNED DATA
                dispatch("LOADER_CLOSE");
                dispatch("VIEW_EDUCATION")

                console.log(response);
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }

                console.log(error.response);
            });
    },
    [DELETE_EDUCATION]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");

        axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_EDUCATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                //TODO:COMMIT THE RETURNED DATA
                dispatch("LOADER_CLOSE");
                dispatch("VIEW_EDUCATION")

                console.log(response);
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }

                console.log(error.response);
            });
    },
    [VIEW_EDUCATION]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + VIEW_EDUCATION_URL,
            method: "POST",
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                console.log("________");
                console.log(response.data.data);

                commit(VIEW_EDUCATION, response.data.data);
            })
            .catch(error => {
                console.log(error.response);
                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [VIEW_EXPERIENCE]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + VIEW_EXPERIENCE_URL,
            method: "POST",
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                console.log("________");
                console.log(response.data.data);

                commit(VIEW_EXPERIENCE, response.data.data);
            })
            .catch(error => {
                console.log(error.response);
                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [VIEW_PORTFOLIO]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + VIEW_PORTFOLIO_URL,
            method: "POST",
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                console.log("________");
                console.log(response.data.data);

                commit(VIEW_PORTFOLIO, response.data.data);
            })
            .catch(error => {
                console.log(error.response);
                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [VIEW_EMPLOYMENT]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + VIEW_EMPLOYMENT_URL,
            method: "POST",
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                console.log("________");
                console.log(response.data.data);

                commit(VIEW_EMPLOYMENT, response.data.data);
            })
            .catch(error => {
                console.log(error.response);
                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [VIEW_QUALIFICATION]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: SITE_API + getters.get_crm_prefix + VIEW_QUALIFICATION_URL,
            method: "POST",
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        })
            .then(response => {
                console.log("________");
                console.log(response.data.data);

                commit(VIEW_QUALIFICATION, response.data.data);
            })
            .catch(error => {
                console.log(error.response);
                let response = error.response;
                if (response.status == 401 || response.status == 400) {
                    dispatch("SET_ALERT", {
                        visible: true,
                        type: 'error',
                        message: response.data.error
                    })
                    return
                }
            });
    },
    [DELETE_SKILL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_SKILL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [EDIT_SKILL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + EDIT_SKILL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    }
};

const getters = {
    getMeProfile(state) {
        if (state.meProfileData.profile == null) {
            state.meProfileData.profile = { location: {} }
        }
        // if (state.meProfileData.profile.availability!=null){
        //     state.meProfileData.profile.availability = ""
        // }
        return state.meProfileData;
    },
    getToolbar(state) {
        return state.toolbarInfos;
    },
    getEducations(state) {
        return state.educations;
    },
    getNotification(state) {
        return state.notification;
    },
    getExperiences(state) {
        return state.experiences;
    },
    getPortfolios(state) {
        return state.portfolios;
    },
    getEmployments(state) {
        return state.employments;
    },
    getQualifications(state) {
        return state.qualifications;
    },
    getSearchedPeople(state) {
        return state.searchedpeople;
    },
    getDashBoardData(state) {
        return state.meDashBoardData;
    },
    getInvitationData(state) {
        return state.invitationData;
    },
    getProfile(state) {
        return state.profile
    },
    getSubscription(state) {
        return state.subscription
    }
};

export default {
    mutations,
    actions,
    getters,
    state
};