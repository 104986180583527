<template>
  <div class="parent-container">
    <div class="parent-toolbar">
      <WidgetToolbar title="School" :icon="require('@/assets/admin-icon/menu/school_active.svg')">
        <div class="options"></div>
      </WidgetToolbar>
    </div>
    <div class="skills-wrapper">
      <div class="tab-options"></div>
      <multipane class="custom-resizer" layout="vertical">
        <div class="__skills-wraper">
          <div class="__lined">
            <span class="labell">Skills</span>
          </div>
          <div class="__content-body __bordered">
            <div class="__menus-tab">
              <div class="tab-item" @click="showSkillTab('isTab1')" :class="{ activetablink: tab3.isTab1 }">
                <span>Tools</span>
              </div>

              <div class="tab-item" @click="showSkillTab('isTab2')" :class="{ activetablink: tab3.isTab2 }">
                <span>Domains</span>
              </div>

              <div class="tab-item" @click="showSkillTab('isTab3')" :class="{ activetablink: tab3.isTab3 }">
                <span>Competences</span>
              </div>

              <div class="tab-item" @click="showSkillTab('isTab4')" :class="{ activetablink: tab3.isTab4 }">
                <span>Roles</span>
              </div>

              <div class="tab-item" @click="showSkillTab('isTab5')" :class="{ activetablink: tab3.isTab5 }">
                <span>Languages</span>
              </div>
            </div>
            <div class="__tabs-body">
              <div class="__tabs__content" v-show="tab3.isTab1">
                <div class="tabs__content-wrapper">
                  <div class="__headder" v-if="!showAddTool && profile_data.skills_data.tools.length > 0
                    ">
                    <div class="icon-texts">
                      <span>All Tools</span>
                      <div class="__row">
                        <button v-if="false" class="add-skill-btn" @click="showAddTool = true">
                          Add Tool +
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Add tool -->
                  <div v-if="showAddTool">
                    <div class="skill__edit">
                      <h5>Add Tool</h5>
                      <div class="help-section">
                        <label>A device or implement, used to carry out a particular
                          function.</label>
                        <span></span>
                      </div>
                      <div class="check">
                        <label>Name</label>
                        <div class="skills-passive-widget">
                          <input type="checkbox" id="add-passive" v-model="tool.status" />
                          <label class="label-check" for="add-passive">Active</label>

                          <help-tool-tip text="Skill you have but don’t want to use when finding jobs" />
                        </div>
                      </div>
                      <input type="text" placeholder="E.g: Hammer, Photoshop, Java" v-model="tool.name" />
                      <label>Experience Level</label>
                      <div class="rating-skill __bg-white">
                        <star-rating inactive-color="#C1C1C1" active-color="#1BA5A4" :star-size="15" :show-rating="false"
                          v-model="tool.level"></star-rating>
                      </div>
                      <div class="actions-searchables">
                        <div class="btns">
                          <consultfied-button :loading="loading" :disabled="tool.name === ''" @click="addAnySkill(tool)"
                            class="__s">Save</consultfied-button>
                          <button class="__c" @click="showAddTool = false">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="skills-list" v-else>
                    <!-- Tools list -->
                    <div v-if="profile_data.skills_data.tools.length > 0" class="__containner">
                      <draggable draggable=".item" v-model="profile_data.skills_data.tools" v-bind="dragOptions"
                        @start="isDragging = true" @end="onDragEnd" :move="setDragInfo">
                        <transition-group type="transition" :name="'flip-list'">
                          <div v-for="(item, index) in profile_data.skills_data
                            .tools" :key="index" class="item">
                            <div class="__roww" v-if="!item.editMode">
                              <span class="skill-name">{{
                                item.name | validate
                              }}</span>
                              <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4"
                                :star-size="15" :show-rating="false" v-model="item.priority"></star-rating>
                              <div class="__actions">
                                <span class="__colored __act" v-if="item.status">Active</span>
                                <span class="span __colored __act" v-else>Passive</span>
                              </div>
                              <div class="__actionss">
                                <img src="@/assets/icons/editt.svg" @click="item.editMode = true" />
                                <app-dialog title="Delete Skill"
                                  ask="Are you sure you want to permanently delete this skill?" yesText="Delete"
                                  yesBtnClass="btn-danger" noText="Cancel" @onComfirm="
                                    removeTool('tool', item.id, index)
                                    ">
                                  <button>
                                    <img src="@/assets/icons/remove.svg" />
                                  </button>
                                </app-dialog>
                              </div>
                            </div>
                            <div class="skill__edit" v-else>
                              <h5>Edit Tool</h5>
                              <div class="check">
                                <label>Name</label>
                                <div class="skills-passive-widget">
                                  <input type="checkbox" id="passive" v-model="item.searchable" />
                                  <label class="label-check" for="passive">Active</label>
                                  <help-tool-tip text="Skill you have but don’t want to use when finding jobs" />
                                </div>
                              </div>
                              <input type="text" v-model="item.name" />
                              <label>Experience Level</label>
                              <div class="rating-skill __bg-white">
                                <star-rating inactive-color="#C1C1C1" active-color="#1BA5A4" :star-size="15"
                                  :show-rating="false" v-model="item.priority"></star-rating>
                              </div>
                              <div class="actions-searchables">
                                <div class="btns">
                                  <consultfied-button :loading="loading" @click="editAnySkill('tool', item)"
                                    class="__s">Save</consultfied-button>
                                  <button class="__c" @click="item.editMode = false">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </transition-group>
                      </draggable>
                    </div>
                    <!-- If tools not found -->
                    <div v-else class="empty-tools">
                      <empty-tools :showAdd="false" @onAdd="showAddTool = true" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="__tabs__content" v-show="tab3.isTab2">
                <div class="tabs__content-wrapper">
                  <div class="__headder" v-if="!showAddDomain &&
                    profile_data.skills_data.domains.length > 0
                    ">
                    <div class="icon-texts">
                      <span>Domains</span>
                      <div class="__row">
                        <button class="add-skill-btn" v-if="false" @click="showAddDomain = true">
                          Add Domain +
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Add domain -->
                  <div v-if="showAddDomain">
                    <div class="skill__edit">
                      <h5>Add Domain</h5>
                      <div class="help-section">
                        <label>An area or sector of profession.</label>
                        <span></span>
                      </div>
                      <div class="check">
                        <label>Name</label>
                        <div class="skills-passive-widget">
                          <input type="checkbox" id="add2-passive" v-model="domain.status" />
                          <label class="label-check" for="add2-passive">Active</label>
                          <help-tool-tip text="Skill you have but don’t want to use when finding jobs" />
                        </div>
                      </div>
                      <input type="text" placeholder="E.g: Insurance, Banking, Education" v-model="domain.name" />
                      <label>Experience Level</label>
                      <div class="rating-skill __bg-white">
                        <star-rating inactive-color="#C1C1C1" active-color="#1BA5A4" :star-size="15" :show-rating="false"
                          v-model="domain.level"></star-rating>
                      </div>
                      <div class="actions-searchables">
                        <div></div>
                        <div class="btns">
                          <consultfied-button :loading="loading" @click="addAnySkill(domain)" class="__s"
                            :disabled="domain.name === ''">Save</consultfied-button>
                          <button class="__c" @click="showAddDomain = false">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="skills-list" v-else>
                    <!-- Domain list -->
                    <div v-if="profile_data.skills_data.domains.length > 0" class="__containner">
                      <draggable draggable=".item" v-model="profile_data.skills_data.domains" v-bind="dragOptions"
                        @start="isDragging = true" @end="onDragEnd" :move="setDragInfo">
                        <transition-group type="transition" :name="'flip-list'">
                          <div v-for="(item, index) in profile_data.skills_data
                            .domains" :key="index" class="item">
                            <div class="__roww" v-if="!item.editMode">
                              <span class="skill-name">{{
                                item.name | validate
                              }}</span>
                              <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4"
                                :star-size="15" :show-rating="false" v-model="item.priority"></star-rating>
                              <div class="__actions">
                                <span class="__colored __act" v-if="item.status">Active</span>
                                <span class="span __act" v-else>Passive</span>
                              </div>
                              <div class="__actionss">
                                <img src="@/assets/icons/editt.svg" @click="item.editMode = true" />
                                <app-dialog title="Delete Skill"
                                  ask="Are you sure you want to permanently delete this skill?" yesText="Delete"
                                  yesBtnClass="btn-danger" noText="Cancel" @onComfirm="
                                    removeTool('domain', item.id, index)
                                    ">
                                  <button>
                                    <img src="@/assets/icons/remove.svg" />
                                  </button>
                                </app-dialog>
                              </div>
                            </div>
                            <div class="skill__edit" v-else>
                              <div class="check">
                                <label>Name</label>
                                <div class="skills-passive-widget">
                                  <input type="checkbox" id="passive2" v-model="item.searchable" />
                                  <label class="label-check" for="passive2">Active</label>
                                  <help-tool-tip text="Skill you have but don’t want to use when finding jobs" />
                                </div>
                              </div>
                              <input type="text" v-model="item.name" />
                              <label>Experience Level</label>
                              <div class="rating-skill __bg-white">
                                <star-rating inactive-color="#C1C1C1" active-color="#1BA5A4" :star-size="15"
                                  :show-rating="false" v-model="item.priority"></star-rating>
                              </div>
                              <div class="actions-searchables">
                                <div class="btns">
                                  <consultfied-button :loading="loading" @click="editAnySkill('domain', item)"
                                    class="__s">Save</consultfied-button>
                                  <button class="__c" @click="item.editMode = false">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </transition-group>
                      </draggable>
                    </div>
                    <!-- if no domain -->
                    <div v-else class="empty-domain">
                      <empty-domains :showAdd="false" @onAdd="showAddDomain = true" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="__tabs__content" v-show="tab3.isTab3">
                <div class="tabs__content-wrapper">
                  <div class="__headder" v-if="!showAddCompetence &&
                    profile_data.skills_data.competences.length > 0
                    ">
                    <div class="icon-texts">
                      <span>Competences</span>
                      <div class="__row">
                        <button v-if="false" class="add-skill-btn" @click="showAddCompetence = true">
                          Add Competence +
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Add compentence -->
                  <div v-if="showAddCompetence">
                    <div class="skill__edit">
                      <h5>Add Competence</h5>
                      <div class="help-section">
                        <label>Actions you are able to do or perform.</label>
                        <span></span>
                      </div>
                      <div class="check">
                        <label>Name</label>
                        <div class="skills-passive-widget">
                          <input type="checkbox" id="add3-passive" v-model="competence.status" />
                          <label class="label-check" for="add3-passive">Active</label>
                          <help-tool-tip text="Skill you have but don’t want to use when finding jobs" />
                        </div>
                      </div>
                      <input type="text" placeholder="E.g: Leadership, Teamwork" v-model="competence.name" />
                      <label>Experience Level</label>
                      <div class="rating-skill __bg-white">
                        <star-rating inactive-color="#C1C1C1" active-color="#1BA5A4" :star-size="15" :show-rating="false"
                          v-model="competence.level"></star-rating>
                      </div>
                      <div class="actions-searchables">
                        <div class="btns">
                          <consultfied-button :loading="loading" @click="addAnySkill(competence)" class="__s"
                            :disabled="competence.name === ''">Save</consultfied-button>
                          <button class="__c" @click="showAddCompetence = false">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="skills-list" v-else>
                    <!-- Compentence list -->
                    <div v-if="profile_data.skills_data.competences.length > 0" class="__containner">
                      <draggable draggable=".item" v-model="profile_data.skills_data.competences" v-bind="dragOptions"
                        @start="isDragging = true" @end="onDragEnd" :move="setDragInfo">
                        <transition-group type="transition" :name="'flip-list'">
                          <div v-for="(item, index) in profile_data.skills_data
                            .competences" :key="index" class="item">
                            <div class="__roww" v-if="!item.editMode">
                              <span class="__colored">{{
                                item.name | validate
                              }}</span>
                              <star-rating inactive-color="#C1C1C1" active-color="#1BA5A4" :star-size="15"
                                :read-only="true" :show-rating="false" v-model="item.priority"></star-rating>
                              <div class="__actions">
                                <span class="__colored __act" v-if="item.status">Active</span>
                                <span class="span __act" v-else>Passive</span>
                              </div>
                              <div class="__actionss">
                                <img src="@/assets/icons/editt.svg" @click="item.editMode = true" />
                                <app-dialog title="Delete Skill"
                                  ask="Are you sure you want to permanently delete this skill?" yesText="Delete"
                                  yesBtnClass="btn-danger" noText="Cancel" @onComfirm="
                                    removeTool('competence', item.id, index)
                                    ">
                                  <button>
                                    <img src="@/assets/icons/remove.svg" />
                                  </button>
                                </app-dialog>
                              </div>
                            </div>
                            <div class="skill__edit" v-else>
                              <div class="check">
                                <label>Name</label>
                                <div class="skills-passive-widget">
                                  <input type="checkbox" id="passive3" v-model="item.searchable" />
                                  <label class="label-check" for="passive">Active</label>
                                  <help-tool-tip text="Skill you have but don’t want to use when finding jobs" />
                                </div>
                              </div>
                              <input type="text" v-model="item.name" />
                              <label>Experience Level</label>
                              <div class="rating-skill __bg-white">
                                <star-rating inactive-color="#C1C1C1" active-color="#1BA5A4" :star-size="15"
                                  :show-rating="false" v-model="item.priority"></star-rating>
                              </div>
                              <div class="actions-searchables">
                                <div class="btns">
                                  <consultfied-button :loading="loading" @click="editAnySkill('competence', item)"
                                    class="__s">Save</consultfied-button>

                                  <button class="__c" @click="item.editMode = false">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </transition-group>
                      </draggable>
                    </div>
                    <!-- If compentences not fund -->
                    <div v-else class="empty-compentence">
                      <empty-competences :showAdd="false" @onAdd="showAddCompetence = true" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="__tabs__content" v-show="tab3.isTab4">
                <div class="tabs__content-wrapper">
                  <div class="__headder" v-if="!showAddRole && profile_data.skills_data.roles.length > 0
                    ">
                    <div class="icon-texts">
                      <span>Roles</span>
                      <div class="__row">
                        <button v-if="false" class="add-skill-btn" @click="showAddRole = true">
                          Add Role +
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Add roles -->
                  <div v-if="showAddRole">
                    <div class="skill__edit">
                      <h5>Add Role</h5>
                      <div class="help-section">
                        <label>The function or part played in a current or past
                          situation.</label>
                        <span></span>
                      </div>
                      <div class="check">
                        <label>Name</label>
                        <div class="skills-passive-widget">
                          <input type="checkbox" id="add5-passive" v-model="role.status" />
                          <label class="label-check" for="add5-passive">Active</label>
                          <help-tool-tip text="Skill you have but don’t want to use when finding jobs" />
                        </div>
                      </div>
                      <input type="text" placeholder="E.g: CEO, Designer, Project Manager" v-model="role.name" />
                      <label>Experience Level</label>
                      <div class="rating-skill __bg-white">
                        <star-rating inactive-color="#C1C1C1" active-color="#1BA5A4" :star-size="15" :show-rating="false"
                          v-model="role.level"></star-rating>
                      </div>
                      <div class="actions-searchables">
                        <div class="btns">
                          <consultfied-button :loading="loading" @click="addAnySkill(role)" class="__s"
                            :disabled="role.name === ''">Save</consultfied-button>
                          <button class="__c" @click="showAddRole = false">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="skills-list" v-else>
                    <!-- Role roles -->
                    <div v-if="profile_data.skills_data.roles.length > 0" class="__containner">
                      <draggable draggable=".item" v-model="profile_data.skills_data.roles" v-bind="dragOptions"
                        @start="isDragging = true" @end="onDragEnd" :move="setDragInfo">
                        <transition-group type="transition" :name="'flip-list'">
                          <div v-for="(item, index) in profile_data.skills_data
                            .roles" :key="index" class="item">
                            <div class="__roww" v-if="!item.editMode">
                              <span class="__colored">{{
                                item.name | validate
                              }}</span>
                              <star-rating inactive-color="#C1C1C1" active-color="#1BA5A4" :star-size="15"
                                :read-only="true" :show-rating="false" v-model="item.priority"></star-rating>
                              <div class="__actions">
                                <span class="__colored __act" v-if="item.searchable">Active</span>
                                <span class="span __act" v-else>Passive</span>
                              </div>
                              <div class="__actionss">
                                <img src="@/assets/icons/editt.svg" @click="item.editMode = true" />
                                <app-dialog title="Delete Skill"
                                  ask="Are you sure you want to permanently delete this skill?" yesText="Delete"
                                  yesBtnClass="btn-danger" noText="Cancel" @onComfirm="
                                    removeTool('role', item.id, index)
                                    ">
                                  <button>
                                    <img src="@/assets/icons/remove.svg" />
                                  </button>
                                </app-dialog>
                              </div>
                            </div>
                            <div class="skill__edit" v-else>
                              <div class="check">
                                <label>Name</label>
                                <div class="skills-passive-widget">
                                  <input type="checkbox" id="passive4" v-model="item.searchable" />
                                  <label class="label-check" for="passive4">Active</label>
                                  <help-tool-tip text="Skill you have but don’t want to use when finding jobs" />
                                </div>
                              </div>
                              <input type="text" v-model="item.name" />
                              <label>How do you know this</label>
                              <div class="rating-skill __bg-white">
                                <star-rating inactive-color="#C1C1C1" active-color="#1BA5A4" :star-size="15"
                                  :show-rating="false" v-model="item.priority"></star-rating>
                              </div>
                              <div class="actions-searchables">
                                <div class="btns">
                                  <consultfied-button :loading="loading" @click="editAnySkill('role', item)"
                                    class="__s">Save</consultfied-button>

                                  <button class="__c" @click="item.editMode = false">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </transition-group>
                      </draggable>
                    </div>
                    <!-- if roles is empty -->
                    <div v-else class="empty-role">
                      <empty-roles :showAdd="false" @onAdd="showAddRole = true" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="__tabs__content" v-show="tab3.isTab5">
                <div class="tabs__content-wrapper">
                  <div class="__headder" v-if="!showAddLanguage &&
                    profile_data.skills_data.languages.length > 0
                    ">
                    <div class="icon-texts">
                      <span>Languages</span>
                      <div class="__row">
                        <button v-if="false" class="add-skill-btn" @click="showAddLanguage = true">
                          Add Language +
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- Add languages -->
                  <div v-if="showAddLanguage">
                    <div class="skill__edit">
                      <h5>Add Language</h5>
                      <div class="help-section">
                        <label>A system of communication used by a country or
                          community.</label>
                        <span></span>
                      </div>
                      <div class="check">
                        <label>Name</label>
                        <div class="skills-passive-widget">
                          <input type="checkbox" id="add4-passive" placeholder="E.g: English, French"
                            v-model="language.status" />
                          <label class="label-check" for="add4-passive">Active</label>
                          <help-tool-tip text="Skill you have but don’t want to use when finding jobs" />
                        </div>
                      </div>
                      <select v-model="language.name">
                        <option :value="''">Select language</option>
                        <option v-for="(lang, index) in getCountries.languages" :value="lang.id" :key="index + 'sis'">
                          {{ lang.name | validate }}
                        </option>
                      </select>
                      <label>Experience Level</label>
                      <div class="rating-skill __bg-white">
                        <star-rating inactive-color="#C1C1C1" active-color="#1BA5A4" :star-size="15" :show-rating="false"
                          v-model="language.level"></star-rating>
                      </div>
                      <div class="actions-searchables">
                        <div class="btns">
                          <consultfied-button :loading="loading" @click="addAnySkill(language)" class="__s"
                            :disabled="language.name === ''">Save</consultfied-button>
                          <button class="__c" @click="showAddLanguage = false">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="skills-list" v-else>
                    <!-- List of languages -->
                    <div v-if="profile_data.skills_data.languages.length > 0" class="__containner">
                      <draggable draggable=".item" v-model="profile_data.skills_data.languages" v-bind="dragOptions"
                        @start="isDragging = true" @end="onDragEnd" :move="setDragInfo">
                        <transition-group type="transition" :name="'flip-list'">
                          <div v-for="(item, index) in profile_data.skills_data
                            .languages" :key="index" class="item">
                            <div class="__roww" v-if="!item.editMode">
                              <span class="__colored">{{
                                item.name | validate
                              }}</span>

                              <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4"
                                :star-size="15" :show-rating="false" v-model="item.level"></star-rating>

                              <div class="__actions">
                                <span class="__colored __act" v-if="item.status">Active</span>
                                <span class="span __act" v-else>Passive</span>
                              </div>

                              <div class="__actionss">
                                <img src="@/assets/icons/editt.svg" @click="item.editMode = true" />
                                <app-dialog title="Delete Skill"
                                  ask="Are you sure you want to permanently delete this skill?" yesText="Delete"
                                  yesBtnClass="btn-danger" noText="Cancel" @onComfirm="
                                    removeTool('language', item.id, index)
                                    ">
                                  <button>
                                    <img src="@/assets/icons/remove.svg" />
                                  </button>
                                </app-dialog>
                              </div>
                            </div>
                            <div class="skill__edit" v-else>
                              <div class="check">
                                <label>Select Language</label>
                                <div class="skills-passive-widget">
                                  <input type="checkbox" id="passive7" v-model="item.searchable" />
                                  <label class="label-check" for="passive7">Active</label>
                                  <help-tool-tip text="Skill you have but don’t want to use when finding jobs" />
                                </div>
                              </div>
                              <select v-model="item.lang">
                                <option v-for="(
                                    lang, index
                                  ) in getCountries.languages" :key="index" :value="lang.id">
                                  {{ lang.name | validate }}
                                </option>
                              </select>
                              <label>Select Level</label>
                              <select v-model="item.level">
                                <option value="1">Elementary</option>
                                <option value="2">Limited Working</option>
                                <option value="3">Professional Working</option>
                                <option value="4">
                                  Full Professional Working
                                </option>
                                <option value="5">Native</option>
                              </select>
                              <label class="status">Status</label>
                              <div class="actions-searchables">
                                <div class="btns">
                                  <consultfied-button :loading="loading" @click="editAnySkill('language', item)"
                                    class="__s">Save</consultfied-button>
                                  <button class="__c" @click="item.editMode = false">
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </transition-group>
                      </draggable>
                    </div>
                    <!-- If no languages -->
                    <div v-else class="empty-languages">
                      <empty-languages :showAdd="false" @onAdd="showAddLanguage = true" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <multipane-resizer></multipane-resizer>
        <div class="skills-section" :style="{ flexGrow: 1 }">
          <ul class="skills-items">
            <li class="skills-starter">
              <div class="skills-item-header">
                <label>Skills Public Display</label>
              </div>
            </li>
          </ul>
          <div class="skills-widget">
            <div class="skills_content__wrapper">
              <!-- Tools -->
              <div class="skill_box">
                <div class="skill_box_header">
                  <label>Tools</label>
                </div>
                <div class="skill_box_body" v-if="user.skills.tools.length > 0">
                  <div class="row__item" v-for="(item, i) in user.skills.tools.slice(0, 5)" :key="i">
                    <span>{{ item.name | validate }}</span>
                    <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                      :show-rating="false" v-model="item.priority"></star-rating>
                  </div>
                </div>
                <div class="skill_box_body" v-else>
                  <div class="empty-skills">
                    <p>You haven't any tool yet</p>
                  </div>
                </div>
              </div>
              <!-- Competence -->
              <div class="skill_box">
                <div class="skill_box_header">
                  <label>Competences</label>
                </div>
                <div class="skill_box_body" v-if="user.skills.competences.length > 0">
                  <div class="row__item" v-for="(item, i) in user.skills.competences.slice(0, 5)" :key="i">
                    <span>{{ item.name | validate }}</span>
                    <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                      :show-rating="false" v-model="item.priority"></star-rating>
                  </div>
                </div>
                <div class="skill_box_body" v-else>
                  <div class="empty-skills">
                    <p>You haven't any competence yet</p>
                  </div>
                </div>
              </div>
              <!-- Roles -->
              <div class="skill_box">
                <div class="skill_box_header">
                  <label>Roles</label>
                </div>
                <div class="skill_box_body" v-if="user.skills.roles.length > 0">
                  <div class="row__item" v-for="(item, i) in user.skills.roles.slice(0, 5)" :key="i">
                    <span>{{ item.name | validate }}</span>
                    <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                      :show-rating="false" v-model="item.priority"></star-rating>
                  </div>
                </div>
                <div class="skill_box_body" v-else>
                  <div class="empty-skills">
                    <p>You haven't any role yet</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="next_skills_content__wrapper">
              <!-- Domain -->
              <div class="skill_box">
                <div class="skill_box_header">
                  <label>Domains</label>
                </div>
                <div class="skill_box_body" v-if="user.skills.domains.length > 0">
                  <div class="row__item" v-for="(item, i) in user.skills.domains.slice(0, 5)" :key="i">
                    <span>{{ item.name | validate }}</span>
                    <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                      :show-rating="false" v-model="item.priority"></star-rating>
                  </div>
                </div>
                <div class="skill_box_body" v-else>
                  <div class="empty-skills">
                    <p>You haven't any domain yet</p>
                  </div>
                </div>
              </div>
              <!-- Laguage -->
              <div class="skill_box">
                <div class="skill_box_header">
                  <label>Languages</label>
                </div>
                <div class="skill_box_body" v-if="user.skills.languages.length > 0">
                  <div class="row__item" v-for="(item, i) in user.skills.languages.slice(0, 5)" :key="i">
                    <span>{{ item.lang.name | validate }}</span>
                    <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                      :show-rating="false" v-model="item.level"></star-rating>
                  </div>
                </div>
                <div class="skill_box_body" v-else>
                  <div class="empty-skills">
                    <p>You haven't any language yet</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </multipane>
    </div>
  </div>
</template>
<script>
import appDialog from "@/components/Dialog/AppDialog.vue";
import emptyTools from "@/components/Partials/emptyTools.vue";
import emptyDomains from "@/components/Partials/emptyDomains.vue";
import emptyCompetences from "@/components/Partials/emptyCompetences.vue";
import emptyRoles from "@/components/Partials/emptyRoles.vue";
import emptyLanguages from "@/components/Partials/emptyLanguages.vue";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import HelpToolTip from "@/components/helper/HelpToolTip.vue";
import consultfiedButton from "@/components/helper/consultfiedButton.vue";
import { eventBus } from "@/main";
export default {
  name: "skills",
  components: {
    appDialog,
    draggable,
    emptyTools,
    emptyDomains,
    emptyCompetences,
    emptyRoles,
    emptyLanguages,
    HelpToolTip,
    consultfiedButton,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data: () => {
    return {
      showAddTool: false,
      showAddDomain: false,
      showAddCompetence: false,
      showAddRole: false,
      showAddLanguage: false,
      isDragging: false,
      loading: false,
      tab3: {
        isTab1: true,
        isTab2: false,
        isTab3: false,
        isTab4: false,
        isTab5: false,
      },
      tool: {
        name: "",
        status: true,
        level: 4,
        type: "tool",
      },
      domain: {
        name: "",
        status: true,
        level: 4,
        type: "domain",
      },
      competence: {
        name: "",
        status: true,
        level: 4,
        type: "competence",
      },
      language: {
        name: "",
        status: true,
        level: 4,
        type: "language",
      },
      role: {
        name: "",
        status: true,
        level: 4,
        type: "role",
      },
      skills_drag_details: {
        draggedContext: {},
        relatedContext: {},
      },
      profile_data: {
        skills_data: {
          tools: [],
          domains: [],
          languages: [],
          competences: [],
          roles: [],
        },
        skills: {
          tools: [],
          domains: [],
          languages: [],
          competences: [],
          roles: [],
        },
        company_info: {},
        manager_info: {},
        manager_profile: {},
        consultants: [],
        projects: [],
        featured_projects: [],
      },
      user: {
        company_info: {},
        manager_info: {},
        manager_profile: {},
        consultants: [],
        projects: [],
        featured_projects: [],
        skills: {
          tools: [],
          domains: [],
          languages: [],
          competences: [],
          roles: [],
        },
      },
    };
  },
  mounted() {
    let vm = this;
    vm.getProfileData();
    vm.getStartData();
    vm.$store.dispatch("GET_COUNTRIES");
  },
  computed: {
    ...mapGetters(["getCountries", "getCities"]),
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    editAnySkill(type, item) {
      let vm = this;
      eventBus.$set(item, "type", type);
      eventBus.$set(item, "status", item.searchable);
      eventBus.$set(item, "company_id", vm.profile_data.company_info.id);
      vm.$store
        .dispatch("EDIT_SKILL", item)
        .then((responce) => {
          item.editMode = false;
          vm.getProfileData();
          vm.getStartData();
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    removeTool(type, id, index) {
      let vm = this;
      let obj = { type: type, id: id };
      eventBus.$set(obj, "company_id", vm.profile_data.company_info.id);
      vm.$store
        .dispatch("DELETE_SKILL", obj)
        .then((responce) => {
          if (index > -1) {
            vm.profile_data.skills_data[type + "s"].splice(index, 1);
          }
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    addAnySkill(skill) {
      let vm = this;
      vm.loading = true;
      eventBus.$set(skill, "company_id", vm.profile_data.company_info.id);
      vm.$store
        .dispatch("COMPANY_ADD_SKILL", skill)
        .then((responce) => {
          vm.showAddTool = false;
          vm.showAddDomain = false;
          vm.showAddCompetence = false;
          vm.showAddRole = false;
          vm.showAddLanguage = false;
          vm.loading = false;
          vm.getProfileData();
          vm.getStartData();
        })
        .catch((error) => {
          console.log({ error });
          vm.loading = false;
        });
    },
    showSkillTab(key) {
      let vm = this;
      Object.keys(vm.tab3).forEach(function (key) {
        vm.tab3[key] = false;
      });
      this.tab3[key] = true;
    },
    getProfileData() {
      let vm = this;
      vm.$store.dispatch("GET_ME_USER_COMPANY").then((response) => {
        let data = response.data;
        vm.profile_data = data;
        let skills = vm.processSkills(data.skills);
        eventBus.$set(vm.profile_data, "skills_data", skills);
      });
    },
    processSkills(skills_data) {
      let skills = {
        tools: [],
        domains: [],
        languages: [],
        competences: [],
        roles: [],
      };
      if (skills_data && skills_data.length > 0) {
        skills_data.forEach((skill) => {
          skill.tools.forEach((item) => {
            let obj = {
              id: item.id,
              name: item.name,
              priority: item.priority,
              editMode: false,
              type: "tools",
              order: item.order,
              status: item.status,
              searchable: item.status === 0 ? false : true,
              level: parseInt(item.priority),
            };
            let exists = skills.tools.some((skil) => skil.name == item.name);
            if (!exists) {
              skills.tools.push(obj);
            }
          });

          skill.domains.forEach((item) => {
            let obj = {
              id: item.id,
              name: item.name,
              priority: item.priority,
              editMode: false,
              type: "domains",
              order: item.order,
              status: item.status,
              searchable: item.status === 0 ? false : true,
              level: parseInt(item.priority),
            };
            let exists = skills.domains.some((skil) => skil.name == item.name);
            if (!exists) {
              skills.domains.push(obj);
            }
          });
          skill.competences.forEach((item) => {
            let obj = {
              id: item.id,
              name: item.name,
              priority: item.priority,
              editMode: false,
              type: "competences",
              order: item.order,
              status: item.status,
              searchable: item.status === 0 ? false : true,
              level: parseInt(item.priority),
            };
            let exists = skills.competences.some(
              (skil) => skil.name == item.name
            );
            if (!exists) {
              skills.competences.push(obj);
            }
          });
          skill.roles.forEach((item) => {
            let obj = {
              id: item.id,
              name: item.name,
              priority: item.priority,
              editMode: false,
              type: "roles",
              order: item.order,
              status: item.status,
              searchable: item.status === 0 ? false : true,
              level: parseInt(item.priority),
            };
            let exists = skills.roles.some((skil) => skil.name == item.name);
            if (!exists) {
              skills.roles.push(obj);
            }
          });
          skill.languages.forEach((item) => {
            if (item.lang) {
              let obj = {
                id: item.id,
                name: item.lang.name,
                lang: item.lang.id,
                editMode: false,
                type: "languages",
                order: item.order,
                status: item.status,
                searchable: item.status === 0 ? false : true,
                level: parseInt(item.level),
              };

              let exists = skills.languages.some(
                (skil) => skil.name == item.lang.name
              );
              if (!exists) {
                skills.languages.push(obj);
              }
            }
          });
        });
      }
      skills.tools.sort((start, end) => {
        return start.order - end.order;
      });
      skills.domains.sort((start, end) => {
        return start.order - end.order;
      });
      skills.languages.sort((start, end) => {
        return start.order - end.order;
      });
      skills.competences.sort((start, end) => {
        return start.order - end.order;
      });
      skills.roles.sort((start, end) => {
        return start.order - end.order;
      });
      return skills;
    },
    onDragEnd(ev) {
      let vm = this;
      let skill_type = vm.skills_drag_details.draggedContext.element.type;
      let start_order = vm.skills_drag_details.draggedContext.index;
      let end_order = vm.skills_drag_details.draggedContext.futureIndex;
      let skill_id = vm.skills_drag_details.draggedContext.element.id;
      let direction = "";
      if (start_order < end_order) {
        direction = "down";
      } else {
        direction = "up";
      }
      //Send data
      vm.$store
        .dispatch("COMPANY_SKILL_SAVE_PRIOLITY", {
          skill_type: skill_type,
          start_order: start_order,
          end_order: end_order,
          skill_id: skill_id,
          direction: direction,
        })
        .then((responce) => {
          vm.getProfileData();
          vm.getStartData();
        });
    },
    setDragInfo({ draggedContext, relatedContext }) {
      this.skills_drag_details.draggedContext = draggedContext;
      this.skills_drag_details.relatedContext = relatedContext;
    },
    getStartData() {
      let vm = this;
      vm.$store
        .dispatch("GET_ME_USER_COMPANY")
        .then((responce) => {
          vm.user = responce.data;
          let skills = vm.processPublicSkills(vm.user.skills);
          vm.user.consultants = vm.user.consultants
            .filter((item) => {
              return item.profile;
            })
            .sort((start, end) => {
              return start.order - end.order;
            });
          eventBus.$set(vm.user, "skills", skills);
        })
        .catch((error) => { });
    },
    processPublicSkills(skills_data) {
      let skills = {
        tools: [],
        domains: [],
        languages: [],
        competences: [],
        roles: [],
      };
      if (skills_data && skills_data.length > 0) {
        skills_data.forEach((skill) => {
          skill.tools.forEach((item) => {
            let obj = {
              name: item.name,
              priority: item.priority,
              level: parseInt(item.priority),
            };
            let exists = skills.tools.some((skil) => skil.name == item.name);
            if (!exists) {
              skills.tools.push(obj);
            }
          });
          skill.domains.forEach((item) => {
            let obj = {
              name: item.name,
              priority: item.priority,
              level: parseInt(item.priority),
            };
            let exists = skills.domains.some((skil) => skil.name == item.name);
            if (!exists) {
              skills.domains.push(obj);
            }
          });
          skill.competences.forEach((item) => {
            let obj = {
              name: item.name,
              priority: item.priority,
              level: parseInt(item.priority),
            };
            let exists = skills.competences.some(
              (skil) => skil.name == item.name
            );
            if (!exists) {
              skills.competences.push(obj);
            }
          });
          skill.roles.forEach((item) => {
            let obj = {
              name: item.name,
              priority: item.priority,
              level: parseInt(item.priority),
            };
            let exists = skills.roles.some((skil) => skil.name == item.name);
            if (!exists) {
              skills.roles.push(obj);
            }
          });
          skill.languages.forEach((item) => {
            if (item.lang) {
              let obj = {
                lang: { name: item.lang.name },
                level: parseInt(item.level),
              };
              let exists = skills.languages.some(
                (skil) => skil.lang.name == item.lang.name
              );
              if (!exists) {
                skills.languages.push(obj);
              }
            }
          });
        });
      }
      return skills;
    },
  },
  watch: {
    showAddTool: function (val) {
      if (!val) {
        this.tool.name = "";
      }
    },
    showAddDomain: function (val) {
      if (!val) {
        this.domain.name = "";
      }
    },
    showAddCompetence: function (val) {
      if (!val) {
        this.competence.name = "";
      }
    },
    showAddRole: function (val) {
      if (!val) {
        this.role.name = "";
      }
    },
    showAddLanguage: function (val) {
      if (!val) {
        this.language.name = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.parent-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .parent-toolbar {
    position: absolute;
    z-index: 999;
    top: 5px;
    left: 5px;
    right: 5px;
  }

  .skills-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    .custom-resizer {
      height: 100%;

      .__skills-wraper {
        padding-top: 10px;
        border-radius: 5px;
        background: #fff;
        padding: 20px;
        min-width: calc(100% - 450px);
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;

        @media (min-width: 300px) and (max-width: 630px) {
          padding: 10px;
          padding-bottom: 40px;
        }

        .__lined {
          margin-bottom: 10px;
          height: 25px;

          span {
            color: #1ba5a4;
            cursor: pointer;
          }

          .labell {
            font-size: 12px;
            font-weight: normal;
            color: #000000;

            @media (min-width: 300px) and (max-width: 630px) {}
          }
        }

        .__content-body {
          font-weight: normal;
          width: 100%;
          padding: 0px;
          display: flex;
          flex-direction: column;
          height: calc(100% - 25px);

          h3 {
            margin-left: 40px;
            font-size: 13px;
            margin-top: 20px;
            color: #000000;
            margin-bottom: 20px;
            font-weight: normal;
          }

          .__menus-tab {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            margin: 0;
            height: 40px;

            @media (min-width: 300px) and (max-width: 630px) {
              margin: 0;
            }

            overflow: hidden;

            .tab-item {
              height: 40px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 500ms ease-in-out;
              background: #c4c4c4;

              @media (min-width: 300px) and (max-width: 630px) {
                height: 40px;
              }

              &:nth-child(1) {
                border-top-left-radius: 10px;
              }

              &:nth-child(5) {
                border-top-right-radius: 10px;
              }

              span {
                color: #f5f5f5;
                font-size: 12px;
                text-align: center;

                @media (min-width: 300px) and (max-width: 630px) {
                  font-size: 10px;
                }
              }
            }

            .activetablink {
              background: #fff;
              font-weight: bold;
              display: flex;
              transition: 500ms ease-in-out;

              span {
                color: #1ba5a4;
                width: 100%;
                height: 100%;
                background: #fbfbfb2b;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }

          .__tabs-body {
            position: relative;
            padding-bottom: 10px;
            height: calc(100% - 48px);
            display: flex;
            flex-direction: column;

            @media (min-width: 300px) and (max-width: 630px) {
              padding: 10px 0;
            }

            .__tabs__content {
              position: relative;
              top: 0;
              left: 0;
              display: flex;
              flex-direction: column;
              height: 100%;

              .tabs__content-wrapper {
                display: flex;
                flex-direction: column;
                height: 100%;

                .__headder {
                  display: flex;
                  margin-top: 20px;
                  flex-direction: column;
                  height: 40px;

                  .icon-texts {
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    color: #6e6e6e;
                    padding-right: 31px;
                    margin-bottom: 20px;
                    align-items: center;

                    @media (min-width: 300px) and (max-width: 630px) {
                      font-size: 11px;
                      margin-bottom: 10px;
                      padding-left: 22px;
                    }

                    .__row {
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;

                      .add-skill-btn {
                        height: 35px;
                        border-radius: 5px;
                        color: #1890a9;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 12px;
                        white-space: nowrap;

                        img {
                          width: 15px;
                          margin-left: 5px;
                        }
                      }

                      .__item {
                        display: flex;
                        cursor: pointer;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        span {
                          margin-top: 10px;
                          font-size: 10px;
                        }

                        img {
                          width: 10px;

                          @media (min-width: 300px) and (max-width: 630px) {
                            width: 7px;
                          }
                        }
                      }
                    }
                  }

                  label {
                    font-size: 10px;
                    color: #646262;
                    font-weight: normal;
                  }
                }

                .skills-list {
                  height: calc(100% - 40px);
                  overflow-y: auto;

                  .__containner {
                    display: grid;
                    grid-template-rows: 1fr;

                    @media (min-width: 300px) and (max-width: 630px) {
                      padding-right: 0;
                    }

                    .__roww {
                      border-radius: 10px;
                      display: grid;
                      grid-template-columns: 55% 67px 50px 50px;
                      margin-bottom: 8px;
                      cursor: grab;
                      background: #f5f5f5;
                      padding: 11px 20px;
                      justify-content: space-between;

                      @media (min-width: 300px) and (max-width: 630px) {
                        padding: 5px 10px;
                        grid-template-columns: 48% 53px 41px 40px;
                      }

                      .skill-name {
                        color: #837f7f;
                        min-width: 0;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                      }

                      span {
                        color: #837f7f;
                        min-width: 0;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;

                        @media (min-width: 300px) and (max-width: 630px) {
                          font-size: 10px !important;
                          margin-top: 3px;
                        }
                      }

                      .__actions {
                        display: flex;
                        min-width: 0;
                        justify-content: space-between;

                        span {
                          margin-left: 10px;
                          opacity: 0.4;
                        }

                        .__act {
                          opacity: 1;
                        }
                      }

                      .__actionss {
                        min-width: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                          margin-right: 10px;
                          width: 15px;
                          height: 15px;
                          cursor: pointer;

                          @media (min-width: 300px) and (max-width: 630px) {
                            width: 10px;
                            height: 10px;
                            margin-right: 0;
                            margin-left: 5px;
                          }
                        }
                      }
                    }

                    .skill__edit {
                      display: flex;
                      flex-direction: column;
                      padding-left: 0px;
                      margin-bottom: 10px;
                      padding-top: 0px;
                      border-radius: 5px;
                      margin-bottom: 10px;

                      .__bg-white {
                        background: #fff;
                        padding: 7px 10px;
                        width: 60%;
                        border-radius: 5px;
                        margin-bottom: 10px;
                        margin-top: 5px;
                        outline: none;
                      }

                      .rating-skill {
                        background: #fdfdfd;
                        height: 40px;
                        border: 1px solid #ccc;
                        display: flex;
                        align-items: center;
                      }

                      .check {
                        width: 60%;
                        display: flex;
                        justify-content: space-between;

                        .help-tool-tip {
                          margin-top: 4px;
                        }

                        div {
                          display: flex;
                          margin-top: -5px;

                          input[type="checkbox"] {
                            background: #fff;
                            outline: none;
                            margin-left: 10px;
                            margin-top: 3px;
                            padding-left: 5px;
                            padding-right: 5px;
                            /*-webkit-appearance:button;*/
                            color: #fff;
                            white-space: nowrap;
                            overflow: hidden;
                            width: 15px;
                            height: 15px;

                            &:checked {
                              background-color: #000;
                            }
                          }
                        }

                        .label-check {
                          margin: -2px 5px 0 5px;
                        }
                      }

                      h5 {
                        font-size: 13px;
                        color: #6e6e6e;
                        margin-bottom: 20px;
                        font-weight: normal;
                      }

                      label {
                        color: #6e6e6e;
                        margin-bottom: 0;
                        font-size: 12px;
                        margin-left: 10px;
                        font-weight: normal;
                      }

                      .status {
                        margin-top: 10px;
                        font-size: 12px;
                      }

                      input[type="text"],
                      select {
                        background: #fdfdfd;
                        height: 40px;
                        border: 1px solid #ccc;
                        color: #000;
                        padding: 7px 10px;
                        width: 60%;
                        border-radius: 5px;
                        margin-bottom: 10px;
                        margin-top: 5px;
                        outline: none;
                      }

                      .actions-searchables {
                        display: flex;
                        margin-top: 10px;
                        justify-content: space-between;

                        .btns {
                          display: flex;

                          button {
                            outline: none;
                            border: none;
                            padding: 7px 20px;
                            border-radius: 5px;
                            margin-right: 10px;
                            font-size: 12px;
                            background: transparent;
                          }

                          .__c {
                            color: #1890a9;
                            padding: 7px 10px;
                            margin-left: 20px;
                            font-size: 12px;
                          }

                          .__s {
                            color: #fff;
                            background: #1890a9;
                            padding: 0px 20px;
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &:disabled {
                              background: #8d8d8d;
                              opacity: 0.3;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .skill__edit {
                  display: flex;
                  flex-direction: column;
                  padding-left: 50px;
                  border-radius: 5px;
                  margin-bottom: 10px;
                  padding-top: 27px;
                  border-radius: 5px;
                  margin-bottom: 10px;

                  .help-section {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 7px;

                    label {
                      font-size: 12px;
                      margin-left: 0px;
                      margin-bottom: 10px;
                      font-style: italic;
                    }

                    span {
                      font-size: 12px;
                      font-weight: bold;
                    }
                  }

                  .__bg-white {
                    background: #fff;
                    padding: 7px 10px;
                    width: 60%;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    margin-top: 5px;
                    outline: none;
                  }

                  .rating-skill {
                    background: #fdfdfd;
                    height: 40px;
                    border: 1px solid #ccc;
                    display: flex;
                    align-items: center;
                  }

                  .check {
                    width: 60%;
                    display: flex;
                    justify-content: space-between;

                    .help-tool-tip {
                      margin-top: 4px;
                    }

                    div {
                      display: flex;
                      margin-top: -5px;

                      input[type="checkbox"] {
                        background: #fff;
                        outline: none;
                        margin-left: 10px;
                        margin-top: 3px;
                        padding-left: 5px;
                        padding-right: 5px;
                        /*-webkit-appearance:button;*/
                        color: #fff;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 15px;
                        height: 15px;

                        &:checked {
                          background-color: #000;
                        }
                      }
                    }

                    .label-check {
                      margin: 2px 5px 0 5px !important;
                    }
                  }

                  h5 {
                    font-size: 13px;
                    color: #000;
                    margin-bottom: 20px;
                    font-weight: normal;
                  }

                  label {
                    color: #6e6e6e;
                    margin-bottom: 0;
                    font-size: 12px;
                    margin-left: 10px;
                    font-weight: normal;
                  }

                  .status {
                    margin-top: 10px;
                    font-size: 12px;
                  }

                  input[type="text"],
                  select {
                    background: #fdfdfd;
                    height: 40px;
                    border: 1px solid #ccc;
                    color: #000;
                    padding: 7px 10px;
                    width: 60%;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    margin-top: 5px;
                    outline: none;
                  }

                  .actions-searchables {
                    display: flex;
                    margin-top: 10px;

                    .btns {
                      display: flex;

                      button {
                        outline: none;
                        border: none;
                        padding: 7px 20px;
                        border-radius: 5px;
                        margin-right: 10px;
                        font-size: 12px;
                        background: transparent;
                      }

                      .__c {
                        color: #1890a9;
                        font-size: 12px;
                        padding: 7px 10px;
                      }

                      .__s {
                        color: #fff;
                        background: #1890a9;
                        padding: 0px 45px;
                        font-size: 12px;

                        &:disabled {
                          background: #8d8d8d;
                          opacity: 0.3;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .multipane-resizer {
        width: 20px;
      }

      .skills-section {
        overflow-y: auto;
        border-radius: 5px;
        background: #ffffff;
        width: 400px;
        min-width: 400px;
        max-width: 440px;
        height: 100%;
        padding-bottom: 100px;

        @media (min-width: 300px) and (max-width: 630px) {
          display: none;
        }

        ul.skills-items {
          padding-left: 0px;
          list-style: none;

          .skills-starter {
            margin-bottom: 6px;

            .skills-item-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 6px 15px;

              label {
                color: #000000;
                font-size: 12px;
              }
            }
          }
        }

        .skills-widget {
          padding: 0px 15px;

          .skills_content__wrapper {
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: 10px;
            margin-bottom: 0px;

            .skill_box {
              min-width: 0;
              display: flex;
              flex-direction: column;
              width: 100%;
              background: #f5f5f5;
              border-radius: 5px;
              margin-bottom: 8px;

              .skill_box_header {
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 0px 15px;

                label {
                  color: #0884af;
                  width: 100%;
                  font-size: 12px;
                }
              }

              .skill_box_body {
                padding: 0px 15px;

                .empty-skills {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  height: 60px;
                  justify-content: center;

                  p {
                    text-align: center;
                    margin-bottom: 20px;
                    color: #8d8d8d;
                  }

                  button {
                    width: 133px;
                    height: 36px;
                    background: #1890a9 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    color: #fff;
                  }
                }

                .row__item {
                  display: flex;
                  margin-bottom: 10px;
                  justify-content: space-between;
                  width: 100%;

                  span {
                    color: #000000;
                    font-weight: normal;
                    margin-top: 2px;
                    font-size: 12px;
                    overflow: hidden;
                    text-decoration: none;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }

          .next_skills_content__wrapper {
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: 10px;

            .skill_box {
              min-width: 0;
              display: flex;
              flex-direction: column;
              width: 100%;
              background: #f5f5f5;
              border-radius: 5px;
              margin-bottom: 8px;

              .skill_box_header {
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 0px 15px;

                label {
                  color: #0884af;
                  width: 100%;
                  font-size: 12px;
                }
              }

              .skill_box_body {
                padding: 0px 15px;

                .empty-skills {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  height: 60px;
                  justify-content: center;

                  p {
                    text-align: center;
                    margin-bottom: 20px;
                    color: #8d8d8d;
                  }

                  button {
                    width: 133px;
                    height: 36px;
                    background: #1890a9 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    color: #fff;
                  }
                }

                .row__item {
                  display: flex;
                  margin-bottom: 10px;
                  justify-content: space-between;
                  width: 100%;

                  span {
                    color: #000000;
                    font-weight: normal;
                    margin-top: 2px;
                    font-size: 12px;
                    overflow: hidden;
                    text-decoration: none;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>