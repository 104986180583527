<template>
  <div class="parent-container">
    <div class="company-profile-wrapper">
      <!-- company-profile display -->
      <div class="company-profile-container">
        <multipane class="custom-resizer" layout="vertical">
          <div class="items">
            <div class="company-profile-body">
              <loading :height="20" color="#1ba5a4" :opacity="1" :width="20" :active.sync="user_loading"
                :is-full-page="false" :can-cancel="false" background-color="#fff" class="loader"></loading>
              <!-- For All Consultant -->
              <div class="list-container" v-if="student_data &&
                student_data.data &&
                student_data.data.length > 0
                ">
                <div class="table-label">All Students</div>
                <div @scroll="onPageScoll" ref="mainScollContainer" class="table-container">
                  <table class="table-data">
                    <thead class="table-header">
                      <tr class="th-tr">
                        <th class="table-th-stick-top">Student name</th>
                        <th class="table-th-stick-top">Class</th>
                        <th class="table-th-stick-top">Contacts</th>
                        <th class="table-th-stick-top"></th>
                      </tr>
                    </thead>
                    <tbody class="table-body">
                      <template v-for="(item, index) in student_data.data">
                        <tr :key="index" :class="{ active: item.active }" class="row-data">
                          <td @click="viewItem(item)">
                            <widgetProfile :title="item.first_name + ' ' + item.last_name" :subtitle="item.profile ? item.profile.address : ''
                              " :file_name="item.profile_picture" :path="$fullUrl('images/profiles/')"></widgetProfile>
                          </td>
                          <td @click="viewItem(item)">
                            <div v-if="item.teams.length > 0" class="email-phone">
                              <span class="email crop-text">{{
                                item.teams[0].name | validate
                              }}</span>
                              <span class="phone crop-text">{{
                                item.teams[0].location | validate
                              }}</span>
                            </div>
                          </td>
                          <td @click="viewItem(item)">
                            <div class="email-phone">
                              <span class="email crop-text">{{
                                item.email | validate
                              }}</span>
                              <span class="phone crop-text" v-if="item.profile">{{
                                item.profile.phone | validate
                              }}</span>
                            </div>
                          </td>
                          <td>
                            <div class="menu">
                              <!-- Dropdown -->
                              <dropdown-menu>
                                <ul>
                                  <li>
                                    <a href @click.prevent="transferConsultant(item)">Transfer Student</a>
                                  </li>
                                  <li>
                                    <a href @click.prevent="updateConsultant(item)">Update Information</a>
                                  </li>
                                  <li>
                                    <a href @click.prevent="manageConsultant(item.id)">Manage Profile</a>
                                  </li>
                                  <li>
                                    <app-dialog title="Remove Student"
                                      ask="Are you sure? This student will be removed from the scholl and will not be managed any further."
                                      yesText="Remove" noText="Cancel" @onComfirm="removeConsultant(item)">
                                      <a @click.prevent="">Remove student</a>
                                    </app-dialog>
                                  </li>
                                </ul>
                              </dropdown-menu>
                              <!-- End of dropdown -->
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <!-- loading on pagination -->
                  <div class="loading-paginated-items" v-if="isLoadingDataOnPagination">
                    <loading :height="15" color="#1ba5a4" :opacity="1" :width="15"
                      :active.sync="isLoadingDataOnPagination" :is-full-page="false" :can-cancel="false"
                      background-color="#fff" class="loader"></loading>
                  </div>
                </div>
              </div>
              <!-- Empty student data widget -->
              <div class="empty-widget" v-if="student_data &&
                student_data.data &&
                student_data.data.length == 0
                ">
                <div class="empty-widget-body">
                  <p v-if="search_member_input">
                    Student you are trying to search not found,
                    <br />Click below to add one
                  </p>
                  <p v-else>
                    You have not yet added student,
                    <br />Click below to add one
                  </p>
                  <button class="add-btn" @click="addMember()">
                    Add a student
                  </button>
                </div>
              </div>
            </div>
          </div>
          <multipane-resizer></multipane-resizer>
          <div class="item-view-more" :style="{ flexGrow: 1 }" v-if="consultant_item">
            <div class="consultants-item">
              <div class="consultants-item-header">
                <widgetProfile :title="consultant_item.first_name + ' ' + consultant_item.last_name
                  " :subtitle="consultant_item.profile
    ? consultant_item.profile.address
    : ''
    " :file_name="consultant_item.profile_picture" :path="$fullUrl('images/profiles/')" :size="30" :text_size="12">
                </widgetProfile>

                <div class="consultants-item-header-options">
                  <button class="close-btn" @click="showConsultants()">
                    <span>&times;</span>
                  </button>
                </div>
              </div>
              <div class="consultants-item-body">
                <div class="consultants-info">
                  <h5 v-if="consultant_item.profile">Telephone</h5>
                  <p v-if="consultant_item.profile">
                    {{ consultant_item.profile.phone | validate }}
                  </p>
                  <h5 v-if="$validateData(consultant_item.email)">E-mail</h5>
                  <p>{{ consultant_item.email | validate }}</p>

                  <h5>Class</h5>
                  <p v-if="consultant_item.teams.length > 0">{{ consultant_item.teams[0].name | validate }}</p>
                  <p v-else>Not Set</p>

                  <h5 v-if="consultant_item.profile &&
                    consultant_item.profile.description
                    ">
                    Description
                  </h5>
                  <div v-if="consultant_item.profile" class="description" v-html="consultant_item.profile.description">
                  </div>
                  <h5>Skills</h5>
                  <div class="skills-widget">
                    <div class="skills_content__wrapper">
                      <div class="skill_box">
                        <div class="skill_box_header">
                          <label>Tools</label>
                        </div>
                        <div class="skill_box_body" v-if="consultant_item.skills.tools.length > 0">
                          <div class="row__item" v-for="(
                              value, i
                            ) in consultant_item.skills.tools.slice(0, 5)" :key="i">
                            <span>{{ value.name | validate }}</span>
                            <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                              :show-rating="false" v-model="value.level"></star-rating>
                          </div>
                        </div>
                        <div class="skill_box_body" v-else>
                          <div class="empty-skills">
                            <p>Have not added any tool yet.</p>
                          </div>
                        </div>
                      </div>
                      <div class="skill_box">
                        <div class="skill_box_header">
                          <label>Domains</label>
                        </div>
                        <div class="skill_box_body" v-if="consultant_item.skills.domains.length > 0">
                          <div class="row__item" v-for="(
                              value, i
                            ) in consultant_item.skills.domains.slice(0, 5)" :key="i">
                            <span>{{ value.name | validate }}</span>
                            <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                              :show-rating="false" v-model="value.level"></star-rating>
                          </div>
                        </div>
                        <div class="skill_box_body" v-else>
                          <div class="empty-skills">
                            <p>Have not added any domain yet.</p>
                          </div>
                        </div>
                      </div>
                      <div class="skill_box">
                        <div class="skill_box_header">
                          <label>Languages</label>
                        </div>
                        <div class="skill_box_body" v-if="consultant_item.skills.languages.length > 0">
                          <div class="row__item" v-for="(
                              value, i
                            ) in consultant_item.skills.languages.slice(0, 5)" :key="i">
                            <span>{{ value.lang.name | validate }}</span>
                            <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                              :show-rating="false" v-model="value.level"></star-rating>
                          </div>
                        </div>
                        <div class="skill_box_body" v-else>
                          <div class="empty-skills">
                            <p>Have not added any language yet.</p>
                          </div>
                        </div>
                      </div>

                      <div class="skill_box">
                        <div class="skill_box_header">
                          <label>Roles</label>
                        </div>
                        <div class="skill_box_body" v-if="consultant_item.skills.roles.length > 0">
                          <div class="row__item" v-for="(
                              value, i
                            ) in consultant_item.skills.roles.slice(0, 5)" :key="i">
                            <span>{{ value.name | validate }}</span>
                            <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                              :show-rating="false" v-model="value.level"></star-rating>
                          </div>
                        </div>
                        <div class="skill_box_body" v-else>
                          <div class="empty-skills">
                            <p>Have not added any role yet.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="next_skills_content__wrapper">
                      <div class="skill_box">
                        <div class="skill_box_header">
                          <label>Competences</label>
                        </div>
                        <div class="skill_box_body" v-if="consultant_item.skills.competences.length > 0">
                          <div class="row__item" v-for="(
                              value, i
                            ) in consultant_item.skills.competences.slice(0, 5)" :key="i">
                            <span>{{ value.name | validate }}</span>
                            <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                              :show-rating="false" v-model="value.level"></star-rating>
                          </div>
                        </div>
                        <div class="skill_box_body" v-else>
                          <div class="empty-skills">
                            <p>Have not added any competence yet.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="consultants-item-body-option"></div>
              </div>
            </div>
          </div>
          <div class="item-view-more" :style="{ flexGrow: 1 }" v-else>
            <div v-if="main_tab == 'consultant'" class="empty-widget">
              <widgetNotFoundWidget title="No student selected" content="Please select a student to view their details."
                align="center">
                <img class="widget-img" src="@/assets/icons/member-notfound.svg" />
              </widgetNotFoundWidget>
            </div>
          </div>
        </multipane>
      </div>
    </div>

    <!-- Add New Student -->
    <div class="overlay" v-show="show_student_form && form_student.save_type == 'single'">
      <div class="form-widget-modal member-container">
        <div class="member-header">
          <h3 v-if="!form_student.id">Add new student</h3>
          <h3 v-else>Update student</h3>
        </div>
        <div class="modal-body">
          <div class="form-widget">
            <div class="form-widget-body">
              <div class="twin-input">
                <div class="input-group">
                  <label>First name</label>
                  <input v-model="form_student.first_name" type="text" class="input-form" />
                </div>
                <div class="input-group">
                  <label>Last name</label>
                  <input v-model="form_student.last_name" type="text" class="input-form" />
                </div>
              </div>
              <div class="twin-input">
                <div class="input-group">
                  <label>Phone</label>
                  <input v-model="form_student.phone" type="text" class="input-form" />
                </div>
                <div class="input-group">
                  <label>Email</label>
                  <input v-model="form_student.email" type="text" class="input-form" />
                </div>
              </div>
              <div class="twin-input">
                <div class="input-group">
                  <label>Location</label>
                  <map-helper v-model="form_student.location"></map-helper>
                </div>
                <div class="input-group" v-if="!form_student.id">
                  <label>Class</label>
                  <select class="input-form" v-model="form_student.selected_class">
                    <option v-for="(team, index) in department_data" :key="index" :value="team.id">
                      {{ team.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="input-group">
                <label>password</label>
                <input v-model="form_student.password" type="password" class="input-form" />
              </div>
            </div>
          </div>
          <div class="line" v-if="!form_student.id"></div>
          <div class="form-widget" v-if="!form_student.id">
            <div class="form-widget-body">
              <button class="btn-add-many" @click="form_student.save_type = 'many'">
                Add Multiple Students
              </button>
            </div>
          </div>
        </div>
        <div class="member-managment-option">
          <c-button :loading="save_student_loading" @click="submitStudent()" class="btn-save">Save</c-button>
          <button class="btn-cancel" @click="show_student_form = false">
            Cancel
          </button>
        </div>
      </div>
    </div>

    <!-- Add Many Student -->
    <widgetModal v-if="form_student.save_type == 'many'" :footerhidden="true" :headerhidden="true" body_padding="0px 0px"
      width="550px">
      <uploadManyItems file="https://storage.googleapis.com/consultified_media/files/templates/template.xlsx"
        title="Upload Students List" description="To upload many students as file, you need to download the .xl file
              template here. Add students to the file and then repload it in the
              next step." actiontext="Upload below the filled document with students"
        @back="form_student.save_type = 'single'" @onFile="(file) => {
          if (file) {
            form_student.selected_file = file;
          }
        }
          " :loading="save_student_loading" @onSubmit="submitStudent()">
        <widgetInputWrapper title="Select Class">
          <widgetSelect v-model="form_student.selected_class" :opt="department_data.map((team) => {
            return { label: team.name, value: team.id };
          })
            " placeholder="Select Class"></widgetSelect>
        </widgetInputWrapper>
      </uploadManyItems>
    </widgetModal>


    <!-- Transfer Student -->
    <widgetModal v-if="showTransferModal && consultant_item" width="550px" title="Transafer student"
      subtitle="Fill in the information below to transfer the student to another class" btnlefttext="Save Changes"
      btncentertext="Cancel" btnrighttext="Add Multiple LIA" :leftloading="loadingTransfer" :rightloading="false"
      :centerloading="false" :footerhidden="false" footer_padding="10px 18px 30px 30px" header_padding="30px 0px 0px 31px"
      body_padding="0px 30px" :oldhiderightbtn="false" :hiderightbtn="true" @onLeftClick="onConfirmStudentTransfer()"
      @onCenterClick="showTransferModal = false">

      <div class="widget-modal-body-noscoll">
        <widgetInputWrapper title="Current Class">
          <p v-if="consultant_item.teams.length > 0">{{ consultant_item.teams[0].name | validate }}</p>
          <p v-else>Not Set</p>
        </widgetInputWrapper>


        <widgetInputWrapper title="Select class">
          <select class="input-form" v-model="form_transfer.classId">
            <option v-for="(team, index) in department_data" :key="index" :value="team.id">
              {{ team.name }}
            </option>
          </select>
        </widgetInputWrapper>

      </div>


    </widgetModal>

  </div>
</template>

<script>
import appDialog from "@/components/Dialog/AppDialog.vue";
import { eventBus } from "@/main";
import { mapGetters } from "vuex";

export default {
  name: "consultants",
  components: {
    appDialog,
  },
  props: {},
  data: () => {
    return {
      user_loading: false,
      show_filter: false,
      request_sent: false,
      member_management_modal: false,
      consultant_list: true,
      tab: "all",
      main_tab: "consultant",
      manage_consultant_component: false,
      search_consultant_member: "",
      added_member_list: [],
      selected_team_id: "",
      company_add_member_loading: false,
      user: {
        consultants: [],
      },
      search_member_input: "",
      selected_id: null,
      consultant_item: null,
      manager_perms: [
        {
          label: "Admin Manager",
          value: "super-manager",
          checked: false,
          description:
            "As the Admin Manager, one will access full control and administration of the school.",
        },
        {
          label: "Division Manager",
          value: "department-manager",
          checked: false,
          description:
            "As the Department Manager, one will interact with departments, classes aswell as students profiles, create leads, invite students and instructors, etc. However, some of the features requires the Admin approval.",
        },
        {
          label: "Class Manager",
          value: "team-manager",
          checked: false,
          description:
            "As Class Manager, one have access to a class their students. They will be developing and carrying out management process. They will be able to manage students profiles, create leads, invite student.",
        },
      ],
      manager_form: {
        change: false,
        permt_type: null,
        company_id: null,
        user_id: null,
      },
      show_student_form: false,
      save_student_loading: false,
      form_student: {
        save_type: "single",
        id: null,
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        location: "",
        password: "",
        selected_class: null,
        selected_file: null,
      },
      show_manager_form: false,
      save_manager_loading: false,
      form_manager: {
        save_type: "single",
        id: null,
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        location: "",
        selected_class: null,
        selected_file: null,
      },
      show_invite_manager_modal: false,
      invite_manager_loading: false,
      department_data: [],
      selected_view_filter: "",
      view_filter: [
        {
          value: "all",
          name: "All",
        },
        {
          value: "avail",
          name: "Available",
        },
      ],
      isLoadingDataOnPagination: false,
      filter_form: {
        id: null,
        id_type: "",
        sort_by: "all",
        query: "",
        class: null,
        url_type: "",
        next_page_url: "",
      },
      student_data: null,
      form_transfer: {
        id: null,
        classId: null,
      },
      showTransferModal: false,
      loadingTransfer: false,
    };
  },
  mounted() {
    let vm = this;
    vm.getAllStartData();
    vm.getTeamDepData();
  },
  computed: {
    ...mapGetters(["getSearchedPeople"]),
  },
  watch: {
    search_consultant_member: function (query) {
      this.searchPeople(query);
    },
    selected_view_filter(val) {
      let vm = this;
      vm.tab = val;
    },
  },
  methods: {
    transferConsultant(item) {
      let vm = this;
      vm.viewItem(item);
      vm.form_transfer.id = item.id;
      vm.form_transfer.classId = item.teams.length > 0 ? item.teams[0].id : null;
      vm.showTransferModal = true;
    },
    onConfirmStudentTransfer() {
      let vm = this;
      vm.loadingTransfer = true;
      vm.$store.commit("SET_COMPANY_ID", parseInt(vm.$route.params.id));
      vm.$store
        .dispatch("COMPANY_TRANSFER_STUDENT", vm.form_transfer)
        .then((responce) => {
          vm.loadingTransfer = false;
          vm.showTransferModal = false;
          vm.getAllStartData();
          vm.$notify({
            group: "status",
            title: "Success",
            text: "Student was transfered",
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          vm.loadingTransfer = false;
          vm.$notify({
            group: "status",
            title: "Warning",
            text: "Student not transfered, Please try again.",
            type: "warn",
            duration: 3000,
          });
          console.log(error);
        });
    },
    onPageScoll({ target }) {
      let vm = this;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100) {
        if (
          !vm.isLoadingDataOnPagination &&
          vm.student_data.current_page < vm.student_data.last_page
        ) {
          vm.getAllStartData("next");
        }
      }
    },
    updateInstuctor(item) {
      let vm = this;
      vm.form_manager.save_type = "single";
      vm.form_manager.id = item.id;
      vm.form_manager.first_name = item.first_name;
      vm.form_manager.last_name = item.last_name;
      vm.form_manager.phone = item.profile.phone;
      vm.form_manager.email = item.email;
      vm.form_manager.location = item.profile.address;
      vm.form_manager.selected_class = null;
      vm.form_manager.selected_file = null;
      vm.show_manager_form = true;
    },
    updateConsultant(item) {
      let vm = this;
      vm.form_student.save_type = "single";
      vm.form_student.id = item.id;
      vm.form_student.first_name = item.first_name;
      vm.form_student.last_name = item.last_name;
      vm.form_student.phone = item.profile.phone;
      vm.form_student.email = item.email;
      vm.form_student.location = item.profile.address;
      vm.form_student.selected_class = null;
      vm.form_student.selected_file = null;
      vm.show_student_form = true;
    },
    validateRoleName(name) {
      if (name == "super-manager") {
        return "Admin manager";
      } else if (name == "team-manager") {
        return "Class manager";
      } else if (name == "department-manager") {
        return "Division manager";
      }
    },
    changePermission(item) {
      let vm = this;
      vm.manager_form.company_id = parseInt(vm.$route.params.id);
      vm.manager_form.user_id = item.id;
      vm.manager_form.permt_type = item.roles[0].name;
      vm.manager_form.change = true;
      vm.show_invite_manager_modal = true;
      vm.consultant_item = item;
    },
    changeUserPermssion() {
      let vm = this;
      vm.invite_manager_loading = true;
      vm.$store.commit("SET_COMPANY_ID", parseInt(vm.$route.params.id));
      vm.$store
        .dispatch("COMPANY_CONSULTANT_CHANGE_PERMISSION", vm.manager_form)
        .then((responce) => {
          vm.invite_manager_loading = false;
          vm.show_invite_manager_modal = false;
          vm.getAllStartData();
          vm.$notify({
            group: "status",
            title: "Success",
            text: "Permission was changed",
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          vm.invite_manager_loading = false;
          vm.$notify({
            group: "status",
            title: "Warning",
            text: "Permission not set, Please try again.",
            type: "warn",
            duration: 3000,
          });
          console.log(error);
        });
    },
    removeManager(id) {
      let vm = this;
      vm.$store.commit("SET_COMPANY_ID", parseInt(vm.$route.params.id));
      vm.$store
        .dispatch("REMOVE_MANAGER_FROM_COMPANY", {
          user_id: id,
        })
        .then((responce) => {
          vm.getAllStartData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTeamDepData() {
      let vm = this;
      vm.$store.commit("SET_COMPANY_ID", parseInt(vm.$route.params.id));
      vm.$store
        .dispatch("GET_ALL_USER_INFO")
        .then((response) => {
          response.data.forEach((item) => {
            item.teams.forEach((team) => {
              vm.department_data.push(team);
            });
          });
        })
        .catch((error) => {
          console.error({ error });
        });
    },
    showinviteManagerModal(id) {
      let vm = this;
      vm.manager_form.company_id = parseInt(vm.$route.params.id);
      vm.manager_form.user_id = id;
      vm.manager_form.change = false;
      vm.show_invite_manager_modal = true;
    },
    inviteAsManager() {
      let vm = this;
      vm.invite_manager_loading = true;
      vm.$store.commit("SET_COMPANY_ID", parseInt(vm.$route.params.id));
      if (!vm.manager_form.change) {
        vm.$store
          .dispatch("COMPANY_INVITE_CONSULTANT_AS_MANAGER", vm.manager_form)
          .then((responce) => {
            vm.invite_manager_loading = false;
            vm.show_invite_manager_modal = false;
            vm.getAllStartData();
            vm.$notify({
              group: "status",
              title: "Success",
              text: "Now invite was sent",
              type: "success",
              duration: 3000,
            });
          })
          .catch((error) => {
            vm.invite_manager_loading = false;
            vm.$notify({
              group: "status",
              title: "Warning",
              text: "Invite not sent, Please try again.",
              type: "warn",
              duration: 3000,
            });
            console.log(error);
          });
      } else {
        vm.$store
          .dispatch("COMPANY_CONSULTANT_CHANGE_PERMISSION", vm.manager_form)
          .then((responce) => {
            vm.invite_manager_loading = false;
            vm.show_invite_manager_modal = false;
            vm.getAllStartData();
            vm.$notify({
              group: "status",
              title: "Success",
              text: "Permission was changed",
              type: "success",
              duration: 3000,
            });
          })
          .catch((error) => {
            vm.invite_manager_loading = false;
            vm.$notify({
              group: "status",
              title: "Warning",
              text: "Permission not set, Please try again.",
              type: "warn",
              duration: 3000,
            });
            console.log(error);
          });
      }
    },
    viewItem(item) {
      let vm = this;
      vm.student_data.data.forEach((item) => {
        eventBus.$set(item, "active", false);
        return item;
      });
      item.active = true;
      vm.consultant_item = item;
    },
    searchCompanyMember() {
      let vm = this;
      vm.filter_form.query = vm.search_member_input;
      if (vm.search_member_input) {
        vm.getAllStartData();
      } else {
        vm.getAllStartData();
      }
    },
    getAllStartData(type = "init") {
      let vm = this;
      if (type == "init") {
        vm.user_loading = true;
      }
      vm.$store.commit("SET_COMPANY_ID", parseInt(vm.$route.params.id));
      vm.isLoadingDataOnPagination = true;
      vm.filter_form.url_type = type;
      vm.filter_form.next_page_url = vm.student_data
        ? vm.student_data.next_page_url
        : "";
      vm.$store
        .dispatch("GET_ALL_SCHOOL_STUDENT", vm.filter_form)
        .then((responce) => {
          vm.user_loading = false;
          vm.isLoadingDataOnPagination = false;
          let consultant_data = vm.processSkills(responce.data.data);
          eventBus.$set(responce.data, "data", consultant_data);
          responce.data.data = responce.data.data.map((item) => {
            eventBus.$set(item, "checked", false);
            eventBus.$set(item, "active", false);
            return item;
          });
          if (type == "init") {
            vm.student_data = responce.data;
            if (vm.student_data.current_page < vm.student_data.last_page) {
              vm.getAllStartData("next");
            }
          } else {
            vm.student_data.next_page_url = responce.data.next_page_url;
            vm.student_data.current_page = responce.data.current_page;
            vm.student_data.last_page = responce.data.last_page;
            responce.data.data.forEach((item) => {
              vm.student_data.data.push(item);
            });
            const scollContainerRect = vm.$refs.mainScollContainer.getBoundingClientRect();
            const scollContainerLastChildRect = vm.$refs.mainScollContainer.lastChild.getBoundingClientRect();
            if (scollContainerRect.height > scollContainerLastChildRect.y) {
              vm.getAllStartData("next");
            }
          }
        })
        .catch((error) => {
          vm.user_loading = false;
          vm.isLoadingDataOnPagination = false;
          console.log(error);
        });
    },
    removeConsultant(item) {
      let vm = this;
      vm.$store
        .dispatch("REMOVE_MEMBER_FROM_COMPANY", {
          company_id: parseInt(vm.$route.params.id),
          user_id: item.id,
          account_type: item.account_type,
        })
        .then((responce) => {
          vm.getAllStartData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    processSkills(consultants) {
      return consultants.map((item) => {
        let skills = {
          tools: [],
          domains: [],
          languages: [],
          competences: [],
          roles: [],
        };
        if (item.skills.length && item.skills.length > 0) {
          item.skills.forEach((skill) => {
            skill.tools.forEach((item) => {
              let obj = {
                name: item.name,
                priority: item.priority,
                level: parseInt(item.priority),
              };
              skills.tools.push(obj);
            });
            skill.domains.forEach((item) => {
              let obj = {
                name: item.name,
                priority: item.priority,
                level: parseInt(item.priority),
              };
              skills.domains.push(obj);
            });
            skill.competences.forEach((item) => {
              let obj = {
                name: item.name,
                priority: item.priority,
                level: parseInt(item.priority),
              };
              skills.competences.push(obj);
            });
            skill.roles.forEach((item) => {
              let obj = {
                name: item.name,
                priority: item.priority,
                level: parseInt(item.priority),
              };
              skills.roles.push(obj);
            });
            skill.languages.forEach((item) => {
              if (item.lang) {
                let obj = {
                  lang: { name: item.lang.name },
                  level: parseInt(item.level),
                };
                skills.languages.push(obj);
              }
            });
          });
        }
        eventBus.$set(item, "skills", skills);
        return item;
      });
    },
    submitStudent() {
      let vm = this;
      vm.save_student_loading = true;
      let form_data = new FormData();
      form_data.append("id", vm.form_student.id);
      form_data.append("save_type", vm.form_student.save_type);
      form_data.append("first_name", vm.form_student.first_name);
      form_data.append("last_name", vm.form_student.last_name);
      form_data.append("phone", vm.form_student.phone);
      form_data.append("email", vm.form_student.email);
      form_data.append("location", vm.form_student.location);
      form_data.append("selected_class", vm.form_student.selected_class);
      form_data.append("password", vm.form_student.password);
      if (vm.form_student.selected_file) {
        form_data.append(
          "selected_file",
          vm.form_student.selected_file,
          vm.form_student.selected_file.name
        );
      }

      for (var pair of form_data.entries()) {
        let formDataKey = pair[0];
        let formDataValue = pair[1];
        if (formDataValue == "null") {
          form_data.set(formDataKey, "");
        }
      }

      if (!vm.form_student.id) {
        vm.$store
          .dispatch("ADD_SCHOOL_STUDENT", form_data)
          .then((responce) => {
            vm.save_student_loading = false;
            if (responce.data.message == "sucess with errors") {
              eventBus.$emit("openDialogMessage", {
                title: "Students already exist",
                message:
                  "Some students already exist in your school: " +
                  responce.data.errors
                    .map((item) => {
                      return (
                        "<span style='font-weight:600;color:#0884af;font-size: 16px;'>" +
                        item.email +
                        "</span>"
                      );
                    })
                    .join(", "),
                action_label: "Okay",
                action_close_label: null,
                callback: () => {
                  vm.form_student.save_type = "single";
                  vm.show_student_form = false;
                  vm.getAllStartData();
                },
              });
            } else {
              vm.$notify({
                group: "status",
                title: "Success",
                text: "New student was added",
                type: "success",
                duration: 3000,
              });

              vm.show_student_form = false;
              vm.getAllStartData();
            }
          })
          .catch((error) => {
            vm.save_student_loading = false;
            if (error.response.status === 400) {
              eventBus.$emit("openDialogMessage", {
                title: "Students already exist",
                message: "The student has already created an account",
                action_label: "Connect to existing account",
                action_close_label: "Close",
                callback: () => {
                  vm.$store
                    .dispatch("CONNECT_EXISTING_USER_TO_CLASS", {
                      email: vm.form_student.email,
                      selected_class: vm.form_student.selected_class,
                    })
                    .then((responce) => {
                      vm.show_student_form = false;
                      vm.$notify({
                        group: "status",
                        title: "Success",
                        text: "Waiting for student to accept invitation",
                        type: "success",
                        duration: 3000,
                      });
                    })
                    .catch(({ response }) => {
                      vm.$notify({
                        group: "status",
                        title: "Warning",
                        text: response.data.error.message,
                        type: "warn",
                        duration: 3000,
                      });
                    });
                },
              });
            }
          });
      } else {
        vm.$store
          .dispatch("UPDATE_SCHOOL_STUDENT", form_data)
          .then((responce) => {
            vm.$notify({
              group: "status",
              title: "Success",
              text: "Student was updated",
              type: "success",
              duration: 3000,
            });
            vm.save_student_loading = false;
            vm.show_student_form = false;
            vm.getAllStartData();
          })
          .catch(({ response }) => {
            vm.$notify({
              group: "status",
              title: "Warning",
              text: response.data.error.message,
              type: "warn",
              duration: 3000,
            });
            vm.save_student_loading = false;
          });
      }
    },
    submitManager() {
      let vm = this;
      vm.save_manager_loading = true;
      let form_data = new FormData();
      form_data.append("id", vm.form_manager.id);
      form_data.append("save_type", vm.form_manager.save_type);
      form_data.append("first_name", vm.form_manager.first_name);
      form_data.append("last_name", vm.form_manager.last_name);
      form_data.append("phone", vm.form_manager.phone);
      form_data.append("email", vm.form_manager.email);
      form_data.append("location", vm.form_manager.location);
      form_data.append("selected_class", vm.form_manager.selected_class);
      if (vm.form_manager.selected_file) {
        form_data.append(
          "selected_file",
          vm.form_manager.selected_file,
          vm.form_manager.selected_file.name
        );
      }
      if (!vm.form_manager.id) {
        vm.$store
          .dispatch("ADD_SCHOOL_MANAGER", form_data)
          .then((responce) => {
            vm.$notify({
              group: "status",
              title: "Success",
              text: "Invitation was sent, wait for instructor to approve",
              type: "success",
              duration: 3000,
            });
            vm.save_manager_loading = false;
            vm.show_manager_form = false;
            vm.getAllStartData();
          })
          .catch((error) => {
            vm.$notify({
              group: "status",
              title: "Warning",
              text: "Error while adding new instructor, Please try again.",
              type: "warn",
              duration: 3000,
            });
            vm.save_manager_loading = false;
          });
      } else {
        vm.$store
          .dispatch("UPDATE_SCHOOL_MANAGER", form_data)
          .then((responce) => {
            vm.$notify({
              group: "status",
              title: "Success",
              text: "Instructor have been updated",
              type: "success",
              duration: 3000,
            });
            vm.save_manager_loading = false;
            vm.show_manager_form = false;
            vm.getAllStartData();
          })
          .catch((error) => {
            vm.$notify({
              group: "status",
              title: "Warning",
              text: "Error while updating instructor, Please try again.",
              type: "warn",
              duration: 3000,
            });
            vm.save_manager_loading = false;
          });
      }
    },
    removeMate(i) {
      let vm = this;
      vm.added_member_list.splice(i, 1);
    },
    addMate(member) {
      let vm = this;
      let obj = {
        id: member.id,
        names: member.first_name + " " + member.last_name,
        picture: member.profile_picture,
      };
      if (!vm.added_member_list.map((item) => item.id).includes(member.id)) {
        vm.added_member_list.push(obj);
        vm.search_consultant_member = "";
      } else {
        vm.$notify({
          group: "status",
          title: "Warning",
          text: "Already selected, Please try other.",
          type: "warn",
          duration: 3000,
        });
      }
    },
    searchPeople(query) {
      this.$store.dispatch("SEARCH_FOR_PROJECT_MEMBERS", { query: query });
    },
    onCloseManageConsultant() {
      let vm = this;
      vm.selected_id = null;
      vm.manage_consultant_component = false;
      vm.member_management_modal = false;
      vm.consultant_list = true;
      vm.getAllStartData();
    },
    manageConsultant(id) {
      let vm = this;
      vm.$router.push({
        name: "organization",
        params: { type: "manage" },
        query: {
          profile_type: "student",
          id: id,
          company_id: parseInt(vm.$route.params.id),
        },
      });
    },
    addMember() {
      let vm = this;
      vm.form_student = {
        save_type: "single",
        id: null,
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        location: "",
        selected_class: null,
        selected_file: null,
      };
      vm.show_student_form = true;
    },
    addManager() {
      let vm = this;
      vm.form_manager = {
        save_type: "single",
        id: null,
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        location: "",
        selected_class: null,
        selected_file: null,
      };
      vm.show_manager_form = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.parent-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .parent-toolbar {
    position: absolute;
    z-index: 999;
    top: 5px;
    left: 5px;
    right: 5px;
  }

  .company-profile-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .company-profile-container {
      padding: 1px;
      width: 100%;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;

      .custom-resizer {
        height: 100%;

        .items {
          height: 100%;
          min-width: 50%;
          width: 50%;
          background: #ffffff;
          border-radius: 5px;
          display: flex;
          flex-direction: column;

          .company-profile-body {
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            height: 100%;

            .list-container {
              display: flex;
              flex-direction: column;
              height: 100%;

              .table-label {
                font-weight: 400;
                font-size: 13px;
                color: #000000;
                display: flex;
                align-items: center;
                padding: 0px 10px;
                height: 35px;
              }

              .table-container {
                height: calc(100% - 35px);
                overflow-y: auto;
                padding: 0px 10px;

                .table-data {
                  border-collapse: collapse;
                  border-spacing: 0;

                  tr {
                    .table-th-stick-top {
                      position: sticky;
                      top: 0;
                    }

                    &.th-tr {
                      background: #fff;

                      th {
                        font-weight: 400;
                        font-size: 12px;
                        color: #969696;
                        z-index: 999999;
                      }
                    }

                    &.row-data {
                      border-bottom: 1px solid #ddd;
                    }

                    &:hover {
                      cursor: pointer;
                    }

                    &.active {
                      background: rgb(248, 248, 248);
                    }

                    th {
                      border-bottom: none;

                      &.center {
                        display: flex;
                        align-content: center;

                        input {
                          position: relative;
                          top: 0px;
                          margin-right: 5px;
                          margin: 0px 20px;
                        }
                      }

                      &.text-center {
                        text-align: center;
                      }
                    }

                    td {
                      text-align: left;
                      border-bottom: none;
                      font-size: 11px;
                      color: rgb(28, 28, 28);

                      .checkbox {
                        margin: 0px 20px;
                      }


                      .email-phone {
                        display: flex;
                        flex-direction: column;

                        .email {
                          font-size: 11px;
                        }

                        .phone {
                          font-size: 10px;
                          color: #707070;
                        }
                      }

                      .menu {
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        button {
                          padding: 0px;
                          position: relative;
                          left: -3px;

                          img {
                            width: 12px;
                          }
                        }
                      }

                      &:first-child {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                      }

                      &:last-child {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                      }

                      &.center {
                        text-align: center;
                      }
                    }
                  }
                }

                .loading-paginated-items {
                  height: 50px;
                  position: relative;
                }
              }
            }
          }

          .empty-widget {
            padding: 0px 20px;

            .empty-widget-body {
              height: 350px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              padding: 0px 0px;

              p {
                text-align: center;
                width: 300px;
              }

              .add-btn {
                width: 180px;
                height: 45px;
                background: #1890a9 0% 0% no-repeat padding-box;
                border-radius: 4px;
                color: #fff;
                outline: none;
                margin-right: 20px;
                display: center;
                justify-content: center;
              }
            }
          }
        }

        .multipane-resizer {
          width: 15px;
        }

        .item-view-more {
          height: 100%;
          overflow-y: auto;
          padding: 20px;
          padding-bottom: 40px;
          background: #fff;
          border-radius: 5px;
          width: 400px;
          min-width: 400px;
          max-width: 50%;

          @media (min-width: 300px) and (max-width: 630px) {
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 999999;
            display: flex;
            justify-content: center;
            padding-right: 0px;
            background: #fff;
          }

          .consultants-item {
            margin-bottom: 10px;
            position: relative;

            @media (min-width: 300px) and (max-width: 630px) {
              padding-top: 40px;
            }

            .consultants-item-header {
              display: flex;
              justify-content: space-between;
              align-items: center;

              @media (min-width: 300px) and (max-width: 630px) {
                padding: 10px 17px;
                position: fixed;
                top: 0px;
                left: 0px;
                right: 0px;
                background: #fff;
              }

              .consultants-item-header-options {
                .close-btn {
                  display: none;
                  border: none;
                  background: none;
                }
              }
            }

            .consultants-item-body {
              padding-bottom: 25px;

              .consultants-info {
                h5 {
                  text-align: left;
                  color: #6e6e6e;
                  font-size: 12px;
                  margin-bottom: 0px;
                }

                p {
                  text-align: left;
                  color: #000;
                  font-size: 11px;
                  margin-top: 4px;
                  margin-bottom: 20px;
                }

                .description {
                  margin-top: -8px;

                  p {
                    font-size: 15px !important;
                  }
                }

                .skills-widget {
                  margin-top: 5px;

                  .skills_content__wrapper {
                    display: grid;
                    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
                    grid-gap: 10px;
                    margin-bottom: 10px;

                    @media (min-width: 300px) and (max-width: 630px) {
                      grid-template-columns: 1fr;
                    }

                    .skill_box {
                      min-width: 0;
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      background: #f3f3f3;
                      border-radius: 10px;

                      .skill_box_header {
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        padding: 0px 15px;

                        label {
                          color: #0884af;
                          width: 100%;
                          font-size: 12px;
                        }
                      }

                      .skill_box_body {
                        padding: 0px 15px;

                        .empty-skills {
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          min-height: 80px;
                          justify-content: center;

                          p {
                            text-align: center;
                            margin-bottom: 20px;
                            color: #8d8d8d;
                            font-size: 12px;
                          }

                          button {
                            width: 133px;
                            height: 36px;
                            background: #1890a9 0% 0% no-repeat padding-box;
                            border-radius: 5px;
                            color: #fff;
                          }
                        }

                        .row__item {
                          display: flex;
                          margin-bottom: 10px;
                          justify-content: space-between;
                          width: 100%;

                          span {
                            color: #000000;
                            font-weight: normal;
                            margin-top: 2px;
                            font-size: 12px;
                            overflow: hidden;
                            text-decoration: none;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }
                  }

                  .next_skills_content__wrapper {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 10px;

                    @media (min-width: 300px) and (max-width: 630px) {
                      grid-template-columns: 1fr;
                    }

                    .skill_box {
                      min-width: 0;
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      background: #f3f3f3;
                      border-radius: 10px;
                      margin-bottom: 15px;

                      .skill_box_header {
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        padding: 0px 15px;

                        label {
                          color: #0884af;
                          width: 100%;
                          font-size: 12px;
                        }
                      }

                      .skill_box_body {
                        padding: 0px 15px;

                        .empty-skills {
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          min-height: 80px;
                          justify-content: center;

                          p {
                            text-align: center;
                            margin-bottom: 20px;
                            color: #8d8d8d;
                          }

                          button {
                            width: 133px;
                            height: 36px;
                            background: #1890a9 0% 0% no-repeat padding-box;
                            border-radius: 5px;
                            color: #fff;
                          }
                        }

                        .row__item {
                          margin-bottom: 10px;
                          display: flex;
                          justify-content: space-between;
                          width: 100%;

                          span {
                            color: #000000;
                            font-weight: normal;
                            margin-top: 2px;
                            font-size: 12px;
                            overflow: hidden;
                            text-decoration: none;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-transform: capitalize;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .consultants-item-body-option {
                display: flex;
                justify-content: flex-end;

                .toogle-panel-btn {
                  padding: 1px 9px;
                  font-size: 30px;
                  background: transparent;
                  border: none;
                  outline: none;
                  cursor: pointer;
                  color: #ccc;
                  height: 36px;
                  width: 19px;
                  padding: 0;
                  height: 22px;
                  font-size: 20px;
                  position: relative;
                  top: -3px;
                  right: -5px;
                }
              }
            }
          }

          .empty-widget {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 80px;
          }
        }
      }
    }
  }

  .overlay {
    .member-container {
      background: #fff;
      padding: 30px 30px;
      border-radius: 5px;
      width: 450px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      @media (min-width: 300px) and (max-width: 630px) {
        padding: 30px 20px;
      }

      .member-header {
        width: 100%;
        padding-bottom: 30px;

        h3 {
          font-size: 20px;
          margin: 0px;
        }
      }

      .modal-body {
        .form-widget {
          .form-widget-header {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            input[type="radio"] {
              position: relative;
              top: -3px;
              left: -5px;
              margin-right: 8px;
            }

            img {
              margin-left: 5px;
            }

            label {
              cursor: pointer;
            }
          }

          .form-widget-body {
            .btn-add-many {
              color: #1890a9;
            }
          }
        }

        .line {
          border-bottom: 1px solid #ddd;
          margin: 10px 0px;
        }
      }

      .member-managment-option {
        width: 100%;
        padding-top: 20px;
        display: flex;
        justify-content: flex-start;

        .btn-save {
          width: 100px;
          height: 45px;
          background: #1890a9 0% 0% no-repeat padding-box;
          border-radius: 4px;
          color: #fff;
          outline: none;
          margin-right: 20px;
          display: flex !important;
          justify-content: center;
          align-items: center;
        }

        .btn-cancel {
          height: 45px;
          border-radius: 4px;
          color: #1890a9;
          outline: none;
        }
      }
    }
  }
}
</style>