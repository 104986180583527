export const PROCESSMESSAGE_NOTIFICATION_GET_ALL = "PROCESSMESSAGE_NOTIFICATION_GET_ALL";
export const PROCESSMESSAGE_NOTIFICATION_ADD_NEW = "PROCESSMESSAGE_NOTIFICATION_ADD_NEW";
export const PROCESSMESSAGE_NOTIFICATION_UPDATE = "PROCESSMESSAGE_NOTIFICATION_UPDATE";
export const PROCESSMESSAGE_NOTIFICATION_DELETE = "PROCESSMESSAGE_NOTIFICATION_DELETE";


export const PROCESSMESSAGE_EMAIL_GET_ALL = "PROCESSMESSAGE_EMAIL_GET_ALL";
export const PROCESSMESSAGE_EMAIL_ADD_NEW = "PROCESSMESSAGE_EMAIL_ADD_NEW";
export const PROCESSMESSAGE_EMAIL_UPDATE = "PROCESSMESSAGE_EMAIL_UPDATE";
export const PROCESSMESSAGE_EMAIL_DELETE = "PROCESSMESSAGE_EMAIL_DELETE";
export const PROCESSMESSAGE_EMAIL_GET_ALL_OUTBOX = "PROCESSMESSAGE_EMAIL_GET_ALL_OUTBOX";
export const PROCESSMESSAGE_EMAIL_OUTBOX_DELETE = "PROCESSMESSAGE_EMAIL_OUTBOX_DELETE";
export const PROCESSMESSAGE_EMAIL_SIGNATURE_MARK_AS_DEFAULT = "PROCESSMESSAGE_EMAIL_SIGNATURE_MARK_AS_DEFAULT";
export const PROCESSMESSAGE_EMAIL_SIGNATURE_DELETE = "PROCESSMESSAGE_EMAIL_SIGNATURE_DELETE";
export const PROCESSMESSAGE_EMAIL_GET_SIGNATURE = "PROCESSMESSAGE_EMAIL_GET_SIGNATURE";
export const PROCESSMESSAGE_EMAIL_SIGNATURE_ADD_NEW = "PROCESSMESSAGE_EMAIL_SIGNATURE_ADD_NEW";
export const PROCESSMESSAGE_EMAIL_SIGNATURE_UPDATE = "PROCESSMESSAGE_EMAIL_SIGNATURE_UPDATE";
export const PROCESSMESSAGE_EMAIL_GET_DEFAULT_SIGNATURE = "PROCESSMESSAGE_EMAIL_GET_DEFAULT_SIGNATURE";

export const PROCESSMESSAGE_SURVEY_GET_ALL = "PROCESSMESSAGE_SURVEY_GET_ALL";
export const PROCESSMESSAGE_SURVEY_ADD_NEW = "PROCESSMESSAGE_SURVEY_ADD_NEW";
export const PROCESSMESSAGE_SURVEY_UPDATE = "PROCESSMESSAGE_SURVEY_UPDATE";
export const PROCESSMESSAGE_SURVEY_DELETE = "PROCESSMESSAGE_SURVEY_DELETE";

export const PROCESSMESSAGE_SURVEY_DUPLICATE = "PROCESSMESSAGE_SURVEY_DUPLICATE";
export const PROCESSMESSAGE_SEND_EMAIL = "PROCESSMESSAGE_SEND_EMAIL";