<template>
  <div class="progress-wrapper">
    <label v-if="showNumber" :style="{ textAlign: numberPosition }">{{ value }}%</label>
    <div class="progress-container">
      <div class="progress-bar" :style="{ width: value + '%' }"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "progress-widget",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    showNumber: {
      type: Boolean,
      default: true,
    },
    numberPosition: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() { },
  methods: {},
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.progress-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  label {
    width: 100%;
    font-size: 11px;
  }

  .progress-container {
    background: #ebeff2;
    border-radius: 2px;
    height: 6px;
    width: 100%;
    display: flex;

    .progress-bar {
      height: 100%;
      width: 40%;
      border-radius: 2px;
      transition: 400ms ease-in-out;
    }
  }

  &.p-danger {
    .progress-container {
      background: #e3e3e3;

      .progress-bar {
        background: #e30000;
      }
    }
  }

  &.p-warning {
    .progress-container {
      background: #e3e3e3;

      .progress-bar {
        background: #fec42e;
      }
    }
  }

  &.p-success {
    .progress-container {
      background: #e3e3e3;

      .progress-bar {
        background: #1cca00;
      }
    }
  }
}
</style>