<template>
  <div class="widget-wrapper">
    <div class="toolbar-left-container">
      <div class="back-option" v-if="back" @click="goBack">
        <img src="@/assets/icon/allow-left-blue.svg" class="icon" />
        <label class="title">Back</label>
      </div>
      <div class="toolbar-label-widget">
        <img :src="icon" class="icon" />
        <label class="title">{{ title }}</label>
      </div>
    </div>
    <div class="toolbar-right-container">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "widget",
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    back: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() { },
  methods: {
    goBack() {
      let vm = this;
      vm.$emit("onBack", null);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.widget-wrapper {
  height: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0px 10px;
  border-radius: 5px;

  .toolbar-left-container {
    display: flex;
    align-items: center;

    .back-option {
      display: flex;
      align-items: center;
      margin-right: 30px;

      .icon {
        width: 13px;
        margin-right: 10px;
      }

      .title {
        color: #0884af;
        position: relative;
        top: 0px;
      }
    }

    .toolbar-label-widget {
      display: flex;
      align-items: center;

      .icon {
        width: 18px;
        margin-right: 10px;
      }

      .title {
        color: #1ba5a4;
        position: relative;
      }
    }
  }

  .toolbar-right-container {
    display: flex;
    justify-content: flex-end;
  }
}
</style>