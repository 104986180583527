import {
    API,
    CANDIDATES_ADD_NEW_SECTION_URL,
    CANDIDATES_UPDATE_SECTION_URL,
    CANDIDATES_DELETE_SECTION_URL,
    CANDIDATES_ADD_NEW_STORY_URL,
    CANDIDATES_UPDATE_STORY_URL,
    CANDIDATES_GET_ALL_URL,
    CANDIDATES_DELETE_STORY_URL,
    CANDIDATES_MOVE_STORY_URL,
    CANDIDATES_GET_EXISTS_URL,
    CANDIDATES_SEARCH_URL,
    CANDIDATES_FILTER_BY_SORT_URL
} from "../variable";

import axios from 'axios'

import {
    CANDIDATES_ADD_NEW_SECTION,
    CANDIDATES_UPDATE_SECTION,
    CANDIDATES_ADD_NEW_STORY,
    CANDIDATES_DELETE_SECTION,
    CANDIDATES_UPDATE_STORY,
    CANDIDATES_GET_ALL,
    CANDIDATES_DELETE_STORY,
    CANDIDATES_MOVE_STORY,
    CANDIDATES_GET_EXISTS,
    CANDIDATES_SEARCH,
    CANDIDATES_FILTER_BY_SORT
} from "../Actions/candidates";
import router from "../../router";

const state = {

};

const actions = {
    [CANDIDATES_FILTER_BY_SORT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CANDIDATES_FILTER_BY_SORT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });
    },
    [CANDIDATES_SEARCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CANDIDATES_SEARCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });
    },
    [CANDIDATES_GET_EXISTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CANDIDATES_GET_EXISTS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [CANDIDATES_DELETE_SECTION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CANDIDATES_DELETE_SECTION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [CANDIDATES_ADD_NEW_SECTION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CANDIDATES_ADD_NEW_SECTION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [CANDIDATES_UPDATE_SECTION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CANDIDATES_UPDATE_SECTION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });
    },
    [CANDIDATES_ADD_NEW_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CANDIDATES_ADD_NEW_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [CANDIDATES_UPDATE_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CANDIDATES_UPDATE_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [CANDIDATES_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CANDIDATES_GET_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [CANDIDATES_DELETE_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CANDIDATES_DELETE_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [CANDIDATES_MOVE_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CANDIDATES_MOVE_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },

};

const mutations = {};

const getters = {};

export default {
    state,
    getters,
    actions,
    mutations
};