import {
    API,
    TASK_ADD_NEW_SECTION_URL,
    TASK_UPDATE_SECTION_URL,
    TASK_DELETE_SECTION_URL,
    TASK_ADD_NEW_STORY_URL,
    TASK_UPDATE_STORY_URL,
    TASK_GET_ALL_URL,
    TASK_DELETE_STORY_URL,
    TASK_MOVE_STORY_URL,
    TASK_FILTER_BY_TAG_URL,
    TASK_FILTER_BY_SORT_URL,
    TASK_SEARCH_URL,
    TASK_MAKE_COMPLETED_URL
} from "../variable";

import axios from 'axios'

import {
    TASK_ADD_NEW_SECTION,
    TASK_UPDATE_SECTION,
    TASK_ADD_NEW_STORY,
    TASK_DELETE_SECTION,
    TASK_UPDATE_STORY,
    TASK_GET_ALL,
    TASK_DELETE_STORY,
    TASK_MOVE_STORY,
    TASK_FILTER_BY_TAG,
    TASK_FILTER_BY_SORT,
    TASK_SEARCH,
    TASK_MAKE_COMPLETED
} from "../Actions/task";
import router from "../../router";

const state = {

};

const actions = {
    [TASK_MAKE_COMPLETED]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_MAKE_COMPLETED_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [TASK_SEARCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_SEARCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [TASK_FILTER_BY_SORT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_FILTER_BY_SORT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [TASK_FILTER_BY_TAG]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_FILTER_BY_TAG_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [TASK_DELETE_SECTION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_DELETE_SECTION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [TASK_ADD_NEW_SECTION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_ADD_NEW_SECTION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [TASK_UPDATE_SECTION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_UPDATE_SECTION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [TASK_ADD_NEW_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_ADD_NEW_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [TASK_UPDATE_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_UPDATE_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [TASK_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_GET_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [TASK_DELETE_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_DELETE_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [TASK_MOVE_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + TASK_MOVE_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },

};

const mutations = {};

const getters = {};

export default {
    state,
    getters,
    actions,
    mutations
};