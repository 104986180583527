import {
    API,
    LEADS_ADD_NEW_SECTION_URL,
    LEADS_UPDATE_SECTION_URL,
    LEADS_ADD_NEW_STORY_URL,
    LEADS_UPDATE_STORY_URL,
    LEADS_GET_ALL_URL,
    LEADS_DELETE_STORY_URL,
    LEADS_MOVE_STORY_URL,
    LEADS_DELETE_SECTION_URL,
    LEADS_FILTER_BY_SORT_URL,
    LEADS_SEARCH_URL,
    LEADS_FILTER_BY_TAG_URL,
    LEADS_GET_TAGS_URL,
    LEADS_FILTER_BY_CLOSE_DATE_URL,
    LEADS_FILTER_BY_DISPLAY_URL
} from "../variable";

import axios from 'axios'

import {
    LEADS_ADD_NEW_SECTION,
    LEADS_UPDATE_SECTION,
    LEADS_ADD_NEW_STORY,
    LEADS_UPDATE_STORY,
    LEADS_GET_ALL,
    LEADS_DELETE_STORY,
    LEADS_MOVE_STORY,
    LEADS_DELETE_SECTION,
    LEADS_FILTER_BY_SORT,
    LEADS_SEARCH,
    LEADS_FILTER_BY_TAG,
    LEADS_GET_TAGS,
    LEADS_FILTER_BY_CLOSE_DATE,
    LEADS_FILTER_BY_DISPLAY
} from "../Actions/leads";
import router from "../../router";

const state = {

};

const actions = {
    [LEADS_FILTER_BY_DISPLAY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_FILTER_BY_DISPLAY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_FILTER_BY_CLOSE_DATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_FILTER_BY_CLOSE_DATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_FILTER_BY_TAG]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_FILTER_BY_TAG_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_GET_TAGS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_GET_TAGS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_SEARCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_SEARCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_FILTER_BY_SORT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_FILTER_BY_SORT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_DELETE_SECTION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_DELETE_SECTION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_ADD_NEW_SECTION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_ADD_NEW_SECTION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_UPDATE_SECTION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_UPDATE_SECTION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_ADD_NEW_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_ADD_NEW_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_UPDATE_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_UPDATE_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_GET_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_DELETE_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_DELETE_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [LEADS_MOVE_STORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + LEADS_MOVE_STORY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },

};

const mutations = {};

const getters = {};

export default {
    state,
    getters,
    actions,
    mutations
};