export const ADD_COMPANY = "ADD_COMPANY";
export const BUY_COMPANY = "BUY_COMPANY"
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const GET_COMPANY_INFO = "GET_COMPANY_INFO";
export const GET_COMPANY_ADMIN_INFO = "GET_COMPANY_ADMIN_INFO";
export const GET_COMPANY_EMPLOYEES = "GET_COMPANY_EMPLOYEES";

export const ADD_COMPANY_MANAGER_INFO = "ADD_COMPANY_MANAGER_INFO";
export const GET_ME_USER_COMPANY = "GET_ME_USER_COMPANY";
export const DELETE_COMPANY_ACCOUNT = "DELETE_COMPANY_ACCOUNT";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const UPDATE_COMPANY_ACCOUNT_PASSWORD = "UPDATE_COMPANY_ACCOUNT_PASSWORD";
export const UPDATE_COMPANY_INFO = "UPDATE_COMPANY_INFO";
export const UPDATE_MANAGER_INFO = "UPDATE_MANAGER_INFO";
export const ADD_COMPANY_MEMBER = "ADD_COMPANY_MEMBER";
export const GRANT_TO_BE_COMPANY_MEMBER = "GRANT_TO_BE_COMPANY_MEMBER";
export const ADD_PITCH = "ADD_PITCH";
export const SEARCH_FOR_COMPANY_MEMBERS = "SEARCH_FOR_COMPANY_MEMBERS";
export const REMOVE_PITCH = "REMOVE_PITCH";
export const UPDATE_PITCH = "UPDATE_PITCH";
export const SEARCH_PITCH = "SEARCH_PITCH";
export const REMOVE_MEMBER_FROM_PITCH = "REMOVE_MEMBER_FROM_PITCH";
export const REMOVE_MEMBER_FROM_COMPANY = "REMOVE_MEMBER_FROM_COMPANY";
export const GET_CONSULTANT_ALL_DATA = "GET_CONSULTANT_ALL_DATA";
export const EXPORT_CONSULTANT_CV = "EXPORT_CONSULTANT_CV";
export const SAVE_CONSULTANT_INFO = "SAVE_CONSULTANT_INFO";
export const COMPANY_CONSULTANT_UPDATE_PROJECT = "COMPANY_CONSULTANT_UPDATE_PROJECT";
export const COMPANY_CONSULTANT_ADD_NEW_PROJECT = "COMPANY_CONSULTANT_ADD_NEW_PROJECT";
export const COMPANY_CONSULTANT_DELETE_PROJECT = "COMPANY_CONSULTANT_DELETE_PROJECT";
export const COMPANY_CONSULTANT_VERIFY_PROJECT = "COMPANY_CONSULTANT_VERIFY_PROJECT";
export const COMPANY_CONSULTANT_ADD_SKILL = "COMPANY_CONSULTANT_ADD_SKILL";
export const COMPANY_CONSULTANT_DELETE_SKILL = "COMPANY_CONSULTANT_DELETE_SKILL";
export const COMPANY_CONSULTANT_UPDATE_SKILL = "COMPANY_CONSULTANT_UPDATE_SKILL";
export const COMPANY_CONSULTANT_SAVE_SKILL_PRIOLITY = "COMPANY_CONSULTANT_SAVE_SKILL_PRIOLITY"
export const COMPANY_CONSULTANT_UPDATE_EMPLOYMENT = "COMPANY_CONSULTANT_UPDATE_EMPLOYMENT";
export const COMPANY_CONSULTANT_ADD_NEW_EMPLOYMENT = "COMPANY_CONSULTANT_ADD_NEW_EMPLOYMENT";
export const COMPANY_CONSULTANT_DELETE_EMPLOYMENT = "COMPANY_CONSULTANT_DELETE_EMPLOYMENT"
export const COMPANY_CONSULTANT_VERIFY_EMPLOYMENT = "COMPANY_CONSULTANT_VERIFY_EMPLOYMENT";
export const COMPANY_CONSULTANT_UPDATE_EDUCATION = "COMPANY_CONSULTANT_UPDATE_EDUCATION";
export const COMPANY_CONSULTANT_ADD_NEW_EDUCATION = "COMPANY_CONSULTANT_ADD_NEW_EDUCATION";
export const COMPANY_CONSULTANT_DELETE_EDUCATION = "COMPANY_CONSULTANT_DELETE_EDUCATION";
export const COMPANY_CONSULTANT_UPDATE_TRAINING = "COMPANY_CONSULTANT_UPDATE_TRAINING";
export const COMPANY_CONSULTANT_ADD_NEW_TRAINING = "COMPANY_CONSULTANT_ADD_NEW_TRAINING";
export const COMPANY_CONSULTANT_DELETE_TRAINING = "COMPANY_CONSULTANT_DELETE_TRAINING";
export const COMPANY_CONSULTANT_DELETE_CERTIFICATION = "COMPANY_CONSULTANT_DELETE_CERTIFICATION";
export const COMPANY_CONSULTANT_ADD_NEW_CERTIFICATION = "COMPANY_CONSULTANT_ADD_NEW_CERTIFICATION";
export const COMPANY_CONSULTANT_UPDATE_CERTIFICATION = "COMPANY_CONSULTANT_UPDATE_CERTIFICATION";
export const SEARCH_COMPANY_PROJECT = "SEARCH_COMPANY_PROJECT";
export const COMPANY_INVITE_CONSULTANT_AS_MANAGER = "COMPANY_INVITE_CONSULTANT_AS_MANAGER";
export const COMPANY_REQUEST_MARGER_PROFILE_TO_CONSULTANT = "COMPANY_REQUEST_MARGER_PROFILE_TO_CONSULTANT";
export const COMPANY_ADD_NEW_ASSIGNMENT = "COMPANY_ADD_NEW_ASSIGNMENT";
export const COMPANY_UPDATE_ASSIGNMENT = "COMPANY_UPDATE_ASSIGNMENT";
export const COMPANY_ASSING_ASSINGMENT = "COMPANY_ASSING_ASSINGMENT";
export const CONSULTANT_GET_ASSIGNMENT = "CONSULTANT_GET_ASSIGNMENT";
export const CONSULTANT_ACCEPT_ASSIGNMENT = "CONSULTANT_ACCEPT_ASSIGNMENT";
export const CONSULTANT_REJECT_ASSIGNMENT = "CONSULTANT_REJECT_ASSIGNMENT";
export const COMPANY_ADD_PROJECT_TO_PUBLIC = "COMPANY_ADD_PROJECT_TO_PUBLIC";
export const COMPANY_REMOVE_PROJECT_TO_PUBLIC = "COMPANY_REMOVE_PROJECT_TO_PUBLIC";
export const COMPANY_SEND_PITCH = "COMPANY_SEND_PITCH";
export const SEARCH_CUSTOMERS_MEMBERS = "SEARCH_CUSTOMERS_MEMBERS";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_SINGLE_PITCH = "GET_SINGLE_PITCH";
export const CUSTOMER_REJECT_PITCH = "CUSTOMER_REJECT_PITCH";
export const CUSTOMER_ACCEPT_PITCH = "CUSTOMER_ACCEPT_PITCH";
export const CUSTOMER_ADD_PITCH = "CUSTOMER_ADD_PITCH"
export const COMPANY_ADD_SKILL = "COMPANY_ADD_SKILL";
export const COMPANY_SKILL_SAVE_PRIOLITY = "COMPANY_SKILL_SAVE_PRIOLITY";
export const COMPANY_DELETE_SKILL = "COMPANY_DELETE_SKILL"
export const COMPANY_EDIT_SKILL = "COMPANY_EDIT_SKILL";
export const COMPANY_SEND_PITCH_VIA_EMAIL = "COMPANY_SEND_PITCH_VIA_EMAIL";
export const SEARCH_COMPANY_OUR_PROJECT = "SEARCH_COMPANY_OUR_PROJECT";
export const SEARCH_ASIGNED_ASSIGNMENT = "SEARCH_ASIGNED_ASSIGNMENT";
export const GET_COMPANY_PROFILE = "GET_COMPANY_PROFILE";
export const COMPANY_ADD_NEW_CV = "COMPANY_ADD_NEW_CV";
export const COMPANY_APPLY_CV = "COMPANY_APPLY_CV";
export const GET_USER_COMPANY_CV = "GET_USER_COMPANY_CV";
export const COMPANY_DOWNLOAD_CV = "COMPANY_DOWNLOAD_CV";
export const COMPANY_UPDATE_CV = "COMPANY_UPDATE_CV";
export const COMPANY_REMOVE_CV = "COMPANY_REMOVE_CV";
export const COMPANY_CONSULTANT_SAVE_PRIOLITY = "COMPANY_CONSULTANT_SAVE_PRIOLITY";
export const COMPANY_ADD_NEW_CV_FILE = "COMPANY_ADD_NEW_CV_FILE";
export const COMPANY_UPDATE_CV_FILE = "COMPANY_UPDATE_CV_FILE";
export const COMPANY_ADD_NEW_FOLDER = "COMPANY_ADD_NEW_FOLDER";
export const COMPANY_UPDATE_FOLDER = "COMPANY_UPDATE_FOLDER";
export const COMPANY_GET_MY_CV = "COMPANY_GET_MY_CV";
export const COMPANY_CV_DELETE_FILE_FOLDER = "COMPANY_CV_DELETE_FILE_FOLDER";
export const COMPANY_DOWNLOAD_CV_FILE = "COMPANY_DOWNLOAD_CV_FILE";
export const COMPANY_ADD_SELECTED_PROJECTS_TO_PUBLIC = "COMPANY_ADD_SELECTED_PROJECTS_TO_PUBLIC";
export const COMPANY_UPLOAD_NEW_CV = "COMPANY_UPLOAD_NEW_CV";
export const COMPANY_UPDATE_UPLOADED_CV = "COMPANY_UPDATE_UPLOADED_CV";
export const COMPANY_GET_CONSULTANT_CV = "COMPANY_GET_CONSULTANT_CV";
export const REMOVE_MANAGER_FROM_COMPANY = "REMOVE_MANAGER_FROM_COMPANY";
export const COMPANY_DOWNLOAD_CVs_FILE = "COMPANY_DOWNLOAD_CVs_FILE";
export const COMPANY_DOWNLOAD_MILT_CVS = 'COMPANY_DOWNLOAD_MILT_CVS';
export const COMPANY_CONSULTANT_CHANGE_PERMISSION = "COMPANY_CONSULTANT_CHANGE_PERMISSION";
export const COMPANY_DOWNLOAD_MILT_UPLOADED_CVs = "COMPANY_DOWNLOAD_MILT_UPLOADED_CVs";
export const COMPANY_CONSULTANT_SEND_REMAINDER = "COMPANY_CONSULTANT_SEND_REMAINDER";
export const COMPANY_TRANSFER_STUDENT = "COMPANY_TRANSFER_STUDENT";

// Team
export const ORGANIZATION_GET_TEAM = "ORGANIZATION_GET_TEAM";
export const ORGANIZATION_ADD_NEW_TEAM = "ORGANIZATION_ADD_NEW_TEAM";
export const ORGANIZATION_UPDATE_TEAM = "ORGANIZATION_UPDATE_TEAM";
export const ORGANIZATION_DELETE_TEAM = "ORGANIZATION_DELETE_TEAM";
export const ORGANIZATION_ADD_NEW_DEPARTMENT = "ORGANIZATION_ADD_NEW_DEPARTMENT";
export const ORGANIZATION_UPDATE_DEPARTMENT = "ORGANIZATION_UPDATE_DEPARTMENT";
export const ORGANIZATION_DELETE_DEPARTMENT = "ORGANIZATION_DELETE_DEPARTMENT";
export const ORGANIZATION_GET_TEAM_DATA = "ORGANIZATION_GET_TEAM_DATA";
export const ORGANIZATION_TEAM_REMOVE_MEMBER = "ORGANIZATION_TEAM_REMOVE_MEMBER";
export const ORGANIZATION_ADD_NEW_TEAM_MEMBERS = "ORGANIZATION_ADD_NEW_TEAM_MEMBERS";
export const VALIDATE_STUDENT_IF_EXIST_IN_CLASS = "VALIDATE_STUDENT_IF_EXIST_IN_CLASS";
export const SEARCH_PUBLIC_INTERNSHIP = "SEARCH_PUBLIC_INTERNSHIP";

export const ORGANIZATION_SEARCH_DEPARTMENT_TEAM = "ORGANIZATION_SEARCH_DEPARTMENT_TEAM";
export const ORGANIZATION_SEARCH_TEAM_MEMBER = "ORGANIZATION_SEARCH_TEAM_MEMBER";
export const ORGANIZATION_MOVE_TEAM_MEMBER = "ORGANIZATION_MOVE_TEAM_MEMBER";

export const ADD_SCHOOL_MANAGER = "ADD_SCHOOL_MANAGER";
export const ADD_SCHOOL_STUDENT = "ADD_SCHOOL_STUDENT";
export const GET_ALL_SCHOOL_STUDENT = "GET_ALL_SCHOOL_STUDENT";
export const INVITE_SCHOOL_STUDENT = "INVITE_SCHOOL_STUDENT";
export const UPDATE_SCHOOL_STUDENT = "UPDATE_SCHOOL_STUDENT";
export const UPDATE_SCHOOL_MANAGER = "UPDATE_SCHOOL_MANAGER";

export const SCHOOL_DROPDOWN_SEARCH_CLASS = "SCHOOL_DROPDOWN_SEARCH_CLASS";
export const SCHOOL_DROPDOWN_ALL_CLASS = "SCHOOL_DROPDOWN_ALL_CLASS";

export const ORGANIZATION_SEARCH_USER_IN_DEPARTMENT = "ORGANIZATION_SEARCH_USER_IN_DEPARTMENT";
export const CONNECT_EXISTING_USER_TO_CLASS = "CONNECT_EXISTING_USER_TO_CLASS";
export const ORGANIZATION_CLOSE_TEAM = "ORGANIZATION_CLOSE_TEAM";