import axios from "axios";
import {
    API,
    SITE_API,
    SEARCH_BY_ALL_URL,
    SEARCH_BY_COMPANY_URL,
    SEARCH_BY_LOCATION_URL,
    SEARCH_BY_SKILLS_URL,
    SEARCH_INDEX_URL,
    SEARCH_LOCATION_URL
} from "../variable";
import { SEARCH_BY_ALL, SEARCH_BY_COMPANY, SEARCH_BY_LOCATION, SEARCH_BY_SKILLS, SEARCH_INDEX, SEARCH_LOCATION } from "../Actions/search";


const state = {
    consultants: []
};

const mutations = {
    [SEARCH_INDEX]: (state, payload) => {
        state.consultants = payload;
    },
};

const actions = {
    [SEARCH_LOCATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + SEARCH_LOCATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [SEARCH_INDEX]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: API + getters.get_crm_prefix + SEARCH_INDEX_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        })
            .then(response => {
                let data = response.data.map((item) => { return item = item._source })
                commit(SEARCH_INDEX, data);
            })
            .catch(error => {
                console.log(error)
            });
    },
    [SEARCH_BY_LOCATION]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: API + getters.get_crm_prefix + SEARCH_BY_LOCATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        })
            .then(response => {
                let data = response.data.map((item) => { return item = item._source })
                commit(SEARCH_INDEX, data);
            })
            .catch(error => {
                console.log(error)
            });
    },
    [SEARCH_BY_SKILLS]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: API + getters.get_crm_prefix + SEARCH_BY_SKILLS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        })
            .then(response => {
                let data = response.data.map((item) => { return item = item._source })
                commit(SEARCH_INDEX, data);
            })
            .catch(error => {
                console.log(error)
            });
    },
    [SEARCH_BY_ALL]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: API + getters.get_crm_prefix + SEARCH_BY_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        })
            .then(response => {
                let data = response.data.map((item) => { return item = item._source })
                commit(SEARCH_INDEX, data);
            })
            .catch(error => {
                console.log(error)
            });
    },
    [SEARCH_BY_COMPANY]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: API + getters.get_crm_prefix + SEARCH_BY_COMPANY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        })
            .then(response => {
                let data = response.data.map((item) => { return item = item._source })
                commit(SEARCH_INDEX, data);
            })
            .catch(error => {
                console.log(error)
            });
    },
};

const getters = {
    getSearchedConsultants(state) {
        return state.consultants;
    },
};


export default {
    mutations,
    actions,
    getters,
    state
}