import axios from 'axios'
/* --------------------------------------------------------------------
 * ======================== Include action file =====================
 * ------------------------------------------------------------------*/
import {
    ADD_NEW_CUSTOMER,
    GET_CUSTOMER,
    UPDATE_CUSTOMER,
    SEARCH_ON_CUSTOMER,
    GET_ASSIGNMENT,
    ADD_NEW_ASSIGNMENT,
    DELETE_ASSIGNMENT,
    GET_ALL_ASSIGNMENT,
    PUBLISH_ASSIGNMENT,
    UNPUBLISH_ASSIGNMENT,
    UPDATE_ASSIGNMENT,
    GET_CUSTOMER_BY_ID,
    GET_ASSIGNMENT_BY_CUSTOMER_ID,
    SEARCH_ASSIGNMENT,
    DELETE_CUSTOMER_ACCOUNT,
    UPDATE_CUSTOMER_ACCOUNT_PASSWORD
} from '../Actions/customer.js';
/* --------------------------------------------------------------------
 * ======================== Iclude mutation file =====================
 * ------------------------------------------------------------------*/
import {
    SET_CUSTOMER_DATA,
    SET_ASSIGNMENT_DATA,
} from '../Mutations/customer.js';
/* --------------------------------------------------------------------
 * ======================== Include valiable file =====================
 * ------------------------------------------------------------------*/
import {
    SITE_API,
    ADD_NEW_CUSTOMER_URL,
    GET_CUSTOMER_URL,
    UPDATE_CUSTOMER_URL,
    SEARCH_ON_CUSTOMER_URL,
    GET_ASSIGNMENT_URL,
    ADD_NEW_ASSIGNMENT_URL,
    GET_ALL_ASSIGNMENT_URL,
    PUBLISH_ASSIGNMENT_URL,
    UNPUBLISH_ASSIGNMENT_URL,
    DELETE_ASSIGNMENT_URL,
    UPDATE_ASSIGNMENT_URL,
    GET_CUSTOMER_BY_ID_URL,
    GET_ASSIGNMENT_BY_CUSTOMER_ID_URL,
    SEARCH_ASSIGNMENT_URL,
    DELETE_CUSTOMER_ACCOUNT_URL,
    UPDATE_CUSTOMER_ACCOUNT_PASSWORD_URL
} from '../variable';

/* --------------------------------------------------------------------
 * ======================== STATE OBJECT ==============================
 * ------------------------------------------------------------------*/
const state = {
    customer_data: {
        name: "",
        location: "",
        phone: "",
        email: "",
        description: ""
    },
    assignment_data: [],
    assignments: {}
};

/* --------------------------------------------------------------------
 * ======================== MUTATION OBJECT ==========================
 * ------------------------------------------------------------------*/
const mutations = {
    [SET_CUSTOMER_DATA]: (state, payload) => {
        state.customer_data = payload;
    },
    [SET_ASSIGNMENT_DATA]: (state, payload) => {
        console.log("_+_+_")
        payload.map(item => {
            let roles = ''
            let skills = []
            if (!item.location) {
                item.loc = "";
            } else {
                if (item.location.country_id !== 0 && item.location.country_id !== null)
                    item.loc = item.location.country.name;
                if (item.location.city_id !== 0 && item.location.city_id !== null)
                    item.loc += ", " + item.location.city.name;
            }
            item.skills.forEach(skill => {
                skill.roles.forEach(i => {
                    skills.push(i.name);
                });
                skill.tools.forEach(i => {
                    skills.push(i.name);
                });
                skill.domains.forEach(i => {
                    skills.push(i.name);
                });
                skill.competences.forEach(i => {
                    skills.push(i.name);
                });
                skill.languages.forEach(i => {
                    skills.push(i.name);
                });
            });
            item.roles = roles
            item.skills_data = item.skills;
            item.skills = skills;
            return item
        })
        state.assignment_data = payload;
    }
};

/* --------------------------------------------------------------------
 * ======================== ACTION OBJECT ==============================
 * ------------------------------------------------------------------*/
const actions = {
    [DELETE_CUSTOMER_ACCOUNT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_CUSTOMER_ACCOUNT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [UPDATE_CUSTOMER_ACCOUNT_PASSWORD]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_CUSTOMER_ACCOUNT_PASSWORD_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [UPDATE_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_ASSIGNMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [ADD_NEW_CUSTOMER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ADD_NEW_CUSTOMER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [GET_CUSTOMER]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + GET_CUSTOMER_URL,
            method: "POST",
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        })
        request.then(response => {
                commit(SET_CUSTOMER_DATA, response.data);
                dispatch("LOADER_CLOSE");
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error);
            });
        return request;
    },
    [UPDATE_CUSTOMER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + UPDATE_CUSTOMER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken,
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    [SEARCH_ON_CUSTOMER]: ({ commit, dispatch, getters }, payload) => {
        axios({
                url: SITE_API + getters.get_crm_prefix + SEARCH_ON_CUSTOMER_URL,
                method: "POST",
                data: validatePayloadData(payload),
                headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
            })
            .then(response => {})
            .catch(error => {
                console.log(error);
            });
    },
    [GET_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START");
        let request = axios({
            url: SITE_API + getters.get_crm_prefix + GET_ASSIGNMENT_URL,
            method: "POST",
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
        request.then(response => {
                dispatch("LOADER_CLOSE");
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error);
            });
        return request;
    },
    [GET_ALL_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + GET_ALL_ASSIGNMENT_URL,
            method: "POST",
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [PUBLISH_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PUBLISH_ASSIGNMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        })
    },
    [UNPUBLISH_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + UNPUBLISH_ASSIGNMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [DELETE_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
                url: SITE_API + getters.get_crm_prefix + DELETE_ASSIGNMENT_URL,
                method: "POST",
                data: validatePayloadData(payload),
                headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
            }).then(response => {
                dispatch("GET_ALL_ASSIGNMENT");
            })
            .catch(error => {
                console.log(error);
            });
    },
    [ADD_NEW_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ADD_NEW_ASSIGNMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken,
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    [GET_CUSTOMER_BY_ID]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + GET_CUSTOMER_BY_ID_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [GET_ASSIGNMENT_BY_CUSTOMER_ID]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + GET_ASSIGNMENT_BY_CUSTOMER_ID_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [SEARCH_ASSIGNMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + SEARCH_ASSIGNMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    }

};
/* --------------------------------------------------------------------
 * ======================== GETTERS OBJECT ============================
 * ------------------------------------------------------------------*/
const getters = {
    getCustomer(state) {
        return state.customer_data;
    },
    assignment(state) {
        return state.assignment_data;
    }

};


export default {
    mutations,
    actions,
    getters,
    state
}