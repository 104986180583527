<template>
  <div class="wrapper">
    <div class="milt-select-widget" ref="milt_select_widget" @click="shop_pop_up = !shop_pop_up">
      <div class="label">
        <label>{{ selected_item.name | validate }} </label>
        <div class="box-color" :style="{ 'background-color': selected_item.color }"></div>
      </div>
      <img src="@/assets/admin-icon/allow-down.svg" />
    </div>
    <div class="select-items-widget" v-if="shop_pop_up" v-closable="{
      exclude: ['milt_select_widget'],
      handler: 'hidePopUp',
    }">
      <div class="select-item" v-for="(item, index) in input_data" :key="index" @click="selectItem(item)">
        <label>{{ item.name | validate }}</label>
        <div class="box-color" :style="{ 'background-color': item.color }"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "milt-select-widget",
  props: {
    content: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      input_data: [
        {
          name: "Task",
          color: "#0884AF",
        },
        {
          name: "Follow up",
          color: "#1BA5A4",
        },
        {
          name: "Call",
          color: "#FFC273",
        },
        {
          name: "Mail",
          color: "#9FD076",
        },
        {
          name: "Meeting",
          color: "#ACAF5C",
        },
        {
          name: "Other",
          color: "#ED6400",
        },
      ],
      selected_item: {
        name: "Task",
        color: "#0884AF",
      },
      shop_pop_up: false,
    };
  },
  computed: {},
  watch: {
    content(val) {
      let vm = this;
      //vm.input_data = val;
    },
    value(val) {
      let vm = this;
      vm.input_data.forEach((item) => {
        if (item.name == val) {
          vm.selected_item = item;
        }
      });
    },
  },
  mounted() {
    let vm = this;
    //vm.input_data = vm.content;
    if (vm.value) {
      vm.input_data.forEach((item) => {
        if (item.name == vm.value) {
          vm.selected_item = item;
        }
      });
    }
  },
  methods: {
    selectItem(item) {
      let vm = this;
      vm.selected_item = item;
      vm.shop_pop_up = false;
      vm.$emit("onSubmit", vm.selected_item.name);
      vm.$emit("input", vm.selected_item.name);
    },
    hidePopUp() {
      let vm = this;
      vm.shop_pop_up = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.wrapper {
  position: relative;
  width: 100%;
  padding: 0px;

  .milt-select-widget {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;

    .label {
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        margin-bottom: 0px;
      }

      .box-color {
        width: 15px;
        height: 15px;
        margin-left: 10px;
      }
    }
  }

  .select-items-widget {
    position: absolute;
    top: 35px;
    left: 0px;
    right: 0px;
    background: #ffffff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 5px;
    height: 200px;
    overflow-y: auto;
    z-index: 999999;

    .select-item {
      border-bottom: 1px solid #f2f4f8;
      padding: 10px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      label {
        margin-top: 2px;
        margin-left: 7px;
        cursor: pointer;
      }

      .box-color {
        width: 15px;
        height: 15px;
      }
    }
  }
}
</style>