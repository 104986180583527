var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-widget",on:{"click":_vm.onClick}},[(_vm.file_name)?_c('div',{staticClass:"profile-image",class:{ margin_right: _vm.enableRightMargin, 'white-border': _vm.whiteBorder },style:({
      width: _vm.size + 'px',
      height: _vm.size + 'px',
    }),attrs:{"title":_vm.title}},[_c('img',{attrs:{"src":_vm.path + _vm.file_name}})]):(_vm.$validateString(_vm.title) && _vm.$validateString(_vm.title) != ' ')?_c('div',{staticClass:"profile-alphabet",class:{ margin_right: _vm.enableRightMargin, 'white-border': _vm.whiteBorder },style:({
    width: parseInt(_vm.size) + 'px',
    height: parseInt(_vm.size) + 'px',
  }),attrs:{"title":_vm.title}},[_c('span',{style:({
      fontSize: parseInt(_vm.text_size) + 'px',
    })},[_vm._v(_vm._s(_vm._f("validate")(_vm.$nameInitials(_vm.title))))])]):_vm._e(),(_vm.show_name && _vm.showContent)?_c('div',{staticClass:"profile-widget-content",style:({
    width: _vm.contentWidth == 0 ? 'max-content' : _vm.contentWidth + 'px',
  }),attrs:{"title":_vm.title}},[(_vm.title)?_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm._f("validate")(_vm.$validateString(_vm.title)))+" ")]):_vm._e(),(_vm.subtitle)?_c('span',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm._f("validate")(_vm.subtitle))+" ")]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }