<template>
  <!-- Real company internship item -->
  <div class="assignments-item" v-if="type == 'company'" :class="{ not_expanded: !item.expanded }">
    <div class="assingment-left-side">
      <div class="assignments-item-brand">
        <input v-if="isCheckBox" v-model="item.checked" @change="checkItem(item)" class="checkbox" type="checkbox" />
        <img v-if="$hasKey(item, 'owner.customer_profile.logo')"
          :src="$fullUrl('images/profiles/' + item.owner.customer_profile.logo)" />
        <img v-else src="@/assets/icons/logo_avatar.svg" />
      </div>
    </div>
    <div class="assingment-right-side">
      <div class="assignments-item-header">
        <div class="content">
          <!-- For company -->
          <div class="internship-details">
            <h1 class="internship-title">{{ item.name }}</h1>

            <div class="internship-row-info" v-if="$hasKey(item, 'owner.customer_profile.name')">
              <label class="company-name">{{
                item.owner.customer_profile.name
              }}</label>
            </div>
            <div class="internship-row-info" v-if="item.expanded && $hasKey(item, 'owner.customer_profile.address')
                ">
              <img src="@/assets/icons/internship/location.svg" class="icon" />
              <span class="company-location">
                {{ item.owner.customer_profile.address }}</span>
            </div>
          </div>
        </div>

        <button class="toogle-panel-btn" @click="item.expanded = !item.expanded">
          <img v-if="item.expanded" src="@/assets/admin-icon/allow-blue-up.svg" />
          <img v-else src="@/assets/admin-icon/allow-blue-down.svg" />
        </button>

        <!-- Not expanded date -->
        <div v-if="!item.expanded" class="not_expanded-time">
          <span v-if="item.from">{{ item.from | formatDate }} - {{ item.to | formatDate }}</span>
        </div>

        <!-- menu -->
        <dropdown-menu v-if="show_option" class="internship-menu">
          <ul>
            <li>
              <a @click.prevent="updateInternship(item)">Update</a>
            </li>
            <li v-if="!isInArchive">
              <app-dialog v-if="!item.completed" title="Move LIA to archive"
                ask="Are you sure you want to move this LIA to archive?" yesText="Yes" noText="No"
                @onComfirm="moveInternshipToArchive(item)">
                <a>Move To Archive</a>
              </app-dialog>
            </li>
            <li>
              <app-dialog v-if="!item.completed" title="Delete LIA" ask="Are you sure you want to delete this LIA?"
                yesText="Yes" noText="No" @onComfirm="deleteInternship(item)">
                <a>Delete</a>
              </app-dialog>
            </li>
          </ul>
        </dropdown-menu>
      </div>
      <div class="assignments-item-body" v-if="item.expanded">
        <div class="assignments-info" v-if="item.description">
          <label class="label">Description</label>
          <p class="assignment-desc">
            {{ item.description }}
          </p>
        </div>
        <div class="tags-time" :class="{ no_tag: item.tags == 0 }">
          <div class="tags-widget">
            <div class="tag-item" v-for="(tag, g) in item.tags" :key="g">
              {{ tag.name }}
            </div>
          </div>
          <div class="time">
            <span v-if="item.from">{{ item.from | formatDate }} - {{ item.to | formatDate }}</span>
          </div>
        </div>
      </div>
      <!-- Contact -->
      <div class="account-profile" v-if="item.expanded &&
        !item.published &&
        item.account &&
        item.account.contact_type == 'company_contact'
        ">
        <div class="account-details">
          <h3 class="account-contact-name">Student Contact</h3>
          <p class="account-contact-email" :title="item.account.email">
            {{ item.account.email }}
          </p>
          <p class="account-contact-phone" :title="item.account.phone">
            {{ item.account.phone_alt }}
          </p>
        </div>
      </div>
      <div class="item-option" v-if="item.expanded">
        <!-- Number -->
        <div class="assignment-people">
          <label @click="item.expand_student = !item.expand_student">{{ item.students.length }} Candidates applied to this
            LIA
            <img v-if="!item.expand_student" v-show="item.students.length > 0" class="btn-dropdown"
              src="@/assets/admin-icon/allow-down.svg" />
            <img v-else v-show="item.students.length > 0" class="btn-dropdown" src="@/assets/admin-icon/allow-up.svg" />
          </label>
          <div v-if="item.expand_student" class="people-profiles">
            <div class="people-item" v-for="(student, index) in item.students" :key="index">
              <img class="profile-image" v-if="student.profile_picture"
                :src="$fullUrl('images/profiles/' + student.profile_picture)" alt />

              <img v-else class="profile-image" src="@/assets/icons/logo_avatar.svg" />
              <span>{{ student.first_name + " " + student.last_name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Lia internship item-->
  <div v-else-if="type == 'school'" class="internship-item" :class="{ fullContent: fullContent }">
    <div class="internship-item-header">
      <div class="internship-item-header-left">
        <input v-if="isCheckBox" v-model="item.checked" @change="checkItem(item)" class="checkbox" type="checkbox" />
        <widgetProfile v-if="tab_section == 'private' || item.status == 1" :title="item.company_name" :subtitle="location"
          :size="30" :text_size="13" file_name="" path=""></widgetProfile>
        <widgetProfile v-else :title="item.account && item.account.account
          ? item.account.account.name
          : ''
          " :subtitle="location" :size="30" :text_size="13" file_name="" path=""></widgetProfile>
      </div>
      <div class="internship-item-header-right">
        <div class="internship-item-date">
          <span v-if="item.from">{{ item.from | formatDate }} - {{ item.to | formatDate }}</span>
        </div>
        <!-- menu -->
        <dropdown-menu v-if="show_option" class="internship-menu">
          <ul>
            <li v-if="tab_section == 'private' || item.status == 1">
              <a @click.prevent="assignInternshipTo(item)">Assign to...</a>
            </li>
            <li>
              <a @click.prevent="updateInternship(item)">Update</a>
            </li>
            <li v-if="!isInArchive">
              <app-dialog v-if="!item.completed" title="Move LIA to archive"
                ask="Are you sure you want to move this LIA to archive?" yesText="Yes" noText="No"
                @onComfirm="moveInternshipToArchive(item)">
                <a>Move To Archive</a>
              </app-dialog>
            </li>
            <li>
              <app-dialog v-if="!item.completed" title="Delete LIA" ask="Are you sure you want to delete this LIA?"
                yesText="Yes" noText="No" @onComfirm="deleteInternship(item)">
                <a>Delete</a>
              </app-dialog>
            </li>
          </ul>
        </dropdown-menu>
      </div>
    </div>
    <div class="internship-item-title-widget">
      <h1 class="internship-item-title">{{ item.name | validate }}</h1>
    </div>
    <div v-if="fullContent" class="internship-item-body">
      <div class="internship-item-description">
        {{ item.description | validate }}
      </div>
      <div class="internship-item-tags">
        <template v-for="(tag, index) in item.tags">
          <widgetTagItem :key="index" :value="tag.name" :canClose="false"></widgetTagItem>
        </template>
      </div>

      <!-- Display private lia contact -->
      <div v-if="tab_section == 'private' || item.status == 1" class="internship-contact-header">
        Company’s contact
      </div>

      <div class="intership-contact" v-if="tab_section == 'private' || item.status == 1">
        <div class="internship-contact-avatar">
          <img src="@/assets/icons/contact-white-avatar.svg" />
        </div>
        <div class="internship-contact-info">
          <div class="internship-contact-name">
            {{ item.first_name | validate }}
            {{ item.last_name | validate }}
          </div>
          <div class="internship-contact-email">
            {{ item.email | validate }}
          </div>
          <div class="internship-contact-phone">
            {{ item.phone | validate }}
          </div>
        </div>
      </div>

      <!-- Display school lia contact -->
      <div v-if="item.account && item.account.public_contact" class="internship-contact-header">
        Student’s contact
      </div>
      <div class="intership-contact" v-if="item.account && item.account.public_contact">
        <div class="internship-contact-avatar">
          <img src="@/assets/icons/contact-white-avatar.svg" />
        </div>
        <div class="internship-contact-info">
          <div class="internship-contact-name">
            {{ item.account.first_name | validate }}
            {{ item.account.last_name | validate }}
          </div>
          <div class="internship-contact-email">
            {{ item.account.public_contact | validate }}
          </div>
          <div v-if="false" class="internship-contact-phone">
            {{ item.account.phone_alt | validate }}
          </div>
        </div>
      </div>
      <!-- End of contact -->
    </div>

    <div v-if="fullContent" class="internship-footer">
      <div v-if="showCandidate" class="internship-footer-left">
        <div class="internship-interested-candidates" v-if="item.students.length > 0">
          <template v-for="(student, index) in item.students">
            <widgetProfile v-if="index < 3" :title="student.first_name + ' ' + student.last_name" :key="index"
              :class="index > 0 ? 'move-left' : ''" :size="20" :text_size="8" :file_name="student.profile_picture"
              :path="$fullUrl('images/profiles/')" :showContent="false" :enableRightMargin="false"></widgetProfile>
          </template>
          <div v-if="item.students.length > 3" class="internship-interested-candidates-count">
            <template v-if="tab_section == 'private' || item.status == 1">
              and {{ item.students.length - 3 }} more are assigned
            </template>
            <template v-else>
              and {{ item.students.length - 3 }} more are interested
            </template>
          </div>
        </div>
        <div v-else class="internship-interested-candidates">
          <div v-if="tab_section == 'private' || item.status == 1" class="internship-interested-candidates-count">
            No candidates are assigned
          </div>
          <div v-else class="internship-interested-candidates-count">
            No candidates are interested
          </div>
        </div>
      </div>
      <div class="internship-footer-right"></div>
    </div>
    <div v-if="matched" class="internship-match-widget">Matched</div>

    <div class="worktipe-website-widget">
      <div v-if="tab_section != 'private' && item.status != 1" class="internship-item-worktype">
        <span>{{ item.account.worktype | validate }}</span>
      </div>
      <div class="internship-footer-contact-website">
        <a :href="'https://' + website" target="_blank">{{
          website | validate
        }}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "internship-item",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    show_option: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    isCheckBox: {
      type: Boolean,
      default: false,
    },
    isInArchive: {
      type: Boolean,
      default: false,
    },
    showCandidate: {
      type: Boolean,
      default: true,
    },
    matched: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    fullContent: {
      type: Boolean,
      default: true,
    },
    tab_section: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    location() {
      let vm = this;
      let full_location = "";
      if (!vm.item.published && Array.isArray(vm.item.location)) {
        vm.item.location.forEach((location, index) => {
          full_location += index == 0 ? location : ", " + location;
        });
      } else {
        full_location = vm.item.location;
      }
      return full_location;
    },
    website() {
      let vm = this;
      let website = vm.item.account.website;
      if (website) {
        website = website.replace("https://", "");
        website = website.replace("http://", "");
      }
      return website;
    },
  },
  methods: {
    assignInternshipTo(item) {
      let vm = this;
      vm.$emit("assignTo", item);
    },
    checkItem(item) {
      let vm = this;
      vm.$emit("checked", item);
    },
    deleteInternship(item) {
      let vm = this;
      vm.$emit("delete", item);
    },
    updateInternship(item) {
      let vm = this;
      vm.$emit("update", item);
    },
    moveInternshipToArchive(item) {
      let vm = this;
      vm.$emit("move-to-archive", item);
    },
  },
  created() { },
};
</script>
<style lang="scss" scoped>
.assignments-item {
  background: #f8f8f8;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 20px;
  margin-bottom: 5px;

  @media (min-width: 300px) and (max-width: 630px) {
    padding: 0px 0px;
  }

  &.active {
    background: #1885ad15;
  }

  .assingment-left-side {
    width: 70px;
    margin-right: 10px;

    .assignments-item-brand {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;

      img {
        width: 49px;
        height: 49px;
        border-radius: 50%;
        object-fit: cover;
      }

      .checkbox {}
    }
  }

  .assingment-right-side {
    width: calc(100% - 70px);
    position: relative;

    .assignments-item-header {
      display: flex;
      padding-bottom: 10px;

      .content {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        .internship-details {
          width: 100%;
          display: flex;
          flex-direction: column;

          .internship-title {
            width: calc(100% - 40px);
            font-size: 19px;
            font-weight: 500;
            margin-top: 11px;
            transition: 500ms ease-in-out;
          }

          .internship-row-info {
            display: flex;
            align-items: center;
            margin-bottom: 2px;

            .icon {
              margin-right: 5px;
              width: 15px;
            }

            .company-name {
              font-size: 14px;
            }

            .company-location {
              font-size: 13px;
              color: #1ba5a4;
              position: relative;
              top: 3px;
            }

            .company-worktype {
              color: #1ba5a4;
              font-size: 13px;
              position: relative;
              top: 3px;
            }

            .company-website {
              font-size: 13px;
              position: relative;
              top: 3px;
              color: #0884af;
            }
          }
        }
      }

      .toogle-panel-btn {
        padding: 1px 7px;
        font-size: 16px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        color: #ccc;
        position: absolute;
        top: 0px;
        right: 10px;
        height: 15px;
      }

      .not_expanded-time {
        color: #707070;
        position: absolute;
        top: 32px;
        right: 0px;
        font-size: 12px;
      }
    }

    .assignments-item-body {
      .assignments-info {
        .label {
          margin-bottom: 5px;
          display: block;
        }

        p.assignment-desc {
          color: #707070;
          margin: 0px;
          margin-bottom: 11px;
          text-align: justify;
        }
      }

      .tags-time {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgb(247, 247, 247);
        padding-top: 8px;
        padding-bottom: 8px;

        &.no_tag {
          padding-top: 0px;
          border-top: none;
        }

        .tags-widget {
          display: flex;
          flex-wrap: wrap;
          width: 60%;

          .tag-item {
            background: #ebebeb;
            border-radius: 20px;
            padding: 2px 10px;
            margin-right: 10px;
            font-size: 10px;
            margin-bottom: 10px;
            font-weight: 800;
            color: #000;
          }
        }

        .time {
          span {
            color: #707070;
            font-size: 13px;
          }
        }
      }
    }

    .account-profile {
      display: flex;
      padding-top: 5px;
      padding-right: 10px;
      border-top: 1px solid rgb(247, 247, 247);

      .account-details {
        .account-contact-name {
          font-size: 14px;
          margin: 0px;
        }

        .account-contact-email {
          font-size: 12px;
          margin: 0px;
          position: relative;
          top: -2px;
          color: #0884af;
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .account-contact-phone {
          font-size: 12px;
          margin: 0px;
          position: relative;
          top: -2px;
          color: #0884af;
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .item-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      border-top: 1px solid rgb(247, 247, 247);

      .assignment-people {
        display: flex;
        flex-direction: column;

        label {
          font-size: 12px;

          .btn-dropdown {
            width: 10px;
            cursor: pointer;
          }
        }

        .people-profiles {
          display: flex;
          flex-direction: column;
          position: relative;
          left: -4px;
          margin-top: 5px;

          .people-item {
            display: flex;
            align-items: center;

            .profile-image {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 2px solid #fff;
              object-fit: cover;

              &.override {
                margin-left: -6px;
              }
            }

            span {
              font-size: 11px;
            }
          }
        }
      }

      .assignment-options {
        .applied-badget {
          background: #1ba5a4;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding: 5px 13px;
          color: #fff;
          display: flex;
          align-items: center;
          position: relative;
          right: -19px;

          img {
            width: 15px;
            height: 15px;
            margin-right: 4px;
          }
        }

        .interested-badget {
          background: #888888;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding: 5px 13px;
          color: #fff;
          display: flex;
          align-items: center;
          position: relative;
          right: -19px;

          img {
            width: 15px;
            height: 15px;
            margin-right: 4px;
          }
        }

        .btn-apply {
          background: #0884af;
          border-radius: 5px;
          padding: 5px 28px;
          color: #fff;
        }

        .btn-select {
          background: #1ba5a4;
          border-radius: 5px;
          padding: 5px 28px;
          color: #fff;
        }
      }
    }
  }

  &.not_expanded {
    align-items: center;

    .assignments-item-header {
      padding-bottom: 0px;

      .internship-title {
        font-size: 19px;
        font-weight: 500;
        margin-top: 0px !important;
        transition: 500ms ease-in-out;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.internship-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-bottom: 5px;

  &.fullContent {}

  .internship-item-header {
    width: 100%;
    padding: 7px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .internship-item-header-left {
      display: flex;
      align-items: center;

      .checkbox {
        margin-right: 10px;
      }
    }

    .internship-item-header-right {
      display: flex;
      align-items: center;

      .internship-item-date {
        font-size: 11px;
        color: #707070;
        margin-right: 10px;
      }
    }
  }

  .internship-item-title-widget {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;

    .internship-item-title {
      font-size: 16px;
      font-weight: 500;
      margin: 0px;

      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .internship-item-body {
    padding: 7px 10px;

    .internship-item-description {
      margin-bottom: 10px;
    }

    .internship-item-tags {
      display: flex;
      flex-wrap: wrap;
    }

    .internship-contact-header {
      padding: 5px 8px;
      border-bottom: 1px solid #d8d8d8;
      width: 60%;
      color: #707070;
      font-style: italic;
    }

    .intership-contact {
      display: flex;
      align-items: center;
      padding: 8px;

      .internship-contact-avatar {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 10px;
        }
      }

      .internship-contact-info {
        .internship-contact-email {
          font-size: 13px;
          margin: 0px;
          color: #0884af;
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .internship-contact-phone {
          font-size: 13px;
          margin: 0px;
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .internship-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 17px;
    margin-bottom: 10px;

    .internship-footer-left {
      display: flex;

      .internship-interested-candidates {
        display: flex;
        align-items: center;

        .move-left {
          position: relative;
          left: -5px;

          .profile-image {
            border: 2px solid #fff;
          }
        }

        .internship-interested-candidates-count {
          font-size: 12px;
          color: #707070;
          margin-right: 10px;
          position: relative;
          left: 5px;
        }
      }
    }

    .internship-footer-right {
      .internship-footer-contact-website {
        a {
          color: #0884af;
          text-decoration: none;
        }
      }
    }
  }

  .internship-match-widget {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px;
    color: #1885ad;
  }

  .worktipe-website-widget {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fafafa;
    border-radius: 0px 0px 10px 10px;
    height: 35px;
    padding: 0px 8px;

    .internship-item-worktype {
      font-size: 12px;
      color: #0884af;
      border-radius: 20px;
      padding: 4px 5px;
      text-transform: capitalize;
      background: rgba(8, 132, 175, 0.1);
      border-radius: 5px;
    }

    .internship-footer-contact-website {
      a {
        color: #0884af;
        text-decoration: none;
      }
    }
  }
}
</style>
