<template>
  <div class="page-modal">
    <!-- Single -->
    <widgetModal v-if="account_form.save_type == 'single'" :title="content ? 'Account Information' : 'Add Account'"
      :subtitle="content
        ? 'You can edit information directly from this form'
        : 'Fillin information to add a new account'
        " :btnlefttext="content ? 'Save Changes' : 'Save Account'" btncentertext="Cancel"
      :btnrighttext="'Add Multiple Accounts'" :leftloading="adding_acc_loading" :rightloading="false"
      :centerloading="false" :footerhidden="false" :hiderightbtn="content != null" footer_padding="10px 18px 30px 30px"
      header_padding="30px 0px 0px 31px" body_padding="0px 30px" width="400px" @onLeftClick="onConfirm('save')"
      @onCenterClick="onCancel" @onRightClick="onRightClick">
      <div class="widget-modal-body-noscoll">
        <div class="row">
          <widgetInputWrapper title="Account Name">
            <widgetInput v-model="account_form.name" type="text" placeholder=""></widgetInput>
          </widgetInputWrapper>
        </div>
        <div class="row">
          <widgetInputWrapper title="Account Status">
            <widgetSelect v-model="account_form.status" :opt="[
              { label: 'Active', value: 'active' },
              { label: 'Passive', value: 'passive' },
            ]" placeholder="Acccount status"></widgetSelect>
          </widgetInputWrapper>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Account Category">
            <widgetSelect v-model="account_form.category" :opt="[
              { label: 'Gold', value: 'gold' },
              { label: 'Silver', value: 'silver' },
              { label: 'Bronze', value: 'bronze' }
            ]" placeholder="No category"></widgetSelect>
          </widgetInputWrapper>
        </div>

        <div class="row">
          <widgetInputWrapper title="Account Owner">
            <searchSelectInput url="/company/search/manager" :miltiple="false" type="site" v-model="assigned"
              placeholder="Search account owner">
              <template v-slot:result="{ result }">
                <span class="search-contact-item"><img width="20" v-if="result.profile_picture"
                    :src="$fullUrl('images/profiles/' + result.profile_picture)" />
                  <img v-else width="20" src="@/assets/avatar.png" />
                  {{ result.first_name | validate }}
                  {{ result.last_name | validate }}</span>
              </template>
              <template v-slot:selected="{ selected }">
                <span class="selected-search-contact-item">
                  <img width="20" v-if="selected.profile_picture"
                    :src="$fullUrl('images/profiles/' + selected.profile_picture)" />
                  <img v-else width="20" src="@/assets/avatar.png" />
                  {{ selected.first_name | validate }}</span>
              </template>
            </searchSelectInput>
          </widgetInputWrapper>
        </div>

        <widgetInputWrapper title="Description">
          <widgetTextarea v-model="account_form.description" placeholder=""></widgetTextarea>
        </widgetInputWrapper>
      </div>
    </widgetModal>
    <!-- Many -->
    <widgetModal v-if="account_form.save_type == 'many'" :footerhidden="true" :headerhidden="true" body_padding="0px 0px"
      width="550px">
      <uploadManyItems file="https://storage.googleapis.com/consultified_media/files/templates/AccountTemplate2.xlsx"
        title="Upload Accounts" description="To upload many accounts as file, you need to download the .xl file
              template here. Add contacts to the file and then repload it in the
              next step." actiontext="Upload below the filled document with accounts"
        @back="account_form.save_type = 'single'" @onFile="(file) => {
          if (file) {
            account_form.selected_file = file;
          }
        }
          " :loading="adding_acc_loading" @onSubmit="onConfirm"></uploadManyItems>
    </widgetModal>
  </div>
</template>
<script>
import { eventBus } from "@/main";
export default {
  name: "page-modal",
  props: {
    content: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      account_form: {
        save_type: "single",
        id: null,
        account_id: null,
        name: "",
        description: "",
        phone: "",
        email: "",
        location: "",
        status: "active",
        category: "gold",
        corporate_identity: "",
        website: "",
        worktype: "",
        selected_file: null,
        student_contact: "",
        tags: [],
        tags_added: [],
        tags_removed: [],
        school: null,
        account_owner: null,
        position: "",
        domain: "",
      },
      tags: {
        added: [],
        removed: [],
      },
      assigned: {
        added: [],
        removed: [],
      },
      adding_acc_loading: false,
      schools: []
    };
  },
  computed: {},
  watch: {},
  mounted() {
    let vm = this;
    if (vm.content) {
      vm.updateContent(vm.content);
    } else {
      vm.addNew();
    }
    vm.getSchools();
  },
  methods: {
    getSchools() {
      let vm = this;
      vm.$store
        .dispatch("SCHOOL_DROPDOWN_ALL_CLASS")
        .then((response) => {
          vm.schools = response.data.map((item, index) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
        })
        .catch((error) => {
        });
    },
    updateContent(item) {
      let vm = this;
      vm.account_form.id = item.id;
      vm.account_form.account_id = item.id;
      vm.account_form.name = item.name;
      vm.account_form.description = item.description;
      vm.account_form.phone = item.phone;
      vm.account_form.email = item.email;
      vm.account_form.location = item.location;
      vm.account_form.status = item.status;
      vm.account_form.category = item.category ? item.category.toLowerCase() : "";
      vm.account_form.corporate_identity = item.corporate_identity;
      vm.account_form.website = item.website;
      vm.account_form.worktype = item.worktype;
      vm.account_form.student_contact = item.contact.email;
      vm.assigned = {
        added: [],
        removed: [],
      };
      vm.account_form.account_owner = item.owner;
      if (item.owner) {
        vm.assigned.added.push(item.owner);
      }

      vm.tags = {
        added: [],
        removed: [],
      };


      if (item.tags) {
        item.tags.forEach((item) => {
          eventBus.$set(item, "on_update", true);
          vm.tags.added.push(item);
        });
      }
    },
    addNew() {
      let vm = this;
      vm.assigned = {
        added: [],
        removed: [],
      };
      vm.account_form = {
        save_type: "single",
        id: null,
        account_id: null,
        name: "",
        description: "",
        phone: "",
        email: "",
        location: "",
        status: "active",
        category: "gold",
        corporate_identity: "",
        website: "",
        worktype: "",
        selected_file: null,
        student_contact: "",
        tags: [],
        tags_added: [],
        tags_removed: [],
        school: null,
        account_owner: null,
        position: "",
        domain: "",
      };
    },
    onRightClick() {
      let vm = this;
      // vm.content
      //   ? vm.onGenerateInternship()
      //   : (vm.account_form.save_type = "many");
      vm.account_form.save_type = "many";
    },
    onConfirm(type) {
      let vm = this;

      if (vm.assigned.added.length > 0) {
        vm.account_form.account_owner = JSON.stringify(vm.assigned.added[0]);
      }

      const rules = {
        name: {
          run: (value) => {
            if (value && value.length > 0) {
              return false;
            }
            return "Account name can not be empty";
          },
        },
        email: {
          run: (value) => {
            if (value) {
              const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (re.test(String(value).toLowerCase())) {
                return false;
              }
            }
            return false;
          },
        },
      };
      vm.adding_acc_loading = true;
      vm.account_form.tags = vm.tags.added.map((item) => {
        return item;
      });

      if (!vm.account_form.id) {
        let form_data = new FormData();
        form_data.append("save_type", vm.account_form.save_type);
        let company_id = vm.$getLoggedUser().company_id;
        form_data.append("crm_company_id", company_id);
        if (vm.account_form.save_type == "single") {
          if (vm.$isFieldsValidated(vm.account_form, rules)) {
            form_data.append("id", vm.account_form.id);
            form_data.append("account_id", vm.account_form.account_id);
            form_data.append("name", vm.account_form.name);
            form_data.append("description", vm.account_form.description);
            form_data.append("phone", vm.account_form.phone);
            form_data.append("email", vm.account_form.email);
            form_data.append(
              "location",
              JSON.stringify(vm.account_form.location)
            );
            form_data.append("status", vm.account_form.status);
            form_data.append(
              "student_contact",
              vm.account_form.student_contact
            );
            form_data.append("category", vm.account_form.category);
            form_data.append(
              "corporate_identity",
              vm.account_form.corporate_identity
            );
            form_data.append("tags", JSON.stringify(vm.account_form.tags));
            form_data.append("selected_file", null);
            form_data.append("website", vm.account_form.website);
            form_data.append("worktype", vm.account_form.worktype);
            form_data.append("account_owner", vm.account_form.account_owner);
          } else {
            vm.adding_acc_loading = false;
            return;
          }
        } else {
          if (vm.account_form.selected_file) {
            form_data.append(
              "file",
              vm.account_form.selected_file,
              vm.account_form.selected_file.name
            );
          } else {
            vm.adding_acc_loading = false;
            vm.$notify({
              group: "status",
              title: "Message",
              text: "File template not selected",
              type: "warn",
              duration: 3000,
            });
            return;
          }
        }
        for (var pair of form_data.entries()) {
          let formDataKey = pair[0];
          let formDataValue = pair[1];
          if (formDataValue == "null") {
            form_data.set(formDataKey, "");
          }
        }
        vm.$store
          .dispatch("ACCOUNT_ADD_NEW", form_data)
          .then((response) => {
            vm.adding_acc_loading = false;
            vm.account_form.save_type = "single";
            vm.$notify({
              group: "status",
              title: "Message",
              text: "Now account is added",
              type: "success",
              duration: 3000,
            });
            vm.$emit("success", null);
          })
          .catch((error) => {
            vm.$notify({
              group: "status",
              title: "Message",
              text: "Something went wrong",
              type: "warn",
              duration: 3000,
            });
            console.error({ error });
            vm.adding_acc_loading = false;
          });
      } else {
        if (vm.$isFieldsValidated(vm.account_form, rules)) {
          // For tags
          vm.account_form.tags_added = vm.tags.added
            .filter((item) => !item.on_update)
            .map((item) => {
              return item;
            });
          vm.account_form.tags_removed = vm.tags.removed.map((item) => {
            return item;
          });

          vm.$store
            .dispatch("ACCOUNT_UPDATE", vm.account_form)
            .then((response) => {
              vm.account_form.save_type = "single";
              vm.adding_acc_loading = false;
              let data = [];
              data.push(vm.content);
              if (type == "with_internship") {
                vm.$emit("onGenerateInternship", {
                  type: "many",
                  accounts: data,
                });
              } else {
                vm.$notify({
                  group: "status",
                  title: "Message",
                  text: "Account is now updated",
                  type: "success",
                  duration: 3000,
                });
                vm.$emit("success", null);
              }
            })
            .catch((error) => {
              vm.$notify({
                group: "status",
                title: "Message",
                text: "Something went wrong",
                type: "warn",
                duration: 3000,
              });
              console.error({ error });
              vm.adding_acc_loading = false;
            });
        }
      }
    },
    onCancel() {
      let vm = this;
      vm.$emit("close", null);
    },
    onGenerateInternship() {
      let vm = this;
      vm.onConfirm("with_internship");
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.page-modal {}
</style>