export var CONFIRM_VERIFY_PROJECT = "CONFIRM_VERIFY_PROJECT";
export var VERIFY_PROJECT = "VERIFY_PROJECT";
export var CONFIRM_VERIFY_EMPLOYMENT = "CONFIRM_VERIFY_EMPLOYMENT";
export var VERIFY_EMPLOYMENT = "VERIFY_EMPLOYMENT";
export const SAVE_EMPLOYMENT = "SAVE_EMPLOYMENT";
export const GET_ME_PROFILE_DATA = "GET_ME_PROFILE_DATA";
export const GET_NOTIFICATION_DATA = "GET_NOTIFICATION_DATA";
export const POST_PROJECTS = "POST_PROJECTS"
export const RESET = "RESET"
export const ALLOW_TOBE_MEMBER = "ALLOW_TOBE_MEMBER";
export const ACCEPT_ONLY_PROJECT = "ACCEPT_ONLY_PROJECT";
export const ACCEPT_ONLY_EMPLOYMENT = "ACCEPT_ONLY_EMPLOYMENT";
export const REQUEST_TO_VIEW_PROFILE = "REQUEST_TO_VIEW_PROFILE"
export const GRANT_REQUEST_TO_VIEW_PROFILE = "GRANT_REQUEST_TO_VIEW_PROFILE"
export const CHECK_REQUEST_TO_VIEW_PROFILE = "CHECK_REQUEST_TO_VIEW_PROFILE"
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
export const DECLINE_TOBE_MEMBER = "DECLINE_TOBE_MEMBER";
export const GET_ME_PROFILE = "GET_ME_PROFILE";
export const TITLE_BAR_INFO = "TITLE_BAR_INFO";
export const SET_TITLE_BAR_INFO = "SET_TITLE_BAR_INFO";
export const GET_ME_DASHBOARD_DATA = "GET_ME_DASHBOARD_DATA";
export const ADD_EXPERIENCE = "ADD_EXPERIENCE";
export const ADD_PORTFOLIO = "ADD_PORTFOLIO";
export const ADD_EMPLOYMENT = "ADD_EMPLOYMENT";
export const ADD_QUALIFICATION = "ADD_QUALIFICATION";
export const ADD_EDUCATION = "ADD_EDUCATION";

export const EDIT_PROFILE_INFO = "EDIT_PROFILE_INFO";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE";
export const UPDATE_PORTFOLIO = "UPDATE_PORTFOLIO";
export const UPDATE_EMPLOYMENT = "UPDATE_EMPLOYMENT";
export const UPDATE_QUALIFICATION = "UPDATE_QUALIFICATION";
export const UPDATE_EDUCATION = "UPDATE_EDUCATION";
export const GRANT_PERMISSION = "GRANT_PERMISSION"
export const DELETE_EXPERIENCE = "DELETE_EXPERIENCE";
export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO";
export const DELETE_EMPLOYMENT = "DELETE_EMPLOYMENT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_QUALIFICATION = "DELETE_QUALIFICATION";
export const DELETE_EDUCATION = "DELETE_EDUCATION";


export const VIEW_EDUCATION = "VIEW_EDUCATION";
export const VIEW_EXPERIENCE = "VIEW_EXPERIENCE";
export const VIEW_PORTFOLIO = "VIEW_PORTFOLIO";
export const VIEW_EMPLOYMENT = "VIEW_EMPLOYMENT";
export const VIEW_QUALIFICATION = "VIEW_QUALIFICATION";
export const DELETE_SKILL = "DELETE_SKILL";
export const EDIT_SKILL = "EDIT_SKILL";


export const PUSH_EMPLOYMENT = "PUSH_EMPLOYMENT"
export const SAVE_PROJECT = "SAVE_PROJECT"
export const EDIT_PROJECT_ITEM = "EDIT_PROJECT_ITEM"
export const EDIT_EMPLOYMENT_ITEM = "EDIT_EMPLOYMENT_ITEM"
export const EDIT_QUALIFICATION_ITEM = "EDIT_QUALIFICATION_ITEM"
export const DELETE_QUALIFICATION_ITEM = "DELETE_QUALIFICATION_ITEM"
export const ADD_SKILL = "ADD_SKILL"
export const SEARCH_FOR_PROJECT_MEMBERS = "SEARCH_FOR_PROJECT_MEMBERS"
export const CLEAR_PROJECT_MEMBERS = "CLEAR_PROJECT_MEMBERS"
export const INVITE_USER_VIA_PROJECT = "INVITE_USER_VIA_PROJECT"
export const INVITE_USER_VIA_EMAIL = "INVITE_USER_VIA_EMAIL"
export const EXPORT_CV = "EXPORT_CV"
export const INVITE_USER_VIA_EMPLOYMENT = "INVITE_USER_VIA_EMPLOYMENT"
export const EDIT_PROJECT_MEMBERS = "EDIT_PROJECT_MEMBERS"
export const EDIT_EMPLOYMENT_MEMBERS = "EDIT_EMPLOYMENT_MEMBERS"
export const SAVE_CERTIFICATION = "SAVE_CERTIFICATION"
export const ALLOW_TOBE_EMPLOYMENT_MEMBER = "ALLOW_TOBE_EMPLOYMENT_MEMBER"
export const GET_CONSULTANT_DATA = "GET_CONSULTANT_DATA";
export const AI_ACCOUNT_CHANGE_PASSWORD = "AI_ACCOUNT_CHANGE_PASSWORD";
export const MAIN_SEARCH = "MAIN_SEARCH";

export const ACCOUNT_PROJECT_GET_ALL = "ACCOUNT_PROJECT_GET_ALL";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const GET_ALL_USER_INFO = "GET_ALL_USER_INFO";
export const SUBMIT_SETTING = "SUBMIT_SETTING";
export const GET_SUBMIT_SETTING = "GET_SUBMIT_SETTING"
export const USER_CHECK_WIZARD_STATUS = "USER_CHECK_WIZARD_STATUS";

export const GET_SCHOOLS = "GET_SCHOOLS";
export const GET_SCHOOL = "GET_SCHOOL";
export const DELETE_SCHOOL = "DELETE_SCHOOL";