export const INSTRUCTOR_DELETE_SCHOOL_STUDENT = "INSTRUCTOR_DELETE_SCHOOL_STUDENT";
export const INSTRUCTOR_APPROVE_SCHOOL_STUDENT = "INSTRUCTOR_APPROVE_SCHOOL_STUDENT";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT = "INSTRUCTOR_SEARCH_SCHOOL_STUDENT";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT = "INSTRUCTOR_FILTER_SCHOOL_STUDENT";
export const INSTRUCTOR_SEND_SCHOOL_STUDENT_REMAINDER = "INSTRUCTOR_SEND_SCHOOL_STUDENT_REMAINDER";
export const INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE1 = "INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE1";

export const INSTRUCTOR_SEARCH_INTERNSHIP = "INSTRUCTOR_SEARCH_INTERNSHIP";
export const INSTRUCTOR_SAVE_COMPANY_INTERNSHIP = "INSTRUCTOR_SAVE_COMPANY_INTERNSHIP";
export const INSTRUCTOR_SEARCH_COMPANY = "INSTRUCTOR_SEARCH_COMPANY";
export const INSTRUCTOR_GET_ALL_INTERNSHIP = "INSTRUCTOR_GET_ALL_INTERNSHIP";
export const INSTRUCTOR_GET_ALL_SCHOOL_STUDENT_PHASE2 = "INSTRUCTOR_GET_ALL_SCHOOL_STUDENT_PHASE2";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE2 = "INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE2";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE2 = "INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE2";
export const INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP = "INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP";
export const INSTRUCTOR_SEND_ALL_SCHOOL_STUDENT_REMAINDER = "INSTRUCTOR_SEND_ALL_SCHOOL_STUDENT_REMAINDER";
export const INSTRUCTOR_SEND_ALL_COMPANY_REMAINDER = "INSTRUCTOR_SEND_ALL_COMPANY_REMAINDER";

export const INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE3 = "INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE3";
export const INSTRUCTOR_MATCH_SCHOOL_STUDENT = "INSTRUCTOR_MATCH_SCHOOL_STUDENT";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE3 = "INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE3";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE3 = "INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE3";
export const GET_ALL_SCHOOL_STUDENT_PHASE3 = "GET_ALL_SCHOOL_STUDENT_PHASE3";
export const INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP_STUDENTS = "INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP_STUDENTS";
export const INSTRUCTOR_SUBMIT_CHANGES_PHASE2 = "INSTRUCTOR_SUBMIT_CHANGES_PHASE2";
export const INSTRUCTOR_SEND_PERIODIC_REMAINDER = "INSTRUCTOR_SEND_PERIODIC_REMAINDER";
export const INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE4 = "INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE4";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE4 = "INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE4";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE4 = "INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE4";
export const GET_ALL_SCHOOL_STUDENT_PHASE4 = "GET_ALL_SCHOOL_STUDENT_PHASE4";

export const INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE5 = "INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE5";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE5 = "INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE5";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE5 = "INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE5";
export const GET_ALL_SCHOOL_STUDENT_PHASE5 = "GET_ALL_SCHOOL_STUDENT_PHASE5";
export const INSTRUCTOR_SCHOOL_SEND_EVALUATION = "INSTRUCTOR_SCHOOL_SEND_EVALUATION";
export const INSTRUCTOR_SCHOOL_SEND_SURVEY = "INSTRUCTOR_SCHOOL_SEND_SURVEY";
export const INSTRUCTOR_REMOVED_COMPANY_INTERNSHIP_TO_STUDENT = "INSTRUCTOR_REMOVED_COMPANY_INTERNSHIP_TO_STUDENT";

export const INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE6 = "INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE6";
export const INSTRUCTOR_APPROVE_SCHOOL_STUDENT_PHASE6 = "INSTRUCTOR_APPROVE_SCHOOL_STUDENT_PHASE6";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE6 = "INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE6";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE6 = "INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE6";
export const GET_ALL_SCHOOL_STUDENT_PHASE6 = "GET_ALL_SCHOOL_STUDENT_PHASE6";
export const INVITE_SCHOOL_STUDENT_PHASE6 = "INVITE_SCHOOL_STUDENT_PHASE6";
export const UPLOAD_MILTIPLE_FILE = "UPLOAD_MILTIPLE_FILE";
export const INSTRUCTOR_GET_STUDENT_SURVEY_RESULT = "INSTRUCTOR_GET_STUDENT_SURVEY_RESULT";
export const INSTRUCTOR_SCHOOL_VIEW_SURVEY_RESULT = "INSTRUCTOR_SCHOOL_VIEW_SURVEY_RESULT";

export const INSTRUCTOR_SEND_REMAINDER_SELECTED_STUDENT = "INSTRUCTOR_SEND_REMAINDER_SELECTED_STUDENT";
export const INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE1 = "INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE1";
export const INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE1 = "INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE1";
export const INSTRUCTOR_SCHOOL_STUDENT_DELETE_SELECTED_PHASE1 = "INSTRUCTOR_SCHOOL_STUDENT_DELETE_SELECTED_PHASE1";
export const INSTRUCTOR_SCHOOL_STUDENT_EXPORT_SELECTED_PHASE1 = "INSTRUCTOR_SCHOOL_STUDENT_EXPORT_SELECTED_PHASE1";

export const INSTRUCTOR_MATCH_SCHOOL_SELECTED_STUDENT = "INSTRUCTOR_MATCH_SCHOOL_SELECTED_STUDENT";

export const INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE6 = "INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE6";
export const INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE6 = "INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE6";

export const INSTRUCTOR_UPDATE_COMPANY_INTERNSHIP = "INSTRUCTOR_UPDATE_COMPANY_INTERNSHIP";
export const INSTRUCTOR_DELETE_COMPANY_INTERNSHIP = "INSTRUCTOR_DELETE_COMPANY_INTERNSHIP";


export const INSTRUCTOR_GET_NOTIFICATION = "INSTRUCTOR_GET_NOTIFICATION";
export const INSTRUCTOR_DISMISS_NOTIFICATION = "INSTRUCTOR_DISMISS_NOTIFICATION";
export const PROCESSMESSAGE_SURVEY_REPORT = "PROCESSMESSAGE_SURVEY_REPORT";
export const INSTRUCTOR_GET_NOTIFICATION_STATUS = "INSTRUCTOR_GET_NOTIFICATION_STATUS";

export const INSTRUCTOR_GET_PUBLIC_INTERNSHIP = "INSTRUCTOR_GET_PUBLIC_INTERNSHIP";

export const INSTRUCTOR_STUDENT_CONTRACT_SIGNED = "INSTRUCTOR_STUDENT_CONTRACT_SIGNED";
export const INSTRUCTOR_SIGN_CONTRACT_OF_SELECTED_STUDENT = "INSTRUCTOR_SIGN_CONTRACT_OF_SELECTED_STUDENT";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_STATUS_PHASE3 = "INSTRUCTOR_FILTER_SCHOOL_STUDENT_STATUS_PHASE3";
export const INSTRUCTOR_SEND_NOTIFICATION_RATE_COMPANY_PHASE6 = "INSTRUCTOR_SEND_NOTIFICATION_RATE_COMPANY_PHASE6";
export const INSTRUCTOR_SEND_NOTIFICATION_UPDATE_PROFILE_PHASE6 = "INSTRUCTOR_SEND_NOTIFICATION_UPDATE_PROFILE_PHASE6";