import {
    SEARCH_SKILLS,
    SKILL_SAVE_PRIOLITY,
    GET_SKILLS_BY_TYPE,
} from "../Actions/skills";
import {
    SET_SKILLS_DATA
} from "../Mutations/skills";
import { eventBus } from "../../main";
import router from "../../router";

import {
    SITE_API,
    GET_SKILLS_URL,
    SEARCH_SKILLS_URL,
    SKILL_SAVE_PRIOLITY_URL
} from "../variable";
import axios from 'axios'

const state = {
    skills_data: { tools: [], domains: [], competences: [], roles: [], languages: [] }
};

const actions = {
    [SKILL_SAVE_PRIOLITY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + SKILL_SAVE_PRIOLITY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }

        });
    },
    [GET_SKILLS_BY_TYPE]: ({ commit }, payload) => {
        axios({
                url: SITE_API + getters.get_crm_prefix + GET_SKILLS_URL,
                method: "POST",
                data: validatePayloadData(payload),
                headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }

            }).then(response => {
                commit(SET_SKILLS_DATA, { type: payload.type, data: response.data });
            })
            .catch(error => {});
    },
    [SEARCH_SKILLS]: ({ commit, dispatch, getters }, payload) => {
        axios({
                url: SITE_API + getters.get_crm_prefix + SEARCH_SKILLS_URL,
                method: "POST",
                data: validatePayloadData(payload),
                headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
            }).then(response => {
                commit(SET_SKILLS_DATA, { type: payload.type, data: response.data });
            })
            .catch(error => {});
    },

};

const mutations = {
    [SET_SKILLS_DATA]: (state, payload) => {
        let data = payload.data;
        let type = payload.type;
        state.skills_data[type] = [];
        data.forEach(item => {
            let exist = false;
            state.skills_data[type].forEach(text => {
                if (text.toLowerCase() === item.name.toLowerCase()) {
                    exist = true;
                }
            });
            if (!exist) {
                state.skills_data[type].push(item.name);
            }
        });
    }
};

const getters = {
    getSkillsData(state) {
        return state.skills_data;
    },


};

export default {
    state,
    getters,
    actions,
    mutations
};