<template>
  <div class="page-wrapper">
    <div class="page-header">
      <WidgetToolbar :title="contacts_data ? `Account Contacts (${contacts_data.total})` : `Accounts Contacts`"
        :icon="require('@/assets/icon/accounts-active.svg')">
        <div class="options">
          <widgetSearch v-model="filter_form.query" class="search-account" placeholder="Search here..." icon="right"
            @keyup="searchContacts">
          </widgetSearch>
          <div class="h-spacer"></div>
          <map-helper @onSubmit="onFilterByLocation" v-model="filter_form.location" placeholder="Filter by location"
            style="width: 200px" type="account"></map-helper>
          <div class="h-spacer"></div>
          <widgetDropdown v-model="display_input_filter" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              Display
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in display_filter" :key="index"
                @click="view_type = item.value">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>
          <div class="v-line-divider" style="height: 40px"></div>

          <widgetDropdown v-model="tags_input_filter" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              Tags
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <widgetListSelecter v-model="tags_filter" @close="tags_input_filter = false" @confirm="(data) => {
                tags_input_filter = false;
                filterByTag(data);
              }
                "></widgetListSelecter>
            </div>
          </widgetDropdown>

          <div class="v-line-divider" style="height: 40px"></div>

          <widgetDropdown v-model="roles_input_filter" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              Roles
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <widgetListSelecter v-model="roles_filter" @close="roles_input_filter = false" @confirm="(data) => {
                roles_input_filter = false;
                filterByRole(data);
              }
                "></widgetListSelecter>
            </div>
          </widgetDropdown>

          <div class="v-line-divider" style="height: 40px"></div>

          <widgetDropdown v-model="sort_input_filter" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              Sort
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in sort_filter" :key="index"
                @click="sort_filter_data = item.value">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>



          <widgetButton :loading="false" @click="addNew()" class="btn-add btn-secondary">
            Add Contact +
          </widgetButton>
        </div>
      </WidgetToolbar>
    </div>
    <div class="page-tab">
      <div></div>

      <div class="page-tab-options row">
        <widgetButton v-if="isActiveFilter" class="btn-transparent-secondary fit-content" href="#" @click="clearFilter()">
          Clear Filter</widgetButton>
        <div v-if="show_selected_options" class="h-spacer"></div>
        <widgetDropdown v-if="show_selected_options" v-model="selected_list_dropdown" :right="true" :hover="false"
          :interactive="true" class="selected-dropdown">
          <button class="btn-dropdown">
            <img class="icon" style="height: 20px" src="@/assets/icon/menu-dot.svg" />
          </button>
          <div slot="dropdown" class="dropdown-widget right">
            <a class="dropdown-item" href="#" @click="sendEmailSelectedAccount()">Send Email</a>
            <a class="dropdown-item" href="#" @click="ExportSelectedContact()">Export Contact</a>
            <a class="dropdown-item" href="#" @click="DeleteSelectedContacts()">Delete Selected</a>
          </div>
        </widgetDropdown>
      </div>
    </div>
    <div class="page-body">
      <div class="tab-body">
        <div class="left-panel" :class="{ reduce: contact_details || contact_details_loading }">
          <!-- Table view -->
          <div class="contacts-table" v-if="view_type == 'list'" @scroll="onPageScoll">
            <table class="table-data" v-if="contacts_data && contacts_data.data.length > 0">
              <thead class="table-header">
                <tr>
                  <th>
                    <div class="space-between">
                      <div class="row">
                        <input @change="onSelectAll" class="checkbox" type="checkbox" />
                        <div class="h-spacer"></div>
                        <div>Name</div>
                      </div>
                    </div>
                  </th>

                  <th>Account</th>
                  <th class="phone-hide">Email & Phone</th>
                  <th width="400">
                    <div class="space-between">
                      <div class="row">
                        <div>Position & Domain</div>
                      </div>
                    </div>
                  </th>
                  <th width="400">
                    <div style="white-space:nowrap">Contact owner</div>
                  </th>
                  <th>Tags</th>
                  <th>Roles</th>
                  <th>School</th>
                  <th>Opting</th>
                  <th width="90">More actions</th>
                </tr>
              </thead>
              <tbody class="table-body">
                <template v-for="(item, index) in contacts_data.data">
                  <tr :key="index" class="table-row" :class="{ opted_out: item.optedOut }">
                    <td @click="viewContact(item)" class="opt_blur" :class="{ active: item.active }">
                      <div class="row">
                        <input v-model="item.checked" @change="checkItem(item)" class="checkbox" type="checkbox" />
                        <div class="h-spacer"></div>
                        <widgetProfile :title="item.first_name + ' ' + item.last_name"
                          :subtitle="processLocation(item.location)" file_name="" path="">
                        </widgetProfile>
                      </div>
                    </td>

                    <td class="phone-hide opt_blur">
                      <div @click="
                        $router.push({
                          name: 'account-view',
                          params: { type: 'overview', id: item.account.id },
                        })
                        " class="contact-account">
                        <widgetProfile :title="item.account.name" subtitle="" file_name="" path="" :size="19"
                          :text_size="9">
                        </widgetProfile>
                      </div>
                    </td>
                    <td class="phone-hide opt_blur">
                      <div class="email">
                        {{ item.email | validate }}
                      </div>
                      <div class="phone">
                        {{ item.phone_alt | validate }}
                      </div>
                    </td>
                    <td class="phone-hide opt_blur">
                      <div class="email">
                        {{ item.position | validate }}
                      </div>
                      <div class="phone">
                        {{ item.domain | validate }}
                      </div>
                    </td>
                    <td class="phone-hide opt_blur">
                      <div class="email" v-if="item.owner">
                        {{ item.owner.first_name | validate }} {{ item.owner.last_name | validate }}
                      </div>
                    </td>

                    <td class="opt_blur">
                      <widgetTagsViewer :tags="item.tags" @remove="(e) => {
                        removeContactTags(item.id, e.id, e.i, item.tags);
                      }
                        "></widgetTagsViewer>
                    </td>
                    <td class="opt_blur">
                      <widgetTagsViewer :tags="item.roles" @remove="(e) => {
                        removeContactTags(item.id, e.id, e.i, item.tags);
                      }
                        "></widgetTagsViewer>
                    </td>
                    <td class="phone-hide opt_blur">
                      <widgetTagsViewer :tags="item.schools" :canDelete="false"></widgetTagsViewer>
                    </td>
                    <td>
                      <div class="opted-out opt_blur" v-if="item.optedOut">
                        Opt-out
                      </div>
                    </td>
                    <td>
                      <div class="menu">
                        <dropdownMenu>
                          <ul>
                            <li v-if="item.optedOut">
                              <a @click="optContact(item, 'in')">Set as Opt-in</a>
                            </li>
                            <li v-else>
                              <a @click="optContact(item, 'out')">Set as Opt-out</a>
                            </li>
                            <li>
                              <a @click="updateContact(item)">Update</a>
                            </li>
                            <li>
                              <app-dialog title="Delete Contact" ask="Are you sure you want to delete this contact?"
                                yesText="Delete" noText="Cancel" @onComfirm="deleteContact(item.id)">
                                <a>Delete Contact</a>
                              </app-dialog>
                            </li>
                          </ul>
                        </dropdownMenu>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>

            <!-- Loading widget -->
            <div class="content-loading-container" v-if="contact_loading">
              <loading :height="20" color="#1ba5a4" :opacity="1" :width="20" :active.sync="contact_loading"
                :is-full-page="false" :can-cancel="false" background-color="#fff" class="loader"></loading>
            </div>

          </div>
          <!-- Grid View -->
          <div class="contact-grid-widget" @scroll="onPageScoll" v-else>
            <div class="contacts-items" v-if="contacts_data && contacts_data.data.length > 0">
              <div class="contact-item" v-for="(item, index) in contacts_data.data" :key="index"
                @click="viewContact(item)" :class="{ active: item.active }">
                <img src="@/assets/contact-avatar.svg" />
                <div class="contact-details-info">
                  <h3>
                    {{ (item.first_name + " " + item.last_name) | validate }}
                  </h3>
                  <h5>{{ item.title | validate }}</h5>
                  <p class="email">{{ item.email | validate }}</p>
                  <p class="phone">{{ item.phone_alt | validate }}</p>
                </div>
                <dropdown-menu class="menu">
                  <ul>
                    <li>
                      <a @click.prevent="updateContact(item)" href>Update Contact</a>
                    </li>
                    <li>
                      <app-dialog title="Delete Contact" ask="Are you sure you want to delete this contact?"
                        yesText="Delete" noText="Cancel" @onComfirm="deleteContact(item.id)">
                        <a>Delete Contact</a>
                      </app-dialog>
                    </li>
                  </ul>
                </dropdown-menu>
              </div>
            </div>
            <!-- Loading widget -->
            <div class="content-loading-container" v-if="contact_loading">
              <loading :height="20" color="#1ba5a4" :opacity="1" :width="20" :active.sync="contact_loading"
                :is-full-page="false" :can-cancel="false" background-color="#fff" class="loader"></loading>
            </div>
          </div>

          <!-- Not found -->
          <div v-if="contacts_data &&
            contacts_data.data.length == 0 &&
            !contact_loading
            " class="not-found">
            <widgetNotFoundWidget title="No Contact Found" content="Looks like you haven’t added any contact yet"
              btntext="Add new contact" @click="addNew()">
              <img class="widget-img" src="@/assets/admin-icon/add-student-icon.svg" />
            </widgetNotFoundWidget>
          </div>
        </div>

        <!-- Right panel -->
        <div class="right-panel" :class="{ active: contact_details || contact_details_loading }"
          @scroll="onPageDetailsScoll">
          <loading :height="20" color="#1ba5a4" :opacity="1" :width="20" :active.sync="contact_details_loading"
            :is-full-page="false" :can-cancel="false" background-color="#fff" class="loader"></loading>

          <template v-if="contact_details">
            <!-- Contact details -->
            <div class="contact-card" :class="{ stick_card: stickContactCard }">
              <div class="contact-details-header" :class="{ stick: stickContactCard }">
                <widgetProfile :title="`${contact_details.first_name} ${contact_details.last_name}`" subtitle=""
                  file_name="" path="" :size="stickContactCard ? 80 : 100" :text_size="30" :showContent="false"
                  :whiteBorder="true" class="contact-profile-image">
                </widgetProfile>
                <div class="contact-card-info">
                  <h3>
                    {{ contact_details.first_name | validate }} {{ " " }}
                    {{ contact_details.last_name | validate }}
                  </h3>
                  <h5>{{ contactLocation }}</h5>
                </div>
              </div>
              <p>{{ contact_details.title | validate }}</p>
              <p>{{ contact_details.email | validate }}</p>
              <p>{{ contact_details.phone_alt | validate }}</p>
              <p>{{ contact_details.website | validate }}</p>
              <p>{{ contact_details.public_contact | validate }}</p>
              <p></p>
              <div class="tags" v-if="contact_details.tags">
                <widgetTagItem v-for="(tag, index) in contact_details.tags" :key="index" :value="tag.name"
                  :canClose="true" @remove="removeTags(tag.id, index)"></widgetTagItem>
              </div>
              <p class="comment">
                {{ contact_details.description | validate }}
              </p>

              <p v-if="contact_details.worktype == 'onsite'" class="worktype">
                On site
              </p>
              <p v-if="contact_details.worktype == 'remote'" class="worktype">
                Remote
              </p>
              <p v-if="contact_details.worktype == 'hybrid'" class="worktype">
                Hybrid
              </p>
              <p v-if="contact_details.worktype == 'flexible'" class="worktype">
                Flexible
              </p>
            </div>
            <!-- Contact task -->
            <div class="widget-container" v-if="contact_details.tasks && contact_details.tasks.length > 0">
              <div class="container-header">
                <h3>Tasks</h3>
                <button class="btn-view-all" @click="$router.push({ name: 'task' })">
                  View All
                </button>
              </div>
              <div class="widget-body">
                <div v-for="(task, i) in contact_details.tasks.slice(0, 5)" :key="i" class="card">
                  <div class="card-header">
                    <div class="title">
                      {{ task.name | validate }}
                    </div>
                    <div class="card-type">
                      <label :style="{ color: getColor(task.type) }">{{
                        task.type | validate
                      }}</label>
                      <div :style="{ 'background-color': getColor(task.type) }" class="type-box"></div>
                    </div>
                  </div>
                  <div class="compang-widget">
                    <span class="company-name" v-if="task.account">{{
                      task.account.name | validate
                    }}</span>
                  </div>
                  <div class="members">
                    <div class="member" v-for="(member, index) in task.assignees" :key="index">
                      <assingProfileToolTip :member="member">
                        <img v-if="member.profile_picture" :src="$fullUrl(
                          'images/profiles/' + member.profile_picture
                        )
                          " :class="{ override: index > 0 }" />
                        <img v-else :class="{ override: index > 0 }" src="@/assets/avatar.png" />
                      </assingProfileToolTip>
                      <span v-if="task.assignees.length == 1" class="name">{{
                        (member.first_name + " " + member.last_name) | validate
                      }}</span>
                    </div>
                  </div>
                  <div class="due-date" v-if="task.due_date">
                    {{
                      moment(task.due_date).format("ddd, DD MMM YYYY")
                      | validate
                    }}
                  </div>
                </div>
              </div>
            </div>
            <!-- Contact lead -->
            <div class="widget-container" v-if="contact_details.leads && contact_details.leads.length > 0">
              <div class="container-header">
                <h3>Leads</h3>
                <button class="btn-view-all" @click="$router.push({ name: 'leads' })">
                  View All
                </button>
              </div>
              <div class="widget-body">
                <div v-for="(lead, i) in contact_details.leads.slice(0, 5)" :key="i" class="lead-card">
                  <div class="card-header"></div>
                  <div class="title-widget">
                    <span class="lead-name">{{ lead.name | validate }}</span>
                  </div>
                  <div class="compang-widget" v-if="lead.account">
                    <span class="company-name">
                      {{ lead.account.name | validate }}
                    </span>
                  </div>

                  <div class="members">
                    <div class="member" v-for="(member, index) in lead.assignees" :key="index">
                      <assingProfileToolTip :member="member">
                        <img v-if="member.profile_picture" :src="$fullUrl(
                          'images/profiles/' + member.profile_picture
                        )
                          " :class="{ override: index > 0 }" />
                        <img v-else :class="{ override: index > 0 }" src="@/assets/avatar.png" />
                      </assingProfileToolTip>
                      <span v-if="lead.assignees.length == 1" class="name">{{
                        (member.first_name + " " + member.last_name) | validate
                      }}</span>
                    </div>
                  </div>
                  <div class="price">{{ lead.value | validate }} USD</div>
                </div>
              </div>
            </div>
            <!-- Contact Lia -->
            <div class="widget-container" v-if="contact_details.internships &&
              contact_details.internships.length > 0
              ">
              <div class="container-header">
                <h3>LIA</h3>
                <button class="btn-view-all" @click="$router.push({ name: 'assignment' })">
                  View All
                </button>
              </div>
              <div class="widget-body">
                <internshipItem v-for="(item, index) in contact_details.internships" :item="item" :key="index"
                  :index="index" :class="{ active: false }" :show_option="false" :isCheckBox="false" :isInArchive="false"
                  :fullContent="false" type="school">
                </internshipItem>
              </div>
            </div>
            <div class="footer">
              <button class="btn-cancel" @click="contact_details = null">
                Close
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Add - Update Modal -->
    <addUpdateContactModal v-if="show_form_add_update" :content="selected_contact" acccountType="account_contact"
      @success="contactSaved()" @close="show_form_add_update = false"></addUpdateContactModal>

    <!-- Send Mail -->
    <sendMailModal v-if="show_send_mail_modal" :content="selected_contacts" type="contact" @success="sendEmailSuccess()"
      @close="show_send_mail_modal = false"></sendMailModal>

    <!-- Add LIA -->
    <addInternshipModal v-if="show_add_internship_modal" :content="null" type="gen"
      :account_data="accounts_for_internship" @success="show_add_internship_modal = false"
      @close="show_add_internship_modal = false"></addInternshipModal>
  </div>
</template>
<script>
import { eventBus } from "@/main";
import sendMailModal from "@/views/pages/UsersManagement/tabs/Accounts/components/send_mail_modal.vue";
import addUpdateContactModal from "@/views/pages/UsersManagement/tabs/Accounts/components/add_update_contact_modal.vue";
import addInternshipModal from "@/views/pages/UsersManagement/tabs/internship/components/add_internship_modal.vue";
import internshipItem from "@/views/pages/UsersManagement/tabs/internship/components/internship_item.vue";

export default {
  components: {
    sendMailModal,
    addUpdateContactModal,
    addInternshipModal,
    internshipItem,
  },
  data: () => ({
    show_add_internship_modal: false,
    accounts_for_internship: null,
    selected_contact: null,
    show_send_mail_modal: false,
    selected_contacts: [],
    selected_list_dropdown: false,
    sort_input_filter: false,
    tags_input_filter: false,
    roles_input_filter: false,
    display_input_filter: false,
    tab: 2,
    view_type: "list",
    sort_filter_data: "",
    display_filter: [
      {
        value: "grid",
        name: "Grid",
      },
      {
        value: "list",
        name: "List",
      },
    ],
    tag_filter_data: "",
    tags_filter: [],
    roles_filter: [],
    sort_filter: [
      {
        value: "create_date",
        name: "Create Date",
      },
      {
        value: "name",
        name: "Name",
      },
    ],
    task_types: [
      {
        name: "Task",
        color: "#0884AF",
      },
      {
        name: "Follow up",
        color: "#1BA5A4",
      },
      {
        name: "Call",
        color: "#FFC273",
      },
      {
        name: "Mail",
        color: "#9FD076",
      },
      {
        name: "Meeting",
        color: "#ACAF5C",
      },
      {
        name: "Other",
        color: "#ED6400",
      },
    ],
    data_filter: [
      {
        value: "mine",
        name: "My Contacts",
      },
      {
        value: "all",
        name: "All Contacts",
      },
    ],
    filter_data: "all",
    show_form_add_update: false,
    show_view_contant_modal: false,
    contacts_data: null,
    contact_details: null,
    show_selected_options: false,
    filter_form: {
      type: "all",
      contact_type: "account_contact",
      location: "",
      url_type: "",
      query: "",
      tags: [],
      roles: [],
      sort: "",
      next_page_url: "",
    },
    contact_loading: false,
    isAllContactSelected: false,
    contact_details_loading: false,
    stickContactCard: false,
  }),
  mounted() {
    let vm = this;
    vm.getContactsData("init");
  },
  computed: {
    contactLocation() {
      let vm = this;
      let full_location = "";
      if (vm.contact_details && Array.isArray(vm.contact_details.location)) {
        vm.contact_details.location.forEach((location, index) => {
          full_location += index == 0 ? location : ", " + location;
        });
      }
      return full_location;
    },
    isActiveFilter: function () {
      let vm = this;
      return (
        vm.filter_form.type == "mine" ||
        vm.filter_form.query ||
        vm.filter_form.tags.length > 0 ||
        vm.filter_form.roles.length > 0 ||
        vm.filter_form.sort ||
        vm.filter_form.location
      );
    },
  },
  methods: {
    processLocation(locations) {
      let vm = this;
      let full_location = "";
      if (locations && Array.isArray(locations)) {
        locations.forEach((location, index) => {
          full_location += index == 0 ? location : ", " + location;
        });
      }
      return full_location;
    },
    onPageDetailsScoll(e) {
      let vm = this;
      if (e.target.scrollTop > 150) {
        vm.stickContactCard = true;
      } else {
        vm.stickContactCard = false;
      }
    },
    onFilterByLocation(data) {
      let vm = this;
      if (data.name) {
        vm.filter_form.location = data.name;
        vm.getContactsData("init");
      }
    },
    removeContactTags(contact_id, id, index, tags) {
      let vm = this;
      vm.$store
        .dispatch("CONTACT_DELETE_TAG", {
          id: id,
          contact_id: contact_id,
        })
        .then((response) => {
          tags.splice(index, 1);
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Tag removed",
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    addInternship() {
      let vm = this;
      eventBus.$emit("openDialogMessage", {
        title: "Generate LIA",
        message: "We'd like to let you know that not all contacts can create LIAs. Just the LIA contacts are able to create LIA. As a result, the total number of internships may differ from the total number of contacts on your list.",
        action_label: "Proceed",
        callback: () => {
          let payload = {
            type: "many",
            origin: "contact",
            contacts: vm.validateChecked("data"),
            allSelected: vm.isAllContactSelected
          };
          vm.accounts_for_internship = payload;
          vm.show_add_internship_modal = true;
        },
        action_close_label: "Cancel",
      });

    },
    onPageScoll({ target }) {
      let vm = this;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100) {
        if (
          !vm.contact_loading &&
          vm.contacts_data.current_page < vm.contacts_data.last_page
        ) {
          vm.getContactsData("next");
        }
      }
    },
    sendEmailSuccess() {
      let vm = this;
      vm.show_send_mail_modal = false;
    },
    sendEmailSelectedAccount() {
      let vm = this;
      vm.selected_contacts = vm.validateChecked("data");
      if (vm.selected_contacts.length == 1) {
        if (vm.selected_contacts[0].optedOut) {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Selected contact opted out",
            type: "warn",
            duration: 3000,
          });
          return;
        }
      }
      vm.show_send_mail_modal = true;
    },
    ExportSelectedContact() {
      let vm = this;
      let ids = vm.validateChecked("data");
      vm.$store
        .dispatch("ACCOUNT_CONTACT_EXPORT", {
          ids: ids.map((item) => item.id),
          all: vm.isAllContactSelected,
          filters: vm.filter_form,
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "contact.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    onSelectAll({ target }) {
      let vm = this;
      vm.isAllContactSelected = target.checked;
      if (target.checked) {
        vm.contacts_data.data = vm.contacts_data.data.map((item) => {
          eventBus.$set(item, "checked", true);
          return item;
        });
      } else {
        vm.contacts_data.data = vm.contacts_data.data.map((item) => {
          eventBus.$set(item, "checked", false);
          return item;
        });
      }
      vm.validateChecked();
    },
    checkItem(item) {
      let vm = this;
      vm.validateChecked();
    },
    validateChecked(type = "validate") {
      let vm = this;
      if (type == "validate") {
        vm.show_selected_options =
          vm.contacts_data.data
            .map((item) => item.checked)
            .filter((item) => item == true).length > 0;
        return;
      }
      return vm.contacts_data.data
        .filter((item) => item.checked == true)
        .map((item) => item);
    },
    selectTab(type) {
      let vm = this;
      vm.$emit("tab", type);
    },
    getColor(val) {
      let vm = this;
      let color = "";
      vm.task_types.forEach((item) => {
        if (item.name == val) {
          color = item.color;
        }
      });
      return color;
    },
    getContactsTags() {
      let vm = this;
      vm.$store
        .dispatch("CONTACTS_GET_TAGS")
        .then((response) => {
          vm.tags_filter = response.data.map((item) => {
            return {
              value: item.name,
              name: item.name,
            };
          });
        })
        .catch((error) => { });
    },
    getContactsRoles() {
      let vm = this;
      vm.$store
        .dispatch("CONTACTS_GET_ROLES")
        .then((response) => {
          vm.roles_filter = response.data.map((item) => {
            return {
              value: item.name,
              name: item.name,
            };
          });
        })
        .catch((error) => { });
    },

    // Filter functions
    clearFilter() {
      let vm = this;
      vm.filter_form.query = "";
      vm.filter_form.tags = [];
      vm.filter_form.roles = [];
      vm.filter_form.sort = "";
      vm.filter_form.next_page_url = "";
      vm.filter_form.location = "";
      vm.getContactsData("init");
    },
    filterBySort(val) {
      let vm = this;
      vm.filter_form.sort = val;
      vm.getContactsData("init");
    },
    filterByTag(tags) {
      let vm = this;
      vm.filter_form.tags = tags;
      vm.getContactsData("init");
    },
    filterByRole(roles) {
      let vm = this;
      vm.filter_form.roles = roles;
      vm.getContactsData("init");
    },
    searchContacts({ target }) {
      let vm = this;
      vm.filter_form.query = target.value;
      vm.getContactsData("init");
    },
    filterContactByType(type) {
      let vm = this;
      vm.filter_form.type = type;
      vm.getContactsData("init");
    },
    // ====
    optContact(item, status) {
      let vm = this;
      vm.$store
        .dispatch("ACCOUNT_CONTACT_OPTING", {
          contact_id: item.id,
          status: status,
        })
        .then((response) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text:
              status == "out"
                ? "Contact was opted out"
                : "Contact opted in successfully",
            type: "success",
            duration: 3000,
          });
          if (status == "out") {
            item.optedOut = true;
          } else {
            item.optedOut = false;
          }
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    deleteContact(id) {
      let vm = this;
      vm.$store
        .dispatch("ACCOUNT_CONTACT_DELETE", { contact_id: id })
        .then((response) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Contact deleted successfully",
            type: "success",
            duration: 3000,
          });
          vm.getContactsData("init");
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    DeleteSelectedContacts() {
      let vm = this;
      let ids = vm.validateChecked("data");
      vm.$store
        .dispatch("ACCOUNT_CONTACT_DELETE_SELECTED", {
          ids: ids.map((item) => item.id),
          all: vm.isAllContactSelected,
          filters: vm.filter_form,
        })
        .then((response) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Contact deleted successfully",
            type: "success",
            duration: 3000,
          });
          vm.getContactsData("init");
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    viewContact(item) {
      let vm = this;
      vm.contacts_data.data.forEach((item) => {
        item.active = false;
      });
      eventBus.$set(item, "active", true);
      vm.getContantDetails(item.id);
    },
    getContantDetails(id) {
      let vm = this;
      vm.contact_details_loading = true;
      vm.$store
        .dispatch("ACCOUNT_CONTACT_DETAILS", {
          id: id,
        })
        .then((responce) => {
          vm.contact_details_loading = false;
          vm.contact_details = responce.data;
        })
        .catch((error) => {
          vm.contact_details_loading = false;
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    addNew() {
      let vm = this;
      vm.selected_contact = null;
      vm.show_form_add_update = true;
    },
    contactSaved() {
      let vm = this;
      vm.selected_contact = null;
      vm.show_form_add_update = false;
      vm.getContactsData("init");
    },
    updateContact(item) {
      let vm = this;
      vm.selected_contact = item;
      vm.show_form_add_update = true;
    },
    getContactsData(type = "init") {
      let vm = this;
      vm.contact_loading = true;
      if (type == "init") {
        vm.contacts_data = null;
      }
      vm.filter_form.url_type = type;
      vm.filter_form.next_page_url = vm.contacts_data
        ? vm.contacts_data.next_page_url
        : "";
      vm.$store
        .dispatch("ACCOUNT_CONTACT_GET_DATA", vm.filter_form)
        .then((responce) => {
          vm.contact_loading = false;
          vm.getContactsTags();
          vm.getContactsRoles();
          responce.data.data = responce.data.data.map((item, index) => {
            if (vm.isAllContactSelected) {
              eventBus.$set(item, "checked", true);
            } else {
              eventBus.$set(item, "checked", false);
            }
            eventBus.$set(item, "active", false);
            eventBus.$set(item, "expand_student", false);
            if (index == 0 && type == "init") {
              eventBus.$set(item, "expanded", true);
            } else {
              eventBus.$set(item, "expanded", false);
            }
            if (typeof item.schools === 'string') {
              eventBus.$set(item, "schools", JSON.parse(item.schools));
            } else if (item.schools == null) {
              eventBus.$set(item, "schools", []);
            }
            return item;
          });

          if (type == "init") {
            vm.contacts_data = responce.data;
            if (vm.contacts_data.current_page < vm.contacts_data.last_page) {
              vm.getContactsData("next");
            }
          } else {
            vm.contacts_data.next_page_url = responce.data.next_page_url;
            vm.contacts_data.current_page = responce.data.current_page;
            vm.contacts_data.last_page = responce.data.last_page;
            responce.data.data.forEach((item) => {
              vm.contacts_data.data.push(item);
            });
          }
        })
        .catch((error) => {
          vm.contact_loading = false;
          console.error({ error });
        });
    },
  },
  watch: {
    sort_filter_data(val) {
      let vm = this;
      vm.filterBySort(val);
    },
    filter_data(val) {
      let vm = this;
      vm.filterContactByType(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ebebeb;
  padding: 5px;

  .page-header {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;

    .options {
      display: flex;
      align-items: center;

      .search-account {
        width: 250px;
      }

      .btn-add {
        margin-left: 20px;
      }
    }
  }

  .page-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    margin-bottom: 5px;

    .selected-dropdown {
      position: relative;
    }
  }

  .page-body {
    height: calc(100% - 80px);

    .phone-hide {
      @media (min-width: 300px) and (max-width: 630px) {
        display: none;
      }
    }

    .tab-body {
      height: calc(100% - 47px);
      width: 100%;
      overflow-y: unset;
      height: 100%;
      position: relative;
      padding: 0px;
      display: flex;
      justify-content: space-between;

      .left-panel {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease-in-out;
        position: relative;
        background: #fff;
        border-radius: 5px;
        padding: 5px;

        &.reduce {
          width: calc(100% - 410px);
          transition: all 0.3s ease-in-out;
        }

        .contacts-table {
          height: 100%;
          overflow-y: auto;
          min-width: calc(100% - 400px);
          width: 100%;

          .table-data {
            border-collapse: collapse;
            border-spacing: 0;

            .opted_out {
              // background: #efefef;

              .opt_blur {
                // opacity: 0.3;
              }
            }

            tr {
              .table-row {
                border-bottom: 1px solid rgb(207, 207, 207);
              }

              &:hover {
                background: rgb(248, 248, 248);
                cursor: pointer;
              }

              th {
                text-align: left;

                .center {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                }
              }

              td {
                text-align: left;
                font-size: 13px;
                color: rgb(28, 28, 28);

                .end {
                  float: right;
                }

                .account-name {
                  color: #0884af;
                  overflow: hidden;
                  text-decoration: none;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 150px;
                }

                .contact-account {
                  padding: 2px;
                  background: #f7f7f7;
                  border-radius: 5px;
                  width: max-content;
                }

                .email {
                  overflow: hidden;
                  text-decoration: none;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 100px;
                }

                .phone {
                  overflow: hidden;
                  text-decoration: none;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 100px;
                }

                .opted-out {
                  color: #ffffff;
                  background: #a51b1b;
                  border-radius: 5px;
                  text-decoration: none;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  padding: 4px 10px;
                }

                .count-internship {
                  background: #f6f6f6;
                  border-radius: 5px;
                  width: 24px;
                  height: 24px;
                  font-family: "Nunito";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 11px;
                  line-height: 22px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #000000;
                }

                .menu {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                }

                &:first-child {
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                }

                &:last-child {
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                }
              }
            }
          }
        }

        .contact-grid-widget {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow-y: auto;
          min-width: calc(100% - 400px);
          width: 100%;
          background: #fff;
          border-radius: 10px;

          .contacts-items {
            width: 100%;
            display: grid;
            padding: 10px;
            grid-template-columns: calc(33% - 5px) calc(33% - 5px) calc(33% - 5px);
            grid-gap: 10px;

            .contact-item {
              position: relative;
              background: #eeeeee;
              border-radius: 6px;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 15px;
              max-height: 250px;
              cursor: pointer;

              &.active {
                background: #f8f8f8;
                border: none;
              }

              img {
                width: 80px;
                margin-bottom: 7px;
              }

              .contact-details-info {
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                  margin: 0px;
                  font-size: 18px;
                  margin-bottom: 4px;
                }

                h5 {
                  margin: 0px;
                  font-size: 15px;
                  color: #646262;
                  margin-bottom: 10px;
                }

                p {
                  margin: 0px;
                  font-size: 13px;
                  color: #646262;
                }
              }

              .menu {
                position: absolute;
                top: 8px;
                right: 10px;
              }
            }
          }
        }

        .not-found {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          font-size: 20px;
          background: #fff;
          border-radius: 10px;
        }
      }

      .right-panel {
        height: 100%;
        overflow-y: auto;
        padding-bottom: 100px;
        width: 0px;
        position: relative;

        &.active {
          width: 405px;
          transition: all 0.3s ease-in-out;
        }

        @media (min-width: 300px) and (max-width: 630px) {
          position: fixed;
          top: 0px;
          left: 0px;
          bottom: 0px;
          right: 0px;
          z-index: 999999;
        }

        .contact-card {
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 10px;
          padding: 5px;
          background: #fff;
          transition: all 0.3s ease-in-out;

          @media (min-width: 300px) and (max-width: 630px) {
            border: none;
          }

          &.stick_card {
            padding-top: 80px;
            transition: all 0.3s ease-in-out;
          }

          .contact-details-header {
            background: linear-gradient(98.55deg,
                #1ba5a4 2.27%,
                #0884af 95.39%);
            border-radius: 5px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 100px;
            height: 100px;
            width: 395px;

            .contact-profile-image {
              position: relative;
              top: 50px;
              transition: all 0.3s ease-in-out;
            }

            .contact-card-info {
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              top: 50px;
              transition: all 0.3s ease-in-out;

              h3 {
                margin: 0px;
                font-size: 20px;
                margin-bottom: 4px;
              }

              h5 {
                margin: 0px;
                font-size: 14px;
                color: #646262;
                margin-bottom: 10px;
              }
            }

            &.stick {
              position: fixed;
              top: 90px;
              right: 7px;
              width: 400px;
              transition: all 0.3s ease-in-out;
              z-index: 9999;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding-left: 20px;

              .contact-profile-image {
                position: relative;
                top: 0px;
                transition: all 0.3s ease-in-out;
              }

              .contact-card-info {
                position: relative;
                top: 0px;
                transition: all 0.3s ease-in-out;
                display: flex;
                align-items: flex-start;

                h3 {
                  margin: 0px;
                  font-size: 20px;
                  margin-bottom: 4px;
                  color: #fff;
                }

                h5 {
                  margin: 0px;
                  font-size: 13px;
                  color: #fff;
                  margin-bottom: 10px;
                }
              }
            }
          }

          p {
            margin: 0px;
            font-size: 12px;
            margin-bottom: 10px;
            color: #646262;
          }

          p.comment {
            font-size: 12px;
            text-align: justify;
          }

          .menu {
            position: absolute;
            top: 20px;
            right: 10px;
          }

          .tags {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 10px;
            width: 100%;
          }

          .worktype {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 10px;
            width: 100%;
            color: #0884af;
          }
        }

        .widget-container {
          background: #ffffff;
          padding: 10px 10px;
          margin-bottom: 20px;
          border-radius: 5px;

          .container-header {
            display: flex;
            justify-content: space-between;
            padding: 15px 0px;

            h3 {
              font-size: 14px;
              margin: 0px;
            }

            .btn-view-all {
              color: #0884af;
            }
          }

          .widget-body {
            .card {
              width: 100%;
              background: #f5f5f5;
              position: relative;
              padding: 8px 8px;
              border-radius: 5px;
              margin-bottom: 5px;

              .card-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                width: 100%;

                .title {
                  font-size: 14px;

                  color: #000;
                  overflow: hidden;
                  text-decoration: none;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 99%;
                }

                .card-type {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;

                  label {
                    font-size: 10px;
                    white-space: nowrap;
                    text-align: right;
                  }

                  .type-box {
                    height: 15px;
                    width: 15px;
                    border-radius: 2px;
                    margin-left: 5px;
                  }
                }
              }

              .compang-widget {
                display: flex;
                flex-direction: column;
                padding-right: 20px;

                .company-name {
                  color: #707070;
                  font-size: 12px;
                  margin-bottom: 5px;
                }

                .company-contact {
                  display: flex;
                  align-items: center;
                  margin-bottom: 5px;

                  span {
                    font-size: 12px;
                    white-space: nowrap;
                  }

                  .other-contact {
                    color: #ccc;
                    font-size: 12px;
                    white-space: nowrap;
                  }
                }
              }

              .members {
                display: flex;

                .member {
                  display: flex;
                  align-items: center;

                  img {
                    width: 20px;
                    height: 20px;
                    object-fit: cover;
                    margin-right: 4px;
                    border-radius: 50%;
                    border: 2px solid #fff;

                    &.override {
                      margin-left: -10px;
                    }
                  }

                  .name {
                    background: #06a1a11f;
                    padding: 2px 5px;
                    border-radius: 15px;
                    font-size: 11px;
                    color: #04a5a5;
                    display: block;
                  }
                }
              }

              .due-date {
                position: absolute;
                bottom: 18px;
                right: 15px;
                font-size: 10px;
                display: none;
              }

              &.hold {
                border: solid#04a5a5 1px;
                border-radius: 5px;
              }

              &.invisible {
                opacity: 0.5;
              }
            }

            .lead-card {
              width: 100%;
              background: #f5f5f5;
              position: relative;
              padding: 15px 15px;
              cursor: pointer;
              border-radius: 5px;
              margin-bottom: 5px;

              &:hover {
                .card-header {
                  .card-checkbox {
                    display: block;
                  }
                }
              }

              .card-header {
                display: flex;
                justify-content: flex-end;
                position: absolute;
                right: 2px;
                top: 8px;

                .card-checkbox:checked {
                  display: block;
                }

                .card-checkbox {
                  display: none;
                }
              }

              .title-widget {
                margin-bottom: 5px;

                .lead-name {
                  font-size: 14px;

                  color: #000;
                  overflow: hidden;
                  text-decoration: none;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 115px;
                }
              }

              .compang-widget {
                display: flex;
                justify-content: space-between;
                padding-right: 20px;

                .company-name {
                  color: #0884af;
                  font-size: 12px;
                }

                .company-contact {
                  display: flex;
                  align-items: center;
                  margin-bottom: 5px;

                  span {
                    font-size: 12px;
                    white-space: nowrap;
                  }

                  .other-contact {
                    color: #ccc;
                    font-size: 12px;
                    white-space: nowrap;
                  }
                }
              }

              .members {
                margin-top: 5px;
                display: flex;

                .member {
                  display: flex;
                  align-items: center;

                  img {
                    width: 20px;
                    height: 20px;
                    object-fit: cover;
                    margin-right: 4px;
                    border-radius: 50%;
                    border: 2px solid #fff;

                    &.override {
                      margin-left: -10px;
                    }
                  }

                  .name {
                    background: #06a1a11f;
                    padding: 2px 5px;
                    border-radius: 15px;
                    font-size: 11px;
                    color: #04a5a5;
                    display: block;
                  }
                }
              }

              .price {
                color: rgb(134, 134, 134);
                position: absolute;
                bottom: 18px;
                right: 15px;
                font-size: 10px;
              }

              .attachment {
                width: 100%;
                padding: 0px 10px;

                img {
                  width: 100%;
                  height: 70px;
                  object-fit: cover;
                  border-radius: 20px;
                }
              }
            }
          }
        }

        .widget-container {
          background: #ffffff;
          padding: 10px 10px;
          margin-bottom: 10px;
          border-radius: 5px;

          .container-header {
            display: flex;
            justify-content: space-between;
            padding: 15px 0px;

            h3 {
              font-size: 17px;
              margin: 0px;
            }

            .btn-view-all {
              color: #0884af;
            }
          }

          .widget-body {
            .card {
              width: 100%;
              background: #f5f5f5;
              position: relative;
              padding: 8px 8px;
              border-radius: 10px;
              margin-bottom: 10px;
              min-height: 72px;

              .card-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                width: 100%;

                .title {
                  font-size: 17px;
                  color: #000;
                  overflow: hidden;
                  text-decoration: none;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 115px;
                }

                .card-type {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;

                  label {
                    font-size: 10px;
                    white-space: nowrap;
                    text-align: right;
                  }

                  .type-box {
                    height: 15px;
                    width: 15px;
                    border-radius: 2px;
                    margin-left: 5px;
                  }
                }
              }

              .compang-widget {
                display: flex;
                flex-direction: column;
                padding-right: 20px;

                .company-name {
                  color: #707070;
                  margin-bottom: 5px;
                }

                .company-contact {
                  display: flex;
                  align-items: center;
                  margin-bottom: 5px;

                  span {
                    font-size: 12px;
                    white-space: nowrap;
                  }

                  .other-contact {
                    color: #ccc;
                    font-size: 12px;
                    white-space: nowrap;
                  }
                }
              }

              .members {
                display: flex;

                .member {
                  display: flex;
                  align-items: center;

                  img {
                    width: 20px;
                    height: 20px;
                    object-fit: cover;
                    margin-right: 4px;
                    border-radius: 50%;
                    border: 2px solid #fff;

                    &.override {
                      margin-left: -10px;
                    }
                  }

                  .name {
                    background: #06a1a11f;
                    padding: 2px 5px;
                    border-radius: 15px;
                    font-size: 11px;
                    color: #04a5a5;
                    display: block;
                  }
                }
              }

              .due-date {
                position: absolute;
                bottom: 10px;
                right: 15px;
                font-size: 10px;
              }

              &.hold {
                border: solid#04a5a5 1px;
                border-radius: 5px;
              }

              &.invisible {
                opacity: 0.5;
              }
            }

            .lead-card {
              width: 100%;
              background: #f5f5f5;
              position: relative;
              padding: 15px 15px;
              cursor: pointer;
              border-radius: 10px;
              margin-bottom: 10px;

              &:hover {
                .card-header {
                  .card-checkbox {
                    display: block;
                  }
                }
              }

              .card-header {
                display: flex;
                justify-content: flex-end;
                position: absolute;
                right: 2px;
                top: 8px;

                .card-checkbox:checked {
                  display: block;
                }

                .card-checkbox {
                  display: none;
                }
              }

              .title-widget {
                margin-bottom: 5px;

                .lead-name {
                  font-size: 16px;
                  color: #000;
                  overflow: hidden;
                  text-decoration: none;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 115px;
                }
              }

              .compang-widget {
                display: flex;
                justify-content: space-between;
                padding-right: 20px;

                .company-name {
                  color: #0884af;
                }

                .company-contact {
                  display: flex;
                  align-items: center;
                  margin-bottom: 5px;

                  span {
                    font-size: 12px;
                    white-space: nowrap;
                  }

                  .other-contact {
                    color: #ccc;
                    font-size: 12px;
                    white-space: nowrap;
                  }
                }
              }

              .members {
                margin-top: 5px;
                display: flex;

                .member {
                  display: flex;
                  align-items: center;

                  img {
                    width: 20px;
                    height: 20px;
                    object-fit: cover;
                    margin-right: 4px;
                    border-radius: 50%;
                    border: 2px solid #fff;

                    &.override {
                      margin-left: -10px;
                    }
                  }

                  .name {
                    background: #06a1a11f;
                    padding: 2px 5px;
                    border-radius: 15px;
                    font-size: 11px;
                    color: #04a5a5;
                    display: block;
                  }
                }
              }

              .price {
                color: rgb(134, 134, 134);
                position: absolute;
                bottom: 18px;
                right: 15px;
                font-size: 10px;
              }

              .attachment {
                width: 100%;
                padding: 0px 10px;

                img {
                  width: 100%;
                  height: 70px;
                  object-fit: cover;
                  border-radius: 20px;
                }
              }
            }

            ul.assignments-items {
              list-style: none;
              padding-left: 0px;
              margin: 0px;

              @media (min-width: 300px) and (max-width: 630px) {
                padding: 0px 0px;
              }

              .assignments-item {
                background: #f8f8f8;
                border-radius: 10px;
                margin-bottom: 10px;
                position: relative;
                cursor: pointer;
                border: none;

                @media (min-width: 300px) and (max-width: 630px) {
                  padding: 0px 0px;
                }

                &.active {
                  background: #1885ad15;
                }

                .inter-check {
                  position: absolute;
                  top: 4px;
                  left: 3px;
                }

                .assignments-item-header {
                  display: flex;
                  align-items: center;
                  padding: 10px 14px;

                  .assignments-item-brand {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 60px;
                    height: 60px;

                    @media (min-width: 300px) and (max-width: 630px) {
                      width: 60px;
                      height: 60px;
                    }

                    img {
                      width: 50px;
                      height: 50px;
                      border-radius: 50%;
                      object-fit: cover;

                      @media (min-width: 300px) and (max-width: 630px) {
                        width: 50px;
                        height: 50px;
                      }
                    }
                  }

                  .content {
                    display: flex;
                    width: 100%;
                    padding-right: 5px;
                    padding-left: 10px;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding-top: 6px;

                    .title-row {
                      width: 100%;

                      label {
                        font-size: 18px;
                        color: #000;
                        margin-bottom: 3px;
                        overflow: hidden;
                        text-decoration: none;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;
                        display: block;
                      }

                      p.company {
                        margin: 0px;
                        font-size: 16px;
                        margin-bottom: 3px;
                        overflow: hidden;
                        text-decoration: none;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;
                        display: block;
                        color: #6c6c6c;
                      }

                      p.location {
                        color: #1ba5a4;
                        margin: 0px;
                        margin-bottom: 3px;
                        font-size: 14px;
                        overflow: hidden;
                        text-decoration: none;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;
                        display: block;
                      }

                      p.date {
                        color: #6c6c6c;
                        margin: 0px;
                        margin-bottom: 3px;
                        font-size: 14px;
                        overflow: hidden;
                        text-decoration: none;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .footer {
          display: none;
          justify-content: center;
          padding: 30px;

          @media (min-width: 300px) and (max-width: 630px) {
            display: flex;
          }

          .btn-cancel {
            background: #24a5a5;
            height: 50px;
            width: 125px;
            color: #fff;
            outline: none;
            border: none;
            border-radius: 5px;
            margin-right: 15px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.form-widget-modal {
  width: 600px;

  .moder-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-option {
      display: flex;
      align-items: center;

      input {
        margin-right: 5px;
      }

      label {
        margin-top: 5px;
      }
    }
  }

  .modal-body {
    .form-widget {
      .form-widget-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        input[type="radio"] {
          position: relative;
          top: -3px;
          left: -5px;
          margin-right: 8px;
        }

        img {
          margin-left: 5px;
        }

        label {
          cursor: pointer;
        }
      }

      .form-widget-body {
        .template-widget {
          margin-bottom: 30px;

          .btn-download {
            width: 200px;
            height: 45px;
            background: #1890a9 0% 0% no-repeat padding-box;
            border-radius: 4px;
            color: #fff;
            outline: none;
            margin-right: 20px;
            display: flex !important;
            justify-content: center;
            align-items: center;
          }
        }

        .upload-widget {
          display: flex;
          align-items: center;

          .uploader {
            width: 200px;

            .btn-upload {
              width: 200px;
              height: 45px;
              background: #1890a9 0% 0% no-repeat padding-box;
              border-radius: 4px;
              color: #fff;
              outline: none;
              margin-right: 20px;
              display: flex !important;
              justify-content: center;
              align-items: center;

              img {
                margin-left: 6px;
                width: 20px;
                position: relative;
                top: -1px;
              }
            }
          }

          .selected-file {
            display: flex;
            align-items: center;

            img {
              position: relative;
              top: 3px;
            }
          }
        }
      }
    }

    .line {
      border-bottom: 1px solid #ddd;
      margin: 10px 0px;
    }
  }
}

.search-account-item {
  padding: 10px 15px;
  display: block;
  cursor: pointer;

  &:hover {
    background: rgb(250, 250, 250);
  }
}

.selected-search-account-item {
  padding: 5px 12px;
  display: block;
  cursor: pointer;
  border: 1px solid #ddd;
  margin-top: 10px;
  border-radius: 15px;
  font-size: 11px;

  &:hover {
    background: rgb(250, 250, 250);
  }
}
</style>
