import {
    API,
    SITE_API,
    PROCESSMESSAGE_NOTIFICATION_GET_ALL_URL,
    PROCESSMESSAGE_NOTIFICATION_ADD_NEW_URL,
    PROCESSMESSAGE_NOTIFICATION_UPDATE_URL,
    PROCESSMESSAGE_NOTIFICATION_DELETE_URL,

    PROCESSMESSAGE_EMAIL_GET_ALL_URL,
    PROCESSMESSAGE_EMAIL_ADD_NEW_URL,
    PROCESSMESSAGE_EMAIL_UPDATE_URL,
    PROCESSMESSAGE_EMAIL_DELETE_URL,

    PROCESSMESSAGE_EMAIL_GET_ALL_OUTBOX_URL,
    PROCESSMESSAGE_EMAIL_OUTBOX_DELETE_URL,
    PROCESSMESSAGE_EMAIL_SIGNATURE_MARK_AS_DEFAULT_URL,
    PROCESSMESSAGE_EMAIL_SIGNATURE_DELETE_URL,
    PROCESSMESSAGE_EMAIL_GET_SIGNATURE_URL,
    PROCESSMESSAGE_EMAIL_SIGNATURE_ADD_NEW_URL,
    PROCESSMESSAGE_EMAIL_SIGNATURE_UPDATE_URL,
    PROCESSMESSAGE_EMAIL_GET_DEFAULT_SIGNATURE_URL,

    PROCESSMESSAGE_SURVEY_GET_ALL_URL,
    PROCESSMESSAGE_SURVEY_ADD_NEW_URL,
    PROCESSMESSAGE_SURVEY_UPDATE_URL,
    PROCESSMESSAGE_SURVEY_DELETE_URL,
    PROCESSMESSAGE_SURVEY_DUPLICATE_URL,
    PROCESSMESSAGE_SEND_EMAIL_URL,
} from "../variable";

import axios from 'axios'
import {
    PROCESSMESSAGE_NOTIFICATION_GET_ALL,
    PROCESSMESSAGE_NOTIFICATION_ADD_NEW,
    PROCESSMESSAGE_NOTIFICATION_UPDATE,
    PROCESSMESSAGE_NOTIFICATION_DELETE,

    PROCESSMESSAGE_EMAIL_GET_ALL,
    PROCESSMESSAGE_EMAIL_ADD_NEW,
    PROCESSMESSAGE_EMAIL_UPDATE,
    PROCESSMESSAGE_EMAIL_DELETE,


    PROCESSMESSAGE_EMAIL_GET_ALL_OUTBOX,
    PROCESSMESSAGE_EMAIL_OUTBOX_DELETE,
    PROCESSMESSAGE_EMAIL_SIGNATURE_MARK_AS_DEFAULT,
    PROCESSMESSAGE_EMAIL_SIGNATURE_DELETE,
    PROCESSMESSAGE_EMAIL_GET_SIGNATURE,
    PROCESSMESSAGE_EMAIL_SIGNATURE_ADD_NEW,
    PROCESSMESSAGE_EMAIL_SIGNATURE_UPDATE,
    PROCESSMESSAGE_EMAIL_GET_DEFAULT_SIGNATURE,

    PROCESSMESSAGE_SURVEY_GET_ALL,
    PROCESSMESSAGE_SURVEY_ADD_NEW,
    PROCESSMESSAGE_SURVEY_UPDATE,
    PROCESSMESSAGE_SURVEY_DELETE,
    PROCESSMESSAGE_SURVEY_DUPLICATE,
    PROCESSMESSAGE_SEND_EMAIL
} from "../Actions/process_messages.js";

const state = {

};

const actions = {
    [PROCESSMESSAGE_SEND_EMAIL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_SEND_EMAIL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_GET_DEFAULT_SIGNATURE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_GET_DEFAULT_SIGNATURE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_SURVEY_DUPLICATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_SURVEY_DUPLICATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_GET_ALL_OUTBOX]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_GET_ALL_OUTBOX_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_OUTBOX_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_OUTBOX_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_SIGNATURE_MARK_AS_DEFAULT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_SIGNATURE_MARK_AS_DEFAULT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_SIGNATURE_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_SIGNATURE_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_GET_SIGNATURE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_GET_SIGNATURE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_SIGNATURE_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_SIGNATURE_ADD_NEW_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_SIGNATURE_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_SIGNATURE_UPDATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },


    [PROCESSMESSAGE_NOTIFICATION_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_NOTIFICATION_GET_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_NOTIFICATION_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_NOTIFICATION_ADD_NEW_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_NOTIFICATION_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_NOTIFICATION_UPDATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_NOTIFICATION_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_NOTIFICATION_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_GET_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_ADD_NEW_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_UPDATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_EMAIL_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_EMAIL_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_SURVEY_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_SURVEY_GET_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_SURVEY_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_SURVEY_ADD_NEW_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_SURVEY_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_SURVEY_UPDATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [PROCESSMESSAGE_SURVEY_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_SURVEY_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
};

const mutations = {};

const getters = {};

export default {
    state,
    getters,
    actions,
    mutations
};