<template>
  <div class="department-dropdown" v-if="isRolePermited">
    <div class="role-dropdown-widget">
      <div class="label" @click="showMenu()" ref="dropdown_menu">
        <span>{{ select_label }}</span>
        <img src="@/assets/admin-icon/allow-down.svg" />
      </div>
      <div v-closable="{ exclude: ['dropdown_menu'], handler: 'hideMenu' }" class="dropdown-items" v-if="show_dropdown">
        <div class="dropdown-items-header">Change View</div>
        <div class="item" v-for="(item, index) in roles" :key="index" :class="{
          link: item.value == 'super-manager' || item.value == 'teams',
          active: selectedRole == item.value,
        }" @click="onSelectedRole(item.value)">
          <div class="item-label">{{ item.name }}</div>
          <div class="item-subdropmenu" v-if="item.value != 'super-manager' || item.value != 'teams'">
            <div class="sub-item" v-for="(team, index) in teams" :key="index" @click="onSelectedTeam(team.id)"
              v-if="item.value == 'team-manager'">
              {{ team.name }}
            </div>
            <div v-for="(department, index) in departments" :key="index" @click="onSelectedDepartment(department.id)"
              class="sub-item" v-if="item.value == 'department-manager'">
              {{ department.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { eventBus } from "@/main";
export default {
  name: "role-management",
  components: {},
  data() {
    return {
      show_dropdown: false,
      roles: [],
      teams: [],
      departments: [],
      select_label: "School",
      selectedRole: "super-manager",
    };
  },
  watch: {},
  mounted() {
    let vm = this;
    vm.getRoles();
  },
  methods: {
    getDepartmentAndTeamData(item) {
      let vm = this;
      if (item == "team-manager") {
        vm.$store
          .dispatch("GET_ALL_USER_INFO", { prefix: "team-manager" })
          .then((response) => {
            vm.teams = response.data;
            vm.onSelectedRole("none");
          })
          .catch((error) => {
            console.error({ error });
          });
      } else if (item == "super-manager") {
        vm.$store
          .dispatch("GET_ALL_USER_INFO", { prefix: "super-manager" })
          .then((response) => {
            vm.departments = response.data;
            const found = vm.roles.some((el) => el.value === "teams");
            if (!found) {
              let obj = {
                name: "Classes",
                value: "teams",
                order: 5,
              };
              vm.roles.push(obj);
              vm.onSelectedRole("teams");
            }
          })
          .catch((error) => {
            console.error({ error });
          });
      } else if (item == "department-manager") {
        vm.$store
          .dispatch("GET_ALL_USER_INFO", { prefix: "department-manager" })
          .then((response) => {
            vm.departments = response.data;
            const found = vm.roles.some((el) => el.value === "teams");
            if (!found) {
              let obj = {
                name: "Classes",
                value: "teams",
                order: 5,
              };
              vm.roles.push(obj);
              vm.onSelectedRole("teams");
            }
          })
          .catch((error) => {
            console.error({ error });
          });
      }
    },
    showMenu() {
      let vm = this;
      vm.show_dropdown = !vm.show_dropdown;
    },
    hideMenu() {
      let vm = this;
      vm.show_dropdown = false;
    },
    onSelectedRole(type) {
      let vm = this;
      if (type == "super-manager") {
        vm.select_label = "School";
        vm.selectedRole = type;
        vm.hideMenu();
        let department_ids = [];
        vm.departments.forEach((department) => {
          department_ids.push(department.id);
        });
        vm.$emit("onDeapartmentSelected", department_ids);
      } else if (type == "teams") {
        vm.select_label = "Classes";
        vm.selectedRole = type;
        vm.hideMenu();
        let team_ids = [];
        vm.departments.forEach((department) => {
          department.teams.forEach((team) => {
            team_ids.push(team.id);
          });
        });
        vm.$emit("onTeamSelected", team_ids);
      } else {
        vm.$emit("onNoneSelected");
      }
    },
    onSelectedTeam(team_id) {
      let vm = this;
      vm.hideMenu();
      vm.select_label = "Team";
      let team_ids = [];
      team_ids.push(team_id);
      vm.$emit("onTeamSelected", team_ids);
    },
    onSelectedDepartment(department_id) {
      let vm = this;
      vm.hideMenu();
      vm.select_label = "Department";
      let department_ids = [];
      department_ids.push(department_id);
      vm.$emit("onDeapartmentSelected", department_ids);
    },
    getRoles() {
      let vm = this;
      let user_data = JSON.parse(vm.$localStorage.get("logged_user_data"));
      let roles = user_data.roles;
      vm.roles = roles
        .map((item) => {
          vm.getDepartmentAndTeamData(item);
          let obj = {
            name:
              item == "super-manager"
                ? "School"
                : item == "department-manager"
                  ? "My divisions"
                  : item == "team-manager"
                    ? "My Classes"
                    : "",
            value: item,
            order:
              item == "super-manager"
                ? 1
                : item == "department-manager"
                  ? 2
                  : item == "team-manager"
                    ? 3
                    : 4,
          };
          return obj;
        })
        .sort((a, b) => {
          return a.order - b.order;
        });
    },
  },
  computed: {
    isRolePermited() {
      let vm = this;
      if (
        vm.$route.name == "organization" ||
        vm.$route.name == "manage_consultant" ||
        vm.$route.name == "view-team"
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.department-dropdown {
  position: relative;

  .role-dropdown-widget {
    .label {
      height: 30px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid #c5c7d0;
      border-radius: 5px;
      color: #1ba5a4;

      img {
        margin-left: 30px;
      }
    }

    .dropdown-items {
      position: absolute;
      top: 45px;
      z-index: 99999999;
      right: 0px;
      background: #ffffff;
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      width: 200px;

      .dropdown-items-header {
        height: 45px;
        display: flex;
        align-items: center;
        padding: 0px 10px;
        border-bottom: 1px solid #e6e9ef;
      }

      .item {
        padding: 5px 10px;

        &.link {
          cursor: pointer;

          &:hover {
            background: rgb(243, 243, 243);
          }
        }

        .item-label {
          width: 100px;
          padding: 5px;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.active {
          .item-label {
            color: #1ba5a4;
          }
        }

        .item-subdropmenu {
          padding-left: 10px;

          .sub-item {
            cursor: pointer;
            padding: 4px;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:hover {
              background: rgb(243, 243, 243);
            }
          }
        }
      }
    }
  }
}
</style>