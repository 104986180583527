import {
    EDIT_EMPLOYMENT_ITEM
} from "./Actions/user";

import {
    API_,
    SITE_API_,
    FILE_BASE_URL_,
    FILE_MAKER_SERVER_BASE_URL_
} from "./app_url";

export const CONFIRM_VERIFY_PROJECT_URL = '/project/confirm/verify';
export const CONFIRM_VERIFY_EMPLOYMENT_URL = '/employment/confirm/verify';
export const EDIT_SKILL_URL = '/profile/skill/edit';
export const ALLOW_TOBE_MEMBER_URL = '/project/grant/permission';
export const ACCEPT_ONLY_PROJECT_URL = "/project/grant/without/permission";
export const ACCEPT_ONLY_EMPLOYMENT_URL = "/employment/grant/without/permission";
export const ALLOW_TOBE_EMPLOYMENT_MEMBER_URL = '/employment/grant/permission';
export const REQUEST_TO_VIEW_PROFILE_URL = '/notification/request/profile'
export const GRANT_REQUEST_TO_VIEW_PROFILE_URL = '/notification/request/profile/grant'
export const CHECK_REQUEST_TO_VIEW_PROFILE_URL = '/notification/check/profile/permission'
export const RESET_NOTIFICATION_URL = '/notification/set-all-read';
export const GET_NOTIFICATION_DATA_URL = '/notification/view';
export const DECLINE_TOBE_MEMBER_URL = '/project/decline/permission';
export const EDIT_PROJECT_MEMBERS_URL = '/project/edit-members';



export const API = API_;
export const SITE_API = SITE_API_;
export const FILE_MAKER_SERVER_BASE_URL = FILE_MAKER_SERVER_BASE_URL_;

const debug = process.env.NODE_ENV !== "production";
// this file url
export const FILE_BASE_URL = FILE_BASE_URL_;

export const ACCOUNT_TYPE = "school";


export const API_BASE_URL = API;

export const GET_ALL_FEEDS_REQUEST = "/feed/all";
export const GET_LOCALE_REQUEST = "/locale/get";
//It uses post method. It also requires data body: group,locale

export const AUTH_LOGIN_URL = "/auth/login";
export const AUTH_SET_TYPE_URL = "/account/save/type"
//It requires data body: password,email
export const AUTH_LOGOUT_URL = "/auth/logout";
export const AUTH_LINKEDIN_SIGNUP_URL = "/account/signup";
export const AUTH_GOOGLE_SIGNUP_REQUEST_URL = "/account/signup/google";
export const AUTH_FACEBOOK_SIGNUP_REQUEST_URL = "/account/signup/facebook";
export const CONSULTIFIED_SIGNUP_URL = "/account/register"
export const ACCOUNT_FINISH_WIZARD_URL = "/profile/wizard/finish";
export const ACCOUNT_REGISTER_VIA_INVITE_URL = "/account/get-data";
export const AUTH_LINKEDIN_LOGIN_URL = "/auth/linkedin";
export const AUTH_MICROSOFT_SIGNUP_REQUEST_URL = "/account/signup/microsoft";
export const VERIFY_ACCOUNT_URL = "/account/activate";
export const AUTH_CREATE_PASSWORD_REQUEST = "/account/create-password"
export const RESEND_VERIFY_EMAIL_ACCOUNT_URL = "/account/resent-email"
export const SUBMIT_SETTING_URL = "/account/settings/save";
export const GET_SUBMIT_SETTING_URL = "/account/settings/get";
export const USER_CHECK_WIZARD_STATUS_URL = "/user/wizard/check";

export const GET_ME_PROFILE_DATA_URL = "/profile/me";
export const GET_ME_PROFILE_URL = "/profile/get-profile";
export const TITLE_BAR_INFO_URL = "/profile/toolbar-info";
export const GET_TRANSLATIONS_URL = "/locale/get"

export const GET_COUNTRIES_URL = "/data/countries/list"
export const GET_CITIES_URL = "/data/cities/list"
export const GET_COMPANIES_URL = "/data/companies/list"

//start of feeds routes
export const REMOVE_FEED_COMMENT_URL = "/feed/delete/comment"
export const GET_PROFILE_FEEDS_URL = "/data/profile/card";
export const GET_FEEDS_POSTS_URL = "/feed/all/";
export const POST_FEED_POST_URL = "/feed/create";
export const TAG_FEED_POST_URL = "/feed/tags";
export const GET_MORE_FEEDS_POSTS_URL = ""; //TODO:here the url is incomplete
export const POST_FEED_COMMENT_URL = "/feed/comment/create";
export const POST_FEED_LIKE_URL = "/feed/like";
export const DELETE_FEED_POST_URL = "/feed/delete";
export const EDIT_POST_URL = "/feed/edit"


//end of feeds routes

//start of messages routes

export const GET_CHAT_CONNECTIONS_URL = "/messages/message-connections";
export const SEARCH_NEW_CONNECTIONS_URL = "/messages/search/new/connections";
export const SEARCH_CONNECTIONS_URL = "/messages/search/connections";
export const GET_CHAT_MESSAGES_URL = "/messages/get-message";
export const SET_AS_READ_URL = "/messages/set-read";
export const POST_MESSAGE_URL = "/messages/send";
export const POST_SEARCH_CONNECTION_URL = "/messages/get-search-queries"
export const POST_SEARCH_CONSULTANTS_URL = "/messages/get-search-queries"
export const POST_SEARCH_TAGS_URL = "/feed/search/tags"

//end of messages routes


//add profile routes




export const ADD_EXPERIENCE_URL = "/profile/add/experience";
export const ADD_PORTFOLIO_URL = "/profile/add/portfolio";
export const ADD_EMPLOYMENT_URL = "/profile/add/employment";
export const ADD_QUALIFICATION_URL = "/profile/add/training";
export const ADD_EDUCATION_URL = "/profile/add/education";


export const UPDATE_EXPERIENCE_URL = "/profile/edit/experience";
export const UPDATE_PORTFOLIO_URL = "/profile/edit/portfolio";
export const UPDATE_EMPLOYMENT_URL = "/profile/edit/employment";
export const UPDATE_QUALIFICATION_URL = "/profile/edit/qualification";
export const UPDATE_EDUCATION_URL = "/profile/edit/education";


export const DELETE_EXPERIENCE_URL = "/profile/delete/experience";
export const DELETE_PORTFOLIO_URL = "/profile/delete/portfolio";
export const DELETE_QUALIFICATION_URL = "/profile/delete/qualification";
export const DELETE_EDUCATION_URL = "/profile/delete/education";



export const VIEW_EDUCATION_URL = "/profile/get/education"

export const VIEW_EXPERIENCE_URL = "/profile/get/experience";
export const VIEW_PORTFOLIO_URL = "/profile/get/portfolio";
export const VIEW_EMPLOYMENT_URL = "/profile/get/employment";
export const VIEW_QUALIFICATION_URL = "/profile/get/qualification";
export const DELETE_SKILL_URL = "/profile/skill/delete";



export const ADD_COMPANY_URL = "/company/create";
export const GET_COMPANY_INFO_URL = "/company/get-info";
export const GET_COMPANY_INFO_ADMIN_URL = "/company/admin/get-info";
export const BUY_COMPANY_URL = "/company/buy";
export const ADD_EMPLOYEE_URL = "/company/add-employee";
export const GET_COMPANY_EMPLOYEES_URL = "/company/get-employees";
export const EDIT_PROFILE_INFO_URL = "/profile/update/info";
export const CHANGE_PASSWORD_URL = "/profile/update/password";
export const DELETE_ACCOUNT_URL = "/profile/account/delete";


export const SAVE_PROJECT_URL = "/project/create";
export const DELETE_PROJECT_URL = "/project/delete";
export const DELETE_EMPLOYMENT_URL = "/employment/delete";
export const VERIFY_PROJECT_URL = "/project/verify";
export const VERIFY_EMPLOYMENT_URL = "/employment/verify";
export const ADD_SKILL_URL = "/project/skill/add";
export const EDIT_PROJECT_ITEM_URL = "/project/edit/item";
export const EDIT_EMPLOYMENT_ITEM_URL = "/employment/edit/item";
export const EDIT_QUALIFICATION_ITEM_URL = "/profile/edit/qualification";
export const SAVE_EMPLOYMENT_URL = "/employment/create";
export const SEARCH_FOR_PROJECT_MEMBERS_URL = "/project/search-members";
export const GET_ME_DASHBOARD_DATA_URL = "/profile/get/dashboard";
export const INVITE_USER_VIA_PROJECT_URL = "/invitation/project"
export const INVITE_USER_VIA_EMAIL_URL = "/invitation/invite"
export const SUBMIT_INVITE_PARTICIPATION_INFO_URL = "/account/invite-participation-info"
export const SEND_CONTACT_MAIL_URL = "/send-contact-email"
export const SEND_INVESTOR_MAIL_URL = "/send-investor-email"
export const EXPORT_CV_URL = "/profile/export"
export const GRANT_PERMISSION_URL = "grant/permission";
export const SAVE_CERTIFICATION_URL = "/profile/add/certification"
export const DELETE_QUALIFICATION_ITEM_URL = "/profile/delete/qualification"
export const SEARCH_INDEX_URL = "/search/index"
export const SEARCH_BY_LOCATION_URL = "/search/by/location"
export const SEARCH_BY_SKILLS_URL = "/search/by/skills"
export const SEARCH_BY_COMPANY_URL = "/search/by/company"
export const SEARCH_BY_ALL_URL = '/search/by/all'


// Customer  api
export const ADD_NEW_CUSTOMER_URL = "/customer/setup/wizard";
export const GET_CUSTOMER_URL = "/customer/get-info";
export const UPDATE_CUSTOMER_URL = "/customer/update/info";
export const SEARCH_ON_CUSTOMER_URL = "/customer/search";
export const GET_ASSIGNMENT_URL = "/customer/get/leads";
export const DELETE_ASSIGNMENT_URL = "/customer/delete/lead";
export const PUBLISH_ASSIGNMENT_URL = "/customer/publish/lead";
export const UNPUBLISH_ASSIGNMENT_URL = "/customer/unpublish/lead";
export const GET_ALL_ASSIGNMENT_URL = "/customer/get/all/leads";
export const ADD_NEW_ASSIGNMENT_URL = "/customer/create/leads"
export const UPDATE_ASSIGNMENT_URL = "/customer/update/lead"
export const GET_CUSTOMER_BY_ID_URL = "/customer/user/get-info";
export const GET_ASSIGNMENT_BY_CUSTOMER_ID_URL = "/customer/get/user/leads";
export const SEARCH_ASSIGNMENT_URL = "/assignment/search";
export const DELETE_CUSTOMER_ACCOUNT_URL = "/customer/delete";
export const UPDATE_CUSTOMER_ACCOUNT_PASSWORD_URL = "/accounts/change-password";


// Skills component api
export const GET_SKILLS_URL = "/search/skill/suggestions";
export const SEARCH_SKILLS_URL = "/search/skill/suggestions";
export const SKILL_SAVE_PRIOLITY_URL = "/profile/skill/priority";

// Company api
export const ADD_COMPANY_MANAGER_INFO_URL = "/company/wizard";
export const GET_ME_USER_COMPANY_URL = "/company/get-info";
export const DELETE_COMPANY_ACCOUNT_URL = "/company/manager/delete";
export const DELETE_COMPANY_URL = "/company/delete";
export const UPDATE_COMPANY_ACCOUNT_PASSWORD_URL = "/accounts/change-password";
export const UPDATE_COMPANY_INFO_URL = "/company/update-info";
export const UPDATE_MANAGER_INFO_URL = "/company/update-manager-info";
export const ADD_COMPANY_MEMBER_URL = "/company/add-members";
export const GRANT_TO_BE_COMPANY_MEMBER_URL = "/company/confirm-add-employee";
export const ADD_PITCH_URL = "/company/create-pitch";
export const SEARCH_FOR_COMPANY_MEMBERS_URL = "/company/search-members";
export const REMOVE_PITCH_URL = "/company/remove-pitch";
export const UPDATE_PITCH_URL = "/company/update-pitch";
export const SEARCH_PITCH_URL = "/company/search-pitch";
export const REMOVE_MEMBER_FROM_PITCH_URL = "/company/remove-pitch-member";
export const REMOVE_MEMBER_FROM_COMPANY_URL = "/company/remove-member";
export const SEARCH_COMPANY_PROJECT_URL = "/company/search-projects";
export const REMOVE_MANAGER_FROM_COMPANY_URL = "/company/remove-manager";

// Company consultant profile api
export const GET_CONSULTANT_ALL_DATA_URL = "/company/consultant/get-info";
export const EXPORT_CONSULTANT_CV_URL = "/company/consultant/export-cv";
export const SAVE_CONSULTANT_INFO_URL = "/company/consultant/save-info";
export const COMPANY_CONSULTANT_UPDATE_PROJECT_URL = "/company/project/update";
export const COMPANY_CONSULTANT_ADD_NEW_PROJECT_URL = "/company/project/create";
export const COMPANY_CONSULTANT_DELETE_PROJECT_URL = "/company/project/delete";
export const COMPANY_CONSULTANT_VERIFY_PROJECT_URL = "/company/project/verify";
export const COMPANY_CONSULTANT_ADD_SKILL_URL = "/company/project/skill/add";
export const COMPANY_CONSULTANT_DELETE_SKILL_URL = "/company/consultant/skill/delete";
export const COMPANY_CONSULTANT_UPDATE_SKILL_URL = "/company/consultant/skill/update";
export const COMPANY_CONSULTANT_SAVE_SKILL_PRIOLITY_URL = "/company/consultant/skill/priority";
export const COMPANY_CONSULTANT_UPDATE_EMPLOYMENT_URL = "/company/employment/update";
export const COMPANY_CONSULTANT_ADD_NEW_EMPLOYMENT_URL = "/company/employment/create";
export const COMPANY_CONSULTANT_DELETE_EMPLOYMENT_URL = "/company/employment/delete"
export const COMPANY_CONSULTANT_VERIFY_EMPLOYMENT_URL = "/company/employment/verify";
export const COMPANY_CONSULTANT_UPDATE_EDUCATION_URL = "/company/consultant/edit/qualification";
export const COMPANY_CONSULTANT_ADD_NEW_EDUCATION_URL = "/company/consultant/add/education";
export const COMPANY_CONSULTANT_DELETE_EDUCATION_URL = "/company/consultant/delete/qualification";
export const COMPANY_CONSULTANT_UPDATE_TRAINING_URL = "/company/consultant/edit/qualification";
export const COMPANY_CONSULTANT_ADD_NEW_TRAINING_URL = "/company/consultant/add/training";
export const COMPANY_CONSULTANT_DELETE_TRAINING_URL = "/company/consultant/delete/qualification";
export const COMPANY_CONSULTANT_DELETE_CERTIFICATION_URL = "/company/consultant/delete/qualification";
export const COMPANY_CONSULTANT_ADD_NEW_CERTIFICATION_URL = "/company/consultant/add/certification";
export const COMPANY_CONSULTANT_UPDATE_CERTIFICATION_URL = "/company/consultant/edit/qualification";
export const COMPANY_INVITE_CONSULTANT_AS_MANAGER_URL = "/company/invite/manager"; // new
export const COMPANY_REQUEST_MARGER_PROFILE_TO_CONSULTANT_URL = "/company/request/merge"; //new
export const COMPANY_ADD_NEW_ASSIGNMENT_URL = "/company/add-assignment";
export const COMPANY_UPDATE_ASSIGNMENT_URL = "/customer/update/lead";
export const COMPANY_ASSING_ASSINGMENT_URL = "/company/consultant/assignment/assign";
export const CONSULTANT_GET_ASSIGNMENT_URL = "/company/get-assignment";
export const CONSULTANT_ACCEPT_ASSIGNMENT_URL = "/notification/assignment/action";
export const CONSULTANT_REJECT_ASSIGNMENT_URL = "/notification/assignment/action";
export const COMPANY_ADD_PROJECT_TO_PUBLIC_URL = "/company/project/add-to-public";
export const COMPANY_SEND_PITCH_URL = "/company/send-pitch";
export const SEARCH_CUSTOMERS_MEMBERS_URL = "/company/search-customers";
export const GET_CUSTOMERS_URL = "/company/get-customers";
export const COMPANY_DOWNLOAD_CVs_FILE_URL = "/company/download/cv";
export const COMPANY_DOWNLOAD_MILT_CVS_URL = "/company/download/milt/cv";
export const COMPANY_CONSULTANT_CHANGE_PERMISSION_URL = "/company/consultant/change/permission";
export const COMPANY_DOWNLOAD_MILT_UPLOADED_CVs_URL = "/company/consultant/download/milt/cvs"
export const VALIDATE_STUDENT_IF_EXIST_IN_CLASS_URL = "/company/student/validate/if/in/other/class"
export const SEARCH_PUBLIC_INTERNSHIP_URL = "/company/search/public/internship";
export const COMPANY_CONSULTANT_SEND_REMAINDER_URL = "/company/consultant/send/remainder";
export const COMPANY_TRANSFER_STUDENT_URL = "/company/transfer/student";


// AI Profile
export const AI_WIZARD_SUBMIT_CV_URL = "/account/create-by-cv";
export const GET_SINGLE_PITCH_URL = "/company/get-pitch";
export const CUSTOMER_REJECT_PITCH_URL = "/notification/pitch/action";
export const CUSTOMER_ACCEPT_PITCH_URL = "/notification/pitch/action";
export const CUSTOMER_ADD_PITCH_URL = ""; // -> new
export const COMPANY_ADD_SKILL_URL = "/company/add-skill";
export const COMPANY_SKILL_SAVE_PRIOLITY_URL = "/company/profile/skill/priority";
export const COMPANY_DELETE_SKILL_URL = "/company/profile/skill/delete"
export const COMPANY_EDIT_SKILL_URL = "/company/profile/skill/edit";
export const COMPANY_SEND_PITCH_VIA_EMAIL_URL = "/company/send-email-pitch";
export const SEARCH_COMPANY_OUR_PROJECT_URL = ""; // -> new
export const SEARCH_ASIGNED_ASSIGNMENT_URL = ""; // -> new
export const AI_ACCOUNT_CHANGE_PASSWORD_URL = "/ai/change/password";
export const GET_COMPANY_PROFILE_URL = "/company/get-public-info";
export const MAIN_SEARCH_URL = "/search/get-search-queries";

export const COMPANY_ADD_NEW_CV_URL = "/company/cv/add"
export const COMPANY_APPLY_CV_URL = "/company/cv/apply"
export const GET_USER_COMPANY_CV_URL = "/company/cv/get/custom";
export const COMPANY_DOWNLOAD_CV_URL = "/company/cv/download";
export const COMPANY_UPDATE_CV_URL = "/company/cv/update";
export const COMPANY_REMOVE_CV_URL = "/company/cv/remove";
export const COMPANY_CONSULTANT_SAVE_PRIOLITY_URL = "/company/consultant/prioritize"
export const COMPANY_GET_MY_CV_URL = "/company/cv/get-dir";
export const COMPANY_ADD_NEW_CV_FILE_URL = "/company/cv/add/file";
export const COMPANY_UPDATE_CV_FILE_URL = "/company/cv/update/file";
export const COMPANY_ADD_NEW_FOLDER_URL = "/company/cv/new/folder";
export const COMPANY_UPDATE_FOLDER_URL = "/company/cv/update/folder";
export const COMPANY_CV_DELETE_FILE_FOLDER_URL = "/company/cv/delete/file-folder";
export const COMPANY_DOWNLOAD_CV_FILE_URL = "/company/cv/download/file"
export const COMPANY_ADD_SELECTED_PROJECTS_TO_PUBLIC_URL = "/company/projects/selected/add-to-public";
export const COMPANY_REMOVE_PROJECT_TO_PUBLIC_URL = "/company/project/remove-to-public";
export const COMPANY_UPLOAD_NEW_CV_URL = "/company/cv/upload";
export const COMPANY_UPDATE_UPLOADED_CV_URL = "/company/cv/update/uploaded";
export const COMPANY_GET_CONSULTANT_CV_URL = "/company/cv/get/consultant"

// ============================
// CRM
// ============================

export const VALIDATE_TOKEN_URL = "/validate/token";


export const GET_ALL_USER_INFO_URL = "/user/get/all/info";

// Acccount as glabal
export const ACCOUNT_ADD_NEW_URL = "/account/create";
export const ACCOUNT_UPDATE_URL = "/account/update";
export const ACCOUNT_GET_DATA_URL = "/account/list";
export const ACCOUNT_DELETE_URL = "/account/delete"; // new
export const ACCOUNT_SEARCH_URL = "/account/search"; // new 
export const ACCOUNT_FILTER_BY_SORT_URL = "/account/filter/sort"; // new

export const ACCOUNT_DELETE_SELECTED_URL = "/account/selected/delete";
export const ACCOUNT_SEND_SELECTED_MESSAGE_URL = "/account/selected/send/message";
export const ACCOUNT_UPDATE_SELECTED_URL = "/account/selected/update";
export const ACCOUNT_SINGLE_GET_INTERNSHIP_URL = "/internship/get/by/account";
export const ACCOUNT_GET_TAGS_URL = "/account/get/tags";
export const ACCOUNT_FILTER_BY_TAG_URL = "/account/filter/tag";
export const ACCOUNT_DELETE_TAG_URL = "/account/remove/tag"
export const ACCOUNT_FILTER_BY_NAME_URL = "/account/filter/name";
export const ACCOUNT_FILTER_BY_LOCATION_URL = "/account/filter/location";
export const ACCOUNT_CONTACT_EXPORT_URL = "/account/contact/export";
export const ACCOUNT_EXPORT_URL = "/accounts/export";

// CONTACTS API
export const ACCOUNT_CONTACT_GET_DATA_URL = "/contact/list";
export const ACCOUNT_CONTACT_ADD_NEW_URL = "/contact/create";
export const ACCOUNT_CONTACT_UPDATE_URL = "/contact/update";
export const ACCOUNT_CONTACT_DELETE_URL = "/contact/delete";
export const CONTACTS_SEARCH_URL = "/contact/search"; // new
export const CONTACTS_FILTER_BY_SORT_URL = "/contact/filter/sort"; // new
export const CONTACTS_FILTER_BY_TAG_URL = "/contact/filter/tag"; //new
export const CONTACTS_GET_TAGS_URL = "/contact/tags" //new
export const CONTACTS_GET_ROLES_URL = "/contact/roles" //new
export const ACCOUNT_CONTACT_DETAILS_URL = "/contact/details";
export const CONTACT_DELETE_TAG_URL = "/contact/delete/tag"
export const ACCOUNT_CONTACT_OPTING_URL = "/contact/opting";
export const ACCOUNT_CONTACT_DELETE_SELECTED_URL = "/contact/selected/delete";

// ACTIVITIES API
export const ACTIVITIES_DELETE_SELECTED_URL = "/activities/delete/selected";
export const ACTIVITIES_SEARCH_URL = "/activities/search";
export const ACTIVITIES_GET_ALL_URL = "/activities/all";
export const ACTIVITIES_FILTER_URL = "/activities/filter";
export const ACTIVITIES_DELETE_URL = "/activities/delete";

// NOTES API
export const NOTES_ADD_NEW_URL = "/notes/add/new";
export const NOTES_UPDATE_URL = "/notes/update";
export const NOTES_SEARCH_URL = "/notes/search";
export const NOTES_GET_ALL_URL = "/notes/all";
export const NOTES_FILTER_URL = "/notes/filter";
export const NOTES_DELETE_URL = "/notes/delete";
export const NOTES_DELETE_SELECTED_URL = "/notes/delete/selected";


// EVENT API
export const ACCOUNT_EVENTS_GET_DATA_URL = "/event/list";
export const ACCOUNT_EVENTS_ADD_NEW_URL = "/event/create";
export const ACCOUNT_EVENTS_UPDATE_URL = "/event/update";
export const ACCOUNT_GET_EVENTS_BASED_DATE_URL = "/event/list/by/date";



// LEADS
export const LEADS_ADD_NEW_SECTION_URL = "/leads/section/add";
export const LEADS_UPDATE_SECTION_URL = "/leads/section/update";
export const LEADS_DELETE_SECTION_URL = "/leads/section/delete";
export const LEADS_ADD_NEW_STORY_URL = "/leads/section/story/add";
export const LEADS_UPDATE_STORY_URL = "/leads/section/story/update";
export const LEADS_GET_ALL_URL = "/leads/get-all";
export const LEADS_DELETE_STORY_URL = "/leads/section/story/delete";
export const LEADS_MOVE_STORY_URL = "/leads/section/story/move";
export const LEADS_FILTER_BY_SORT_URL = "/leads/filter/sort"; // new
export const LEADS_SEARCH_URL = "/leads/search"; // new
export const LEADS_FILTER_BY_TAG_URL = "/leads/filter/tag";
export const LEADS_GET_TAGS_URL = "/leads/all/tags";
export const LEADS_FILTER_BY_CLOSE_DATE_URL = "/leads/filter/closedate";
export const LEADS_FILTER_BY_DISPLAY_URL = "/leads/filter/display";


// CANDIDATES
export const CANDIDATES_ADD_NEW_SECTION_URL = "/candidates/section/add";
export const CANDIDATES_UPDATE_SECTION_URL = "/candidates/section/update";
export const CANDIDATES_DELETE_SECTION_URL = "/candidates/section/delete";
export const CANDIDATES_ADD_NEW_STORY_URL = "/candidates/section/story/add";
export const CANDIDATES_UPDATE_STORY_URL = "/candidates/section/story/update";
export const CANDIDATES_GET_ALL_URL = "/candidates/get-all";
export const CANDIDATES_DELETE_STORY_URL = "/candidates/section/story/delete";
export const CANDIDATES_MOVE_STORY_URL = "/candidates/section/story/move";
export const CANDIDATES_GET_EXISTS_URL = "/candidates/search/exists";
export const CANDIDATES_SEARCH_URL = "/candidates/search";
export const CANDIDATES_FILTER_BY_SORT_URL = "/candidates/filter/sort";


// Task
export const TASK_ADD_NEW_SECTION_URL = "/task/section/add";
export const TASK_UPDATE_SECTION_URL = "/task/section/update";
export const TASK_DELETE_SECTION_URL = "/task/section/delete";
export const TASK_ADD_NEW_STORY_URL = "/task/section/story/add";
export const TASK_UPDATE_STORY_URL = "/task/section/story/update";
export const TASK_GET_ALL_URL = "/task/get-all";
export const TASK_DELETE_STORY_URL = "/task/section/story/delete";
export const TASK_MOVE_STORY_URL = "/task/section/story/move";
export const TASK_FILTER_BY_TAG_URL = "/task/filter/tag"; // new
export const TASK_FILTER_BY_SORT_URL = "/task/filter/sort"; // new
export const TASK_SEARCH_URL = "/task/search" // new
export const TASK_MAKE_COMPLETED_URL = "/task/change/status"


// Single Account api
export const ACCOUNT_SINGLE_GET_ALL_DATA_URL = "/account/single/";
export const ACCOUNT_PROJECT_GET_ALL_URL = "/crm/get-projects";
export const ACCOUNT_SINGLE_CONTACT_DELETE_URL = "/account/single/contact/delete";
export const ACCOUNT_SINGLE_CONTACT_ADD_NEW_URL = "/account/single/contact/add";
export const ACCOUNT_SINGLE_CONTACT_UPDATE_URL = "/account/single/contact/update";
export const ACCOUNT_SINGLE_ATTACHMENT_DELETE_URL = "/account/single/attachment/delete";
export const ACCOUNT_SINGLE_ATTACHMENT_ADD_NEW_URL = "/account/single/attachment/create";
export const ACCOUNT_SINGLE_ATTACHMENT_UPDATE_URL = "/account/single/attachment/update";
export const ACCOUNT_SINGLE_ATTACHMENT_DOWNLOAD_FILE_URL = "/account/single/attachment/download";
export const ACCOUNT_CHANGE_STATUS_URL = "/account/single/change/status"

// Heatmap api
export const HEATMAP_GET_ALL_DATA_URL = "/heatmap/get/all";
export const HEATMAP_UPDATE_USER_URL = "/heatmap/update";
export const HEATMAP_UPDATE_USER_COMMENT_URL = "/heatmap/comment/update";
export const HEATMAP_UPDATE_TEAM_URL = "/heatmap/team/update";
export const HEATMAP_UPDATE_TEAM_COMMENT_URL = "/heatmap/team/comment/update";
export const HEATMAP_GET_PREV_DATA_URL = "/heatmap/get/prev";
export const HEATMAP_GET_NEXT_DATA_URL = "/heatmap/get/next";
export const HEATMAP_UPDATE_DEPARTMENT_URL = "/heatmap/department/update";
export const HEATMAP_UPDATE_DEPARTMENT_COMMENT_URL = "/heatmap/department/comment/update";


// Report api
export const REPORT_GET_ALL_URL = "/report/all";
export const REPORT_GET_ALL_SKILLS_URL = "/crm/skill-report";

// Search api
export const SEARCH_ALL_CRM_URL = "/search/everything";
export const SEARCH_ALL_SITE_URL = "/crm/search/site/everything";
export const SEARCH_LOCATION_URL = "/search/location"

// Team api
export const ORGANIZATION_GET_TEAM_URL = "/team/get/all";
export const ORGANIZATION_ADD_NEW_TEAM_URL = "/team/add";
export const ORGANIZATION_UPDATE_TEAM_URL = "/team/update";
export const ORGANIZATION_DELETE_TEAM_URL = "/team/delete";
export const ORGANIZATION_ADD_NEW_DEPARTMENT_URL = "/department/add";
export const ORGANIZATION_UPDATE_DEPARTMENT_URL = "/department/update";
export const ORGANIZATION_DELETE_DEPARTMENT_URL = "/department/delete";
export const ORGANIZATION_GET_TEAM_DATA_URL = "/team/get/single";
export const ORGANIZATION_ADD_NEW_TEAM_MEMBERS_URL = "/company/add-members";
export const ORGANIZATION_TEAM_REMOVE_MEMBER_URL = "/team/remove/member";
export const ORGANIZATION_SEARCH_DEPARTMENT_TEAM_URL = "/team/search";
export const ORGANIZATION_SEARCH_TEAM_MEMBER_URL = "/team/search/member";
export const ORGANIZATION_MOVE_TEAM_MEMBER_URL = "/team/move/member";
export const ORGANIZATION_SEARCH_USER_IN_DEPARTMENT_URL = "/department/search/member";
export const ORGANIZATION_CLOSE_TEAM_URL = "/team/close";

// School api
export const ADD_SCHOOL_MANAGER_URL = "/school/manager/add";
export const UPDATE_SCHOOL_MANAGER_URL = "/school/manager/update";
export const ADD_SCHOOL_STUDENT_URL = "/school/student/add";
export const UPDATE_SCHOOL_STUDENT_URL = "/school/student/update"
export const GET_ALL_SCHOOL_STUDENT_URL = "/school/student/all";
export const INVITE_SCHOOL_STUDENT_URL = "/school/student/send/invitation"
export const SCHOOL_DROPDOWN_SEARCH_CLASS_URL = "/super-manager/team/search";
export const SCHOOL_DROPDOWN_ALL_CLASS_URL = "/super-manager/user/get/all/info";
export const CONNECT_EXISTING_USER_TO_CLASS_URL = "/school/student/connect/existing/user";

// Process messages Api
// ==== Notification ======
export const PROCESSMESSAGE_NOTIFICATION_GET_ALL_URL = "/process/messages/notification/get/all";
export const PROCESSMESSAGE_NOTIFICATION_ADD_NEW_URL = "/process/messages/notification/add";
export const PROCESSMESSAGE_NOTIFICATION_UPDATE_URL = "/process/messages/notification/update";
export const PROCESSMESSAGE_NOTIFICATION_DELETE_URL = "/process/messages/notification/delete";


// Communication Api
export const NOTIFICATION_MARK_ALL_AS_READ_URL = "/notifications/mark/all/read";

// ==== Email ======
export const PROCESSMESSAGE_EMAIL_GET_ALL_URL = "/process/messages/email/get/all";
export const PROCESSMESSAGE_EMAIL_ADD_NEW_URL = "/process/messages/email/add";
export const PROCESSMESSAGE_EMAIL_UPDATE_URL = "/process/messages/email/update";
export const PROCESSMESSAGE_EMAIL_DELETE_URL = "/process/messages/email/delete";

export const PROCESSMESSAGE_EMAIL_GET_ALL_OUTBOX_URL = "/process/messages/email/outbox";
export const PROCESSMESSAGE_EMAIL_OUTBOX_DELETE_URL = "/process/messages/email/outbox/delete";
export const PROCESSMESSAGE_EMAIL_SIGNATURE_MARK_AS_DEFAULT_URL = "/process/messages/email/signature/mark/default";
export const PROCESSMESSAGE_EMAIL_SIGNATURE_DELETE_URL = "/process/messages/email/signature/delete";
export const PROCESSMESSAGE_EMAIL_GET_SIGNATURE_URL = "/process/messages/email/signatures";
export const PROCESSMESSAGE_EMAIL_SIGNATURE_ADD_NEW_URL = "/process/messages/email/signature/new";
export const PROCESSMESSAGE_EMAIL_SIGNATURE_UPDATE_URL = "/process/messages/email/signature/update";
export const PROCESSMESSAGE_EMAIL_GET_DEFAULT_SIGNATURE_URL = "/process/messages/email/signature/default";
export const PROCESSMESSAGE_SEND_EMAIL_URL = "/process/messages/email/send";

// ===== Survey ======
export const PROCESSMESSAGE_SURVEY_GET_ALL_URL = "/process/messages/survey/get/all";
export const PROCESSMESSAGE_SURVEY_ADD_NEW_URL = "/process/messages/survey/add";
export const PROCESSMESSAGE_SURVEY_UPDATE_URL = "/process/messages/survey/update";
export const PROCESSMESSAGE_SURVEY_DELETE_URL = "/process/messages/survey/delete";
export const PROCESSMESSAGE_SURVEY_DUPLICATE_URL = "/process/messages/survey/duplicate";
export const COMMUNICATION_GET_QUESTION_ANSWERS_URL = "/communication/get/question/answers";
export const COMMUNICATION_GET_QUESTIONAIRE_URL = "/communication/get/questionaire";
export const PROCESSMESSAGE_SURVEY_REMIND_URL = "/process/messages/survey/remind";

export const COMMUNICATION_LOCK_QUESTIONAIRE_URL = "/communication/lock/questionaire";
export const COMMUNICATION_COMPLETED_QUESTIONAIRE_URL = "/communication/completed/questionaire";
export const COMMUNICATION_NOT_STARTED_QUESTIONAIRE_URL = "/communication/not-started/questionaire";
export const COMMUNICATION_VIEW_QUESTIONAIRE_RESULT_URL = "/communication/view/questionaire/result";

export const COMMUNICATION_EMAIL_GET_TEMPLATES_URL = "/communication/email/get/templates";
export const COMMUNICATION_EMAIL_SEARCH_TEMPLATES_URL = "/communication/email/search/templates";
export const COMMUNICATION_NOTIFICATION_SEARCH_TEMPLATES_URL = "/communication/notification/search/templates";
export const COMMUNICATION_NOTIFICATION_GET_TEMPLATES_URL = "/communication/notification/get/templates";
export const COMMUNICATION_QUESTIONAIRE_SEARCH_TEMPLATES_URL = "/communication/questionaire/search/templates";
export const COMMUNICATION_QUESTIONAIRE_GET_TEMPLATES_URL = "/communication/questionaire/get/templates";
export const COMMUNICATION_EMAIL_SAVE_TEMPLATE_URL = "/communication/email/save/template";
export const COMMUNICATION_EMAIL_UPDATE_TEMPLATE_URL = "/communication/email/update/template";
export const COMMUNICATION_QUESTIONAIRE_SAVE_TEMPLATE_URL = "/communication/questionaire/save/template";
export const COMMUNICATION_NOTIFICATION_SAVE_TEMPLATE_URL = "/communication/notification/save/template";
export const COMMUNICATION_NOTIFICATION_SEND_NOTIFICATION_URL = "/communication/notification/send/notification";

export const COMMUNICATION_NOTIFICATION_DELETE_TEMPLATE_URL = "/communication/notification/delete/template";
export const COMMUNICATION_QUESTIONAIRE_DELETE_TEMPLATE_URL = "/communication/questionaire/delete/template";
export const COMMUNICATION_EMAIL_DELETE_TEMPLATE_URL = "/communication/email/delete/template";

export const COMMUNICATION_NOTIFICATION_UPDATE_TEMPLATE_URL = "/communication/notification/update/template";
export const COMMUNICATION_QUESTIONAIRE_UPDATE_TEMPLATE_URL = "/communication/questionaire/update/template";


// Instructor Api
export const INSTRUCTOR_DELETE_SCHOOL_STUDENT_URL = "/instructor/school/student/delete";
export const INSTRUCTOR_APPROVE_SCHOOL_STUDENT_URL = "/instructor/school/student/approve";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT_URL = "/instructor/school/student/search";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_URL = "/instructor/school/student/filter";
export const INSTRUCTOR_SEND_SCHOOL_STUDENT_REMAINDER_URL = "/instructor/school/student/send/remainder";
export const INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE1_URL = "/instructor/school/report/phase/1";
export const INSTRUCTOR_SEARCH_INTERNSHIP_URL = "/instructor/school/search/internship";
export const INSTRUCTOR_GET_ALL_INTERNSHIP_URL = "/instructor/school/internship/all";
export const INSTRUCTOR_SAVE_COMPANY_INTERNSHIP_URL = "/instructor/school/company/internship/save";
export const INSTRUCTOR_SEARCH_COMPANY_URL = "/account/search";
export const INSTRUCTOR_GET_ALL_SCHOOL_STUDENT_PHASE2_URL = "/instructor/school/student/all/phase/2";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE2_URL = "/instructor/school/student/search/phase/2";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE2_URL = "/instructor/school/student/filter/phase/2";
export const INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP_URL = "/instructor/school/student/internship/add";
export const INSTRUCTOR_REMOVED_COMPANY_INTERNSHIP_TO_STUDENT_URL = "/instructor/school/student/remove/internship";
export const INSTRUCTOR_SEND_ALL_SCHOOL_STUDENT_REMAINDER_URL = "/instructor/school/student/remind/all";
export const INSTRUCTOR_SEND_ALL_COMPANY_REMAINDER_URL = "/instructor/school/remind/all/company";

export const INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE3_URL = "/instructor/school/student/report/phase/3";
export const INSTRUCTOR_MATCH_SCHOOL_STUDENT_URL = "/instructor/school/student/match";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE3_URL = "/instructor/school/student/search/phase/3";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE3_URL = "/instructor/school/student/filter/phase/3";
export const GET_ALL_SCHOOL_STUDENT_PHASE3_URL = "/instructor/school/student/all/phase/3";
export const INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP_STUDENTS_URL = "/instructor/school/internship/assing/student/all/";
export const INSTRUCTOR_SUBMIT_CHANGES_PHASE2_URL = "/instructor/school/submit/changes/phase/2";
export const INSTRUCTOR_SEND_PERIODIC_REMAINDER_URL = "/instructor/school/student/send/periodic/remainder";

export const INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE4_URL = "/instructor/school/student/report/phase/4";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE4_URL = "/instructor/school/student/search/phase/4";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE4_URL = "/instructor/school/student/filter/phase/4";
export const GET_ALL_SCHOOL_STUDENT_PHASE4_URL = "/instructor/school/student/all/phase/4";

export const INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE5_URL = "/instructor/school/student/report/phase/5";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE5_URL = "/instructor/school/student/search/phase/5";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE5_URL = "/instructor/school/student/filter/phase/5";
export const GET_ALL_SCHOOL_STUDENT_PHASE5_URL = "/instructor/school/student/all/phase/5";
export const INSTRUCTOR_SCHOOL_SEND_EVALUATION_URL = "/instructor/school/student/send/evaluation";
export const INSTRUCTOR_SCHOOL_SEND_SURVEY_URL = "/instructor/school/student/send/survey";

export const INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE6_URL = "/instructor/school/student/report/phase/6";
export const INSTRUCTOR_APPROVE_SCHOOL_STUDENT_PHASE6_URL = "/instructor/school/student/approve/phase/6";
export const INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE6_URL = "/instructor/school/student/search/phase/6";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE6_URL = "/instructor/school/student/filter/phase/6";
export const GET_ALL_SCHOOL_STUDENT_PHASE6_URL = "/instructor/school/student/all/phase/6";
export const INVITE_SCHOOL_STUDENT_PHASE6_URL = "/instructor/school/student/invite/phase/6";

export const INSTRUCTOR_GET_STUDENT_SURVEY_RESULT_URL = "/instructor/school/student/survey/result"
export const INSTRUCTOR_SCHOOL_VIEW_SURVEY_RESULT_URL = "/instructor/school/student/result";

export const UPLOAD_MILTIPLE_FILE_URL = "/main/upload"

export const INSTRUCTOR_SEND_REMAINDER_SELECTED_STUDENT_URL = "/instructor/school/student/remind/selected";
export const INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE1_URL = "/instructor/school/student/invite/selected/phase1";
export const INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE1_URL = "/instructor/school/student/approve/selected/phase1";
export const INSTRUCTOR_SCHOOL_STUDENT_DELETE_SELECTED_PHASE1_URL = "/instructor/school/student/delete/selected/phase1";
export const INSTRUCTOR_SCHOOL_STUDENT_EXPORT_SELECTED_PHASE1_URL = "/instructor/school/student/export/selected/phase1";

export const INSTRUCTOR_MATCH_SCHOOL_SELECTED_STUDENT_URL = "/instructor/school/student/match/selected";

export const INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE6_URL = "/instructor/school/student/invite/selected/phase6";
export const INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE6_URL = "/instructor/school/student/approve/selected/phase6";

export const INSTRUCTOR_UPDATE_COMPANY_INTERNSHIP_URL = "/instructor/school/student/internship/update";
export const INSTRUCTOR_DELETE_COMPANY_INTERNSHIP_URL = "/instructor/school/student/internship/delete";

export const INSTRUCTOR_GET_NOTIFICATION_URL = "/instructor/school/notifications";
export const INSTRUCTOR_DISMISS_NOTIFICATION_URL = "/instructor/school/notifications/dismiss";
export const PROCESSMESSAGE_SURVEY_REPORT_URL = "/instructor/school/student/survey/report";
export const INSTRUCTOR_GET_NOTIFICATION_STATUS_URL = "/instructor/school/check/notifications";
export const INSTRUCTOR_STUDENT_CONTRACT_SIGNED_URL = "/instructor/school/student/contract/sign";
export const INSTRUCTOR_SIGN_CONTRACT_OF_SELECTED_STUDENT_URL = "/instructor/school/student/contract/sign/selected";

export const INSTRUCTOR_GET_PUBLIC_INTERNSHIP_URL = "/internship/get/all";
export const INSTRUCTOR_FILTER_SCHOOL_STUDENT_STATUS_PHASE3_URL = "/instructor/school/student/filter/status/phase/3";
export const INSTRUCTOR_SEND_NOTIFICATION_RATE_COMPANY_PHASE6_URL = "/instructor/school/student/send/notification/rate/company/phase/6";
export const INSTRUCTOR_SEND_NOTIFICATION_UPDATE_PROFILE_PHASE6_URL = "/instructor/school/student/send/notification/update/profile/phase/6";

// INTERNSHIP
export const INTERNSHIP_FILTER_BY_TAG_URL = "/internship/filter/by/tags";
export const INTERNSHIP_GET_TAGS_URL = "/internship/get/tags";
export const INTERNSHIP_FILTER_BY_LOCATION_URL = "/internship/filter/by/location";
export const INTERNSHIP_EXPORT_INTERNAL_LIA_URL = "/internship/export/internal/lia";
export const INTERNSHIP_MOVE_TO_ARCHIVE_URL = "/internship/move/to/archive";
export const INTERNSHIP_DELETE_SELECTED_LIA_URL = "/internship/delete/selected/lia";
export const INTERNSHIP_MOVE_ARCHIVE_SELECTED_LIA_URL = "/internship/move/archive/selected/lia";
export const INTERNSHIP_SAVE_PRIVATE_INTERNSHIP_URL = "/internship/save/private/internship";
export const INTERNSHIP_ASSIGN_INTERNSHIP_URL = "/internship/assign/internship/to/student";
export const INSTRUCTOR_GET_PRIVATE_INTERNSHIP_URL = "/internship/get/private/internship";
export const INTERNSHIP_UPDATE_PRIVATE_INTERNSHIP_URL = "/internship/update/private/internship";

// CVS
export const CVS_ADD_NEW_TEMPLATE_URL = "/cvs/template/add";
export const CVS_UPDATE_TEMPLATE_URL = "/cvs/template/update";
export const CVS_GET_TEMPLATES_URL = "/cvs/template/all";
export const CVS_DELETE_TEMPLATE_URL = "/cvs/template/delete";
export const CVS_APPLY_TEMPLATE_URL = "/cvs/template/apply";
export const CVS_RENAME_TEMPLATE_URL = "/cvs/template/rename";
export const CVS_SEARCH_STUDENT_URL = "/cvs/search/students";
export const CVS_GET_STUDENT_INFORMATION_URL = "/cvs/student/info";
export const CVS_GET_STUDENT_PROJECTS_URL = "/cvs/projects";
export const CVS_GET_STUDENT_EMPLOYMENTS_URL = "/cvs/employments";
export const CVS_GET_STUDENT_SKILLS_URL = "/cvs/skills";
export const CVS_GET_STUDENT_QUALIFICATION_URL = "/cvs/qualifications";
export const CVS_UPLOAD_IMAGES_URL = "/cvs/upload/images";
export const CVS_ALL_IMAGES_URL = "/cvs/images/all";
export const CVS_UPLOAD_BACKGROUND_URL = "/cvs/upload/background";
export const CVS_ALL_BACKGROUND_URL = "/cvs/background/all";
export const CVS_IMAGE_DELETE_URL = "/cvs/image/delete";
export const CVS_SHARE_TEMPLATE_URL = "/cvs/template/share";
export const CVS_STOP_SHARE_TEMPLATE_URL = "/cvs/template/stop/share";
export const CVS_SUBMIT_CV_URL = "/cvs/submit";
export const CVS_GET_CVS_URL = "/cvs/get";
export const CVS_UPDATE_CV_URL = "/cvs/update";
export const CVS_DELETE_CV_URL = "/cvs/delete";
export const CVS_SET_AS_DEFAULT_URL = "/cvs/set/default";
export const CVS_GET_TEMPLATE_ITEM_URL = "/cvs/template/item";
export const CVS_ADD_NEW_COMPONENT_URL = "/cvs/template/component/add";
export const CVS_GET_TEMPLATE_COMPONENTS_URL = "/cvs/template/components";
export const CVS_DELETE_TEMPLATE_COMPONENT_URL = "/cvs/template/component/delete";
export const CVS_GENELATE_FILE_URL = "/cvs/generate/file";


export const GET_SCHOOLS_URL = "/get-schools";
export const GET_SCHOOL_URL = "/get-school-info";
export const DELETE_SCHOOL_URL = "/delete-school";

export const RELEASE_ADD_NEW_URL = "/release/add";
export const RELEASE_UPDATE_URL = "/release/update";
export const RELEASE_DELETE_URL = "/release/delete";
export const RELEASE_GET_ALL_URL = "/release/get-all";