import { render, staticRenderFns } from "./SchoolNotifiation.vue?vue&type=template&id=56f8874f&scoped=true"
import script from "./SchoolNotifiation.vue?vue&type=script&lang=js"
export * from "./SchoolNotifiation.vue?vue&type=script&lang=js"
import style0 from "./SchoolNotifiation.vue?vue&type=style&index=0&id=56f8874f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_react-dom@18.2.0_react@18.2.0_vue-template-compiler@2.7.16_webpack@5.90.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56f8874f",
  null
  
)

export default component.exports