import {
    LOADER_START,
    LOADER_FAIL,
    LOADER_SUCCESS,
    LOADER_CLOSE,
    LOADER_MESSAGE,
    LOADER_MORE_FEEDS,
    LOADER_EXPERIENCE,
    LOADER_EDUCATION,
    LOADER_QUALIFICATION,
    LOADER_EMPLOYMENT,
    LOADER_PORTFOLIO,
    ENABLE_GENERAL_EDIT,
    DIALOG_SEARCH,
    SET_ALERT,
    SET_DRAWER, SET_INFO


} from "../Actions/loader";

import router from "../../router";

import {
    API,
  } from "../variable";
  import axios from 'axios';

  const state = {
    loading: false,
    isSuccess: null,
    message: '',
    backgroundColor: '#fff',
    opacity: 0.5,
    loaderType: 'dots',//spinner or dots or bars
    color: '#1885AD',
    moreFeedLoader:true,
    dialog_experience:false,
    dialog_education:false,
    dialog_qualification:false,
    dialog_employment:false,
    dialog_portfolio:false,
    enableGeneralEdit:true,
    dialog_search:false,
    alert: {state : false, message : '', action : false, title:'',id : '', yestitle : '', notitle : ''},
    info: {state : false, message: '', title : ''},
    drawer:false,
    dialog :{
        type : '',
        message : '',
        action : '',
        response : false,
        value : '',
        position : ''
    }
  };


  const actions= {
      [LOADER_START]:({ commit, dispatch},payload)=>{
          commit(LOADER_START);
      },
      [LOADER_MESSAGE]:({commit,dispatch},payload)=>{
          commit(LOADER_MESSAGE,payload);
         
      },
      [LOADER_CLOSE]:({commit,dispatch},payload)=>{
          commit(LOADER_CLOSE);
      },
      [LOADER_SUCCESS]:({commit,dispatch},payload)=>{
          commit(LOADER_SUCCESS);
      },
      [LOADER_FAIL]:({commit,dispatch},payload)=>{
          commit(LOADER_FAIL);
      },
      [LOADER_MORE_FEEDS]:({commit,dispatch},payload)=>{
          commit(LOADER_MORE_FEEDS,payload);
      },
      [LOADER_EXPERIENCE]:({commit,dispatch},payload)=>{
        commit(LOADER_EXPERIENCE,payload);
      },
      [LOADER_EDUCATION]:({commit,dispatch},payload)=>{
        commit(LOADER_EDUCATION,payload);
      },
      [LOADER_QUALIFICATION]:({commit,dispatch},payload)=>{
        commit(LOADER_QUALIFICATION,payload);
      },
      [LOADER_EMPLOYMENT]:({commit,dispatch},payload)=>{
        commit(LOADER_EMPLOYMENT,payload);
      },
      [LOADER_PORTFOLIO]:({commit,dispatch},payload)=>{
        commit(LOADER_PORTFOLIO,payload);
      },
      [ENABLE_GENERAL_EDIT]:({commit,dispatch},payload)=>{
        commit(ENABLE_GENERAL_EDIT,payload);
      },
      [DIALOG_SEARCH]:({commit,dispatch},payload)=>{
        commit(DIALOG_SEARCH,payload);
      },
      [SET_ALERT]:({commit,dispatch},payload)=>{
        commit(SET_ALERT,payload);
      },
      [SET_INFO]:({commit,dispatch},payload)=>{
          commit(SET_INFO,payload);
      },
      [SET_DRAWER]:({commit,dispatch},payload)=>{
        commit(SET_DRAWER,payload);
      }
  };

  const mutations={

    [LOADER_START]:(state)=>{
        state.loading =true;
    },
    [LOADER_CLOSE]:(state)=>{
        state.loading= false;
    },
    [LOADER_FAIL]:(state)=>{
        state.isSuccess= false;
    },
    [LOADER_SUCCESS]:(state)=>{
        state.isSuccess=true;
    },
    [LOADER_MESSAGE]:(state,payload)=>{
        state.message=payload;
    },
    [LOADER_MORE_FEEDS]:(state,payload)=>{
        state.moreFeedLoader=payload;
    },
    [LOADER_EXPERIENCE]:(state,payload)=>{
        state.dialog_experience=payload;
    },
    [LOADER_EDUCATION]:(state,payload)=>{
        state.dialog_education=payload;
    },
    [LOADER_QUALIFICATION]:(state,payload)=>{
        state.dialog_qualification=payload;
    },
    [LOADER_EMPLOYMENT]:(state,payload)=>{
        state.dialog_employment=payload;
    },
    [LOADER_PORTFOLIO]:(state,payload)=>{
        state.dialog_portfolio=payload;
    },

    [ENABLE_GENERAL_EDIT]:(state,payload)=>{
        state.enableGeneralEdit=payload;
    },
    
    [DIALOG_SEARCH]:(state,payload)=>{
      state.dialog_search=payload;
  },
  [SET_ALERT]:(state,payload)=>{
    state.alert=payload;
  },
  [SET_INFO]:(state,payload)=>{
    state.info = payload;
  },
  [SET_DRAWER]:(state,payload)=>{
    state.drawer=payload;
  }

    

};

const getters ={

    loaderMessage: state=> state.message,
    loaderStatus: state=>state.loading,
    getLoader:state =>({
                    isLoading: state.loading,
                    isSuccess: state.isSuccess,
                    message: state.message,
                    backgroundColor: state.backgroundColor,
                    opacity: state.opacity,
                    loaderType: state.loaderType,//spinner or dots or bars
                    color: state.color
    }),
    getLoaderMoreFeeds:state=>state.moreFeedLoader,
    getExperienceDialog:state=>state.dialog_experience,
    getEducationDialog:state=>state.dialog_education,
    getPortfolioDialog:state=>state.dialog_portfolio,
    getEmploymentDialog:state=>state.dialog_employment,
    getQualificationDialog:state=>state.dialog_qualification,
    getEnableGeneralEdit:state=>state.enableGeneralEdit,
    getDialogSearch:state=>state.dialog_search,
    getAlert:state=>state.alert,
    getInfo:state=>state.info,
    getDrawer:state=>state.drawer,
    getDialog : state => state.dialog
};

export default {
    state,
    getters,
    actions,
    mutations
  };

  