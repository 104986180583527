import {
    API,
    AI_WIZARD_SUBMIT_CV_URL
} from "../variable";

import axios from 'axios'
import { AI_WIZARD_SUBMIT_CV } from "../Actions/ai";

const state = {

};

const actions = {
    [AI_WIZARD_SUBMIT_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + AI_WIZARD_SUBMIT_CV_URL,
            method: "POST",
            data: payload
        });
    },
};

const mutations = {};

const getters = {};

export default {
    state,
    getters,
    actions,
    mutations
};