<template>
  <div class="widget-list-selecter">
    <div class="widget-list-header" :class="{ withSearch: showSearch }">
      <div class="widget-list-header-top">
        <widgetCheckbox @change="toggleCheck($event, 'all')" v-model="isAllChecked" title="Select All"></widgetCheckbox>
        <button class="btn-clear" @click="toggleCheck($event, 'clear')">
          Clear all
        </button>
      </div>
      <div v-if="showSearch" class="widget-list-header-bottom">
        <widgetSearch icon="left" @keyup="searchItem" placeholder="Search here..."></widgetSearch>
      </div>
    </div>
    <div class="widget-list-body">
      <div class="item row" v-for="(item, index) in value" :key="index">
        <input type="checkbox" :id="'hsjska' + index" v-model="item.checked" />
        <div class="item-spacer"></div>
        <label class="label" :for="'hsjska' + index">{{ item.name }}</label>
      </div>
    </div>
    <div class="widget-list-footer row">
      <widgetButton :loading="false" @click="onConfirm()" class="btn-primary">
        Apply
      </widgetButton>
      <div class="h-spacer"></div>
      <widgetButton :loading="false" @click="onClose()" class="btn-primary-transparent">
        Cancel
      </widgetButton>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/main";
export default {
  name: "widget_input",
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    content() {
      return JSON.parse(
        JSON.stringify(
          this.value.map((item) => {
            eventBus.$set(item, "checked", false);
            return item;
          })
        )
      );
    },
  },
  data() {
    return {
      isAllChecked: false,
      tempValue: null,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        let vm = this;
        vm.saveInitiaData();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    let vm = this;
    vm.saveInitiaData();
    vm.toggleCheck({ target: null }, "clear");
  },
  methods: {
    saveInitiaData() {
      let vm = this;
      if (!vm.tempValue || (vm.tempValue && vm.tempValue.length === 0)) {
        vm.tempValue = JSON.parse(
          JSON.stringify(
            this.value.map((item) => {
              eventBus.$set(item, "checked", false);
              return item;
            })
          )
        );
      }
    },
    toggleCheck({ target }, type) {
      let vm = this;
      let content = vm.content;
      if (type == "all") {
        if (target.checked) {
          content = content.map((item) => {
            item.checked = true;
            return item;
          });
        } else {
          content.map((item) => {
            item.checked = false;
            return item;
          });
        }
      } else {
        vm.isAllChecked = false;
        content = content.map((item) => {
          item.checked = false;
          return item;
        });
      }
      vm.$emit("input", content);
    },
    searchItem({ target }) {
      let vm = this;
      let query = target.value;
      if (query) {
        let content = vm.value.filter((item) => {
          if (item.name) {
            return item.name.toLowerCase().includes(query.toLowerCase());
          } else {
            return false;
          }
        });
        vm.$emit("input", content);
      } else {
        vm.$emit("input", vm.tempValue);
      }
    },
    onConfirm() {
      let vm = this;
      vm.$emit(
        "confirm",
        vm.value
          .filter((item) => {
            return item.checked;
          })
          .map((item) => item.value)
      );
    },
    onClose() {
      let vm = this;
      vm.$emit("close", null);
    },
  },
};
</script>
<style lang="scss" scoped>
.widget-list-selecter {
  border-radius: 10px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ddd;

  .widget-list-header {
    height: 50px;
    border-bottom: 1px solid #ddd;
    padding: 10px;

    &.withSearch {
      height: 75px;
    }

    .widget-list-header-top {
      display: flex;
      justify-content: space-between;
      margin: 6px 0px;

      .btn-clear {
        text-align: right;
        color: #0884af;
      }
    }
  }

  .widget-list-body {
    max-height: 100px;
    padding: 10px;
    overflow-y: auto;

    .item {
      margin-bottom: 10px;
      cursor: pointer;

      .item-spacer {
        margin: 0px 4px;
      }

      .label {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ebebeb;
        border-radius: 20px;
        padding: 6px 15px;
        cursor: pointer;
      }
    }
  }

  .widget-list-footer {
    border-top: 1px solid #ddd;
    padding: 10px;
  }
}
</style>
