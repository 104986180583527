<template>
  <div class="tag">
    <span class="label" :title="value">{{ value | validate }}</span>
    <button v-if="canClose" class="remove-btn" @click="removeItem">
      &times;
    </button>
  </div>
</template>
<script>
export default {
  name: "tag-item-widget",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    canClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() { },
  methods: {
    removeItem() {
      let vm = this;
      vm.$emit("remove", vm.value);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.tag {
  background: #e8e8e8;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  position: relative;
  margin-bottom: 2px;
  height: 25px;

  @media (min-width: 300px) and (max-width: 630px) {
    margin-bottom: 5px;
  }

  span {
    font-weight: 300;
    padding-right: 10px;
    padding-left: 8px;
    text-transform: capitalize;
    // make sure the text is not longer than the container
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80px;
  }

  .remove-btn {
    border: none;
    background: #a51b1b;
    border-radius: 4px;
    margin-left: 10px;
    display: none;
    height: 100%;
    width: 30px;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  &:hover {
    .remove-btn {
      display: flex;
    }
  }
}
</style>