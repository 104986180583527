<template>
  <div class="wrapper">
    <div class="milt-select-widget" ref="milt_select_widget" @click="shop_pop_up = !shop_pop_up">
      <img class="icon" v-if="selected_item" :style="{ width: selected_item.width }" :src="selected_item.img" />
      <label v-if="selected_item">{{ selected_item.name }}</label>
      <label v-else>Question Type</label>
      <img class="dropimage" src="@/assets/admin-icon/allow-down.svg" />
    </div>
    <div class="select-items-widget" v-if="shop_pop_up" v-closable="{
      exclude: ['milt_select_widget'],
      handler: 'hidePopUp',
    }">
      <div class="select-item" v-for="(item, index) in input_data" @click="selectItem(item)" :key="index"
        v-if="type != item.denny">
        <img :src="item.img" :class="{ give_space: item.value == 'time' }" :style="{ width: item.width }" />
        <label>{{ item.name | validate }}</label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "question-select-widget",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      input_data: [
        {
          name: "Short answer",
          value: "short_answer",
          img: require("@/assets/question-icon/short_answer.svg"),
          width: "16px",
          denny: "form",
        },
        {
          name: "Paragraph",
          value: "paragraph",
          img: require("@/assets/question-icon/paragraph.svg"),
          width: "15px",
          denny: "form",
        },
        {
          name: "Multiple choice",
          value: "multiple_choice",
          img: require("@/assets/question-icon/multiple_choice.svg"),
          width: "15px",
        },
        {
          name: "Checkboxes",
          value: "checkboxes",
          img: require("@/assets/question-icon/checkbox.svg"),
          width: "15px",
        },
        {
          name: "Dropdown",
          value: "dropdown",
          img: require("@/assets/question-icon/dropdown.svg"),
          width: "15px",
        },
        {
          name: "File Upload",
          value: "file_upload",
          img: require("@/assets/question-icon/file_upload.svg"),
          width: "19px",
          denny: "form",
        },
        {
          name: "Date",
          value: "date",
          img: require("@/assets/question-icon/calendar.svg"),
          width: "20px",
          denny: "form",
        },
        {
          name: "Time",
          value: "time",
          img: require("@/assets/question-icon/clock.svg"),
          width: "16px",
          denny: "form",
        },
      ],
      selected_item: null,
      shop_pop_up: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    let vm = this;
  },
  methods: {
    selectItem(item) {
      let vm = this;
      vm.selected_item = item;
      vm.$emit("input", item.value);
      vm.hidePopUp();
    },
    hidePopUp() {
      let vm = this;
      vm.shop_pop_up = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.wrapper {
  position: relative;
  border-radius: 5px;
  width: 200px;

  .milt-select-widget {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0px 8px;
    position: relative;

    img.icon {
      width: 30px;
      margin-right: 5px;
    }

    label {
      margin-bottom: 0px !important;
      margin-right: 30px;
    }

    .dropimage {
      position: absolute;
      top: 10px;
      right: 11px;
    }
  }

  .select-items-widget {
    position: absolute;
    top: 50px;
    left: 0px;
    right: 0px;
    background: #ffffff;
    border: 1px solid #f2f4f8;
    box-sizing: border-box;
    border-radius: 5px;
    min-height: 200px;
    overflow-y: auto;
    z-index: 99999;

    .select-item {
      border-bottom: 1px solid #f2f4f8;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: #f2f4f8;
      }

      img {
        width: 30px;
        margin-right: 10px;

        &.give_space {
          margin-left: 2px;
        }
      }

      label {
        margin: 0px;
        cursor: pointer;
      }
    }
  }
}
</style>