<script>
import { hasDeepValue, has } from "has-deep-value";
import moment from "moment";
import { eventBus } from "@/main";
import store from "@/store";
export default {
  data() {
    return {
      APPLICATION_NAME: "meritios",
    };
  },
  methods: {
    moment,
    hasDeepValue,
    $navigationGoBack(defaultPath) {
      let vm = this;
      store.commit("SET_NAVIGATION_DIRECTION", true);
      store.dispatch("removeNavigationHistory", null).then((response) => {
        let removedNavigation = response;
        if (removedNavigation) {
          vm.$router.push({ path: removedNavigation.path });
        } else {
          vm.$router.push({ path: defaultPath });
        }
      });
    },
    htmlToText(html) {
      var temp = document.createElement("div");
      temp.innerHTML = html;
      return temp.textContent;
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    $makeId(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return 'id_' + result;
    },
    $checkUserPermission(perm) {
      let vm = this;
      let user_data = JSON.parse(vm.$localStorage.get("logged_user_data"));
      let roles = user_data.roles;
      return roles.includes(perm);
    },
    $htmlToText(html) {
      var temp = document.createElement("div");
      temp.innerHTML = html;
      return temp.textContent;
    },
    $isFieldsValidated(form, rules, length = 10000) {
      let vm = this;
      let is_error = false;
      Object.keys(rules).forEach((key, index) => {
        if (index < length - 1) {
          if (rules[key].run(form[key])) {
            let error_message = rules[key].run(form[key]);
            if (error_message) {
              if (!is_error) {
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "Warning",
                  text: error_message,
                });
              }
              is_error = true;
            }
          }
        }
      });
      return !is_error;
    },
    $validateDateTime(value) {
      if (value && value !== "null" && value !== "" && value !== "0000-00-00") {
        return true;
      }
      return false;
    },
    $validateData(value) {
      if (value && value !== "null" && value !== "" && value !== "0000-00-00") {
        return true;
      }
      return false;
    },
    $getLoggedUser() {
      let vm = this;
      let user_data = JSON.parse(vm.$localStorage.get("logged_user_data"));
      return user_data;
    },
    $nameInitials(name) {
      var initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      return initials;
    },
    $fetchFromObject(obj, prop) {
      let vm = this;
      if (typeof obj === "undefined") return false;
      var _index = prop.indexOf(".");
      if (_index > -1) {
        return vm.$fetchFromObject(
          obj[prop.substring(0, _index)],
          prop.substr(_index + 1)
        );
      }
      if (obj[prop] && obj[prop] != "null" && obj[prop] != "") {
        return true;
      } else {
        return false;
      }
    },
    $hasKey(obj, keyString) {
      let vm = this;
      if (vm.hasDeepValue(obj, keyString)) {
        if (vm.$fetchFromObject(obj, keyString)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    $dateObj(date) {
      let vm = this;
      if (date && date != "null" && date != "") {
        let yyy = vm.moment(date).format("YYYY");
        let mm = vm.moment(date).format("MM");
        let dd = vm.moment(date).format("DD");
        return new Date(yyy, mm, dd);
      } else {
        return new Date();
      }
    },
    $fullUrl(uri) {
      return this.$store.state.auth.api.fileUrl + uri;
    },
    $crmFullUrl(uri) {
      return this.$store.state.auth.api.fileUrl + uri;
    },

    $notifyAppError(message) {
      eventBus.$emit("app-error", {
        isError: true,
        message: message,
      });
    },
  },
};
</script>