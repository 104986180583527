<template>
    <div class="profile-widget px-1 flex items-center">
        <div
            class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600  mr-3">
            <span class="font-medium text-gray-600 dark:text-gray-300">JL</span>
        </div>
        <h4>Abe Jahwin</h4>
    </div>
</template>
  
<script>
// @ is an alias to /src
export default {
    name: 'profile-widget',
    components: {
    }
}
</script>
  