<template>
  <div class="widget_wizard">
    <div class="tab-item" v-for="(item, index) in newTabs" :key="index" :class="{
      active: index + 1 == active,
      disabled: showDot && item.disabled,
    }">
      <div class="tab-dot" v-if="showDot && item.dot"></div>
      <div class="tab-icon" v-if="item.icon" @click="onClick(item, index)">
        <img class="inactive-icon" :src="item.icon.inactive" />
        <img class="active-icon" :src="item.icon.active" />
      </div>
      <div class="tab-title" @click="onClick(item, index)">
        {{ item.title }}
      </div>
      <div class="tab-menu-dropup" v-if="item.drobdown && item.drobdown.length > 0 && item.dropdownOpen">
        <div class="tab-item" v-for="(drop, i) in item.drobdown" @click="onClickDrop(item, drop, index)">
          {{ drop.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "widget_wizard",
  props: {
    showDot: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 1,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {

  },
  data() {
    return {
      active: 1,
      newTabs: []
    };
  },
  watch: {
    value(val) {
      let vm = this;
      vm.active = val;
    },
  },
  mounted() {
    let vm = this;
    vm.newTabs = vm.tabs.map((item, index) => {
      return {
        ...item,
        dropdownOpen: false,
      };
    });
    vm.active = vm.value;
  },
  methods: {
    onClick(tab, index) {
      let vm = this;
      if (tab.drobdown) {
        vm.active = index + 1;
        tab.dropdownOpen = !tab.dropdownOpen;
      } else {
        if (vm.showDot) {
          if (tab.dot) {
            vm.active = index + 1;
            vm.$emit("change", tab);
            vm.$emit("input", vm.active);
          }
          return;
        }
        vm.active = index + 1;
        vm.$emit("change", tab);
        vm.$emit("input", vm.active);
      }

    },
    onClickDrop(tab, drop, index) {
      let vm = this;
      vm.active = index + 1;
      tab.dropdownOpen = false;
      vm.$emit("change", drop);
      vm.$emit("input", vm.active);
    }
  },
};
</script>
<style lang="scss" scoped>
.widget_wizard {
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 9999999;
  position: relative;

  .tab-item {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 0px 10px;
    height: 35px;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }

    .tab-dot {
      position: absolute;
      top: -2px;
      right: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #ea0000;
    }

    &:hover {
      opacity: 0.8;

      &.disabled {
        opacity: 0.2;
      }
    }

    .tab-icon {
      margin-right: 5px;

      img {
        width: 15px;
        height: 15px;
      }

      .inactive-icon {
        display: block;
      }

      .active-icon {
        display: none;
      }
    }

    .tab-title {
      &::before {
        position: absolute;
        bottom: 1px;
        left: 0;
        content: "";
        width: 100%;
        border-bottom: 2px solid #dcdcdc;
      }
    }

    // Select first tab
    &:first-child {
      .tab-title {
        &::before {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }
    }

    // Select last tab
    &:last-child {
      .tab-title {
        &::before {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }

    &.active {
      .tab-icon {
        .inactive-icon {
          display: none;
        }

        .active-icon {
          display: block;
        }
      }

      .tab-title {
        color: #1BA5A4;

        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          width: 100%;
          border-bottom: 4px solid #1BA5A4;
        }
      }
    }

    .tab-menu-dropup {
      position: absolute;
      top: 35px;
      left: 0;
      width: 150px;
      background: #fff;
      border: 1px solid #dcdcdc;
      border-radius: 5px;
      box-shadow: 0px 0px 5px 0px #dcdcdc;
      display: flex;
      flex-direction: column;
      z-index: 9999;

      .tab-item {
        padding: 0px 10px;
        height: 40px;
        display: flex;
        align-items: center;
        width: 100%;

        &:hover {
          background: #e0e0e0 !important;
          cursor: pointer;
        }
      }

    }

    .tab-item:hover .tab-menu-dropup {
      display: block;
    }

  }

  &.fill {
    .tab-item {
      width: 100%;
      justify-content: center;
    }
  }
}
</style>
