<template>
  <div class="search-section">
    <div class="search-widget">
      <input
        type="text"
        class="search-input"
        :value="value"
        :placeholder="placeholder"
        @keyup="searchQuel"
      />
      <div class="loading-icon" v-if="is_loading">
        <loading
          :height="15"
          color="#1ba5a4"
          :opacity="1"
          :width="15"
          :active.sync="is_loading"
          :is-full-page="false"
          :can-cancel="false"
          background-color="#fff"
          class="loader"
        ></loading>
      </div>
    </div>
    <!-- Search result -->
    <div
      class="searched-result-data"
      v-if="show_search_result"
      v-closable="{ exclude: [], handler: 'hideSearchResult' }"
    >
      <div
        class="item"
        v-for="(item, index) in search_result"
        :key="index"
        @click="selectItem(item)"
      >
        __
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "search-widget",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "account",
    },
  },
  components: {},
  data: () => {
    return {
      is_loading: false,
      show_search_result: false,
      search_result: [],
      selected_result: "",
      query: "",
    };
  },
  watch: {},
  mounted() {
    let vm = this;
  },
  methods: {
    hideSearchResult() {
      let vm = this;
      vm.show_search_result = false;
    },
    searchQuel({ target }) {
      let vm = this;
      vm.is_loading = true;
      vm.$emit("input", target.value);
      vm.query = target.value;
      vm.$store
        .dispatch("SEARCH_LOCATION", {
          query: target.value,
          type: vm.type,
        })
        .then((response) => {
          vm.is_loading = false;
          vm.show_search_result = true;
          vm.search_result = response.data;
        })
        .catch((error) => {
          vm.is_loading = false;
          console.error({ error });
        });
    },
    selectItem(item) {
      let vm = this;
      vm.show_search_result = false;
      vm.selected_result = item;
      vm.$emit("onSubmit", vm.selected_result);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .search-widget {
    display: flex;
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    height: 50px;
    border-radius: 10px;
    background: #fff;
    position: relative;
    .search-input {
      width: calc(100% - 40px);
      border: none;
      outline: none;
    }
    .loading-icon {
      width: 40px;
      position: relative;
      background: #fff;
    }
  }
  .searched-result-data {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 46px;
    box-shadow: 0px 0px 4px rgb(165, 165, 165);
    max-height: 200px;
    background: #fff;
    overflow-y: auto;
    z-index: 999999;
    .item {
      width: 100%;
      padding: 15px 10px;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      &:hover {
        background-color: rgb(240, 240, 240);
      }
    }
  }
}
</style>
