export const GET_FEEDS_REQUEST = "GET_POSTS_REQUEST";
export const GET_PROFILE_FEEDS = "GET_PROFILE_FEEDS";
export const GET_FEEDS_POSTS = "GET_FEEDS_POSTS";
export const GET_MORE_FEEDS_POSTS = "GET_MORE_FEEDS_POSTS";
export const POST_FEED_POST = "POST_FEED_POST";
export const TAG_FEED_POST = "TAG_FEED_POST";
export const POST_FEED_COMMENT = "POST_FEED_COMMENT";
export const POST_FEED_LIKE = "POST_FEED_LIKE";
export const POST_FEED_COMMENT_TEXT = "POST_FEED_COMMENT_TEXT"
export const DELETE_FEED_POST = "DELETE_FEED_POST";
export const POST_SEARCH_TAGS = "POST_SEARCH_TAGS";
export const REMOVE_FEED_COMMENT = "REMOVE_FEED_COMMENT";
export const EDIT_POST = "EDIT_POST";