<template>
  <div class="page-wrapper">
    <div class="page-header">
      <WidgetToolbar title="Tasks" :icon="require('@/assets/admin-icon/menu/tasks_active.svg')">
        <div class="options">
          <widgetSearch placeholder="Search here..." icon="right" @keyup="searchTask">
          </widgetSearch>
          <div class="h-spacer"></div>
          <widgetDropdown v-model="show_display_filter" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              Display
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in display_filter" :key="index"
                @click="changeDisplay(item)">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>
          <div class="v-line-divider" style="height: 40px"></div>
          <widgetDropdown v-model="show_sort_filter" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              <img src="@/assets/admin-icon/filter.svg" />
              Filter
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in sort_filter" :key="index" @click="
                (sort_filter_data = item.value), (show_sort_filter = false)
                ">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>
          <div class="v-line-divider" style="height: 40px"></div>
          <widgetDropdown v-model="show_data_filter" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              {{ filter_data == "mine" ? "My Task" : "All Task" }}
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in data_filter" :key="index"
                @click="(filter_data = item.value), (show_data_filter = false)">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>

          <div class="h-spacer"></div>
          <widgetButton :loading="false" @click="addNewStory()" class="btn-secondary">
            Add Task +
          </widgetButton>
        </div>
      </WidgetToolbar>
    </div>

    <div class="page-body">
      <div class="wrapper-section" :style="{ minWidth: storyWindowWidth }">
        <div class="column" :class="{ relaxed: view_type == 'relaxed' }" v-for="(item, index) in columns" :key="index">
          <div class="container">
            <div class="header">
              <div class="row-1">
                <div class="title">
                  {{ item.name | validate }}
                </div>
                <div class="options">
                  <!-- <button @click="addNewStory(item)" class="btn-add">+</button> -->
                </div>
              </div>
            </div>

            <div class="body" @dragover.self="columnDradOver($event, index)"
              @dragenter.self="columnDradEnter($event, index)" @dragleave.self="columnDradLeave($event, index)"
              @drop.self="columnDradDrop($event)" @dragend="columnDragEnd($event)">
              <div v-for="(task, i) in item.tasks" :key="i" class="card" draggable="true"
                @dragstart="cardDradStart($event, task, index, i)" @dragend="cardDragEnd"
                @dragover="cardDradOver($event, index)" @dragenter="cardDradEnter($event, index, i)"
                @dragleave="cardDradLeave($event, index)" @drop="cardDradDrop($event)">
                <div class="card-header">
                  <div class="title" @click="viewStory(task)">
                    {{ task.name | validate }}
                  </div>
                  <input class="card-checkbox" type="checkbox" @change="makeCompletedTask($event, task)" />
                  <div class="card-type">
                    <label :style="{ color: getColor(task.type) }">{{
                      task.type | validate
                      }}</label>
                    <div :style="{ 'background-color': getColor(task.type) }" class="type-box"></div>
                  </div>
                </div>
                <div class="compang-widget" @click="viewStory(task)">
                  <span class="company-name" v-if="task.account">{{
                    task.account.name | validate
                    }}</span>
                  <div class="company-contact" @click="viewStory(task)" v-if="task.contacts.length > 0">
                    <template v-for="(contact, index) in task.contacts">
                      <span :key="index" v-if="index == 0">
                        {{ contact.first_name | validate }}
                        {{ contact.last_name | validate }}
                      </span>
                    </template>
                    <div v-if="task.contacts.length > 1" class="other-contact">
                      , & {{ (task.contacts.length - 1) | validate }} more
                      contacts
                    </div>
                  </div>
                </div>
                <div class="members" @click="viewStory(task)">
                  <div class="member" v-for="(member, index) in task.assignees" :key="index">
                    <assingProfileToolTip :member="member">
                      <img v-if="member.profile_picture" :src="$fullUrl('images/profiles/' + member.profile_picture)
                        " :class="{ override: index > 0 }" />

                      <img v-else :class="{ override: index > 0 }" src="@/assets/avatar.png" />
                    </assingProfileToolTip>
                    <span v-if="task.assignees.length == 1" class="name">{{
                      (member.first_name + " " + member.last_name) | validate
                      }}</span>
                  </div>
                </div>
                <div class="due-date" v-if="task.due_date">
                  {{
                    moment(task.due_date).format("ddd, DD MMM YYYY") | validate
                  }}
                </div>
              </div>
              <div @dragover="cardDradOver($event, index)" @dragleave="cardDradLeave($event, index)"
                @drop="cardDradDrop($event)" v-if="item.on_drag_active" class="drag-holder card">
                Drag card here
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Section Modal BUT NOT NEEDED -->
      <addTaskSectionModal v-if="show_section_form"></addTaskSectionModal>

      <!-- Add Task Modal -->
      <addTaskStoryModal v-if="show_form" :value="story_item" :columns="columns" @close="onCloseStory()"
        @saved="onStorySaved()" @delete="deleteStory" @completed="makeCompletedTask"></addTaskStoryModal>
    </div>
  </div>
</template>
<script>
import addTaskStoryModal from "./components/addTaskStoryModal.vue";
import addTaskSectionModal from "./components/addTaskSectionModal.vue";
import { eventBus } from "@/main";
export default {
  components: {
    addTaskStoryModal,
    addTaskSectionModal,
  },
  data: () => ({
    view_type: "compact",
    tag_filter_data: "",
    sort_filter_data: "",
    show_form: false,
    columns: [],
    item_hold: null,
    active_column_index: null,
    active_card_index: null,
    inactive_column_index: null,
    inactive_card_index: null,
    team_id: null,
    section_form: {
      id: null,
      section_name: "",
    },
    show_section_form: false,
    section_add_loading: false,
    show_story_form: false,
    story_item: null,
    show_display_filter: false,
    display_filter: [
      {
        value: "relaxed",
        name: "Relaxed",
      },
      {
        value: "compact",
        name: "Compact",
      },
    ],
    show_tags_filter: false,
    tags_filter: [
      {
        name: "Task",
        value: "task",
      },
      {
        name: "Follow up",
        value: "Follow up",
      },
      {
        name: "Call",
        value: "Call",
      },
      {
        name: "Mail",
        value: "Call",
      },
      {
        name: "Meeting",
        value: "Meeting",
      },
      {
        name: "Other",
        value: "Other",
      },
    ],
    show_sort_filter: false,
    sort_filter: [
      {
        value: "create_date",
        name: "Create Date",
      },
      {
        value: "name",
        name: "Name",
      },
    ],
    show_data_filter: false,
    data_filter: [
      {
        value: "mine",
        name: "My Task",
      },
      {
        value: "all",
        name: "All Task",
      },
    ],
    filter_data: "all",
    task_types: [
      {
        name: "Task",
        color: "#0884AF",
      },
      {
        name: "Follow up",
        color: "#1BA5A4",
      },
      {
        name: "Call",
        color: "#FFC273",
      },
      {
        name: "Mail",
        color: "#9FD076",
      },
      {
        name: "Meeting",
        color: "#ACAF5C",
      },
      {
        name: "Other",
        color: "#ED6400",
      },
    ],
    storyWindowWidth: "0px",
  }),
  mounted() {
    let vm = this;
    // Get team data
    vm.getTask(vm.filter_data);
  },
  methods: {
    changeDisplay(item) {
      let vm = this;
      vm.view_type = item.value;
      vm.show_display_filter = false;
      if (vm.view_type == "relaxed") {
        vm.storyWindowWidth = vm.columns.length * 310 + "px";
      } else {
        vm.storyWindowWidth = vm.columns.length * 260 + "px";
      }
    },
    onCloseStory() {
      let vm = this;
      vm.show_form = false;
    },
    searchTask({ target }) {
      let vm = this;
      if (target.value) {
        vm.$store
          .dispatch("TASK_SEARCH", {
            query: target.value,
            team_id: vm.team_id,
            type: vm.filter_data,
          })
          .then((response) => {
            vm.columns = response.data.map((item) => {
              eventBus.$set(item, "on_drag_active", false);
              return item;
            });
          })
          .catch((error) => { });
      } else {
        vm.getTask(vm.filter_data);
      }
    },
    filterBySort(val) {
      let vm = this;
      vm.$store
        .dispatch("TASK_FILTER_BY_SORT", {
          option: val,
          team_id: vm.team_id,
          type: vm.filter_data,
        })
        .then((response) => {
          vm.columns = response.data.map((item) => {
            eventBus.$set(item, "on_drag_active", false);
            return item;
          });
        })
        .catch((error) => { });
    },
    filterByTag(val) {
      let vm = this;
      vm.show_tags_filter = false;
      if (val.length > 0) {
        vm.$store
          .dispatch("TASK_FILTER_BY_TAG", {
            option: val,
            team_id: vm.team_id,
            type: vm.filter_data,
          })
          .then((response) => {
            vm.columns = response.data.map((item) => {
              eventBus.$set(item, "on_drag_active", false);
              return item;
            });
          })
          .catch((error) => { });
      } else {
        vm.getTask(vm.filter_data);
      }
    },
    getColor(val) {
      let vm = this;
      let color = "";
      vm.task_types.forEach((item) => {
        if (item.name == val) {
          color = item.color;
        }
      });
      return color;
    },
    setImagefile(file) {
      let vm = this;
      if (file) {
        vm.form.file = file;
      }
    },
    getTask(type, loadingEnabled = true) {
      let vm = this;
      if (loadingEnabled) {
        vm.$store.dispatch("LOADER_START");
      }
      vm.$store
        .dispatch("TASK_GET_ALL", { team_id: vm.team_id, type: type })
        .then((response) => {
          vm.columns = response.data.map((item) => {
            eventBus.$set(item, "on_drag_active", false);
            return item;
          });
          vm.$store.dispatch("LOADER_CLOSE");

          vm.changeDisplay({ value: vm.view_type });

          if (vm.$route.params.id) {
            vm.columns.forEach((column) => {
              column.tasks.forEach((task) => {
                if (task.id == vm.$route.params.id) {
                  vm.viewStory(task);
                }
              });
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("LOADER_CLOSE");
        });
    },
    reLoadTask() {
      let vm = this;
      vm.getTask('all', false);
    },
    // Section
    editSection(item) {
      let vm = this;
      vm.section_form.id = item.id;
      vm.section_form.section_name = item.name;
      vm.show_section_form = true;
    },
    submitSection() {
      let vm = this;
      vm.section_add_loading = true;
      if (!vm.section_form.id) {
        vm.$store
          .dispatch("TASK_ADD_NEW_SECTION", vm.section_form)
          .then((response) => {
            vm.section_add_loading = false;
            vm.show_section_form = false;
            vm.getTask(vm.filter_data);
          })
          .catch((error) => {
            vm.section_add_loading = false;
          });
      } else {
        vm.$store
          .dispatch("TASK_UPDATE_SECTION", vm.section_form)
          .then((response) => {
            vm.section_add_loading = false;
            vm.show_section_form = false;
            vm.getTask(vm.filter_data);
          })
          .catch((error) => {
            vm.section_add_loading = false;
          });
      }
    },
    addSectionNew() {
      let vm = this;
      vm.show_section_form = true;
      vm.section_form = {
        id: null,
        team_id: vm.team_id,
        section_name: "",
      };
    },
    deleteSection(id) {
      let vm = this;
      vm.$store
        .dispatch("TASK_DELETE_SECTION", {
          column_id: id,
          team_id: vm.team_id,
        })
        .then((response) => {
          vm.show_section_form = false;
          vm.getTask(vm.filter_data);
        })
        .catch((error) => { });
    },
    // Story
    onStorySaved() {
      let vm = this;
      vm.show_form = false;
      vm.getTask(vm.filter_data);
    },
    addNewStory() {
      let vm = this;
      vm.story_item = null;
      vm.show_form = true;
    },
    viewStory(task) {
      let vm = this;
      vm.story_item = task;
      vm.editStory(task);
    },
    editStory(task) {
      let vm = this;
      vm.story_item = task;
      vm.show_form = true;
      vm.show_story_form = false;
    },
    deleteStory(task) {
      let vm = this;
      vm.$store
        .dispatch("TASK_DELETE_STORY", {
          section_id: task.column_id,
          team_id: vm.team_id,
          task_id: task.id,
        })
        .then((response) => {
          vm.show_story_form = false;
          vm.show_form = false;
          vm.getTask(vm.filter_data);
        })
        .catch((error) => { });
    },
    makeCompletedTask({ target }, task) {
      let vm = this;
      if (target.checked) {
        vm.$store
          .dispatch("TASK_MAKE_COMPLETED", {
            id: task.id,
            team_id: vm.team_id,
          })
          .then((response) => {
            vm.getTask(vm.filter_data);
            vm.$notify({
              group: "status",
              title: "Success",
              text: "Task is now marked as completed",
              type: "success",
              duration: 3000,
            });
          })
          .catch((error) => { });
      }
    },
    // ====
    cardDragEnd({ target }) {
      let vm = this;
      target.className = " card";
      vm.columns.forEach((item) => {
        item.on_drag_active = false;
      });
    },
    cardDradStart(e, item, column_index, card_index) {
      let vm = this;
      vm.item_hold = item;
      vm.inactive_column_index = column_index;
      vm.inactive_card_index = card_index;
      e.target.className += " hold";
      setTimeout(() => {
        e.target.className += " invisible";
      }, 0);
    },
    columnDradOver(e, index) {
      let vm = this;
      e.preventDefault();
      vm.columns.forEach((item) => {
        item.on_drag_active = false;
      });
      vm.columns[index].on_drag_active = true;
    },
    columnDradEnter(e, index) {
      e.preventDefault();
      e.target.className += " hovered";
      let vm = this;
      vm.active_column_index = index;
    },
    columnDradLeave(e, index) {
      e.target.className = "body";
      let vm = this;
    },
    columnDradDrop(e) {
      let vm = this;
      e.target.className = "body";
      if (vm.active_column_index != null) {
        vm.columns[vm.active_column_index].tasks.push(vm.item_hold);
        vm.columns[vm.inactive_column_index].tasks.splice(
          vm.inactive_card_index,
          1
        );
        let card_index = vm.columns[vm.active_column_index].tasks.length - 1;
        vm.cardMove(card_index, vm.active_column_index);
      }
    },
    columnDragEnd(e) {
      let vm = this;
    },
    cardDradOver(e, index) {
      e.preventDefault();
      let vm = this;
      vm.columns.forEach((item) => {
        item.on_drag_active = false;
      });
      vm.columns[index].on_drag_active = true;
    },
    cardDradEnter(e, column_index, card_index) {
      e.preventDefault();
      let vm = this;
      vm.active_column_index = column_index;
      vm.active_card_index = card_index;
    },
    cardDradLeave(e, column_index) {
      let vm = this;
    },
    cardDradDrop(e) {
      let vm = this;
      vm.columns.forEach((item) => {
        item.on_drag_active = false;
      });
      if (vm.active_card_index != null) {
        vm.columns[vm.inactive_column_index].tasks.splice(
          vm.inactive_card_index,
          1
        );
        vm.columns[vm.active_column_index].tasks.splice(
          vm.active_card_index,
          0,
          vm.item_hold
        );
        vm.cardMove(vm.active_card_index, vm.active_column_index);
      }
    },
    cardMove(card_index, column_index) {
      let vm = this;
      let section_id = vm.columns[column_index].id;
      let story_id = vm.columns[column_index].tasks[card_index].id;
      vm.$store
        .dispatch("TASK_MOVE_STORY", {
          to_section_id: section_id,
          story_id: story_id,
          story_index: card_index,
        })
        .then((response) => {
          vm.show_story_form = false;
          vm.reLoadTask();
        })
        .catch((error) => { });
    },
  },
  watch: {
    sort_filter_data(val) {
      let vm = this;
      vm.filterBySort(val);
    },
    filter_data(val) {
      let vm = this;
      vm.getTask(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5px;
  background: #f6f6f6;

  .page-header {
    height: 38px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .options {
      display: flex;
      align-items: center;
    }
  }

  .page-body {
    height: calc(100% - 43px);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    .wrapper-section {
      height: 100%;
      display: flex;
      transition: 600ms ease-in-out;

      .column {
        width: 250px;
        margin-right: 5px;
        border-radius: 5px;
        height: 100%;
        display: flex;
        flex-direction: column;
        transition: 600ms ease-in-out;

        .container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          .header {
            width: 100%;
            height: 50px;
            padding: 0px 10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            display: flex;
            background: #fff;

            .row-1 {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 40px;
              width: 100%;
              border-bottom: 2px solid #ebebeb;

              .title {
                text-transform: capitalize;
                font-size: 12px;
                overflow: hidden;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
              }

              .options {
                .btn-add {
                  background: linear-gradient(180deg, #229fa5 0%, #229ea5 100%);
                  color: #fff;
                  border-radius: 5px;
                  padding-top: 3px;
                  width: 35px;
                  height: 35px;
                  margin-left: 14px;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }

          .body {
            width: 100%;
            max-height: calc(100% - 60px);
            min-height: 100px;
            overflow-y: auto;
            padding: 0px 10px;
            padding-bottom: 50px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            display: flex;
            flex-direction: column;
            background: #fff;

            .card {
              width: 100%;
              background: #f5f5f5;
              position: relative;
              padding: 6px 6px;
              cursor: pointer;
              border-radius: 5px;
              margin-bottom: 5px;

              &:hover {
                .card-header {
                  .card-type {
                    display: none;
                  }

                  .card-checkbox {
                    display: block;
                  }
                }
              }

              .card-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                width: 100%;

                .title {
                  font-size: 12px;
                  color: #000;
                  overflow: hidden;
                  text-decoration: none;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 100%;
                }

                .card-checkbox {
                  display: none;
                  margin: 0px;
                }

                .card-type {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;

                  label {
                    font-size: 10px;
                    white-space: nowrap;
                    text-align: right;
                  }

                  .type-box {
                    height: 10px;
                    width: 10px;
                    border-radius: 2px;
                    margin-left: 5px;
                  }
                }
              }

              .compang-widget {
                display: flex;
                flex-direction: column;
                padding-right: 20px;

                .company-name {
                  color: #707070;
                  font-size: 11px;
                  margin-bottom: 5px;
                }

                .company-contact {
                  display: flex;
                  align-items: center;
                  margin-bottom: 5px;

                  span {
                    font-size: 11px;
                    white-space: nowrap;
                  }

                  .other-contact {
                    color: #ccc;
                    font-size: 12px;
                    white-space: nowrap;
                  }
                }
              }

              .members {
                display: flex;

                .member {
                  display: flex;
                  align-items: center;

                  img {
                    width: 20px;
                    height: 20px;
                    object-fit: cover;
                    margin-right: 4px;
                    border-radius: 50%;
                    border: 2px solid #fff;

                    &.override {
                      margin-left: -10px;
                    }
                  }

                  .name {
                    background: #06a1a11f;
                    padding: 2px 5px;
                    border-radius: 15px;
                    font-size: 11px;
                    color: #04a5a5;
                    display: block;
                    overflow: hidden;
                    text-decoration: none;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 68px;
                    text-transform: capitalize;
                  }
                }
              }

              .due-date {
                position: absolute;
                bottom: 18px;
                right: 15px;
                font-size: 10px;
                display: none;
              }

              &.hold {
                border: solid#04a5a5 1px;
                border-radius: 5px;
              }

              &.invisible {
                opacity: 0.5;
              }
            }

            .drag-holder {
              height: 80px;
              width: 100%;
              border: 1px dashed rgb(75, 75, 75);
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &.hovered {
              background: #f4f4f4;
            }
          }
        }

        &.relaxed {
          width: 300px;

          .container {
            .body {
              .card {
                .due-date {
                  position: absolute;
                  bottom: 18px;
                  right: 15px;
                  font-size: 10px;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
