export const LEADS_ADD_NEW_SECTION = "LEADS_ADD_NEW_SECTION";
export const LEADS_UPDATE_SECTION = "LEADS_UPDATE_SECTION";
export const LEADS_ADD_NEW_STORY = "LEADS_ADD_NEW_STORY";
export const LEADS_UPDATE_STORY = "LEADS_UPDATE_STORY";
export const LEADS_GET_ALL = "LEADS_GET_ALL";
export const LEADS_DELETE_STORY = "LEADS_DELETE_STORY";
export const LEADS_MOVE_STORY = "LEADS_MOVE_STORY";
export const LEADS_DELETE_SECTION = "LEADS_DELETE_SECTION";
export const LEADS_FILTER_BY_SORT = "LEADS_FILTER_BY_SORT";
export const LEADS_SEARCH = "LEADS_SEARCH";
export const LEADS_FILTER_BY_TAG = "LEADS_FILTER_BY_TAG";
export const LEADS_GET_TAGS = "LEADS_GET_TAGS";
export const LEADS_FILTER_BY_CLOSE_DATE = "LEADS_FILTER_BY_CLOSE_DATE";
export const LEADS_FILTER_BY_DISPLAY = "LEADS_FILTER_BY_DISPLAY";