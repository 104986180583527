<template>
    <div class="user-managment h-full w-full p-2 bg-gray-100">
        <PageHeader v-if="!isLoadingSchool">
            <template v-slot:brand>
                <div class="page-brand flex items-center mr-3">
                    <img src="@/assets/images/menu/user-active.svg" class="icon mr-2">
                    <span class=" text-black">User management</span>
                </div>
            </template>

            <template v-slot:path>
                <div class="nav-path-widget flex items-center">
                    <div class="nav-path mr-4 text-green-600 cursor-pointer" @click="$router.push({
                        name: 'users-management'
                    })">All Schools
                        > </div>
                    <div class="nav-path">{{ school.company_info.name }}</div>
                </div>
            </template>

        </PageHeader>
        <div class="page-body">

            <div v-if="!isLoadingSchool" class="page-container">
                <div class="page-container-header">
                    <widgetTabs :tabs="[
                        {
                            title: 'Feed',
                            value: 'feed',
                        },
                        {
                            title: 'LIA',
                            value: 'lia',
                        },
                        {
                            title: 'Tasks',
                            value: 'tasks',
                        },
                        {
                            title: 'Instructor',
                            value: 'instructor',
                        },
                        {
                            title: 'Accounts',
                            value: 'accounts',
                            drobdown: [
                                {
                                    title: 'Accounts',
                                    value: 'accounts',
                                },
                                {
                                    title: 'Account contact',
                                    value: 'account_contact',
                                },
                                {
                                    title: 'LIA contact',
                                    value: 'lia_contact',
                                }
                            ]
                        },
                        {
                            title: 'School',
                            value: 'school',
                        },
                        {
                            title: 'Resumes',
                            value: 'resumes',
                        },
                        {
                            title: 'Candidates',
                            value: 'candidates',
                        },
                        {
                            title: 'Leads',
                            value: 'leads',
                        },
                        {
                            title: 'Reports',
                            value: 'reports',
                        },
                        {
                            title: 'Comunications',
                            value: 'comunications',
                        },
                    ]" v-model="tab" @change="onTabChange"></widgetTabs>
                </div>
                <div class="page-container-body">
                    <Feed :school="school" v-if="tab == 1"></Feed>
                    <Tasks :school="school" v-if="tab == 3 && selecteTab == 'tasks'"></Tasks>
                    <Leads :school="school" v-if="tab == 9 && selecteTab == 'leads'"></Leads>
                    <Accounts v-if="tab == 5 && selecteTab == 'accounts'"></Accounts>
                    <AccountContacts v-if="tab == 5 && selecteTab == 'account_contact'"></AccountContacts>
                    <LIAContacts v-if="tab == 5 && selecteTab == 'lia_contact'"></LIAContacts>
                    <School :school="school" v-if="tab == 6"></School>
                </div>
            </div>


            <div v-if="isLoadingSchool">
                <loading :height="20" color="#1ba5a4" :opacity="1" :width="20" :active.sync="isLoadingSchool"
                    :is-full-page="false" :can-cancel="false" background-color="#fff" class="loader"></loading>
            </div>



        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from "@/components/PageHeader.vue";
import School from "./School"
import Feed from "./Feed.vue"
import Accounts from "./Accounts/Accounts.vue";
import AccountContacts from "./Accounts/AccountContacts.vue";
import LIAContacts from "./Accounts/LIAContacts.vue";
import Leads from "./Leads/Leads.vue"
import Tasks from "./Task/Tasks.vue"

export default {
    name: 'feed',
    components: {
        PageHeader,
        School,
        Feed,
        Accounts,
        AccountContacts,
        LIAContacts,
        Leads,
        Tasks
    },
    data() {
        return {
            tab: 1,
            school: null,
            isLoadingSchool: true,
            selecteTab: ""
        }
    },
    mounted() {
        let vm = this;
        vm.getSchool();
    },
    methods: {
        onTabChange(tab) {
            this.selecteTab = tab.value;
        },
        getSchool() {
            let vm = this;
            vm.isLoadingSchool = true;
            vm.$store.commit("SET_COMPANY_ID", vm.$route.params.id);
            const id = vm.$route.params.id;
            vm.$store
                .dispatch("GET_SCHOOL", {
                    id: id,
                })
                .then((response) => {
                    vm.isLoadingSchool = false;
                    vm.school = response.data;
                })
                .catch((error) => {
                    vm.isLoadingSchool = false;
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.page-body {
    height: calc(100% - 70px);
    border-radius: 4px;
    margin-top: 10px;
    display: flex;
    width: 100%;

    .page-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .page-container-header {
            margin-bottom: 10px;
            height: 45px;
            z-index: 9999;
        }

        .page-container-body {
            height: calc(100% - 45px);
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
}
</style>