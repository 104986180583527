import Vue from 'vue'
import {
    API,
    HEATMAP_GET_ALL_DATA_URL,
    HEATMAP_UPDATE_USER_URL,
    HEATMAP_UPDATE_USER_COMMENT_URL,
    HEATMAP_UPDATE_TEAM_URL,
    HEATMAP_UPDATE_TEAM_COMMENT_URL,
    HEATMAP_GET_PREV_DATA_URL,
    HEATMAP_GET_NEXT_DATA_URL,
    HEATMAP_UPDATE_DEPARTMENT_URL,
    HEATMAP_UPDATE_DEPARTMENT_COMMENT_URL

} from "../variable";

import axios from 'axios'
import {
    HEATMAP_GET_ALL_DATA,
    HEATMAP_UPDATE_USER,
    HEATMAP_UPDATE_USER_COMMENT,
    HEATMAP_UPDATE_TEAM,
    HEATMAP_UPDATE_TEAM_COMMENT,
    HEATMAP_GET_PREV_DATA,
    HEATMAP_GET_NEXT_DATA,
    HEATMAP_UPDATE_DEPARTMENT,
    HEATMAP_UPDATE_DEPARTMENT_COMMENT
} from "../Actions/heatmap.js";

const state = {

};

const actions = {
    [HEATMAP_UPDATE_DEPARTMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + HEATMAP_UPDATE_DEPARTMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [HEATMAP_UPDATE_DEPARTMENT_COMMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + HEATMAP_UPDATE_DEPARTMENT_COMMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [HEATMAP_GET_PREV_DATA]: ({ commit, dispatch, getters }, payload) => {
        if (!payload) {
            payload = {};
        }
        Vue.set(payload, "token", sessionStorage.siteAuthToken);
        return axios({
            url: API + payload.prefix + HEATMAP_GET_PREV_DATA_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [HEATMAP_GET_NEXT_DATA]: ({ commit, dispatch, getters }, payload) => {
        if (!payload) {
            payload = {};
        }
        Vue.set(payload, "token", sessionStorage.siteAuthToken);
        return axios({
            url: API + payload.prefix + HEATMAP_GET_NEXT_DATA_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [HEATMAP_UPDATE_TEAM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + HEATMAP_UPDATE_TEAM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [HEATMAP_UPDATE_TEAM_COMMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + HEATMAP_UPDATE_TEAM_COMMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [HEATMAP_UPDATE_USER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + HEATMAP_UPDATE_USER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [HEATMAP_UPDATE_USER_COMMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + HEATMAP_UPDATE_USER_COMMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [HEATMAP_GET_ALL_DATA]: ({ commit, dispatch, getters }, payload) => {
        if (!payload) {
            payload = {};
        }
        Vue.set(payload, "token", sessionStorage.siteAuthToken);
        return axios({
            url: payload.prefix != null ? API + "/" + payload.prefix + HEATMAP_GET_ALL_DATA_URL : API + getters.get_crm_prefix + HEATMAP_GET_ALL_DATA_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
};

const mutations = {};

const getters = {};

export default {
    state,
    getters,
    actions,
    mutations
};