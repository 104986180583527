<template>
  <select :type="type" :disabled="disabled" :value="value" class="widget_select" @change="onChange" @blur="onBlur"
    @focus="onFocus" @keyup="onKeyup" @keydown="onKeydown" @click="onClick">
    <option value="none">{{ placeholder }}</option>
    <option v-for="(item, index) in opt" :key="index" :value="item.value">
      {{ item.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: "widget_select",
  props: {
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "none",
    },
    placeholder: {
      type: String,
      default: "",
    },
    opt: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() { },
  methods: {
    onChange(e) {
      let vm = this;
      vm.$emit("input", e.target.value);
      vm.$emit("change", e);
    },
    onBlur(e) {
      let vm = this;
      vm.$emit("input", e.target.value);
      vm.$emit("blur", e);
    },
    onFocus(e) {
      let vm = this;
      vm.$emit("input", e.target.value);
      vm.$emit("focus", e);
    },
    onKeyup(e) {
      let vm = this;
      vm.$emit("input", e.target.value);
    },
    onKeydown(e) {
      let vm = this;
      vm.$emit("input", e.target.value);
    },
    onClick(e) {
      let vm = this;
      vm.$emit("click", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.widget_select {
  border-radius: 5px;
  font-weight: 500;
  width: 100%;
  height: 30px;
  position: relative;
  background: #fff;
  border: 1px solid #ddd;
  box-sizing: border-box;
  outline: none;
  padding-left: 3px;
  font-size: 11px;
}
</style>
