<template>
  <section class="app-editor-widget">
    <loading :height="15" color="#1ba5a4" :opacity="1" :width="15" :active.sync="is_loading" :is-full-page="false"
      :can-cancel="false" background-color="#ffffff69" class="loader"></loading>
    <div class="ritch-editor">
      <div id="toolbar">
        <div class="toolbar-font-widget">
          <select class="ql-font">
            <option selected>Font Style</option>
            <option value="serif">Serif</option>
            <option value="monospace">Monospace</option>
            <option value="Fredoka">Fredoka</option>
          </select>
          <span class="ql-format-group">
            <select title="Size" class="ql-size">
              <option selected>Font Size</option>
              <template v-for="(n, index) in 60">
                <option :key="index" v-if="n > 6" :value="`${n}px`">
                  {{ n }}
                </option>
              </template>
            </select>
          </span>
        </div>
        <div class="toolbar-primary-options">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
        </div>
        <div class="toolbar-secondary-options">
          <button class="ql-align" :value="null"></button>
          <button class="ql-align" value="right"></button>
          <button class="ql-align" value="center"></button>
          <button class="ql-align" value="justify"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </div>

        <!-- Link and Image-->
        <div class="toolbar-link-image">
          <button class="ql-link"></button>
          <button class="ql-image"></button>
        </div>
      </div>
      <div id="editor" class="editor"></div>

      <!-- Image upload input -->
      <input type="file" style="display: none" id="image-upload"
        accept="image/png, image/gif, image/jpeg, image/bmp, image/x-icon" @change="handleImageAdded" />
    </div>
  </section>
</template>

<script>
import { eventBus } from "@/main";
import Quill from "quill/dist/quill.min.js";
import "@/styles/quill.theme.css";
import PlainTextClipboard from "quill-plain-text-paste";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "@/libs/image-resize.min.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/align"), true);
Quill.register("modules/clipboard", PlainTextClipboard);
var Size = Quill.import("attributors/style/size");
var sizeWhitelist = [];
for (let i = 0; i < 60; i++) {
  if (i > 6) {
    sizeWhitelist.push(i + "px");
  }
}
Size.whitelist = sizeWhitelist;
Quill.register(Size, true);
export default {
  components: {},
  name: "veditor",
  props: {
    placeholder: {
      type: String,
      default: "Enter Description",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      is_loading: false,
      quill: null,
      content: null,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        let vm = this;
        if (vm.content == val) return;
        if (vm.quill) {
          vm.quill.root.innerHTML = vm.value;
          vm.$nextTick(() => {
            vm.quill.setSelection(val.length, 0, "api");
            vm.quill.focus();
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    let vm = this;
    vm.quill = new Quill(`#editor`, {
      modules: {
        toolbar: {
          container: "#toolbar",
          handlers: {
            image: vm.selectImage,
          },
        },
        imageDrop: true,
        imageResize: {
          context: Quill,
        },
      },
      placeholder: vm.placeholder,
      theme: "snow",
    });

    vm.quill.on("text-change", function (delta, oldDelta, source) {
      vm.content = vm.quill.root.innerHTML;
      vm.updateValue(vm.quill.root.innerHTML);
    });
    vm.quill.on("selection-change", function (range, oldRange, source) { });
    // Add initial content to the editor
    setTimeout(() => {
      vm.quill.root.innerHTML = vm.value;
    }, 100);

    eventBus.$on("addPropertyToEditor", (property) => {
      // Check if there is selected text and if there is, replace with property if not insert it where the cursor is
      if (vm.quill.getSelection()) {
        // delete the selected text
        vm.quill.deleteText(vm.quill.getSelection().index, vm.quill.getSelection().length);
        vm.quill.insertText(vm.quill.getSelection().index, property);
      } else {
        vm.quill.insertText(vm.quill.getLength(), property);
      }
    });
  },
  methods: {
    updateValue(value) {
      let vm = this;
      vm.$emit("input", value);
    },
    selectImage() {
      let vm = this;
      document.getElementById("image-upload").click();
    },
    handleImageAdded({ target }) {
      let vm = this;
      let file = target.files[0];
      vm.is_loading = true;
      var formData = new FormData();
      const file_name = Date.now() + file.name;
      formData.append("files[]", file, file_name);
      vm.$store
        .dispatch("UPLOAD_MILTIPLE_FILE", formData)
        .then((result) => {
          vm.is_loading = false;
          const url = vm.$fullUrl("images/") + file_name;
          vm.quill.insertEmbed(vm.quill.getSelection().index, "image", url);
          // reset input
          target.value = "";
        })
        .catch((err) => {
          vm.is_loading = false;
          vm.$notify({
            group: "status",
            title: "Warning",
            text: "Something wrong while uploading",
            type: "warn",
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style  lang="scss">
.app-editor-widget {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  padding: 5px;

  .ql-editor {
    padding: 10px 0px;
    min-height: 150px;
  }

  .ql-toolbar {
    border-radius: 8px;
    background: #fff;
    padding-left: 0px !important;
    position: sticky !important;
    top: 0 !important;
    border: 1px solid #e0e0e0 !important;
    z-index: 99;
  }

  .ql-container.ql-snow {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fff;
    border: none !important;
    padding: 0px 0px !important;

    .ql-editor.ql-blank::before {
      font-style: normal;
      font-size: 14px;
      font-family: "Nunito", sans-serif;
      padding-left: 0px !important;
      left: 0px !important;
    }
  }

  &.second-row-options {
    .ql-editor {
      margin-top: 50px;
    }
  }
}
</style>
