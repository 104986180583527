<template>
  <div class="feed-posts-wrapper">
    <!-- List of post -->
    <div id="posts" v-if="!showFeedLoading &&
      feedsData &&
      feedsData.data.length > 0
      ">
      <div class="__post-item" v-for="(post, i) in feedsData.data" :key="i">
        <div class="__header">
          <div class="group">
            <widgetProfile :title="`${post.user.first_name} ${post.user.last_name}`" subtitle="" :show_name="false"
              :file_name="post.user.profile_picture" :path="$fullUrl('images/profiles/')">
            </widgetProfile>
            <div class="__time">
              <label class="__green" v-if="post.user">{{ post.user.first_name | validate }}
                {{ post.user.last_name | validate }}</label>
              <span v-if="!post.sponsored">{{
                post.created_at | best
              }}</span>
              <span v-else>Today</span>
            </div>
          </div>
          <div class="options">
            <!-- Dropdown for customer  -->
            <dropdown-menu>
              <ul>
                <li v-if="false">
                  <a href @click.prevent="editPost(post)">Edit</a>
                </li>
                <li>
                  <app-dialog title="Delete Post" ask="Are you sure you want to permanently delete this post?"
                    yesText="Delete" noText="Cancel" yesBtnClass="btn-danger" @onComfirm="deletePost(post.id, i)">
                    <a href="#">Delete</a>
                  </app-dialog>
                </li>
              </ul>
            </dropdown-menu>
            <!-- End of dropdown -->

            <div class="items" v-show="post.showOption">
              <span>Delete</span>
            </div>
          </div>
        </div>
        <div class="__paragraph">
          <div class="feed-content" v-html="post.feed" v-linkified></div>
          <img v-if="post.image" :src="$fullUrl('images/feeds/' + post.image)" />
          <video v-if="post.video" controls>
            <source :src="$fullUrl('videos/feeds/' + post.video)" />
            Your browser is out of date.
          </video>
        </div>
        <div class="__actions">
          <div class="__likes">
            <div class="likes-widget" @click="showLikes(post.likes)">
              <div class="likes-profile" v-for="(like, index) in post.likes" :key="index" v-if="index < 5 && like.user">
                <img class="profile-image" :class="{
                  many: index > 0 && post.likes.length > 1,
                }" v-if="like.user.profile_picture" :src="$fullUrl(
  'images/profiles/' + like.user.profile_picture
)
  " />
                <img class="profile-image" :class="{
                  many: index > 0 && post.likes.length > 1,
                }" v-else src="@/assets/avatar.png" />
                <span class="names" v-if="post.likes.length < 1">
                  {{ like.user.first_name | validate }}
                  {{ like.user.last_name | validate }}</span>
              </div>
            </div>
            <span class="likes-left-count" v-if="post.likes.length > 4">
              and
              <span @click="showLikes(post.likes)" class="link">{{ post.likes.length - 4 }}
                {{
                  post.likes.length - 4 > 1 ? "others" : "other"
                }}</span>
            </span>
          </div>
        </div>

        <div class="__comments" v-if="post.comments &&
          post.comments.length > 0
          " id="comment-parent">
          <div class="__coment" v-for="(c, i) in post.comments" v-if="c.user" :key="i">
            <img v-if="c.user && c.user.profile_picture" :src="$fullUrl('images/profiles/' + c.user.profile_picture)
              " @click="gotoProfile(c.user, 'people')" />
            <img v-else src="@/assets/avatar.png" @click="gotoProfile(c.user, 'people')" />
            <div class="__text">
              <h6 class="__green">
                {{ c.user.first_name | validate }}
                {{ c.user.last_name | validate }}
                <span>{{
                  c.created_at
                  | moment("add", "2 hours")
                  | moment("from")
                }}</span>
              </h6>
              <p>{{ c.comment | validate }}</p>
              <div class="__actions">
                <span class="__green" @click="commentLike(c)">
                  <span class="__green">Like</span>
                  <span class="__green" v-show="c.likes.length">{{
                    c.likes.length | validate
                  }}</span>
                </span>
                <!--<span class="__green">.</span>-->
                <!--<span class="__green">Reply</span>-->
              </div>
              <div class="comment-dropdown" v-if="userId === c.user.id">
                <button @click="removeComment(c.id, i, post.comments)">
                  <img src="@/assets/icons/red_delete.svg" alt />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="__comments-wrapper" v-if="post.showComment">
          <div class="__comment__post-box">
            <img v-if="getMeProfile.user && getMeProfile.user.profile_picture
              " :src="$fullUrl(
    'images/profiles/' +
    getMeProfile.user.profile_picture
  )
    " />
            <img v-else src="@/assets/avatar.png" alt />
            <textarea placeholder="Leave a Comment" v-model="post.text"
              @keyup.enter="saveComment(post.text, i, post)"></textarea>
            <input type="file" style="display: none" ref="image2" accept="image/*" @change="onImagePicked2" />
            <input type="file" style="display: none" ref="video2" accept="video/*" @change="onVideoPicked2" />
          </div>
        </div>
      </div>
    </div>
    <div id="posts" v-if="!showFeedLoading &&
      feedsData &&
      feedsData.data.length == 0
      ">
      <div class="not-found" style="padding: 50px 0px">
        <widgetNotFoundWidget title="Feed is currently unavailable, please check back for updates.">
          <img class="widget-img" src="@/assets/icons/member-notfound.svg" />
        </widgetNotFoundWidget>
      </div>
    </div>
    <div v-if="showFeedLoading" class="feed-loading-widget">
      <loading :height="15" color="#1ba5a4" :opacity="1" :width="15" :active.sync="showFeedLoading" :is-full-page="false"
        :can-cancel="false" background-color="#fff" class="loader"></loading>
    </div>
    <!-- End of list of post -->
  </div>
</template>

<script>
import { eventBus } from "@/main";
export default {
  name: "Feeds",
  components: {
  },
  data() {
    return {
      schools_opt: [],
      show_share_modal: false,
      share_info: {
        url: "",
        title: "Hello there",
        description: "This is a description",
      },
      internship_data: [],
      showLoading: false,
      body_height: "calc(100% - 70px)",
      header_height: "70px",
      sticky: false,
      isFetching: false,
      reRender: true,
      showReactions: false,
      likes: [],
      availability_value: 0,
      colorCache: {},
      isEmpty: false,
      options: {
        dotSize: 14,
        width: "50%",
        height: 5,
        railStyle: {
          backgroundColor: "#22B573",
        },
        processStyle: {
          backgroundColor: "#1995A8",
        },
      },
      chatCollapsed: false,
      postData: {
        thumbnail: false,
        thumbnail2: false,
        imageFile: null,
        imageName: "",
        imageUrl: "",
        videoFile: null,
        text: "",
        tags: [],
        user_id: null,
      },
      showPostEdit: false,
      editPostData: {
        thumbnail: false,
        thumbnail2: false,
        imageFile: null,
        imageName: "",
        imageUrl: "",
        videoFile: null,
        text: "",
        tags: [],
        user_id: null,
      },
      Comment: {
        imageFile: null,
        imageName: "",
        imageUrl: "",
        videoFile: null,
        text: "",
        user_id: null,
      },
      imageOptions: {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: false,
      },
      chatList: [],
      chatItems: [],
      feedsData: { data: [] },
      chats: [],
      searchchat: "",
      comment: { text: "" },
      showFeedLoading: false,
    };
  },
  computed: {
  },
  watch: {

  },
  mounted() {
    let vm = this;
    vm.getFeeds();
    // vm.$nextTick(function () {
    //   const listElm = document.getElementById("posts");
    //   listElm.addEventListener("scroll", vm.handleScrolled);
    // });
  },
  methods: {
    onFeedScoll({ target }) {
      let vm = this;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100) {
        vm.getMoreFeeds();
      }
    },
    editPost(post) { },
    deletePost(id, i) {
      let vm = this;
      vm.$store.commit("SET_COMPANY_ID", parseInt(vm.$route.params.id));
      vm.$store
        .dispatch("DELETE_FEED_POST", { id: id })
        .then((response) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Post deleted successfully",
            type: "success",
            duration: 3000,
          });
          vm.feedsData.data.splice(i, 1);
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Failed to delete post",
            type: "error",
            duration: 3000,
          });
        });
    },
    getFeeds() {
      let vm = this;
      vm.showFeedLoading = true;
      vm.$store.commit("SET_COMPANY_ID", parseInt(vm.$route.params.id));
      vm.$store
        .dispatch("GET_FEEDS_POSTS", {
          school_id: parseInt(vm.$route.params.id),
        })
        .then((response) => {
          vm.showFeedLoading = false;
          vm.feedsData = response.data;
          vm.feedsData.data = response.data.data
            .map(function (item) {
              if (item.user) {
                if (item.likes.length > 0) {
                  let likes = item.likes.map((like) => {
                    if (like.user) {
                      return like.user.id;
                    }
                  });
                  eventBus.$set(item, "liked", likes.indexOf(vm.userId) > -1);
                } else eventBus.$set(item, "liked", false);
                item.comments.map((comment) => {
                  if (comment.user) {
                    return comment;
                  }
                });
                if (item.user === null) eventBus.$set(item, "user", {});
                else eventBus.$set(item, "user", item.user);

                eventBus.$set(item, "showComment", false);
                eventBus.$set(item, "showOption", false);
                eventBus.$set(item, "text", "");
                return item;
              }
            })
            .filter((item) => {
              return item;
            });
        })
        .catch((error) => {
          vm.showFeedLoading = false;
        });

    },
    getMoreFeeds() {
      let vm = this;
      vm.showFeedLoading = true;
      axios({
        url: this.feedsData.next_page_url,
        method: "GET",
        headers: { Authorization: "Bearer " + sessionStorage.authToken },
      })
        .then((response) => {
          vm.showFeedLoading = false;
          let data = response.data.data
            .map(function (item) {
              if (item.user) {
                if (item.likes.length > 0) {
                  let likes = item.likes.map((like) => {
                    if (like.user) {
                      return like.user.id;
                    }
                  });
                  eventBus.$set(item, "liked", likes.indexOf(vm.userId) > -1);
                } else eventBus.$set(item, "liked", false);

                item.comments.map((comment) => {
                  if (comment.user) {
                    return comment;
                  }
                });

                eventBus.$set(item, "showComment", false);
                eventBus.$set(item, "showOption", false);
                eventBus.$set(item, "text", "");
                return item;
              }
            })
            .filter((item) => {
              return item;
            });
          data.forEach((item) => {
            if (item.user) {
              vm.feedsData.data.push(item);
            }
          });
          vm.feedsData.next_page_url = response.data.next_page_url;
        })
        .catch((error) => {
          vm.showFeedLoading = false;
        });
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.feed-posts-wrapper {
  .__post-item {
    margin-top: 10px;
    background: #fff;
    position: relative;
    padding: 10px 0 0 0;
    border-radius: 5px;

    @media (min-width: 300px) and (max-width: 630px) {
      border-radius: 0px;
    }

    .__header {
      display: flex;
      padding: 0 10px 10px 10px;
      /*border-bottom: 2px solid #F5F5F5;*/
      flex-direction: row;
      justify-content: space-between;

      .group {
        display: flex;
        padding: 0;
        align-items: center;

        img {
          width: 43px;
          height: 43px;
          border-radius: 100%;
          object-fit: cover;
          cursor: pointer;
        }

        .__time {
          display: flex;
          margin-left: 10px;
          margin-top: -5px;
          flex-direction: column;

          span {
            color: #b3b3b3;
            font-weight: normal;
            font-size: 14px;
          }

          label {
            color: #4e4e4e;
            margin-top: 5px;
            font-weight: normal;
            font-size: 14px;
          }
        }
      }

      .options {
        position: relative;

        img {
          cursor: pointer;
          transform: rotate(90deg);
        }

        .items {
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
          background: #fff;
          position: absolute;
          right: 0;
          top: 25px;
          padding: 10px 20px;

          span {
            cursor: pointer;

            &:hover {
              color: #04a5a5;
            }
          }
        }
      }
    }

    .__paragraph {
      max-width: 100%;
      font-size: 14px;
      color: #4e4e4e;
      padding: 10px;
      line-height: 20px;
      font-weight: normal;
      text-align: left;

      img,
      video {
        width: 100% !important;
        max-height: 400px;
        border-radius: 10px;
        object-fit: cover;
      }

      .feed-content {
        white-space: pre-wrap;
      }
    }

    .__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 10px;
      height: 40px;
      border-top: 2px solid #f5f5f5;
      border-bottom: 2px solid #f5f5f5;
      margin-bottom: 10px;

      .__likes {
        display: flex;
        cursor: pointer;
        align-items: center;

        .btn-like {
          background: #f3f3f3;
          border-radius: 5px;
          width: 23px;
          height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease-in-out;
          transform: scale(1);

          &:hover {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
            transition: all 0.3s ease-in-out;
            transform: scale(1.1);
          }

          img.like-img {
            width: 15px !important;
            height: 15px !important;
          }
        }

        .likes-widget {
          display: flex;
          margin-left: 8px;

          .likes-profile {
            display: flex;
            align-items: center;

            img.profile-image {
              height: 19px;
              width: 19px;
              border-radius: 50%;
              border: 2px solid #fff;

              &.many {
                margin-left: -8px;
              }
            }

            span.names {
              background: #1ba5a4;
              border-radius: 9px;
              display: flex;
              align-items: center;
              color: #fff;
              font-size: 11px;
              padding: 2px 10px;
              margin-left: 3px;
            }
          }
        }

        span.likes-left-count {
          color: #999999;
          margin-left: 5px;
          font-size: 12px;

          span.link {
            color: #0884ad;
            cursor: pointer;
          }
        }
      }

      ._comments {
        display: flex;
        cursor: pointer;
        align-items: center;

        .btn-comments {
          margin-right: 3px;
          background: #f3f3f3;
          border-radius: 5px;
          width: 23px;
          height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease-in-out;
          transform: scale(1);

          &:hover {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
            transition: all 0.3s ease-in-out;
            transform: scale(1.1);
          }

          &.active {
            background: #0884af;
          }

          img.comment {
            width: 15px !important;
            height: 15px !important;
          }
        }

        span {
          position: relative;
          top: 1px;
          font-size: 12px;
        }
      }
    }

    .show-comments {
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      background: #efefef;
      padding: 8px;

      span {
        color: #b3b3b3;
      }
    }

    .__comments {
      max-height: 320px;
      padding: 0 20px;
      overflow: auto;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      /* scrollbar itself */
      &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid #f2f2f2;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }

      .__coment {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        width: 100%;
        position: relative;

        .comment-dropdown {
          position: absolute;
          right: 0px;
          padding: 0px 9px;

          button {
            background: transparent;
            border: none;
            outline: none;

            img {
              width: 10px;
              height: 10px;
            }
          }
        }

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
        }

        .__text {
          display: flex;
          margin-left: 10px;
          padding: 12px 10px;
          border-radius: 5px;
          background: #efefef;
          width: 100%;
          flex-direction: column;

          h6 {
            font-weight: normal;
            margin-bottom: 0;
            font-size: 14px;
          }

          p {
            font-weight: normal;
            margin-bottom: 0;
            font-size: 14px;
          }

          h6 {
            margin-bottom: 2px;
            font-size: 14px;
            line-height: 10px;
            font-weight: normal;
            color: #000000;

            span {
              color: #b3b3b3;
              cursor: pointer;
              margin-left: 10px;
              font-size: 11px;
            }
          }

          p {
            color: #535151;
            margin-bottom: 10px;
            margin-top: 6px;
          }

          .__actions {
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 0;
            border: none;
            justify-content: space-between;
            width: 30%;

            span {
              color: #999999;
              cursor: pointer;
              font-size: 12px;
            }

            .__green {
              color: #0884af;
            }
          }
        }
      }
    }

    .__comments-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 10px;

      .__comment__post-box {
        padding: 6px 20px;
        display: flex;

        @media (min-width: 300px) and (max-width: 630px) {
          border-radius: 0px;
        }

        img {
          width: 30px;
          border-radius: 100%;
          height: 30px;
          margin-right: 10px;
          object-fit: cover;
        }

        textarea {
          width: 100%;
          outline: none;
          font-size: 13px;
          background: none;
          height: 35px;
          color: rgb(99, 99, 99);
          overflow-wrap: break-word;
          padding: 6px 10px;
          transition: 400ms ease-in;
          resize: none;
          border: 1px solid #d5d5d5;
          border-radius: 5px;

          @media (min-width: 300px) and (max-width: 630px) {
            padding: 6px 10px;
          }
        }

        textarea:focus,
        textarea:hover {
          height: 100px;
          transition: 400ms ease-in;
          background: #fff;
        }
      }
    }

    .__reactions {
      display: flex;
      width: 100%;
      margin-top: 10px;
      padding: 10px 20px;
      flex-direction: row;

      .btn-post {
        width: 40px;
        height: 40px;
        margin-top: -5px;
        background: #04a5a5;
        justify-content: center;
        display: flex;
        border-radius: 100%;
        cursor: pointer;
      }

      .__actions__ {
        display: flex;
        margin-right: 20px;

        .__icon {
          width: 20px;
          height: 20px;
          margin-right: 40%;

          img {
            width: 20px;
            cursor: pointer;
            height: 20px;
          }
        }
      }
    }
  }

  .loader {
    height: 80px;
    margin-top: 10px;
    background: #fff;
    position: relative;
    padding: 10px 0 0 0;
    border-radius: 5px;

    svg {
      width: 70px !important;
    }
  }

  .feed-loading-widget {
    border-radius: 10px;
    background: #fff;
  }
}
</style>