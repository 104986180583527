<template>
  <div class="school-notification-wrapper">
    <button v-if="false" class="btn-notification" @click="toggleNotifiationModal()">
      <img src="@/assets/admin-icon/notifiation-icon-grey.svg" />
      <img v-if="notification_status > 0" class="new-notification-icon" src="@/assets/admin-icon/ellipse-icon-red.svg" />
    </button>
  </div>
</template>

<script>
export default {
  name: "school-notification",
  components: {},
  data() {
    return {
      notification_status: false,
    };
  },
  watch: {},
  mounted() {
    let vm = this;
    vm.getNotificationStatus();
  },
  methods: {
    getNotificationStatus() {
      let vm = this;
      vm.$store
        .dispatch("INSTRUCTOR_GET_NOTIFICATION_STATUS")
        .then((responce) => {
          vm.notification_status = responce.data.notifications;
        })
        .catch((error) => { });
    },
    toggleNotifiationModal() {
      let vm = this;
      // vm.$router.push({
      //   name: "instructor",
      //   query: { sub_menu: "notifications" },
      // });
    },
  },
  computed: {},
  created() { },
};
</script>
<style lang="scss" scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.school-notification-wrapper {
  position: relative;

  .btn-notification {
    position: relative;

    .new-notification-icon {
      position: absolute;
      right: 7px;
      top: 0px;
      animation: fadeIn 500ms infinite;
    }
  }
}
</style>
