<template>
  <button @click="onClick" class="widget-asc-desc">
    <img v-if="value == 'asc'" src="@/assets/icon/asc.svg" />
    <img v-else src="@/assets/icon/desc.svg" />
  </button>
</template>

<script>
export default {
  name: "widget_button",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {

    };
  },
  watch: {},
  mounted() {
    let vm = this;
  },
  methods: {
    onClick() {
      let vm = this;
      if (vm.value == "asc") {
        vm.$emit("click", "desc");
        vm.$emit("input", "desc");
      } else {
        vm.$emit("click", "asc");
        vm.$emit("input", "asc");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.widget-asc-desc {
  background: transparent;
  border: none;
  font-style: bold !important;
  font-weight: 500;
  width: min-content;
  position: relative;
  cursor: pointer;
}
</style>
