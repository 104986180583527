import Vue from 'vue'
import Vuex from 'vuex'
import auth from './Module/auth'
import feed from './Module/feed'
import loader from './Module/loader'
import user from './Module/user'
import message from './Module/message'
import company from './Module/company'
import landing from './Module/landing'
import search from './Module/search'
import customer from './Module/customer'
import skills from './Module/skills'
import ai from './Module/ai'
import accounts from "./Module/accounts.js";
import leads from "./Module/leads.js";
import candidates from "./Module/candidates.js";
import task from "./Module/task.js";
import heatmap from "./Module/heatmap.js";
import report from "./Module/report.js";
import process_messages from "./Module/process_messages.js";
import instructor from "./Module/instructor.js";
import internship from "./Module/internship.js";
import Cvs from "./Module/cvs.js";
import Communication from "./Module/communication.js";
import { SITE_API_, API_ } from "./app_url.js";


Vue.use(Vuex)
const debug = process.env.NODE_ENV !== "production";


export default new Vuex.Store({
    state: {
        side_bar: false,
        crm_prefix: '/admin',
        wizard_active_menu: "",
        path: [],
        dialog_class: "",
        navigationHistory: [],
        removedNavigationPath: null,
        isNavigationBackDirection: false,
        SITE_API: SITE_API_,
        API: API_,
        isDownloadingCV: false,
        canCVSaveHistory: true,
        company_id: 0,
    },
    mutations: {
        ['SET_WIZARD_ACTIVE_MENU']: (state, payload) => {
            state.wizard_active_menu = payload;
        },
        ['SET_CRM_PREFIX']: (state, payload) => {
            if (Vue.localStorage.get("role")) {
                state.crm_prefix = "/" + Vue.localStorage.get("role");
            } else {
                state.crm_prefix = payload.prefix
            }
        },
        ['SET_PATH']: (state, payload) => {
            state.path = [];
            state.path = payload
        },
        ['SET_DIALOG_CLASS']: (state, payload) => {
            state.dialog_class = payload
        },
        ['SET_NAVIGATION_HISTORY']: (state, payload) => {
            state.navigationHistory.push(payload)
        },
        ['REMOVE_NAVIGATION_HISTORY']: (state, payload) => {
            state.removedNavigationPath = state.navigationHistory.pop()
        },
        ['SET_NAVIGATION_DIRECTION']: (state, payload) => {
            state.isNavigationBackDirection = payload
        },
        ['SET_DOWNLOADING_CV']: (state, payload) => {
            state.isDownloadingCV = payload
        },
        ['SET_CV_SAVE_HISTORY']: (state, payload) => {
            state.canCVSaveHistory = payload
        },
        ['SET_COMPANY_ID']: (state, payload) => {
            state.company_id = payload
        }
    },
    actions: {
        saveNavigationHistory: ({ commit }, payload) => {
            commit('SET_NAVIGATION_HISTORY', payload)
        },
        removeNavigationHistory: ({ state, commit }, payload) => {
            commit('REMOVE_NAVIGATION_HISTORY', payload)
            return state.removedNavigationPath;
        }
    },
    getters: {
        getNavigationHistory: state => {
            return state.navigationHistory
        },
        get_crm_prefix(state) {
            if (Vue.localStorage.get("role")) {
                return "/" + Vue.localStorage.get("role");
            }
            return state.crm_prefix;
        },
        get_path(state) {
            return state.path;
        },
        get_dialog_class(state) {
            return state.dialog_class;
        }

    },
    modules: {
        auth,
        feed,
        loader,
        user,
        message,
        company,
        landing,
        search,
        customer,
        skills,
        ai,
        accounts,
        leads,
        candidates,
        task,
        heatmap,
        report,
        process_messages,
        instructor,
        internship,
        Cvs,
        Communication,
    },
    strict: debug

});