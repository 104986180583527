<template>
  <div class="page-wrapper">
    <div class="page-header">
      <WidgetToolbar :back="backEnabled" @onBack="onBackFromCloseDate()" title="Leads"
        :icon="require('@/assets/admin-icon/menu/leads_active.svg')">
        <div class="options">
          <widgetSearch placeholder="Search here..." icon="right" @keyup="searchLeads">
          </widgetSearch>
          <div class="h-spacer"></div>
          <widgetDropdown v-model="show_display_filter" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              Display
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in display_filter" :key="index" @click="
                filterByDisplay(item.value),
                (show_display_filter = false),
                changeDisplay(item)
                ">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>

          <div class="v-line-divider" style="height: 40px"></div>
          <widgetDropdown v-model="show_sort_filter" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              <img src="@/assets/admin-icon/filter.svg" />
              Filter
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in sort_filter" :key="index" @click="
                (sort_filter_data = item.value), (show_sort_filter = false)
                ">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>
          <div class="v-line-divider" style="height: 40px"></div>
          <widgetDropdown v-model="show_data_filter" :right="true" :hover="false" :interactive="true">
            <button class="btn-dropdown">
              {{ filter_data == "mine" ? "My Leads" : "All Leads" }}
              <img class="icon" src="@/assets/icon/carret-bottom-gray.svg" />
            </button>
            <div slot="dropdown" class="dropdown-widget right">
              <a class="dropdown-item" href="#" v-for="(item, index) in data_filter" :key="index"
                @click="(filter_data = item.value), (show_data_filter = false)">
                {{ item.name }}</a>
            </div>
          </widgetDropdown>

          <div class="h-spacer"></div>
          <widgetButton :loading="false" @click="addSectionNew()" class="btn-secondary">
            Add Section +
          </widgetButton>
        </div>
      </WidgetToolbar>
    </div>

    <div v-if="columns.length > 0" class="page-body">
      <div class="wrapper-section" :style="{ minWidth: storyWindowWidth }">
        <div class="column" :class="{ relaxed: view_type == 'relaxed' }" v-for="(item, index) in columns" :key="index">
          <div class="container">
            <div class="header">
              <div class="row-1">
                <div class="title" @click="editSection(item)">
                  {{ item.name | validate }}
                </div>

                <div class="options row" v-if="sort_filter_data != 'close_date'">
                  <help-tool-tip position="bottom right" :text="item.description" backgroundColor="#FFFFFF"
                    textColor="#8E8B8B" width="200px" />
                  <button v-if="temp_view_type != 'closedate' ||
                    temp_view_type == 'normal'
                  " @click="addNewStory(item)" class="btn-add">
                    +
                  </button>
                </div>
              </div>
              <div class="row-2">
                <hr />
              </div>
            </div>
            <div class="body" @dragover.self="columnDradOver($event, index)"
              @dragenter.self="columnDradEnter($event, index)" @dragleave.self="columnDradLeave($event, index)"
              @drop.self="columnDradDrop($event)">
              <div v-for="(lead, i) in item.leads" :key="i" class="card" draggable="true"
                @dragstart="cardDradStart($event, lead, index, i)" @dragend="cardDragEnd"
                @dragover="cardDradOver($event, index)" @dragenter="cardDradEnter($event, index, i)"
                @dragleave="cardDradLeave($event, index)" @drop="cardDradDrop($event)">
                <div class="card-header">
                  <input class="card-checkbox" type="checkbox" />
                </div>
                <div class="title-widget" @click="viewStory(lead)">
                  <span class="lead-name">{{ lead.name | validate }}</span>
                </div>
                <div class="compang-widget" @click="viewStory(lead)" v-if="lead.account">
                  <span class="company-name">
                    {{ lead.account.name | validate }}
                  </span>

                  <div class="company-contact" @click="viewStory(lead)"
                    v-if="lead.contacts && lead.contacts.length > 0">
                    <template v-for="(contact, index) in lead.contacts">
                      <span :key="index" v-if="index == 0">
                        {{ contact.first_name | validate }}
                        {{ contact.last_name | validate }}
                      </span>
                    </template>
                    <div v-if="lead.contacts.length > 1" class="other-contact">
                      , & {{ (lead.contacts.length - 1) | validate }} more
                      contacts
                    </div>
                  </div>
                </div>

                <div class="members">
                  <div class="member" v-for="(member, index) in lead.assignees" :key="index">
                    <assingProfileToolTip :member="member">
                      <img v-if="member.profile_picture" :src="$fullUrl(
                        'images/profiles/' + member.profile_picture
                      )
                        " :class="{ override: index > 0 }" />
                      <img v-else :class="{ override: index > 0 }" src="@/assets/avatar.png" />
                    </assingProfileToolTip>
                    <span v-if="lead.assignees.length == 1" class="name">{{
                      (member.first_name + " " + member.last_name) | validate
                      }}</span>
                  </div>
                </div>
                <div class="price" v-if="false">
                  {{ lead.value | validate }} USD
                </div>

                <div class="card-footer">
                  <div class="resume-widget">
                    <button v-if="lead.attachment" @click="downloadAttachment(lead.attachment)"
                      class="btn-download-resume">
                      <img class="icon" src="@/assets/icons/attach-file.svg" />
                      Attachment
                    </button>
                    <div v-else class="no-resume">No Attachment</div>
                  </div>
                  <div class="card-date">
                    {{ moment(lead.create_at).format("MMM DD, yyyy") }}
                  </div>
                </div>

              </div>
              <div @dragover="cardDradOver($event, index)" @dragleave="cardDradLeave($event, index)"
                @drop="cardDradDrop($event)" v-if="item.on_drag_active" class="drag-holder card">
                Drag card here
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Not found -->
    <div v-else class="page-body">
      <div class="not-found">
        <widgetNotFoundWidget title="Looks like you haven’t added any section yet"
          :content="'Add a section to start adding Leads'" :btntext="'Add Section'" @click="addSectionNew()">
          <img class="widget-img" src="@/assets/admin-icon/add-student-icon.svg" />
        </widgetNotFoundWidget>
      </div>
    </div>

    <!-- Add Section modal -->
    <addLeadSectionModal v-if="show_section_form" :value="selected_section" :team_id="null" @saved="onSectionSaved"
      @close="show_section_form = false" @delete="deleteSection"></addLeadSectionModal>

    <!-- Add Story Modal -->
    <addLeadStoryModal v-if="show_form && selected_section" :column_id="selected_section.id" :value="story_item"
      @saved="onStorySaved" @close="onCloseStory()" @delete="deleteStory"></addLeadStoryModal>
  </div>
</template>
<script>
import { eventBus } from "@/main";
import addLeadSectionModal from "./components/addLeadSectionModal.vue";
import addLeadStoryModal from "./components/addLeadStoryModal.vue";
export default {
  components: {
    addLeadSectionModal,
    addLeadStoryModal,
  },
  data: () => ({
    backEnabled: false,
    sort_filter_data: "",
    teams_filter_data: "",
    division_filter_data: "",
    show_form: false,
    columns: [],
    item_hold: null,
    active_column_index: null,
    active_card_index: null,
    inactive_column_index: null,
    inactive_card_index: null,
    show_display_filter: false,
    show_tags_filter: false,
    show_sort_filter: false,
    show_data_filter: false,
    show_section_form: false,
    show_story_form: false,
    story_item: null,
    view_type: "compact",
    temp_view_type: "",
    display_filter: [
      {
        value: "relaxed",
        name: "Relaxed",
      },
      {
        value: "compact",
        name: "Compact",
      },
      // {
      //   value: "closedate",
      //   name: "Close date",
      // },
      // {
      //   value: "normal",
      //   name: "Normal",
      // },
    ],
    sort_filter: [
      {
        value: "create_date",
        name: "Create Date",
      },
      {
        value: "close_date",
        name: "Close Date",
      },
      {
        value: "name",
        name: "Name",
      },
    ],
    data_filter: [
      {
        value: "mine",
        name: "My Leads",
      },
      {
        value: "all",
        name: "All Leads",
      },
    ],
    filter_data: "all",
    team_filter: [],
    division_filter: [],
    tag_filter_data: "",
    tags_filter: [],
    filter_close_date_data: "",
    selected_section: null,
    storyWindowWidth: "0px",
  }),
  mounted() {
    let vm = this;
    vm.getLeads(vm.filter_data);
  },
  methods: {
    downloadAttachment(attachment) {
      const url = vm.$fullUrl("images/") + attachment;
      window.open(url, "_blank");
    },
    onBackFromCloseDate() {
      let vm = this;
      vm.backEnabled = false;
      vm.getLeads(vm.filter_data);
    },
    changeDisplay(item) {
      let vm = this;
      vm.view_type = item.value;
      vm.show_display_filter = false;
      if (vm.view_type == "relaxed") {
        vm.storyWindowWidth = vm.columns.length * 255 + "px";
      } else {
        vm.storyWindowWidth = vm.columns.length * 205 + "px";
      }
    },
    onCloseStory() {
      let vm = this;
      vm.show_form = false;
      vm.getLeads(vm.filter_data);
    },
    filterByDisplay(value) {
      let vm = this;
      if (value == "relaxed" || value == "compact") {
        vm.view_type = value;
      } else if (value == "normal") {
        vm.temp_view_type = value;
        vm.getLeads(vm.filter_data);
      } else {
        vm.temp_view_type = value;
        vm.$store.dispatch("LOADER_START");
        vm.$store
          .dispatch("LEADS_FILTER_BY_DISPLAY", {
            display: value,
          })
          .then((response) => {
            vm.$store.dispatch("LOADER_CLOSE");
            vm.columns = response.data.map((item) => {
              eventBus.$set(item, "on_drag_active", false);
              return item;
            });
            vm.changeDisplay({ value: vm.view_type });
          })
          .catch((error) => {
            vm.$store.dispatch("LOADER_CLOSE");
          });
      }
    },
    filterByCloseDate(val) {
      let vm = this;
      vm.$store.dispatch("LOADER_START");
      vm.$store
        .dispatch("LEADS_FILTER_BY_CLOSE_DATE", {
          date: vm.moment(val).format("YYYY-MM-DD"),
          type: vm.filter_data
        })
        .then((response) => {
          vm.$store.dispatch("LOADER_CLOSE");
          vm.columns = response.data.map((item) => {
            eventBus.$set(item, "on_drag_active", false);
            return item;
          });
          vm.changeDisplay({ value: vm.view_type });
        })
        .catch((error) => {
          vm.$store.dispatch("LOADER_CLOSE");
        });
    },
    filterByTag(val) {
      let vm = this;
      vm.show_tags_filter = false;
      if (val.length > 0) {
        vm.$store
          .dispatch("LEADS_FILTER_BY_TAG", {
            tag: val,
            type: vm.filter_data
          })
          .then((response) => {
            vm.columns = response.data.map((item) => {
              eventBus.$set(item, "on_drag_active", false);
              return item;
            });
            vm.changeDisplay({ value: vm.view_type });
          })
          .catch((error) => { });
      } else {
        vm.getLeads(vm.filter_data);
      }
    },
    getLeadsTags() {
      let vm = this;
      vm.$store
        .dispatch("LEADS_GET_TAGS")
        .then((response) => {
          vm.tags_filter = response.data.map((item) => {
            return {
              value: item.name,
              name: item.name,
            };
          });
        })
        .catch((error) => { });
    },
    searchLeads({ target }) {
      let vm = this;
      if (target.value) {
        vm.$store
          .dispatch("LEADS_SEARCH", {
            query: target.value,
            team_id: null,
            type: vm.filter_data
          })
          .then((response) => {
            vm.columns = response.data.map((item) => {
              eventBus.$set(item, "on_drag_active", false);
              return item;
            });
            vm.changeDisplay({ value: vm.view_type });
          })
          .catch((error) => { });
      } else {
        vm.getLeads(vm.filter_data);
      }
    },
    filterBySort(val) {
      let vm = this;
      vm.$store
        .dispatch("LEADS_FILTER_BY_SORT", {
          option: val,
          team_id: null,
          type: vm.filter_data

        })
        .then((response) => {
          if (val == 'close_date') {
            vm.backEnabled = true;
          }
          vm.columns = response.data.map((item) => {
            eventBus.$set(item, "on_drag_active", false);
            return item;
          });
          vm.changeDisplay({ value: vm.view_type });
        })
        .catch((error) => { });
    },
    filterByTeam(val) {
      let vm = this;
      vm.$store.dispatch("LOADER_START");
      vm.$store
        .dispatch("LEADS_GET_ALL", { team_id: null, type: vm.filter_data })
        .then((response) => {
          vm.columns = response.data.map((item) => {
            eventBus.$set(item, "on_drag_active", false);
            return item;
          });
          vm.$store.dispatch("LOADER_CLOSE");
          vm.changeDisplay({ value: vm.view_type });
          if (vm.$route.params.id) {
            vm.columns.forEach((column) => {
              column.leads.forEach((lead) => {
                if (lead.id == vm.$route.params.id) {
                  vm.viewStory(lead);
                }
              });
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("LOADER_CLOSE");
        });
    },
    // =======================
    deleteSection(id) {
      let vm = this;
      vm.$store
        .dispatch("LEADS_DELETE_SECTION", {
          column_id: id,
          team_id: null,
        })
        .then((response) => {
          vm.show_section_form = false;
          vm.getLeads(vm.filter_data);
        })
        .catch((error) => { });
    },
    editSection(item) {
      let vm = this;
      vm.selected_section = item;
      vm.show_section_form = true;
    },
    addSectionNew() {
      let vm = this;
      vm.selected_section = null;
      vm.show_section_form = true;
    },
    onSectionSaved() {
      let vm = this;
      vm.show_section_form = false;
      vm.getLeads(vm.filter_data);
    },
    // =========================
    editStory(lead) {
      let vm = this;
      vm.story_item = lead;
      vm.selected_section = { id: lead.column_id };
      vm.show_form = true;
    },
    viewStory(lead) {
      let vm = this;
      vm.story_item = lead;
      vm.editStory(lead);
    },
    deleteStory(lead) {
      let vm = this;
      vm.$store
        .dispatch("LEADS_DELETE_STORY", {
          section_id: lead.column_id,
          lead_id: lead.id,
          team_id: null,
        })
        .then((response) => {
          vm.show_form = false;
          vm.getLeads(vm.filter_data);
        })
        .catch((error) => { });
    },
    onStorySaved() {
      let vm = this;
      vm.show_form = false;
      vm.getLeads(vm.filter_data);
    },
    getLeads(type) {
      let vm = this;
      vm.$store.dispatch("LOADER_START");
      vm.$store
        .dispatch("LEADS_GET_ALL", { team_id: null, type: type })
        .then((response) => {
          vm.columns = response.data.map((item) => {
            eventBus.$set(item, "on_drag_active", false);
            return item;
          });
          vm.$store.dispatch("LOADER_CLOSE");
          vm.changeDisplay({ value: vm.view_type });
          if (vm.$route.params.id) {
            vm.columns.forEach((column) => {
              column.leads.forEach((lead) => {
                if (lead.id == vm.$route.params.id) {
                  vm.viewStory(lead);
                }
              });
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("LOADER_CLOSE");
        });
    },
    addNewStory(item) {
      let vm = this;
      vm.story_item = null;
      vm.selected_section = item;
      vm.show_form = true;
    },
    // =========================
    cardDragEnd({ target }) {
      target.className = " card";
    },
    cardDradStart(e, item, column_index, card_index) {
      let vm = this;
      vm.item_hold = item;
      vm.inactive_column_index = column_index;
      vm.inactive_card_index = card_index;
      e.target.className += " hold";
      setTimeout(() => {
        e.target.className += " invisible";
      }, 0);
    },
    columnDradOver(e, index) {
      let vm = this;
      e.preventDefault();
      vm.columns.forEach((item) => {
        item.on_drag_active = false;
      });
      vm.columns[index].on_drag_active = true;
    },
    columnDradEnter(e, index) {
      e.preventDefault();
      e.target.className += " hovered";
      let vm = this;
      vm.active_column_index = index;
    },
    columnDradLeave(e, index) {
      e.target.className = "body";
      let vm = this;
    },
    columnDradDrop(e) {
      let vm = this;
      e.target.className = "body";
      if (vm.active_column_index != null) {
        vm.columns[vm.active_column_index].leads.push(vm.item_hold);
        vm.columns[vm.inactive_column_index].leads.splice(
          vm.inactive_card_index,
          1
        );
        let card_index = vm.columns[vm.active_column_index].leads.length - 1;
        vm.cardMove(card_index, vm.active_column_index);
      }
    },
    cardDradOver(e, index) {
      e.preventDefault();
      let vm = this;
      vm.columns.forEach((item) => {
        item.on_drag_active = false;
      });
      vm.columns[index].on_drag_active = true;
    },
    cardDradEnter(e, column_index, card_index) {
      e.preventDefault();
      let vm = this;
      vm.active_column_index = column_index;
      vm.active_card_index = card_index;
    },
    cardDradLeave(e, index) {
      let vm = this;
    },
    cardDradDrop(e) {
      let vm = this;
      vm.columns.forEach((item) => {
        item.on_drag_active = false;
      });
      if (vm.active_card_index != null) {
        vm.columns[vm.inactive_column_index].leads.splice(
          vm.inactive_card_index,
          1
        );
        vm.columns[vm.active_column_index].leads.splice(
          vm.active_card_index,
          0,
          vm.item_hold
        );
        vm.cardMove(vm.active_card_index, vm.active_column_index);
      }
    },
    cardMove(card_index, column_index) {
      let vm = this;
      let section_id = vm.columns[column_index].id;
      let story_id = vm.columns[column_index].leads[card_index].id;
      vm.$store
        .dispatch("LEADS_MOVE_STORY", {
          to_section_id: section_id,
          story_id: story_id,
          story_index: card_index,
        })
        .then((response) => {
          vm.show_story_form = false;
        })
        .catch((error) => { });
    },
  },
  watch: {
    filter_close_date_data(val) {
      let vm = this;
      vm.filterByCloseDate(val);
    },
    sort_filter_data(val) {
      let vm = this;
      vm.filterBySort(val);
    },
    teams_filter_data(val) {
      let vm = this;
      vm.filterByTeam(val);
    },
    filter_data(val) {
      let vm = this;
      vm.getLeads(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ebebeb;
  padding: 5px;

  .page-header {
    height: 38px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .options {
      display: flex;
      align-items: center;
    }
  }

  .page-body {
    height: calc(100% - 43px);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    .wrapper-section {
      height: 100%;
      display: flex;
      transition: 600ms ease-in-out;

      .column {
        width: 200px;
        margin-right: 5px;
        border-radius: 5px;
        height: 100%;
        display: flex;
        flex-direction: column;
        transition: 600ms ease-in-out;

        .container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          .header {
            width: 100%;
            height: 50px;
            padding: 0px 5px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            display: flex;
            background: #fff;

            .row-1 {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 40px;
              width: 100%;
              border-bottom: 2px solid #ebebeb;

              .title {
                text-transform: capitalize;
                font-size: 12px;
                overflow: hidden;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
              }

              .options {
                .btn-add {
                  background: linear-gradient(180deg, #229fa5 0%, #229ea5 100%);
                  color: #fff;
                  border-radius: 5px;
                  padding-top: 1px;
                  width: 22px;
                  height: 22px;
                  margin-left: 14px;
                  font-size: 13px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }

          .body {
            width: 100%;
            max-height: calc(100% - 50px);
            min-height: 100px;
            overflow-y: auto;
            padding: 0px 5px;
            padding-bottom: 50px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            display: flex;
            flex-direction: column;
            background: #fff;

            .card {
              width: 100%;
              background: #f5f5f5;
              position: relative;
              cursor: pointer;
              border-radius: 5px;
              margin-bottom: 5px;

              &:hover {
                .card-header {
                  .card-checkbox {
                    display: block;
                  }
                }
              }

              .card-header {
                display: flex;
                justify-content: flex-end;
                position: absolute;
                right: 2px;
                top: 8px;

                .card-checkbox:checked {
                  display: block;
                }

                .card-checkbox {
                  display: none;
                }
              }

              .title-widget {
                display: flex;
                padding: 8px 8px;

                .lead-name {
                  font-size: 12px;
                  font-weight: 800;
                  color: #000;
                  overflow: hidden;
                  text-decoration: none;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 100%;
                }
              }

              .compang-widget {
                padding: 8px 8px;
                padding-top: 0px;

                .company-name {
                  color: #0884af;
                  font-size: 11px;
                  margin-bottom: 5px;
                  display: block;
                }

                .company-contact {
                  display: flex;
                  align-items: center;
                  margin-bottom: 5px;

                  span {
                    font-size: 11px;
                    white-space: nowrap;
                  }

                  .other-contact {
                    color: #ccc;
                    font-size: 11px;
                    white-space: nowrap;
                  }
                }
              }

              .members {
                display: flex;
                padding: 8px 8px;
                padding-top: 0px;

                .member {
                  display: flex;
                  align-items: center;

                  img {
                    width: 16px;
                    height: 16px;
                    object-fit: cover;
                    margin-right: 4px;
                    border-radius: 50%;
                    border: 2px solid #fff;

                    &.override {
                      margin-left: -10px;
                    }
                  }

                  .name {
                    background: #06a1a11f;
                    padding: 2px 5px;
                    border-radius: 15px;
                    font-size: 11px;
                    color: #04a5a5;
                    display: block;
                    overflow: hidden;
                    text-decoration: none;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 68px;
                    text-transform: capitalize;
                  }
                }
              }

              .price {
                color: rgb(134, 134, 134);
                padding: 8px 8px;
                padding-top: 0px;
                font-size: 10px;
              }

              .attachment {
                width: 100%;
                padding: 0px 10px;

                img {
                  width: 100%;
                  height: 70px;
                  object-fit: cover;
                  border-radius: 20px;
                }
              }

              .card-footer {
                background: #f0f0f0;
                border-radius: 0px 0px 10px 10px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px 5px;

                .resume-widget {
                  .btn-download-resume {
                    background: #f6f6f6;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    padding: 8px 8px;
                    font-size: 11px;
                    color: #0884af;

                    .icon {
                      margin-right: 5px;
                      width: 12px;
                    }
                  }

                  .no-resume {
                    font-family: "Nunito";
                    font-style: italic;
                    font-weight: 200;
                    font-size: 11px;
                    color: #707070;
                  }
                }

                .card-date {
                  font-family: "Nunito";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  text-align: right;
                  color: #707070;
                }
              }

              &.hold {
                border: solid#04a5a5 1px;
                border-radius: 5px;
              }

              &.invisible {
                opacity: 0.5;
              }
            }

            .drag-holder {
              height: 80px;
              width: 100%;
              border: 1px dashed rgb(75, 75, 75);
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &.hovered {
              background: #f4f4f4;
            }
          }
        }

        &.relaxed {
          width: 300px;

          .container {
            .body {
              .card {
                .due-date {
                  position: absolute;
                  bottom: 18px;
                  right: 15px;
                  font-size: 10px;
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    .not-found {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      font-size: 20px;
      color: #000;
      background: #fff;
      border-radius: 10px;
    }

    .form-widget-modal {
      width: 500px;
    }

    .section-modal {
      .moder-header {
        display: flex;
        justify-content: space-between;

        .btn-section-delete {
          display: flex;
          align-items: center;

          img {
            width: 12px;
            margin-right: 3px;
            margin-top: -2px;
          }
        }
      }
    }

    .story-form-widget-modal {
      width: 400px;
      min-height: 200px;
      padding: 0px;
      position: relative;

      .moder-header {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 10px 10px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        border-bottom: 1px solid #ddd;

        .options {
          display: flex;
          align-items: center;

          button {
            display: flex;
            align-items: center;
            margin-right: 10px;
            font-size: 15px;

            img {
              width: 15px;
              margin-right: 4px;
              margin-top: -4px;
            }
          }
        }

        .btn-close {
          font-size: 30px;
          color: #890000;
        }
      }

      .modal-body {
        padding: 0px 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        h4,
        h5,
        h6,
        p {
          margin: 0px;
        }

        h4 {
          font-size: 14px;
          color: #000;
          margin-bottom: 21px;
          padding-bottom: 20px;
          border-bottom: 1px solid #ddd;
        }

        h5 {
          font-size: 17px;
          color: #707070;
          margin-bottom: 21px;
          padding-bottom: 20px;
          border-bottom: 1px solid #ddd;
        }

        h6 {
          font-size: 17px;
          color: #707070;
          margin-bottom: 21px;
          padding-bottom: 20px;
          border-bottom: 1px solid #ddd;
        }

        p {
          font-size: 15px;
          font-weight: 800;
          color: #707070;
          margin-bottom: 4px;
        }

        .company-contact {
          margin-bottom: 15px;
          padding-bottom: 20px;
          border-bottom: 1px solid #ddd;
        }

        .members {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          max-height: 150px;
          overflow-y: auto;

          .member {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            img {
              width: 23px;
              margin-right: 10px;
              border-radius: 50%;
            }
          }
        }

        .price {
          position: absolute;
          right: 27px;
          bottom: 23px;
        }
      }
    }
  }
}

.search-account-item {
  padding: 10px 15px;
  display: block;
  cursor: pointer;

  &:hover {
    background: rgb(250, 250, 250);
  }
}

.selected-search-account-item {
  padding: 5px 12px;
  display: block;
  cursor: pointer;
  border: 1px solid #ddd;
  margin-top: 10px;
  border-radius: 15px;
  font-size: 11px;

  &:hover {
    background: rgb(250, 250, 250);
  }
}

.search-contact-item {
  padding: 10px 15px;
  display: flex;
  cursor: pointer;
  align-items: center;

  img {
    border-radius: 50%;
    margin-right: 8px;
  }

  &:hover {
    background: rgb(250, 250, 250);
  }
}

.selected-search-contact-item {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
  margin-top: 10px;
  border-radius: 15px;
  font-size: 11px;

  img {
    border-radius: 50%;
    margin-right: 8px;
  }

  &:hover {
    background: rgb(250, 250, 250);
  }
}

.dates-widget {
  display: grid;
  grid-template-columns: 49% 48%;
  grid-gap: 10px;
  background: #fff;
  padding: 8px;
}

.filter-date-field_wrapper {
  height: 40px;
  align-items: center;
  width: 120px;
  padding-top: 10px;
}
</style>
<style lang="scss">
.filter-date-field {
  background: transparent;
  width: 100%;
}
</style>
