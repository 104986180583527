export const NOTIFICATION_MARK_ALL_AS_READ = "NOTIFICATION_MARK_ALL_AS_READ";
export const COMMUNICATION_GET_QUESTION_ANSWERS = "COMMUNICATION_GET_QUESTION_ANSWERS";
export const COMMUNICATION_GET_QUESTIONAIRE = "COMMUNICATION_GET_QUESTIONAIRE";
export const PROCESSMESSAGE_SURVEY_REMIND = "PROCESSMESSAGE_SURVEY_REMIND";

export const COMMUNICATION_LOCK_QUESTIONAIRE = "COMMUNICATION_LOCK_QUESTIONAIRE"
export const COMMUNICATION_COMPLETED_QUESTIONAIRE = "COMMUNICATION_COMPLETED_QUESTIONAIRE"
export const COMMUNICATION_NOT_STARTED_QUESTIONAIRE = "COMMUNICATION_NOT_STARTED_QUESTIONAIRE"
export const COMMUNICATION_VIEW_QUESTIONAIRE_RESULT = "COMMUNICATION_VIEW_QUESTIONAIRE_RESULT";

export const COMMUNICATION_EMAIL_GET_TEMPLATES = "COMMUNICATION_EMAIL_GET_TEMPLATES";
export const COMMUNICATION_EMAIL_SEARCH_TEMPLATES = "COMMUNICATION_EMAIL_SEARCH_TEMPLATES";
export const COMMUNICATION_NOTIFICATION_SEARCH_TEMPLATES = "COMMUNICATION_NOTIFICATION_SEARCH_TEMPLATES";
export const COMMUNICATION_NOTIFICATION_GET_TEMPLATES = "COMMUNICATION_NOTIFICATION_GET_TEMPLATES";
export const COMMUNICATION_QUESTIONAIRE_SEARCH_TEMPLATES = "COMMUNICATION_QUESTIONAIRE_SEARCH_TEMPLATES";
export const COMMUNICATION_QUESTIONAIRE_GET_TEMPLATES = "COMMUNICATION_QUESTIONAIRE_GET_TEMPLATES";
export const COMMUNICATION_EMAIL_SAVE_TEMPLATE = "COMMUNICATION_EMAIL_SAVE_TEMPLATE";
export const COMMUNICATION_EMAIL_UPDATE_TEMPLATE = "COMMUNICATION_EMAIL_UPDATE_TEMPLATE";
export const COMMUNICATION_QUESTIONAIRE_SAVE_TEMPLATE = "COMMUNICATION_QUESTIONAIRE_SAVE_TEMPLATE";
export const COMMUNICATION_NOTIFICATION_SAVE_TEMPLATE = "COMMUNICATION_NOTIFICATION_SAVE_TEMPLATE";
export const COMMUNICATION_NOTIFICATION_SEND_NOTIFICATION = "COMMUNICATION_NOTIFICATION_SEND_NOTIFICATION";

export const COMMUNICATION_NOTIFICATION_DELETE_TEMPLATE = "COMMUNICATION_NOTIFICATION_DELETE_TEMPLATE";
export const COMMUNICATION_QUESTIONAIRE_DELETE_TEMPLATE = "COMMUNICATION_QUESTIONAIRE_DELETE_TEMPLATE";
export const COMMUNICATION_EMAIL_DELETE_TEMPLATE = "COMMUNICATION_EMAIL_DELETE_TEMPLATE";

export const COMMUNICATION_NOTIFICATION_UPDATE_TEMPLATE = "COMMUNICATION_NOTIFICATION_UPDATE_TEMPLATE";
export const COMMUNICATION_QUESTIONAIRE_UPDATE_TEMPLATE = "COMMUNICATION_QUESTIONAIRE_UPDATE_TEMPLATE";

export const RELEASE_ADD_NEW = "RELEASE_ADD_NEW";
export const RELEASE_UPDATE = "RELEASE_UPDATE";
export const RELEASE_DELETE = "RELEASE_DELETE";
export const RELEASE_GET_ALL = "RELEASE_GET_ALL";