export const ACCOUNT_ADD_NEW = "ACCOUNT_ADD_NEW";
export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";
export const ACCOUNT_GET_DATA = "ACCOUNT_GET_DATA";
export const ACCOUNT_CONTACT_DELETE = "ACCOUNT_CONTACT_DELETE";

export const ACCOUNT_CONTACT_GET_DATA = "ACCOUNT_CONTACT_GET_DATA";
export const ACCOUNT_CONTACT_ADD_NEW = "ACCOUNT_CONTACT_ADD_NEW";
export const ACCOUNT_CONTACT_UPDATE = "ACCOUNT_CONTACT_UPDATE";

export const SEARCH_EVERYTHING = "SEARCH_EVERYTHING";

export const ACCOUNT_EVENTS_GET_DATA = "ACCOUNT_EVENTS_GET_DATA";
export const ACCOUNT_EVENTS_ADD_NEW = "ACCOUNT_EVENTS_ADD_NEW";
export const ACCOUNT_EVENTS_UPDATE = "ACCOUNT_EVENTS_UPDATE";
export const ACCOUNT_GET_EVENTS_BASED_DATE = "ACCOUNT_GET_EVENTS_BASED_DATE";
export const ACCOUNT_SINGLE_GET_ALL_DATA = "ACCOUNT_SINGLE_GET_ALL_DATA";
export const ACCOUNT_SINGLE_CONTACT_DELETE = "ACCOUNT_SINGLE_CONTACT_DELETE";
export const ACCOUNT_SINGLE_CONTACT_ADD_NEW = "ACCOUNT_SINGLE_CONTACT_ADD_NEW";
export const ACCOUNT_SINGLE_CONTACT_UPDATE = "ACCOUNT_SINGLE_CONTACT_UPDATE";
export const ACCOUNT_SINGLE_ATTACHMENT_DELETE = "ACCOUNT_SINGLE_ATTACHMENT_DELETE";
export const ACCOUNT_SINGLE_ATTACHMENT_ADD_NEW = "ACCOUNT_SINGLE_ATTACHMENT_ADD_NEW";
export const ACCOUNT_SINGLE_ATTACHMENT_UPDATE = "ACCOUNT_SINGLE_ATTACHMENT_UPDATE";
export const ACCOUNT_SINGLE_ATTACHMENT_DOWNLOAD_FILE = "ACCOUNT_SINGLE_ATTACHMENT_DOWNLOAD_FILE";
export const ACCOUNT_CHANGE_STATUS = "ACCOUNT_CHANGE_STATUS";
export const ACCOUNT_FILTER_BY_LOCATION = "ACCOUNT_FILTER_BY_LOCATION";
export const ACCOUNT_CONTACT_EXPORT = "ACCOUNT_CONTACT_EXPORT";
export const SEARCH_ALL_CRM = "SEARCH_ALL_CRM";
export const SEARCH_ALL_SITE = "SEARCH_ALL_SITE";
export const ACCOUNT_DELETE = "ACCOUNT_DELETE";
export const ACCOUNT_SEARCH = "ACCOUNT_SEARCH";
export const ACCOUNT_FILTER_BY_SORT = "ACCOUNT_FILTER_BY_SORT";
export const CONTACTS_SEARCH = "CONTACTS_SEARCH";
export const CONTACTS_FILTER_BY_SORT = "CONTACTS_FILTER_BY_SORT";
export const CONTACTS_FILTER_BY_TAG = "CONTACTS_FILTER_BY_TAG";
export const CONTACTS_GET_TAGS = "CONTACTS_GET_TAGS";
export const CONTACTS_GET_ROLES = "CONTACTS_GET_ROLES";
export const CONTACT_DELETE_TAG = "CONTACT_DELETE_TAG";
export const ACCOUNT_CONTACT_OPTING = "ACCOUNT_CONTACT_OPTING";
export const ACCOUNT_CONTACT_DELETE_SELECTED = "ACCOUNT_CONTACT_DELETE_SELECTED";

export const ACTIVITIES_DELETE_SELECTED = "ACTIVITIES_DELETE_SELECTED";
export const ACTIVITIES_SEARCH = "ACTIVITIES_SEARCH";
export const ACTIVITIES_GET_ALL = "ACTIVITIES_GET_ALL";
export const ACTIVITIES_FILTER = "ACTIVITIES_FILTER";
export const ACTIVITIES_DELETE = "ACTIVITIES_DELETE";

export const NOTES_ADD_NEW = "NOTES_ADD_NEW";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const NOTES_SEARCH = "NOTES_SEARCH";
export const NOTES_GET_ALL = "NOTES_GET_ALL";
export const NOTES_FILTER = "NOTES_FILTER";
export const NOTES_DELETE = "NOTES_DELETE";
export const NOTES_DELETE_SELECTED = "NOTES_DELETE_SELECTED";
export const ACCOUNT_DELETE_SELECTED = "ACCOUNT_DELETE_SELECTED";
export const ACCOUNT_SEND_SELECTED_MESSAGE = "ACCOUNT_SEND_SELECTED_MESSAGE";
export const ACCOUNT_UPDATE_SELECTED = "ACCOUNT_UPDATE_SELECTED";
export const ACCOUNT_SINGLE_GET_INTERNSHIP = "ACCOUNT_SINGLE_GET_INTERNSHIP";
export const ACCOUNT_GET_TAGS = "ACCOUNT_GET_TAGS";
export const ACCOUNT_FILTER_BY_TAG = "ACCOUNT_FILTER_BY_TAG";
export const ACCOUNT_DELETE_TAG = "ACCOUNT_DELETE_TAG"
export const ACCOUNT_FILTER_BY_NAME = "ACCOUNT_FILTER_BY_NAME";
export const ACCOUNT_EXPORT = "ACCOUNT_EXPORT";

export const ACCOUNT_CONTACT_DETAILS = "ACCOUNT_CONTACT_DETAILS";