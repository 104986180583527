<template>
  <textarea :disabled="disabled" :value="value" :placeholder="placeholder" class="widget_textarea" @change="onChange"
    @blur="onBlur" @focus="onFocus" @keyup="onKeyup" @keydown="onKeydown" @click="onClick"></textarea>
</template>

<script>
export default {
  name: "widget_input",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() { },
  methods: {
    onChange(e) {
      let vm = this;
      vm.$emit("change", e);
    },
    onBlur(e) {
      let vm = this;
      vm.$emit("blur", e);
    },
    onFocus(e) {
      let vm = this;
      vm.$emit("focus", e);
    },
    onKeyup(e) {
      let vm = this;
      vm.$emit("input", e.target.value);
    },
    onKeydown(e) {
      let vm = this;
      vm.$emit("input", e.target.value);
    },
    onClick(e) {
      let vm = this;
      vm.$emit("click", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.widget_textarea {
  padding: 10px 10px;
  border-radius: 5px;
  font-weight: 500;
  width: 100%;
  position: relative;
  background: #fff;
  border-width: 1px;
  border-style: solid;
  outline: none;
  border-color: #ddd;
  resize: none;
  min-height: 70px;
}
</style>
