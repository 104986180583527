<template>
  <div class="page-modal">
    <widgetModal
      :title="'Account Selected(' + content.length + ')'"
      subtitle="You can add information directly from this form"
      btnlefttext="Save Changes"
      btncentertext="Cancel"
      btnrighttext="Generate LIA"
      :leftloading="adding_acc_loading"
      :rightloading="false"
      :centerloading="false"
      :footerhidden="false"
      :hiderightbtn="true"
      footer_padding="10px 18px 30px 30px"
      header_padding="30px 0px 0px 31px"
      body_padding="0px 30px"
      width="550px"
      @onLeftClick="onConfirm"
      @onCenterClick="onCancel"
      @onRightClick="onGenerateInternship"
    >
      <div class="widget-modal-body-noscoll">
        <div class="row">
          <switches
            theme="c-switch"
            style="margin-top: 40px"
            type-bold="true"
            color="blue"
            v-model="allowed_input.is_status_enabled"
          ></switches>
          <div class="h-spacer"></div>
          <widgetInputWrapper style="width: 100%" title="Account Status">
            <widgetSelect
              v-model="account_form.status"
              :opt="[
                { label: 'Active', value: 'active' },
                { label: 'Passive', value: 'passive' },
              ]"
              placeholder="Acccount status"
            ></widgetSelect>
          </widgetInputWrapper>
        </div>
        <div class="row">
          <switches
            theme="c-switch"
            style="margin-top: 40px"
            type-bold="true"
            color="blue"
            v-model="allowed_input.is_location_enabled"
          ></switches>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Account Location">
            <map-helper v-model="account_form.location"></map-helper>
          </widgetInputWrapper>
        </div>
        <div class="row">
          <switches
            theme="c-switch"
            style="margin-top: 40px"
            type-bold="true"
            color="blue"
            v-model="allowed_input.is_category_enabled"
          ></switches>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Account Category">
            <widgetSelect
              v-model="account_form.category"
              :opt="[
                { label: 'No category', value: '' },
                { label: 'Gold', value: 'gold' },
                { label: 'Silver', value: 'silver' },
                { label: 'Platinum', value: 'platinum' },
              ]"
              placeholder="Acccount category"
            ></widgetSelect>
          </widgetInputWrapper>
        </div>
        <div class="row">
          <switches
            theme="c-switch"
            style="margin-top: 40px"
            type-bold="true"
            color="blue"
            v-model="allowed_input.is_tags_enabled"
          ></switches>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Account Tags">
            <widgetTags v-model="tags" placeholder="Add tags"></widgetTags>
          </widgetInputWrapper>
        </div>
      </div>
    </widgetModal>
  </div>
</template>
<script>
import { eventBus } from "@/main";
export default {
  name: "page-modal",
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      account_form: {
        ids: null,
        status: "active",
        category: "gold",
        location: "",
        tags: [],
        tags_added: [],
        tags_removed: [],
      },
      allowed_input: {
        is_status_enabled: false,
        is_location_enabled: false,
        is_category_enabled: false,
        is_tags_enabled: false,
      },
      tags: {
        added: [],
        removed: [],
      },
      adding_acc_loading: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    let vm = this;
    vm.addNew();
  },
  methods: {
    addNew() {
      let vm = this;
      vm.account_form = {
        ids: null,
        status: "active",
        category: "gold",
        location: "",
        tags: [],
        tags_added: [],
        tags_removed: [],
      };
    },
    onConfirm() {
      let vm = this;
      vm.adding_acc_loading = true;
      vm.account_form.ids = vm.content.map((item) => item.id);
      if (vm.tags.added.length > 0) {
        vm.account_form.tags_added = vm.tags.added.map((item) => {
          return item;
        });
      }
      let formData = {};

      eventBus.$set(formData, "ids", vm.account_form.ids);
      if (vm.allowed_input.is_status_enabled) {
        eventBus.$set(formData, "status", vm.account_form.status);
      }
      if (vm.allowed_input.is_category_enabled) {
        eventBus.$set(formData, "category", vm.account_form.category);
      }
      if (vm.allowed_input.is_location_enabled) {
        eventBus.$set(formData, "location", vm.account_form.location);
      }
      if (vm.allowed_input.is_tags_enabled) {
        eventBus.$set(formData, "tags_added", vm.account_form.tags_added);
      }
      vm.$store
        .dispatch("ACCOUNT_UPDATE_SELECTED", formData)
        .then((response) => {
          vm.adding_acc_loading = false;
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Accounts updated",
            type: "success",
            duration: 3000,
          });
          vm.$emit("success", null);
        })
        .catch((error) => {
          vm.adding_acc_loading = false;
          vm.$notify({
            group: "status",
            title: "Message",
            text: "Something went wrong",
            type: "warn",
            duration: 3000,
          });
          console.error({ error });
        });
    },
    onCancel() {
      let vm = this;
      vm.$emit("close", null);
    },
    onGenerateInternship() {
      let vm = this;
      vm.$emit("onGenerateInternship", {
        type: "many",
        accounts: vm.content,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";
.page-modal {
}
</style>