<template>
  <section class="terms-widget">
    <img src="@/assets/images/logo/logo__dark.svg" class="grey_logo" />
    <div class="term-content">
      <h1 class="terms-main-title">Terms & Conditions</h1>
      The Terms and Conditions are binding legal agreements between you, as an
      individual or entity, and Consultified AB ("MERITIOS"). By using the
      MERITIOS software you agree to be bound by the Terms and Conditions. If
      you do not agree, do not check the "I accept the terms" box and do not use
      MERITIOS. You agree to use MERITIOS signifies that you have read,
      understood, and agree to be bound by the Terms and Conditions.

      <h1 class="terms-title">Description of MERITIOS</h1>
      MERITIOS is a web-based application platform and an app that provides
      Software as a Service (SaaS).

      <h1 class="terms-title">Rights and Obligations</h1>
      The user hereby represents and warrant that: <br />
      It has full power and capacity to execute and deliver this Agreement;
      perform its obligations under this Agreement; and use the service
      contemplated by this Agreement; and the execution, delivery, and
      performance by the Parties' of its obligations under this Agreement and
      the use of MERITIOS do not contravene or conflict with;

      <br />(i) any applicable law or regulation or any order of any government
      or other official authority, body, or agency or any judgment, order, or
      decree of any court having jurisdiction over it, or

      <br />(ii) the provisions of its constitutional documents..
      <h1 class="terms-title">Limitation of Liability</h1>
      YOU EXPRESSLY UNDERSTAND AND AGREE THAT MERITIOS SHALL NOT BE LIABLE FOR
      ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
      INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE,
      DATA OR OTHER INTANGIBLE LOSSES (EVEN IF MERITIOS HAS BEEN ADVISED OF THE
      POSSIBILITY OF SUCH DAMAGES). IN NO EVENT WILL MERITIOS AGGREGATE
      LIABILITY TO YOU EXCEED THE AMOUNT OF FEES PAID BY YOU TO MERITIOS. THESE
      LIMITATIONS AND EXCLUSIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF
      ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. SOME JURISDICTIONS DO NOT ALLOW
      THE LIMITATIONS OF DAMAGES AND/OR EXCLUSIONS OF LIABILITY FOR INCIDENTAL
      OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY
      NOT APPLY TO YOU.

      <h1 class="terms-title">Open Source</h1>
      MERITIOS may contain or be provided together with open-source software.
      Each item of open source software is subject to its own applicable license
      terms. Copyrights to the open-source software are held by the respective
      copyright holders indicated therein.

      <h1 class="terms-title">Intellectual Property</h1>
      The user acknowledges that all rights, title and interest in the
      intellectual property in MERITIOS, including all copyrights, patents and
      trade secrets therein including, but not limited to, all documentation and
      manuals relating thereto are and shall remain the sole and exclusive
      property of MERITIOS or under the use of MERITIOS. Upon termination or
      expiry of this Agreement for any reason, all rights and subscriptions of
      the user under this Agreement shall terminate.

      <h1 class="terms-title">Privacy and data protection:</h1>
      Your personal information and how MERITIOS handles the data By personal
      data we mean the data that MERITIOS collects and save about you and how
      you use our services that identify you directly or indirectly. We use this
      information at MERITIOS to offer services, give you as a user a better
      experience, improve our services and provide offers that suit your needs
      and creates value. The following information explains how we collect and
      manage your personal data by GDPR.

      <h1 class="terms-title">Type of personal data that MERITIOS collects</h1>
      When you register as a user with MERITIOS you do this with LinkedIn,
      Google, Microsoft, Facebook Authentication Service or by adding your
      e-mail and password in MERITIOS. MERITIOS saves data related to your
      Profile, Communication, Leads, Feed and the services you use.
      <br />

      Support tickets:<br />
      When you contact MERITIOS support, the information you provide to MERITIOS
      is collected to help our support. <br />
      This is how MERITIOS collects personal information:
      <br />
      MERITIOS collects and processes data:<br />
      • After you become a user of MERITIOS and start adding content to your
      profile.<br />
      • When you contact MERITIOS in the form of notes, chat conversations,
      e-mails and recorded calls.<br />
      • When you use MERITIOS for example when you visit and use the website.<br />
      • Collected via cookies when you use our website. This is what MERITIOS
      uses your personal information for MERITIOS to process your data, one of
      the following legal bases is required.
      <br />
      • Execute the agreement with you<br />
      • Perform a legal obligation for MERITIOS
      <br />
      • The processing of data is in your and MERITIOS 's interest<br />
      • Approval from you regarding treatment.<br />

      In order for MERITIOS to offer its services to you, we need to process
      your personal information. Below you will find information about what
      MERITIOS uses your data for as to what legal basis in which the processing
      of your data is based.

      <h1 class="terms-title">Offer of services</h1>
      MERITIOS processes contact and personal information in order to identify
      you as a user and customer and to handle and deliver the services that
      MERITIOS platform offers as well as billing and payment according to
      MERITIOS's general terms and conditions that you have ordered and use as
      well as the contact and personal information required to manage the
      billing and payment of the services you use. <br />
      <h1 class="terms-title">Communication and support from MERITIOS</h1>
      MERITIOS may use personal information previously collected to offer you
      better help and a richer experience and greater value. MERITIOS uses your
      contact information and information about the services you as a user use
      for the basis of invoices, newsletters, important information about your
      services, offers and tips on how to use our services. Legal basis: Balance
      of interests, consent and performance of agreements.

      <h1 class="terms-title">
        Development of MERITIOS 's services and products
      </h1>
      MERITIOS processes your personal information about how you use the
      MERITIOS services as well as your interaction with MERITIOS as a basis for
      improving your experience specifically and the MERITIOS services in
      general.

      <h1 class="terms-title">Marketing</h1>
      In order for to be able to market relevant services to you and your needs,
      MERITIOS processes your personal information about what services you use
      and how you use them. Legal basis: Balance of interest and consent.
      <h1 class="terms-title">Security</h1>
      MERITIOS have the right to process your personal information in order to
      discover and prevent the following:
      <br />
      • abuse<br />
      • intrusion<br />
      • offenses<br />
      • violates Terms and Conditions<br />

      <h1 class="terms-title">Statutory obligations:</h1>
      MERITIOS processes personal data in order to comply with the requirements
      set by the law.<br />
      Legal basis: Legal obligation.<br />
      MERITIOS saves personal information<br />
      MERITIOS saves personal data if there is a documented purpose. As a user
      you can contact MERITIOS data protection representative for details.
      infos@MERITIOS.com.

      <h1 class="terms-title">Authorities</h1>
      Upon request, MERITIOS may be required by law and authority to disclose
      certain personal information to, for example, the Police. This is how
      MERITIOS protects your personal information
      <br />
      MERITIOS uses industry standards to store, process and communicate
      sensitive information such as personal data and passwords in a secure way
      using state of the art encryption algorithms. The protection is
      implemented with systematic, organizational and technical measures to
      ensure integrity, confidentiality and accessibility. You decide on your
      personal data
      <br />
      You decide on your own personal data which means; you decide what
      information you want to submit and what processing of your personal data
      you approve. You can withdraw your consent when you wish. Please note,
      however, that we need certain personal information in order to provide our
      services to you. If you choose to revoke your consent, it means that we
      cannot provide the services to you and that your account will be
      permanently deleted. Your personal information when you are no longer a
      user
      <br />
      When you, as a user, terminate your account, MERITIOS deletes all personal
      data where there is no purpose for future processing. Data that is not
      deleted is data required by the Accounting Act.
      <h1 class="terms-title">Changes</h1>
      Any changes to this Agreement require a written agreement between the
      Parties, except as set out below. MERITIOS shall have the right to carry
      out changes (including in prices) with immediate effect due to new or
      changes to legislation, taxes, etc.

      <h1 class="terms-title">Terms and Terminations</h1>
      MMERITIOS shall have a right to close the users’s access to the
      Application with immediate effect if the user:
      <br />
      • uses MERITIOS in a way constituting a criminal act or contrary to
      applicable law (especially personal data protection legislation)
      <br />
      • uses MERITIOS in a way constituting a risk of damage for MERITIOS or a
      third party<br />
      • the Customer does not pay of Subscription Fees or other charges pursuant
      to this agreement.

      <h1 class="terms-title">Confidentiality</h1>
      MERITIOS shall have the right to mention the user as reference in its
      marketing activities (including web page with use of the Customer’s
      official logo), both during the term of this Agreement and thereafter.

      <h1 class="terms-title">Disputes</h1>
      Agreements shall be governed and construed in accordance with Swedish Law.
      Each of the parties irrevocably submits for all purposes to the exclusive
      jurisdiction of the courts of Sweden.
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
.terms-widget {
  width: 100%;
  .grey_logo {
    width: 100px;
    margin-bottom: 20px;
  }
  .term-content {
    text-align: justify;
    .terms-main-title {
      font-size: 30px;
      font-family: "Nunito", sans-serif;
      color: #2991b8;
    }
    .terms-title {
      font-size: 16px;
      font-family: "Nunito", sans-serif;
      color: #2991b8;
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }
}
</style>