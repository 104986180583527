<template>
  <div class="form-widget-modal">
    <div class="wizard-widget">
      <div class="wizard-widget-header">
        <button
          class="btn-back"
          @click="
            () => {
              if (add_many_active_tab == 2) {
                add_many_active_tab = 1;
              } else {
                goBack();
              }
            }
          "
        >
          <img src="@/assets/admin-icon/chevron-left-green.svg" />
        </button>
        <div class="wizard-indicator">
          <div
            class="indicator-item"
            :class="{ active: add_many_active_tab == 1 }"
          ></div>
          <div
            class="indicator-item"
            :class="{ active: add_many_active_tab == 2 }"
          ></div>
        </div>
        <div class="wizard-action"></div>
      </div>
      <div class="wizard-widget-body">
        <div class="tab" v-if="add_many_active_tab == 1">
          <div class="template-widget">
            <h1>{{ title }}</h1>
            <p>
              {{ description }}
            </p>
            <a download :href="file" class="btn-download"
              >Download Template
              <img src="@/assets/admin-icon/download-white.svg" />
            </a>
          </div>
          <div class="wizard-widget-footer-options">
            <button class="btn-next" @click="add_many_active_tab = 2">
              Next
            </button>
          </div>
        </div>
        <div class="tab" v-if="add_many_active_tab == 2">
          <div class="upload-widget">
            <slot></slot>
            <h1>{{ title }}</h1>
            <p>{{ actiontext }}</p>
            <div class="upload-widget-container">
              <CustomerUploader
                @onFileSelected="
                  (file) => {
                    if (file) {
                      onFileSelected(file);
                    }
                  }
                "
                class="uploader"
              >
                <button class="btn-upload">
                  Attach file
                  <img src="@/assets/admin-icon/upload-white.svg" />
                </button>
              </CustomerUploader>
              <div class="selected-file" v-if="selected_file">
                <label
                  ><img src="@/assets/admin-icon/file-grey.svg" />
                  {{ selected_file.name }}</label
                >
              </div>
            </div>
          </div>
          <div class="wizard-widget-footer-options">
            <c-button :loading="loading" @click="submitData" class="btn-next"
              >Finish</c-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "tags-widget",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    actiontext: {
      type: String,
      default: "",
    },
    file: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      add_many_active_tab: 1,
      selected_file: null,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    let vm = this;
  },
  methods: {
    goBack() {
      let vm = this;
      vm.$emit("back", null);
    },
    onFileSelected(file) {
      let vm = this;
      vm.selected_file = file;
      vm.$emit("onFile", file);
    },
    submitData() {
      let vm = this;
      vm.$emit("onSubmit", null);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";
.form-widget-modal {
  .wizard-widget {
    .wizard-widget-header {
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 20px;
      .wizard-indicator {
        display: flex;
        align-items: center;
        .indicator-item {
          height: 5px;
          width: 50px;
          background: #d5d5d5;
          margin: 0px 5px;
          &.active {
            background: #1ba5a4;
          }
        }
      }
    }
    .wizard-widget-body {
      .tab {
        .template-widget {
          h1 {
            font-size: 18px;
          }
          .btn-download {
            width: 200px;
            height: 45px;
            background: #1890a9 0% 0% no-repeat padding-box;
            border-radius: 4px;
            color: #fff;
            outline: none;
            margin-right: 20px;
            display: flex !important;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            img {
              margin-left: 10px;
            }
          }
        }
        .upload-widget {
          h1 {
            font-size: 18px;
          }
          .btn-upload {
            width: 200px;
            height: 45px;
            background: #1890a9 0% 0% no-repeat padding-box;
            border-radius: 4px;
            color: #fff;
            outline: none;
            margin-right: 20px;
            display: flex !important;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            img {
              margin-left: 10px;
            }
          }
          .selected-file {
            margin-top: 20px;
            display: flex;
            align-items: center;
          }
        }
        .wizard-widget-footer-options {
          display: flex;
          justify-content: center;
          padding-top: 40px;
          .btn-next {
            width: 100px;
            height: 45px;
            background: #229ea5;
            border-radius: 4px;
            color: #fff;
            outline: none;
            margin-right: 20px;
            display: flex !important;
            justify-content: center;
            align-items: center;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>