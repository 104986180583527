<template>
    <div class="admin-layout">
        <Sidebar />
        <Navbar />
        <div class="admin-content flex flex-col">
            <router-view />
        </div>
    </div>
</template>
  
<script>
// @ is an alias to /src
import Sidebar from "@/views/components/Sidebar.vue";
import Navbar from "@/views/components/Navbar.vue"
export default {
    name: 'admin-layout',
    components: {
        Sidebar,
        Navbar
    }
}
</script>

<style lang="scss" scoped>
.admin-layout {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .admin-content {
        width: calc(100% - 182px);
        padding-top: 47px;
    }
}
</style>
  