import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import dropdownMenu from "@/components/dropdownMenu.vue";
import VueClosable from 'vue-closable';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLocalStorage from 'vue-localstorage'
import VeeValidate from 'vee-validate';
import MapHelper from "@/components/MapHelper.vue";
import * as VueGoogleMaps from 'vue2-google-maps';
import Datepicker from 'vuejs-datepicker';
import AppMixin from '@/components/mixins/AppMixin.vue';
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import searchSelectInput from "@/components/searchSelect.vue";
import cButton from "@/components/cButton.vue";
import chooseWidget from "@/components/chooseWidget.vue";
import StarRating from 'vue-star-rating'
import moment from 'moment'
import ReadMore from 'vue-read-more';
import Vuetify from 'vuetify'
import TagsInput from "@/components/TagsInput.vue"
import { Datetime } from 'vue-datetime';
import "@/styles/vue-datetime.scss";
import Uploader from "@/components/Partials/Uploader.vue";
import TaskType from "@/components/TaskType.vue";
import filterSelect from "@/components/filterSelect.vue"
import VueApexCharts from 'vue-apexcharts';
import vuescroll from "vuescroll/dist/vuescroll-native";
import assingProfileToolTip from "@/components/assingProfileToolTip.vue"
import { Multipane, MultipaneResizer } from "vue-multipane";
import RoleManagement from "@/components/RoleManagement.vue";
import VueConfirmDialog from 'vue-confirm-dialog';
import appDialog from "@/components/Dialog/AppDialog.vue";
import pathViewer from "@/components/pathViewer.vue";
import progressBar from "@/components/progressBar.vue";
import DropdownButton from "@/components/DropdownButton.vue";
import CustomerUploader from "@/components/Partials/CustomerUploader.vue";
import classDropdown from "@/components/classDropdown.vue";
import questionInputWidget from "@/components/questionInputWidget.vue";
import dialogActionMessage from "@/components/dialogActionMessage.vue";
import SchoolNotifiation from "@/components/SchoolNotifiation.vue";
import miltLevelSelect from "@/components/miltLevelSelect.vue";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';




import uploadManyItems from "@/components/upload_many_items.vue"
import Switches from "vue-switches";

// Consultified widget 
import widgetNotFoundWidget from "@/components/widget/widgetNotFoundWidget.vue"
import WidgetToolbar from "@/components/widget/widgetToolbar.vue";
import widgetButton from "@/components/widget/widgetButton.vue";
import widgetSearch from "@/components/widget/widgetSearch.vue";
import widgetDropdownMenu from "@innologica/vue-dropdown-menu";
import widgetTabs from "@/components/widget/widgetTabs.vue";
import widgetProfile from "@/components/widget/widgetProfile.vue";
import widgetAscDesc from "@/components/widget/widgetAscDesc.vue";
import widgetInput from "@/components/widget/widgetInput.vue";
import widgetModal from "@/components/widget/widgetModal.vue";
import widgetWrapper from "@/components/widget/widgetWrapper.vue";
import widgetTags from "@/components/widget/widgetTags.vue";
import widgetSelect from "@/components/widget/widgetSelect.vue";
import widgetTextarea from "@/components/widget/widgetTextarea.vue";
import widgetDatePicker from "@/components/widget/widgetDatePicker.vue";
import widgetClassDropdown from "@/components/widget/widgetClassDropdown.vue";
import widgetAccountInput from "@/components/widget/widgetAccountInput.vue";
import widgetListSelecter from "@/components/widget/widgetListSelecter.vue";
import widgetCheckbox from "@/components/widget/widgetCheckbox.vue";
import widgetRitchEditor from "@/components/widget/widgetRitchEditor.vue";
import widgetTagItem from "@/components/widget/widgetTagItem.vue"
import widgetMiltSelect from "@/components/widget/widgetMiltSelect.vue"
import widgetDateRangePicker from "@/components/widget/widgetDateRangePicker.vue";
import widgetInvite from "@/components/widgetInvite.vue";
import widgetSearchUserInput from "@/components/widget/widgetSearchUserInput.vue";
import widgetSearchLocalLocation from "@/components/widget/widgetSearchLocalLocation.vue";
import widgetTagsViewer from "@/components/widget/widgetTagsViewer.vue";
import widgetBadget from "@/components/widget/widgetBadget.vue";
import widgetDropdownWithIcon from "@/components/widget/widgetDropdownWithIcon.vue"
import HelpToolTip from "@/components/helper/HelpToolTip.vue";

Vue.mixin(AppMixin);
Vue.use(VueLoading);
Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.component('Loading', VueLoading)




Vue.use(vuescroll, {
  ops: {
    rail: {
      background: 'transparent',
      opacity: 1,
      size: '5px',
      specifyBorderRadius: "5px",
      gutterOfEnds: null,
      gutterOfSide: '0px',
      keepShow: false
    },
    bar: {
      showDelay: 200,
      onlyShowBarOnScroll: false,
      keepShow: true,
      background: '#0884AF',
      opacity: 0.8,
      specifyBorderRadius: false,
      minSize: 0,
      size: '5px',
      disable: false
    }
  }
});
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuetify, {
  theme: {
    primary: "#173024",
    secondary: '#006837',
    accent: '#AAAA2D',
    error: '#b71c1c'
  }
});
Vue.use(ReadMore);

Vue.component('pathViewer', pathViewer);
Vue.component('appDialog', appDialog);
Vue.component('Multipane', Multipane)
Vue.component('MultipaneResizer', MultipaneResizer);
Vue.component('Uploader', Uploader);
Vue.component('datetime', Datetime);
Vue.component('chooseWidget', chooseWidget);
Vue.component('cButton', cButton);
Vue.component('searchSelectInput', searchSelectInput);
Vue.component('star-rating', StarRating);
Vue.component('TagsInput', TagsInput);
Vue.component('TaskType', TaskType);
Vue.component('filterSelect', filterSelect);
Vue.component('assingProfileToolTip', assingProfileToolTip);
Vue.component('HtmlEditor', widgetRitchEditor);
Vue.component('RoleManagement', RoleManagement);
Vue.component('progressBar', progressBar);
Vue.component('DropdownButton', DropdownButton);
Vue.component('CustomerUploader', CustomerUploader);
Vue.component('classDropdown', classDropdown);
Vue.component('questionInputWidget', questionInputWidget);
Vue.component('dialogActionMessage', dialogActionMessage);
Vue.component('SchoolNotifiation', SchoolNotifiation);
Vue.component('miltLevelSelect', miltLevelSelect);
Vue.component('uploadManyItems', uploadManyItems);
Vue.component('Switches', Switches);



// Consultified widget
Vue.component('widgetNotFoundWidget', widgetNotFoundWidget);
Vue.component('WidgetToolbar', WidgetToolbar);
Vue.component('WidgetButton', widgetButton);
Vue.component('WidgetSearch', widgetSearch);
Vue.component('widgetDropdown', widgetDropdownMenu);
Vue.component('widgetTabs', widgetTabs);
Vue.component('widgetProfile', widgetProfile);
Vue.component('widgetAscDesc', widgetAscDesc)
Vue.component('widgetInput', widgetInput);
Vue.component('widgetModal', widgetModal);
Vue.component('widgetInputWrapper', widgetWrapper);
Vue.component('widgetTags', widgetTags);
Vue.component('widgetSelect', widgetSelect);
Vue.component('widgetTextarea', widgetTextarea);
Vue.component('widgetDatePicker', widgetDatePicker);
Vue.component('widgetClassDropdown', widgetClassDropdown);
Vue.component('widgetAccountInput', widgetAccountInput);
Vue.component('widgetListSelecter', widgetListSelecter);
Vue.component('widgetCheckbox', widgetCheckbox);
Vue.component('widgetRitchEditor', widgetRitchEditor);
Vue.component('widgetTagItem', widgetTagItem);
Vue.component('widgetMiltSelect', widgetMiltSelect);
Vue.component('widgetDateRangePicker', widgetDateRangePicker);
Vue.component('widgetInvite', widgetInvite);
Vue.component('widgetSearchUserInput', widgetSearchUserInput);
Vue.component('widgetSearchLocalLocation', widgetSearchLocalLocation);
Vue.component('widgetTagsViewer', widgetTagsViewer);
Vue.component('widgetBadget', widgetBadget);
Vue.component('widgetDropdownWithIcon', widgetDropdownWithIcon);
Vue.component('HelpToolTip', HelpToolTip);

Vue.use(Notifications, { velocity })
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCZu-doKWHHf8P68LQrzYAoFy-IZJBAJuQ',
    libraries: 'places',
  }
});
Vue.component('MapHelper', MapHelper);
Vue.component('Datepicker', Datepicker);

import VAnimateCss from "v-animate-css";
Vue.use(VAnimateCss);

Vue.use(VeeValidate, {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  dictionary: null,
  errorBagName: 'errors', // change if property conflicts
  events: 'input|blur',
  fieldsBagName: 'vee_validate_fields',
  i18n: null, // the vue-i18n plugin instance
  i18nRootKey: 'validations', // the nested key under which the validation messages will be located
  inject: true,
  locale: 'en',
  validity: false,
  useConstraintAttrs: true
});
Vue.use(VueAxios, axios)
Vue.use(VueLocalStorage);
Vue.use(VueClosable);
Vue.component('dropdownMenu', dropdownMenu);
Vue.config.productionTip = false
export const eventBus = new Vue();



Vue.filter('validate', function (value) {
  if (value && value != "null" && value != '' && value != 'undefined' && value != 'Invalid date') {
    return value
  } else {
    return "";
  }
});

Vue.filter('formatDate', function (value) {
  if (value && value !== 'null' && value !== '' && value !== '0000-00-00' && value !== '0000-00-00 00:00:00') {
    try {
      return moment.utc(String(value)).local().format(' D MMM, YYYY')
    } catch (e) {
      return value
    }
  }

});
Vue.filter('formatDateTime', function (value) {
  if (value && value !== 'null' && value !== '' && value !== '0000-00-00') {
    try {
      return moment.utc(String(value)).local().format("D MMM. YYYY, h:mm A")
    } catch (e) {
      return value
    }
  }
});
Vue.filter('formatTime', function (value) {
  if (value && value !== 'null' && value !== '' && value !== '0000-00-00') {
    try {
      return moment.utc(String(value)).local().format(' HH:mm')
    } catch (e) {
      return value
    }
  }
});
Vue.filter('best', function (value) {
  if (value && value !== 'null' && value !== '') {
    try {
      return moment.utc(String(value)).local().from(new Date());
    } catch (e) {
      return value
    }
  }
});

window.validatePayloadData = (payload) => {
  let company_id = store.state.company_id;

  if (payload) {
    if (payload instanceof FormData) {
      payload.append('crm_company_id', company_id)
    }
    Vue.set(
      payload,
      "crm_company_id",
      company_id
    );
    return payload;
  } else {
    payload = {};
    Vue.set(
      payload,
      "crm_company_id",
      company_id
    );
    return payload;
  }

};



axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  /*
     When the server responds with UnAuthorized access error,
     take back the user to the login page
  */
  console.log(error)
  try {
    if (error.response.status === 403) {
      store.commit("RESET", {})
      Vue.localStorage.remove("logged_user_data");
      router.push({ name: 'login' })
    }
  } catch (e) {
    console.log(e)
  }
  return Promise.reject(error);
});


let handleOutsideClick = null;

Vue.directive('closable', {
  bind(el, binding, vnode) {
    let handleOutsideClick = (e) => {
      e.stopPropagation()
      const { handler, exclude } = binding.value
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          if (vnode.context.$refs[refName]) {
            const excludedEl = vnode.context.$refs[refName]
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        }
      })
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]()
      }
    }
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },

  unbind() {
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})

router.beforeEach(
  (to, from, next) => {

    if (!store.state.isNavigationBackDirection) {
      store.dispatch("saveNavigationHistory", {
        name: from.name,
        path: from.fullPath
      });
    }


    store.commit("SET_NAVIGATION_DIRECTION", false);
    if (to.matched.some(record => record.meta.requiresAuth) && sessionStorage.isLogedIn !== "true") {
      window.parent.postMessage({ message: "logoutUser", value: "" }, "*");
    } else {
      if (!to.query.cancel_parent_event) {
        window.parent.postMessage({ message: "crmNavigation", value: to.path }, "*");
      }
      next();
    }
  }
)

new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')