<template>
  <div class="page-modal">
    <!-- Single -->
    <widgetModal v-if="contact_form.save_type == 'single'" :title="content ? 'Contact Information' : 'Add Contact'"
      :subtitle="content
        ? 'You can edit information directly from this form'
        : 'Fillin information to add a new account'
        " :btnlefttext="content ? 'Save Changes' : 'Save Contact'" btncentertext="Cancel"
      btnrighttext="Add Multiple Contacts" :leftloading="adding_contact_loading" :rightloading="false"
      :centerloading="false" :footerhidden="false" :hiderightbtn="true" footer_padding="10px 18px 30px 30px"
      header_padding="30px 0px 0px 31px" body_padding="0px 30px" width="500px" @onLeftClick="submitAccountData()"
      @onCenterClick="onCancel" @onRightClick="onRightClick" :loading="isLoadingData">
      <div class="widget-modal-body-noscoll">

        <div class="row">
          <widgetInputWrapper title="Account Name">
            <widgetAccountInput :miltiple="false" v-model="accounts" :readonly="false" :_new="false" type="text"
              placeholder="" @onNewAccount="(payload) => { }"></widgetAccountInput>
          </widgetInputWrapper>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Domain">
            <widgetInput v-model="contact_form.domain" type="text" placeholder=""></widgetInput>
          </widgetInputWrapper>
        </div>

        <div class="row">
          <widgetInputWrapper title="Website">
            <widgetInput v-model="contact_form.website" type="text" placeholder=""></widgetInput>
          </widgetInputWrapper>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Work Type">
            <widgetSelect v-model="contact_form.worktype" :opt="[
              { label: 'Flexible', value: 'flexible' },
              { label: 'Hybrid', value: 'hybrid' },
              { label: 'Remote', value: 'remote' },
              { label: 'On Site', value: 'onsite' },
            ]" placeholder="Select work Type"></widgetSelect>
          </widgetInputWrapper>
        </div>

        <div class="row">
          <widgetInputWrapper title="Location">
            <map-helper :isMultiple="true" v-model="contact_form.location"></map-helper>
          </widgetInputWrapper>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Position">
            <widgetInput v-model="contact_form.position" type="text" placeholder=""></widgetInput>
          </widgetInputWrapper>
        </div>

        <div class="row">
          <widgetInputWrapper title="Contact Owner">
            <searchSelectInput url="/company/search/manager" :miltiple="true" type="site" v-model="assigned"
              placeholder="Search account owner">
              <template v-slot:result="{ result }">
                <span class="search-contact-item"><img width="20" v-if="result.profile_picture"
                    :src="$fullUrl('images/profiles/' + result.profile_picture)" />
                  <img v-else width="20" src="@/assets/avatar.png" />
                  {{ result.first_name | validate }}
                  {{ result.last_name | validate }}</span>
              </template>
              <template v-slot:selected="{ selected }">
                <span v-if="selected" class="selected-search-contact-item">
                  <img width="20" v-if="selected.profile_picture"
                    :src="$fullUrl('images/profiles/' + selected.profile_picture)" />
                  <img v-else width="20" src="@/assets/avatar.png" />
                  {{ selected.first_name | validate }}</span>
              </template>
            </searchSelectInput>
          </widgetInputWrapper>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Schools">
            <widgetMiltSelect v-model="schools_input" placeholder="Select Schools" selectedholder="Selected Schools">
            </widgetMiltSelect>
          </widgetInputWrapper>
        </div>


        <div class="row">
          <widgetInputWrapper title="First Name*">
            <widgetInput type="text" v-model="contact_form.first_name" placeholder=""></widgetInput>
          </widgetInputWrapper>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Last Name*">
            <widgetInput type="email" v-model="contact_form.last_name" placeholder=""></widgetInput>
          </widgetInputWrapper>
        </div>

        <div class="row">
          <widgetInputWrapper title="Phone">
            <widgetInput type="text" v-model="contact_form.phone_alt" placeholder=""></widgetInput>
          </widgetInputWrapper>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Email">
            <widgetInput type="email" v-model="contact_form.email" placeholder=""></widgetInput>
          </widgetInputWrapper>
        </div>

        <div class="row">
          <widgetInputWrapper title="LIA Title">
            <widgetInput type="email" v-model="contact_form.title" placeholder=""></widgetInput>
          </widgetInputWrapper>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Student Contact">
            <widgetInput type="text" v-model="contact_form.public_contact" placeholder=""></widgetInput>
          </widgetInputWrapper>
        </div>

        <widgetInputWrapper title="Contact Tags">
          <widgetTags v-model="tags" input_width="100%" placeholder="Add tags"></widgetTags>
        </widgetInputWrapper>

        <widgetInputWrapper v-if="acccountType == 'account_contact'" title="Roles">
          <widgetTags v-model="roles" input_width="100%" placeholder="Add role"></widgetTags>
        </widgetInputWrapper>


        <div class="row align-start">
          <widgetInputWrapper title="Description">
            <widgetTextarea v-model="contact_form.description" placeholder=""></widgetTextarea>
          </widgetInputWrapper>
        </div>
      </div>
    </widgetModal>
    <!-- Many -->
    <widgetModal v-if="contact_form.save_type == 'many'" :footerhidden="true" :headerhidden="true" body_padding="0px 0px"
      width="550px">
      <uploadManyItems file="https://storage.googleapis.com/consultified_media/files/templates/AccountTemplate2.xlsx"
        title="Upload Contacts" description="To upload many contacts as file, you need to download the .xl file
              template here. Add contacts to the file and then repload it in the
              next step." actiontext="Upload below the filled document with contacts"
        @back="contact_form.save_type = 'single'" @onFile="(file) => {
          if (file) {
            contact_form.selected_file = file;
          }
        }
          " :loading="adding_contact_loading" @onSubmit="submitAccountData()"></uploadManyItems>
    </widgetModal>
  </div>
</template>
<script>
import { eventBus } from "@/main";
export default {
  name: "page-modal",
  props: {
    content: {
      type: Object,
      default: () => null,
    },
    acccountType: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      schools_input: [
      ],
      contact_form: {
        default: false,
        save_type: "single",
        contact_id: null,
        account_id: null,
        first_name: "",
        last_name: "",
        description: "",
        comment: "",
        title: "",
        email: "",
        phone_alt: "",
        phone_work: "",
        tags: [],
        tags_added: [],
        tags_removed: [],
        roles: [],
        roles_added: [],
        roles_removed: [],
        selected_file: null,
        website: "",
        worktype: "",
        location: [],
        contact_type: "none",
        public_contact: "",
        schools: [],
        contact_owner: null,
        domain: "",
        position: "",
        school_contact: "",
      },
      tags: {
        added: [],
        removed: [],
      },
      assigned: {
        added: [],
        removed: [],
      },
      roles: {
        added: [],
        removed: [],
      },
      accounts: null,
      adding_contact_loading: false,
      isLoadingData: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    let vm = this;
    vm.getSchools();
  },
  methods: {
    getSchools() {
      let vm = this;
      vm.isLoadingData = true;
      vm.$store
        .dispatch("SCHOOL_DROPDOWN_ALL_CLASS")
        .then((response) => {
          vm.isLoadingData = false;
          vm.schools_input = response.data.map((item, index) => {
            return {
              name: item.name,
              value: item.id,
              checked: false,
            };
          });
          if (vm.content) {
            vm.updateContent(vm.content);
          } else {
            vm.addNew();
          }
        })
        .catch((error) => {
          vm.isLoadingData = false;
        });
    },
    updateContent(item) {
      let vm = this;
      vm.contact_form.save_type = "single";
      vm.accounts = item.account;
      if (item.id) {
        console.log(item.account);
        vm.contact_form.contact_id = item.id;
        vm.contact_form.account_id = item.account_id;
        vm.contact_form.first_name = item.first_name;
        vm.contact_form.last_name = item.last_name;
        vm.contact_form.description = item.description;
        vm.contact_form.comment = item.comment;
        vm.contact_form.title = item.title;
        vm.contact_form.email = item.email;
        vm.contact_form.phone_alt = item.phone_alt;
        vm.contact_form.phone_work = item.phone_work;
        vm.contact_form.default = item.default;
        vm.contact_form.website = item.website;
        vm.contact_form.worktype = item.worktype ? item.worktype.toLowerCase() : "";
        vm.contact_form.public_contact = item.public_contact;
        vm.contact_form.schools = item.schools;
        vm.contact_form.contact_type = vm.acccountType;
        vm.contact_form.domain = item.domain;
        vm.contact_form.position = item.position;

        vm.schools_input.forEach((item) => {
          if (vm.contact_form.schools.find((school) => school.id == item.value)) {
            item.checked = true;
          }
        });

        // Check if location is array
        if (Array.isArray(item.location)) {
          vm.contact_form.location = item.location;
        } else {
          vm.contact_form.location = [item.location];
        }
        vm.contact_form.contact_type = item.contact_type;


        vm.assigned = {
          added: [],
          removed: [],
        };

        vm.contact_form.contact_owner = item.owner;
        if (item.owner) {
          vm.assigned.added.push(item.owner);
        }

        vm.tags = {
          added: [],
          removed: [],
        };
        item.tags.forEach((item) => {
          eventBus.$set(item, "on_update", true);
          vm.tags.added.push(item);
        });


        vm.roles = {
          added: [],
          removed: [],
        };
        item.roles.forEach((item) => {
          eventBus.$set(item, "on_update", true);
          vm.roles.added.push(item);
        });

      } else {
        vm.addNew();
      }



    },
    addNew() {
      let vm = this;
      vm.show_form_add_update = true;
      vm.accounts = null;
      vm.tags = {
        added: [],
        removed: [],
      };
      vm.contact_form = {
        default: false,
        save_type: "single",
        contact_id: null,
        account_id: null,
        first_name: "",
        last_name: "",
        description: "",
        comment: "",
        title: "",
        email: "",
        phone_alt: "",
        phone_work: "",
        tags: [],
        tags_added: [],
        tags_removed: [],
        roles: [],
        roles_added: [],
        roles_removed: [],
        selected_file: null,
        website: "",
        worktype: "",
        location: [],
        contact_type: vm.acccountType,
        public_contact: "",
        schools: [],
        contact_owner: null,
        domain: "",
        position: "",
        school_contact: "",
      };
    },
    onRightClick() {
      let vm = this;
      vm.contact_form.save_type = "many";
    },
    submitAccountData() {
      let vm = this;
      vm.contact_form.tags = vm.tags.added.map((item) => {
        return item;
      });

      vm.contact_form.roles = vm.roles.added.map((item) => {
        return item;
      });

      vm.contact_form.schools = vm.schools_input.filter((item) => {
        return item.checked;
      }).map((item) => {
        return {
          id: item.value,
          name: item.name
        };
      });

      if (vm.assigned.added.length > 0) {
        vm.contact_form.contact_owner = vm.assigned.added[0];
      }

      let form_data = new FormData();
      let company_id = vm.$getLoggedUser().company_id;
      form_data.append("crm_company_id", company_id);
      let owner = {
        name: vm.$getLoggedUser().manager_name,
        profile_image: vm.$getLoggedUser().manager_picture,
      };
      form_data.append("owner", JSON.stringify(owner));
      form_data.append("save_type", vm.contact_form.save_type);

      if (vm.accounts) {
        vm.contact_form.account_id = vm.accounts.id;
        form_data.append("account_id", vm.contact_form.account_id);
      }

      form_data.append("contact_id", vm.contact_form.contact_id);
      form_data.append("first_name", vm.contact_form.first_name);
      form_data.append("last_name", vm.contact_form.last_name);
      form_data.append("description", vm.contact_form.description);
      form_data.append("comment", vm.contact_form.comment);
      form_data.append("title", vm.contact_form.title);
      form_data.append("email", vm.contact_form.email);
      form_data.append("phone_alt", vm.contact_form.phone_alt);
      form_data.append("phone_work", vm.contact_form.phone_work);
      form_data.append("tags", JSON.stringify(vm.contact_form.tags));
      form_data.append("roles", JSON.stringify(vm.contact_form.roles));
      form_data.append("save_type", vm.contact_form.save_type);
      form_data.append("default", vm.contact_form.default);
      form_data.append("website", vm.contact_form.website);
      form_data.append("worktype", vm.contact_form.worktype);
      form_data.append("location", JSON.stringify(vm.contact_form.location));
      form_data.append("contact_type", vm.contact_form.contact_type);
      form_data.append("public_contact", vm.contact_form.public_contact);
      form_data.append("schools", JSON.stringify(vm.contact_form.schools));
      form_data.append("contact_owner", JSON.stringify(vm.contact_form.contact_owner));
      form_data.append("domain", vm.contact_form.domain);
      form_data.append("position", vm.contact_form.position);

      for (var pair of form_data.entries()) {
        let formDataKey = pair[0];
        let formDataValue = pair[1];
        if (formDataValue == "null") {
          form_data.set(formDataKey, "");
        }
      }

      if (!vm.contact_form.contact_id) {
        if (vm.contact_form.save_type == "single") {
          const rules = {
            first_name: {
              run: (value) => {
                if (vm.contact_form.save_type == "single") {
                  if (value) {
                    return false;
                  }
                  return "First name can not be empty";
                }
                return false;
              },
            },
            account_id: {
              run: (value) => {
                if (vm.accounts) {
                  return false;
                }
                return "Please select account";
              },
            },
          };
          if (vm.$isFieldsValidated(vm.contact_form, rules)) {
            vm.adding_contact_loading = true;
            vm.$store
              .dispatch("ACCOUNT_CONTACT_ADD_NEW", form_data)
              .then((response) => {
                vm.adding_contact_loading = false;
                vm.$notify({
                  group: "status",
                  title: "Success",
                  text: "Contact is now added",
                  type: "success",
                  duration: 3000,
                });
                vm.$emit("success", null);
              })
              .catch((error) => {
                console.error({ error });
                vm.adding_contact_loading = false;
                vm.$notify({
                  group: "status",
                  title: "Success",
                  text: "Something went wrong, try again",
                  type: "warn",
                  duration: 3000,
                });
              });
          }
        } else {
          if (vm.contact_form.selected_file) {
            form_data.append(
              "file",
              vm.contact_form.selected_file,
              vm.contact_form.selected_file.name
            );
            vm.adding_contact_loading = true;
            vm.$store
              .dispatch("ACCOUNT_CONTACT_ADD_NEW", form_data)
              .then((response) => {
                vm.adding_contact_loading = false;
                vm.$notify({
                  group: "status",
                  title: "Success",
                  text: "Contact is now added",
                  type: "success",
                  duration: 3000,
                });
                vm.$emit("success", null);
              })
              .catch((error) => {
                console.error({ error });
                vm.adding_contact_loading = false;
                vm.$notify({
                  group: "status",
                  title: "Success",
                  text: "Something went wrong, try again",
                  type: "warn",
                  duration: 3000,
                });
              });
          }
        }

        // Updating contact
      } else {

        // For roles
        vm.contact_form.roles_added = vm.roles.added
          .filter((item) => !item.on_update)
          .map((item) => {
            return item;
          });
        vm.contact_form.roles_removed = vm.roles.removed.map((item) => {
          return item;
        });
        form_data.append(
          "roles_added",
          JSON.stringify(vm.contact_form.roles_added)
        );
        form_data.append(
          "roles_removed",
          JSON.stringify(vm.contact_form.roles_removed)
        );

        // For tags
        vm.contact_form.tags_added = vm.tags.added
          .filter((item) => !item.on_update)
          .map((item) => {
            return item;
          });
        vm.contact_form.tags_removed = vm.tags.removed.map((item) => {
          return item;
        });
        form_data.append(
          "tags_added",
          JSON.stringify(vm.contact_form.tags_added)
        );
        form_data.append(
          "tags_removed",
          JSON.stringify(vm.contact_form.tags_removed)
        );
        vm.adding_contact_loading = true;
        vm.$store
          .dispatch("ACCOUNT_CONTACT_UPDATE", form_data)
          .then((response) => {
            vm.adding_contact_loading = false;
            vm.$notify({
              group: "status",
              title: "Success",
              text: "Contact is now updated",
              type: "success",
              duration: 3000,
            });
            vm.$emit("success", null);
          })
          .catch((error) => {
            console.error({ error });
            vm.adding_contact_loading = false;
            vm.$notify({
              group: "status",
              title: "Success",
              text: "Something went wrong, try again",
              type: "warn",
              duration: 3000,
            });
          });
      }
    },
    onCancel() {
      let vm = this;
      vm.$emit("close", null);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.page-modal {
  .widget-modal-body-noscoll {
    position: relative;

    .top-floating-option {
      position: absolute;
      top: -53px;
      right: 0px;
      display: flex;
      align-items: center;
    }
  }
}
</style>