<template>
  <div class="wrapper">
    <div class="milt-select-widget" ref="milt_select_widget_trigger" @click="shop_pop_up = !shop_pop_up">
      <label v-if="checkedItems.length > 0">{{ checkedItems.length | validate }} {{ selectedholder }}</label>
      <label v-else>{{ placeholder }}</label>
      <img src="@/assets/admin-icon/allow-down.svg" />
    </div>
    <div class="select-items-widget" ref="milt_select_widget" v-show="shop_pop_up" v-closable="{
      exclude: ['milt_select_widget_trigger', 'milt_select_widget'],
      handler: 'hidePopUp',
    }">
      <div class="placeholder-tile">{{ placeholder }}</div>
      <div class="selection-list">
        <div class="select-item">
          <input type="checkbox" @change="selectAll" value="All" id="_all" />
          <label for="_all">All</label>
        </div>
        <div class="select-item" v-for="(item, index) in value" :key="index">
          <input type="checkbox" @change="onChange" v-model="item.checked" :id="`id_${item.value}`" />
          <label :for="`id_${item.value}`">{{ item.name | validate }}</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "milt-select-widget",
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    selectedholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      shop_pop_up: false,
    };
  },
  computed: {
    checkedItems() {
      return this.value.filter((item) => item.checked);
    },
  },
  watch: {},
  mounted() { },
  methods: {
    hidePopUp() {
      let vm = this;
      vm.shop_pop_up = false;
    },
    onChange({ target }) {
      let vm = this;
      vm.$emit("onSubmit", vm.value);
      vm.$emit("input", vm.value);
    },
    selectAll({ target }) {
      let vm = this;
      if (target.checked) {
        vm.value.forEach((item) => {
          item.checked = true;
        });
      } else {
        vm.value.forEach((item) => {
          item.checked = false;
        });
      }
      vm.$emit("onSubmit", vm.value);
      vm.$emit("input", vm.value);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.wrapper {
  position: relative;
  width: 100%;
  display: flex;

  .milt-select-widget {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px;
  }

  .select-items-widget {
    position: absolute;
    top: 30px;
    left: 0px;
    right: 0px;
    background: #ffffff;
    border: 1px solid #f2f4f8;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    max-height: 400px;
    z-index: 99999;

    .placeholder-tile {
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0px 15px;
      border-bottom: 1px solid #f2f4f8;
      font-weight: 600;
    }

    .selection-list {
      height: calc(100% - 50px);
      overflow-y: auto;

      .select-item {
        padding: 8px 15px;
        display: flex;
        align-items: center;

        label {
          margin-top: 2px;
          margin-left: 7px;
        }
      }
    }
  }
}
</style>