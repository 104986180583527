import {
    API,
    SITE_API,
    INTERNSHIP_FILTER_BY_TAG_URL,
    INTERNSHIP_GET_TAGS_URL,
    INTERNSHIP_FILTER_BY_LOCATION_URL,
    INTERNSHIP_EXPORT_INTERNAL_LIA_URL,
    INTERNSHIP_MOVE_TO_ARCHIVE_URL,
    INTERNSHIP_DELETE_SELECTED_LIA_URL,
    INTERNSHIP_MOVE_ARCHIVE_SELECTED_LIA_URL,
    INTERNSHIP_SAVE_PRIVATE_INTERNSHIP_URL,
    INTERNSHIP_ASSIGN_INTERNSHIP_URL,
    INSTRUCTOR_GET_PRIVATE_INTERNSHIP_URL,
    INTERNSHIP_UPDATE_PRIVATE_INTERNSHIP_URL,
} from "../variable";

import axios from 'axios'
import {
    INTERNSHIP_FILTER_BY_TAG,
    INTERNSHIP_GET_TAGS,
    INTERNSHIP_FILTER_BY_LOCATION,
    INTERNSHIP_EXPORT_INTERNAL_LIA,
    INTERNSHIP_MOVE_TO_ARCHIVE,
    INTERNSHIP_DELETE_SELECTED_LIA,
    INTERNSHIP_MOVE_ARCHIVE_SELECTED_LIA,
    INTERNSHIP_SAVE_PRIVATE_INTERNSHIP,
    INTERNSHIP_ASSIGN_INTERNSHIP,
    INSTRUCTOR_GET_PRIVATE_INTERNSHIP,
    INTERNSHIP_UPDATE_PRIVATE_INTERNSHIP
} from "../Actions/internship";

const state = {

};

const actions = {
    [INTERNSHIP_UPDATE_PRIVATE_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INTERNSHIP_UPDATE_PRIVATE_INTERNSHIP_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            },
        });
    },
    [INSTRUCTOR_GET_PRIVATE_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_GET_PRIVATE_INTERNSHIP_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            },
        });
    },
    [INTERNSHIP_ASSIGN_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INTERNSHIP_ASSIGN_INTERNSHIP_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            },

        });
    },
    [INTERNSHIP_SAVE_PRIVATE_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INTERNSHIP_SAVE_PRIVATE_INTERNSHIP_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            },
        });
    },
    [INTERNSHIP_MOVE_ARCHIVE_SELECTED_LIA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INTERNSHIP_MOVE_ARCHIVE_SELECTED_LIA_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            },
        });
    },
    [INTERNSHIP_DELETE_SELECTED_LIA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INTERNSHIP_DELETE_SELECTED_LIA_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            },
        });
    },
    [INTERNSHIP_MOVE_TO_ARCHIVE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INTERNSHIP_MOVE_TO_ARCHIVE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            },
        });
    },
    [INTERNSHIP_EXPORT_INTERNAL_LIA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INTERNSHIP_EXPORT_INTERNAL_LIA_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            },
            responseType: 'blob',
        });
    },
    [INTERNSHIP_FILTER_BY_TAG]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == 'init' ? SITE_API + getters.get_crm_prefix + INTERNSHIP_FILTER_BY_TAG_URL : payload.next_page_url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [INTERNSHIP_FILTER_BY_LOCATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == 'init' ? SITE_API + getters.get_crm_prefix + INTERNSHIP_FILTER_BY_LOCATION_URL : payload.next_page_url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
    [INTERNSHIP_GET_TAGS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INTERNSHIP_GET_TAGS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });

    },
};

const mutations = {};

const getters = {};

export default {
    state,
    getters,
    actions,
    mutations
};