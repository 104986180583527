import {
    NOTIFICATION_MARK_ALL_AS_READ,
    COMMUNICATION_GET_QUESTION_ANSWERS,
    COMMUNICATION_GET_QUESTIONAIRE,
    PROCESSMESSAGE_SURVEY_REMIND,
    COMMUNICATION_LOCK_QUESTIONAIRE,
    COMMUNICATION_COMPLETED_QUESTIONAIRE,
    COMMUNICATION_NOT_STARTED_QUESTIONAIRE,
    COMMUNICATION_VIEW_QUESTIONAIRE_RESULT,
    COMMUNICATION_EMAIL_GET_TEMPLATES,
    COMMUNICATION_EMAIL_SEARCH_TEMPLATES,
    COMMUNICATION_NOTIFICATION_SEARCH_TEMPLATES,
    COMMUNICATION_NOTIFICATION_GET_TEMPLATES,
    COMMUNICATION_QUESTIONAIRE_SEARCH_TEMPLATES,
    COMMUNICATION_QUESTIONAIRE_GET_TEMPLATES,
    COMMUNICATION_EMAIL_SAVE_TEMPLATE,
    COMMUNICATION_EMAIL_UPDATE_TEMPLATE,
    COMMUNICATION_QUESTIONAIRE_SAVE_TEMPLATE,
    COMMUNICATION_NOTIFICATION_SAVE_TEMPLATE,
    COMMUNICATION_NOTIFICATION_SEND_NOTIFICATION,
    COMMUNICATION_NOTIFICATION_DELETE_TEMPLATE,
    COMMUNICATION_QUESTIONAIRE_DELETE_TEMPLATE,
    COMMUNICATION_EMAIL_DELETE_TEMPLATE,
    COMMUNICATION_NOTIFICATION_UPDATE_TEMPLATE,
    COMMUNICATION_QUESTIONAIRE_UPDATE_TEMPLATE,
    RELEASE_ADD_NEW,
    RELEASE_UPDATE,
    RELEASE_DELETE,
    RELEASE_GET_ALL
} from "../Actions/communication";

import {
    API,
    SITE_API,
    NOTIFICATION_MARK_ALL_AS_READ_URL,
    COMMUNICATION_GET_QUESTION_ANSWERS_URL,
    COMMUNICATION_GET_QUESTIONAIRE_URL,
    PROCESSMESSAGE_SURVEY_REMIND_URL,
    COMMUNICATION_LOCK_QUESTIONAIRE_URL,
    COMMUNICATION_COMPLETED_QUESTIONAIRE_URL,
    COMMUNICATION_NOT_STARTED_QUESTIONAIRE_URL,
    COMMUNICATION_VIEW_QUESTIONAIRE_RESULT_URL,
    COMMUNICATION_EMAIL_GET_TEMPLATES_URL,
    COMMUNICATION_EMAIL_SEARCH_TEMPLATES_URL,
    COMMUNICATION_NOTIFICATION_SEARCH_TEMPLATES_URL,
    COMMUNICATION_NOTIFICATION_GET_TEMPLATES_URL,
    COMMUNICATION_QUESTIONAIRE_SEARCH_TEMPLATES_URL,
    COMMUNICATION_QUESTIONAIRE_GET_TEMPLATES_URL,
    COMMUNICATION_EMAIL_SAVE_TEMPLATE_URL,
    COMMUNICATION_EMAIL_UPDATE_TEMPLATE_URL,
    COMMUNICATION_QUESTIONAIRE_SAVE_TEMPLATE_URL,
    COMMUNICATION_NOTIFICATION_SAVE_TEMPLATE_URL,
    COMMUNICATION_NOTIFICATION_SEND_NOTIFICATION_URL,
    COMMUNICATION_NOTIFICATION_DELETE_TEMPLATE_URL,
    COMMUNICATION_QUESTIONAIRE_DELETE_TEMPLATE_URL,
    COMMUNICATION_EMAIL_DELETE_TEMPLATE_URL,
    COMMUNICATION_NOTIFICATION_UPDATE_TEMPLATE_URL,
    COMMUNICATION_QUESTIONAIRE_UPDATE_TEMPLATE_URL,
    RELEASE_ADD_NEW_URL,
    RELEASE_UPDATE_URL,
    RELEASE_DELETE_URL,
    RELEASE_GET_ALL_URL,
} from "../variable";

import axios from "axios";

const state = {
};

const mutations = {

};

const actions = {
    [RELEASE_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.type == "student" ? SITE_API + RELEASE_ADD_NEW_URL : API + RELEASE_ADD_NEW_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: payload.type == "student" ? "Bearer " + sessionStorage.AuthToken : "Bearer " + sessionStorage.AuthToken }
        });
    },
    [RELEASE_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.type == "student" ? SITE_API + RELEASE_UPDATE_URL : API + RELEASE_UPDATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: payload.type == "student" ? "Bearer " + sessionStorage.AuthToken : "Bearer " + sessionStorage.AuthToken }
        });
    },
    [RELEASE_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.type == "student" ? SITE_API + RELEASE_DELETE_URL : API + RELEASE_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: payload.type == "student" ? "Bearer " + sessionStorage.AuthToken : "Bearer " + sessionStorage.AuthToken }
        });
    },
    [RELEASE_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.type == "student" ? SITE_API + RELEASE_GET_ALL_URL : API + RELEASE_GET_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: payload.type == "student" ? "Bearer " + sessionStorage.AuthToken : "Bearer " + sessionStorage.AuthToken }
        });
    },
    [COMMUNICATION_NOTIFICATION_UPDATE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_NOTIFICATION_UPDATE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_QUESTIONAIRE_UPDATE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_QUESTIONAIRE_UPDATE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_NOTIFICATION_DELETE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_NOTIFICATION_DELETE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_QUESTIONAIRE_DELETE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_QUESTIONAIRE_DELETE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_EMAIL_DELETE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_EMAIL_DELETE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_NOTIFICATION_SAVE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_NOTIFICATION_SAVE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_NOTIFICATION_SEND_NOTIFICATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_NOTIFICATION_SEND_NOTIFICATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_QUESTIONAIRE_SAVE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_QUESTIONAIRE_SAVE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_EMAIL_SAVE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_EMAIL_SAVE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_EMAIL_UPDATE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_EMAIL_UPDATE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_NOTIFICATION_SEARCH_TEMPLATES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_NOTIFICATION_SEARCH_TEMPLATES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_NOTIFICATION_GET_TEMPLATES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_NOTIFICATION_GET_TEMPLATES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_QUESTIONAIRE_SEARCH_TEMPLATES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_QUESTIONAIRE_SEARCH_TEMPLATES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_QUESTIONAIRE_GET_TEMPLATES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_QUESTIONAIRE_GET_TEMPLATES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_EMAIL_SEARCH_TEMPLATES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_EMAIL_SEARCH_TEMPLATES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_EMAIL_GET_TEMPLATES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_EMAIL_GET_TEMPLATES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [PROCESSMESSAGE_SURVEY_REMIND]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + PROCESSMESSAGE_SURVEY_REMIND_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_GET_QUESTIONAIRE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_GET_QUESTIONAIRE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_GET_QUESTION_ANSWERS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_GET_QUESTION_ANSWERS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [NOTIFICATION_MARK_ALL_AS_READ]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + NOTIFICATION_MARK_ALL_AS_READ_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_LOCK_QUESTIONAIRE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_LOCK_QUESTIONAIRE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_COMPLETED_QUESTIONAIRE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_COMPLETED_QUESTIONAIRE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_NOT_STARTED_QUESTIONAIRE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_NOT_STARTED_QUESTIONAIRE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [COMMUNICATION_VIEW_QUESTIONAIRE_RESULT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + COMMUNICATION_VIEW_QUESTIONAIRE_RESULT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    }
};

const getters = {

};

export default {
    mutations,
    actions,
    getters,
    state
};