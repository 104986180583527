<template>
  <div id="app">
    <router-view />

    <!-- Notification widget -->
    <notifications group="status" animation-type="velocity" position="bottom right" :duration="5000">
      <template slot="body" slot-scope="props">
        <div @click="props.close" :class="'notification-template __' + props.item.type">
          <div class="icon-widget">
            <img v-if="props.item.type == 'success'" src="@/assets/notification-icon/success.svg" />
            <img v-if="props.item.type == 'warn'" src="@/assets/notification-icon/warning.svg" />
          </div>
          <div class="not-content">
            <h3 class="title">
              {{ props.item.title }}
            </h3>
            <div class="descr" v-html="props.item.text"></div>
          </div>
        </div>
      </template>
    </notifications>

    <vue-confirm-dialog class="delete-consultified-dialog" :class="$store.state.dialog_class"></vue-confirm-dialog>
    <!-- End of dialog -->

    <!-- App dialog -->
    <dialogActionMessage></dialogActionMessage>


  </div>
</template>


<script>
// @ is an alias to /src
export default {
  name: 'app',
  components: {

  }
}
</script>

<style lang="scss">
@import "~@/styles/style.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 12px;
}

.delete-consultified-dialog {
  .vc-container {
    width: 486px;
    border-radius: 5px;
    padding: 25px 0px;

    .vc-title {}

    .vc-text {
      padding-top: 10px;
    }

    .vc-btn-grid {
      padding: 10px 60px;
      grid-column-gap: 40px;

      .vc-btn {
        border: none;
        min-height: 40px !important;
        border-radius: 5px;

        &.left {
          background: #620000;
          color: #fff;
        }

        &:nth-child(2) {
          color: #808080;
        }
      }
    }
  }

  &.approve-widget {
    .vc-container {
      .vc-btn-grid {
        .vc-btn {
          border: none;
          min-height: 40px !important;
          border-radius: 5px;

          &.left {
            background: #1ba5a4 !important;
            color: #fff;
          }

          &:nth-child(2) {
            color: #808080;
          }
        }
      }
    }
  }
}

button {
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
}

input[type="text"] {
  cursor: pointer;
  outline: none;
  border: none;
  height: 25px;
}

input[type="button"] {
  cursor: pointer;
  outline: none;
  border: none;
}

.pac-container {
  z-index: 99999999999999999 !important;
}

.vue-notification-group {
  z-index: 99999999999999999 !important;
  right: 10px !important;
  bottom: 65px !important;

  .vue-notification-wrapper {
    height: unset !important;
    margin-bottom: 20px;
    box-shadow: 7px 2px 20px 2px #777777;
    border-radius: 10px;

    .notification-template {
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 20px 20px;
      box-shadow: 0px 0px 8px #1b1b1b;

      &.__success {
        background: #ffffff;

        .title {
          color: #1ba5a4;
        }

        .descr {
          color: #000000;
        }
      }

      &.__warn {
        background: #f6f0cb;

        .title {
          color: #685a00;
        }

        .descr {
          color: #000000;
        }
      }

      .icon-widget {
        margin-right: 10px;

        img {
          width: 45px;
        }
      }

      .not-content {
        .title {
          margin-top: 0px;
          margin-bottom: 6px;
          font-size: 15px;
        }

        .descr {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
