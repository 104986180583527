<template>
  <div class="skills-widget">
    <div class="__box">
      <div class="skills-inner-tab __card b__20">
        <div class="inner-links">
          <a
            :class="{ button__active: skillsTabs.showSkillTab1 }"
            @click.prevent="showSkillsTab('showSkillTab1')"
            >Tools</a
          >
          <a
            :class="{ button__active: skillsTabs.showSkillTab2 }"
            @click.prevent="showSkillsTab('showSkillTab2')"
            >Domains</a
          >
          <a
            :class="{ button__active: skillsTabs.showSkillTab3 }"
            @click.prevent="showSkillsTab('showSkillTab3')"
            >Competences</a
          >
          <a
            :class="{ button__active: skillsTabs.showSkillTab4 }"
            @click.prevent="showSkillsTab('showSkillTab4')"
            >Roles</a
          >
          <a
            :class="{ button__active: skillsTabs.showSkillTab5 }"
            @click.prevent="showSkillsTab('showSkillTab5')"
            >Languages</a
          >
        </div>
        <!-- Tool -->
        <div class="inner-body" v-if="skillsTabs.showSkillTab1">
          <div class="adding-form">
            <div class="input-widget">
              <div class="top-label">
                <span class="skill-laber-name">Tool Name</span>
                <div class="active-input">
                  <label for="now1" class>Active</label>
                  <input type="checkbox" v-model="tool.status" id="now1" />
                  <help-tool-tip
                    position="top left"
                    text="Skill you have but don’t want to use when finding jobs"
                  />
                </div>
              </div>
              <div class="skill-input-group">
                <input
                  class="skill-input"
                  v-model="tool.name"
                  placeholder=" E.g: Hammer, Photoshop, Java"
                  autocomplete="off"
                  @focus="autoCompletesuggest = true"
                  @blur="closeSuggetion"
                  @keyup="getSuggetion(tool.name, 'tools')"
                />
                <div class="skill-options">
                  <a
                    :disabled="tool.name === ''"
                    class="skill-add-btn"
                    @click.prevent="
                      () => {
                        if (!checkIfSkillExist(skills.tools, tool.name)) {
                          skills.tools.push(tool);
                          tool = {
                            id: null,
                            name: '',
                            status: true,
                            level: 4,
                            type: 'tool',
                          };
                        }
                      }
                    "
                    >Add Tool</a
                  >
                </div>
              </div>
              <div
                class="search-suggetion"
                v-if="autoCompletesuggest && suggestion.tools.length > 0"
              >
                <span
                  class="item"
                  v-for="(item, index) in suggestion.tools"
                  :key="index + 'sug'"
                  @click="
                    () => {
                      tool.name = item;
                    }
                  "
                  >{{ item | validate }}</span
                >
              </div>
            </div>

            <div class="skill-rating-widget">
              <span class="skill-laber-name">Experience Level</span>
              <div class="stars-widget">
                <div class="start-input">
                  <star-rating
                    inactive-color="#C1C1C1"
                    v-model="tool.level"
                    :rating="tool.level"
                    active-color="#1BA5A4"
                    :star-size="15"
                    :padding="1"
                    :show-rating="false"
                  ></star-rating>
                </div>
              </div>
            </div>
          </div>
          <div class="__line" v-if="skills.tools.length > 0"></div>
          <div class="list-items">
            <div
              class="item"
              v-for="(tool, i) in skills.tools"
              :key="i + 'djhghj'"
            >
              <label>{{ tool.name | validate }}</label>
              <div class="start-input">
                <star-rating
                  inactive-color="#C1C1C1"
                  :read-only="true"
                  :rating="tool.level"
                  active-color="#1BA5A4"
                  :star-size="10"
                  :padding="1"
                  :show-rating="false"
                ></star-rating>
              </div>
              <img
                class="close"
                src="@/assets/icons/closee.svg"
                @click.prevent="
                  () => {
                    if (tool.id) {
                      skills.removedtools.push(tool.id);
                    }
                    skills.tools.splice(i, 1);
                  }
                "
              />
            </div>
          </div>
        </div>
        <!-- Domain -->
        <div class="inner-body" v-if="skillsTabs.showSkillTab2">
          <div class="adding-form">
            <div class="input-widget">
              <div class="top-label">
                <span class="skill-laber-name">Domain Name</span>
                <div class="active-input">
                  <label for="now2" class>Active</label>
                  <input type="checkbox" v-model="domain.status" id="now2" />
                  <help-tool-tip
                    position="top left"
                    text="Skill you have but don’t want to use when finding jobs"
                  />
                </div>
              </div>
              <div class="skill-input-group">
                <input
                  class="skill-input"
                  v-model="domain.name"
                  placeholder="E.g: Insurance, Banking, Education"
                  @focus="autoCompletesuggest = true"
                  @blur="closeSuggetion"
                  @keyup="getSuggetion(domain.name, 'domains')"
                />
                <div class="skill-options">
                  <a
                    :disabled="domain.name === ''"
                    class="skill-add-btn"
                    @click.prevent="
                      () => {
                        if (!checkIfSkillExist(skills.domains, domain.name)) {
                          skills.domains.push(domain);
                          domain = {
                            id: null,
                            name: '',
                            status: true,
                            level: 4,
                            type: 'domain',
                          };
                        }
                      }
                    "
                    >Add domain</a
                  >
                </div>
              </div>
              <div
                class="search-suggetion"
                v-if="autoCompletesuggest && suggestion.domains.length > 0"
              >
                <span
                  class="item"
                  v-for="(item, index) in suggestion.domains"
                  :key="index + 'sug'"
                  @click="
                    () => {
                      domain.name = item;
                    }
                  "
                  >{{ item | validate }}</span
                >
              </div>
            </div>

            <div class="skill-rating-widget">
              <span class="skill-laber-name">Experience Level</span>
              <div class="stars-widget">
                <div class="start-input">
                  <star-rating
                    inactive-color="#C1C1C1"
                    v-model="domain.level"
                    :rating="domain.level"
                    active-color="#1BA5A4"
                    :star-size="15"
                    :padding="1"
                    :show-rating="false"
                  ></star-rating>
                </div>
              </div>
            </div>
          </div>

          <div class="__line" v-if="skills.domains.length > 0"></div>
          <div class="list-items">
            <div
              class="item"
              v-for="(domain, i) in skills.domains"
              :key="i + 'dhjgf'"
            >
              <label>{{ domain.name | validate }}</label>
              <div class="start-input">
                <star-rating
                  inactive-color="#C1C1C1"
                  :rating="domain.level"
                  :read-only="true"
                  active-color="#1BA5A4"
                  :star-size="10"
                  :padding="1"
                  :show-rating="false"
                ></star-rating>
              </div>
              <img
                class="close"
                src="@/assets/icons/closee.svg"
                @click.prevent="
                  () => {
                    if (domain.id) {
                      skills.removeddomains.push(domain.id);
                    }
                    skills.domains.splice(i, 1);
                  }
                "
              />
            </div>
          </div>
        </div>
        <!-- Starting competence -->
        <div class="inner-body" v-if="skillsTabs.showSkillTab3">
          <div class="adding-form">
            <div class="input-widget">
              <div class="top-label">
                <span class="skill-laber-name">Competence Name</span>
                <div class="active-input">
                  <label for="now3" class>Active</label>
                  <input
                    type="checkbox"
                    v-model="competence.status"
                    id="now3"
                  />
                  <help-tool-tip
                    position="top left"
                    text="Skill you have but don’t want to use when finding jobs"
                  />
                </div>
              </div>
              <div class="skill-input-group">
                <input
                  class="skill-input"
                  v-model="competence.name"
                  placeholder="E.g: Leadership, Teamwork"
                  @focus="autoCompletesuggest = true"
                  @blur="closeSuggetion"
                  @keyup="getSuggetion(competence.name, 'competences')"
                />
                <div class="skill-options">
                  <a
                    :disabled="competence.name === ''"
                    class="skill-add-btn"
                    @click.prevent="
                      () => {
                        if (
                          !checkIfSkillExist(
                            skills.competences,
                            competence.name
                          )
                        ) {
                          skills.competences.push(competence);
                          competence = {
                            id: null,
                            name: '',
                            status: true,
                            level: 4,
                            type: 'competence',
                          };
                        }
                      }
                    "
                    >Add competence</a
                  >
                </div>
              </div>
              <div
                class="search-suggetion"
                v-if="autoCompletesuggest && suggestion.competences.length > 0"
              >
                <span
                  class="item"
                  v-for="(item, index) in suggestion.competences"
                  :key="index + 'sug'"
                  @click="
                    () => {
                      competence.name = item;
                    }
                  "
                  >{{ item | validate }}</span
                >
              </div>
            </div>

            <div class="skill-rating-widget">
              <span class="skill-laber-name">Experience Level</span>
              <div class="stars-widget">
                <div class="start-input">
                  <star-rating
                    inactive-color="#C1C1C1"
                    v-model="competence.level"
                    :rating="competence.level"
                    active-color="#1BA5A4"
                    :star-size="15"
                    :padding="1"
                    :show-rating="false"
                  ></star-rating>
                </div>
              </div>
            </div>
          </div>

          <div class="__line" v-if="skills.competences.length > 0"></div>
          <div class="list-items">
            <div
              class="item"
              v-for="(competence, i) in skills.competences"
              :key="i + 'dhghj'"
            >
              <label>{{ competence.name | validate }}</label>
              <div class="start-input">
                <star-rating
                  inactive-color="#C1C1C1"
                  :rating="competence.level"
                  :read-only="true"
                  active-color="#1BA5A4"
                  :star-size="10"
                  :padding="1"
                  :show-rating="false"
                ></star-rating>
              </div>
              <img
                class="close"
                src="@/assets/icons/closee.svg"
                @click.prevent="
                  () => {
                    if (competence.id) {
                      skills.removedcompetences.push(competence.id);
                    }
                    skills.competences.splice(i, 1);
                  }
                "
              />
            </div>
          </div>
        </div>
        <!-- Start role -->
        <div class="inner-body" v-if="skillsTabs.showSkillTab4">
          <div class="adding-form">
            <div class="input-widget">
              <div class="top-label">
                <span class="skill-laber-name">Role Name</span>
                <div class="active-input">
                  <label for="now4" class>Active</label>
                  <input type="checkbox" v-model="role.status" id="now4" />
                  <help-tool-tip
                    position="top left"
                    text="Skill you have but don’t want to use when finding jobs"
                  />
                </div>
              </div>
              <div class="skill-input-group">
                <input
                  class="skill-input"
                  v-model="role.name"
                  placeholder="E.g: CEO, Designer, Project Manager"
                  @focus="autoCompletesuggest = true"
                  @blur="closeSuggetion"
                  @keyup="getSuggetion(role.name, 'roles')"
                />
                <div class="skill-options">
                  <a
                    :disabled="role.name === ''"
                    class="skill-add-btn"
                    @click.prevent="
                      () => {
                        if (!checkIfSkillExist(skills.roles, role.name)) {
                          skills.roles.push(role);
                          role = {
                            id: null,
                            name: '',
                            status: true,
                            level: 4,
                            type: 'role',
                          };
                        }
                      }
                    "
                    >Add role</a
                  >
                </div>
              </div>
              <div
                class="search-suggetion"
                v-if="autoCompletesuggest && suggestion.roles.length > 0"
              >
                <span
                  class="item"
                  v-for="(item, index) in suggestion.roles"
                  :key="index + 'sug'"
                  @click="
                    () => {
                      role.name = item;
                    }
                  "
                  >{{ item | validate }}</span
                >
              </div>
            </div>

            <div class="skill-rating-widget">
              <span class="skill-laber-name">Experience Level</span>
              <div class="stars-widget">
                <div class="start-input">
                  <star-rating
                    inactive-color="#C1C1C1"
                    v-model="role.level"
                    :rating="role.level"
                    active-color="#1BA5A4"
                    :star-size="15"
                    :padding="1"
                    :show-rating="false"
                  ></star-rating>
                </div>
              </div>
            </div>
          </div>

          <div class="__line" v-if="skills.roles.length > 0"></div>
          <div class="list-items">
            <div
              class="item"
              v-for="(role, i) in skills.roles"
              :key="i + 'djjhj'"
            >
              <label>{{ role.name | validate }}</label>
              <div class="start-input">
                <star-rating
                  inactive-color="#C1C1C1"
                  :rating="role.level"
                  :read-only="true"
                  active-color="#1BA5A4"
                  :star-size="10"
                  :padding="1"
                  :show-rating="false"
                ></star-rating>
              </div>
              <img
                class="close"
                src="@/assets/icons/closee.svg"
                @click.prevent="
                  () => {
                    if (role.id) {
                      skills.removedroles.push(role.id);
                    }
                    skills.roles.splice(i, 1);
                  }
                "
              />
            </div>
          </div>
        </div>

        <!-- Start language -->
        <div class="inner-body" v-if="skillsTabs.showSkillTab5">
          <div class="adding-form">
            <div class="input-widget">
              <div class="top-label">
                <span class="skill-laber-name">Language Name</span>
                <div class="active-input">
                  <label for="now5" class>Active</label>
                  <input type="checkbox" v-model="language.status" id="now5" />
                  <help-tool-tip
                    position="top left"
                    text="Skill you have but don’t want to use when finding jobs"
                  />
                </div>
              </div>
              <div class="skill-input-group">
                <select v-model="language_item" class="skill-input">
                  <option :value="{ id: 0, name: '' }">Select Language</option>
                  <option
                    v-for="(lang, index) in getCountries.languages"
                    :value="lang"
                    :key="index + 'lang'"
                  >
                    {{ lang.name | validate }}
                  </option>
                </select>
                <div class="skill-options">
                  <a
                    :disabled="language_item.id === 0"
                    class="skill-add-btn"
                    @click.prevent="
                      () => {
                        if (
                          !checkIfSkillExist(skills.langs, language_item.name)
                        ) {
                          language.id = language_item.id;
                          language.name = language_item.name;
                          skills.langs.push(language);
                          language = {
                            id: null,
                            name: {},
                            status: true,
                            level: 4,
                            type: 'language',
                          };
                        }
                      }
                    "
                    >Add language</a
                  >
                </div>
              </div>
            </div>

            <div class="skill-rating-widget">
              <div class="skill-rating-label">
                <span class="skill-laber-name">Profiency Level</span>
                <help-tool-tip
                  position="top left"
                  src="../assets/language_profe_help.svg"
                />
              </div>
              <div class="stars-widget">
                <div class="start-input">
                  <star-rating
                    inactive-color="#C1C1C1"
                    v-model="language.level"
                    :rating="language.level"
                    active-color="#1BA5A4"
                    :star-size="15"
                    :padding="1"
                    :show-rating="false"
                  ></star-rating>
                </div>
              </div>
            </div>
          </div>

          <div class="__line" v-if="skills.langs.length > 0"></div>
          <div class="list-items">
            <div
              class="item"
              v-for="(language, i) in skills.langs"
              :key="i + 'dhj'"
            >
              <label>{{ language.name | validate }}</label>
              <div class="start-input">
                <star-rating
                  inactive-color="#C1C1C1"
                  :rating="language.level"
                  :read-only="true"
                  active-color="#1BA5A4"
                  :star-size="10"
                  :padding="1"
                  :show-rating="false"
                ></star-rating>
              </div>
              <img
                class="close"
                src="@/assets/icons/closee.svg"
                @click.prevent="
                  () => {
                    if (language.id) {
                      skills.removedlanguages.push(language.id);
                    }
                    skills.langs.splice(i, 1);
                  }
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "skills",
  props: {
    data: Array,
  },
  computed: {
    ...mapGetters(["getSkillsData", "getCountries"]),
    suggestion() {
      return this.getSkillsData;
    },
  },
  components: {},
  data: () => {
    return {
      typingTimer: null,
      doneTypingInterval: 800,
      language_item: {
        id: 0,
        name: "",
      },
      autoCompletesuggest: false,
      skillsTabs: {
        showSkillTab1: true,
        showSkillTab2: false,
        showSkillTab3: false,
        showSkillTab4: false,
        showSkillTab5: false,
      },
      skills: {
        tools: [],
        langs: [],
        domains: [],
        competences: [],
        roles: [],
        removedtools: [],
        removedlanguages: [],
        removeddomains: [],
        removedcompetences: [],
        removedroles: [],
      },
      tool: {
        id: null,
        name: "",
        status: true,
        level: 4,
        type: "tool",
      },
      domain: {
        id: null,
        name: "",
        status: true,
        level: 4,
        type: "domain",
      },
      competence: {
        id: null,
        name: "",
        status: true,
        level: 4,
        type: "competence",
      },
      language: {
        id: null,
        name: {},
        status: true,
        level: 4,
        type: "language",
      },
      role: {
        id: null,
        name: "",
        status: true,
        level: 4,
        type: "role",
      },
    };
  },
  watch: {
    skills: {
      handler: function (val) {
        let vm = this;
        let data = {};
        if (val) {
          Object.keys(val).forEach(function (key) {
            if (
              key == "tools" ||
              key == "langs" ||
              key == "domains" ||
              key == "competences" ||
              key == "roles"
            ) {
              data[key] = val[key].filter((item) => {
                if (key == "langs") {
                  return true;
                } else {
                  return item.id === null;
                }
              });
            } else {
              data[key] = val[key];
            }
          });
          this.updateValue(data);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch("GET_SKILLS_BY_TYPE", { type: "tools" });
    this.$store.dispatch("GET_SKILLS_BY_TYPE", { type: "domains" });
    this.$store.dispatch("GET_SKILLS_BY_TYPE", { type: "competences" });
    this.$store.dispatch("GET_SKILLS_BY_TYPE", { type: "roles" });
    this.$store.dispatch("GET_SKILLS_BY_TYPE", { type: "languages" });
    if (this.data) {
      if (this.data.length > 0) {
        this.data.forEach((item) => {
          // Get tools data
          if (item.tools.length > 0) {
            item.tools.forEach((data) => {
              let tool = {
                id: data.id,
                name: data.name,
                status: data.status === 1,
                level: parseInt(data.priority),
                type: "tool",
              };
              this.skills.tools.push(tool);
            });
          }

          // Get domain data
          item.domains.forEach((data) => {
            let tool = {
              id: data.id,
              name: data.name,
              status: data.status === 1,
              level: parseInt(data.priority),
              type: "domain",
            };
            this.skills.domains.push(tool);
          });
          // Get competences  data
          item.competences.forEach((data) => {
            let tool = {
              id: data.id,
              name: data.name,
              status: data.status === 1,
              level: parseInt(data.priority),
              type: "competence",
            };
            this.skills.competences.push(tool);
          });
          // Get languages data
          item.languages.forEach((data) => {
            if (data.lang) {
              let tool = {
                id: data.id,
                name: data.lang.name,
                status: data.status === 1,
                level: parseInt(data.level),
                type: "language",
              };
              this.skills.langs.push(tool);
            }
          });
          // Get roles data
          item.roles.forEach((data) => {
            let tool = {
              id: data.id,
              name: data.name,
              status: data.status === 1,
              level: parseInt(data.priority),
              type: "role",
            };
            this.skills.roles.push(tool);
          });
        });
      }
    }
  },
  methods: {
    getSuggetion(keyword, type) {
      let vm = this;
      clearTimeout(vm.typingTimer);
      vm.typingTimer = setTimeout(() => {
        vm.searchSkills(keyword, type);
      }, vm.doneTypingInterval);
    },
    searchSkills(keyword, type) {
      let vm = this;
      if (keyword.length > 1) {
        vm.$store.dispatch("SEARCH_SKILLS", { name: keyword, type: type });
      } else {
        vm.$store.dispatch("GET_SKILLS_BY_TYPE", { type: type });
      }
    },
    closeSuggetion() {
      setTimeout(() => {
        this.autoCompletesuggest = false;
      }, 500);
    },
    checkIfSkillExist(array, val) {
      let vm = this;
      let exist = false;
      if (val) {
        array.forEach((item) => {
          if (item.name.toLowerCase() === val.toLowerCase()) {
            vm.$notify({
              group: "status",
              title: "Warning",
              text: val + " already added",
              type: "warn",
              duration: 3000,
            });
            exist = true;
          }
        });
        return exist;
      } else {
        return true;
      }
    },
    updateValue(value) {
      this.$emit("input", value);
    },
    showSkillsTab(key) {
      let vm = this;
      Object.keys(this.skillsTabs).forEach(function (key) {
        vm.skillsTabs[key] = false;
      });
      this.skillsTabs[key] = true;
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.skills-widget {
  .__box {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    span {
      font-family: "Nunito", sans-serif;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #646262;
      b {
        font-weight: 900;
        color: #0884af;
      }
    }
    .skills-inner-tab {
      .inner-links {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: #d9d9d9;
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        a {
          background: #cccccc;
          outline: none;
          border: none;
          flex: 1;
          padding: 18px 10px;
          color: #ffffff;
          text-align: center;
          cursor: pointer;
        }
        .button__active {
          background: #f4f4f4 !important;
          color: #1ba5a4;
        }
      }
      .inner-body {
        padding-top: 30px;
        background: #f4f4f4;
        border-radius: 0px 0px 5px 5px;
        .adding-form {
          display: flex;
          padding: 10px;
          margin-bottom: 10px;
          display: flex;
          border-radius: 5px;
          @media (min-width: 300px) and (max-width: 630px) {
            flex-direction: column;
          }
          .part {
            display: flex;
            flex-grow: 1;
            width: 50%;
            flex-direction: column;
            @media (min-width: 300px) and (max-width: 630px) {
              width: 100%;
              margin-bottom: 15px;
            }
            input,
            select {
              border: none;
              border-radius: 5px;
              margin-top: 10px;
              box-shadow: none !important;
              margin-bottom: 10px !important;
              padding: 7px 10px !important;
              font-weight: normal;
              outline: none;
              background: #f4f4f4 !important;
            }
            .__bg-star {
              border-radius: 5px;
              margin-top: 10px;
              margin-bottom: 10px;
              padding: 7px 10px;
              border: 0.5px solid #707070;
            }
          }

          .input-widget {
            position: relative;
            width: calc(100% - 117px);
            padding-right: 20px;
            @media (min-width: 300px) and (max-width: 630px) {
              width: 100%;
              padding-right: 0px;
              margin-bottom: 19px;
            }
            .top-label {
              display: flex;
              justify-content: space-between;
              .skill-laber-name {
                margin: 0px;
              }
              .active-input {
                display: grid;
                grid-template-columns: auto auto auto;
                grid-column-gap: 5px;
                input {
                  position: relative;
                  top: 2px;
                }
              }
            }
            .skill-input-group {
              display: flex;
              .skill-input {
                background: #fff;
                height: 45px;
                padding: 0px 10px;
                width: 100%;
                outline: none;
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
                border: none;
              }
              .skill-options {
                display: flex;
                justify-content: flex-start;
                .skill-add-btn {
                  background: #1ba5a4;
                  color: #fff;
                  font-size: 14px;
                  font-weight: normal;
                  cursor: pointer;
                  height: 45px;
                  width: 132px;
                  padding: 0px 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-bottom-right-radius: 10px;
                  border-top-right-radius: 10px;
                }
              }
            }
            .search-suggetion {
              width: 96%;
              height: 75px;
              overflow-y: auto;
              background: #fff;
              position: absolute;
              top: 63px;
              border: 1px solid #f4f4f4;
              border-radius: 5px;
              display: flex;
              flex-wrap: wrap;
              padding: 9px;
              z-index: 99;
              .item {
                height: 25px;
                background: #b2b2b2 0% 0% no-repeat padding-box;
                border-radius: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #fff;
                padding: 0px 10px;
                overflow: hidden;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;

                margin-right: 10px;
                margin-bottom: 7px;
                cursor: pointer;
              }
            }
          }
          .skill-rating-widget {
            width: 117px;
            display: flex;
            flex-direction: column;
            .skill-rating-label {
              display: flex;
              .skill-laber-name {
                margin: 0px;
                margin-right: 4px;
              }
            }
            @media (min-width: 300px) and (max-width: 630px) {
              width: 100%;
              padding-right: 0px;
              margin-bottom: 0px;
            }
            .stars-widget {
              display: flex;
              background: #f4f4f4;
              padding: 0px 10px;
              border-radius: 5px;
              .start-input {
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 46px;
                width: 100%;
                padding-left: 0px;
              }
            }
          }
        }

        .__line {
          background: #ccc;
          height: 2px;
        }
        .list-items {
          display: flex;
          flex-wrap: wrap;
          padding-left: 15px;
          padding-right: 15px;
          .item {
            display: flex;
            background: #1ba5a4;
            height: 35px;
            margin-right: 10px;
            align-items: center;
            border-radius: 26px;
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
            margin-bottom: 15px;
            label {
              color: #fff;
              margin-right: 10px;
              font-size: 10px;
            }
            .start-input {
              background: #fff;
              display: flex;
              align-items: center;
              padding: 2px;
              border-radius: 15px;
              padding-left: 10px;
            }
            .close {
              position: absolute;
              right: 0px;
              top: -2px;
              cursor: pointer;
            }
          }
        }
        .__line {
          height: 1px;
          width: 100%;
          background: #f7f7f7;
        }
      }
      .list-items {
        display: block;
        margin-top: 10px;
        .____item {
          padding: 2px 10px;
          background: #1ba5a4;
          margin-right: 10px;
          position: relative;
          border-radius: 16px;
          display: inline-block;
          span {
            color: #fff !important;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 80px;
            margin-right: 10px;
          }
          .close {
            position: absolute;
            right: -5px;
            top: -10px;
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
