<template>
  <div class="navbar-wrapper h-16 flex justify-between items-center pl-3 pr-3 fixed top-0 left-0 right-0 z-20">
    <div class="admin-brand">
      <img src="@/assets/images/logo.png">
    </div>

    <div class="profile-widget flex items-center" v-if="$getLoggedUser()">
      <h4 class="mr-3 text-white">{{ `${$getLoggedUser().first_name} ${$getLoggedUser().last_name}` }}</h4>
      <widgetProfile :show_name="false" :title="`${$getLoggedUser().first_name} ${$getLoggedUser().last_name}`"
        subtitle="" :file_name="$getLoggedUser().profile_picture
          " :path="$fullUrl('images/profiles/')"></widgetProfile>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {

  }
}
</script>

<style lang="scss" scoped>
.navbar-wrapper {
  background: linear-gradient(90deg, #000 -1.73%, #1BA5A4 100%);
}
</style>

