<template>
    <div class="page-header w-full h-14 flex items-center justify-between px-3 bg-white rounded">
        <div class="panel-left flex">
            <slot name="brand"></slot>
            <slot name="path"></slot>


        </div>


        <div class="flex">
            <slot name="actions"></slot>
        </div>
    </div>
</template>
  
<script>
// @ is an alias to /src
export default {
    name: 'page-header',
    components: {
    }
}
</script>
  