import {
    API,
    SITE_API,
    REPORT_GET_ALL_URL,
    REPORT_GET_ALL_SKILLS_URL

} from "../variable";

import axios from 'axios'
import {
    REPORT_GET_ALL,
    REPORT_GET_ALL_SKILLS,
} from "../Actions/report.js";

const state = {

};

const actions = {
    [REPORT_GET_ALL_SKILLS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + REPORT_GET_ALL_SKILLS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [REPORT_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + REPORT_GET_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
};

const mutations = {};

const getters = {};

export default {
    state,
    getters,
    actions,
    mutations
};