import {
    AUTH_REQUEST,
    ACCOUNT_FINISH_WIZARDS,
    AUTH_REQUEST_ERROR,
    INITIALIZE_APP_DATA,
    CHANGE_LOCALE_REQUEST,
    AUTH_LINKEDIN_SIGNUP_REQUEST,
    AUTH_GOOGLE_SIGNUP_REQUEST,
    AUTH_FACEBOOK_SIGNUP_REQUEST,
    FETCH_MESSAGES,
    GET_COUNTRIES,
    AUTH_LOGOUT,
    GET_COMPANIES,
    GET_CITIES,
    ACCOUNT_REGISTER_VIA_INVITE,
    CONSULTIFIED_SIGNUP,
    SUBMIT_INVITE_PARTICIPATION_INFO,
    VERIFY_ACCOUNT,
    RESEND_VERIFY_EMAIL_ACCOUNT,
    AUTH_MICROSOFT_SIGNUP_REQUEST
} from "../Actions/auth";
import { eventBus } from "../../main";

import router from "../../router";

import {
    SITE_API,
    API,
    AUTH_LOGIN_URL,
    ACCOUNT_FINISH_WIZARD_URL,
    AUTH_LOGOUT_URL,
    AUTH_LINKEDIN_LOGIN_URL,
    AUTH_LINKEDIN_SIGNUP_URL,
    AUTH_GOOGLE_SIGNUP_REQUEST_URL,
    AUTH_FACEBOOK_SIGNUP_REQUEST_URL,
    GET_TRANSLATIONS_URL,
    GET_COUNTRIES_URL,
    GET_COMPANIES_URL,
    GET_CITIES_URL,
    FILE_BASE_URL,
    ACCOUNT_REGISTER_VIA_INVITE_URL,
    CONSULTIFIED_SIGNUP_URL,
    SUBMIT_INVITE_PARTICIPATION_INFO_URL,
    VERIFY_ACCOUNT_URL,
    RESEND_VERIFY_EMAIL_ACCOUNT_URL,
    AUTH_MICROSOFT_SIGNUP_REQUEST_URL
} from "../variable";
import axios from "axios";

const state = {
    user: null,
    locale: "en",
    messages: null,
    authToken: "",
    countries: [],
    cities: [],
    companies: [],
    errorMsg: {},
    userId: 0,
    previouslyVisitedRoutePath: "",
    currentlyVisitedRoutePath: "",
    api: {
        fileUrl: FILE_BASE_URL,
        relativeUrls: {
            getTranslations: "api/locale/get", //It uses post method. It also requires data body: group,locale
            postAuthenticateUser: "api/auth/login", //It requires data body: password,email
            postSignUpUserWithLinkedIn: "api/account/signup",
            redirectUserWithLinkedInUrl: "auth/linkedin",
            postCreatePassword: "api/account/create-password",
            getCountries: "api/data/countries/list",
            getCurrencies: "api/data/currencies/list",
            saveUSerFromToken: "api/account/save/type",
            getMeProfileData: "api/profile/me",
            postGetMeProfileData: "api/network/profile", //data for body [id]
            postPersonalInfoUpdate: "api/profile/update/me",

            getFeedsProfileData: "api/data/profile/card",
            getFeedsPosts: "api/feed/all",

            postFeedPost: "api/feed/create", //Data: description,image
            postFeedPostComment: "api/feed/comment/create", //commentable_type: Feed, commentable_id: post_id,text
            postFeedPostLike: "api/feed/like", //type: Feed, id: post_id

            postDeletePost: "api/feed/delete", //id: post_id
            postResetPassword: "api/account/reset", //data required Key: email

            //Network page routes
            getConnections: "api/network/connections",
            getRecommendations: "api/network/people-you-may-know",
            getInvitations: "api/network/invitations",

            postIgnoreConnection: "api/network/ignore-connection", //data: status ="accepted"
            postAcceptConnection: "api/network/accept-connection", //data: status ="accepted"
            postConnect: "api/network/connect", //id:
            //This route below, retrieves all individuals one has chatted with
            getChatsConnections: "api/messages/message-connections",
            //This route retrieves chat(conversation) messages with a specific person
            getChatMessages: "api/messages/message", //Parameter: [receiver_id]
            //To send someone a chat message
            postChatMessage: "api/messages/send", //Body data: [receiver_id,text]
            postChatFile: "api/messages/send-file",
            postSearchConnection: "api/messages/get-queries", //Data for body [query]
            // Notification page routes
            // getNotifications: "api/notifications/get-all",
            //
            getSearchResults: "api/search/people",
            saveGroup: 'api/messages/create/group'
        }
    }
};

const mutations = {
    [CHANGE_LOCALE_REQUEST]: (state, locale) => {
        state.locale = locale;
        eventBus.$emit("locale-changed", {});
    },
    [INITIALIZE_APP_DATA]: (state, payload) => {
        state.authToken = payload.token;
        state.userId = payload.id;
    },
    [AUTH_REQUEST]: (state, payload) => {
        state.user = payload;

    },
    [AUTH_REQUEST_ERROR]: (state, payload) => {
        state.errorMsg = payload
    },

    [AUTH_LOGOUT]: (state, payload) => {
        state.authToken = '';
        state.userId = '';

    },
    [FETCH_MESSAGES]: (state, payload) => {
        state.messages = payload;

    },
    [GET_COUNTRIES]: (state, payload) => {

        state.countries = payload;

    },
    [GET_CITIES]: (state, payload) => {
        state.cities = payload;
    },

    [GET_COMPANIES]: (state, payload) => {
        state.companies = payload;
    },
    changeCurrentRoute(state, path) {
        state.previouslyVisitedRoutePath = state.currentlyVisitedRoutePath;
        state.currentlyVisitedRoutePath = path;
    }
};

const actions = {

    [RESEND_VERIFY_EMAIL_ACCOUNT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + RESEND_VERIFY_EMAIL_ACCOUNT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [CHANGE_LOCALE_REQUEST]: ({ commit, dispatch, getters }, locale) => {
        commit(CHANGE_LOCALE_REQUEST, locale);
    },
    [GET_COMPANIES]: ({ commit, dispatch, getters }, payload) => {

        axios({
            url: API + getters.get_crm_prefix + GET_COMPANIES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        })
            .then(response => {
                commit(GET_COMPANIES, response.data);

            })
            .catch(error => {
                console.log(error.response);
            });


    },
    [GET_COUNTRIES]: ({ commit, dispatch, getters }, payload) => {

        axios({
            url: SITE_API + getters.get_crm_prefix + GET_COUNTRIES_URL,
            method: "POST",
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        })
            .then(response => {
                commit(GET_COUNTRIES, response.data);

            })
            .catch(error => {
                console.log(error.response);
            });


    },
    [GET_CITIES]: ({ commit, dispatch, getters }, payload) => {

        axios({
            url: API + getters.get_crm_prefix + GET_CITIES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        }).then(response => {
            commit(GET_CITIES, response.data);
        })
            .catch(error => {
                console.log(error.response);
            });


    },
    [INITIALIZE_APP_DATA]: ({ commit, dispatch, getters }, payload) => {
        commit(INITIALIZE_APP_DATA, payload);
        // eventBus.$emit("logged-in", payload);
    },
    [FETCH_MESSAGES]: ({ commit, dispatch, state }, payload) => {

        commit(FETCH_MESSAGES)

        axios({
            url: API + getters.get_crm_prefix + GET_TRANSLATIONS_URL,
            method: "POST",
            data: payload

        })
            .then(response => {
                dispatch("LOADER_CLOSE")
                commit(FETCH_MESSAGES, response.data);
            })
            .catch(error => {
                console.log(error.response);
            });



    },
    [AUTH_REQUEST]: ({ commit, dispatch, state, getters }, payload) => {
        let request = axios({
            url: SITE_API + AUTH_LOGIN_URL,
            method: "POST",
            data: payload
        });
        request.then(response => {
            sessionStorage.authToken = response.data.access_token;
            sessionStorage.siteAuthToken = response.data.consultified_token;
            sessionStorage.setItem("isLogedIn", true);
            sessionStorage.setItem("account_type", response.data.account_type);
            commit(AUTH_REQUEST, response.data);
            commit(INITIALIZE_APP_DATA, { token: response.access_token, id: response.id });

        })
            .catch(error => {
                console.log(error)
                let response = error.response;
                if (response.status === 401 || response.status === 400) {
                    commit(AUTH_REQUEST_ERROR, error.response.data.error);
                    return
                }
            });
        return request;

    },
    [VERIFY_ACCOUNT]: ({ commit, dispatch, state }, payload) => {
        let request = axios({
            url: API + getters.get_crm_prefix + VERIFY_ACCOUNT_URL,
            method: "POST",
            data: payload
        });
        return request;
    },
    [ACCOUNT_FINISH_WIZARDS]: ({ commit, dispatch, state }, payload) => {
        dispatch("LOADER_START");
        axios({
            url: API + getters.get_crm_prefix + ACCOUNT_FINISH_WIZARD_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.authToken }

        })
            .then(response => {
                dispatch("LOADER_CLOSE");
                sessionStorage.setItem("new_user_feed_modal", true);
                sessionStorage.setItem("new_user_profile_modal", true);
                router.push({ name: 'consultant-profile' });
            })
            .catch(error => {
                dispatch("LOADER_CLOSE");
                console.log(error)
            });
    },
    [ACCOUNT_REGISTER_VIA_INVITE]: ({ commit, dispatch, state }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_REGISTER_VIA_INVITE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            // headers: { Authorization: "Bearer " + sessionStorage.authToken }
        })
    },
    [SUBMIT_INVITE_PARTICIPATION_INFO]: ({ commit, dispatch, state }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + SUBMIT_INVITE_PARTICIPATION_INFO_URL,
            method: "POST",
            data: validatePayloadData(payload),
            //headers: { Authorization: "Bearer " + sessionStorage.authToken }
        })
    },
    [AUTH_MICROSOFT_SIGNUP_REQUEST]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: API + getters.get_crm_prefix + AUTH_MICROSOFT_SIGNUP_REQUEST_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
        request.then(response => {
            window.location.replace(response.data)
        })
            .catch(error => {

            });
        return request;
    },
    [AUTH_LINKEDIN_SIGNUP_REQUEST]: ({ commit, dispatch, state }, payload) => {
        let request = axios({
            url: API + getters.get_crm_prefix + AUTH_LINKEDIN_SIGNUP_URL,
            method: "POST",
            data: payload
        });
        request.then(response => {
            window.location.replace(response.data)
        })
            .catch(error => {

            });
        return request;
    },
    [AUTH_GOOGLE_SIGNUP_REQUEST]: ({ commit, dispatch, state }, payload) => {

        let request = axios({
            url: API + getters.get_crm_prefix + AUTH_GOOGLE_SIGNUP_REQUEST_URL,
            method: "POST",
            data: payload
        });
        request.then(response => {
            window.location.replace(response.data)

        })
            .catch(error => { });
        return request;
    },
    [AUTH_FACEBOOK_SIGNUP_REQUEST]: ({ commit, dispatch, state }, payload) => {

        let request = axios({
            url: API + getters.get_crm_prefix + AUTH_FACEBOOK_SIGNUP_REQUEST_URL,
            method: "POST",
            data: payload
        });

        request.then(response => {
            window.location.replace(response.data)
        })
            .catch(error => {

            });
        return request;
    },
    [CONSULTIFIED_SIGNUP]: ({ commit, dispatch, state }, payload) => {

        let request = axios({
            url: API + getters.get_crm_prefix + CONSULTIFIED_SIGNUP_URL,
            method: "POST",
            data: payload
        });

        request.then(response => {
            let user = { loggeddIn: true };
            sessionStorage.authToken = response.data.access_token
            sessionStorage.setItem("isLogedIn", true);
            sessionStorage.setItem("account_type", response.data.account_type);
            eventBus.$socket.emit("register", response.data.id);
            commit(AUTH_REQUEST, response.data);
            commit(INITIALIZE_APP_DATA, { token: response.access_token, id: response.id })
            sessionStorage.setItem("new_user_feed_modal", true);
            sessionStorage.setItem("new_user_profile_modal", true);
        })
            .catch(error => {
                console.log(error)
                let response = error.response;
                if (response.status === 401 || response.status === 400) {
                    commit(AUTH_REQUEST_ERROR, error.response.data.error);
                    return
                }
            });
        return request;
    },
    [AUTH_LOGOUT]: ({ commit, dispatch, state }, payload) => {
        sessionStorage.removeItem("authToken");
        sessionStorage.removeItem("setItem");
        sessionStorage.removeItem("isLogedIn");
        sessionStorage.removeItem("account_type");
    },

};

const getters = {
    getLocale(state) {
        return state.locale;
    },
    getAuthToken(state) {
        return state.authToken;
    },
    getCurrentlyVisitedRoutePath(state) {
        return state.currentlyVisitedRoutePath;
    },
    getPreviouslyVisitedRoutePath(state) {
        return state.previouslyVisitedRoutePath;
    },
    getUserId(state) {
        return state.userId;
    },
    getUser(state) {
        return state.user;

    },
    getApiUrl(state) {
        return API;
    },
    getHelper(state) {
        return state;
    },
    getMessages(state) {
        return state.messages;
    },
    getCountries(state) {
        return state.countries;
    },
    getCities(state) {
        return state.cities;
    },
    getCompanies(state) {
        return state.companies;
    },
    getErrorMsg(state) {
        return state.errorMsg;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};