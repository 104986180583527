export const INTERNSHIP_FILTER_BY_TAG = "INTERNSHIP_FILTER_BY_TAG";
export const INTERNSHIP_GET_TAGS = "INTERNSHIP_GET_TAGS";
export const INTERNSHIP_FILTER_BY_LOCATION = "INTERNSHIP_FILTER_BY_LOCATION";
export const INTERNSHIP_EXPORT_INTERNAL_LIA = "INTERNSHIP_EXPORT_INTERNAL_LIA";
export const INTERNSHIP_MOVE_TO_ARCHIVE = "INTERNSHIP_MOVE_TO_ARCHIVE";
export const INTERNSHIP_DELETE_SELECTED_LIA = "INTERNSHIP_DELETE_SELECTED_LIA";
export const INTERNSHIP_MOVE_ARCHIVE_SELECTED_LIA = "INTERNSHIP_MOVE_ARCHIVE_SELECTED_LIA";
export const INTERNSHIP_SAVE_PRIVATE_INTERNSHIP = "INTERNSHIP_SAVE_PRIVATE_INTERNSHIP";
export const INTERNSHIP_ASSIGN_INTERNSHIP = "INTERNSHIP_ASSIGN_INTERNSHIP";
export const INSTRUCTOR_GET_PRIVATE_INTERNSHIP = "INSTRUCTOR_GET_PRIVATE_INTERNSHIP";
export const INTERNSHIP_UPDATE_PRIVATE_INTERNSHIP = "INTERNSHIP_UPDATE_PRIVATE_INTERNSHIP";