/* eslint-disable no-undef */
import {
    API,
    SITE_API,
    ACCOUNT_ADD_NEW_URL,
    ACCOUNT_UPDATE_URL,
    ACCOUNT_GET_DATA_URL,
    ACCOUNT_CONTACT_DELETE_URL,
    ACCOUNT_CONTACT_GET_DATA_URL,
    ACCOUNT_CONTACT_ADD_NEW_URL,
    ACCOUNT_CONTACT_UPDATE_URL,
    ACCOUNT_EVENTS_GET_DATA_URL,
    ACCOUNT_EVENTS_ADD_NEW_URL,
    ACCOUNT_EVENTS_UPDATE_URL,
    ACCOUNT_GET_EVENTS_BASED_DATE_URL,
    ACCOUNT_SINGLE_GET_ALL_DATA_URL,
    ACCOUNT_SINGLE_CONTACT_DELETE_URL,
    ACCOUNT_SINGLE_CONTACT_ADD_NEW_URL,
    ACCOUNT_SINGLE_CONTACT_UPDATE_URL,
    ACCOUNT_SINGLE_ATTACHMENT_DELETE_URL,
    ACCOUNT_SINGLE_ATTACHMENT_ADD_NEW_URL,
    ACCOUNT_SINGLE_ATTACHMENT_UPDATE_URL,
    ACCOUNT_SINGLE_ATTACHMENT_DOWNLOAD_FILE_URL,
    ACCOUNT_FILTER_BY_LOCATION_URL,
    SEARCH_ALL_CRM_URL,
    SEARCH_ALL_SITE_URL,
    ACCOUNT_DELETE_URL,
    ACCOUNT_SEARCH_URL,
    ACCOUNT_FILTER_BY_SORT_URL,
    CONTACTS_SEARCH_URL,
    CONTACTS_FILTER_BY_TAG_URL,
    CONTACTS_FILTER_BY_SORT_URL,
    CONTACTS_GET_TAGS_URL,
    CONTACTS_GET_ROLES_URL,
    ACTIVITIES_DELETE_SELECTED_URL,
    ACTIVITIES_SEARCH_URL,
    ACTIVITIES_GET_ALL_URL,
    ACTIVITIES_FILTER_URL,
    ACTIVITIES_DELETE_URL,
    NOTES_ADD_NEW_URL,
    NOTES_UPDATE_URL,
    NOTES_SEARCH_URL,
    NOTES_GET_ALL_URL,
    NOTES_FILTER_URL,
    NOTES_DELETE_URL,
    NOTES_DELETE_SELECTED_URL,
    ACCOUNT_CHANGE_STATUS_URL,
    ACCOUNT_DELETE_SELECTED_URL,
    ACCOUNT_SEND_SELECTED_MESSAGE_URL,
    ACCOUNT_UPDATE_SELECTED_URL,
    ACCOUNT_SINGLE_GET_INTERNSHIP_URL,
    ACCOUNT_GET_TAGS_URL,
    ACCOUNT_FILTER_BY_TAG_URL,
    ACCOUNT_DELETE_TAG_URL,
    ACCOUNT_FILTER_BY_NAME_URL,
    ACCOUNT_CONTACT_EXPORT_URL,
    ACCOUNT_EXPORT_URL,
    ACCOUNT_CONTACT_DETAILS_URL,
    CONTACT_DELETE_TAG_URL,
    ACCOUNT_CONTACT_OPTING_URL,
    ACCOUNT_CONTACT_DELETE_SELECTED_URL
} from "../variable";

import axios from 'axios'
import {
    ACCOUNT_ADD_NEW,
    ACCOUNT_UPDATE,
    ACCOUNT_GET_DATA,
    ACCOUNT_CONTACT_DELETE,
    ACCOUNT_CONTACT_GET_DATA,
    ACCOUNT_CONTACT_ADD_NEW,
    ACCOUNT_CONTACT_UPDATE,
    SEARCH_EVERYTHING,
    ACCOUNT_EVENTS_GET_DATA,
    ACCOUNT_EVENTS_ADD_NEW,
    ACCOUNT_EVENTS_UPDATE,
    ACCOUNT_GET_EVENTS_BASED_DATE,
    ACCOUNT_SINGLE_GET_ALL_DATA,
    ACCOUNT_SINGLE_CONTACT_DELETE,
    ACCOUNT_SINGLE_CONTACT_ADD_NEW,
    ACCOUNT_SINGLE_CONTACT_UPDATE,
    ACCOUNT_SINGLE_ATTACHMENT_DELETE,
    ACCOUNT_SINGLE_ATTACHMENT_ADD_NEW,
    ACCOUNT_SINGLE_ATTACHMENT_UPDATE,
    ACCOUNT_SINGLE_ATTACHMENT_DOWNLOAD_FILE,
    ACCOUNT_FILTER_BY_LOCATION,
    SEARCH_ALL_CRM,
    SEARCH_ALL_SITE,
    ACCOUNT_DELETE,
    ACCOUNT_SEARCH,
    ACCOUNT_FILTER_BY_SORT,
    CONTACTS_SEARCH,
    CONTACTS_FILTER_BY_TAG,
    CONTACTS_FILTER_BY_SORT,
    CONTACTS_GET_TAGS,
    CONTACTS_GET_ROLES,
    ACTIVITIES_DELETE_SELECTED,
    ACTIVITIES_SEARCH,
    ACTIVITIES_GET_ALL,
    ACTIVITIES_FILTER,
    ACTIVITIES_DELETE,
    NOTES_ADD_NEW,
    NOTES_UPDATE,
    NOTES_SEARCH,
    NOTES_GET_ALL,
    NOTES_FILTER,
    NOTES_DELETE,
    NOTES_DELETE_SELECTED,
    ACCOUNT_CHANGE_STATUS,
    ACCOUNT_DELETE_SELECTED,
    ACCOUNT_SEND_SELECTED_MESSAGE,
    ACCOUNT_UPDATE_SELECTED,
    ACCOUNT_SINGLE_GET_INTERNSHIP,
    ACCOUNT_GET_TAGS,
    ACCOUNT_FILTER_BY_TAG,
    ACCOUNT_DELETE_TAG,
    ACCOUNT_FILTER_BY_NAME,
    ACCOUNT_CONTACT_EXPORT,
    ACCOUNT_EXPORT,
    ACCOUNT_CONTACT_DETAILS,
    CONTACT_DELETE_TAG,
    ACCOUNT_CONTACT_OPTING,
    ACCOUNT_CONTACT_DELETE_SELECTED
} from "../Actions/accounts.js";

const state = {

};

const actions = {
    [ACCOUNT_CONTACT_DELETE_SELECTED]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_CONTACT_DELETE_SELECTED_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_CONTACT_OPTING]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_CONTACT_OPTING_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [CONTACT_DELETE_TAG]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CONTACT_DELETE_TAG_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_CONTACT_DETAILS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_CONTACT_DETAILS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_EXPORT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_EXPORT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
            responseType: 'blob',
        });
    },
    [ACCOUNT_CONTACT_EXPORT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_CONTACT_EXPORT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
            responseType: 'blob',
        });
    },
    [ACCOUNT_FILTER_BY_LOCATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_FILTER_BY_LOCATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_FILTER_BY_NAME]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_FILTER_BY_NAME_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_DELETE_TAG]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_DELETE_TAG_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_GET_TAGS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_GET_TAGS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_FILTER_BY_TAG]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_FILTER_BY_TAG_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_SINGLE_GET_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + ACCOUNT_SINGLE_GET_INTERNSHIP_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { Authorization: "Bearer " + sessionStorage.siteAuthToken }
        });
    },
    [ACCOUNT_DELETE_SELECTED]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_DELETE_SELECTED_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_SEND_SELECTED_MESSAGE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_SEND_SELECTED_MESSAGE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_UPDATE_SELECTED]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_UPDATE_SELECTED_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_CHANGE_STATUS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_CHANGE_STATUS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [NOTES_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + NOTES_ADD_NEW_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [NOTES_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + NOTES_UPDATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [NOTES_SEARCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + NOTES_SEARCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [NOTES_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + NOTES_GET_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [NOTES_FILTER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + NOTES_FILTER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [NOTES_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + NOTES_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [NOTES_DELETE_SELECTED]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + NOTES_DELETE_SELECTED_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },



    [ACTIVITIES_DELETE_SELECTED]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACTIVITIES_DELETE_SELECTED_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACTIVITIES_SEARCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACTIVITIES_SEARCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACTIVITIES_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACTIVITIES_GET_ALL_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACTIVITIES_FILTER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACTIVITIES_FILTER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACTIVITIES_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACTIVITIES_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [CONTACTS_GET_TAGS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CONTACTS_GET_TAGS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [CONTACTS_GET_ROLES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CONTACTS_GET_ROLES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [CONTACTS_FILTER_BY_SORT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CONTACTS_FILTER_BY_SORT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [CONTACTS_FILTER_BY_TAG]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CONTACTS_FILTER_BY_TAG_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [CONTACTS_SEARCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + CONTACTS_SEARCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_FILTER_BY_SORT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_FILTER_BY_SORT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_SEARCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_SEARCH_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [SEARCH_ALL_SITE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + SEARCH_ALL_SITE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken },
        });
    },
    [SEARCH_ALL_CRM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + SEARCH_ALL_CRM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_CONTACT_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_CONTACT_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
        });
    },
    [ACCOUNT_SINGLE_ATTACHMENT_DOWNLOAD_FILE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_SINGLE_ATTACHMENT_DOWNLOAD_FILE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken },
            responseType: 'blob',
        });
    },
    [ACCOUNT_SINGLE_ATTACHMENT_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_SINGLE_ATTACHMENT_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_SINGLE_ATTACHMENT_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_SINGLE_ATTACHMENT_ADD_NEW_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_SINGLE_ATTACHMENT_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_SINGLE_ATTACHMENT_UPDATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_SINGLE_CONTACT_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_SINGLE_CONTACT_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_SINGLE_CONTACT_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_SINGLE_CONTACT_ADD_NEW_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_SINGLE_CONTACT_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_SINGLE_CONTACT_UPDATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_SINGLE_GET_ALL_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_SINGLE_GET_ALL_DATA_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_GET_EVENTS_BASED_DATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_GET_EVENTS_BASED_DATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_EVENTS_GET_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_EVENTS_GET_DATA_URL,
            method: "POST",
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_EVENTS_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_EVENTS_ADD_NEW_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_EVENTS_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_EVENTS_UPDATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [SEARCH_EVERYTHING]: ({ commit, dispatch, getters }, payload) => {
        let base_url = payload.type == "site" ? SITE_API : API;
        let token = payload.type == "site" ? sessionStorage.siteAuthToken : sessionStorage.authToken;
        return axios({
            url: base_url + payload.url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + token }
        });
    },
    [ACCOUNT_CONTACT_GET_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == 'init' ? API + getters.get_crm_prefix + ACCOUNT_CONTACT_GET_DATA_URL : payload.next_page_url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_CONTACT_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_CONTACT_ADD_NEW_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken,
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    [ACCOUNT_CONTACT_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_CONTACT_UPDATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_GET_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == 'init' ? API + getters.get_crm_prefix + ACCOUNT_GET_DATA_URL : payload.next_page_url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_UPDATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [ACCOUNT_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + ACCOUNT_ADD_NEW_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken, 'Content-Type': 'multipart/form-data' }
        });
    },
};

const mutations = {};

const getters = {};

export default {
    state,
    getters,
    actions,
    mutations
};