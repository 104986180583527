<template>
  <div class="parent-container">
    <div class="parent-toolbar">
      <WidgetToolbar title="School" :icon="require('@/assets/admin-icon/menu/school_active.svg')">
        <div class="options"></div>
      </WidgetToolbar>
    </div>
    <div class="company-profile-wrapper">
      <multipane class="custom-resizer" layout="vertical">
        <!-- company-profile display -->
        <div class="company-profile-container">
          <div class="company-profile-header">
            <label class="title">Public information</label>
            <div class="company-profile-brand">
              <div class="company-profile-logo">
                <img v-if="user.company_info.company_logo" :src="$fullUrl(
                  'images/profiles/' + user.company_info.company_logo
                )
                  " />
                <img v-else src="@/assets/icons/logo_avatar.svg" />
              </div>
              <h2 class="company-name">
                {{ user.company_info.company_name | validate }}
              </h2>
              <div class="location-data">
                <span>{{ user.company_info.address | validate }}</span>
              </div>
              <div class="contact-data">
                <span v-if="user.company_info.phone">{{
                  user.company_info.phone | validate
                }}</span>
                <span v-if="user.company_info.email" class="separator">|</span>
                <span v-if="user.company_info.email">{{
                  user.company_info.email | validate
                }}</span>
              </div>
              <div class="company-desc" v-if="user.company_info.description" v-html="user.company_info.description"></div>
              <div class="company-profile-option">

              </div>
            </div>
          </div>

          <div class="company-profile-body" style="margin-bottom: 10px">
            <ul class="consultants-items" v-if="user.consultants.length > 0">
              <li class="consultants-starter">
                <div class="consultants-item-header">
                  <label>Students</label>
                </div>
              </li>

              <draggable draggable=".consultants-item" v-model="user.consultants" v-bind="dragOptions"
                @start="isDragging = true" @end="onDragEnd" :move="setDragInfo">
                <transition-group type="transition" :name="'flip-list'">
                  <li class="consultants-item" v-for="(item, index) in user.consultants" :key="index">
                    <div class="consultants-item-header">
                      <widgetProfile :title="item.first_name + ' ' + item.last_name"
                        :subtitle="item.profile ? item.profile.address : ''" :file_name="item.profile_picture"
                        :path="$fullUrl('images/profiles/')" :size="30" :text_size="12"></widgetProfile>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </ul>
            <div class="empty-widget" v-else>
              <div class="empty-widgetheader">
                <label>Students</label>
              </div>
              <div class="empty-widget-body">
                <p>
                  You have not yet added any students
                  <!-- <br />Click below to add one -->
                </p>
                <!-- <button class="add-btn">Add a member</button> -->
              </div>
            </div>
          </div>

          <div v-if="false" class="company-profile-body">
            <ul class="project-items" v-if="user.featured_projects.length > 0">
              <li class="project-starter">
                <div class="project-item-header">
                  <label>Project</label>
                </div>
              </li>
              <li class="project-item" v-for="(item, i) in user.featured_projects" :key="i">
                <div class="project-item-header">
                  <div class="project-item-brand">
                    <img src="@/assets/icons/project-icon.svg" alt />
                  </div>
                  <div class="content">
                    <div class="title-row">
                      <label>{{ item.name | validate }}</label>
                      <span v-if="item.address">{{
                        item.address | validate
                      }}</span>

                      <span class="date">
                        <span v-if="item.from">{{
                          item.from | formatDate | validate
                        }}</span>
                        <span class="separator" v-if="item.from && item.to">-</span>
                        <span v-if="item.to">{{
                          item.to | formatDate | validate
                        }}</span>
                      </span>
                    </div>
                    <div class="options">
                      <div class="btns">
                        <span v-if="item.verified" class="verified-status">Verified</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="empty-widget" v-else>
              <div class="empty-widgetheader">
                <label>Projects</label>
              </div>
              <div class="empty-widget-body">
                <p>
                  You have not yet any school project
                  <!-- <br />Click below to add one -->
                </p>
                <!-- <button class="add-btn">Add a project</button> -->
              </div>
            </div>
          </div>



        </div>
        <!-- End -->
        <multipane-resizer></multipane-resizer>
        <!-- Skills widget -->
        <div class="skills-section" :style="{ flexGrow: 1 }">
          <ul class="skills-items">
            <li class="skills-starter">
              <div class="skills-item-header">
                <label>Skills</label>
              </div>
            </li>
          </ul>
          <div class="skills-widget">
            <div class="skills_content__wrapper">
              <!-- Tools -->
              <div class="skill_box">
                <div class="skill_box_header">
                  <label>Tools</label>
                </div>
                <div class="skill_box_body" v-if="user.skills.tools.length > 0">
                  <div class="row__item" v-for="(item, i) in user.skills.tools.slice(0, 5)" :key="i">
                    <span>{{ item.name | validate }}</span>
                    <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                      :show-rating="false" v-model="item.priority"></star-rating>
                  </div>
                </div>
                <div class="skill_box_body" v-else>
                  <div class="empty-skills">
                    <p>You haven't any tool yet</p>
                  </div>
                </div>
              </div>
              <!-- Competence -->
              <div class="skill_box">
                <div class="skill_box_header">
                  <label>Competences</label>
                </div>
                <div class="skill_box_body" v-if="user.skills.competences.length > 0">
                  <div class="row__item" v-for="(item, i) in user.skills.competences.slice(0, 5)" :key="i">
                    <span>{{ item.name | validate }}</span>
                    <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                      :show-rating="false" v-model="item.priority"></star-rating>
                  </div>
                </div>
                <div class="skill_box_body" v-else>
                  <div class="empty-skills">
                    <p>You haven't any competence yet</p>
                  </div>
                </div>
              </div>
              <!-- Roles -->
              <div class="skill_box">
                <div class="skill_box_header">
                  <label>Roles</label>
                </div>
                <div class="skill_box_body" v-if="user.skills.roles.length > 0">
                  <div class="row__item" v-for="(item, i) in user.skills.roles.slice(0, 5)" :key="i">
                    <span>{{ item.name | validate }}</span>
                    <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                      :show-rating="false" v-model="item.priority"></star-rating>
                  </div>
                </div>
                <div class="skill_box_body" v-else>
                  <div class="empty-skills">
                    <p>You haven't any role yet</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="next_skills_content__wrapper">
              <!-- Domain -->
              <div class="skill_box">
                <div class="skill_box_header">
                  <label>Domains</label>
                </div>
                <div class="skill_box_body" v-if="user.skills.domains.length > 0">
                  <div class="row__item" v-for="(item, i) in user.skills.domains.slice(0, 5)" :key="i">
                    <span>{{ item.name | validate }}</span>
                    <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                      :show-rating="false" v-model="item.priority"></star-rating>
                  </div>
                </div>
                <div class="skill_box_body" v-else>
                  <div class="empty-skills">
                    <p>You haven't any domain yet</p>
                  </div>
                </div>
              </div>
              <!-- Laguage -->
              <div class="skill_box">
                <div class="skill_box_header">
                  <label>Languages</label>
                </div>
                <div class="skill_box_body" v-if="user.skills.languages.length > 0">
                  <div class="row__item" v-for="(item, i) in user.skills.languages.slice(0, 5)" :key="i">
                    <span>{{ item.lang.name | validate }}</span>
                    <star-rating inactive-color="#C1C1C1" :read-only="true" active-color="#1BA5A4" :star-size="14"
                      :show-rating="false" v-model="item.level"></star-rating>
                  </div>
                </div>
                <div class="skill_box_body" v-else>
                  <div class="empty-skills">
                    <p>You haven't any language yet</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </multipane>
      <!-- Setting modal -->
      <Settings @onClose="onUpdateProfile()" @onCancel="show_settings_modal = false" v-if="show_settings_modal">
      </Settings>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import draggable from "vuedraggable";
import Settings from "./Settings.vue";
export default {
  name: "genelar-info-profile",
  components: {
    draggable,
    Settings,
  },
  props: {
    school: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => {
    return {
      show_settings_modal: false,
      user: {
        company_info: {},
        manager_info: {},
        manager_profile: {},
        consultants: [],
        projects: [],
        featured_projects: [],
        skills: {
          tools: [],
          domains: [],
          languages: [],
          competences: [],
          roles: [],
        },
      },
      isDragging: false,
      consultant_drag_details: {
        draggedContext: {},
        relatedContext: {},
      },
    };
  },
  mounted() {
    let vm = this;
    vm.getStartData();
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    onUpdateProfile() {
      let vm = this;
      vm.show_settings_modal = false;
      vm.getStartData();
    },
    navigateTocompanySettings() {
      let vm = this;
      vm.show_settings_modal = true;
    },
    setDragInfo({ draggedContext, relatedContext }) {
      this.consultant_drag_details.draggedContext = draggedContext;
      this.consultant_drag_details.relatedContext = relatedContext;
    },
    onDragEnd(ev) {
      let vm = this;
      let start_order = vm.consultant_drag_details.draggedContext.index;
      let end_order = vm.consultant_drag_details.draggedContext.futureIndex;
      let id = vm.consultant_drag_details.draggedContext.element.id;
      let direction = "";
      if (start_order < end_order) {
        direction = "down";
      } else {
        direction = "up";
      }
      //Send data
      vm.$store
        .dispatch("COMPANY_CONSULTANT_SAVE_PRIOLITY", {
          start_order: start_order,
          end_order: end_order,
          id: id,
          direction: direction,
        })
        .then((responce) => {
          vm.getProfileData();
        });
    },
    getStartData() {
      let vm = this;
      vm.user = vm.school;
      let skills = vm.processSkills(vm.user.skills);
      vm.user.consultants = vm.user.consultants
        .filter((item) => {
          return item.profile;
        })
        .sort((start, end) => {
          return start.order - end.order;
        });
      eventBus.$set(vm.user, "skills", skills);
    },
    processSkills(skills_data) {
      let skills = {
        tools: [],
        domains: [],
        languages: [],
        competences: [],
        roles: [],
      };
      if (skills_data && skills_data.length > 0) {
        skills_data.forEach((skill) => {
          skill.tools.forEach((item) => {
            let obj = {
              name: item.name,
              priority: item.priority,
              level: parseInt(item.priority),
            };
            let exists = skills.tools.some((skil) => skil.name == item.name);
            if (!exists) {
              skills.tools.push(obj);
            }
          });
          skill.domains.forEach((item) => {
            let obj = {
              name: item.name,
              priority: item.priority,
              level: parseInt(item.priority),
            };
            let exists = skills.domains.some((skil) => skil.name == item.name);
            if (!exists) {
              skills.domains.push(obj);
            }
          });
          skill.competences.forEach((item) => {
            let obj = {
              name: item.name,
              priority: item.priority,
              level: parseInt(item.priority),
            };
            let exists = skills.competences.some(
              (skil) => skil.name == item.name
            );
            if (!exists) {
              skills.competences.push(obj);
            }
          });
          skill.roles.forEach((item) => {
            let obj = {
              name: item.name,
              priority: item.priority,
              level: parseInt(item.priority),
            };
            let exists = skills.roles.some((skil) => skil.name == item.name);
            if (!exists) {
              skills.roles.push(obj);
            }
          });
          skill.languages.forEach((item) => {
            if (item.lang) {
              let obj = {
                lang: { name: item.lang.name },
                level: parseInt(item.level),
              };
              let exists = skills.languages.some(
                (skil) => skil.lang.name == item.lang.name
              );
              if (!exists) {
                skills.languages.push(obj);
              }
            }
          });
        });
      }
      return skills;
    },
  },
};
</script>
<style lang="scss" scoped>
.parent-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .parent-toolbar {
    position: absolute;
    z-index: 999;
    top: 5px;
    left: 5px;
    right: 5px;
  }

  .company-profile-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;

    @media (min-width: 300px) and (max-width: 630px) {
      padding-right: 0px;
    }

    .custom-resizer {
      .company-profile-container {
        min-width: calc(100% - 441px);
        width: 100%;

        @media (min-width: 300px) and (max-width: 630px) {
          margin-bottom: 10px;
        }

        .company-profile-header {
          padding: 10px 20px;
          border-radius: 5px;
          background: #ffffff;
          margin-bottom: 5px;

          .title {
            font-size: 16px !important;
            margin-bottom: 16px;
            display: none;

            @media (min-width: 300px) and (max-width: 630px) {
              display: block;
            }
          }

          .company-profile-brand {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @media (min-width: 300px) and (max-width: 630px) {
              border-radius: 4px;
              border: 1px solid #ddd;
              padding: 15px;
            }

            .company-profile-logo {
              margin-bottom: 5px;

              img {
                width: 24.18px;
                height: 24.18px;
                object-fit: contain;
                border-radius: 50%;
              }
            }

            .company-name {
              font-family: "Nunito";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              text-align: center;
              color: #000000;
              margin: 4px 0px;
            }

            .location-data {
              margin: 0px;
              font-family: "Nunito";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              text-align: center;
              color: #707070;
            }

            .contact-data {
              .separator {
                margin: 0px 20px;
              }
            }

            .company-desc {
              font-size: 13px;
              line-height: 20px;
              text-align: center;

              @media (min-width: 300px) and (max-width: 630px) {
                margin: 10px 10px 10px 10px;
                text-align: left;
              }

              p {
                margin: 0;
              }
            }

            .company-profile-option {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;

              .btn-edit {
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #ddd;
                border-radius: 5px;
                width: 100px;
                height: 32px;
                font-size: 14px;
                margin-top: 13px;
                color: #646262;
                cursor: pointer;
                outline: none;
                border: 1px solid #ccc;
              }
            }
          }
        }

        .company-profile-body {
          padding: 20px;
          border-radius: 5px;
          background: #ffffff;

          ul.consultants-items {
            padding-left: 0px;
            list-style: none;
            margin: 0px;

            .consultants-starter {
              margin-bottom: 6px;

              .consultants-item-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 16px;

                label {
                  color: #707070;
                  font-size: 16px;
                }
              }

              p {
                color: #131313;
              }
            }

            .consultants-item {
              background: #f5f5f5;
              border-radius: 5px;
              margin-bottom: 10px;

              .consultants-item-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 6px;
              }
            }
          }

          ul.project-items {
            padding-left: 0px;
            list-style: none;
            margin: 0px;

            .project-starter {
              margin-bottom: 6px;

              .project-item-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 15px;

                label {
                  color: #707070;
                  font-size: 16px;
                }
              }
            }

            .project-item {
              background: #f5f5f5;
              border-radius: 10px;
              margin-bottom: 10px;

              .project-item-header {
                display: grid;
                padding: 5px 17px;
                grid-template-columns: 40px calc(100% - 40px);
                grid-column-gap: 20px;

                @media (min-width: 300px) and (max-width: 630px) {
                  grid-template-columns: 100%;
                  padding: 10px 12px;
                }

                .project-item-brand {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 60px;
                  height: 60px;

                  @media (min-width: 300px) and (max-width: 630px) {
                    width: 60px;
                    height: 60px;
                    display: none;
                  }

                  img {
                    width: 40px;
                    height: 40px;
                    object-fit: contain;

                    @media (min-width: 300px) and (max-width: 630px) {
                      width: 33px;
                      height: 33px;
                    }
                  }
                }

                .content {
                  display: flex;
                  justify-content: space-between;
                  padding-top: 10px;

                  .title-row {
                    padding-right: 9px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    label {
                      display: block;
                      font-size: 14px;
                      margin-bottom: 1px;

                      @media (min-width: 300px) and (max-width: 630px) {
                        font-size: 12px;
                      }
                    }

                    span {
                      color: #808080;

                      font-size: 13px;
                      margin-bottom: 6px;

                      @media (min-width: 300px) and (max-width: 630px) {
                        font-size: 12px;
                      }
                    }

                    .date {
                      color: #808080;

                      font-size: 13px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;

                      .separator {
                        margin: 0px 5px;
                      }

                      @media (min-width: 300px) and (max-width: 630px) {
                        font-size: 11px;
                      }
                    }
                  }

                  .options {
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;

                    .btns {
                      display: flex;
                      margin-top: -5px;

                      .verified-status {
                        color: #0590ab;
                        margin: 10px 0px;
                      }

                      .btn-verify {
                        height: 40px;
                        width: 100px;
                        outline: none;
                        color: #fff;
                        background-color: #1890a9;
                        border-radius: 5px;
                      }
                    }

                    .status {
                      color: #000;

                      font-size: 13px;
                    }

                    .toogle-panel-btn {
                      width: 19px;
                      padding: 0;
                      height: 22px;
                      font-size: 20px;
                      position: absolute;
                      bottom: 5px;
                      right: 7px;
                    }
                  }
                }

                .toogle-panel-btn {
                  padding: 1px 40px;
                  font-size: 30px;
                  background: transparent;
                  border: none;
                  outline: none;
                  cursor: pointer;
                  color: #ccc;
                }
              }

              .project-item-body {
                padding: 10px;

                h4 {}

                img {
                  width: 100%;
                }

                p {
                  font-size: 14px;
                }

                label {
                  color: #808080;
                  font-size: 15px;
                  display: block;
                  margin-bottom: 8px;
                }

                .skills-widget {
                  display: flex;
                  flex-wrap: wrap;

                  .skill-item {
                    background: #707070 0% 0% no-repeat padding-box;
                    border-radius: 25px;
                    color: #ffffff;
                    font-size: 13px;
                    padding: 5px 22px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                  }
                }

                .project-info {
                  display: flex;
                  justify-content: space-between;
                  margin-top: 20px;

                  .col-one,
                  .col-two {
                    width: 50%;

                    label {
                      color: #808080;
                      margin-bottom: 3px;
                      margin-top: 20px;
                    }

                    p {
                      color: #000;
                      margin: 0px;
                    }
                  }
                }
              }
            }
          }

          .empty-widget {
            .empty-widgetheader {
              label {
                font-size: 16px;
              }
            }

            .empty-widget-body {
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              padding: 0px 200px;

              p {
                text-align: center;
              }

              .add-btn {
                width: 180px;
                height: 45px;
                background: #1890a9 0% 0% no-repeat padding-box;
                border-radius: 4px;
                color: #fff;
                outline: none;
                margin-right: 20px;
                display: center;
                justify-content: center;
              }
            }
          }
        }
      }

      .multipane-resizer {
        width: 15px;
      }

      .skills-section {
        border-radius: 5px;
        background: #ffffff;
        width: 400px;
        min-width: 400px;
        max-width: 441px;
        height: max-content;
        padding-bottom: 20px;

        @media (min-width: 300px) and (max-width: 630px) {
          padding-bottom: 100px;
        }

        ul.skills-items {
          width: 100%;
          padding-left: 0px;
          list-style: none;

          .skills-starter {
            margin-bottom: 6px;

            .skills-item-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 6px 15px;

              label {
                color: #000000;

                font-size: 16px;
              }
            }
          }
        }

        .skills-widget {
          padding: 0px 15px;

          .skills_content__wrapper {
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: 10px;
            margin-bottom: 0px;

            .skill_box {
              min-width: 0;
              display: flex;
              flex-direction: column;
              width: 100%;
              background: #f5f5f5;
              border-radius: 5px;
              margin-bottom: 8px;

              .skill_box_header {
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 0px 15px;

                label {
                  color: #0884af;
                  width: 100%;
                  font-size: 15px;
                }
              }

              .skill_box_body {
                padding: 0px 15px;

                .empty-skills {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  height: 60px;
                  justify-content: center;

                  p {
                    text-align: center;
                    margin-bottom: 20px;
                    color: #8d8d8d;
                  }

                  button {
                    width: 133px;
                    height: 36px;
                    background: #1890a9 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    color: #fff;
                  }
                }

                .row__item {
                  margin-bottom: 10px;
                  display: flex;
                  margin-bottom: 10px;
                  justify-content: space-between;
                  width: 100%;

                  span {
                    color: #000000;
                    font-weight: normal;
                    margin-top: 2px;
                    font-size: 12px;
                    overflow: hidden;
                    text-decoration: none;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }
            }
          }

          .next_skills_content__wrapper {
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: 10px;

            .skill_box {
              min-width: 0;
              display: flex;
              flex-direction: column;
              width: 100%;
              background: #f5f5f5;
              border-radius: 5px;
              margin-bottom: 8px;

              .skill_box_header {
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 0px 15px;

                label {
                  color: #0884af;
                  width: 100%;
                  font-size: 15px;
                }
              }

              .skill_box_body {
                padding: 0px 15px;

                .empty-skills {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  height: 60px;
                  justify-content: center;

                  p {
                    text-align: center;
                    margin-bottom: 20px;
                    color: #8d8d8d;
                  }

                  button {
                    width: 133px;
                    height: 36px;
                    background: #1890a9 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    color: #fff;
                  }
                }

                .row__item {
                  margin-bottom: 10px;
                  display: flex;
                  margin-bottom: 10px;
                  justify-content: space-between;
                  width: 100%;

                  span {
                    color: #000000;
                    font-weight: normal;
                    margin-top: 2px;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>