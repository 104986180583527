var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-modal"},[_c('widgetModal',{attrs:{"title":'Account Selected(' + _vm.content.length + ')',"subtitle":"You can add information directly from this form","btnlefttext":"Save Changes","btncentertext":"Cancel","btnrighttext":"Generate LIA","leftloading":_vm.adding_acc_loading,"rightloading":false,"centerloading":false,"footerhidden":false,"hiderightbtn":true,"footer_padding":"10px 18px 30px 30px","header_padding":"30px 0px 0px 31px","body_padding":"0px 30px","width":"550px"},on:{"onLeftClick":_vm.onConfirm,"onCenterClick":_vm.onCancel,"onRightClick":_vm.onGenerateInternship}},[_c('div',{staticClass:"widget-modal-body-noscoll"},[_c('div',{staticClass:"row"},[_c('switches',{staticStyle:{"margin-top":"40px"},attrs:{"theme":"c-switch","type-bold":"true","color":"blue"},model:{value:(_vm.allowed_input.is_status_enabled),callback:function ($$v) {_vm.$set(_vm.allowed_input, "is_status_enabled", $$v)},expression:"allowed_input.is_status_enabled"}}),_c('div',{staticClass:"h-spacer"}),_c('widgetInputWrapper',{staticStyle:{"width":"100%"},attrs:{"title":"Account Status"}},[_c('widgetSelect',{attrs:{"opt":[
              { label: 'Active', value: 'active' },
              { label: 'Passive', value: 'passive' },
            ],"placeholder":"Acccount status"},model:{value:(_vm.account_form.status),callback:function ($$v) {_vm.$set(_vm.account_form, "status", $$v)},expression:"account_form.status"}})],1)],1),_c('div',{staticClass:"row"},[_c('switches',{staticStyle:{"margin-top":"40px"},attrs:{"theme":"c-switch","type-bold":"true","color":"blue"},model:{value:(_vm.allowed_input.is_location_enabled),callback:function ($$v) {_vm.$set(_vm.allowed_input, "is_location_enabled", $$v)},expression:"allowed_input.is_location_enabled"}}),_c('div',{staticClass:"h-spacer"}),_c('widgetInputWrapper',{attrs:{"title":"Account Location"}},[_c('map-helper',{model:{value:(_vm.account_form.location),callback:function ($$v) {_vm.$set(_vm.account_form, "location", $$v)},expression:"account_form.location"}})],1)],1),_c('div',{staticClass:"row"},[_c('switches',{staticStyle:{"margin-top":"40px"},attrs:{"theme":"c-switch","type-bold":"true","color":"blue"},model:{value:(_vm.allowed_input.is_category_enabled),callback:function ($$v) {_vm.$set(_vm.allowed_input, "is_category_enabled", $$v)},expression:"allowed_input.is_category_enabled"}}),_c('div',{staticClass:"h-spacer"}),_c('widgetInputWrapper',{attrs:{"title":"Account Category"}},[_c('widgetSelect',{attrs:{"opt":[
              { label: 'No category', value: '' },
              { label: 'Gold', value: 'gold' },
              { label: 'Silver', value: 'silver' },
              { label: 'Platinum', value: 'platinum' },
            ],"placeholder":"Acccount category"},model:{value:(_vm.account_form.category),callback:function ($$v) {_vm.$set(_vm.account_form, "category", $$v)},expression:"account_form.category"}})],1)],1),_c('div',{staticClass:"row"},[_c('switches',{staticStyle:{"margin-top":"40px"},attrs:{"theme":"c-switch","type-bold":"true","color":"blue"},model:{value:(_vm.allowed_input.is_tags_enabled),callback:function ($$v) {_vm.$set(_vm.allowed_input, "is_tags_enabled", $$v)},expression:"allowed_input.is_tags_enabled"}}),_c('div',{staticClass:"h-spacer"}),_c('widgetInputWrapper',{attrs:{"title":"Account Tags"}},[_c('widgetTags',{attrs:{"placeholder":"Add tags"},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }