<template>
  <div class="dropdown-menu-widget">
    <button ref="dropdown_menu" class="toogle-panel-btn" @click="toggleMenu">
      <img src="@/assets/admin-icon/menu.svg" />
    </button>
    <div class="menus" :class="{ [direction]: true }" @click="show_menu = !show_menu"
      v-closable="{ exclude: ['dropdown_menu'], handler: 'hideMenu' }" v-show="show_menu">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "dropdown-menu",
  components: {},
  props: ["visible"],
  data: () => {
    return {
      show_menu: false,
      direction: "down",
    };
  },
  watch: {
    visible: function (val) {
      this.show_menu = val;
    },
  },
  mounted() { },
  methods: {
    toggleMenu(e) {
      let vm = this;
      let windowHeight = window.innerHeight;
      if (e.clientY > windowHeight / 2) {
        vm.direction = "up";
      } else {
        vm.direction = "down";
      }
      vm.show_menu = !vm.show_menu;
    },
    hideMenu() {
      this.show_menu = false;
    },
  },
};
</script>
<style lang="scss"  >
.dropdown-menu-widget {
  position: relative;

  .toogle-panel-btn {
    font-size: 25px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #ccc;
    height: 20px;
    width: 14px;
    display: flex;
    align-items: center;

    img {
      height: 20px;
    }
  }

  .menus {
    position: absolute;
    top: 38px;
    left: -121px;
    background: #fff;
    z-index: 9999;
    padding-top: 0px;
    padding-bottom: 0px;
    min-width: 145px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px #ccc;

    &.up {
      top: unset;
      bottom: 38px;
    }

    ul {
      list-style: none;
      padding-left: 0px !important;
      margin-top: 0px;
      margin-bottom: 0px;

      li {
        a {
          padding: 13px 10px;
          text-decoration: none;
          color: #000;
          display: block;
          border-bottom: 1px solid #f5f5f5;
          cursor: pointer;
        }
      }

      li:hover {
        a {
          background: #f5f5f5;
        }
      }
    }
  }
}
</style>
