<template>
  <button :disabled="is_loading" @click="onClick" class="widget_button">
    <loading :height="15" color="#fff" :opacity="1" :width="15" :active.sync="is_loading" :is-full-page="false"
      :can-cancel="false" background-color="#1ba5a4" class="loader"></loading>
    <span>
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: "widget_button",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      is_loading: true,
    };
  },
  watch: {
    loading: function (val) {
      this.is_loading = val;
    },
  },
  mounted() {
    let vm = this;
    vm.is_loading = vm.loading;
  },
  methods: {
    onClick(e) {
      let vm = this;
      vm.$emit("click", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.widget_button {
  height: 30px;
  border-radius: 5px;
  border: none;
  font-style: bold !important;
  font-weight: 500;
  width: max-content;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 14px;
  transition: all 0.2s ease-in-out;

  &.fit-content {
    padding: 0px 1px;
    width: max-content !important;
    min-width: max-content !important;
  }

  &.fill-parent {
    width: 100% !important;
  }

  &:hover {
    // animate the button
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  }

  &.outline {
    background: transparent;
    border-width: 1px;
    border-style: solid;
  }

  &.btn-primary-outline {
    background: transparent;
    color: #1ba5a4;
    border-style: solid;
    border-width: 1px;
    border-color: #1ba5a4;
  }

  &.btn-secondary-outline {
    background: transparent;
    color: #0884af;
    border-style: solid;
    border-width: 1px;
    border-color: #0884af;
  }

  &.btn-primary-transparent {
    background: transparent;
    color: #1ba5a4;
    border-style: none;
  }

  &.btn-transparent-primary {
    background: transparent;
    color: #1ba5a4;
  }

  &.btn-primary {
    background: #1ba5a4;
    color: #fff;
  }

  &.btn-transparent-secondary {
    background: transparent;
    color: #0884af;
  }

  &.btn-secondary {
    background: #0884af;
    color: #fff;
  }

  .loader {
    border-radius: 10px;
  }

  span {
    white-space: nowrap;
    display: flex;
    align-items: center;

    img {
      margin: 0px 3px;
    }
  }
}
</style>
