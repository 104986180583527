<template>
  <div class="search-widget">
    <button class="btn-search left" v-if="icon == 'left'">
      <img v-if="!loading" src="@/assets/icons/search.svg" class="search-icon" alt="" />
      <loading :height="15" color="#1ba5a4" :opacity="1" :width="15" :active.sync="loading" :is-full-page="false"
        :can-cancel="false" background-color="#fff" class="loader"></loading>
    </button>
    <form action="#" autoComplete="false" @submit.prevent="onChange">
      <input :type="type" :disabled="disabled" :value="value" :placeholder="placeholder" class="widget_search-input"
        @change="onChange" @blur="onBlur" @focus="onFocus" @keyup="onKeyup" @keydown="onKeydown" @click="onClick"
        @keypress.enter="onKeypress" />
    </form>
    <button class="btn-search right" v-if="icon == 'right'">
      <img v-if="!loading" src="@/assets/icons/search.svg" class="search-icon" alt="" />
      <loading :height="15" color="#1ba5a4" :opacity="1" :width="15" :active.sync="loading" :is-full-page="false"
        :can-cancel="false" background-color="#fff" class="loader"></loading>
    </button>
  </div>
</template>

<script>
export default {
  name: "widget_search",
  props: {
    icon: {
      type: String,
      default: "left",
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "off",
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() { },
  methods: {
    onKeypress(e) {
      this.$emit("keypress", e);
    },
    onChange(e) {
      let vm = this;
      vm.$emit("change", e);
    },
    onBlur(e) {
      let vm = this;
      vm.$emit("blur", e);
    },
    onFocus(e) {
      let vm = this;
      vm.$emit("focus", e);
    },
    onKeyup(e) {
      let vm = this;
      vm.$emit("input", e.target.value);
      vm.$emit("keyup", e);
    },
    onKeydown(e) {
      let vm = this;
      vm.$emit("input", e.target.value);
      vm.$emit("keydown", e);
    },
    onClick(e) {
      let vm = this;
      vm.$emit("click", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-widget {
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  height: 30px;
  border-radius: 5px;

  .btn-search {
    width: 20px;
    height: 20px;
    background: transparent;
    outline: none;
    position: relative;

    &.left {
      margin-right: 10px;
    }

    &.right {
      margin-left: 10px;
    }

    .search-icon {
      width: 15px;
    }
  }

  form {
    width: calc(100% - 20px);

    .widget_search-input {
      font-weight: 500;
      width: 100%;
      position: relative;
      background: transparent;
      outline: none;
      border: none;
    }
  }
}
</style>
