<template>
  <div class="widget-dropdown-list-icon">
    <widgetDropdown v-model="internal_list_dropdown" :right="true" :hover="false" :interactive="true">
      <button class="btn-dropdown">
        <img class="icon" style="height: 20px" :src="selectedItem ? selectedItem.icon : null" />
        {{ selectedItem ? selectedItem.name : "Select" }}
        <img v-if="!internal_list_dropdown" class="allow-icon" style="height: 20px" src="@/assets/icons/allow-down.svg" />
        <img v-else class="allow-icon" style="height: 20px" src="@/assets/icons/allow-up.svg" />
      </button>

      <div slot="dropdown" class="dropdown-widget right">
        <a class="dropdown-item" @click="onSelect(item)" href="#" v-for="(item, index) in items" :key="index">
          <div class="dropdown-item-icon">
            <img class="item-icon" style="height: 20px" :src="item.icon" />
          </div>
          <div class="item-label">
            {{ item.name }}
          </div>
        </a>
      </div>
    </widgetDropdown>
  </div>
</template>
  
<script>
export default {
  name: "widget-dropdown-list-icon",
  props: {
    value: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      internal_list_dropdown: false,
      items: [
        {
          name: "Email",
          value: "emails",
          icon: require("@/assets/icons/email.svg"),
        },
        {
          name: "Notifications",
          value: "notifications",
          icon: require("@/assets/icons/notifications.svg"),
        },
        {
          name: "Questionnaires",
          value: "questionnaires",
          icon: require("@/assets/icons/questionnaires.svg"),
        },
      ],
      selectedItem: null,
    };
  },
  watch: {},
  mounted() {
    let vm = this;
    vm.selectedItem = vm.items[vm.value];
  },
  methods: {
    onSelect(item) {
      let vm = this;
      vm.selectedItem = item;
      vm.internal_list_dropdown = false;
      vm.$emit("select", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.widget-dropdown-list-icon {
  transition: all 0.3s ease-in-out;

  .btn-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    padding: 0px 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background: #ffffff;
    transition: all 0.3s ease-in-out;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .allow-icon {
      width: 14px;
      height: 14px;
      margin-left: 10px;
    }
  }

  .dropdown-widget {
    .dropdown-item {
      display: flex;
      align-items: center;
      padding: 10px 20px;

      .dropdown-item-icon {
        margin-right: 5px;
        display: flex;
        align-items: center;

        .item-icon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
</style>
  