<template>
  <div class="wrapper">
    <div class="milt-select-widget" ref="milt_select_widget" @click="shop_pop_up = !shop_pop_up">
      <label>
        <slot></slot>
      </label>
      <img width="13" src="@/assets/admin-icon/allow-down-grey.svg" />
    </div>
    <!-- When on school only -->
    <div class="select-items-widget" v-if="shop_pop_up &&
      teams_data.length > 0 &&
      $localStorage.get('role') == 'team-manager'
      " v-closable="{
    exclude: ['milt_select_widget'],
    handler: 'hidePopUp',
  }">
      <div class="select-item" v-for="(item, index) in teams_data" :key="index"
        :class="{ active: selected_item == item.id }" @click="onChange(item, 'team')">
        <label :for="`id_${item.value}`">{{ item.name | validate }}</label>
      </div>
    </div>
    <!-- Department - school -->
    <div class="select-items-drowpdown-widget" v-else-if="shop_pop_up && department_data.length > 0" v-closable="{
      exclude: ['milt_select_widget'],
      handler: 'hidePopUp',
    }">
      <div @click="onChange(null, 'school')" class="select-item school">
        All Departments
      </div>
      <div class="select-item" v-for="(item, index) in department_data" :key="index">
        <label @click="onChange(item, 'department')" :for="`id_${item.value}`">{{ item.name | validate }}</label>
        <div class="drowdown-widget">
          <div class="dropdown-item" v-for="(team, index) in item.teams" :key="index"
            :class="{ active: selected_item == team.id }" @click="onChange(team, 'team')">
            {{ team.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "milt-select-widget",
  props: {
    value: {
      type: String,
      default: "",
    },
    school: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      teams_data: [],
      department_data: [],
      selected_item: "",
      shop_pop_up: false,
    };
  },
  computed: {},
  watch: {
    value(val) {
      let vm = this;
      vm.selected_item = val;
    },
  },
  mounted() {
    let vm = this;
    vm.getTeamDepData();
    if (vm.value) {
      vm.selected_item = vm.value;
    }
  },
  methods: {
    getTeamDepData() {
      let vm = this;
      vm.$store
        .dispatch("GET_ALL_USER_INFO")
        .then((response) => {
          if (vm.$localStorage.get("role") == "team-manager") {
            vm.teams_data = response.data;
          } else {
            vm.department_data = response.data.map((item, index) => {
              return item;
            });
          }
        })
        .catch((error) => {
          console.error({ error });
        });
    },
    hidePopUp() {
      let vm = this;
      vm.shop_pop_up = false;
    },
    onChange(item, type) {
      let vm = this;
      if (type != "school") {
        vm.selected_item = item.id;
      }
      vm.shop_pop_up = false;
      vm.$emit("onSubmit", { data: item, type: type });
      vm.$emit("input", vm.selected_item);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.wrapper {
  position: relative;
  border-radius: 5px;
  padding: 0px;
  min-width: 160px;

  .milt-select-widget {
    display: flex;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 3px;

    label {
      margin-right: 8px;
    }
  }

  &.as-input {
    width: 100%;

    .milt-select-widget {
      padding: 0px 10px;
      border-radius: 5px;
      font-weight: 300;
      width: 100%;
      height: 30px;
      position: relative;
      background: #fff;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      outline: none;

      label {
        color: #000;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 150px;
      }
    }
  }

  .select-items-widget {
    position: absolute;
    top: 25px;
    width: 150px;
    left: 0px;
    background: #ffffff;
    border: 1px solid #f2f4f8;
    box-sizing: border-box;
    border-radius: 5px;
    min-width: 117px;
    overflow-y: auto;
    z-index: 99999999999999;
    height: 250px;

    .select-item {
      border-bottom: 1px solid #f2f4f8;
      padding: 7px 15px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.school {
        padding: 10px 10px;

        &:hover {
          background: rgb(243, 243, 243);
        }
      }

      &.active {
        border-left: 2px solid#0884af;
      }

      &:hover {
        background: rgb(243, 243, 243);
      }

      label {
        margin-top: 2px;
        margin-left: 7px;
      }
    }
  }

  .select-items-drowpdown-widget {
    position: absolute;
    top: 25px;
    width: 150px;
    height: 250px;
    left: 0px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    min-width: 117px;
    overflow-y: auto;
    z-index: 99999999999999;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    .select-item {
      border-bottom: 1px solid #f2f4f8;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &.school {
        padding: 10px 10px;

        &:hover {
          background: rgb(243, 243, 243);
        }
      }

      label {
        background: #f2f4f8;
        padding: 6px 10px;
        cursor: pointer;
      }

      .drowdown-widget {
        padding-left: 10px;

        .dropdown-item {
          padding: 4px 10px;

          &.active {
            border-left: 2px solid#0884af;
          }

          &:hover {
            background: rgb(243, 243, 243);
          }
        }
      }
    }
  }
}
</style>