<template>
  <div class="page-modal">
    <widgetModal
      :title="'Send Email'"
      subtitle="You can send email directly from this form"
      btnlefttext="Send Mail"
      btncentertext="Cancel"
      btnrighttext=""
      :leftloading="loading"
      :loading="signature_loading"
      :rightloading="false"
      :centerloading="false"
      :footerhidden="false"
      :hiderightbtn="true"
      footer_padding="10px 18px 30px 30px"
      header_padding="30px 0px 0px 31px"
      body_padding="0px 30px"
      width="550px"
      @onLeftClick="onConfirm"
      @onCenterClick="onCancel"
    >
      <div class="widget-modal-body-noscoll">
        <widgetInputWrapper title="Subject">
          <widgetInput
            v-model="form.subject"
            type="text"
            placeholder=""
          ></widgetInput>
        </widgetInputWrapper>
        <widgetInputWrapper title="Message">
          <widgetRitchEditor v-model="form.message"></widgetRitchEditor>
        </widgetInputWrapper>
      </div>
    </widgetModal>
  </div>
</template>
<script>
import { eventBus } from "@/main";
export default {
  name: "page-modal",
  props: {
    content: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "account",
    },
  },
  data() {
    return {
      form: {
        ids: null,
        type: "account",
        subject: "",
        message: "",
      },
      loading: false,
      signature_loading: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    let vm = this;
    vm.getDefaultSignature();
  },
  methods: {
    getDefaultSignature() {
      let vm = this;
      vm.signature_loading = true;
      vm.$store
        .dispatch("PROCESSMESSAGE_EMAIL_GET_DEFAULT_SIGNATURE")
        .then((res) => {
          vm.signature_loading = false;
          vm.form.message = "<br><br>" + res.data.message;
        })
        .catch((err) => {
          vm.signature_loading = false;
        });
    },
    onConfirm() {
      let vm = this;
      vm.form.ids = vm.content
        .filter((item) => !item.optedOut)
        .map((item) => item.id);
      vm.form.type = vm.type;
      const rules = {
        subject: {
          run: (value) => {
            if (value) {
              return false;
            }
            return "Subject can not be empty";
          },
        },
        message: {
          run: (value) => {
            if (value) {
              return false;
            }
            return "Message can not be empty";
          },
        },
      };
      if (vm.$isFieldsValidated(vm.form, rules)) {
        vm.loading = true;
        vm.$store
          .dispatch("ACCOUNT_SEND_SELECTED_MESSAGE", vm.form)
          .then((response) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              title: "Message",
              text: "Email was sent",
              type: "success",
              duration: 3000,
            });
            vm.$emit("success", null);
          })
          .catch((error) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              title: "Message",
              text: "Something went wrong",
              type: "warn",
              duration: 3000,
            });
            console.error({ error });
          });
      }
    },
    onCancel() {
      let vm = this;
      vm.$emit("close", null);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";
.page-modal {
}
</style>