import Vue from 'vue'
import VueRouter from 'vue-router'
import UsersManagement from '../views/pages/UsersManagement'
import UserSchool from '../views/pages/UsersManagement/tabs/index.vue'
import AdminLayout from "../views/AdminLayout.vue";
import Login from "../views/Login.vue";
import SystemNotification from "../views/pages/SystemNotification";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '',
    name: 'layout',
    component: AdminLayout,
    children: [
      {
        path: '/',
        name: 'users-management',
        component: UsersManagement,
      },
      {
        path: '/user/school/:id?',
        name: 'user-school',
        component: UserSchool,
      },
      {
        path: '/system-notification',
        name: 'system-notification',
        component: SystemNotification,
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
