<template>
  <div class="profile-widget" @click="onClick">
    <div class="profile-image" v-if="file_name" :class="{ margin_right: enableRightMargin, 'white-border': whiteBorder }"
      :title="title" :style="{
        width: size + 'px',
        height: size + 'px',
      }">
      <img :src="path + file_name" />
    </div>
    <div class="profile-alphabet" :title="title" :style="{
      width: parseInt(size) + 'px',
      height: parseInt(size) + 'px',
    }" :class="{ margin_right: enableRightMargin, 'white-border': whiteBorder }"
      v-else-if="$validateString(title) && $validateString(title) != ' '">
      <span :style="{
        fontSize: parseInt(text_size) + 'px',
      }">{{ $nameInitials(title) | validate }}</span>
    </div>
    <div class="profile-widget-content" :title="title" :style="{
      width: contentWidth == 0 ? 'max-content' : contentWidth + 'px',
    }" v-if="show_name && showContent">
      <span class="title" v-if="title">
        {{ $validateString(title) | validate }}
      </span>
      <span class="subtitle" v-if="subtitle">
        {{ subtitle | validate }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "widget-profile-viewer",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    file_name: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    show_name: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 25,
    },
    text_size: {
      type: Number,
      default: 10,
    },
    showContent: {
      type: Boolean,
      default: true,
    },
    enableRightMargin: {
      type: Boolean,
      default: true,
    },
    whiteBorder: {
      type: Boolean,
      default: false,
    },
    contentWidth: {
      type: Number,
      default: 123,
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() { },
  methods: {
    $validateString(val) {
      return val ? val.replaceAll("null", "") : "";
    },
    onClick(e) {
      let vm = this;
      vm.$emit("click", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-widget {
  display: flex;
  align-items: center;

  .profile-alphabet {
    height: 35px;
    width: 35px;
    background: #0884af;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 13px;
    font-weight: 800;

    &.margin_right {
      margin-right: 10px;
    }

    &.white-border {
      border: 4px solid #fff;
    }
  }

  .profile-image {
    background: #0884af;
    border-radius: 50%;
    overflow: hidden;
    height: 35px;
    width: 35px;

    &.white-border {
      border: 4px solid #fff;
    }

    img {
      width: 100%;
      height: 100%;
      background: #1ba5a438;
      object-fit: cover;
      position: relative;
      top: 2px;
    }

    &.margin_right {
      margin-right: 10px;
    }
  }

  .profile-widget-content {
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    .title {
      color: #000000;
      font-weight: 600;
      font-size: 11px;
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: capitalize;
    }

    .subtitle {
      color: #636363;
      font-size: 10px;
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: capitalize;
    }
  }

  &.active {
    .profile-alphabet {
      background: #fff;
      border: 1px solid #0884af;
      color: #0884af;
    }
  }

  &.primary {
    background: transparent !important;

    .profile-alphabet {
      background: #1ba5a4;
      color: #fff;
    }

    .profile-image {
      background: #1ba5a4;
    }
  }
}
</style>
