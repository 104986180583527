<template>
  <div class="page-modal">
    <!-- Single -->
    <widgetModal v-if="internship_form.save_type == 'single'" :title="content ? 'LIA information' : 'Create LIA'"
      :subtitle="content
        ? 'Fill in the information below to update LIA'
        : 'Fill in the information below to generate an LIA'
        " btnlefttext="Save Changes" btncentertext="Cancel" btnrighttext="Add Multiple LIA"
      :leftloading="internship_loading" :rightloading="false" :centerloading="false" :footerhidden="false"
      footer_padding="10px 18px 30px 30px" header_padding="30px 0px 0px 31px" body_padding="0px 30px" width="500px"
      :oldhiderightbtn="type == 'gen' ? true : content ? true : false" :hiderightbtn="true" @onLeftClick="onConfirm()"
      @onCenterClick="onCancel" @onRightClick="onRightClick()">
      <div class="widget-modal-body-noscoll">
        <widgetInputWrapper v-if="account_data.origin == 'account'"
          :title="account_data.type == 'single' ? 'Account Name' : 'Accounts'">
          <widgetAccountInput :miltiple="account_data.type == 'single' ? false : true" v-model="selected_accounts"
            :readonly="!add_accounts" type="text" placeholder="" @onNewAccount="(payload) => {
              internship_form.new_company = payload.enabled ? 1 : 0;
              internship_form.company_name = payload.name;
            }
              "></widgetAccountInput>
        </widgetInputWrapper>
        <widgetInputWrapper v-if="type != 'gen'" title="Select LIA Contact">
          <searchSelectInput :url="`/contact/search`" :miltiple="false" :hasPagination="true" v-model="internship_contact"
            placeholder="Search Contact">
            <template v-slot:result="{ result }">
              <span class="search-contact-item">{{ result.first_name | validate }}
                {{ result.last_name | validate }}</span>
            </template>
            <template v-slot:selected="{ selected }">
              <span class="selected-search-contact-item">
                {{ selected.first_name | validate }}
                {{ selected.last_name | validate }}
              </span>
            </template>
          </searchSelectInput>
        </widgetInputWrapper>
        <div class="row" v-if="type != 'gen'">
          <widgetInputWrapper title="LIA Title">
            <widgetInput type="text" v-model="internship_form.name" placeholder=""></widgetInput>
          </widgetInputWrapper>
          <div class="h-spacer"></div>
          <widgetInputWrapper title="Location">
            <map-helper :isMultiple="true" v-model="internship_form.location"></map-helper>
          </widgetInputWrapper>
        </div>
        <div class="row">
          <widgetInputWrapper title="Time frame">
            <widgetDatePicker left_input_placeholder="From" right_input_placeholder="To" v-model="internship_date"
              :left_input_disabled="false" :right_input_disabled="false" :left_input_disabled_dates="{}"
              :right_input_disabled_dates="{}"></widgetDatePicker>
          </widgetInputWrapper>

          <div class="h-spacer"></div>
          <widgetInputWrapper title="Class">
            <widgetClassDropdown v-model="internship_form.classes"></widgetClassDropdown>
          </widgetInputWrapper>
        </div>
        <div class="row align-start">
          <widgetInputWrapper title="Description" v-if="type != 'gen'">
            <widgetTextarea v-model="internship_form.description" placeholder=""></widgetTextarea>
          </widgetInputWrapper>
          <div class="h-spacer" v-if="type != 'gen'"></div>
          <widgetInputWrapper v-if="account_data.type == 'single'" title="Tags">
            <widgetTags v-model="tags" :input_width="type != 'gen' ? '100%' : '50%'" placeholder="Add tags"></widgetTags>
          </widgetInputWrapper>
        </div>
      </div>
    </widgetModal>
    <!-- Many -->
    <widgetModal v-if="internship_form.save_type == 'many'" :footerhidden="true" :headerhidden="true"
      body_padding="0px 0px" width="550px">
      <uploadManyItems file="https://storage.googleapis.com/consultified_media/files/templates/liasTemplate.xlsx"
        title="Upload LIA" description="To upload many LIA as file, you need to download the .xl file
                                              template here. Add LIA to the file and then repload it in the
                                              next step." actiontext="Upload below the filled document with LIA"
        @back="internship_form.save_type = 'single'" @onFile="(file) => {
          if (file) {
            internship_form.selected_file = file;
          }
        }
          " :loading="internship_loading" @onSubmit="onConfirm('save')">
        <widgetInputWrapper title="Class">
          <widgetClassDropdown v-model="internship_form.classes"></widgetClassDropdown>
        </widgetInputWrapper>
      </uploadManyItems>
    </widgetModal>
  </div>
</template>
<script>
import { eventBus } from "@/main";
export default {
  name: "page-modal",
  props: {
    account_data: {
      type: Object,
      default: () => {
        return {
          type: "single",
          accounts: {
            name: "",
          },
        };
      },
    },
    content: {
      type: Object,
      default: () => null,
    },
    add_accounts: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      internship_form: {
        save_type: "single",
        id: null,
        account_id: null,
        accounts: [],
        is_miltiple_accounts: true,
        from: "",
        to: "",
        classes: {
          is_all_classes: false,
          is_my_classes: false,
          selected_classes: [],
        },
        name: "",
        token: "",
        company_id: null,
        company_data: "",
        new_company: 0,
        company_name: "",
        company_email: "",
        invite_company: false,
        location: "",
        description: "",
        selected_file: null,
        tags: [],
        tags_added: [],
        tags_removed: [],
        contact_id: null,
        contact_obj: null,
        isAllContactsSelected: false,
      },
      tags: {
        added: [],
        removed: [],
      },
      internship_date: { from: new Date(), to: new Date() },
      internship_loading: false,
      account_name: "",
      selected_accounts: null,
      internship_contact: null,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    let vm = this;
    vm.tags = {
      added: [],
      removed: [],
    };
    if (vm.content) {
      vm.internship_form.is_miltiple_accounts =
        vm.account_data.type == "single" ? false : true;
      vm.updateContent(vm.content);
    } else {
      if (vm.account_data) {
        vm.internship_form.is_miltiple_accounts =
          vm.account_data.type == "single" ? false : true;
        if (vm.account_data.origin == "account") {
          vm.selected_accounts = vm.account_data.accounts;
          if (vm.internship_form.is_miltiple_accounts) {
            vm.selected_accounts.forEach((account) => {
              account.tags.forEach((item) => {
                if (
                  !vm.tags.added.find((added_tag) => {
                    return added_tag.name == item.name;
                  })
                ) {
                  eventBus.$set(item, "account", true);
                  vm.tags.added.push(item);
                }
              });
            });
          } else {
            vm.selected_accounts.tags.forEach((item) => {
              if (
                !vm.tags.added.find((added_tag) => {
                  return added_tag.name == item.name;
                })
              ) {
                vm.tags.added.push(item);
              }
            });
          }
        }

        // This is for genelating Lia
        if (vm.account_data.origin == "contact") {
          vm.selected_accounts = vm.account_data.contacts
            .filter((contact) => {
              return contact.contact_type == 'company_contact';
            })
            .map((contact) => {
              return {
                id: contact.id,
                title: contact.title,
                location: contact.location,
                description: contact.description,
                tags: contact.tags,
                first_name: contact.first_name,
                last_name: contact.last_name,
                public_contact: contact.public_contact,
                worktype: contact.worktype,
                website: contact.website,
                account: contact.account,
                contact_type: contact.contact_type,
                schools: contact.schools,
              };
            });
          if (vm.internship_form.is_miltiple_accounts) {
            vm.selected_accounts.forEach((account) => {
              account.tags.forEach((item) => {
                if (
                  !vm.tags.added.find((added_tag) => {
                    return added_tag.name == item.name;
                  })
                ) {
                  eventBus.$set(item, "account", true);
                  vm.tags.added.push(item);
                }
              });
            });
          } else {
            vm.selected_accounts.tags.forEach((item) => {
              if (
                !vm.tags.added.find((added_tag) => {
                  return added_tag.name == item.name;
                })
              ) {
                vm.tags.added.push(item);
              }
            });
          }
        }
      }
      vm.addNew();
    }
  },
  methods: {
    onRightClick() {
      let vm = this;
      vm.internship_form.save_type = "many";
    },
    updateContent(item) {
      let vm = this;
      if (item.account && item.account != "{}") {
        vm.selected_accounts = item.account;
      } else {
        vm.selected_accounts = null;
      }
      vm.internship_form.id = item.id;
      vm.internship_form.name = item.name;
      if (item.company) {
        vm.internship_form.company_id = item.company.id;
        vm.internship_form.new_company = 0;
        vm.internship_form.company_name = item.company.name;
        vm.internship_form.company_email = item.company.email;
      }
      vm.internship_form.invite_company = false;
      // Check is location is array
      if (Array.isArray(item.location)) {
        vm.internship_form.location = item.location;
      } else {
        vm.internship_form.location = [];
      }
      vm.internship_form.description = item.description;
      vm.tags = {
        added: [],
        removed: [],
      };
      if (item.tags) {
        item.tags.forEach((item) => {
          eventBus.$set(item, "on_update", true);
          vm.tags.added.push(item);
        });
      }

      vm.internship_date = {
        from:
          item.from && item.from != "0000-00-00 00:00:00"
            ? vm.moment(item.from).toDate()
            : "",
        to:
          item.to && item.to != "0000-00-00 00:00:00"
            ? vm.moment(item.to).toDate()
            : "",
      };

      vm.internship_form.from = item.from;
      vm.internship_form.to = item.to;
      if (item.class_obj) {
        vm.internship_form.classes = JSON.parse(item.class_obj);
      }
      // Show contact on update
      vm.internship_form.contact_id = item.account_id;
      vm.internship_form.contact_obj = item.account;
      eventBus.$set(item.account, "on_update", true);
      vm.internship_contact = {
        added: [],
        removed: [],
      };
      vm.internship_contact.added.push(item.account);
    },
    addNew() {
      let vm = this;
      vm.show_internship_modal = true;
      vm.internship_form = {
        save_type: "single",
        id: null,
        account_id: null,
        accounts: [],
        is_miltiple_accounts: true,
        from: "",
        to: "",
        classes: {
          is_all_classes: false,
          is_my_classes: false,
          selected_classes: [],
        },
        name: "",
        token: "",
        company_id: null,
        company_data: "",
        new_company: 0,
        company_name: "",
        company_email: "",
        invite_company: false,
        location: "",
        description: "",
        selected_file: null,
        tags: [],
        tags_added: [],
        tags_removed: [],
        contact_id: null,
        contact_obj: null,
        isAllContactsSelected: false
      };
      vm.internship_date = {
        from: new Date(),
        to: new Date(),
      };
      vm.internship_form.isAllContactsSelected = vm.account_data.allSelected;
    },
    onConfirm() {
      let vm = this;
      if (vm.internship_contact && vm.internship_contact.added.length > 0) {
        vm.internship_form.contact_id = vm.internship_contact.added[0].id;
        vm.internship_form.contact_obj = vm.internship_contact.added[0];
      } else {
        vm.internship_form.contact_id = null;
        vm.internship_form.contact_obj = null;
      }
      vm.internship_form.from = vm
        .moment(vm.internship_date.from)
        .format("YYYY-MM-DD HH:mm:ss");
      vm.internship_form.to = vm
        .moment(vm.internship_date.to)
        .format("YYYY-MM-DD HH:mm:ss");
      vm.internship_form.tags = vm.tags.added.map((item) => {
        return item;
      });
      vm.internship_form.accounts = JSON.stringify(vm.selected_accounts);

      const rules = {
        contact_id: {
          run: (value) => {
            if (
              vm.internship_form.save_type == "single" &&
              vm.type != "gen" &&
              !value
            ) {
              return "Please select LIA Contact";
            }
            return false;
          },
        },
        name: {
          run: (value) => {
            if (value && value.length > 0) {
              return false;
            }
            if (vm.internship_form.save_type == "single" && vm.type != "gen") {
              return "LIA title can not be empty";
            } else {
              return false;
            }
          },
        },
      };
      if (vm.$isFieldsValidated(vm.internship_form, rules)) {
        let formData = new FormData();
        vm.internship_loading = true;

        if (vm.internship_form.save_type == "single") {
          formData.append("id", vm.internship_form.id);
          formData.append("token", sessionStorage.authToken);
          formData.append("save_type", vm.internship_form.save_type);
          formData.append("type", vm.type);
          formData.append(
            "is_miltiple_accounts",
            vm.internship_form.is_miltiple_accounts
          );
          if (vm.internship_form.is_miltiple_accounts) {
            formData.append("accounts", JSON.stringify(vm.selected_accounts));
          } else {
            formData.append("account", JSON.stringify(vm.selected_accounts));
          }
          formData.append("from", vm.internship_form.from);
          formData.append("to", vm.internship_form.to);
          formData.append(
            "classes",
            JSON.stringify(vm.internship_form.classes)
          );
          formData.append("name", vm.internship_form.name);
          formData.append("company_id", vm.internship_form.company_id);
          formData.append("company_data", vm.internship_form.company_data);
          formData.append("new_company", vm.internship_form.new_company);
          formData.append("company_name", vm.internship_form.company_name);
          formData.append("company_email", vm.internship_form.company_email);
          formData.append("invite_company", vm.internship_form.invite_company);
          formData.append(
            "location",
            JSON.stringify(vm.internship_form.location)
          );
          formData.append("description", vm.internship_form.description);
          formData.append("contact_id", vm.internship_form.contact_id);
          formData.append(
            "contact_obj",
            JSON.stringify(vm.internship_form.contact_obj)
          );
          if (vm.account_data.type == "single") {
            formData.append(
              "tags",
              JSON.stringify(
                vm.internship_form.tags.filter((item) => !item.account)
              )
            );
          }
        } else {
          formData.append(
            "classes",
            JSON.stringify(vm.internship_form.classes)
          );
          formData.append("token", sessionStorage.authToken);
          formData.append(
            "file",
            vm.internship_form.selected_file,
            vm.internship_form.selected_file.name
          );
        }
        formData.append("isAllContactsSelected", vm.internship_form.isAllContactsSelected);

        for (var pair of formData.entries()) {
          let formDataKey = pair[0];
          let formDataValue = pair[1];
          if (formDataValue == "null") {
            formData.set(formDataKey, "");
          }
        }

        if (!vm.internship_form.id) {
          vm.$store
            .dispatch("INSTRUCTOR_SAVE_COMPANY_INTERNSHIP", formData)
            .then((responce) => {
              vm.internship_loading = false;
              vm.$notify({
                group: "status",
                title: "Message",
                text: "LIA is now added",
                type: "success",
                duration: 3000,
              });
              vm.$emit("success", null);
            })
            .catch((error) => {
              vm.internship_loading = false;
              vm.$notify({
                group: "status",
                title: "Message",
                text: "Something went wrong, try again",
                type: "warn",
                duration: 3000,
              });
            });
        } else {
          vm.internship_loading = true;
          // For tags
          vm.internship_form.tags_added = vm.tags.added
            .filter((item) => !item.on_update)
            .map((item) => {
              return item;
            });
          vm.internship_form.tags_removed = vm.tags.removed.map((item) => {
            return item;
          });

          formData.append(
            "tags_added",
            JSON.stringify(vm.internship_form.tags_added)
          );
          formData.append(
            "tags_removed",
            JSON.stringify(vm.internship_form.tags_removed)
          );
          vm.$store
            .dispatch("INSTRUCTOR_UPDATE_COMPANY_INTERNSHIP", formData)
            .then((responce) => {
              vm.show_internship_modal = false;
              vm.$notify({
                group: "status",
                title: "Message",
                text: "LIA is now updated",
                type: "success",
                duration: 3000,
              });
              vm.$emit("success", null);
            })
            .catch((error) => {
              vm.internship_loading = false;
              vm.$notify({
                group: "status",
                title: "Message",
                text: "Something went wrong, try again",
                type: "warn",
                duration: 3000,
              });
            });
        }
      }
    },
    onCancel() {
      let vm = this;
      vm.$emit("close", null);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.page-modal {
  .widget-modal-body-noscoll {}
}
</style>