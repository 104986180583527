/* eslint-disable no-undef */
import {
    API,
    SITE_API,
    FILE_MAKER_SERVER_BASE_URL,
    CVS_ADD_NEW_TEMPLATE_URL,
    CVS_UPDATE_TEMPLATE_URL,
    CVS_GET_TEMPLATES_URL,
    CVS_DELETE_TEMPLATE_URL,
    CVS_APPLY_TEMPLATE_URL,
    CVS_RENAME_TEMPLATE_URL,
    CVS_SEARCH_STUDENT_URL,
    CVS_GET_STUDENT_PROJECTS_URL,
    CVS_GET_STUDENT_EMPLOYMENTS_URL,
    CVS_GET_STUDENT_SKILLS_URL,
    CVS_GET_STUDENT_QUALIFICATION_URL,
    CVS_UPLOAD_IMAGES_URL,
    CVS_ALL_IMAGES_URL,
    CVS_UPLOAD_BACKGROUND_URL,
    CVS_ALL_BACKGROUND_URL,
    CVS_GET_STUDENT_INFORMATION_URL,
    CVS_IMAGE_DELETE_URL,
    CVS_SHARE_TEMPLATE_URL,
    CVS_STOP_SHARE_TEMPLATE_URL,
    CVS_SUBMIT_CV_URL,
    CVS_GET_CVS_URL,
    CVS_UPDATE_CV_URL,
    CVS_DELETE_CV_URL,
    CVS_SET_AS_DEFAULT_URL,
    CVS_GET_TEMPLATE_ITEM_URL,
    CVS_ADD_NEW_COMPONENT_URL,
    CVS_GET_TEMPLATE_COMPONENTS_URL,
    CVS_DELETE_TEMPLATE_COMPONENT_URL,
    CVS_GENELATE_FILE_URL
} from "../variable";

import axios from 'axios'

import {
    GET_IMAGES_FROM_UNSPLASH,
    CVS_ADD_NEW_TEMPLATE,
    CVS_UPDATE_TEMPLATE,
    CVS_GET_TEMPLATES,
    CVS_DELETE_TEMPLATE,
    CVS_APPLY_TEMPLATE,
    CVS_RENAME_TEMPLATE,
    CVS_SEARCH_STUDENT,
    CVS_GET_STUDENT_PROJECTS,
    CVS_GET_STUDENT_EMPLOYMENTS,
    CVS_GET_STUDENT_SKILLS,
    CVS_GET_STUDENT_QUALIFICATION,
    CVS_UPLOAD_IMAGES,
    CVS_ALL_IMAGES,
    CVS_UPLOAD_BACKGROUND,
    CVS_ALL_BACKGROUND,
    CVS_GET_STUDENT_INFORMATION,
    CVS_IMAGE_DELETE,
    CVS_SHARE_TEMPLATE,
    CVS_STOP_SHARE_TEMPLATE,
    CVS_SUBMIT_CV,
    CVS_GET_CVS,
    CVS_UPDATE_CV,
    CVS_DELETE_CV,
    CVS_SET_AS_DEFAULT,
    CVS_GET_TEMPLATE_ITEM,
    CVS_ADD_NEW_COMPONENT,
    CVS_GET_TEMPLATE_COMPONENTS,
    CVS_DELETE_TEMPLATE_COMPONENT,
    CVS_GENELATE_FILE
} from "../Actions/cvs";
import router from "../../router";

const state = {

};

const actions = {
    [CVS_GENELATE_FILE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: FILE_MAKER_SERVER_BASE_URL + CVS_GENELATE_FILE_URL,
            method: "POST",
            data: validatePayloadData(payload)
        });
    },
    [CVS_ADD_NEW_COMPONENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + CVS_ADD_NEW_COMPONENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_GET_TEMPLATE_COMPONENTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + CVS_GET_TEMPLATE_COMPONENTS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_DELETE_TEMPLATE_COMPONENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + CVS_DELETE_TEMPLATE_COMPONENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_GET_TEMPLATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + CVS_GET_TEMPLATE_ITEM_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_SET_AS_DEFAULT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + CVS_SET_AS_DEFAULT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_DELETE_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_DELETE_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_UPDATE_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_UPDATE_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_GET_CVS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_GET_CVS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_SUBMIT_CV]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_SUBMIT_CV_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_STOP_SHARE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_STOP_SHARE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_SHARE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_SHARE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_IMAGE_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_IMAGE_DELETE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_GET_STUDENT_INFORMATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_GET_STUDENT_INFORMATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_UPLOAD_IMAGES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_UPLOAD_IMAGES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken, 'Content-Type': 'multipart/form-data' }
        });
    },
    [CVS_ALL_IMAGES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_ALL_IMAGES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_UPLOAD_BACKGROUND]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_UPLOAD_BACKGROUND_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken, 'Content-Type': 'multipart/form-data' }
        });
    },
    [CVS_ALL_BACKGROUND]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_ALL_BACKGROUND_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_GET_STUDENT_EMPLOYMENTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_GET_STUDENT_EMPLOYMENTS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_GET_STUDENT_SKILLS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_GET_STUDENT_SKILLS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_GET_STUDENT_QUALIFICATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_GET_STUDENT_QUALIFICATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_SEARCH_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_SEARCH_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_GET_STUDENT_PROJECTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_GET_STUDENT_PROJECTS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.siteAuthToken }
        });
    },
    [CVS_APPLY_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_APPLY_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [CVS_RENAME_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_RENAME_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [CVS_DELETE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_DELETE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [CVS_GET_TEMPLATES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_GET_TEMPLATES_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [CVS_UPDATE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_UPDATE_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken, 'Content-Type': 'multipart/form-data' }
        });
    },
    [CVS_ADD_NEW_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + CVS_ADD_NEW_TEMPLATE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken, 'Content-Type': 'multipart/form-data' }
        });
    },
    [GET_IMAGES_FROM_UNSPLASH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: "https://api.unsplash.com/photos/?client_id=WCLpxkGimRieYTj48dIw-MHfcvRhOTll7YF4f0MIP20&per_page=50&page=1",
            method: "GET",
        });
    },


};

const mutations = {};

const getters = {};

export default {
    state,
    getters,
    actions,
    mutations
};