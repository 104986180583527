var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"page-body"},[_c('div',{staticClass:"tabs-widget"},[_c('div',{staticClass:"tabs-links"},[_c('div',{staticClass:"tab",class:{ active: _vm.tab_type == 'public' },staticStyle:{"justify-content":"flex-start"},on:{"click":function($event){return _vm.switchTab('public')}}},[_c('span',{staticClass:"name"},[_vm._v("Summary")])]),_c('div',{staticClass:"tab",class:{
          active:
            _vm.tab_type == 'students' ||
            _vm.$route.query.profile_type == 'student',
        },on:{"click":function($event){return _vm.switchTab('students')}}},[_c('span',{staticClass:"name"},[_vm._v("Students")])]),_c('div',{staticClass:"tab",class:{
          active:
            _vm.tab_type == 'instructors' ||
            _vm.$route.query.profile_type == 'instructor',
        },on:{"click":function($event){return _vm.switchTab('instructors')}}},[_c('span',{staticClass:"name"},[_vm._v("Instructors")])]),_c('div',{staticClass:"tab",class:{ active: _vm.tab_type == 'skills' },on:{"click":function($event){return _vm.switchTab('skills')}}},[_c('span',{staticClass:"name"},[_vm._v("Skills")])]),_c('div',{staticClass:"tab",class:{
          active: _vm.tab_type == 'teams' || _vm.$route.name == 'view-team',
        },on:{"click":function($event){return _vm.switchTab('teams')}}},[_c('span',{staticClass:"name"},[_vm._v("Schools")])])])]),_c('div',{staticClass:"tab-body"},[(_vm.tab_type == 'public')?_c('general-information',{attrs:{"school":_vm.school}}):_vm._e(),(_vm.tab_type == 'students')?_c('Consultants',{attrs:{"school":_vm.school}}):_vm._e(),(_vm.tab_type == 'instructors')?_c('Instructors',{attrs:{"school":_vm.school}}):_vm._e(),(_vm.tab_type == 'skills')?_c('Skills',{attrs:{"school":_vm.school}}):_vm._e(),(_vm.tab_type == 'project-removed')?_c('Projects',{attrs:{"school":_vm.school}}):_vm._e(),(_vm.tab_type == 'teams')?_c('Teams',{attrs:{"school":_vm.school}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }