import {
    GET_PROFILE_FEEDS,
    GET_FEEDS_POSTS,
    GET_MORE_FEEDS_POSTS,
    POST_FEED_POST,
    TAG_FEED_POST,
    POST_FEED_COMMENT,
    POST_FEED_LIKE,
    DELETE_FEED_POST,
    POST_SEARCH_TAGS,
    POST_FEED_COMMENT_TEXT,
    REMOVE_FEED_COMMENT,
    EDIT_POST
} from "../Actions/feed";
import { eventBus } from "../../main";
import router from "../../router";

import {
    API,
    SITE_API,
    GET_PROFILE_FEEDS_URL,
    GET_FEEDS_POSTS_URL,
    POST_FEED_POST_URL,
    TAG_FEED_POST_URL,
    GET_MORE_FEEDS_POSTS_URL,
    POST_FEED_COMMENT_URL,
    POST_FEED_LIKE_URL,
    DELETE_FEED_POST_URL,
    POST_SEARCH_TAGS_URL,
    REMOVE_FEED_COMMENT_URL,
    EDIT_POST_URL
} from "../variable";
import axios from 'axios'

const state = {
    wholePostsData: [],
    postsData: [],
    profileData: null,
    post: {},
    tags: [],
};

const actions = {
    [EDIT_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + getters.get_crm_prefix + EDIT_POST_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });
    },
    [REMOVE_FEED_COMMENT]: ({ commit, dispatch, getters }, payload) => {

        axios({
            url: API + getters.get_crm_prefix + REMOVE_FEED_COMMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }

        }).then(response => { })
            .catch(error => {
                console.log(error.response)
            });
    },
    [GET_PROFILE_FEEDS]: ({ commit, dispatch, getters }, payload) => {

        axios({
            url: API + getters.get_crm_prefix + GET_PROFILE_FEEDS_URL,
            method: "POST",
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }

        }).then(response => {

            commit(GET_PROFILE_FEEDS, response.data)

        })
            .catch(error => {
                console.log(error.response)
            });


    },
    [GET_FEEDS_POSTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + GET_FEEDS_POSTS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }

        })
    },
    [TAG_FEED_POST]: ({ commit, dispatch, getters }, payload) => {
        dispatch("LOADER_START")
        axios({
            url: API + getters.get_crm_prefix + TAG_FEED_POST_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }

        }).then(response => {
            dispatch("LOADER_CLOSE")
            commit(GET_FEEDS_POSTS, { data: response.data, from: 1 });

        })
            .catch(error => {
                dispatch("LOADER_CLOSE")
                console.log(error.response)
            });
    },
    [GET_MORE_FEEDS_POSTS]: ({ commit, dispatch, state }, payload) => {

        axios({
            url: API + getters.get_crm_prefix + GET_FEEDS_POSTS_URL + `?page=${state.wholePostsData.current_page + 1}`,
            method: "POST",
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }

        }).then(response => {
            commit(GET_MORE_FEEDS_POSTS, response.data);
            dispatch("LOADER_MORE_FEEDS", false)

        })
            .catch(error => {
                console.log(error.response)
            });

    },
    [POST_FEED_POST]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: API + getters.get_crm_prefix + POST_FEED_POST_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }

        }).then(response => {
            dispatch("GET_FEEDS_POSTS")
            commit(POST_FEED_POST, response.data)
        }).catch(error => {
            console.log(error.response)
        });

    },
    [POST_FEED_COMMENT]: ({ commit, dispatch, getters }, payload) => {

        axios({
            url: API + getters.get_crm_prefix + POST_FEED_COMMENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }

        }).then(response => {
            commit(POST_FEED_COMMENT, response.data)
        })
            .catch(error => {
                console.log(error.response)
            });

    },
    [POST_FEED_LIKE]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: API + getters.get_crm_prefix + POST_FEED_LIKE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }

        }).then(response => {
            commit(POST_FEED_LIKE, response.data)

        })
            .catch(error => {
                console.log(error.response)
            });


    },
    [DELETE_FEED_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + DELETE_FEED_POST_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }

        });
    },
    [POST_SEARCH_TAGS]: ({ commit, dispatch, getters }, payload) => {
        axios({
            url: API + getters.get_crm_prefix + POST_SEARCH_TAGS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        }).then(response => {
            commit(POST_SEARCH_TAGS, response.data)
        }).catch(error => {
            console.log(error.response)
        });
    },
};

const mutations = {

    [GET_PROFILE_FEEDS]: (state, payload) => {
        state.profileData = payload;
        console.log(payload)
    },

    [GET_FEEDS_POSTS]: (state, payload) => {
        if (payload.from === 2) {
            state.postsData = payload.data.data.map(function (item) {
                item.showComment = false
                item.text = '';
                return item;
            });
            state.wholePostsData = payload.data;
        } else {
            state.postsData = payload.data.map(function (item) {
                item.showComment = false
                item.text = '';
                return item;
            });
        }



    },

    [GET_MORE_FEEDS_POSTS]: (state, payload) => {
        if (payload.data.length == 0) {
            console.log(" empty array");
        } else {
            console.log("not  empty array");
            console.log(state.postsData)
            console.log(payload.data)
            let newposts = []
            let beforeS = state.postsData
            let addToS = payload.data
            newposts = beforeS.concat(addToS)
            state.postsData = newposts

            // state.postsData.concat(payload.data)

            state.wholePostsData = payload;
        }


        // state.postsData.push(payload);

    },

    [POST_FEED_POST]: (state, payload) => {
        // state.postsData.push(payload.data);
    },
    [POST_FEED_COMMENT]: (state, payload) => {
        state.postsData.some(function (post) {
            state.postsData.some(function (post) {
                if (post.id === payload.feed_id) {
                    console.log(post)
                    eventBus.$set(state.postsData[state.postsData.indexOf(post)], 'comments', payload)
                    return true
                }
            });
        });
    },
    [POST_FEED_COMMENT_TEXT]: (state, payload) => {
        state.postsData.some(function (post) {
            if (post.id === payload.feed_id) {
                eventBus.$set(post, 'text', payload)
                return true
            }
        });
    },
    [POST_FEED_LIKE]: (state, payload) => {
        state.postsData.some(function (post) {
            if (post.id === payload.id) {
                post.likes.push(payload.data)

                return true
            }
        });


    },
    [DELETE_FEED_POST]: (state) => {
        state.postsData.data.splice(index, 1)

    },
    [POST_SEARCH_TAGS]: (state, payload) => {
        state.tags = payload
    },

};

const getters = {

    getFeedsProfile(state) {
        return state.profileData;
    },
    getFeedsPosts(state) {
        return state.postsData;
    },
    getWholeFeedsPosts(state) {
        return state.wholePostsData;
    },
    getTags() {
        return state.tags
    }

};

export default {
    state,
    getters,
    actions,
    mutations
};