<template>
  <div class="dropdown-btn-widget">
    <button ref="dropdown_menu" class="dropdown-btn" @click="onClick()" :disabled="is_loading">
      <img class="loading-icon" src="@/assets/icons/loading_white.gif" v-if="is_loading" alt />
      <span class="text">
        <slot></slot>
      </span>
      <div class="dropdown-icon">
        <img class="allow white" src="@/assets/admin-icon/allow-down-white.svg" />
        <img class="allow dark" src="@/assets/admin-icon/allow-down.svg" />
      </div>
    </button>
    <div v-closable="{ exclude: ['dropdown_menu'], handler: 'hideMenu' }" class="btn-widget-content" v-if="show_content">
      <div class="btn-item" v-for="(item, index) in btn_content" :key="index" @click="selectItem(item)">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "consultified_button",
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      is_loading: false,
      btn_content: [],
      show_content: false,
    };
  },
  watch: {
    loading: function (val) {
      this.is_loading = val;
    },
    content: function (val) {
      this.btn_content = val;
    },
  },
  mounted() {
    let vm = this;
    if (this.loading) {
      this.is_loading = this.loading;
    }
    this.btn_content = vm.content;
  },
  methods: {
    selectItem(item) {
      let vm = this;
      vm.$emit("input", item.value);
      vm.$emit("onSubmit", item.value);
      vm.show_content = false;
    },
    hideMenu() {
      let vm = this;
      vm.show_content = false;
    },
    onClick() {
      let vm = this;
      vm.show_content = !vm.show_content;
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-btn-widget {
  position: relative;
  width: max-content;

  &.blue {
    .dropdown-btn {
      background: #0884af;

      .allow.white {
        display: block;
      }

      .allow.dark {
        display: none;
      }
    }
  }

  &.green {
    .dropdown-btn {
      background: #1ba5a4;

      .allow.white {
        display: block;
      }

      .allow.dark {
        display: none;
      }
    }
  }

  &.default {
    .dropdown-btn {
      background: #ebeff2;

      .text {
        margin-right: 15px;
        color: #000;
      }

      .allow.white {
        display: none;
      }

      .allow.dark {
        display: block;
      }
    }
  }

  .dropdown-btn {
    display: flex;
    background: #ddd;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #fff;
    position: relative;

    .allow.white {
      display: block;
    }

    .allow.dark {
      display: none;
    }

    img.loading-icon {
      width: 20px;
      margin-right: 10px;
    }

    .text {
      margin-right: 8px;
      margin-left: 10px;
    }

    .allow {
      width: 13px;
    }

    .dropdown-icon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 5px;
    }
  }

  .btn-widget-content {
    position: absolute;
    top: 55px;
    right: 0px;
    left: 0px;
    max-height: 300px;
    background: #fff;
    z-index: 9999;
    box-shadow: 0px 0px 27px #ccc;
    overflow-y: auto;
    border-radius: 10px;
    min-width: 158px;

    .btn-item {
      color: #000;
      padding: 15px 15px;
      text-decoration: none;
      white-space: nowrap;
      text-align: left;
      cursor: pointer;

      &:hover {
        background: rgb(241, 241, 241);
      }
    }
  }
}
</style>
