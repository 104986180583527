<template>
  <div class="delete-dialog">
    <span @click="openDialog()">
      <slot></slot>
    </span>
  </div>
</template>
<script>
export default {
  name: "dalete",
  components: {},
  props: ["title", "ask", "yesText", "noText", "yesBtnClass"],
  data: () => {
    return {
      data_title: "Delete",
      data_ask: "Are you sure?",
      data_yesText: "Yes",
      data_noText: "No",
      is_dialog_open: false,
      yes_btn_class: "",
    };
  },
  watch: {
    title(title) {
      let vm = this;
      vm.data_title = vm.title;
      vm.data_ask = vm.ask;
      vm.data_yesText = vm.yesText;
      vm.data_noText = vm.noText;
      vm.yes_btn_class = vm.yesBtnClass;
    },
  },
  mounted() {
    let vm = this;
    vm.data_title = vm.title;
    vm.data_ask = vm.ask;
    vm.data_yesText = vm.yesText;
    vm.data_noText = vm.noText;
    vm.yes_btn_class = vm.yesBtnClass;
  },
  methods: {
    openDialog() {
      let vm = this;
      vm.data_title = vm.title;
      vm.data_ask = vm.ask;
      vm.data_yesText = vm.yesText;
      vm.data_noText = vm.noText;
      vm.yes_btn_class = vm.yesBtnClass;
      vm.$store.commit("SET_DIALOG_CLASS", vm.yes_btn_class);
      this.$confirm({
        title: this.data_title,
        message: this.data_ask,
        button: {
          yes: this.data_noText,
          no: this.data_yesText,
        },
        callback: (confirm) => {
          if (!confirm) {
            vm.$store.commit("SET_DIALOG_CLASS", "");
            this.$emit("onComfirm");
          } else {
            this.$emit("onCancel");
          }
        },
      });
    },
  },
};
</script>
