<template>
  <div class="input-group">
    <div class="search-widget">
      <widgetSearch
        v-if="!selected_user"
        class="search-input"
        icon="right"
        placeholder="Search Student"
        v-model="search_user_input"
        :loading="is_loading"
      ></widgetSearch>

      <div class="selected-accounts-widget" v-if="selected_user">
        <div class="selected-company">
          <div class="content">
            <widgetProfile
              :title="selected_user.first_name + ' ' + selected_user.last_name"
              subtitle=""
              :file_name="selected_user.profile_picture"
              :path="$fullUrl('images/profiles/')"
            ></widgetProfile>
          </div>
          <div class="option" v-if="!readonly">
            <button @click="removeSelectUser()" class="btn-close">
              &times;
            </button>
          </div>
        </div>
      </div>

      <div
        class="search-result-widget"
        v-if="search_user_input && search_user_result.length > 0"
      >
        <div
          class="search-item"
          v-for="(item, index) in search_user_result"
          :key="index"
          @click="selectUser(item)"
        >
          <widgetProfile
            :title="item.first_name + ' ' + item.last_name"
            subtitle=""
            :file_name="item.profile_picture"
            :path="$fullUrl('images/profiles/')"
          ></widgetProfile>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "widget_button",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    miltiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      search_user_input: "",
      is_loading: false,
      new_company: false,
      selected_user: null,
      search_user_result: [],
      company_name: "",
    };
  },
  watch: {
    loading: function (val) {
      this.is_loading = val;
    },
    search_user_input(query) {
      let vm = this;
      vm.searchUser(query);
    },
    value: {
      handler: function (val) {
        let vm = this;
        vm.selected_user = val;
      },
      deep: true,
    },
  },
  mounted() {
    let vm = this;
    if (vm.miltiple) {
      vm.selected_user = [];
    } else {
      vm.selected_user = null;
    }
    if (vm.value) {
      vm.selected_user = vm.value;
    }
  },
  methods: {
    selectUser(item) {
      let vm = this;
      vm.search_user_input = "";
      if (vm.miltiple) {
        vm.selected_user.push(item);
        vm.$emit("input", vm.selected_user);
        vm.$emit("change", vm.selected_user);
      } else {
        vm.selected_user = item;
        vm.$emit("input", vm.selected_user);
        vm.$emit("change", vm.selected_user);
      }
    },
    removeSelectUser() {
      let vm = this;
      vm.selected_user = null;
      vm.$emit("input", vm.selected_user);
      vm.$emit("change", vm.selected_user);
    },
    searchUser(query) {
      let vm = this;
      vm.is_loading = true;
      vm.search_user_result = [];
      vm.$store
        .dispatch("SEARCH_FOR_COMPANY_MEMBERS", { query: query })
        .then((responce) => {
          vm.is_loading = false;
          vm.search_user_result = responce.data;
        })
        .catch((error) => {
          vm.is_loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.input-group {
  .search-widget {
    position: relative;
    .search-input {
      margin-bottom: 0px;
    }
    .selected-accounts-widget {
      .selected-company {
        border: 1px solid #ddd;
        border-radius: 5px;
        background: #fff;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        .content {
          display: flex;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
        }
        .option {
          .btn-close {
            font-size: 20px;
            background: #7f0404;
            color: #fff;
            border-radius: 5px;
          }
        }
      }
      .profile-list {
        display: flex;
        flex-wrap: wrap;
        .profile-item {
          margin-bottom: 10px;
          position: relative;
          .small-spacer {
            margin-right: 5px;
          }
          .btn-remove {
            font-size: 16px;
            height: 18px;
            width: 18px;
            background: #7f0404;
            color: #fff;
            border-radius: 50%;
            position: absolute;
            top: -3px;
            right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 4px;
          }
        }
      }
    }
    .search-result-widget {
      position: absolute;
      top: 50px;
      height: 200px;
      width: 100%;
      background: #fff;
      z-index: 999;
      box-shadow: 0px 0px 3px #ccc;
      border-radius: 5px;
      overflow-y: auto;
      .search-item {
        display: flex;
        align-items: center;
        padding: 4px 10px;
        border-bottom: 1px solid #ddd;
        cursor: pointer;
        &:hover {
          background: rgb(240, 240, 240);
        }
      }
    }
  }
}
</style>
