export const GET_IMAGES_FROM_UNSPLASH = "GET_IMAGES_FROM_UNSPLASH";
export const CVS_ADD_NEW_TEMPLATE = "CVS_ADD_NEW_TEMPLATE";
export const CVS_UPDATE_TEMPLATE = "CVS_UPDATE_TEMPLATE";
export const CVS_GET_TEMPLATES = "CVS_GET_TEMPLATES";
export const CVS_DELETE_TEMPLATE = "CVS_DELETE_TEMPLATE";
export const CVS_APPLY_TEMPLATE = "CVS_APPLY_TEMPLATE";
export const CVS_RENAME_TEMPLATE = "CVS_RENAME_TEMPLATE";
export const CVS_SEARCH_STUDENT = "CVS_SEARCH_STUDENT";
export const CVS_GET_STUDENT_PROJECTS = "CVS_GET_STUDENT_PROJECTS";
export const CVS_GET_STUDENT_EMPLOYMENTS = "CVS_GET_STUDENT_EMPLOYMENTS";
export const CVS_GET_STUDENT_SKILLS = "CVS_GET_STUDENT_SKILLS";
export const CVS_GET_STUDENT_QUALIFICATION = "CVS_GET_STUDENT_QUALIFICATION";
export const CVS_UPLOAD_IMAGES = "CVS_UPLOAD_IMAGES";
export const CVS_ALL_IMAGES = "CVS_ALL_IMAGES";
export const CVS_UPLOAD_BACKGROUND = "CVS_UPLOAD_BACKGROUND";
export const CVS_ALL_BACKGROUND = "CVS_ALL_BACKGROUND";
export const CVS_GET_STUDENT_INFORMATION = "CVS_GET_STUDENT_INFORMATION";
export const CVS_IMAGE_DELETE = 'CVS_IMAGE_DELETE';
export const CVS_SHARE_TEMPLATE = "CVS_SHARE_TEMPLATE";
export const CVS_STOP_SHARE_TEMPLATE = "CVS_STOP_SHARE_TEMPLATE";
export const CVS_SUBMIT_CV = "CVS_SUBMIT_CV";
export const CVS_GET_CVS = "CVS_GET_CVS";
export const CVS_UPDATE_CV = "CVS_UPDATE_CV";
export const CVS_DELETE_CV = "CVS_DELETE_CV";
export const CVS_SET_AS_DEFAULT = "CVS_SET_AS_DEFAULT";
export const CVS_GET_TEMPLATE_ITEM = "CVS_GET_TEMPLATE_ITEM";
export const CVS_ADD_NEW_COMPONENT = "CVS_ADD_NEW_COMPONENT";
export const CVS_GET_TEMPLATE_COMPONENTS = "CVS_GET_TEMPLATE_COMPONENTS";
export const CVS_DELETE_TEMPLATE_COMPONENT = "CVS_DELETE_TEMPLATE_COMPONENT";
export const CVS_GENELATE_FILE = "CVS_GENELATE_FILE"