<template>
  <div class="sidebar-wrapper h-full flex flex-col z-10">
    <ul class="pt-5">
      <a href="#" class="flex items-center">
        <img src="@/assets/images/menu/search.svg" class="icon mr-2">
        <span class="text-white">Search</span>
      </a>
      <a href="#" class="flex items-center h-11"
        :class="{ active: whenRouterEqual('users-management') || whenRouterEqual('user-school') }"
        @click="$router.push({ path: '/' })">
        <img src="@/assets/images/menu/user.svg" class="icon mr-2">
        <span class="text-white">User management</span>
      </a>
      <a href="#" @click="$router.push({ path: '/system-notification' })" class="flex items-center h-11">
        <img src="@/assets/admin-icon/menu/communication.svg" class="icon mr-2">
        <span class="text-white">System Communication</span>
      </a>
      <a href="#" class="flex items-center h-11">
        <img src="@/assets/images/menu/settings.svg" class="icon mr-2">
        <span class="text-white">Settings</span>
      </a>
    </ul>

    <div class="sidebar-footer">
      <button class="btn-logout" @click="logOut()">Logout</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Sidebar',
  components: {

  },
  methods: {
    whenRouterEqual(label) {
      let vm = this;
      if (vm.$route.name == label) {
        return true;
      }
      return false;
    },
    logOut() {
      let vm = this;
      vm.$localStorage.remove("logged_user_data");
      vm.$router.push({ path: "/login" });
    },
  }
}
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  background: #010404;
  padding: 65px 0px;
  width: 182px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    a {
      padding: 5px 15px;

      &.active {
        background: #1E1E1E;
      }
    }
  }

  .sidebar-footer {
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-logout {
      color: #fff;
    }
  }
}
</style>
