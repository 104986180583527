import {
    API,
    SITE_API,
    INSTRUCTOR_DELETE_SCHOOL_STUDENT_URL,
    INSTRUCTOR_APPROVE_SCHOOL_STUDENT_URL,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT_URL,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_URL,
    INSTRUCTOR_SEND_SCHOOL_STUDENT_REMAINDER_URL,
    INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE1_URL,
    INSTRUCTOR_SEARCH_INTERNSHIP_URL,
    INSTRUCTOR_SAVE_COMPANY_INTERNSHIP_URL,
    INSTRUCTOR_SEARCH_COMPANY_URL,
    INSTRUCTOR_GET_ALL_INTERNSHIP_URL,
    INSTRUCTOR_GET_ALL_SCHOOL_STUDENT_PHASE2_URL,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE2_URL,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE2_URL,
    INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP_URL,
    INSTRUCTOR_SEND_ALL_SCHOOL_STUDENT_REMAINDER_URL,
    INSTRUCTOR_SEND_ALL_COMPANY_REMAINDER_URL,
    INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE3_URL,
    INSTRUCTOR_MATCH_SCHOOL_STUDENT_URL,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE3_URL,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE3_URL,
    GET_ALL_SCHOOL_STUDENT_PHASE3_URL,
    INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP_STUDENTS_URL,
    INSTRUCTOR_SUBMIT_CHANGES_PHASE2_URL,
    INSTRUCTOR_SEND_PERIODIC_REMAINDER_URL,
    INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE4_URL,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE4_URL,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE4_URL,
    GET_ALL_SCHOOL_STUDENT_PHASE4_URL,
    INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE5_URL,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE5_URL,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE5_URL,
    GET_ALL_SCHOOL_STUDENT_PHASE5_URL,
    INSTRUCTOR_SCHOOL_SEND_EVALUATION_URL,
    INSTRUCTOR_SCHOOL_SEND_SURVEY_URL,
    INSTRUCTOR_REMOVED_COMPANY_INTERNSHIP_TO_STUDENT_URL,
    INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE6_URL,
    INSTRUCTOR_APPROVE_SCHOOL_STUDENT_PHASE6_URL,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE6_URL,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE6_URL,
    GET_ALL_SCHOOL_STUDENT_PHASE6_URL,
    INVITE_SCHOOL_STUDENT_PHASE6_URL,
    UPLOAD_MILTIPLE_FILE_URL,
    INSTRUCTOR_GET_STUDENT_SURVEY_RESULT_URL,
    INSTRUCTOR_SCHOOL_VIEW_SURVEY_RESULT_URL,
    INSTRUCTOR_SEND_REMAINDER_SELECTED_STUDENT_URL,
    INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE1_URL,
    INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE1_URL,
    INSTRUCTOR_SCHOOL_STUDENT_DELETE_SELECTED_PHASE1_URL,
    INSTRUCTOR_SCHOOL_STUDENT_EXPORT_SELECTED_PHASE1_URL,
    INSTRUCTOR_MATCH_SCHOOL_SELECTED_STUDENT_URL,
    INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE6_URL,
    INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE6_URL,
    INSTRUCTOR_UPDATE_COMPANY_INTERNSHIP_URL,
    INSTRUCTOR_DELETE_COMPANY_INTERNSHIP_URL,
    INSTRUCTOR_GET_NOTIFICATION_URL,
    INSTRUCTOR_DISMISS_NOTIFICATION_URL,
    PROCESSMESSAGE_SURVEY_REPORT_URL,
    INSTRUCTOR_GET_NOTIFICATION_STATUS_URL,
    INSTRUCTOR_GET_PUBLIC_INTERNSHIP_URL,
    INSTRUCTOR_STUDENT_CONTRACT_SIGNED_URL,
    INSTRUCTOR_SIGN_CONTRACT_OF_SELECTED_STUDENT_URL,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_STATUS_PHASE3_URL,
    INSTRUCTOR_SEND_NOTIFICATION_RATE_COMPANY_PHASE6_URL,
    INSTRUCTOR_SEND_NOTIFICATION_UPDATE_PROFILE_PHASE6_URL
} from "../variable";

import axios from 'axios'

import {
    INSTRUCTOR_DELETE_SCHOOL_STUDENT,
    INSTRUCTOR_APPROVE_SCHOOL_STUDENT,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT,
    INSTRUCTOR_SEND_SCHOOL_STUDENT_REMAINDER,
    INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE1,
    INSTRUCTOR_SEARCH_INTERNSHIP,
    INSTRUCTOR_SAVE_COMPANY_INTERNSHIP,
    INSTRUCTOR_SEARCH_COMPANY,
    INSTRUCTOR_GET_ALL_INTERNSHIP,
    INSTRUCTOR_GET_ALL_SCHOOL_STUDENT_PHASE2,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE2,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE2,
    INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP,
    INSTRUCTOR_SEND_ALL_SCHOOL_STUDENT_REMAINDER,
    INSTRUCTOR_SEND_ALL_COMPANY_REMAINDER,
    INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE3,
    INSTRUCTOR_MATCH_SCHOOL_STUDENT,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE3,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE3,
    GET_ALL_SCHOOL_STUDENT_PHASE3,
    INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP_STUDENTS,
    INSTRUCTOR_SUBMIT_CHANGES_PHASE2,
    INSTRUCTOR_SEND_PERIODIC_REMAINDER,
    INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE4,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE4,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE4,
    GET_ALL_SCHOOL_STUDENT_PHASE4,
    INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE5,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE5,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE5,
    GET_ALL_SCHOOL_STUDENT_PHASE5,
    INSTRUCTOR_SCHOOL_SEND_EVALUATION,
    INSTRUCTOR_SCHOOL_SEND_SURVEY,
    INSTRUCTOR_REMOVED_COMPANY_INTERNSHIP_TO_STUDENT,
    INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE6,
    INSTRUCTOR_APPROVE_SCHOOL_STUDENT_PHASE6,
    INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE6,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE6,
    GET_ALL_SCHOOL_STUDENT_PHASE6,
    INVITE_SCHOOL_STUDENT_PHASE6,
    UPLOAD_MILTIPLE_FILE,
    INSTRUCTOR_GET_STUDENT_SURVEY_RESULT,
    INSTRUCTOR_SCHOOL_VIEW_SURVEY_RESULT,
    INSTRUCTOR_SEND_REMAINDER_SELECTED_STUDENT,
    INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE1,
    INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE1,
    INSTRUCTOR_SCHOOL_STUDENT_DELETE_SELECTED_PHASE1,
    INSTRUCTOR_SCHOOL_STUDENT_EXPORT_SELECTED_PHASE1,
    INSTRUCTOR_MATCH_SCHOOL_SELECTED_STUDENT,
    INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE6,
    INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE6,
    INSTRUCTOR_UPDATE_COMPANY_INTERNSHIP,
    INSTRUCTOR_DELETE_COMPANY_INTERNSHIP,
    INSTRUCTOR_GET_NOTIFICATION,
    INSTRUCTOR_DISMISS_NOTIFICATION,
    PROCESSMESSAGE_SURVEY_REPORT,
    INSTRUCTOR_GET_NOTIFICATION_STATUS,
    INSTRUCTOR_GET_PUBLIC_INTERNSHIP,
    INSTRUCTOR_STUDENT_CONTRACT_SIGNED,
    INSTRUCTOR_SIGN_CONTRACT_OF_SELECTED_STUDENT,
    INSTRUCTOR_FILTER_SCHOOL_STUDENT_STATUS_PHASE3,
    INSTRUCTOR_SEND_NOTIFICATION_RATE_COMPANY_PHASE6,
    INSTRUCTOR_SEND_NOTIFICATION_UPDATE_PROFILE_PHASE6
} from "../Actions/instructor";
import router from "../../router";

const state = {

};

const actions = {
    [INSTRUCTOR_SEND_NOTIFICATION_RATE_COMPANY_PHASE6]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEND_NOTIFICATION_RATE_COMPANY_PHASE6_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SEND_NOTIFICATION_UPDATE_PROFILE_PHASE6]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEND_NOTIFICATION_UPDATE_PROFILE_PHASE6_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_FILTER_SCHOOL_STUDENT_STATUS_PHASE3]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_FILTER_SCHOOL_STUDENT_STATUS_PHASE3_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_STUDENT_CONTRACT_SIGNED]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_STUDENT_CONTRACT_SIGNED_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SIGN_CONTRACT_OF_SELECTED_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SIGN_CONTRACT_OF_SELECTED_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_GET_PUBLIC_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == "init" ? SITE_API + getters.get_crm_prefix + INSTRUCTOR_GET_PUBLIC_INTERNSHIP_URL : payload.url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_GET_NOTIFICATION_STATUS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_GET_NOTIFICATION_STATUS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [PROCESSMESSAGE_SURVEY_REPORT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + PROCESSMESSAGE_SURVEY_REPORT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_GET_NOTIFICATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_GET_NOTIFICATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_DISMISS_NOTIFICATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_DISMISS_NOTIFICATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_UPDATE_COMPANY_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_UPDATE_COMPANY_INTERNSHIP_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_DELETE_COMPANY_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_DELETE_COMPANY_INTERNSHIP_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE6]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE6_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE6]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE6_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_MATCH_SCHOOL_SELECTED_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_MATCH_SCHOOL_SELECTED_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SEND_REMAINDER_SELECTED_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEND_REMAINDER_SELECTED_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE1]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SCHOOL_STUDENT_INVITE_SELECTED_PHASE1_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE1]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SCHOOL_STUDENT_APPROVE_SELECTED_PHASE1_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SCHOOL_STUDENT_DELETE_SELECTED_PHASE1]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SCHOOL_STUDENT_DELETE_SELECTED_PHASE1_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SCHOOL_STUDENT_EXPORT_SELECTED_PHASE1]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SCHOOL_STUDENT_EXPORT_SELECTED_PHASE1_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
            responseType: "blob",
        });
    },
    [INSTRUCTOR_SCHOOL_VIEW_SURVEY_RESULT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SCHOOL_VIEW_SURVEY_RESULT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_GET_STUDENT_SURVEY_RESULT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_GET_STUDENT_SURVEY_RESULT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [UPLOAD_MILTIPLE_FILE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + UPLOAD_MILTIPLE_FILE_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken,
                'Content-Type': 'multipart/form-data'
            },
        });
    },
    [INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE6]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE6_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_APPROVE_SCHOOL_STUDENT_PHASE6]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_APPROVE_SCHOOL_STUDENT_PHASE6_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE6]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE6_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE6]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE6_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [GET_ALL_SCHOOL_STUDENT_PHASE6]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == 'init' ? SITE_API + getters.get_crm_prefix + GET_ALL_SCHOOL_STUDENT_PHASE6_URL : payload.next_page_url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INVITE_SCHOOL_STUDENT_PHASE6]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INVITE_SCHOOL_STUDENT_PHASE6_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_REMOVED_COMPANY_INTERNSHIP_TO_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_REMOVED_COMPANY_INTERNSHIP_TO_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SCHOOL_SEND_EVALUATION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SCHOOL_SEND_EVALUATION_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SCHOOL_SEND_SURVEY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SCHOOL_SEND_SURVEY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE5]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE5_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE5]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE5_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE5]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE5_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [GET_ALL_SCHOOL_STUDENT_PHASE5]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + GET_ALL_SCHOOL_STUDENT_PHASE5_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE4]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE4_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE4]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE4_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE4]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE4_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [GET_ALL_SCHOOL_STUDENT_PHASE4]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == 'init' ? SITE_API + getters.get_crm_prefix + GET_ALL_SCHOOL_STUDENT_PHASE4_URL : payload.next_page_url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SEND_PERIODIC_REMAINDER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEND_PERIODIC_REMAINDER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SUBMIT_CHANGES_PHASE2]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SUBMIT_CHANGES_PHASE2_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP_STUDENTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP_STUDENTS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE3]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE3_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_MATCH_SCHOOL_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_MATCH_SCHOOL_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE3]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE3_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE3]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE3_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [GET_ALL_SCHOOL_STUDENT_PHASE3]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == 'init' ? SITE_API + getters.get_crm_prefix + GET_ALL_SCHOOL_STUDENT_PHASE3_URL : payload.next_page_url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },

    [INSTRUCTOR_SEND_ALL_SCHOOL_STUDENT_REMAINDER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEND_ALL_SCHOOL_STUDENT_REMAINDER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_SEND_ALL_COMPANY_REMAINDER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEND_ALL_COMPANY_REMAINDER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            },
        });
    },
    [INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_ASSIGN_COMPANY_INTERNSHIP_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [INSTRUCTOR_SAVE_COMPANY_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SAVE_COMPANY_INTERNSHIP_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken,
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    [INSTRUCTOR_SEARCH_COMPANY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API + INSTRUCTOR_SEARCH_COMPANY_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.authToken
            }
        });
    },
    [INSTRUCTOR_SEARCH_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == 'init' ? SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEARCH_INTERNSHIP_URL : payload.next_page_url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [INSTRUCTOR_GET_ALL_INTERNSHIP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: payload.url_type == 'init' ? SITE_API + getters.get_crm_prefix + INSTRUCTOR_GET_ALL_INTERNSHIP_URL : payload.next_page_url,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [INSTRUCTOR_GET_ALL_SCHOOL_STUDENT_PHASE2]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_GET_ALL_SCHOOL_STUDENT_PHASE2_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE2]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEARCH_SCHOOL_STUDENT_PHASE2_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE2]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_FILTER_SCHOOL_STUDENT_PHASE2_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE1]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_REPORT_SCHOOL_STUDENT_PHASE1_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [INSTRUCTOR_SEND_SCHOOL_STUDENT_REMAINDER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEND_SCHOOL_STUDENT_REMAINDER_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [INSTRUCTOR_DELETE_SCHOOL_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_DELETE_SCHOOL_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [INSTRUCTOR_APPROVE_SCHOOL_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_APPROVE_SCHOOL_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    },
    [INSTRUCTOR_SEARCH_SCHOOL_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_SEARCH_SCHOOL_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });

    },
    [INSTRUCTOR_FILTER_SCHOOL_STUDENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: SITE_API + getters.get_crm_prefix + INSTRUCTOR_FILTER_SCHOOL_STUDENT_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.siteAuthToken
            }
        });
    }
};

const mutations = {};

const getters = {};

export default {
    state,
    getters,
    actions,
    mutations
};