<template>
    <widgetModal :title="value ? 'Update Release note' : 'Add New Release note'"
        subtitle="You can edit information directly from this form" :btnlefttext="'Save and Publish'"
        btncentertext="Cancel" btnrighttext="Save As Draft" :leftloading="loading" :rightloading="false"
        :centerloading="false" :footerhidden="false" :hiderightbtn="false" footer_padding="10px 18px 30px 30px"
        header_padding="30px 0px 0px 31px" body_padding="0px 30px" width="600px"
        @onLeftClick="submitReleaseNotes('publish')" @onCenterClick="onCancel()"
        @onRightClick="submitReleaseNotes('draft')">

        <div class="row">
            <widgetInputWrapper title="Release title">
                <widgetInput v-model="form.release_title" type="text" placeholder=""></widgetInput>
            </widgetInputWrapper>
        </div>

        <div class="row">
            <widgetInputWrapper title="Release Version">
                <widgetInput v-model="form.release_version" type="text" placeholder="v.0.1"></widgetInput>
            </widgetInputWrapper>
        </div>

        <div class="row">
            <widgetInputWrapper title="Wiki Link">
                <widgetInput v-model="form.wiki_link" type="text" placeholder=""></widgetInput>
            </widgetInputWrapper>
        </div>

        <widgetInputWrapper title="Release notes">
            <html-editor v-model="form.release_notes" id="comp-info-edit-descr" />
        </widgetInputWrapper>

    </widgetModal>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'update-school',
    components: {

    },
    props: {
        value: {
            type: Object,
            default: () => {
                return null
            }
        },
        type: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            form: {
                id: null,
                release_title: "",
                release_version: "",
                release_notes: "",
                wiki_link: "",
                status: "draft",
                type: "",
            },
            loading: false
        }
    },
    mounted() {
        let vm = this;
        if (vm.value) {
            vm.setForm(vm.value);
        } else {
            vm.resetForm();
        }
    },
    methods: {
        setForm(item) {
            let vm = this;
            vm.form.id = item.id;
            vm.form.release_title = item.release_title;
            vm.form.release_version = item.release_version;
            vm.form.wiki_link = item.wiki_link;
            vm.form.release_notes = item.release_notes;
            vm.form.status = item.status;
            vm.form.type = item.type;
        },
        resetForm() {
            let vm = this;
            vm.form.id = null;
            vm.form.release_title = "";
            vm.form.release_version = "";
            vm.form.wiki_link = "";
            vm.form.release_notes = "";
            vm.form.status = "draft";
            vm.form.type = vm.type;
        },
        submitReleaseNotes(type) {
            let vm = this;
            vm.loading = true;
            vm.form.status = type;
            if (!vm.form.id) {
                vm.$store
                    .dispatch("RELEASE_ADD_NEW", vm.form)
                    .then((response) => {
                        vm.loading = false;
                        vm.$emit("sucess", null);
                    })
                    .catch((error) => {
                        vm.loading = false;
                    });
            } else {
                vm.$store
                    .dispatch("RELEASE_UPDATE", vm.form)
                    .then((response) => {
                        vm.loading = false;
                        vm.$emit("sucess", null);
                    })
                    .catch((error) => {
                        vm.loading = false;
                    });
            }
        },
        onCancel() {
            let vm = this;
            vm.$emit("close", null);
        }
    },

}
</script>