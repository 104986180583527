export const CANDIDATES_ADD_NEW_SECTION = "CANDIDATES_ADD_NEW_SECTION";
export const CANDIDATES_UPDATE_SECTION = "CANDIDATES_UPDATE_SECTION";
export const CANDIDATES_ADD_NEW_STORY = "CANDIDATES_ADD_NEW_STORY";
export const CANDIDATES_DELETE_SECTION = "CANDIDATES_DELETE_SECTION";
export const CANDIDATES_UPDATE_STORY = "CANDIDATES_UPDATE_STORY";
export const CANDIDATES_GET_ALL = "CANDIDATES_GET_ALL";
export const CANDIDATES_DELETE_STORY = "CANDIDATES_DELETE_STORY";
export const CANDIDATES_MOVE_STORY = "CANDIDATES_MOVE_STORY";
export const CANDIDATES_GET_EXISTS = "CANDIDATES_GET_EXISTS";
export const CANDIDATES_SEARCH = "CANDIDATES_SEARCH";
export const CANDIDATES_FILTER_BY_SORT = "CANDIDATES_FILTER_BY_SORT";