<template>
  <div class="uploader">
    <div class="uploaded-image-preview">
      <div class="selected-file" v-if="url">
        <img :src="url" v-if="isFileImage(file, url)" />
        <h6 class="file-name" v-else>{{ file.name | validate }}</h6>
        <button class="btn-remove" @click.prevent="removeFile()">
          <span class="label">{{ remove_caption | validate }} </span>
          <img class="icon" src="@/assets/icons/edit_1.svg" alt="" />
        </button>
      </div>
      <div class="image-preview" @click.prevent="handleGetFile()" v-else>
        <span class="mdi mdi-plus"></span>
        <button class="btn-upload">{{ caption | validate }}</button>
      </div>

      <input
        type="file"
        class="file-input"
        ref="file"
        :accept="accept"
        @change="handleFileUpload()"
      />
    </div>

    <!-- Crop section -->
    <div class="crop-overlay" v-if="crop_modal">
      <div class="crop-widget">
        <cropper
          class="cropper"
          :src="url"
          :stencilProps="{ aspectRatio: ration }"
          @change="onCropChange"
          @ready="onReady"
          :touchMove="true"
          :touchResize="true"
        ></cropper>
        <div class="cropped-data">
          <div class="image-widget">
            <div class="title" v-show="!loading">Cropped Image</div>
            <img :src="cropped_data" v-show="!loading" alt />
            <div class="loading-widget" v-show="loading">
              <h1>Loading image</h1>
              <img src="@/assets/icons/loading_blue.gif" alt />
            </div>
          </div>
          <div class="crop-options" v-show="!loading">
            <button class="save-btn" @click="saveCroppedData()">Use</button>
            <button class="cancel-btn" @click="crop_modal = false">
              Default Size
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import { eventBus } from "@/main";
export default {
  name: "upload",
  components: {
    Cropper,
  },
  props: {
    max_size: {
      type: Number,
      default: 2,
    },
    caption: {
      type: String,
      default: "Upload Image",
    },
    remove_caption: {
      type: String,
      default: "Remove Image",
    },
    value: {
      type: Object,
      default: () => {
        return {
          url: "",
          file_name: "",
        };
      },
    },
    crop: {
      type: Boolean,
      default: false,
    },
    ration: {
      type: Number,
      default: 10 / 10,
    },
    accept: {
      type: String,
      default: "*",
    },
  },
  data: () => {
    return {
      crop_modal: false,
      file: {},
      url: null,
      cropped_data: "",
      blob_data: null,
      loading: false,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) {
          let vm = this;
          if (val.file_name) {
            vm.url = val.url + val.file_name;
            vm.file.name = val.file_name;
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    let vm = this;
    if (vm.value.file_name) {
      vm.url = vm.value.url + vm.value.file_name;
      vm.file.name = vm.value.file_name;
    }
    eventBus.$on("removeFile", (data) => {
      vm.removeFile();
    });
  },
  methods: {
    isFileImage(file, url) {
      if (url) {
        if (url.match(/.(jpg|jpeg|png|gif)$/i)) {
          return true;
        } else {
          if (file["type"]) {
            return file && file["type"].split("/")[0] === "image";
          } else {
            return false;
          }
        }
      }
    },
    makeRandomName(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result + ".png";
    },
    onReady() {
      let vm = this;
      vm.loading = false;
    },
    saveCroppedData() {
      let vm = this;
      vm.crop_modal = false;
      vm.url = vm.cropped_data;
      eventBus.$set(vm.blob_data, "name", vm.makeRandomName(10));
      vm.$emit("onFileSelected", vm.blob_data);
    },
    onCropChange({ coordinates, canvas }) {
      let vm = this;
      canvas.toBlob((blob) => {
        let file = URL.createObjectURL(blob);
        vm.cropped_data = file;
        vm.blob_data = blob;
      }, "image/png");
    },
    handleGetFile() {
      let vm = this;
      vm.$refs.file.click();
    },
    handleFileUpload() {
      let vm = this;
      if (vm.$refs.file.files.length !== 0) {
        vm.file = vm.$refs.file.files[0];
        if (vm.isValidated(vm.file)) {
          vm.url = URL.createObjectURL(vm.file);
          if (vm.crop) {
            vm.crop_modal = true;
            vm.loading = true;
          }
          vm.$emit("onFileSelected", vm.file);
        }
      }
    },
    removeFile() {
      let vm = this;
      vm.$refs.file.type = "text";
      vm.$refs.file.type = "file";
      vm.file = null;
      vm.url = null;
      vm.$emit("onFileSelected", null);
    },
    isValidated(file) {
      let vm = this;
      let file_size = file.size / 1024 / 1024;
      if (file_size <= vm.max_size) {
        return true;
      } else {
        vm.$notify({
          group: "status",
          title: "Warning",
          text:
            "Your file is too big, Please try again with " +
            vm.max_size +
            " MB size file.",
          type: "warn",
          duration: 3000,
        });
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.uploader {
  width: 100%;
  height: 100%;
  .uploaded-image-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .image-preview {
      outline-style: dashed;
      outline-color: #ccc;
      outline-width: 1px;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      .mdi {
        color: #ccc;
        font-size: 40px;
      }
    }
    .btn-upload {
      width: 100%;
      height: 43px;
      border-radius: 5px;
      border: none;
      color: #1885ad;
      font-size: 14px;
      outline: none;
      background: transparent;
    }
    .file-input {
      display: none;
    }
    .selected-file {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: calc(100% - 43px);
        background: #fff;
        object-fit: contain;
      }
      .file-name {
        font-size: 14px;
        margin-top: 10px;
      }
      .btn-remove {
        width: max-content;
        height: 43px;
        border-radius: 10px;
        color: #707070;
        font-size: 16px;
        outline: none;
        background: #fff;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        white-space: nowrap;
        border: 1px solid #ddd;
        margin-top: 10px;
        padding: 0px 15px;
        .icon {
          widows: 25px;
          height: 25px;
          margin-left: 10px;
        }
      }
    }
  }
  .crop-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 99999999;
    display: flex;
    padding-top: 70px;
    justify-content: center;
    @media (min-width: 300px) and (max-width: 630px) {
      padding-top: 54px;
    }
    .crop-widget {
      width: 800px;
      background: #fff;
      height: 500px;
      border-radius: 5px;
      display: grid;
      grid-template-columns: 452px 300px;
      grid-column-gap: 20px;
      padding: 20px;
      @media (min-width: 300px) and (max-width: 630px) {
        width: 100%;
        grid-template-columns: 100%;
        height: 100%;
      }
      .cropper {
        background: #000;
        overflow: hidden;
        @media (min-width: 300px) and (max-width: 630px) {
          height: 400px;
        }
      }
      .cropped-data {
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
        .image-widget {
          @media (min-width: 300px) and (max-width: 630px) {
            display: none;
          }
          .title {
            margin-bottom: 15px;
          }
          img {
            width: 100%;
            border-radius: 5px;
          }
          .loading-widget {
            img {
              width: 40px;
            }
          }
        }
        .crop-options {
          display: flex;
          .save-btn {
            color: #fff;
            outline: none;
            border-radius: 5px;
            padding: 7px;
            width: 100px;
            background-image: linear-gradient(267deg, #1885ad 103%, #04a5a5 0%);
            margin-right: 15px;
          }
          .cancel-btn {
            color: #1885ad;
            outline: none;
            border-radius: 5px;
            padding: 7px;
            width: 100px;
          }
        }
      }
    }
  }
}
</style>
