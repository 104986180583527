<template>
  <div class="widget-invite">
    <div class="row">
      <div class="search-people-widget">
        <widgetSearch
          @keyup="searchMember"
          v-model="search_value"
          icon="right"
          placeholder="Eg: John Doe"
          :loading="loading_search_member"
        ></widgetSearch>
        <div
          class="search-people-result"
          v-if="show_search_result && member_list.length > 0"
        >
          <div
            class="search-people-result-item"
            v-for="(person, i) in member_list"
            :key="i"
            @click="addMate(person)"
          >
            <img
              v-if="person.profile_picture"
              :src="$fullUrl('images/profiles/' + person.profile_picture)"
            />
            <img v-else src="@/assets/avatar.png" />
            <div class="profile-info">
              <span>{{
                (person.first_name + " " + person.last_name) | validate
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="h-spacer"></div>
      <div class="invite-via-email-widget">
        <div class="invite-via-email-label">
          If they dont have a Meritios account, you can
        </div>
        <widgetButton
          :loading="false"
          @click="show_invite_via_email_popup = !show_invite_via_email_popup"
          class="btn-transparent-secondary fit-content"
        >
          Send invitation via email
          <img
            v-if="!show_invite_via_email_popup"
            src="@/assets/admin-icon/allow-blue-down.svg"
          />
          <img v-else src="@/assets/admin-icon/allow-blue-up.svg" />
        </widgetButton>
        <div class="invite-via-email-form" v-if="show_invite_via_email_popup">
          <widgetInput
            type="text"
            class="email-input"
            placeholder="Email Address"
            v-model="invitation.email"
          ></widgetInput>
          <widgetButton
            :loading="false"
            @click="addGuestMember()"
            class="btn-secondary"
          >
            Send
          </widgetButton>
        </div>
      </div>
    </div>

    <!-- Added member list  -->
    <div class="selected-people" v-show="added_member_list.length > 0">
      <template v-for="(member, i) in added_member_list">
        <div v-if="member.type == 'host'" class="selected-people-item" :key="i">
          <div class="person-avatar">
            <img
              v-if="member.picture"
              :src="$fullUrl('images/profiles/' + member.picture)"
            />
            <img v-else src="@/assets/avatar.png" />
          </div>
          <div class="person-name">{{ member.names | validate }}</div>
          <div class="btn-remove" @click="removeSelectedMember(i)">&times;</div>
        </div>
        <div
          v-if="member.type == 'guest'"
          class="selected-people-item"
          :key="i"
        >
          <div class="person-avatar">
            <img src="@/assets/avatar.png" />
          </div>
          <div class="person-name">{{ member.email | validate }}</div>
          <div class="btn-remove" @click="removeSelectedMember(i)">&times;</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "widget-invite",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show_search_result: false,
      show_invite_via_email_popup: false,
      search_value: "",
      loading_search_member: false,
      member_list: [],
      added_member_list: [],
      invitation: {
        target: "",
        email: "",
        note: "",
        type: "guest",
        id: 0,
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    let vm = this;
  },
  methods: {
    removeSelectedMember(index) {
      let vm = this;
      vm.added_member_list.splice(index, 1);
      vm.$emit("input", vm.added_member_list);
    },
    addGuestMember() {
      let vm = this;
      if (
        !vm.added_member_list
          .map((item) => item.email)
          .includes(vm.invitation.email)
      ) {
        if (vm.invitation.email) {
          vm.added_member_list.push(JSON.parse(JSON.stringify(vm.invitation)));
          vm.invitation.email = "";
          vm.show_invite_via_email_popup = false;
          vm.$emit("input", vm.added_member_list);
        } else {
          vm.$notify({
            group: "status",
            title: "Something wrong",
            text: "Please enter email address",
            type: "warn",
            duration: 3000,
          });
        }
      } else {
        vm.$notify({
          group: "status",
          title: "Something wrong",
          text: "Email already exists",
          type: "warn",
          duration: 3000,
        });
      }
    },
    addMate(member) {
      let vm = this;
      let obj = {
        id: member.id,
        names: member.first_name + " " + member.last_name,
        picture: member.profile_picture,
        type: "host",
      };
      if (!vm.added_member_list.map((item) => item.id).includes(member.id)) {
        vm.added_member_list.push(obj);
        vm.show_search_result = false;
        vm.search_value = "";
        vm.$emit("input", vm.added_member_list);
      } else {
        vm.$notify({
          group: "status",
          title: "Something wrong",
          text: "Already selected, Please try other.",
          type: "warn",
          duration: 3000,
        });
      }
    },
    searchMember({ target }) {
      let vm = this;
      vm.loading_search_member = true;
      let query = target.value;
      vm.$store
        .dispatch("SEARCH_FOR_PROJECT_MEMBERS", { query: query })
        .then((response) => {
          vm.show_search_result = true;
          vm.loading_search_member = false;
          vm.member_list = response.data;
        })
        .catch((error) => {
          vm.loading_search_member = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";
.widget-invite {
  width: 100%;
  display: flex;
  flex-direction: column;
  .row {
    .search-people-widget {
      width: 100%;
      position: relative;
      .search-people-result {
        position: absolute;
        height: 200px;
        left: 0;
        right: 0;
        top: 50px;
        z-index: 999;
        background: #fff;
        overflow-y: auto;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0px 11px 10px #ddd;
        .search-people-result-item {
          display: flex;
          cursor: pointer;
          align-items: center;
          height: 60px;
          border-bottom: 1px solid #e6e6e6;
          padding: 0 20px;
          &:hover {
            background: #f5f5f5;
          }
          img {
            width: 25px;
            border-radius: 50%;
            height: 25px;
            margin-right: 8px;
            object-fit: cover;
          }
          .tprofile-info {
            display: flex;
            color: #808080;
            flex-direction: column;
          }
        }
      }
    }
    .invite-via-email-widget {
      width: 100%;
      position: relative;
      .invite-via-email-label {
        font-size: 12px;
      }
      .invite-via-email-form {
        position: absolute;
        top: 60px;
        right: 0px;
        left: 0;
        background: #fff;
        padding: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 0px 11px 10px #ddd;
        z-index: 9999;
        .email-input {
          margin-bottom: 10px;
        }
      }
    }
  }
  .selected-people {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .selected-people-item {
      display: flex;
      align-items: center;
      padding: 5px 5px;
      background: #f4f4f4;
      border-radius: 50px;
      position: relative;
      margin-right: 10px;
      margin-bottom: 10px;
      .person-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #f5f5f5;
        margin-right: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .person-name {
        font-weight: 500;
        color: #808080;
        margin-right: 10px;
      }
      .btn-remove {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #500000;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #fff;
        &:hover {
          background: #6f0505;
        }
      }
    }
  }
}
</style>