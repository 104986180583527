<template>
    <widgetModal title="School information" subtitle="You can edit information directly from this form"
        :btnlefttext="'Save Changes'" btncentertext="Cancel" :leftloading="loading" :rightloading="false"
        :centerloading="false" :footerhidden="false" :hiderightbtn="true" footer_padding="10px 18px 30px 30px"
        header_padding="30px 0px 0px 31px" body_padding="0px 30px" width="600px" @onLeftClick="submitCompanyInfo()"
        @onCenterClick="onCancel()">

        <div class="row">
            <widgetInputWrapper style="width: 65%" title="School name">
                <widgetInput v-model="company_form.company_name" type="text" placeholder=""></widgetInput>
            </widgetInputWrapper>
            <div class="h-spacer"></div>
            <widgetInputWrapper style="width: 35%" title="Location">
                <map-helper v-model="company_form.company_address"></map-helper>
            </widgetInputWrapper>
        </div>

        <widgetInputWrapper title="Description">
            <html-editor v-model="company_form.company_description" id="comp-info-edit-descr" />
        </widgetInputWrapper>

        <widgetInputWrapper style="width: 180px;height:150px" title="Logo image">
            <upload :crop="true" @onFileSelected="(file) => {
                company_form.profile_logo = file;
            }" :value="{
    url: $fullUrl('images/profiles/'),
    file_name: company_form.profile_logo_url,
}" />
        </widgetInputWrapper>

    </widgetModal>
</template>
  
<script>
// @ is an alias to /src
import upload from "@/components/Partials/Uploader.vue";
export default {
    name: 'update-school',
    components: {
        upload
    },
    props: {
        value: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            company_form: {
                id: null,
                company_name: "",
                company_domain: "",
                company_address: "",
                company_description: "",
                profile_logo: null,
                profile_logo_url: "",
                profile_logo_set: true,
            },
            loading: false
        }
    },
    mounted() {
        let vm = this;
        if (vm.value) {
            vm.setForm(vm.value);
        }
    },
    methods: {
        setForm(item) {
            let vm = this;
            vm.company_form.id = item.id;
            vm.company_form.company_name = item.name;
            vm.company_form.company_domain = item.domain;
            vm.company_form.company_address = item.address;
            vm.company_form.company_description = item.description;
            vm.company_form.profile_logo = item.logo;
            vm.company_form.profile_logo_url = item.logo;
            vm.company_form.profile_logo_set = item.logo ? true : false;
        },
        updateSchool() {
            let vm = this;
            vm.isLoading = true;

        },
        submitCompanyInfo() {
            let vm = this;
            vm.loading = true;
            let form_data = new FormData();
            form_data.append("company_name", vm.company_form.company_name || "");
            form_data.append("company_domain", vm.company_form.company_domain || "");
            form_data.append("address", vm.company_form.company_address || "");
            form_data.append(
                "company_description",
                vm.company_form.company_description || ""
            );
            if (vm.company_form.profile_logo) {
                form_data.append(
                    "profile_logo",
                    vm.company_form.profile_logo,
                    vm.company_form.profile_logo.name
                );
            }
            form_data.append("profile_logo_set", vm.company_form.profile_logo_set);
            vm.$store.commit("SET_COMPANY_ID", vm.company_form.id);
            vm.$store
                .dispatch("UPDATE_COMPANY_INFO", form_data)
                .then((response) => {
                    vm.loading = false;
                    vm.$emit("sucess", null);
                })
                .catch((error) => {
                    vm.loading = false;
                });
        },
        onCancel() {
            let vm = this;
            vm.$emit("close", null);
        }
    },

}
</script>
  