<template>
  <div class="widget-badge">
    <span class="icon">
      <slot></slot>
    </span>
    <span class="text">{{ text }}</span>
  </div>
</template>
  
<script>
export default {
  name: "widget-badge",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() {
    let vm = this;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.widget-badge {
  display: flex;
  align-items: center;

  &.badge-primary {
    color: #1ba5a4;
  }

  &.badge-secondary {
    color: #0884af;
  }

  img.icon {
    margin-right: 3px;
  }

  .text {
    white-space: nowrap;
    font-size: 11px;
  }
}
</style>
  