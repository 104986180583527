<template>
  <div
    class="profile-image"
    @mouseover="show_profile = true"
    @mouseenter="show_profile = true"
    @mouseleave="show_profile = false"
  >
    <div class="image">
      <slot></slot>
    </div>

    <div class="profile-widget" v-if="show_profile">
      <img
        v-if="member.profile_picture"
        :src="$fullUrl('images/profiles/' + member.profile_picture)"
      />

      <img v-else src="@/assets/avatar.png" />
      <span class="name">{{
        (member.first_name + " " + member.last_name) | validate
      }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "assinged-profile-tooltip",
  components: {},
  props: {
    member: {
      type: Object,
      default: {},
    },
  },
  data: () => {
    return {
      show_profile: false,
    };
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.profile-image {
  position: relative;
  z-index: 666;
  .image {
    display: flex;
    align-items: center;
  }
  .profile-widget {
    position: absolute;
    bottom: 20px;
    left: 0px;
    z-index: 66;
    width: 200px;
    height: 60px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #ccc;
    padding: 4px 6px;
    display: flex;
    align-items: center;

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
  }
}
</style>
