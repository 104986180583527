<template>
  <div class="wrapper">
    <div
      class="milt-select-widget"
      ref="milt_select_widget"
      @click="shop_pop_up = !shop_pop_up"
    >
      <label><slot></slot></label>
      <img width="13" src="@/assets/admin-icon/allow-down-grey.svg" />
    </div>
    <div
      class="select-items-widget"
      v-if="shop_pop_up && input_data.length > 0"
      v-closable="{
        exclude: ['milt_select_widget'],
        handler: 'hidePopUp',
      }"
    >
      <div
        class="select-item"
        v-for="(item, index) in input_data"
        :key="index"
        :class="{ active: selected_item == item.value }"
        @click="onChange(item)"
      >
        <label :for="`id_${item.value}`">{{ item.name | validate }}</label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "milt-select-widget",
  props: {
    content: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: "",
  },
  data() {
    return {
      input_data: [],
      selected_item: "",
      shop_pop_up: false,
    };
  },
  computed: {},
  watch: {
    content(val) {
      let vm = this;
      vm.input_data = val;
    },
    value(val) {
      let vm = this;
      vm.selected_item = val;
    },
  },
  mounted() {
    let vm = this;
    vm.input_data = vm.content;
    if (vm.value) {
      vm.selected_item = vm.value;
    }
  },
  methods: {
    hidePopUp() {
      let vm = this;
      vm.shop_pop_up = false;
    },
    onChange(item) {
      let vm = this;
      vm.selected_item = item.value;
      vm.shop_pop_up = false;
      vm.$emit("onSubmit", vm.selected_item);
      vm.$emit("input", vm.selected_item);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";
.wrapper {
  position: relative;
  border-radius: 5px;
  padding: 0px;
  .milt-select-widget {
    display: flex;
    border-radius: 5px;
    width: 100%;
    height: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 3px;
    label {
      margin-right: 8px;
    }
  }
  .select-items-widget {
    position: absolute;
    top: 36px;
    width: 150px;
    right: 0px;
    background: #ffffff;
    border: 1px solid #f2f4f8;
    box-sizing: border-box;
    border-radius: 5px;
    min-width: 117px;
    overflow-y: auto;
    z-index: 99999999999999;
    .select-item {
      border-bottom: 1px solid #f2f4f8;
      padding: 7px 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.active {
        border-left: 2px solid#0884af;
      }
      &:hover {
        background: rgb(243, 243, 243);
      }
      label {
        margin-top: 2px;
        margin-left: 7px;
      }
    }
  }
}
</style>