<template>
  <div class="tags-wrapper">
    <div class="tags-widget" v-if="input_data.added.length > 0">
      <div class="tags-item" v-for="(item, index) in input_data.added" :key="index">
        <span>{{ item.name | validate }}</span>
        <button @click="removeItem(item, index)" class="close-btn">
          &times;
        </button>
      </div>
    </div>
    <div class="tags-forms" :style="{ width: input_width }">
      <input type="text" class="item-input" v-model="tag_text" :placeholder="placeholder" @keypress.enter="addTag()"
        @blur="addTag()" />
      <button @click="addTag()" class="btn-add">
        <img src="@/assets/icon/plus-blue.svg" />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "tags-widget",
  props: {
    value: {
      type: Object,
      default: function () {
        return { added: [], removed: [] };
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    input_width: {
      type: String,
      default: "width: 50%;",
    },
  },
  data() {
    return {
      input_data: { added: [], removed: [] },
      tag_text: "",
    };
  },
  computed: {},
  watch: {
    value(val) {
      let vm = this;
      vm.input_data.added = val.added;
    },
  },
  mounted() {
    let vm = this;
    if (vm.value) {
      vm.input_data.added = vm.value.added;
    }
  },
  methods: {
    addTag() {
      let vm = this;
      if (vm.tag_text) {
        let obj = {
          name: vm.tag_text,
        };
        vm.input_data.added.push(obj);
        vm.tag_text = "";
        vm.$emit("onSubmit", vm.input_data);
        vm.$emit("input", vm.input_data);
      }
    },
    removeItem(item, index) {
      let vm = this;
      vm.input_data.added.splice(index, 1);
      if (item.on_update) {
        vm.input_data.removed.push(item.id);
      }
      vm.$emit("onSubmit", vm.input_data);
      vm.$emit("input", vm.input_data);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.tags-wrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  background: #fff;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;

  .tags-widget {
    display: flex;
    flex-wrap: wrap;
    padding: 4px;
    min-height: 47px;
    align-items: center;
    overflow-y: auto;
    margin-bottom: 8px;
    max-height: 300px;
    overflow-y: auto;

    .tags-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ebebeb;
      margin-right: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      color: #000;
      border-radius: 20px;

      span {
        font-size: 13px;
        padding: 4px 20px;
        text-transform: capitalize;
      }

      .close-btn {
        background: #a51b1b;
        color: #fff;
        height: 15px;
        width: 15px;
        font-size: 17px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .tags-forms {
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding-right: 10px;
    padding: 2px;
    max-width: 140px;
    height: 33px;

    .item-input {
      border: none;
      outline: none;
      width: 100%;
      height: 30px;
      padding: 0px 10px;
    }

    .btn-add {
      border: none;
      outline: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 15px;
      }
    }
  }
}
</style>