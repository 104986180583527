export const AUTH_REQUEST_ERROR = "AUTH_REQUEST_ERROR";
export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const CHANGE_LOCALE_REQUEST = "CHANGE_LOCALE_REQUEST";
export const INITIALIZE_APP_DATA = "INITIALIZE_APP_DATA";
export const AUTH_LINKEDIN_SIGNUP_REQUEST = "AUTH_LINKEDIN_SIGNUP_REQUEST";
export const ACCOUNT_REGISTER_VIA_INVITE = "ACCOUNT_REGISTER_VIA_INVITE";
export const SUBMIT_INVITE_PARTICIPATION_INFO = "SUBMIT_INVITE_PARTICIPATION_INFO";
export const AUTH_LINKEDIN_LOGIN_REQUEST = "AUTH_LINKEDIN_LOGIN_REQUEST";
export const AUTH_SET_TYPE = "AUTH_SET_TYPE"
export const AUTH_GOOGLE_SIGNUP_REQUEST = "AUTH_GOOGLE_SIGNUP_REQUEST";
export const AUTH_FACEBOOK_SIGNUP_REQUEST = "AUTH_FACEBOOK_SIGNUP_REQUEST";
export const CONSULTIFIED_SIGNUP = 'CONSULTIFIED_SIGNUP'
export const ACCOUNT_FINISH_WIZARDS = "ACCOUNT_FINISH_WIZARDS";
export const RESEND_VERIFY_EMAIL_ACCOUNT = "RESEND_VERIFY_EMAIL_ACCOUNT";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_CITIES = "GET_CITIES";
export const GET_COMPANIES = "GET_COMPANIES";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const AUTH_MICROSOFT_SIGNUP_REQUEST = "AUTH_MICROSOFT_SIGNUP_REQUEST";
export const GET_ALL_USER_INFO = "GET_ALL_USER_INFO"