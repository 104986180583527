<template>
  <div class="page-modal">
    <!-- Single -->
    <widgetModal :title="form.id ? 'Update Lead' : 'Create a new Lead'" :subtitle="form.id
      ? 'Fill in the information below to update the lead.'
      : 'Fill in the information below to create a new lead.'
      " btnlefttext="Save Changes" btncentertext="Cancel" btnrighttext="" :leftloading="loading_add_story"
      :rightloading="false" :centerloading="false" :footerhidden="false" footer_padding="10px 18px 30px 30px"
      header_padding="30px 0px 0px 31px" body_padding="0px 30px" width="600px" :hiderightbtn="false"
      @onLeftClick="submitStory()" @onCenterClick="cancelModal">
      <template v-slot:right_header_options>
        <div class="task-item-options" v-if="form.id">
          <app-dialog title="Delete Lead" ask="Are you sure you want to delete this lead?" yesText="Delete"
            noText="Cancel" @onComfirm="deleteStory(value)">
            <button class="btn-delete">
              <img src="@/assets/admin-icon/trush.svg" /> Delete
            </button>
          </app-dialog>
        </div>
      </template>

      <div class="widget-modal-body-noscoll">
        <widgetInputWrapper title="Lead name*">
          <widgetInput v-model="form.lead_name" type="text"></widgetInput>
        </widgetInputWrapper>

        <widgetInputWrapper title="Account" checkbox="New Account" @onChecked="(response) => {
          form.create_account = response;
        }
          ">
          <searchSelectInput v-if="!form.create_account" url="/admin/account/search" :miltiple="false"
            :hasPagination="true" placeholder="Search Account" v-model="story_account">

            <template v-slot:result="{ result }">
              <span class="search-account-item">{{
                result.name | validate
              }}</span>
            </template>

            <template v-slot:selected="{ selected }">
              <span class="selected-search-account-item">{{
                selected.name | validate
              }}</span>
            </template>
          </searchSelectInput>
          <widgetInput v-else v-model="form.account_name" type="text" placeholder="Enter account name"></widgetInput>
        </widgetInputWrapper>

        <widgetInputWrapper title="Contact" v-if="!form.create_account && story_account.added[0]">
          <searchSelectInput :url="`/admin/contact/search/${story_account.added[0].id}`" :miltiple="true"
            :hasPagination="true" v-model="story_contact" placeholder="Search Contact">

            <template v-slot:result="{ result }">
              <span class="search-contact-item">{{ result.first_name | validate }}
                {{ result.last_name | validate }}</span>
            </template>

            <template v-slot:selected="{ selected }">
              <span class="selected-search-contact-item">{{
                selected.first_name | validate
              }}</span>
            </template>
          </searchSelectInput>
        </widgetInputWrapper>

        <div class="add-new-contact" v-if="form.create_account">
          <widgetInputWrapper title="New Contact">
            <div class="row">
              <widgetInput v-model="form.first_name" type="text" placeholder="First name"></widgetInput>
              <div class="h-spacer"></div>
              <widgetInput v-model="form.last_name" type="text" placeholder="Last name"></widgetInput>
            </div>
            <div class="v-spacer"></div>
            <div class="row">
              <widgetInput v-model="form.phone" type="text" placeholder="Phone"></widgetInput>
              <div class="h-spacer"></div>
              <widgetInput v-model="form.email" type="text" placeholder="Email"></widgetInput>
            </div>
          </widgetInputWrapper>
        </div>
        <widgetInputWrapper title="Tags">
          <TagsInput v-model="tags" />
        </widgetInputWrapper>

        <widgetInputWrapper title="Close date">
          <datepicker :monday-first="true" :disabled-dates="{
            to: moment().subtract(1, 'days').toDate(),
          }" placeholder="To" input-class="date-field_wrapper" wrapper-class="__date-field" v-model="form.period_to">
          </datepicker>
        </widgetInputWrapper>

        <widgetInputWrapper title="Responsible / Assigned">
          <searchSelectInput url="/admin/company/search/manager" :miltiple="true" type="site" v-model="assigned"
            placeholder="Responsible">

            <template v-slot:result="{ result }">
              <span class="search-contact-item"><img width="20" v-if="result.profile_picture"
                  :src="$fullUrl('images/profiles/' + result.profile_picture)" />
                <img v-else width="20" src="@/assets/avatar.png" />
                {{ result.first_name | validate }}
                {{ result.last_name | validate }}</span>
            </template>

            <template v-slot:selected="{ selected }">
              <span class="selected-search-contact-item">
                <img width="20" v-if="selected.profile_picture"
                  :src="$fullUrl('images/profiles/' + selected.profile_picture)" />
                <img v-else width="20" src="@/assets/avatar.png" />
                {{ selected.first_name | validate }}</span>
            </template>
          </searchSelectInput>
        </widgetInputWrapper>

        <widgetInputWrapper title="Upload attachment">
          <div class="upload-input" v-if="form.attachment" style="height: 100px; width: 100%">
            <Uploader caption="Upload file" remove_caption="Remove file" :uploadBefore="true"
              @onFileSelected="(file) => { form.attachment = file }" :max_size="20" :value="{
                url: $crmFullUrl('images/'),
                file_name: form.attachment,
              }" />
          </div>
          <div v-else class="upload-input" style="height: 100px; width: 100%">
            <Uploader caption="Upload file" remove_caption="Remove file" :uploadBefore="true"
              @onFileSelected="(file) => { form.attachment = file }" :max_size="20" />
          </div>
        </widgetInputWrapper>

        <widgetInputWrapper title="Description/Comments">
          <widgetTextarea v-model="form.description" placeholder=""></widgetTextarea>
        </widgetInputWrapper>
      </div>
    </widgetModal>
  </div>
</template>

<script>
import { eventBus } from "@/main";
export default {
  name: "page-modal",
  components: {},
  props: {
    value: {
      type: Object,
      default: null,
    },
    column_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading_add_story: false,
      form: {
        id: null,
        lead_name: "",
        team_id: null,
        section_id: null,
        create_account: false,
        account_id: null,
        account_added: [],
        account_removed: [],
        contact_id: null,
        value: "",
        parcentage: 0,
        assigned: [],
        added: [],
        removed: [],
        contacts_added: [],
        contacts_removed: [],
        tags: [],
        tags_added: [],
        tags_removed: [],
        period_from: "",
        period_to: "",
        // New account
        account_name: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        description: "",
        attachment: "",
      },
      tags: {
        added: [],
        removed: [],
      },
      story_account: {
        added: [],
        removed: [],
      },
      story_contact: {
        added: [],
        removed: [],
      },
      assigned: {
        added: [],
        removed: [],
      },
    };
  },
  mounted() {
    let vm = this;
    if (vm.value) {
      vm.setFieldsValue(vm.value);
    } else {
      vm.resetFormFields();
    }
  },
  watch: {},
  methods: {
    deleteStory(item) {
      let vm = this;
      vm.$emit("delete", item);
    },
    setFieldsValue(lead) {
      let vm = this;
      vm.form.id = lead.id;
      vm.form.lead_name = lead.name;
      vm.form.section_id = lead.column_id;
      vm.form.account_id = lead.account_id;
      vm.form.value = lead.value;
      vm.form.period_from = lead.from;
      vm.form.period_to = lead.to;
      vm.form.create_account = false;
      vm.form.parcentage = 0;
      vm.form.description = lead.description;
      vm.form.attachment = lead.attachment;
      vm.form.assigned = [];
      vm.assigned = {
        added: [],
        removed: [],
      };
      vm.story_account = {
        added: [],
        removed: [],
      };
      vm.story_contact = {
        added: [],
        removed: [],
      };
      vm.added = [];
      vm.removed = [];
      lead.assignees.forEach((item) => {
        vm.assigned.added.push({
          id: item.id,
          first_name: item.first_name,
          last_name: item.last_name,
          profile_picture: item.profile_picture,
          on_update: true,
        });
      });
      if (lead.account) {
        vm.story_account.added.push({
          on_update: true,
          ...lead.account
        });
      }
      lead.contacts.forEach((item) => {
        eventBus.$set(item, "on_update", true);
        vm.story_contact.added.push(item);
      });
      vm.tags = {
        added: [],
        removed: [],
      };
      lead.tags.forEach((item) => {
        eventBus.$set(item, "on_update", true);
        vm.tags.added.push(item);
      });
    },
    resetFormFields() {
      let vm = this;
      vm.form.assigned = [];
      vm.assigned = {
        added: [],
        removed: [],
      };
      vm.story_account = {
        added: [],
        removed: [],
      };
      vm.story_contact = {
        added: [],
        removed: [],
      };
      vm.added = [];
      vm.removed = [];
      vm.tags = {
        added: [],
        removed: [],
      };
      vm.form = {
        id: null,
        lead_name: "",
        team_id: null,
        section_id: vm.column_id,
        create_account: false,
        account_id: null,
        account_added: [],
        account_removed: [],
        contact_id: null,
        value: "",
        parcentage: 0,
        assigned: [],
        added: [],
        removed: [],
        contacts_added: [],
        contacts_removed: [],
        period_from: "",
        period_to: "",
        // New account
        account_name: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        description: "",
        attachment: "",
      };
    },
    submitStory() {
      let vm = this;

      vm.form.tags = vm.tags.added.map((item) => {
        return item;
      });
      if (vm.story_account.added.length > 0) {
        if (!vm.form.id) {
          vm.form.account_id = vm.story_account.added[0].id;
          vm.form.account_added = vm.story_account.added;
        }
      }
      if (vm.story_contact.added.length > 0) {
        if (!vm.form.id) {
          vm.form.contact_id = vm.story_contact.added.map((item) => {
            return item.id;
          });
        }
      }
      vm.form.assigned = vm.assigned.added.map((item) => {
        return {
          id: item.id,
          first_name: item.first_name,
          last_name: item.last_name,
          profile_picture: item.profile_picture,
        };
      });

      const rules = {
        lead_name: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Lead name can not be empty";
          },
        },
        // account_id: {
        //   run: (value) => {
        //     if (vm.form.create_account) {
        //       if (vm.form.account_name) {
        //         return false;
        //       } else {
        //         return "Please enter account name";
        //       }
        //     } else {
        //       if (value) {
        //         return false;
        //       } else {
        //         return "Please select account";
        //       }
        //     }
        //   },
        // },
        // contact_id: {
        //   run: (value) => {
        //     if (vm.form.create_account) {
        //       if (
        //         vm.form.first_name &&
        //         vm.form.last_name &&
        //         vm.form.phone &&
        //         vm.form.email
        //       ) {
        //         return false;
        //       } else {
        //         return "Please fill all contacts information";
        //       }
        //     } else {
        //       if (value) {
        //         return false;
        //       } else {
        //         return "Please add some contacts";
        //       }
        //     }
        //   },
        // },
        period_to: {
          run: (value) => {
            if (value) {
              return false;
            } else {
              return "Please select period";
            }
          },
        },
        assigned: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Add some responsible";
          },
        },
      };
      if (vm.$isFieldsValidated(vm.form, rules)) {
        vm.form.period_from = vm.moment().format("YYYY-MM-DD");
        vm.form.period_to = vm.moment(vm.form.period_to).format("YYYY-MM-DD");
        vm.loading_add_story = true;
        if (!vm.form.id) {
          vm.$store
            .dispatch("LEADS_ADD_NEW_STORY", vm.form)
            .then((response) => {
              vm.loading_add_story = false;
              vm.show_form = false;
              vm.$emit("saved");
            })
            .catch((error) => {
              vm.loading_add_story = false;
            });
        } else {
          // For assigned
          vm.form.added = vm.assigned.added
            .filter((item) => !item.on_update)
            .map((item) => {
              return {
                id: item.id,
                first_name: item.first_name,
                last_name: item.last_name,
                profile_picture: item.profile_picture,
              };
            });
          vm.form.removed = vm.assigned.removed.map((item) => {
            return item.id;
          });
          // For account
          vm.form.account_added = vm.story_account.added
            .filter((item) => !item.on_update)
            .map((item) => {
              return item;
            });
          if (vm.form.account_added.length > 0) {
            vm.form.account_id = vm.form.account_added[0].id;
          } else {
            vm.form.account_id = null;
          }
          vm.form.account_removed = vm.story_account.removed.map((item) => {
            return item.id;
          });

          // For contacts
          vm.form.contacts_added = vm.story_contact.added
            .filter((item) => !item.on_update)
            .map((item) => {
              return item.id;
            });
          vm.form.contacts_removed = vm.story_contact.removed.map((item) => {
            return item.id;
          });
          if (vm.form.account_added.length == 0 && vm.form.account_removed.length > 0) {
            vm.form.contacts_removed = vm.story_contact.added
              .map((item) => {
                return item.id;
              });
          }
          // For tags
          vm.form.tags_added = vm.tags.added
            .filter((item) => !item.on_update)
            .map((item) => {
              return item;
            });
          vm.form.tags_removed = vm.tags.removed.map((item) => {
            return item;
          });


          vm.$store
            .dispatch("LEADS_UPDATE_STORY", vm.form)
            .then((response) => {
              vm.loading_add_story = false;
              vm.show_form = false;
              vm.$emit("saved");
            })
            .catch((error) => {
              vm.loading_add_story = false;
            });
        }
      }
    },
    cancelModal() {
      let vm = this;
      vm.$emit("close");
    },
    setImagefile(file) {
      let vm = this;
      if (file) {
        vm.form.file = file;
        vm.form.file_set = true;
        vm.form.profile_picture = "";
      } else {
        vm.form.file_set = false;
      }
    },
  },
  computed: {},
  created() { },
};
</script>

<style lang="scss" scoped>
.task-item-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
}

.widget-modal-body-noscoll {
  .input-group {
    position: relative;

    .task-search-reasult {
      position: absolute;
      height: 200px;
      overflow-y: auto;
      z-index: 9999;
      background: #fff;
      box-shadow: 0px 0px 10px #a8a8a8;
      width: 100%;
      top: 62px;

      .task-item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background: rgb(241, 241, 241);
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
}

.search-account-item {
  padding: 10px 15px;
  display: block;
  cursor: pointer;

  &:hover {
    background: rgb(250, 250, 250);
  }
}

.selected-search-account-item {
  padding: 5px 12px;
  display: block;
  cursor: pointer;
  border: 1px solid #ddd;
  margin-top: 10px;
  border-radius: 15px;
  font-size: 11px;

  &:hover {
    background: rgb(250, 250, 250);
  }
}

.input-date {
  background: #fff;
}
</style>
