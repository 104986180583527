<template>
  <button class="loading-btn" @click="onClick()" :disabled="is_loading">
    <img src="@/assets/icons/loading_white.gif" v-if="is_loading" alt />
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: "consultified_button",
  components: {},
  props: {
    loading:{
      type: Boolean,
      default:false
    }
  },
  data: () => {
    return {
      is_loading: false
    };
  },
  watch: {
    loading: function(val) {
        this.is_loading = val;
    }
  },
  mounted() {
    if (this.loading) {
      this.is_loading = this.loading;
    }
  },
  methods: {
    onClick() {
      this.$emit("click", null);
    }
  }
};
</script>
<style lang="scss" scoped>
.loading-btn {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    margin-right: 10px;
  }
}
</style>
