<template>
  <div class="notfound-wrapper" :style="{ justifyContent: align }">
    <div class="notfound-widget" :style="{ alignItems: align }">
      <slot></slot>
      <h3 class="widget-title">{{ title }}</h3>
      <p class="widget-content">
        {{ content }}
      </p>
      <widgetButton v-if="btntext" @click="makeAction()" :loading="false" class="btn-secondary">
        {{ btntext }}
      </widgetButton>


    </div>
  </div>
</template>
<script>
export default {
  name: "not-found-widget",
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    btntext: {
      type: String,
      default: "",
    },
    align: {
      type: String,
      default: "center",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() { },
  methods: {
    makeAction() {
      let vm = this;
      vm.$emit("click", null);
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.notfound-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 15px;

  .notfound-widget {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .widget-img {
      width: 200px;
    }

    .widget-title {
      font-size: 13px;
      text-align: center;
      color: #34343f;
      margin: 0px;
      margin-top: 25px;
    }

    .widget-content {
      text-align: center;
      color: #464665;
      font-size: 11px;
      max-width: 400px;
    }

    .btn-add {
      background: #0884af;
      border-radius: 5px;
      height: 45px;
      width: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }
}
</style>