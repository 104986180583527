<template>
  <div class="tags-viewer-widget" ref="tags_widget">
    <template v-for="(tag, index) in slicedTags">
      <widgetTagItem :key="index" :value="tag.name" :canClose="canDelete" @remove="removeTags(tag.id, index)">
      </widgetTagItem>
    </template>
    <button v-if="tags.length > 2" @click="show_popup = !show_popup" @mouseover="show_popup = true"
      @mouseleave="show_popup = false" class="btn-dropdown">
      <img src="@/assets/admin-icon/white-caret-down.svg" />

      <!-- Tags popup -->
      <div class="tags-popup" v-if="show_popup" v-closable="{ exclude: ['tags_widget'], handler: 'hidePopup' }">
        <template v-for="(item, index) in leftTags">
          <widgetTagItem :key="index" :value="item.name" :canClose="canDelete" @remove="removeTags(item.id, index + 2)">
          </widgetTagItem>
        </template>
      </div>
    </button>
  </div>
</template>
<script>
export default {
  name: "tags-widget-viewer",
  props: {
    tags: {
      type: Array,
      default: function () {
        return [];
      },
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show_popup: false,
    };
  },
  computed: {
    slicedTags() {
      return this.tags.slice(0, 2);
    },
    leftTags() {
      return this.tags.slice(2, 1000);
    },
  },
  watch: {},
  mounted() {
    let vm = this;
  },
  methods: {
    removeTags(id, i) {
      this.$emit("remove", { id: id, index: i });
    },
    hidePopup() {
      this.show_popup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";

.tags-viewer-widget {
  display: flex;
  align-items: center;

  .btn-dropdown {
    background: #0986af;
    border-radius: 4px;
    border: none;
    padding: 0;
    margin: 0;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 10px;
      height: 10px;
    }

    .tags-popup {
      position: absolute;
      top: 40px;
      right: 0px;
      width: 300px;
      background: #fff;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0px 0px 8px rgb(199, 199, 199);
    }
  }
}
</style>