<template>
  <div class="company-profile-widget">
    <!-- company-profile display -->
    <div class="company-profile-container">
      <!-- loading widget -->
      <loading :height="25" color="#000" :opacity="1" :width="25" :active.sync="loading_all_data" :is-full-page="false"
        :can-cancel="false" background-color="#ffffff90" class="loader"></loading>
      <!-- loading widget -->
      <div class="company-profile-body">
        <div class="tabs">
          <div class="tab" :class="{ active: tab == 'man_info' }" @click="tab = 'man_info'">
            Instuctor information
          </div>
          <div class="tab" :class="{ active: tab == 'comp_info' }" @click="tab = 'comp_info'">
            School information
          </div>
          <div class="tab" :class="{ active: tab == 'sec_info' }" @click="tab = 'sec_info'">
            Security information
          </div>
          <div class="tab" :class="{ active: tab == 'term_tab' }" @click="tab = 'term_tab'">
            Terms & Conditions
          </div>
        </div>
        <div class="tab-body man_info" v-if="tab == 'man_info'">
          <div class="row">
            <widgetInputWrapper style="width: 65%" title="First Name">
              <widgetInput v-model="manager_form.first_name" type="text" placeholder=""></widgetInput>
            </widgetInputWrapper>
            <div class="h-spacer"></div>
            <widgetInputWrapper style="width: 35%" title="Last name">
              <widgetInput v-model="manager_form.last_name" type="text" placeholder=""></widgetInput>
            </widgetInputWrapper>
          </div>

          <div class="row">
            <widgetInputWrapper style="width: 65%" title="Telephone">
              <widgetInput v-model="manager_form.phone" type="text" placeholder=""></widgetInput>
            </widgetInputWrapper>
            <div class="h-spacer"></div>
            <widgetInputWrapper style="width: 35%" title="Location">
              <map-helper v-model="manager_form.manager_address"></map-helper>
            </widgetInputWrapper>
          </div>

          <widgetInputWrapper title="Description">
            <html-editor v-model="manager_form.manager_description" id="man-info-edit-descr" />
          </widgetInputWrapper>

          <div class="row align-start">
            <widgetInputWrapper style="width: 40%" title="Profile image">
              <upload :crop="true" @onFileSelected="setManagerProfileLogo" :value="{
                url: $fullUrl('images/profiles/'),
                file_name: manager_form.profile_logo_url,
              }" />
            </widgetInputWrapper>
            <div class="h-spacer"></div>
            <widgetInputWrapper style="width: 60%" title="Cover image">
              <div class="cover-upload-widget">
                <div class="cover-upload">
                  <upload :crop="true" :ration="10 / 1" @onFileSelected="setManagerCoverLogo" :value="{
                    url: $fullUrl('images/covers/'),
                    file_name: manager_form.cover_image_url,
                  }" />
                </div>
                <div class="default-cover-image">
                  <div class="cover-image-item" v-for="index in 2" :key="index" :class="{
                    active:
                      manager_form.cover_image_url == index - 1 + '.png',
                  }" @click="setCoverImage(index - 1 + '.png')">
                    <img :src="$fullUrl('images/covers/' + (index - 1) + '.png')" />
                  </div>
                </div>
              </div>
            </widgetInputWrapper>
          </div>

          <div class="options-widget">
            <widgetButton @click="submitManagerInfo()" :loading="loading" class="btn-secondary">
              Save
            </widgetButton>
            <div class="h-spacer"></div>

            <widgetButton :loading="false" @click="onCancel()" class="btn-transparent-secondary">
              Cancel
            </widgetButton>
          </div>
        </div>
        <div class="tab-body comp_info" v-if="tab == 'comp_info'">
          <h2 class="tab-title">School information</h2>

          <div class="row">
            <widgetInputWrapper style="width: 65%" title="School name">
              <widgetInput v-model="company_form.company_name" type="text" placeholder=""></widgetInput>
            </widgetInputWrapper>
            <div class="h-spacer"></div>
            <widgetInputWrapper style="width: 35%" title="Location">
              <map-helper v-model="company_form.company_address"></map-helper>
            </widgetInputWrapper>
          </div>

          <widgetInputWrapper title="Description">
            <html-editor v-model="company_form.company_description" id="comp-info-edit-descr" />
          </widgetInputWrapper>

          <widgetInputWrapper style="width: 180px" title="Logo image">
            <upload :crop="true" @onFileSelected="setCompanyProfileLogo" :value="{
              url: $fullUrl('images/profiles/'),
              file_name: company_form.profile_logo_url,
            }" />
          </widgetInputWrapper>

          <div class="options-widget">
            <widgetButton @click="submitCompanyInfo()" :loading="loading" class="btn-secondary">
              Save
            </widgetButton>
            <div class="h-spacer"></div>
            <widgetButton :loading="false" @click="onCancel()" class="btn-transparent-secondary">
              Cancel
            </widgetButton>
          </div>
        </div>
        <div class="tab-body sec_info" v-if="tab == 'sec_info'">
          <h4 class="tab-title">Change Password</h4>
          <div class="row">
            <widgetInputWrapper style="width: 65%" title="Current Password">
              <widgetInput v-model="security_form.current_pass" type="password" placeholder=""></widgetInput>
            </widgetInputWrapper>
            <div class="h-spacer"></div>
            <div class="option">
              <a href="#" @click.prevent="$router.push({ name: 'forgot-password' })">Forgot password</a>
            </div>
          </div>

          <div class="row">
            <widgetInputWrapper style="width: 65%" title="New Password">
              <widgetInput v-model="security_form.new_password" type="password" placeholder=""></widgetInput>
            </widgetInputWrapper>
          </div>

          <div class="row">
            <widgetInputWrapper style="width: 65%" title="Comfirm Password">
              <widgetInput v-model="security_form.comfirm_pass" type="password" placeholder=""></widgetInput>
            </widgetInputWrapper>
          </div>

          <div class="row">
            <widgetButton @click="updatePassword()" :loading="loading" class="btn-secondary">
              Save
            </widgetButton>
            <div class="h-spacer"></div>
            <widgetButton :loading="false" @click="onCancel()" class="btn-transparent-secondary">
              Cancel
            </widgetButton>
          </div>

          <div class="other-options">
            <app-dialog title="Account Deletion" ask="Are you sure you want to delete this account?" yesText="Accept"
              noText="Cancel" yesBtnClass="btn-default" @onComfirm="deleteAccount()">
              <consultfied-button class="btn-delete-account" :loading="account_delete_loading">Delete
                Account</consultfied-button>
            </app-dialog>
          </div>
        </div>
        <div class="tab-body term_tab" v-if="tab == 'term_tab'">
          <terms-widget />
        </div>
      </div>
    </div>
    <!-- End -->
  </div>
</template>

<script>
import upload from "@/components/Partials/Uploader.vue";
import consultfiedButton from "@/components/helper/consultfiedButton.vue";
import TermsWidget from "@/components/TermsWidget.vue";
export default {
  name: "companySettings",
  components: {
    upload,
    consultfiedButton,
    TermsWidget,
  },
  props: {},
  data: () => {
    return {
      tab: "comp_info",
      isScoll: false,
      user: {
        company_info: {},
        manager_info: {},
        manager_profile: {},
      },
      manager_form: {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        manager_address: "",
        manager_description: "",
        profile_logo: null,
        profile_logo_url: "",
        cover_image: null,
        default_cover_image: "",
        cover_image_url: "",
        profile_logo_set: true,
        cover_image_set: true,
      },
      company_form: {
        id: null,
        company_name: "",
        company_domain: "",
        company_address: "",
        company_description: "",
        profile_logo: null,
        profile_logo_url: "",
        profile_logo_set: true,
      },
      security_form: {
        current_pass: "",
        new_password: "",
        comfirm_pass: "",
      },
      loading: false,
      account_delete_loading: false,
      company_delete_loading: false,
      loading_all_data: false,
    };
  },
  mounted() {
    let vm = this;
    vm.initialData();
  },
  computed: {},
  methods: {
    initialData() {
      let vm = this;
      vm.loading_all_data = true;
      vm.$store
        .dispatch("GET_ME_USER_COMPANY")
        .then((responce) => {
          vm.loading_all_data = false;
          vm.user = responce.data;
          // Set manager data
          vm.manager_form.id = vm.user.manager_info.id;
          vm.manager_form.first_name = vm.user.manager_info.first_name;
          vm.manager_form.last_name = vm.user.manager_info.last_name;
          vm.manager_form.email = vm.user.manager_info.email;
          vm.manager_form.phone = vm.user.manager_profile.phone;
          vm.manager_form.manager_address = vm.user.manager_profile.address;
          vm.manager_form.manager_description =
            vm.user.manager_info.profile.description;
          vm.manager_form.profile_logo = null;
          vm.manager_form.profile_logo_url =
            vm.user.manager_info.profile_picture;
          vm.manager_form.cover_image = null;
          vm.manager_form.cover_image_url =
            vm.user.manager_info.profile.cover_photo;

          // Set company data
          vm.company_form.id = vm.user.company_info.id;
          vm.company_form.company_name = vm.user.company_info.company_name;
          vm.company_form.company_domain =
            vm.user.company_info.domain == "null"
              ? ""
              : vm.user.company_info.domain;
          vm.company_form.company_address = vm.user.company_info.address;
          vm.company_form.company_description =
            vm.user.company_info.description;
          vm.company_form.profile_logo = null;
          vm.company_form.profile_logo_url = vm.user.company_info.company_logo;
        })
        .catch((error) => {
          vm.loading_all_data = false;
        });
    },
    deleteAccount() {
      let vm = this;
      vm.account_delete_loading = true;
      vm.$store
        .dispatch("DELETE_COMPANY_ACCOUNT", { id: vm.user.manager_info.id })
        .then((response) => {
          vm.account_delete_loading = false;
          window.parent.postMessage({ message: "logoutUser", value: "" }, "*");
        })
        .catch((error) => {
          vm.account_delete_loading = false;
        });
    },
    deleteCompany() {
      let vm = this;
      vm.company_delete_loading = true;
      vm.$store
        .dispatch("DELETE_COMPANY", { id: vm.company_form.id })
        .then((response) => {
          vm.company_delete_loading = false;
          window.parent.postMessage({ message: "logoutUser", value: "" }, "*");
        })
        .catch((error) => {
          vm.company_delete_loading = false;
        });
    },
    updatePassword() {
      let vm = this;
      vm.loading = true;
      if (vm.security_form.new_password == vm.security_form.comfirm_pass) {
        vm.$store
          .dispatch("UPDATE_COMPANY_ACCOUNT_PASSWORD", vm.security_form)
          .then((response) => {
            vm.loading = false;
            vm.$router.push({ path: "/profile/consultant" });
          })
          .catch((error) => {
            vm.loading = false;
          });
      } else {
        vm.loading = false;
        vm.$notify({
          group: "status",
          title: "Warning",
          text: "Password does not match.",
          type: "warn",
          duration: 3000,
        });
      }
    },
    submitCompanyInfo() {
      let vm = this;
      vm.loading = true;
      let form_data = new FormData();
      form_data.append("company_name", vm.company_form.company_name || "");
      form_data.append("company_domain", vm.company_form.company_domain || "");
      form_data.append("address", vm.company_form.company_address || "");
      form_data.append(
        "company_description",
        vm.company_form.company_description || ""
      );
      if (vm.company_form.profile_logo) {
        form_data.append(
          "profile_logo",
          vm.company_form.profile_logo,
          vm.company_form.profile_logo.name
        );
      }
      form_data.append("profile_logo_set", vm.company_form.profile_logo_set);

      vm.$store
        .dispatch("UPDATE_COMPANY_INFO", form_data)
        .then((response) => {
          vm.loading = false;
          vm.$emit("onClose", null);
        })
        .catch((error) => {
          vm.loading = false;
        });
    },
    submitManagerInfo() {
      let vm = this;
      vm.loading = true;
      let form_data = new FormData();
      form_data.append("first_name", vm.manager_form.first_name || "");
      form_data.append("last_name", vm.manager_form.last_name || "");
      form_data.append("address", vm.manager_form.manager_address || "");
      form_data.append("phone", vm.manager_form.phone || "");
      form_data.append(
        "manager_description",
        vm.manager_form.manager_description || ""
      );
      if (vm.manager_form.profile_logo) {
        form_data.append(
          "profile_logo",
          vm.manager_form.profile_logo,
          vm.manager_form.profile_logo.name
        );
      }
      if (vm.manager_form.cover_image) {
        form_data.append(
          "cover_image",
          vm.manager_form.cover_image,
          vm.manager_form.cover_image.name
        );
      } else {
        if (vm.manager_form.default_cover_image) {
          form_data.append(
            "default_cover",
            vm.manager_form.default_cover_image
          );
        }
      }
      form_data.append("profile_logo_set", vm.manager_form.profile_logo_set);
      form_data.append("cover_image_set", vm.manager_form.cover_image_set);

      vm.$store
        .dispatch("UPDATE_MANAGER_INFO", form_data)
        .then((response) => {
          vm.loading = false;
          vm.$emit("onClose", null);
        })
        .catch((error) => {
          vm.loading = false;
        });
    },
    onCancel() {
      let vm = this;
      vm.$emit("onCancel", null);
    },
    onScoll({ target }) {
      // Modify header according to profile scoll
      let vm = this;
      vm.isScoll = true;
    },
    setManagerProfileLogo(file) {
      let vm = this;
      if (file) {
        vm.manager_form.profile_logo = file;
        vm.manager_form.profile_logo_url = "";
        vm.manager_form.profile_logo_set = true;
      } else {
        vm.manager_form.profile_logo_set = false;
      }
    },
    setManagerCoverLogo(file) {
      let vm = this;
      if (file) {
        vm.manager_form.cover_image = file;
        vm.manager_form.cover_image_url = "";
        vm.manager_form.default_cover_image = "";
        vm.manager_form.cover_image_set = true;
      } else {
        vm.manager_form.cover_image_set = false;
      }
    },
    setCompanyProfileLogo(file) {
      let vm = this;
      if (file) {
        vm.company_form.profile_logo = file;
        vm.company_form.profile_logo_url = "";
        vm.company_form.profile_logo_set = true;
      } else {
        vm.company_form.profile_logo_set = false;
      }
    },
    setCoverImage(file_name) {
      let vm = this;
      vm.manager_form.cover_image_url = file_name;
      vm.manager_form.default_cover_image = file_name;
      vm.manager_form.cover_image = null;
      vm.manager_form.cover_image_set = true;
    },
  },
};
</script>
<style lang="scss" scoped>
$assets: "~@/assets";

.company-profile-widget {
  background: rgba($color: #000000, $alpha: 0.5);
  min-height: 500px;
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: auto;
  z-index: 999;

  @media (min-width: 300px) and (max-width: 630px) {
    bottom: 45px;
  }

  .company-profile-container {
    padding: 20px;
    border-radius: 10px;
    width: 600px;
    margin: 0 auto;
    background: #fff;
    margin-top: 40px;
    position: relative;

    .company-profile-body {
      border-radius: 5px;
      margin-bottom: 0px;

      .tabs {
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;

        .tab:nth-child(1) {
          border-top-left-radius: 10px;
        }

        .tab:nth-child(4) {
          border-top-right-radius: 10px;
        }

        .tab {
          background: #cccccc;
          height: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 500ms ease-in-out;
          color: #fff;

          @media (min-width: 300px) and (max-width: 630px) {
            font-size: 12px;
            text-align: center;
          }

          &.active {
            background: #fff;
            color: #1ba5a4;

            transition: 500ms ease-in-out;
          }

          &:hover {
            color: #1ba5a4;
          }
        }
      }

      .tab-body {
        padding: 0px;

        &.man_info {
          .cover-upload-widget {
            background: #fff;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            height: 283px;

            .cover-upload {
              height: 114px;
            }

            .default-cover-image {
              display: flex;
              height: 100%;
              overflow-y: auto;
              padding: 15px 0px;
              flex-wrap: wrap;

              .cover-image-item {
                border: 1px solid #e9e9e9;
                height: 70px;
                width: 70px;
                margin-bottom: 8px;
                margin-right: 8px;
                display: flex;
                padding: 5px;

                img {
                  width: 100%;
                  object-fit: cover;
                  -webkit-transition: 500ms ease-in-out;
                  transition: 500ms ease-in-out;
                }

                &.active {
                  border: 1px solid #1890a9 !important;
                }
              }
            }
          }

          .options-widget {
            display: flex;
            margin-top: 30px;
          }
        }

        &.comp_info {
          display: flex;
          flex-direction: column;

          .tab-title {
            margin-bottom: 15px;
            font-size: 15px;
          }

          .options-widget {
            display: flex;
            margin-top: 30px;

            .btn-save {
              color: #fff;
              outline: none;
              border-radius: 5px;
              padding: 13px 13px;
              width: 100px;
              background-image: linear-gradient(267deg,
                  #1885ad 103%,
                  #04a5a5 0%);
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .btn-cancel {
              color: #1ba5a4;
              outline: none;
              padding: 7px;
              width: 100px;
            }
          }
        }

        &.sec_info {
          .tab-title {
            font-size: 15px;
            margin-bottom: 20px;
          }

          .other-options {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            .btn-delete-company,
            .btn-delete-account {
              color: #fff;
              height: 45px;
              width: 200px;
              background: #4d4d4d;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        &.term_tab {
          padding-top: 20px;

          .__logo {
            width: 79px;
            margin-bottom: 28px;
          }
        }
      }
    }
  }
}

.input-flex {
  display: flex;
}
</style>