import {
    GET_CHAT_CONNECTIONS,
    GET_CHAT_MESSAGES,
    POST_MESSAGE,
    POST_SEARCH_CONNECTION,
    POST_SEARCH_CONSULTANTS,
    SET_AS_READ
} from '../Actions/message';
import router from "../../router";


import {
    API,
    GET_CHAT_CONNECTIONS_URL,
    GET_CHAT_MESSAGES_URL,
    POST_MESSAGE_URL,
    POST_SEARCH_CONNECTION_URL,
    POST_SEARCH_CONSULTANTS_URL,
    SET_AS_READ_URL,


} from "../variable";

import axios from "axios";

const state = {
    chatConnections: [],
    chat: {
        newMessage: '',
        receiverId: 0,
        receiverName: '',
        messages: [],
        searchConnection: {
            isBoxShown: false,
            query: '',
            results: [],
            noFetchAttempt: true,
            loader: {
                isLoading: false,
                backgroundColor: '#fff',
                color: '#FF9C00',
            }
        },
    },

    consultants: []
};

const mutations = {


    [GET_CHAT_CONNECTIONS]: (state, payload) => {
        state.chatConnections = payload;
    },
    [GET_CHAT_MESSAGES]: (state, payload) => {
        state.chat.messages = payload;
    },
    [POST_SEARCH_CONNECTION]: (state, payload) => {
        state.chat.searchConnection.results = payload
    },
    [POST_SEARCH_CONSULTANTS]: (state, payload) => {
        state.consultants = payload;
    }

};

const actions = {

    [GET_CHAT_CONNECTIONS]: ({ commit, dispatch, getters }, payload) => {
        axios({
                url: API + getters.get_crm_prefix + GET_CHAT_CONNECTIONS_URL,
                method: "POST",
                headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
            })
            .then(response => {
                commit(POST_SEARCH_CONNECTION, response.data);
            })
            .catch(error => {
                console.log(error);
            });
    },
    [SET_AS_READ]: ({ commit, dispatch, getters }, payload) => {
        axios({
                url: API + getters.get_crm_prefix + SET_AS_READ_URL,
                method: "POST",
                data: validatePayloadData(payload),
                headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
            })
            .then(response => {

            })
            .catch(error => {
                console.log(error.response);
            });
    },
    [GET_CHAT_MESSAGES]: ({ commit, dispatch, getters }, payload) => {
        axios({
                url: API + getters.get_crm_prefix + GET_CHAT_MESSAGES_URL,
                method: "POST",
                data: validatePayloadData(payload),
                headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
            })
            .then(response => {
                commit(GET_CHAT_MESSAGES, response.data);
                dispatch("TITLE_BAR_INFO")
            })
            .catch(error => {

                console.log(error.response);
            });

    },
    [POST_MESSAGE]: ({ commit, dispatch, getters }, payload) => {
        axios({
                url: API + getters.get_crm_prefix + POST_MESSAGE_URL,
                method: "POST",
                data: validatePayloadData(payload),
                headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
            })
            .then(response => {
                dispatch("GET_CHAT_MESSAGES", payload)
            })
            .catch(error => {
                console.log(error.response);
            });
    },
    [POST_SEARCH_CONNECTION]: ({ commit, dispatch, getters }, payload) => {
        axios({
                url: API + getters.get_crm_prefix + POST_SEARCH_CONNECTION_URL,
                method: "POST",
                data: validatePayloadData(payload),
                headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
            })
            .then(response => {
                commit(POST_SEARCH_CONNECTION, response.data);
            })
            .catch(error => {});
    },
    [POST_SEARCH_CONSULTANTS]: ({ commit, dispatch, getters }, payload) => {
        let request = axios({
            url: API + getters.get_crm_prefix + POST_SEARCH_CONSULTANTS_URL,
            method: "POST",
            data: validatePayloadData(payload),
            headers: { 'Authorization': 'Bearer ' + sessionStorage.authToken }
        });

        request.then(response => {
                commit(POST_SEARCH_CONSULTANTS, response.data);
            })
            .catch(error => {
                commit(POST_SEARCH_CONSULTANTS, []);
            });
        return request;
    }

};

const getters = {

    get_ChatConnections(state) {
        return state.chatConnections;
    },
    getChatMessages(state) {
        return state.chat.messages;
    },
    getSearchResults() {
        return state.chat.searchConnection.results;
    },

    getConsultants() {
        return state.consultants;
    }

};


export default {
    mutations,
    actions,
    getters,
    state
}