<template>
  <div class="wrapper-overlay">
    <div class="wrapper-container-overlay" v-if="show_dialog_modal">
      <div class="dialog-modal">
        <div class="dialog-content">
          <h1 class="dialog-title">{{ dialog.title }}</h1>
          <p class="dialog-message" v-html="dialog.message"></p>
          <div class="dialog-options">
            <button
              class="btn-action"
              v-if="dialog.action_label"
              @click="openAction()"
            >
              {{ dialog.action_label }}
            </button>
            <button class="btn-cancel" @click="closeModal()">
              {{ dialog.action_close_label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { eventBus } from "@/main";
export default {
  name: "wrapper-overlay",
  components: {},
  data() {
    return {
      show_dialog_modal: false,
      dialog: {
        title: "",
        message: "",
        action_label: "",
        action_path: "",
        action_close_label: "Close",
        callback: null,
      },
    };
  },
  watch: {},
  mounted() {
    eventBus.$on("openDialogMessage", (data) => {
      this.dialog.title = data.title;
      this.dialog.message = data.message;
      this.dialog.action_label = data.action_label;
      this.dialog.action_path = data.action_path;
      this.dialog.action_close_label = data.action_close_label;
      this.dialog.callback = data.callback;
      this.show_dialog_modal = true;
    });
  },
  methods: {
    openAction() {
      let vm = this;
      vm.show_dialog_modal = false;
      if (vm.dialog.callback) {
        vm.dialog.callback();
      }
      if (vm.dialog.action_path) {
        vm.$router.push({ path: vm.dialog.action_path });
      }
    },
    closeModal() {
      let vm = this;
      vm.show_dialog_modal = false;
    },
  },
  computed: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.wrapper-overlay {
  .wrapper-container-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99999999999999;
    background: rgba($color: #000000, $alpha: 0.8);
    display: block;
    padding-top: 70px;
    .dialog-modal {
      margin: 0 auto;
      width: 600px;
      height: 300px;
      background: #fff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 40px;
      .dialog-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .dialog-title {
          text-align: center;
          color: #0884af;
          font-size: 28px;
          margin: 4px 10px;
        }
        .dialog-message {
          text-align: center;
          font-size: 15px;
          margin: 30px 0px;
        }
        .dialog-options {
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;
          .btn-action {
            margin-bottom: 20px;
            width: max-content;
            padding: 0px 20px;
            height: 44px;
            background: #1ba5a4;
            border-radius: 5px;
            color: #fff;
            font-size: 14px;
          }
          .btn-cancel {
            color: #1ba5a4;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
