<template>
  <div class="help-tool-tip">
    <div class="icon" @mouseleave="show_help_tip = false" @mouseover="show_help_tip = true">
      <img src="@/assets/icons/help.svg" alt />
    </div>
    <div class="help-text" :style="{
      width: width,
      backgroundColor: backgroundColor,
      color: textColor,
    }" :class="position" v-show="show_help_tip && text">
      {{ text | validate }}
    </div>
    <div class="help-text" :class="position" :style="{ backgroundColor: backgroundColor }" v-show="show_help_tip && src">
      <img src="@/assets/language_profe_help.svg" alt />
    </div>
  </div>
</template>
<script>
export default {
  name: "help",
  components: {},
  props: {
    text: {
      type: String,
      default: "",
    },
    src: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "top right",
    },
    width: {
      type: String,
      default: "140px",
    },
    backgroundColor: {
      type: String,
      default: "#36adae",
    },
    textColor: {
      type: String,
      default: "#fff",
    },
  },
  data: () => {
    return {
      show_help_tip: false,
    };
  },
  mounted() {

  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.help-tool-tip {
  position: relative;

  .icon {
    cursor: default;
    padding: 0px;
    margin: 0px;
    position: relative;
    top: 2px;

    img {
      width: 13px;
    }
  }

  .help-text {
    position: absolute;
    width: 140px;
    border-radius: 5px;
    padding: 10px;
    font-size: 13px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    text-align: left;
    z-index: 999;
    border: 1px solid #b0b0b0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    &.top {
      bottom: 21px;
    }

    &.bottom {
      top: 21px;
    }

    &.left {
      right: 2px;
    }

    &.right {
      left: 2px;
    }
  }
}
</style>
