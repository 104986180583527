<template>
  <div class="search-section">
    <div class="search-widget">
      <input type="text" class="search-input" v-model="form.query" :placeholder="placeholder" />
      <img class="search" src="@/assets/icons/search.svg" />
      <div class="loading-icon" v-if="is_loading">
        <img src="@/assets/icons/loading_white.gif" />
      </div>
    </div>
    <!-- Search result -->
    <div class="searched-result-data" v-if="show_search_result" v-closable="{ exclude: [], handler: 'hideSearchResult' }">
      <div class="item" v-for="(item, index) in search_result" :key="index" @click="selectItem(item)">
        <slot name="result" v-bind:result="item"></slot>
      </div>
    </div>
    <!-- Selected data -->
    <div class="selected-data" v-if="selected_result.added.length > 0">
      <div class="item" v-for="(item, index) in selected_result.added" :key="index">
        <slot name="selected" v-bind:selected="item"></slot>
        <button @click="removeItem(item, index)" class="btn-remove">
          &times;
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "search-widget",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    miltiple: {
      type: Boolean,
      default: true,
    },
    hasPagination: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
        return { added: [], removed: [] };
      },
    },
    type: {
      type: String,
      default: "crm",
    },
    action: {
      type: String,
      default: "none",
    },
  },
  components: {},
  data: () => {
    return {
      is_loading: false,
      form: {
        query: "",
      },
      show_search_result: false,
      search_result: [],
      selected_result: {
        added: [],
        removed: [],
      },
    };
  },
  watch: {
    value(val) {
      let vm = this;
      vm.selected_result.added = val.added;
    },
    "form.query"(val) {
      let vm = this;
      if (val) {
        vm.searchQuel(val);
      } else {
        vm.show_search_result = false;
      }
    },
  },
  mounted() {
    let vm = this;
    if (vm.value) {
      vm.selected_result.added = vm.value.added;
    }
  },
  methods: {
    hideSearchResult() {
      let vm = this;
      vm.show_search_result = false;
    },
    searchQuel(val) {
      let vm = this;
      if (vm.form.query) {
        vm.is_loading = true;
        vm.$store
          .dispatch("SEARCH_EVERYTHING", {
            query: val,
            url: vm.url,
            type: vm.type,
          })
          .then((response) => {
            vm.is_loading = false;
            if (vm.hasPagination) {
              vm.search_result = response.data.data;
            } else {
              vm.search_result = response.data;
            }
            vm.show_search_result = true;
          })
          .catch((error) => {
            vm.is_loading = false;
            console.error({ error });
          });
      }
    },
    selectItem(item) {
      let vm = this;
      vm.show_search_result = false;
      vm.form.query = "";
      if (vm.action == "add-class") {
        vm.$store
          .dispatch("VALIDATE_STUDENT_IF_EXIST_IN_CLASS", { user_id: item.id })
          .then((response) => {
            vm.emitContent(item);
          })
          .catch((error) => {
            vm.$notify({
              group: "status",
              title: "Message",
              text: "Student exists in other class",
              type: "warn",
              duration: 3000,
            });
          });
      } else {
        vm.emitContent(item);
      }
    },
    emitContent(item) {
      let vm = this;
      if (vm.miltiple) {
        vm.selected_result.added.push(item);
        vm.$emit("onSubmit", vm.selected_result);
        vm.$emit("input", vm.selected_result);
      } else {
        vm.selected_result.added = [];
        vm.selected_result.added.push(item);
        vm.$emit("onSubmit", vm.selected_result);
        vm.$emit("input", vm.selected_result);
      }
    },
    removeItem(item, index) {
      let vm = this;
      vm.selected_result.added.splice(index, 1);
      if (item.on_update) {
        vm.selected_result.removed.push(item);
      }
      vm.$emit("onSubmit", vm.selected_result);
      vm.$emit("input", vm.selected_result);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .search-widget {
    display: flex;
    width: 100%;
    border: 1px solid #ddd;
    padding: 1px 10px;
    height: 30px;
    border-radius: 5px;
    background: #fff;
    position: relative;

    .search-input {
      width: 100%;
      border: none;
      outline: none;
    }

    .search {
      width: 14px;
    }

    .loading-icon {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      background: #fff;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 15px;
      }
    }
  }

  .searched-result-data {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 34px;
    box-shadow: 0px 0px 2px #ccc;
    max-height: 200px;
    background: #fff;
    overflow-y: auto;
    z-index: 999999;
    border-radius: 5px;

    .item {
      width: 100%;
    }
  }

  .selected-data {
    display: flex;
    flex-wrap: wrap;

    .item {
      cursor: pointer;
      margin-right: 6px;
      position: relative;

      &:hover {
        .btn-remove {
          display: flex;
        }
      }

      .btn-remove {
        background: #650101;
        color: #fff;
        height: 15px;
        width: 15px;
        font-size: 14px;
        border-radius: 50%;
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 6px;
        right: -3px;
      }
    }
  }
}
</style>
