export const LOADER_START = "LOADER_START";
export const LOADER_FAIL = "LOADER_FAIL";
export const LOADER_SUCCESS = "LOADER_SUCCESS";
export const LOADER_CLOSE = "LOADER_CLOSE";
export const LOADER_MESSAGE = "LOADER_MESSAGE";
export const LOADER_MORE_FEEDS ="LOADER_MORE_FEEDS";
export const LOADER_EXPERIENCE ="LOADER_EXPERIENCE";
export const LOADER_EDUCATION ="LOADER_EDUCATION";
export const LOADER_QUALIFICATION ="LOADER_QUALIFICATION";
export const LOADER_EMPLOYMENT ="LOADER_EMPLOYMENT";
export const LOADER_PORTFOLIO ="LOADER_PORTFOLIO";
export const ENABLE_GENERAL_EDIT ="ENABLE_GENERAL_EDIT";
export const DIALOG_SEARCH = "DIALOG_SEARCH";
export const SET_ALERT = "SET_ALERT";
export const SET_INFO = "SET_INFO";
export const SET_DRAWER = "SET_DRAWER"




