<template>
  <div class="widget_input_wrapper">
    <div class="widget-input-header">
      <h1>{{ title }}</h1>
      <div class="widget-input-header-right-options row">
        <div class="widget-checkbox" v-if="checkbox">
          <input @change="onChecked" type="checkbox" id="check" />
          <span for="check">{{ checkbox }}</span>
        </div>
        <div class="h-spacer" v-if="help"></div>
        <div class="help-widget" v-if="help">
          <help-tool-tip position="bottom left" width="300px" :text="help" />
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import HelpToolTip from "@/components/helper/HelpToolTip.vue";
export default {
  name: "widget_input_wrapper",
  components: {
    HelpToolTip,
  },
  props: {
    title: {
      type: String,
      default: "Input name",
    },
    help: {
      type: String,
      default: null,
    },
    checkbox: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() { },
  methods: {
    onChecked(e) {
      this.$emit("onChecked", e.target.checked);
    },
  },
};
</script>
<style lang="scss" scoped>
.widget_input_wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .widget-input-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;

    h1 {
      margin-bottom: 5px;
      color: #707070;
      font-weight: 500;
      font-size: 12px;
    }
  }
}
</style>
