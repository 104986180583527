<template>
  <div class="path-wrapper">
    <span
      v-for="(item, index) in $store.getters.get_path"
      :key="index"
      :class="{
        active: item.active,
      }"
      @click="$router.push({ path: item.path })"
      >{{ item.label }}
      <span class="path-icon" v-if="$store.getters.get_path.length - 1 > index"
        >></span
      ></span
    >
  </div>
</template>
<script>
export default {
  name: "path-widget",
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets";
.path-wrapper {
  span {
    color: #000;
    text-transform: capitalize;
    cursor: pointer;
    &.active {
      color: #0884af !important;
    }
    &.path-icon {
      margin: 0px 5px;
    }
  }
}
</style>