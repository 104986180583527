<template>
  <div class="row widget-date-range-picker">
    <datepicker :monday-first="true" :placeholder="firstDatePickerPlaceholder" v-model="value.from"
      :disabled="isFirstDatePickerDisables" :disabled-dates="firstDatePickerDisabledDates"
      input-class="first-range-date-field-input" wrapper-class="first-range-date-field" @selected="onChange"></datepicker>
    <div class="picker-divider"></div>
    <datepicker :monday-first="true" :placeholder="secondDatePickerPlaceholder" v-model="value.to"
      :disabled="isSecondDatePickerDisables" :disabled-dates="secondDatePickerDisabledDates"
      input-class="second-range-date-field-input" wrapper-class="second-range-date-field" @selected="onChange">
    </datepicker>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
export default {
  name: "widget_date_range_picker",
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isFirstDatePickerDisables: {
      type: Boolean,
      default: false,
    },
    isSecondDatePickerDisables: {
      type: Boolean,
      default: false,
    },
    firstDatePickerDisabledDates: {
      type: Object,
      default: () => {
        return {};
      },
    },
    secondDatePickerDisabledDates: {
      type: Object,
      default: () => {
        return {};
      },
    },
    firstDatePickerPlaceholder: {
      type: String,
      default: "From",
    },
    secondDatePickerPlaceholder: {
      type: String,
      default: "To",
    },
  },
  components: {
    Datepicker,
  },
  mounted() {
    let vm = this;
  },
  methods: {
    onChange(e) {
      let vm = this;
      vm.$emit("change", vm.value);
      vm.$emit("input", vm.value);
    },
  },
};
</script>

<style lang="scss" >
.widget-date-range-picker {
  width: 100%;

  .first-range-date-field {
    width: calc(50% - 4px);
    display: flex;
    flex-direction: column;

    .first-range-date-field-input {
      width: 100%;
      height: 45px;
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 0px 10px;
    }
  }

  .picker-divider {
    width: 8px;
  }

  .second-range-date-field {
    width: calc(50% - 4px);
    display: flex;
    flex-direction: column;

    .second-range-date-field-input {
      width: 100%;
      height: 45px;
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 0px 10px;
    }
  }
}
</style>