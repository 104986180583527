<template>
  <div class="map-loaction-wrapper">
    <div class="map-wiget">
      <div class="tags">
        <div class="tag" v-for="(item, index) in added_locations" :key="index">
          {{ item }}
          <button @click="removeLocation(index)" class="btn-close">
            &times;
          </button>
        </div>
      </div>
      <gmap-autocomplete :placeholder="placeholder" @place_changed="setPlace" class="search-input"
        :class="{ isMultiple: isMultiple }" :options="{ fields: ['geometry', 'formatted_address', 'name'] }" @keyup="({ target }) => {
          location_name = target.value;
        }
          " :value="location_name"></gmap-autocomplete>
    </div>
  </div>
</template>
<script>
import { eventBus } from "@/main";
export default {
  name: "map-helper",
  components: {},
  props: {
    value: {
      type: [String, Array],
      default: "",
    },
    placeholder: {
      type: String,
      default: "Select your location",
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      location_name: "",
      added_locations: [],
    };
  },
  mounted() {
    let vm = this;
    eventBus.$on("reset_filter", function (data) {
      vm.location_name = "";
      vm.added_locations = [];
    });
    if (vm.value) {
      if (vm.isMultiple) {
        vm.added_locations = vm.value;
      } else {
        vm.location_name = vm.value;
      }
    }
  },
  watch: {
    value(val) {
      let vm = this;
      if (val) {
        if (vm.isMultiple) {
          vm.added_locations = val;
        } else {
          vm.location_name = val;
        }
      }
    },
  },
  methods: {
    removeLocation(index) {
      let vm = this;
      vm.added_locations.splice(index, 1);
    },
    onUse() {
      let vm = this;
      if (vm.selected_location_name) {
        if (vm.isMultiple) {
          vm.added_locations.push(vm.location_name);
          vm.$emit("onSubmit", vm.added_locations);
          vm.$emit("input", vm.added_locations);
          vm.location_name = "";
        } else {
          vm.$emit("onSubmit", { name: vm.location_name, position: null });
          vm.$emit("input", vm.location_name);
        }
      } else {
        if (vm.isMultiple) {
          vm.added_locations.push(vm.location_name);
          vm.$emit("onSubmit", vm.added_locations);
          vm.$emit("input", vm.added_locations);
          vm.location_name = "";
        } else {
          vm.$emit("onSubmit", { name: vm.location_name, position: null });
          vm.$emit("input", vm.location_name);
        }
      }
    },
    setPlace(place) {
      let vm = this;
      vm.place = place;
      if (vm.$hasKey(place, "formatted_address")) {
        vm.location_name = place.formatted_address;
      }
      vm.onUse();
    },
    closeModal() {
      this.locaction_widget = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-loaction-wrapper {
  width: 100%;
  display: flex;

  .map-wiget {
    width: 100%;
    display: flex;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    align-items: center;
    flex-wrap: wrap;
    padding: 7px 10px;

    .tags {
      display: flex;
      flex-wrap: wrap;

      .tag {
        background: #ddd;
        padding: 2px 6px;
        border-radius: 3px;
        margin-right: 8px;
        margin-bottom: 8px;
        position: relative;

        .btn-close {
          position: absolute;
          height: 14px;
          width: 14px;
          background: #6a0202;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          top: -8px;
          right: -2px;
          border-radius: 9px;
        }
      }
    }

    .search-input {
      width: 100%;
      padding: 0px 15px;
      outline: none;
      border: none;
      border-radius: 10px;

      &.isMultiple {
        width: 200px;
        padding: 0px 6px;
      }
    }
  }
}
</style>

<style lang="scss">
.pac-container {
  //z-index: 999999999999999 !important;
}

.pac-logo:after {
  display: none !important;
}

.pac-item {
  cursor: pointer;
  padding: 9px 4px !important;
}
</style>