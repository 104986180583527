<template>
  <div class="page-wrapper">
    <div class="page-body">
      <div class="tabs-widget">
        <div class="tabs-links">
          <div class="tab" style="justify-content: flex-start" :class="{ active: tab_type == 'public' }"
            @click="switchTab('public')">
            <span class="name">Summary</span>
          </div>
          <div class="tab" :class="{
            active:
              tab_type == 'students' ||
              $route.query.profile_type == 'student',
          }" @click="switchTab('students')">
            <span class="name">Students</span>
          </div>
          <div class="tab" :class="{
            active:
              tab_type == 'instructors' ||
              $route.query.profile_type == 'instructor',
          }" @click="switchTab('instructors')">
            <span class="name">Instructors</span>
          </div>
          <div class="tab" :class="{ active: tab_type == 'skills' }" @click="switchTab('skills')">
            <span class="name">Skills</span>
          </div>
          <div class="tab" :class="{
            active: tab_type == 'teams' || $route.name == 'view-team',
          }" @click="switchTab('teams')">
            <span class="name">Schools</span>
          </div>
        </div>
      </div>
      <div class="tab-body">
        <!-- General info -->
        <general-information v-if="tab_type == 'public'" :school="school" />
        <!-- End of genelar info -->

        <!-- Consultants -->
        <Consultants v-if="tab_type == 'students'" :school="school" />
        <!-- End of Consultants -->

        <!-- Instructors -->
        <Instructors v-if="tab_type == 'instructors'" :school="school" />
        <!-- End of Instuctors -->

        <!-- Skills -->
        <Skills v-if="tab_type == 'skills'" :school="school" />
        <!-- End of Skills -->

        <!-- Projects -->
        <Projects v-if="tab_type == 'project-removed'" :school="school" />
        <!-- End of Projects -->

        <!-- View consultant 
        <ManageConsultant v-if="tab_type == 'manage'" /> -->

        <!-- Teams -->
        <Teams v-if="tab_type == 'teams'" :school="school"></Teams>
      </div>
    </div>
  </div>
</template>
<script>
import generalInformation from "./components/generalInformation.vue";
import Consultants from "./components/Consultants.vue";
import Skills from "./components/Skills.vue";
import Projects from "./components/Projects.vue";
import Instructors from "./components/Instructors.vue";
import Teams from "./components/Teams.vue";
// import ManageConsultant from "./components/ManageConsultant.vue";

export default {
  name: "dashboard",
  components: {
    generalInformation,
    Consultants,
    Instructors,
    Skills,
    Projects,
    Teams,
    // ManageConsultant,
  },
  props: {
    school: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      tab_type: "students",
    };
  },
  watch: {
    "$route.params.type"(val) {
      let vm = this;
      vm.tab_type = val;
    },
  },
  created() {
    let vm = this;
  },
  mounted() {
    let vm = this;
  },
  methods: {
    onScoll({ target }) {
      // Modify header according to profile scoll
      let vm = this;
      vm.isScoll = true;
    },
    switchTab(type) {
      let vm = this;
      vm.tab_type = type;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ebebeb;
  padding: 5px;

  .page-body {
    margin-top: 5px;
    height: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;

    .tabs-widget {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-radius: 5px;
      width: 134px;
      border-bottom: 1px solid #efefef;
      height: max-content;
      padding: 18px 16px;

      .tabs-links {
        height: calc(100% - 168px);

        @media (min-width: 300px) and (max-width: 630px) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: unset;
          overflow-y: unset;
        }

        .tab {
          cursor: pointer;
          height: 35px;
          display: flex;
          align-items: center;
          // border-left: 3px solid #eff1f8;
          position: relative;

          @media (min-width: 300px) and (max-width: 630px) {
            font-size: 11px;
          }

          .name {
            display: block;
            color: #707070;

            @media (min-width: 300px) and (max-width: 630px) {
              display: none;
            }
          }
        }

        .tab:hover {
          // border-left: 3px solid #1ba5a4;
          transition: 0.3s ease-in-out;

          .name {
            color: #1ba5a4;
            transition: 0.3s ease-in-out;
          }
        }

        .tab.active {

          // &::after {
          //   content: "";
          //   position: absolute;
          //   width: 5px;
          //   height: 100%;
          //   background: #1ba5a4;
          //   bottom: 0px;
          //   left: -4px;
          //   border-radius: 14px;
          // }
          .name {
            color: #1ba5a4;
          }

          .icon {
            background: #1890a9;
          }
        }
      }
    }

    .tab-body {
      width: calc(100% - 139px);
      height: 100%;
      padding: 0px !important;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>

