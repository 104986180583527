var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-widget-modal"},[_c('div',{staticClass:"wizard-widget"},[_c('div',{staticClass:"wizard-widget-header"},[_c('button',{staticClass:"btn-back",on:{"click":() => {
            if (_vm.add_many_active_tab == 2) {
              _vm.add_many_active_tab = 1;
            } else {
              _vm.goBack();
            }
          }}},[_c('img',{attrs:{"src":require("@/assets/admin-icon/chevron-left-green.svg")}})]),_c('div',{staticClass:"wizard-indicator"},[_c('div',{staticClass:"indicator-item",class:{ active: _vm.add_many_active_tab == 1 }}),_c('div',{staticClass:"indicator-item",class:{ active: _vm.add_many_active_tab == 2 }})]),_c('div',{staticClass:"wizard-action"})]),_c('div',{staticClass:"wizard-widget-body"},[(_vm.add_many_active_tab == 1)?_c('div',{staticClass:"tab"},[_c('div',{staticClass:"template-widget"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('p',[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('a',{staticClass:"btn-download",attrs:{"download":"","href":_vm.file}},[_vm._v("Download Template "),_c('img',{attrs:{"src":require("@/assets/admin-icon/download-white.svg")}})])]),_c('div',{staticClass:"wizard-widget-footer-options"},[_c('button',{staticClass:"btn-next",on:{"click":function($event){_vm.add_many_active_tab = 2}}},[_vm._v(" Next ")])])]):_vm._e(),(_vm.add_many_active_tab == 2)?_c('div',{staticClass:"tab"},[_c('div',{staticClass:"upload-widget"},[_vm._t("default"),_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('p',[_vm._v(_vm._s(_vm.actiontext))]),_c('div',{staticClass:"upload-widget-container"},[_c('CustomerUploader',{staticClass:"uploader",on:{"onFileSelected":(file) => {
                  if (file) {
                    _vm.onFileSelected(file);
                  }
                }}},[_c('button',{staticClass:"btn-upload"},[_vm._v(" Attach file "),_c('img',{attrs:{"src":require("@/assets/admin-icon/upload-white.svg")}})])]),(_vm.selected_file)?_c('div',{staticClass:"selected-file"},[_c('label',[_c('img',{attrs:{"src":require("@/assets/admin-icon/file-grey.svg")}}),_vm._v(" "+_vm._s(_vm.selected_file.name))])]):_vm._e()],1)],2),_c('div',{staticClass:"wizard-widget-footer-options"},[_c('c-button',{staticClass:"btn-next",attrs:{"loading":_vm.loading},on:{"click":_vm.submitData}},[_vm._v("Finish")])],1)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }