export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const SEARCH_ON_CUSTOMER = "SEARCH_ON_CUSTOMER";
export const GET_ASSIGNMENT = "GET_ASSIGNMENT";
export const GET_ALL_ASSIGNMENT = "GET_ALL_ASSIGNMENT";
export const DELETE_ASSIGNMENT = "DELETE_ASSIGNMENT";
export const PUBLISH_ASSIGNMENT = "PUBLISH_ASSIGNMENT";
export const UNPUBLISH_ASSIGNMENT = "UNPUBLISH_ASSIGNMENT";
export const ADD_NEW_ASSIGNMENT = "ADD_NEW_ASSIGNMENT";
export const UPDATE_ASSIGNMENT = "UPDATE_ASSIGNMENT";
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID";
export const GET_ASSIGNMENT_BY_CUSTOMER_ID = "GET_ASSIGNMENT_BY_CUSTOMER_ID";
export const SEARCH_ASSIGNMENT = "SEARCH_ASSIGNMENT";
export const DELETE_CUSTOMER_ACCOUNT = "DELETE_CUSTOMER_ACCOUNT";
export const UPDATE_CUSTOMER_ACCOUNT_PASSWORD = "UPDATE_CUSTOMER_ACCOUNT_PASSWORD";
